<template>
  <transition name="modal-fade">
    <section
      class="main-modal fixed wh100 left0 top0 flex-center z999"
      v-if="$store.state.isModal"
      @mouseup="releaseClick"
    >
      <component @click.stop :is="componentName"></component>
    </section>
  </transition>
</template>

<script>
  export default {
    name: 'main-modal',
    computed: {
      componentName () {
        const name = this.$store.state.modalComponentName;
        // eslint-disable-next-line
        return name ? () => import(`@/components/${name}`) : '';
      }
    },
    methods: {
      releaseClick () {
        this.$store.state.isClickModal
          ? this.$store.commit('SET_KEY_VALUE', {
              key: 'isClickModal',
              value: false
            })
          : this.close();
      },
      close () {
        this.$store.commit('RESET_MODAL_DATA');
        this.$store.commit('SET_MODAL', { val: false, name: '' });
      }
    }
  };
</script>

<style lang="less">
  .main-modal {
    background-color: rgba(0, 0, 0, 0.4);
  }
</style>
