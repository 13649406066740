export const judete = [
  {
    auto: 'AB',
    nume: 'Alba',
    localitati: [
      {
        nume: 'Abrud'
      },
      {
        nume: 'Aiud'
      },
      {
        nume: 'Alba Iulia'
      },
      {
        nume: 'Albac'
      },
      {
        nume: 'Almaşu Mare',
        simplu: 'Almasu Mare'
      },
      {
        nume: 'Arieşeni',
        simplu: 'Arieseni'
      },
      {
        nume: 'Avram Iancu'
      },
      {
        nume: 'Baia de Arieş',
        simplu: 'Baia de Aries'
      },
      {
        nume: 'Berghin'
      },
      {
        nume: 'Bistra'
      },
      {
        nume: 'Blaj'
      },
      {
        nume: 'Blandiana'
      },
      {
        nume: 'Bucium'
      },
      {
        nume: 'Cenade'
      },
      {
        nume: 'Ceru-Băcăinţi',
        simplu: 'Ceru-Bacainti'
      },
      {
        nume: 'Cetatea de Baltă',
        simplu: 'Cetatea de Balta'
      },
      {
        nume: 'Ciugud'
      },
      {
        nume: 'Ciuruleasa'
      },
      {
        nume: 'Cricău',
        simplu: 'Cricau'
      },
      {
        nume: 'Crăciunelu de Jos',
        simplu: 'Craciunelu de Jos'
      },
      {
        nume: 'Cugir'
      },
      {
        nume: 'Câlnic',
        simplu: 'Calnic'
      },
      {
        nume: 'Câmpeni',
        simplu: 'Campeni'
      },
      {
        nume: 'Daia Română',
        simplu: 'Daia Romana'
      },
      {
        nume: 'Doştat',
        simplu: 'Dostat'
      },
      {
        nume: 'Fărău',
        simplu: 'Farau'
      },
      {
        nume: 'Galda de Jos'
      },
      {
        nume: 'Gârbova',
        simplu: 'Garbova'
      },
      {
        nume: 'Gârda de Sus',
        simplu: 'Garda de Sus'
      },
      {
        nume: 'Hopârta',
        simplu: 'Hoparta'
      },
      {
        nume: 'Horea'
      },
      {
        nume: 'Ighiu'
      },
      {
        nume: 'Jidvei'
      },
      {
        nume: 'Livezile'
      },
      {
        nume: 'Lopadea Nouă',
        simplu: 'Lopadea Noua'
      },
      {
        nume: 'Lunca Mureşului',
        simplu: 'Lunca Muresului'
      },
      {
        nume: 'Lupşa',
        simplu: 'Lupsa'
      },
      {
        nume: 'Meteş',
        simplu: 'Metes'
      },
      {
        nume: 'Mihalţ',
        simplu: 'Mihalt'
      },
      {
        nume: 'Mirăslău',
        simplu: 'Miraslau'
      },
      {
        nume: 'Mogoş',
        simplu: 'Mogos'
      },
      {
        nume: 'Noşlac',
        simplu: 'Noslac'
      },
      {
        nume: 'Ocna Mureş',
        simplu: 'Ocna Mures'
      },
      {
        nume: 'Ocoliş',
        simplu: 'Ocolis'
      },
      {
        nume: 'Ohaba'
      },
      {
        nume: 'Poiana Vadului'
      },
      {
        nume: 'Ponor'
      },
      {
        nume: 'Roşia Montană',
        simplu: 'Rosia Montana'
      },
      {
        nume: 'Roşia de Secaş',
        simplu: 'Rosia de Secas'
      },
      {
        nume: 'Râmetea',
        simplu: 'Rametea'
      },
      {
        nume: 'Râmeţ',
        simplu: 'Ramet'
      },
      {
        nume: 'Rădeşti',
        simplu: 'Radesti'
      },
      {
        nume: 'Scărişoara',
        simplu: 'Scarisoara'
      },
      {
        nume: 'Sebeş',
        simplu: 'Sebes'
      },
      {
        nume: 'Sohodol'
      },
      {
        nume: 'Stremţ',
        simplu: 'Stremt'
      },
      {
        nume: 'Sâncel',
        simplu: 'Sancel'
      },
      {
        nume: 'Sântimbru',
        simplu: 'Santimbru'
      },
      {
        nume: 'Săliştea',
        simplu: 'Salistea'
      },
      {
        nume: 'Săsciori',
        simplu: 'Sasciori'
      },
      {
        nume: 'Teiuş',
        simplu: 'Teius'
      },
      {
        nume: 'Unirea'
      },
      {
        nume: 'Vadu Moţilor',
        simplu: 'Vadu Motilor'
      },
      {
        nume: 'Valea Lungă',
        simplu: 'Valea Lunga'
      },
      {
        nume: 'Vidra'
      },
      {
        nume: 'Vinţu de Jos',
        simplu: 'Vintu de Jos'
      },
      {
        nume: 'Zlatna'
      },
      {
        nume: 'Întregalde',
        simplu: 'Intregalde'
      },
      {
        nume: 'Sibot',
        simplu: 'Sibot'
      },
      {
        nume: 'Sona',
        simplu: 'Sona'
      },
      {
        nume: 'Spring',
        simplu: 'Spring'
      },
      {
        nume: 'Sugag',
        simplu: 'Sugag'
      },
      {
        nume: 'Abrud-Sat',
        comuna: 'Abrud'
      },
      {
        nume: 'Gura Cornei',
        comuna: 'Abrud'
      },
      {
        nume: 'Soharu',
        comuna: 'Abrud'
      },
      {
        nume: 'Aiudul de Sus',
        comuna: 'Aiud'
      },
      {
        nume: 'Ciumbrud',
        comuna: 'Aiud'
      },
      {
        nume: 'Gâmbaş',
        simplu: 'Gambas',
        comuna: 'Aiud'
      },
      {
        nume: 'Gârbova de Jos',
        simplu: 'Garbova de Jos',
        comuna: 'Aiud'
      },
      {
        nume: 'Gârbova de Sus',
        simplu: 'Garbova de Sus',
        comuna: 'Aiud'
      },
      {
        nume: 'Gârboviţa',
        simplu: 'Garbovita',
        comuna: 'Aiud'
      },
      {
        nume: 'Măgina',
        simplu: 'Magina',
        comuna: 'Aiud'
      },
      {
        nume: 'Păgida',
        simplu: 'Pagida',
        comuna: 'Aiud'
      },
      {
        nume: 'Sâncrai',
        simplu: 'Sancrai',
        comuna: 'Aiud'
      },
      {
        nume: 'Tifra',
        simplu: 'Tifra',
        comuna: 'Aiud'
      },
      {
        nume: 'Bărăbanţ',
        simplu: 'Barabant',
        comuna: 'Alba Iulia'
      },
      {
        nume: 'Miceşti',
        simplu: 'Micesti',
        comuna: 'Alba Iulia'
      },
      {
        nume: 'Oarda',
        comuna: 'Alba Iulia'
      },
      {
        nume: 'Pâclişa',
        simplu: 'Paclisa',
        comuna: 'Alba Iulia'
      },
      {
        nume: 'Budăieşti',
        simplu: 'Budaiesti',
        comuna: 'Albac'
      },
      {
        nume: 'Bărăşti',
        simplu: 'Barasti',
        comuna: 'Albac'
      },
      {
        nume: 'Cioneşti',
        simplu: 'Cionesti',
        comuna: 'Albac'
      },
      {
        nume: 'Costeşti',
        simplu: 'Costesti',
        comuna: 'Albac'
      },
      {
        nume: 'Dealu Lămăşoi',
        simplu: 'Dealu Lamasoi',
        comuna: 'Albac'
      },
      {
        nume: 'Deve',
        comuna: 'Albac'
      },
      {
        nume: 'După Pleşe',
        simplu: 'Dupa Plese',
        comuna: 'Albac'
      },
      {
        nume: 'Faţa',
        simplu: 'Fata',
        comuna: 'Albac'
      },
      {
        nume: 'Pleşeşti',
        simplu: 'Plesesti',
        comuna: 'Albac'
      },
      {
        nume: 'Potionci',
        comuna: 'Albac'
      },
      {
        nume: 'Rogoz',
        comuna: 'Albac'
      },
      {
        nume: 'Roşeşti',
        simplu: 'Rosesti',
        comuna: 'Albac'
      },
      {
        nume: 'Ruseşti',
        simplu: 'Rusesti',
        comuna: 'Albac'
      },
      {
        nume: 'Sohodol',
        comuna: 'Albac'
      },
      {
        nume: 'Tamboreşti',
        simplu: 'Tamboresti',
        comuna: 'Albac'
      },
      {
        nume: 'Almaşu de Mijloc',
        simplu: 'Almasu de Mijloc',
        comuna: 'Almasu Mare'
      },
      {
        nume: 'Brădet',
        simplu: 'Bradet',
        comuna: 'Almasu Mare'
      },
      {
        nume: 'Cheile Cibului',
        comuna: 'Almasu Mare'
      },
      {
        nume: 'Cib',
        comuna: 'Almasu Mare'
      },
      {
        nume: 'Glod',
        comuna: 'Almasu Mare'
      },
      {
        nume: 'Nădăştia',
        simplu: 'Nadastia',
        comuna: 'Almasu Mare'
      },
      {
        nume: 'Avrămeşti',
        simplu: 'Avramesti',
        comuna: 'Arieseni'
      },
      {
        nume: 'Bubeşti',
        simplu: 'Bubesti',
        comuna: 'Arieseni'
      },
      {
        nume: 'Casa de Piatră',
        simplu: 'Casa de Piatra',
        comuna: 'Arieseni'
      },
      {
        nume: 'Cobleş',
        simplu: 'Cobles',
        comuna: 'Arieseni'
      },
      {
        nume: 'Dealu Bajului',
        comuna: 'Arieseni'
      },
      {
        nume: 'Faţa Cristesei',
        simplu: 'Fata Cristesei',
        comuna: 'Arieseni'
      },
      {
        nume: 'Faţa Lăpuşului',
        simplu: 'Fata Lapusului',
        comuna: 'Arieseni'
      },
      {
        nume: 'Galbena',
        comuna: 'Arieseni'
      },
      {
        nume: 'Hodobana',
        comuna: 'Arieseni'
      },
      {
        nume: 'Izlaz',
        comuna: 'Arieseni'
      },
      {
        nume: 'Poieniţa',
        simplu: 'Poienita',
        comuna: 'Arieseni'
      },
      {
        nume: 'Pănteşti',
        simplu: 'Pantesti',
        comuna: 'Arieseni'
      },
      {
        nume: 'Pătrăhăiţeşti',
        simplu: 'Patrahaitesti',
        comuna: 'Arieseni'
      },
      {
        nume: 'Raviceşti',
        simplu: 'Ravicesti',
        comuna: 'Arieseni'
      },
      {
        nume: 'Sturu',
        comuna: 'Arieseni'
      },
      {
        nume: 'Vanvuceşti',
        simplu: 'Vanvucesti',
        comuna: 'Arieseni'
      },
      {
        nume: 'Stei-Arieşeni',
        simplu: 'Stei-Arieseni',
        comuna: 'Arieseni'
      },
      {
        nume: 'Achimeţeşti',
        simplu: 'Achimetesti',
        comuna: 'Avram Iancu'
      },
      {
        nume: 'Avrămeşti',
        simplu: 'Avramesti',
        comuna: 'Avram Iancu'
      },
      {
        nume: 'Boldeşti',
        simplu: 'Boldesti',
        comuna: 'Avram Iancu'
      },
      {
        nume: 'Bădăi',
        simplu: 'Badai',
        comuna: 'Avram Iancu'
      },
      {
        nume: 'Coceşti',
        simplu: 'Cocesti',
        comuna: 'Avram Iancu'
      },
      {
        nume: 'Cocoşeşti',
        simplu: 'Cocosesti',
        comuna: 'Avram Iancu'
      },
      {
        nume: 'Coroieşti',
        simplu: 'Coroiesti',
        comuna: 'Avram Iancu'
      },
      {
        nume: 'Cândeşti',
        simplu: 'Candesti',
        comuna: 'Avram Iancu'
      },
      {
        nume: 'Cârăşti',
        simplu: 'Carasti',
        comuna: 'Avram Iancu'
      },
      {
        nume: 'Cârţuleşti',
        simplu: 'Cartulesti',
        comuna: 'Avram Iancu'
      },
      {
        nume: 'Călugăreşti',
        simplu: 'Calugaresti',
        comuna: 'Avram Iancu'
      },
      {
        nume: 'Căsoaia',
        simplu: 'Casoaia',
        comuna: 'Avram Iancu'
      },
      {
        nume: 'Dealu Crişului',
        simplu: 'Dealu Crisului',
        comuna: 'Avram Iancu'
      },
      {
        nume: 'Doleşti',
        simplu: 'Dolesti',
        comuna: 'Avram Iancu'
      },
      {
        nume: 'Dumăceşti',
        simplu: 'Dumacesti',
        comuna: 'Avram Iancu'
      },
      {
        nume: 'Gojeieşti',
        simplu: 'Gojeiesti',
        comuna: 'Avram Iancu'
      },
      {
        nume: 'Helereşti',
        simplu: 'Heleresti',
        comuna: 'Avram Iancu'
      },
      {
        nume: 'Inceşti',
        simplu: 'Incesti',
        comuna: 'Avram Iancu'
      },
      {
        nume: 'Jojei',
        comuna: 'Avram Iancu'
      },
      {
        nume: 'Mărteşti',
        simplu: 'Martesti',
        comuna: 'Avram Iancu'
      },
      {
        nume: 'Orgeşti',
        simplu: 'Orgesti',
        comuna: 'Avram Iancu'
      },
      {
        nume: 'Plai',
        comuna: 'Avram Iancu'
      },
      {
        nume: 'Puşeleşti',
        simplu: 'Puselesti',
        comuna: 'Avram Iancu'
      },
      {
        nume: 'Pătruţeşti',
        simplu: 'Patrutesti',
        comuna: 'Avram Iancu'
      },
      {
        nume: 'Târsa',
        simplu: 'Tarsa',
        comuna: 'Avram Iancu'
      },
      {
        nume: 'Târsa-Plai',
        simplu: 'Tarsa-Plai',
        comuna: 'Avram Iancu'
      },
      {
        nume: 'Valea Maciului',
        comuna: 'Avram Iancu'
      },
      {
        nume: 'Valea Uţului',
        simplu: 'Valea Utului',
        comuna: 'Avram Iancu'
      },
      {
        nume: 'Verdeşti',
        simplu: 'Verdesti',
        comuna: 'Avram Iancu'
      },
      {
        nume: 'Vidrişoara',
        simplu: 'Vidrisoara',
        comuna: 'Avram Iancu'
      },
      {
        nume: 'Soiceşti',
        simplu: 'Soicesti',
        comuna: 'Avram Iancu'
      },
      {
        nume: 'Sterteşti',
        simplu: 'Stertesti',
        comuna: 'Avram Iancu'
      },
      {
        nume: 'Brăzeşti',
        simplu: 'Brazesti',
        comuna: 'Baia De Aries'
      },
      {
        nume: 'Cioara de Sus',
        comuna: 'Baia De Aries'
      },
      {
        nume: 'Muncelu',
        comuna: 'Baia De Aries'
      },
      {
        nume: 'Sartăş',
        simplu: 'Sartas',
        comuna: 'Baia De Aries'
      },
      {
        nume: 'Simuleşti',
        simplu: 'Simulesti',
        comuna: 'Baia De Aries'
      },
      {
        nume: 'Ghirbom',
        comuna: 'Berghin'
      },
      {
        nume: 'Henig',
        comuna: 'Berghin'
      },
      {
        nume: 'Straja',
        comuna: 'Berghin'
      },
      {
        nume: 'Aroneşti',
        simplu: 'Aronesti',
        comuna: 'Bistra'
      },
      {
        nume: 'Bârleşti',
        simplu: 'Barlesti',
        comuna: 'Bistra'
      },
      {
        nume: 'Băleşti',
        simplu: 'Balesti',
        comuna: 'Bistra'
      },
      {
        nume: 'Băleşti-Cătun',
        simplu: 'Balesti-Catun',
        comuna: 'Bistra'
      },
      {
        nume: 'Cheleteni',
        comuna: 'Bistra'
      },
      {
        nume: 'Ciuldeşti',
        simplu: 'Ciuldesti',
        comuna: 'Bistra'
      },
      {
        nume: 'Creţeşti',
        simplu: 'Cretesti',
        comuna: 'Bistra'
      },
      {
        nume: 'Dealu Muntelui',
        comuna: 'Bistra'
      },
      {
        nume: 'Durăşti',
        simplu: 'Durasti',
        comuna: 'Bistra'
      },
      {
        nume: 'Dâmbureni',
        simplu: 'Dambureni',
        comuna: 'Bistra'
      },
      {
        nume: 'Gârde',
        simplu: 'Garde',
        comuna: 'Bistra'
      },
      {
        nume: 'Găneşti',
        simplu: 'Ganesti',
        comuna: 'Bistra'
      },
      {
        nume: 'Hodişeşti',
        simplu: 'Hodisesti',
        comuna: 'Bistra'
      },
      {
        nume: 'Hudriceşti',
        simplu: 'Hudricesti',
        comuna: 'Bistra'
      },
      {
        nume: 'Lipaia',
        comuna: 'Bistra'
      },
      {
        nume: 'Lunca Largă',
        simplu: 'Lunca Larga',
        comuna: 'Bistra'
      },
      {
        nume: 'Lunca Merilor',
        comuna: 'Bistra'
      },
      {
        nume: 'Mihăieşti',
        simplu: 'Mihaiesti',
        comuna: 'Bistra'
      },
      {
        nume: 'Novăceşti',
        simplu: 'Novacesti',
        comuna: 'Bistra'
      },
      {
        nume: 'Nămaş',
        simplu: 'Namas',
        comuna: 'Bistra'
      },
      {
        nume: 'Perjeşti',
        simplu: 'Perjesti',
        comuna: 'Bistra'
      },
      {
        nume: 'Poiana',
        comuna: 'Bistra'
      },
      {
        nume: 'Poiu',
        comuna: 'Bistra'
      },
      {
        nume: 'Runcuri',
        comuna: 'Bistra'
      },
      {
        nume: 'Rătitiş',
        simplu: 'Ratitis',
        comuna: 'Bistra'
      },
      {
        nume: 'Sălăgeşti',
        simplu: 'Salagesti',
        comuna: 'Bistra'
      },
      {
        nume: 'Tolăceşti',
        simplu: 'Tolacesti',
        comuna: 'Bistra'
      },
      {
        nume: 'Tomnatec',
        comuna: 'Bistra'
      },
      {
        nume: 'Trişoreşti',
        simplu: 'Trisoresti',
        comuna: 'Bistra'
      },
      {
        nume: 'Vârşi-Rontu',
        simplu: 'Varsi-Rontu',
        comuna: 'Bistra'
      },
      {
        nume: 'Vârşii Mari',
        simplu: 'Varsii Mari',
        comuna: 'Bistra'
      },
      {
        nume: 'Vârşii Mici',
        simplu: 'Varsii Mici',
        comuna: 'Bistra'
      },
      {
        nume: 'Stefanca',
        simplu: 'Stefanca',
        comuna: 'Bistra'
      },
      {
        nume: 'Tărăneşti',
        simplu: 'Taranesti',
        comuna: 'Bistra'
      },
      {
        nume: 'Deleni-Obârşie',
        simplu: 'Deleni-Obarsie',
        comuna: 'Blaj'
      },
      {
        nume: 'Fliteşti',
        simplu: 'Flitesti',
        comuna: 'Blaj'
      },
      {
        nume: 'Izvoarele',
        comuna: 'Blaj'
      },
      {
        nume: 'Mănărade',
        simplu: 'Manarade',
        comuna: 'Blaj'
      },
      {
        nume: 'Petrisat',
        comuna: 'Blaj'
      },
      {
        nume: 'Spătac',
        simplu: 'Spatac',
        comuna: 'Blaj'
      },
      {
        nume: 'Tiur',
        comuna: 'Blaj'
      },
      {
        nume: 'Veza',
        comuna: 'Blaj'
      },
      {
        nume: 'Acmariu',
        comuna: 'Blandiana'
      },
      {
        nume: 'Ibru',
        comuna: 'Blandiana'
      },
      {
        nume: 'Poieni',
        comuna: 'Blandiana'
      },
      {
        nume: 'Răcătău',
        simplu: 'Racatau',
        comuna: 'Blandiana'
      },
      {
        nume: 'Angheleşti',
        simplu: 'Anghelesti',
        comuna: 'Bucium'
      },
      {
        nume: 'Bisericani',
        comuna: 'Bucium'
      },
      {
        nume: 'Bucium-Sat',
        comuna: 'Bucium'
      },
      {
        nume: 'Cerbu',
        comuna: 'Bucium'
      },
      {
        nume: 'Ciuculeşti',
        simplu: 'Ciuculesti',
        comuna: 'Bucium'
      },
      {
        nume: 'Coleşeni',
        simplu: 'Coleseni',
        comuna: 'Bucium'
      },
      {
        nume: 'Dogăreşti',
        simplu: 'Dogaresti',
        comuna: 'Bucium'
      },
      {
        nume: 'Fereşti',
        simplu: 'Feresti',
        comuna: 'Bucium'
      },
      {
        nume: 'Floreşti',
        simplu: 'Floresti',
        comuna: 'Bucium'
      },
      {
        nume: 'Gura Izbitei',
        comuna: 'Bucium'
      },
      {
        nume: 'Heleşti',
        simplu: 'Helesti',
        comuna: 'Bucium'
      },
      {
        nume: 'Izbicioara',
        comuna: 'Bucium'
      },
      {
        nume: 'Izbita',
        comuna: 'Bucium'
      },
      {
        nume: 'Jurcuieşti',
        simplu: 'Jurcuiesti',
        comuna: 'Bucium'
      },
      {
        nume: 'Lupuleşti',
        simplu: 'Lupulesti',
        comuna: 'Bucium'
      },
      {
        nume: 'Muntari',
        comuna: 'Bucium'
      },
      {
        nume: 'Măgura',
        simplu: 'Magura',
        comuna: 'Bucium'
      },
      {
        nume: 'Petreni',
        comuna: 'Bucium'
      },
      {
        nume: 'Poiana',
        comuna: 'Bucium'
      },
      {
        nume: 'Poieni',
        comuna: 'Bucium'
      },
      {
        nume: 'Stâlnişoara',
        simplu: 'Stalnisoara',
        comuna: 'Bucium'
      },
      {
        nume: 'Valea Abruzel',
        comuna: 'Bucium'
      },
      {
        nume: 'Valea Albă',
        simplu: 'Valea Alba',
        comuna: 'Bucium'
      },
      {
        nume: 'Valea Cerbului',
        comuna: 'Bucium'
      },
      {
        nume: 'Valea Negrilesii',
        comuna: 'Bucium'
      },
      {
        nume: 'Valea Poienii',
        comuna: 'Bucium'
      },
      {
        nume: 'Valea Sesii',
        simplu: 'Valea Sesii',
        comuna: 'Bucium'
      },
      {
        nume: 'Vâlcea',
        simplu: 'Valcea',
        comuna: 'Bucium'
      },
      {
        nume: 'Văleni',
        simplu: 'Valeni',
        comuna: 'Bucium'
      },
      {
        nume: 'Cut',
        comuna: 'Calnic'
      },
      {
        nume: 'Deal',
        comuna: 'Calnic'
      },
      {
        nume: 'Bonceşti',
        simplu: 'Boncesti',
        comuna: 'Campeni'
      },
      {
        nume: 'Borleşti',
        simplu: 'Borlesti',
        comuna: 'Campeni'
      },
      {
        nume: 'Boteşti',
        simplu: 'Botesti',
        comuna: 'Campeni'
      },
      {
        nume: 'Certege',
        comuna: 'Campeni'
      },
      {
        nume: 'Coasta Vâscului',
        simplu: 'Coasta Vascului',
        comuna: 'Campeni'
      },
      {
        nume: 'Dealu Bistrii',
        comuna: 'Campeni'
      },
      {
        nume: 'Dealu Capsei',
        comuna: 'Campeni'
      },
      {
        nume: 'Dric',
        comuna: 'Campeni'
      },
      {
        nume: 'Dănduţ',
        simplu: 'Dandut',
        comuna: 'Campeni'
      },
      {
        nume: 'Faţa Abrudului',
        simplu: 'Fata Abrudului',
        comuna: 'Campeni'
      },
      {
        nume: 'Floreşti',
        simplu: 'Floresti',
        comuna: 'Campeni'
      },
      {
        nume: 'Furduieşti',
        simplu: 'Furduiesti',
        comuna: 'Campeni'
      },
      {
        nume: 'Mihoeşti',
        simplu: 'Mihoesti',
        comuna: 'Campeni'
      },
      {
        nume: 'Motorăşti',
        simplu: 'Motorasti',
        comuna: 'Campeni'
      },
      {
        nume: 'Peste Valea Bistrii',
        comuna: 'Campeni'
      },
      {
        nume: 'Poduri',
        comuna: 'Campeni'
      },
      {
        nume: 'Sorliţa',
        simplu: 'Sorlita',
        comuna: 'Campeni'
      },
      {
        nume: 'Tomuşeşti',
        simplu: 'Tomusesti',
        comuna: 'Campeni'
      },
      {
        nume: 'Valea Bistrii',
        comuna: 'Campeni'
      },
      {
        nume: 'Valea Caselor',
        comuna: 'Campeni'
      },
      {
        nume: 'Vârşi',
        simplu: 'Varsi',
        comuna: 'Campeni'
      },
      {
        nume: 'Capu Dealului',
        comuna: 'Cenade'
      },
      {
        nume: 'Gorgan',
        comuna: 'Cenade'
      },
      {
        nume: 'Cergău Mare',
        simplu: 'Cergau Mare',
        comuna: 'Cergau'
      },
      {
        nume: 'Cergău Mic',
        simplu: 'Cergau Mic',
        comuna: 'Cergau'
      },
      {
        nume: 'Lupu',
        comuna: 'Cergau'
      },
      {
        nume: 'Bolovăneşti',
        simplu: 'Bolovanesti',
        comuna: 'Ceru-Bacainti'
      },
      {
        nume: 'Bulbuc',
        comuna: 'Ceru-Bacainti'
      },
      {
        nume: 'Cucuta',
        comuna: 'Ceru-Bacainti'
      },
      {
        nume: 'Curpeni',
        comuna: 'Ceru-Bacainti'
      },
      {
        nume: 'Dumbrăviţa',
        simplu: 'Dumbravita',
        comuna: 'Ceru-Bacainti'
      },
      {
        nume: 'Fântânele',
        simplu: 'Fantanele',
        comuna: 'Ceru-Bacainti'
      },
      {
        nume: 'Groşi',
        simplu: 'Grosi',
        comuna: 'Ceru-Bacainti'
      },
      {
        nume: 'Valea Mare',
        comuna: 'Ceru-Bacainti'
      },
      {
        nume: 'Viezuri',
        comuna: 'Ceru-Bacainti'
      },
      {
        nume: 'Craciunelu de Sus',
        comuna: 'Cetatea De Balta'
      },
      {
        nume: 'Sântămărie',
        simplu: 'Santamarie',
        comuna: 'Cetatea De Balta'
      },
      {
        nume: 'Tătârlaua',
        simplu: 'Tatarlaua',
        comuna: 'Cetatea De Balta'
      },
      {
        nume: 'Drâmbar',
        simplu: 'Drambar',
        comuna: 'Ciugud'
      },
      {
        nume: 'Hăpria',
        simplu: 'Hapria',
        comuna: 'Ciugud'
      },
      {
        nume: 'Limba',
        comuna: 'Ciugud'
      },
      {
        nume: 'Teleac',
        comuna: 'Ciugud'
      },
      {
        nume: 'Seuşa',
        simplu: 'Seusa',
        comuna: 'Ciugud'
      },
      {
        nume: 'Bidigeşti',
        simplu: 'Bidigesti',
        comuna: 'Ciuruleasa'
      },
      {
        nume: 'Bodreşti',
        simplu: 'Bodresti',
        comuna: 'Ciuruleasa'
      },
      {
        nume: 'Bogleşti',
        simplu: 'Boglesti',
        comuna: 'Ciuruleasa'
      },
      {
        nume: 'Buninginea',
        comuna: 'Ciuruleasa'
      },
      {
        nume: 'Gheduleşti',
        simplu: 'Ghedulesti',
        comuna: 'Ciuruleasa'
      },
      {
        nume: 'Morăreşti',
        simplu: 'Moraresti',
        comuna: 'Ciuruleasa'
      },
      {
        nume: 'Mătişeşti',
        simplu: 'Matisesti',
        comuna: 'Ciuruleasa'
      },
      {
        nume: 'Vulcan',
        comuna: 'Ciuruleasa'
      },
      {
        nume: 'Bucerdea Grânoasă',
        simplu: 'Bucerdea Granoasa',
        comuna: 'Craciunelu De Jos'
      },
      {
        nume: 'Cornu',
        comuna: 'Craciunelu De Jos'
      },
      {
        nume: 'Pânca',
        simplu: 'Panca',
        comuna: 'Craciunelu De Jos'
      },
      {
        nume: 'Pădure',
        simplu: 'Padure',
        comuna: 'Craciunelu De Jos'
      },
      {
        nume: 'Craiva',
        comuna: 'Cricau'
      },
      {
        nume: 'Tibru',
        comuna: 'Cricau'
      },
      {
        nume: 'Bocşitura',
        simplu: 'Bocsitura',
        comuna: 'Cugir'
      },
      {
        nume: 'Bucuru',
        comuna: 'Cugir'
      },
      {
        nume: 'Călene',
        simplu: 'Calene',
        comuna: 'Cugir'
      },
      {
        nume: 'Feţeni',
        simplu: 'Feteni',
        comuna: 'Cugir'
      },
      {
        nume: 'Goaşele',
        simplu: 'Goasele',
        comuna: 'Cugir'
      },
      {
        nume: 'Mugeşti',
        simplu: 'Mugesti',
        comuna: 'Cugir'
      },
      {
        nume: 'Vinerea',
        comuna: 'Cugir'
      },
      {
        nume: 'Boz',
        comuna: 'Dostat'
      },
      {
        nume: 'Dealu Doştatului',
        simplu: 'Dealu Dostatului',
        comuna: 'Dostat'
      },
      {
        nume: 'Heria',
        comuna: 'Farau'
      },
      {
        nume: 'Medveş',
        simplu: 'Medves',
        comuna: 'Farau'
      },
      {
        nume: 'Sânbenedic',
        simplu: 'Sanbenedic',
        comuna: 'Farau'
      },
      {
        nume: 'Silea',
        simplu: 'Silea',
        comuna: 'Farau'
      },
      {
        nume: 'Benic',
        comuna: 'Galda De Jos'
      },
      {
        nume: 'Cetea',
        comuna: 'Galda De Jos'
      },
      {
        nume: 'Galda de Sus',
        comuna: 'Galda De Jos'
      },
      {
        nume: 'Lupşeni',
        simplu: 'Lupseni',
        comuna: 'Galda De Jos'
      },
      {
        nume: 'Mesentea',
        comuna: 'Galda De Jos'
      },
      {
        nume: 'Măgura',
        simplu: 'Magura',
        comuna: 'Galda De Jos'
      },
      {
        nume: 'Oiejdea',
        comuna: 'Galda De Jos'
      },
      {
        nume: 'Poiana Galdei',
        comuna: 'Galda De Jos'
      },
      {
        nume: 'Răicani',
        simplu: 'Raicani',
        comuna: 'Galda De Jos'
      },
      {
        nume: 'Zăgriş',
        simplu: 'Zagris',
        comuna: 'Galda De Jos'
      },
      {
        nume: 'Cărpiniş',
        simplu: 'Carpinis',
        comuna: 'Garbova'
      },
      {
        nume: 'Reciu',
        comuna: 'Garbova'
      },
      {
        nume: 'Biharia',
        comuna: 'Garda De Sus'
      },
      {
        nume: 'Dealu Frumos',
        comuna: 'Garda De Sus'
      },
      {
        nume: 'Dealu Ordâncuşii',
        simplu: 'Dealu Ordancusii',
        comuna: 'Garda De Sus'
      },
      {
        nume: 'Dobreşti',
        simplu: 'Dobresti',
        comuna: 'Garda De Sus'
      },
      {
        nume: 'Gheţari',
        simplu: 'Ghetari',
        comuna: 'Garda De Sus'
      },
      {
        nume: 'Gârda Seacă',
        simplu: 'Garda Seaca',
        comuna: 'Garda De Sus'
      },
      {
        nume: 'Hanăşeşti',
        simplu: 'Hanasesti',
        comuna: 'Garda De Sus'
      },
      {
        nume: 'Huzăreşti',
        simplu: 'Huzaresti',
        comuna: 'Garda De Sus'
      },
      {
        nume: 'Izvoarele',
        comuna: 'Garda De Sus'
      },
      {
        nume: 'Munună',
        simplu: 'Mununa',
        comuna: 'Garda De Sus'
      },
      {
        nume: 'Ocoale',
        comuna: 'Garda De Sus'
      },
      {
        nume: 'Plai',
        comuna: 'Garda De Sus'
      },
      {
        nume: 'Plişti',
        simplu: 'Plisti',
        comuna: 'Garda De Sus'
      },
      {
        nume: 'Scoarţa',
        simplu: 'Scoarta',
        comuna: 'Garda De Sus'
      },
      {
        nume: 'Snide',
        comuna: 'Garda De Sus'
      },
      {
        nume: 'Suceşti',
        simplu: 'Sucesti',
        comuna: 'Garda De Sus'
      },
      {
        nume: 'Silivaş',
        simplu: 'Silivas',
        comuna: 'Hoparta'
      },
      {
        nume: 'Turdaş',
        simplu: 'Turdas',
        comuna: 'Hoparta'
      },
      {
        nume: 'Vama Seacă',
        simplu: 'Vama Seaca',
        comuna: 'Hoparta'
      },
      {
        nume: 'Spălnaca',
        simplu: 'Spalnaca',
        comuna: 'Hoparta'
      },
      {
        nume: 'Baba',
        comuna: 'Horea'
      },
      {
        nume: 'Buteşti',
        simplu: 'Butesti',
        comuna: 'Horea'
      },
      {
        nume: 'Dârleşti',
        simplu: 'Darlesti',
        comuna: 'Horea'
      },
      {
        nume: 'Fericet',
        comuna: 'Horea'
      },
      {
        nume: 'Giurgiuţ',
        simplu: 'Giurgiut',
        comuna: 'Horea'
      },
      {
        nume: 'Mănceşti',
        simplu: 'Mancesti',
        comuna: 'Horea'
      },
      {
        nume: 'Mătişeşti',
        simplu: 'Matisesti',
        comuna: 'Horea'
      },
      {
        nume: 'Niculeşti',
        simplu: 'Niculesti',
        comuna: 'Horea'
      },
      {
        nume: 'Petreasa',
        comuna: 'Horea'
      },
      {
        nume: 'Preluca',
        comuna: 'Horea'
      },
      {
        nume: 'Pătruşeşti',
        simplu: 'Patrusesti',
        comuna: 'Horea'
      },
      {
        nume: 'Teiu',
        comuna: 'Horea'
      },
      {
        nume: 'Trifeşti',
        simplu: 'Trifesti',
        comuna: 'Horea'
      },
      {
        nume: 'Zânzeşti',
        simplu: 'Zanzesti',
        comuna: 'Horea'
      },
      {
        nume: 'Bucerdea Vânoasă',
        simplu: 'Bucerdea Vanoasa',
        comuna: 'Ighiu'
      },
      {
        nume: 'Ighiel',
        comuna: 'Ighiu'
      },
      {
        nume: 'Sard',
        simplu: 'Sard',
        comuna: 'Ighiu'
      },
      {
        nume: 'Telna',
        simplu: 'Telna',
        comuna: 'Ighiu'
      },
      {
        nume: 'Dealu Geoagiului',
        comuna: 'Intregalde'
      },
      {
        nume: 'Ghioncani',
        comuna: 'Intregalde'
      },
      {
        nume: 'Ilieşti',
        simplu: 'Iliesti',
        comuna: 'Intregalde'
      },
      {
        nume: 'Ivăniş',
        simplu: 'Ivanis',
        comuna: 'Intregalde'
      },
      {
        nume: 'Modoleşti',
        simplu: 'Modolesti',
        comuna: 'Intregalde'
      },
      {
        nume: 'Mărineşti',
        simplu: 'Marinesti',
        comuna: 'Intregalde'
      },
      {
        nume: 'Necrileşti',
        simplu: 'Necrilesti',
        comuna: 'Intregalde'
      },
      {
        nume: 'Popeşti',
        simplu: 'Popesti',
        comuna: 'Intregalde'
      },
      {
        nume: 'Sfârcea',
        simplu: 'Sfarcea',
        comuna: 'Intregalde'
      },
      {
        nume: 'Tecşeşti',
        simplu: 'Tecsesti',
        comuna: 'Intregalde'
      },
      {
        nume: 'Bălcaciu',
        simplu: 'Balcaciu',
        comuna: 'Jidvei'
      },
      {
        nume: 'Căpâlna de Jos',
        simplu: 'Capalna de Jos',
        comuna: 'Jidvei'
      },
      {
        nume: 'Feisa',
        comuna: 'Jidvei'
      },
      {
        nume: 'Veseuş',
        simplu: 'Veseus',
        comuna: 'Jidvei'
      },
      {
        nume: 'Izvoarele',
        comuna: 'Livezile'
      },
      {
        nume: 'Poiana Aiudului',
        comuna: 'Livezile'
      },
      {
        nume: 'Vălişoara',
        simplu: 'Valisoara',
        comuna: 'Livezile'
      },
      {
        nume: 'Asinip',
        comuna: 'Lopadea Noua'
      },
      {
        nume: 'Beţa',
        simplu: 'Beta',
        comuna: 'Lopadea Noua'
      },
      {
        nume: 'Băgău',
        simplu: 'Bagau',
        comuna: 'Lopadea Noua'
      },
      {
        nume: 'Cicârd',
        simplu: 'Cicard',
        comuna: 'Lopadea Noua'
      },
      {
        nume: 'Ciuguzel',
        comuna: 'Lopadea Noua'
      },
      {
        nume: 'Ocnişoara',
        simplu: 'Ocnisoara',
        comuna: 'Lopadea Noua'
      },
      {
        nume: 'Odverem',
        comuna: 'Lopadea Noua'
      },
      {
        nume: 'Gura Arieşului',
        simplu: 'Gura Ariesului',
        comuna: 'Lunca Muresului'
      },
      {
        nume: 'Bârdeşti',
        simplu: 'Bardesti',
        comuna: 'Lupsa'
      },
      {
        nume: 'Bârzan',
        simplu: 'Barzan',
        comuna: 'Lupsa'
      },
      {
        nume: 'Curmătură',
        simplu: 'Curmatura',
        comuna: 'Lupsa'
      },
      {
        nume: 'După Deal',
        simplu: 'Dupa Deal',
        comuna: 'Lupsa'
      },
      {
        nume: 'Geamăna',
        simplu: 'Geamana',
        comuna: 'Lupsa'
      },
      {
        nume: 'Holobani',
        comuna: 'Lupsa'
      },
      {
        nume: 'Hădărău',
        simplu: 'Hadarau',
        comuna: 'Lupsa'
      },
      {
        nume: 'Lazuri',
        comuna: 'Lupsa'
      },
      {
        nume: 'Lunca',
        comuna: 'Lupsa'
      },
      {
        nume: 'Muşca',
        simplu: 'Musca',
        comuna: 'Lupsa'
      },
      {
        nume: 'Mănăstire',
        simplu: 'Manastire',
        comuna: 'Lupsa'
      },
      {
        nume: 'Mărgaia',
        simplu: 'Margaia',
        comuna: 'Lupsa'
      },
      {
        nume: 'Piţiga',
        simplu: 'Pitiga',
        comuna: 'Lupsa'
      },
      {
        nume: 'Poşogani',
        simplu: 'Posogani',
        comuna: 'Lupsa'
      },
      {
        nume: 'Pârâu-Cărbunări',
        simplu: 'Parau-Carbunari',
        comuna: 'Lupsa'
      },
      {
        nume: 'Trifeşti',
        simplu: 'Trifesti',
        comuna: 'Lupsa'
      },
      {
        nume: 'Valea Holhorii',
        comuna: 'Lupsa'
      },
      {
        nume: 'Valea Lupşii',
        simplu: 'Valea Lupsii',
        comuna: 'Lupsa'
      },
      {
        nume: 'Valea Sesii',
        simplu: 'Valea Sesii',
        comuna: 'Lupsa'
      },
      {
        nume: 'Vinţa',
        simplu: 'Vinta',
        comuna: 'Lupsa'
      },
      {
        nume: 'Văi',
        simplu: 'Vai',
        comuna: 'Lupsa'
      },
      {
        nume: 'Sasa',
        simplu: 'Sasa',
        comuna: 'Lupsa'
      },
      {
        nume: 'Ampoiţa',
        simplu: 'Ampoita',
        comuna: 'Metes'
      },
      {
        nume: 'Isca',
        comuna: 'Metes'
      },
      {
        nume: 'Lunca Ampoiţei',
        simplu: 'Lunca Ampoitei',
        comuna: 'Metes'
      },
      {
        nume: 'Lunca Meteşului',
        simplu: 'Lunca Metesului',
        comuna: 'Metes'
      },
      {
        nume: 'Poiana Ampoiului',
        comuna: 'Metes'
      },
      {
        nume: 'Poiana Ursului',
        comuna: 'Metes'
      },
      {
        nume: 'Presaca Ampoiului',
        comuna: 'Metes'
      },
      {
        nume: 'Pădurea',
        simplu: 'Padurea',
        comuna: 'Metes'
      },
      {
        nume: 'Remetea',
        comuna: 'Metes'
      },
      {
        nume: 'Tăuţi',
        simplu: 'Tauti',
        comuna: 'Metes'
      },
      {
        nume: 'Văleni',
        simplu: 'Valeni',
        comuna: 'Metes'
      },
      {
        nume: 'Cistei',
        comuna: 'Mihalt'
      },
      {
        nume: 'Obreja',
        comuna: 'Mihalt'
      },
      {
        nume: 'Zărieş',
        simplu: 'Zaries',
        comuna: 'Mihalt'
      },
      {
        nume: 'Cicău',
        simplu: 'Cicau',
        comuna: 'Miraslau'
      },
      {
        nume: 'Decea',
        comuna: 'Miraslau'
      },
      {
        nume: 'Lopadea Veche',
        comuna: 'Miraslau'
      },
      {
        nume: 'Ormeniş',
        simplu: 'Ormenis',
        comuna: 'Miraslau'
      },
      {
        nume: 'Rachiş',
        simplu: 'Rachis',
        comuna: 'Miraslau'
      },
      {
        nume: 'Boceşti',
        simplu: 'Bocesti',
        comuna: 'Mogos'
      },
      {
        nume: 'Bogdăneşti',
        simplu: 'Bogdanesti',
        comuna: 'Mogos'
      },
      {
        nume: 'Buteşti',
        simplu: 'Butesti',
        comuna: 'Mogos'
      },
      {
        nume: 'Bârleşti',
        simplu: 'Barlesti',
        comuna: 'Mogos'
      },
      {
        nume: 'Bârleşti-Cătun',
        simplu: 'Barlesti-Catun',
        comuna: 'Mogos'
      },
      {
        nume: 'Bârzogani',
        simplu: 'Barzogani',
        comuna: 'Mogos'
      },
      {
        nume: 'Bărbeşti',
        simplu: 'Barbesti',
        comuna: 'Mogos'
      },
      {
        nume: 'Cojocani',
        comuna: 'Mogos'
      },
      {
        nume: 'Cristeşti',
        simplu: 'Cristesti',
        comuna: 'Mogos'
      },
      {
        nume: 'Mămăligani',
        simplu: 'Mamaligani',
        comuna: 'Mogos'
      },
      {
        nume: 'Negreşti',
        simplu: 'Negresti',
        comuna: 'Mogos'
      },
      {
        nume: 'Onceşti',
        simplu: 'Oncesti',
        comuna: 'Mogos'
      },
      {
        nume: 'Poienile-Mogoş',
        simplu: 'Poienile-Mogos',
        comuna: 'Mogos'
      },
      {
        nume: 'Tomeşti',
        simplu: 'Tomesti',
        comuna: 'Mogos'
      },
      {
        nume: 'Valea Barnii',
        comuna: 'Mogos'
      },
      {
        nume: 'Valea Bârluţeşti',
        simplu: 'Valea Barlutesti',
        comuna: 'Mogos'
      },
      {
        nume: 'Valea Coceşti',
        simplu: 'Valea Cocesti',
        comuna: 'Mogos'
      },
      {
        nume: 'Valea Giogeşti',
        simplu: 'Valea Giogesti',
        comuna: 'Mogos'
      },
      {
        nume: 'Valea Mlacii',
        comuna: 'Mogos'
      },
      {
        nume: 'Valea Tupilor',
        simplu: 'Valea Tupilor',
        comuna: 'Mogos'
      },
      {
        nume: 'Copand',
        comuna: 'Noslac'
      },
      {
        nume: 'Căptălan',
        simplu: 'Captalan',
        comuna: 'Noslac'
      },
      {
        nume: 'Găbud',
        simplu: 'Gabud',
        comuna: 'Noslac'
      },
      {
        nume: 'Stâna de Mureş',
        simplu: 'Stana de Mures',
        comuna: 'Noslac'
      },
      {
        nume: 'Valea Ciuciului',
        comuna: 'Noslac'
      },
      {
        nume: 'Cisteiu de Mureş',
        simplu: 'Cisteiu de Mures',
        comuna: 'Ocna Mures'
      },
      {
        nume: 'Micoşlaca',
        simplu: 'Micoslaca',
        comuna: 'Ocna Mures'
      },
      {
        nume: 'Războieni-Cetate',
        simplu: 'Razboieni-Cetate',
        comuna: 'Ocna Mures'
      },
      {
        nume: 'Uioara de Jos',
        comuna: 'Ocna Mures'
      },
      {
        nume: 'Uioara de Sus',
        comuna: 'Ocna Mures'
      },
      {
        nume: 'Lunca Largă',
        simplu: 'Lunca Larga',
        comuna: 'Ocolis'
      },
      {
        nume: 'Runc',
        comuna: 'Ocolis'
      },
      {
        nume: 'Vidolm',
        comuna: 'Ocolis'
      },
      {
        nume: 'Colibi',
        comuna: 'Ohaba'
      },
      {
        nume: 'Măghierat',
        simplu: 'Maghierat',
        comuna: 'Ohaba'
      },
      {
        nume: 'Secăşel',
        simplu: 'Secasel',
        comuna: 'Ohaba'
      },
      {
        nume: 'Pianu de Jos',
        comuna: 'Pianu'
      },
      {
        nume: 'Pianu de Sus',
        comuna: 'Pianu'
      },
      {
        nume: 'Plaiuri',
        comuna: 'Pianu'
      },
      {
        nume: 'Purcăreţi',
        simplu: 'Purcareti',
        comuna: 'Pianu'
      },
      {
        nume: 'Strungari',
        comuna: 'Pianu'
      },
      {
        nume: 'Costeşti',
        simplu: 'Costesti',
        comuna: 'Poiana Vadului'
      },
      {
        nume: 'Duduieni',
        comuna: 'Poiana Vadului'
      },
      {
        nume: 'Făgetu de Jos',
        simplu: 'Fagetu de Jos',
        comuna: 'Poiana Vadului'
      },
      {
        nume: 'Făgetu de Sus',
        simplu: 'Fagetu de Sus',
        comuna: 'Poiana Vadului'
      },
      {
        nume: 'Hănăşeşti',
        simplu: 'Hanasesti',
        comuna: 'Poiana Vadului'
      },
      {
        nume: 'Lupăieşti',
        simplu: 'Lupaiesti',
        comuna: 'Poiana Vadului'
      },
      {
        nume: 'Morcăneşti',
        simplu: 'Morcanesti',
        comuna: 'Poiana Vadului'
      },
      {
        nume: 'Petelei',
        comuna: 'Poiana Vadului'
      },
      {
        nume: 'Păşteşti',
        simplu: 'Pastesti',
        comuna: 'Poiana Vadului'
      },
      {
        nume: 'Stăneşti',
        simplu: 'Stanesti',
        comuna: 'Poiana Vadului'
      },
      {
        nume: 'Dupa Deal',
        comuna: 'Ponor'
      },
      {
        nume: 'Geogel',
        comuna: 'Ponor'
      },
      {
        nume: 'Măcăreşti',
        simplu: 'Macaresti',
        comuna: 'Ponor'
      },
      {
        nume: 'Vale In Jos',
        comuna: 'Ponor'
      },
      {
        nume: 'Valea Bucurului',
        comuna: 'Ponor'
      },
      {
        nume: 'Corţeşti',
        simplu: 'Cortesti',
        comuna: 'Posaga'
      },
      {
        nume: 'Inceşti',
        simplu: 'Incesti',
        comuna: 'Posaga'
      },
      {
        nume: 'Lunca',
        comuna: 'Posaga'
      },
      {
        nume: 'Orăşti',
        simplu: 'Orasti',
        comuna: 'Posaga'
      },
      {
        nume: 'Poşaga de Jos',
        simplu: 'Posaga de Jos',
        comuna: 'Posaga'
      },
      {
        nume: 'Poşaga de Sus',
        simplu: 'Posaga de Sus',
        comuna: 'Posaga'
      },
      {
        nume: 'Sagagea',
        comuna: 'Posaga'
      },
      {
        nume: 'Leorinţ',
        simplu: 'Leorint',
        comuna: 'Radesti'
      },
      {
        nume: 'Meşcreac',
        simplu: 'Mescreac',
        comuna: 'Radesti'
      },
      {
        nume: 'Soimuş',
        simplu: 'Soimus',
        comuna: 'Radesti'
      },
      {
        nume: 'Boţani',
        simplu: 'Botani',
        comuna: 'Ramet'
      },
      {
        nume: 'Brădeşti',
        simplu: 'Bradesti',
        comuna: 'Ramet'
      },
      {
        nume: 'Cheia',
        comuna: 'Ramet'
      },
      {
        nume: 'Cotorăşti',
        simplu: 'Cotorasti',
        comuna: 'Ramet'
      },
      {
        nume: 'Floreşti',
        simplu: 'Floresti',
        comuna: 'Ramet'
      },
      {
        nume: 'Olteni',
        comuna: 'Ramet'
      },
      {
        nume: 'Valea Făgetului',
        simplu: 'Valea Fagetului',
        comuna: 'Ramet'
      },
      {
        nume: 'Valea Inzelului',
        comuna: 'Ramet'
      },
      {
        nume: 'Valea Mănăstirii',
        simplu: 'Valea Manastirii',
        comuna: 'Ramet'
      },
      {
        nume: 'Valea Poienii',
        comuna: 'Ramet'
      },
      {
        nume: 'Valea Uzei',
        comuna: 'Ramet'
      },
      {
        nume: 'Vlădeşti',
        simplu: 'Vladesti',
        comuna: 'Ramet'
      },
      {
        nume: 'Colţeşti',
        simplu: 'Coltesti',
        comuna: 'Rametea'
      },
      {
        nume: 'Tău',
        simplu: 'Tau',
        comuna: 'Rosia De Secas'
      },
      {
        nume: 'Ungurei',
        comuna: 'Rosia De Secas'
      },
      {
        nume: 'Blideşti',
        simplu: 'Blidesti',
        comuna: 'Rosia Montana'
      },
      {
        nume: 'Bunta',
        comuna: 'Rosia Montana'
      },
      {
        nume: 'Bălmoşeşti',
        simplu: 'Balmosesti',
        comuna: 'Rosia Montana'
      },
      {
        nume: 'Coasta Henţii',
        simplu: 'Coasta Hentii',
        comuna: 'Rosia Montana'
      },
      {
        nume: 'Corna',
        comuna: 'Rosia Montana'
      },
      {
        nume: 'Curături',
        simplu: 'Curaturi',
        comuna: 'Rosia Montana'
      },
      {
        nume: 'Cărpiniş',
        simplu: 'Carpinis',
        comuna: 'Rosia Montana'
      },
      {
        nume: 'Dăroaia',
        simplu: 'Daroaia',
        comuna: 'Rosia Montana'
      },
      {
        nume: 'Gura Roşiei',
        simplu: 'Gura Rosiei',
        comuna: 'Rosia Montana'
      },
      {
        nume: 'Gârda-Bărbuleşti',
        simplu: 'Garda-Barbulesti',
        comuna: 'Rosia Montana'
      },
      {
        nume: 'Iacobeşti',
        simplu: 'Iacobesti',
        comuna: 'Rosia Montana'
      },
      {
        nume: 'Ignăţeşti',
        simplu: 'Ignatesti',
        comuna: 'Rosia Montana'
      },
      {
        nume: 'Vârtop',
        simplu: 'Vartop',
        comuna: 'Rosia Montana'
      },
      {
        nume: 'Soal',
        simplu: 'Soal',
        comuna: 'Rosia Montana'
      },
      {
        nume: 'Tarina',
        simplu: 'Tarina',
        comuna: 'Rosia Montana'
      },
      {
        nume: 'Dealu Caselor',
        comuna: 'Salciua'
      },
      {
        nume: 'Dumeşti',
        simplu: 'Dumesti',
        comuna: 'Salciua'
      },
      {
        nume: 'Sub Piatră',
        simplu: 'Sub Piatra',
        comuna: 'Salciua'
      },
      {
        nume: 'Sălciua de Jos',
        simplu: 'Salciua de Jos',
        comuna: 'Salciua'
      },
      {
        nume: 'Sălciua de Sus',
        simplu: 'Salciua de Sus',
        comuna: 'Salciua'
      },
      {
        nume: 'Valea Largă',
        simplu: 'Valea Larga',
        comuna: 'Salciua'
      },
      {
        nume: 'Mărgineni',
        simplu: 'Margineni',
        comuna: 'Salistea'
      },
      {
        nume: 'Săliştea-Deal',
        simplu: 'Salistea-Deal',
        comuna: 'Salistea'
      },
      {
        nume: 'Tărtăria',
        simplu: 'Tartaria',
        comuna: 'Salistea'
      },
      {
        nume: 'Iclod',
        comuna: 'Sancel'
      },
      {
        nume: 'Pănade',
        simplu: 'Panade',
        comuna: 'Sancel'
      },
      {
        nume: 'Coşlariu',
        simplu: 'Coslariu',
        comuna: 'Santimbru'
      },
      {
        nume: 'Dumitra',
        comuna: 'Santimbru'
      },
      {
        nume: 'Galtiu',
        comuna: 'Santimbru'
      },
      {
        nume: 'Totoi',
        comuna: 'Santimbru'
      },
      {
        nume: 'Căpâlna',
        simplu: 'Capalna',
        comuna: 'Sasciori'
      },
      {
        nume: 'Dumbrava',
        comuna: 'Sasciori'
      },
      {
        nume: 'Laz',
        comuna: 'Sasciori'
      },
      {
        nume: 'Loman',
        comuna: 'Sasciori'
      },
      {
        nume: 'Pleşi',
        simplu: 'Plesi',
        comuna: 'Sasciori'
      },
      {
        nume: 'Răchita',
        simplu: 'Rachita',
        comuna: 'Sasciori'
      },
      {
        nume: 'Sebeşel',
        simplu: 'Sebesel',
        comuna: 'Sasciori'
      },
      {
        nume: 'Tonea',
        comuna: 'Sasciori'
      },
      {
        nume: 'Boteşti',
        simplu: 'Botesti',
        comuna: 'Scarisoara'
      },
      {
        nume: 'Bârleşti',
        simplu: 'Barlesti',
        comuna: 'Scarisoara'
      },
      {
        nume: 'Faţa-Lăzeşti',
        simplu: 'Fata-Lazesti',
        comuna: 'Scarisoara'
      },
      {
        nume: 'Floreşti',
        simplu: 'Floresti',
        comuna: 'Scarisoara'
      },
      {
        nume: 'Lespezea',
        comuna: 'Scarisoara'
      },
      {
        nume: 'Lăzeşti',
        simplu: 'Lazesti',
        comuna: 'Scarisoara'
      },
      {
        nume: 'Maţei',
        simplu: 'Matei',
        comuna: 'Scarisoara'
      },
      {
        nume: 'Negeşti',
        simplu: 'Negesti',
        comuna: 'Scarisoara'
      },
      {
        nume: 'Prelucă',
        simplu: 'Preluca',
        comuna: 'Scarisoara'
      },
      {
        nume: 'Runc',
        comuna: 'Scarisoara'
      },
      {
        nume: 'Sfoartea',
        comuna: 'Scarisoara'
      },
      {
        nume: 'Trânceşti',
        simplu: 'Trancesti',
        comuna: 'Scarisoara'
      },
      {
        nume: 'Stiuleţi',
        simplu: 'Stiuleti',
        comuna: 'Scarisoara'
      },
      {
        nume: 'Lancrăm',
        simplu: 'Lancram',
        comuna: 'Sebes'
      },
      {
        nume: 'Petreşti',
        simplu: 'Petresti',
        comuna: 'Sebes'
      },
      {
        nume: 'Răhău',
        simplu: 'Rahau',
        comuna: 'Sebes'
      },
      {
        nume: 'Balomiru de Câmp',
        simplu: 'Balomiru de Camp',
        comuna: 'Sibot'
      },
      {
        nume: 'Băcăinţi',
        simplu: 'Bacainti',
        comuna: 'Sibot'
      },
      {
        nume: 'Sărăcsău',
        simplu: 'Saracsau',
        comuna: 'Sibot'
      },
      {
        nume: 'Bilăneşti',
        simplu: 'Bilanesti',
        comuna: 'Sohodol'
      },
      {
        nume: 'Bobăreşti',
        simplu: 'Bobaresti',
        comuna: 'Sohodol'
      },
      {
        nume: 'Brădeana',
        simplu: 'Bradeana',
        comuna: 'Sohodol'
      },
      {
        nume: 'Burzoneşti',
        simplu: 'Burzonesti',
        comuna: 'Sohodol'
      },
      {
        nume: 'Băzeşti',
        simplu: 'Bazesti',
        comuna: 'Sohodol'
      },
      {
        nume: 'Deonceşti',
        simplu: 'Deoncesti',
        comuna: 'Sohodol'
      },
      {
        nume: 'Dilimani',
        comuna: 'Sohodol'
      },
      {
        nume: 'Furduieşti',
        simplu: 'Furduiesti',
        comuna: 'Sohodol'
      },
      {
        nume: 'Gura Sohodol',
        comuna: 'Sohodol'
      },
      {
        nume: 'Hoancă',
        simplu: 'Hoanca',
        comuna: 'Sohodol'
      },
      {
        nume: 'Joldişeşti',
        simplu: 'Joldisesti',
        comuna: 'Sohodol'
      },
      {
        nume: 'Lazuri',
        comuna: 'Sohodol'
      },
      {
        nume: 'Leheşti',
        simplu: 'Lehesti',
        comuna: 'Sohodol'
      },
      {
        nume: 'Lumineşti',
        simplu: 'Luminesti',
        comuna: 'Sohodol'
      },
      {
        nume: 'Medreşti',
        simplu: 'Medresti',
        comuna: 'Sohodol'
      },
      {
        nume: 'Morăreşti',
        simplu: 'Moraresti',
        comuna: 'Sohodol'
      },
      {
        nume: 'Muneşti',
        simplu: 'Munesti',
        comuna: 'Sohodol'
      },
      {
        nume: 'Nelegeşti',
        simplu: 'Nelegesti',
        comuna: 'Sohodol'
      },
      {
        nume: 'Nicoreşti',
        simplu: 'Nicoresti',
        comuna: 'Sohodol'
      },
      {
        nume: 'Năpăieşti',
        simplu: 'Napaiesti',
        comuna: 'Sohodol'
      },
      {
        nume: 'Peleş',
        simplu: 'Peles',
        comuna: 'Sohodol'
      },
      {
        nume: 'Poiana',
        comuna: 'Sohodol'
      },
      {
        nume: 'Robeşti',
        simplu: 'Robesti',
        comuna: 'Sohodol'
      },
      {
        nume: 'Sebişeşti',
        simplu: 'Sebisesti',
        comuna: 'Sohodol'
      },
      {
        nume: 'Sicoieşti',
        simplu: 'Sicoiesti',
        comuna: 'Sohodol'
      },
      {
        nume: 'Surdeşti',
        simplu: 'Surdesti',
        comuna: 'Sohodol'
      },
      {
        nume: 'Toci',
        comuna: 'Sohodol'
      },
      {
        nume: 'Valea Verde',
        comuna: 'Sohodol'
      },
      {
        nume: 'Vlădoşeşti',
        simplu: 'Vladosesti',
        comuna: 'Sohodol'
      },
      {
        nume: 'Simoceşti',
        simplu: 'Simocesti',
        comuna: 'Sohodol'
      },
      {
        nume: 'Alecuş',
        simplu: 'Alecus',
        comuna: 'Sona'
      },
      {
        nume: 'Biia',
        comuna: 'Sona'
      },
      {
        nume: 'Doptău',
        simplu: 'Doptau',
        comuna: 'Sona'
      },
      {
        nume: 'Lunca Târnavei',
        simplu: 'Lunca Tarnavei',
        comuna: 'Sona'
      },
      {
        nume: 'Sânmiclăuş',
        simplu: 'Sanmiclaus',
        comuna: 'Sona'
      },
      {
        nume: 'Valea Sasului',
        comuna: 'Sona'
      },
      {
        nume: 'Carpen',
        comuna: 'Spring'
      },
      {
        nume: 'Carpenii de Sus',
        comuna: 'Spring'
      },
      {
        nume: 'Cunţa',
        simplu: 'Cunta',
        comuna: 'Spring'
      },
      {
        nume: 'Draşov',
        simplu: 'Drasov',
        comuna: 'Spring'
      },
      {
        nume: 'Vingard',
        comuna: 'Spring'
      },
      {
        nume: 'Faţa Pietrii',
        simplu: 'Fata Pietrii',
        comuna: 'Stremt'
      },
      {
        nume: 'Geoagiu de Sus',
        comuna: 'Stremt'
      },
      {
        nume: 'Geomal',
        comuna: 'Stremt'
      },
      {
        nume: 'Arţi',
        simplu: 'Arti',
        comuna: 'Sugag'
      },
      {
        nume: 'Bârsana',
        simplu: 'Barsana',
        comuna: 'Sugag'
      },
      {
        nume: 'Dobra',
        comuna: 'Sugag'
      },
      {
        nume: 'Jidoştina',
        simplu: 'Jidostina',
        comuna: 'Sugag'
      },
      {
        nume: 'Mărtinie',
        simplu: 'Martinie',
        comuna: 'Sugag'
      },
      {
        nume: 'Tău Bistra',
        simplu: 'Tau Bistra',
        comuna: 'Sugag'
      },
      {
        nume: 'Beldiu',
        comuna: 'Teius'
      },
      {
        nume: 'Coşlariu Nou',
        simplu: 'Coslariu Nou',
        comuna: 'Teius'
      },
      {
        nume: 'Căpud',
        simplu: 'Capud',
        comuna: 'Teius'
      },
      {
        nume: 'Peţelca',
        simplu: 'Petelca',
        comuna: 'Teius'
      },
      {
        nume: 'Ciugudu de Jos',
        comuna: 'Unirea'
      },
      {
        nume: 'Ciugudu de Sus',
        comuna: 'Unirea'
      },
      {
        nume: 'Dumbrava',
        comuna: 'Unirea'
      },
      {
        nume: 'Inoc',
        comuna: 'Unirea'
      },
      {
        nume: 'Măhăceni',
        simplu: 'Mahaceni',
        comuna: 'Unirea'
      },
      {
        nume: 'Bodeşti',
        simplu: 'Bodesti',
        comuna: 'Vadu Motilor'
      },
      {
        nume: 'Burzeşti',
        simplu: 'Burzesti',
        comuna: 'Vadu Motilor'
      },
      {
        nume: 'Dealu Frumos',
        comuna: 'Vadu Motilor'
      },
      {
        nume: 'Lăzeşti',
        simplu: 'Lazesti',
        comuna: 'Vadu Motilor'
      },
      {
        nume: 'Necşeşti',
        simplu: 'Necsesti',
        comuna: 'Vadu Motilor'
      },
      {
        nume: 'Poduri-Briceşti',
        simplu: 'Poduri-Bricesti',
        comuna: 'Vadu Motilor'
      },
      {
        nume: 'Popeştii de Jos',
        simplu: 'Popestii de Jos',
        comuna: 'Vadu Motilor'
      },
      {
        nume: 'Popeştii de Sus',
        simplu: 'Popestii de Sus',
        comuna: 'Vadu Motilor'
      },
      {
        nume: 'Tomuţeşti',
        simplu: 'Tomutesti',
        comuna: 'Vadu Motilor'
      },
      {
        nume: 'Toţeşti',
        simplu: 'Totesti',
        comuna: 'Vadu Motilor'
      },
      {
        nume: 'Vâltori',
        simplu: 'Valtori',
        comuna: 'Vadu Motilor'
      },
      {
        nume: 'Făget',
        simplu: 'Faget',
        comuna: 'Valea Lunga'
      },
      {
        nume: 'Glogoveţ',
        simplu: 'Glogovet',
        comuna: 'Valea Lunga'
      },
      {
        nume: 'Lodroman',
        comuna: 'Valea Lunga'
      },
      {
        nume: 'Lunca',
        comuna: 'Valea Lunga'
      },
      {
        nume: 'Tăuni',
        simplu: 'Tauni',
        comuna: 'Valea Lunga'
      },
      {
        nume: 'Bobăreşti',
        simplu: 'Bobaresti',
        comuna: 'Vidra'
      },
      {
        nume: 'Bogdăneşti',
        simplu: 'Bogdanesti',
        comuna: 'Vidra'
      },
      {
        nume: 'Bordeştii Poieni',
        simplu: 'Bordestii Poieni',
        comuna: 'Vidra'
      },
      {
        nume: 'Băi',
        simplu: 'Bai',
        comuna: 'Vidra'
      },
      {
        nume: 'Culdeşti',
        simplu: 'Culdesti',
        comuna: 'Vidra'
      },
      {
        nume: 'Dealu Goieşti',
        simplu: 'Dealu Goiesti',
        comuna: 'Vidra'
      },
      {
        nume: 'Dos',
        comuna: 'Vidra'
      },
      {
        nume: 'Dosu Luncii',
        comuna: 'Vidra'
      },
      {
        nume: 'Dosu Văseşti',
        simplu: 'Dosu Vasesti',
        comuna: 'Vidra'
      },
      {
        nume: 'Drăgoieşti-Luncă',
        simplu: 'Dragoiesti-Lunca',
        comuna: 'Vidra'
      },
      {
        nume: 'Ficăreşti',
        simplu: 'Ficaresti',
        comuna: 'Vidra'
      },
      {
        nume: 'Gligoreşti',
        simplu: 'Gligoresti',
        comuna: 'Vidra'
      },
      {
        nume: 'Goieşti',
        simplu: 'Goiesti',
        comuna: 'Vidra'
      },
      {
        nume: 'Haiduceşti',
        simplu: 'Haiducesti',
        comuna: 'Vidra'
      },
      {
        nume: 'Hoancă',
        simplu: 'Hoanca',
        comuna: 'Vidra'
      },
      {
        nume: 'Hărăşti',
        simplu: 'Harasti',
        comuna: 'Vidra'
      },
      {
        nume: 'Jefleşti',
        simplu: 'Jeflesti',
        comuna: 'Vidra'
      },
      {
        nume: 'Lunca',
        comuna: 'Vidra'
      },
      {
        nume: 'Lunca Bisericii',
        comuna: 'Vidra'
      },
      {
        nume: 'Lunca Goieşti',
        simplu: 'Lunca Goiesti',
        comuna: 'Vidra'
      },
      {
        nume: 'Lunca Veseşti',
        simplu: 'Lunca Vesesti',
        comuna: 'Vidra'
      },
      {
        nume: 'Lunca de Jos',
        comuna: 'Vidra'
      },
      {
        nume: 'Modoleşti',
        simplu: 'Modolesti',
        comuna: 'Vidra'
      },
      {
        nume: 'Nemeşi',
        simplu: 'Nemesi',
        comuna: 'Vidra'
      },
      {
        nume: 'Oideşti',
        simplu: 'Oidesti',
        comuna: 'Vidra'
      },
      {
        nume: 'Pitărceşti',
        simplu: 'Pitarcesti',
        comuna: 'Vidra'
      },
      {
        nume: 'Pleşcuţa',
        simplu: 'Plescuta',
        comuna: 'Vidra'
      },
      {
        nume: 'Poieni',
        comuna: 'Vidra'
      },
      {
        nume: 'Ponorel',
        comuna: 'Vidra'
      },
      {
        nume: 'Puiuleţeşti',
        simplu: 'Puiuletesti',
        comuna: 'Vidra'
      },
      {
        nume: 'Runc',
        comuna: 'Vidra'
      },
      {
        nume: 'Segaj',
        comuna: 'Vidra'
      },
      {
        nume: 'Urdeş',
        simplu: 'Urdes',
        comuna: 'Vidra'
      },
      {
        nume: 'Valea Morii',
        comuna: 'Vidra'
      },
      {
        nume: 'Vâlceşti',
        simplu: 'Valcesti',
        comuna: 'Vidra'
      },
      {
        nume: 'Vâlcăneasa',
        simplu: 'Valcaneasa',
        comuna: 'Vidra'
      },
      {
        nume: 'Vârtăneşti',
        simplu: 'Vartanesti',
        comuna: 'Vidra'
      },
      {
        nume: 'Văseşti',
        simplu: 'Vasesti',
        comuna: 'Vidra'
      },
      {
        nume: 'Ciocaşu',
        simplu: 'Ciocasu',
        comuna: 'Vintu De Jos'
      },
      {
        nume: 'Crişeni',
        simplu: 'Criseni',
        comuna: 'Vintu De Jos'
      },
      {
        nume: 'Câmpu Goblii',
        simplu: 'Campu Goblii',
        comuna: 'Vintu De Jos'
      },
      {
        nume: 'Dealu Ferului',
        comuna: 'Vintu De Jos'
      },
      {
        nume: 'Gura Cuţului',
        simplu: 'Gura Cutului',
        comuna: 'Vintu De Jos'
      },
      {
        nume: 'Haţegana',
        simplu: 'Hategana',
        comuna: 'Vintu De Jos'
      },
      {
        nume: 'Inuri',
        comuna: 'Vintu De Jos'
      },
      {
        nume: 'Laz',
        comuna: 'Vintu De Jos'
      },
      {
        nume: 'Mereteu',
        comuna: 'Vintu De Jos'
      },
      {
        nume: 'Mătăcina',
        simplu: 'Matacina',
        comuna: 'Vintu De Jos'
      },
      {
        nume: 'Poieniţa',
        simplu: 'Poienita',
        comuna: 'Vintu De Jos'
      },
      {
        nume: 'Pârău lui Mihai',
        simplu: 'Parau lui Mihai',
        comuna: 'Vintu De Jos'
      },
      {
        nume: 'Stăuini',
        simplu: 'Stauini',
        comuna: 'Vintu De Jos'
      },
      {
        nume: 'Valea Goblii',
        comuna: 'Vintu De Jos'
      },
      {
        nume: 'Valea Vinţului',
        simplu: 'Valea Vintului',
        comuna: 'Vintu De Jos'
      },
      {
        nume: 'Valea lui Mihai',
        comuna: 'Vintu De Jos'
      },
      {
        nume: 'Vurpăr',
        simplu: 'Vurpar',
        comuna: 'Vintu De Jos'
      },
      {
        nume: 'Boteşti',
        simplu: 'Botesti',
        comuna: 'Zlatna'
      },
      {
        nume: 'Budeni',
        comuna: 'Zlatna'
      },
      {
        nume: 'Dealu Roatei',
        comuna: 'Zlatna'
      },
      {
        nume: 'Dobrot',
        comuna: 'Zlatna'
      },
      {
        nume: 'Dumbrava',
        comuna: 'Zlatna'
      },
      {
        nume: 'Feneş',
        simplu: 'Fenes',
        comuna: 'Zlatna'
      },
      {
        nume: 'Galaţi',
        simplu: 'Galati',
        comuna: 'Zlatna'
      },
      {
        nume: 'Izvoru Ampoiului',
        comuna: 'Zlatna'
      },
      {
        nume: 'Pirita',
        comuna: 'Zlatna'
      },
      {
        nume: 'Podu lui Paul',
        comuna: 'Zlatna'
      },
      {
        nume: 'Pârau Gruiului',
        simplu: 'Parau Gruiului',
        comuna: 'Zlatna'
      },
      {
        nume: 'Pătrângeni',
        simplu: 'Patrangeni',
        comuna: 'Zlatna'
      },
      {
        nume: 'Runc',
        comuna: 'Zlatna'
      },
      {
        nume: 'Ruşi',
        simplu: 'Rusi',
        comuna: 'Zlatna'
      },
      {
        nume: 'Suseni',
        comuna: 'Zlatna'
      },
      {
        nume: 'Trâmpoiele',
        simplu: 'Trampoiele',
        comuna: 'Zlatna'
      },
      {
        nume: 'Valea Mică',
        simplu: 'Valea Mica',
        comuna: 'Zlatna'
      },
      {
        nume: 'Vâltori',
        simplu: 'Valtori',
        comuna: 'Zlatna'
      }
    ]
  },
  {
    auto: 'AR',
    nume: 'Arad',
    localitati: [
      {
        nume: 'Almaş',
        simplu: 'Almas'
      },
      {
        nume: 'Apateu'
      },
      {
        nume: 'Arad'
      },
      {
        nume: 'Archiş',
        simplu: 'Archis'
      },
      {
        nume: 'Bata'
      },
      {
        nume: 'Beliu'
      },
      {
        nume: 'Birchiş',
        simplu: 'Birchis'
      },
      {
        nume: 'Bocsig'
      },
      {
        nume: 'Brazii'
      },
      {
        nume: 'Buteni'
      },
      {
        nume: 'Bârsa',
        simplu: 'Barsa'
      },
      {
        nume: 'Bârzava',
        simplu: 'Barzava'
      },
      {
        nume: 'Cermei'
      },
      {
        nume: 'Chisindia'
      },
      {
        nume: 'Chişineu-Criş',
        simplu: 'Chisineu-Cris'
      },
      {
        nume: 'Conop'
      },
      {
        nume: 'Covăsinţ',
        simplu: 'Covasint'
      },
      {
        nume: 'Craiva'
      },
      {
        nume: 'Curtici'
      },
      {
        nume: 'Cărand',
        simplu: 'Carand'
      },
      {
        nume: 'Dezna'
      },
      {
        nume: 'Dieci'
      },
      {
        nume: 'Dorgoş',
        simplu: 'Dorgos'
      },
      {
        nume: 'Felnac'
      },
      {
        nume: 'Ghioroc'
      },
      {
        nume: 'Grăniceri',
        simplu: 'Graniceri'
      },
      {
        nume: 'Gurahonţ',
        simplu: 'Gurahont'
      },
      {
        nume: 'Hălmagiu',
        simplu: 'Halmagiu'
      },
      {
        nume: 'Hălmăgel',
        simplu: 'Halmagel'
      },
      {
        nume: 'Hăşmaş',
        simplu: 'Hasmas'
      },
      {
        nume: 'Igneşti',
        simplu: 'Ignesti'
      },
      {
        nume: 'Ineu'
      },
      {
        nume: 'Iratoşu',
        simplu: 'Iratosu'
      },
      {
        nume: 'Lipova'
      },
      {
        nume: 'Livada'
      },
      {
        nume: 'Macea'
      },
      {
        nume: 'Mişca',
        simplu: 'Misca'
      },
      {
        nume: 'Moneasa'
      },
      {
        nume: 'Nădlac',
        simplu: 'Nadlac'
      },
      {
        nume: 'Olari'
      },
      {
        nume: 'Pecica'
      },
      {
        nume: 'Peregu Mare'
      },
      {
        nume: 'Petriş',
        simplu: 'Petris'
      },
      {
        nume: 'Pilu'
      },
      {
        nume: 'Pleşcuţa',
        simplu: 'Plescuta'
      },
      {
        nume: 'Pâncota',
        simplu: 'Pancota'
      },
      {
        nume: 'Păuliş',
        simplu: 'Paulis'
      },
      {
        nume: 'Sebiş',
        simplu: 'Sebis'
      },
      {
        nume: 'Secusigiu'
      },
      {
        nume: 'Seleuş',
        simplu: 'Seleus'
      },
      {
        nume: 'Semlac'
      },
      {
        nume: 'Sintea Mare'
      },
      {
        nume: 'Socodor'
      },
      {
        nume: 'Sântana',
        simplu: 'Santana'
      },
      {
        nume: 'Săvârşin',
        simplu: 'Savarsin'
      },
      {
        nume: 'Tauţ',
        simplu: 'Taut'
      },
      {
        nume: 'Târnova',
        simplu: 'Tarnova'
      },
      {
        nume: 'Vinga'
      },
      {
        nume: 'Vladimirescu'
      },
      {
        nume: 'Vârfurile',
        simplu: 'Varfurile'
      },
      {
        nume: 'Vărădia de Mureş',
        simplu: 'Varadia de Mures'
      },
      {
        nume: 'Zerind'
      },
      {
        nume: 'Zimandu Nou'
      },
      {
        nume: 'Zăbrani',
        simplu: 'Zabrani'
      },
      {
        nume: 'Zărand',
        simplu: 'Zarand'
      },
      {
        nume: 'Sagu',
        simplu: 'Sagu'
      },
      {
        nume: 'Seitin',
        simplu: 'Seitin'
      },
      {
        nume: 'Sepreuş',
        simplu: 'Sepreus'
      },
      {
        nume: 'Sicula',
        simplu: 'Sicula'
      },
      {
        nume: 'Silindia',
        simplu: 'Silindia'
      },
      {
        nume: 'Simand',
        simplu: 'Simand'
      },
      {
        nume: 'Siria',
        simplu: 'Siria'
      },
      {
        nume: 'Siştarovăţ',
        simplu: 'Sistarovat'
      },
      {
        nume: 'Sofronea',
        simplu: 'Sofronea'
      },
      {
        nume: 'Cil',
        comuna: 'Almas'
      },
      {
        nume: 'Joia Mare',
        comuna: 'Almas'
      },
      {
        nume: 'Rădeşti',
        simplu: 'Radesti',
        comuna: 'Almas'
      },
      {
        nume: 'Berechiu',
        comuna: 'Apateu'
      },
      {
        nume: 'Moţiori',
        simplu: 'Motiori',
        comuna: 'Apateu'
      },
      {
        nume: 'Bârzeşti',
        simplu: 'Barzesti',
        comuna: 'Archis'
      },
      {
        nume: 'Groşeni',
        simplu: 'Groseni',
        comuna: 'Archis'
      },
      {
        nume: 'Nermiş',
        simplu: 'Nermis',
        comuna: 'Archis'
      },
      {
        nume: 'Aldeşti',
        simplu: 'Aldesti',
        comuna: 'Barsa'
      },
      {
        nume: 'Hodiş',
        simplu: 'Hodis',
        comuna: 'Barsa'
      },
      {
        nume: 'Voivodeni',
        comuna: 'Barsa'
      },
      {
        nume: 'Bătuţa',
        simplu: 'Batuta',
        comuna: 'Barzava'
      },
      {
        nume: 'Căpruţa',
        simplu: 'Capruta',
        comuna: 'Barzava'
      },
      {
        nume: 'Dumbrăviţa',
        simplu: 'Dumbravita',
        comuna: 'Barzava'
      },
      {
        nume: 'Groşii Noi',
        simplu: 'Grosii Noi',
        comuna: 'Barzava'
      },
      {
        nume: 'Lalaşinţ',
        simplu: 'Lalasint',
        comuna: 'Barzava'
      },
      {
        nume: 'Monoroştia',
        simplu: 'Monorostia',
        comuna: 'Barzava'
      },
      {
        nume: 'Slatina de Mureş',
        simplu: 'Slatina de Mures',
        comuna: 'Barzava'
      },
      {
        nume: 'Bacău de Mijloc',
        simplu: 'Bacau de Mijloc',
        comuna: 'Bata'
      },
      {
        nume: 'Bulci',
        comuna: 'Bata'
      },
      {
        nume: 'Tela',
        simplu: 'Tela',
        comuna: 'Bata'
      },
      {
        nume: 'Beneşti',
        simplu: 'Benesti',
        comuna: 'Beliu'
      },
      {
        nume: 'Bochia',
        comuna: 'Beliu'
      },
      {
        nume: 'Secaci',
        comuna: 'Beliu'
      },
      {
        nume: 'Tăgădău',
        simplu: 'Tagadau',
        comuna: 'Beliu'
      },
      {
        nume: 'Vasile Goldiş',
        simplu: 'Vasile Goldis',
        comuna: 'Beliu'
      },
      {
        nume: 'Căpălnaş',
        simplu: 'Capalnas',
        comuna: 'Birchis'
      },
      {
        nume: 'Ostrov',
        comuna: 'Birchis'
      },
      {
        nume: 'Virismort',
        comuna: 'Birchis'
      },
      {
        nume: 'Mânerău',
        simplu: 'Manerau',
        comuna: 'Bocsig'
      },
      {
        nume: 'Răpsig',
        simplu: 'Rapsig',
        comuna: 'Bocsig'
      },
      {
        nume: 'Buceava-Soimuş',
        simplu: 'Buceava-Soimus',
        comuna: 'Brazii'
      },
      {
        nume: 'Iacobini',
        comuna: 'Brazii'
      },
      {
        nume: 'Mădrigeşti',
        simplu: 'Madrigesti',
        comuna: 'Brazii'
      },
      {
        nume: 'Secaş',
        simplu: 'Secas',
        comuna: 'Brazii'
      },
      {
        nume: 'Berindia',
        comuna: 'Buteni'
      },
      {
        nume: 'Cuied',
        comuna: 'Buteni'
      },
      {
        nume: 'Păulian',
        simplu: 'Paulian',
        comuna: 'Buteni'
      },
      {
        nume: 'Seliştea',
        simplu: 'Selistea',
        comuna: 'Carand'
      },
      {
        nume: 'Avram Iancu',
        comuna: 'Cermei'
      },
      {
        nume: 'Somoşcheş',
        simplu: 'Somosches',
        comuna: 'Cermei'
      },
      {
        nume: 'Păiuşeni',
        simplu: 'Paiuseni',
        comuna: 'Chisindia'
      },
      {
        nume: 'Văsoaia',
        simplu: 'Vasoaia',
        comuna: 'Chisindia'
      },
      {
        nume: 'Nadab',
        comuna: 'Chisineu-Cris'
      },
      {
        nume: 'Belotinţ',
        simplu: 'Belotint',
        comuna: 'Conop'
      },
      {
        nume: 'Chelmac',
        comuna: 'Conop'
      },
      {
        nume: 'Milova',
        comuna: 'Conop'
      },
      {
        nume: 'Odvoş',
        simplu: 'Odvos',
        comuna: 'Conop'
      },
      {
        nume: 'Chişlaca',
        simplu: 'Chislaca',
        comuna: 'Craiva'
      },
      {
        nume: 'Ciunteşti',
        simplu: 'Ciuntesti',
        comuna: 'Craiva'
      },
      {
        nume: 'Coroi',
        comuna: 'Craiva'
      },
      {
        nume: 'Mărăuş',
        simplu: 'Maraus',
        comuna: 'Craiva'
      },
      {
        nume: 'Rogoz de Beliu',
        comuna: 'Craiva'
      },
      {
        nume: 'Stoineşti',
        simplu: 'Stoinesti',
        comuna: 'Craiva'
      },
      {
        nume: 'Susag',
        comuna: 'Craiva'
      },
      {
        nume: 'Tălmaci',
        simplu: 'Talmaci',
        comuna: 'Craiva'
      },
      {
        nume: 'Siad',
        simplu: 'Siad',
        comuna: 'Craiva'
      },
      {
        nume: 'Dorobanţi',
        simplu: 'Dorobanti',
        comuna: 'Curtici'
      },
      {
        nume: 'Buhani',
        comuna: 'Dezna'
      },
      {
        nume: 'Laz',
        comuna: 'Dezna'
      },
      {
        nume: 'Neagra',
        comuna: 'Dezna'
      },
      {
        nume: 'Slatina de Criş',
        simplu: 'Slatina de Cris',
        comuna: 'Dezna'
      },
      {
        nume: 'Cociuba',
        comuna: 'Dieci'
      },
      {
        nume: 'Crocna',
        comuna: 'Dieci'
      },
      {
        nume: 'Revetiş',
        simplu: 'Revetis',
        comuna: 'Dieci'
      },
      {
        nume: 'Roşia',
        simplu: 'Rosia',
        comuna: 'Dieci'
      },
      {
        nume: 'Bruznic',
        comuna: 'Dorgos'
      },
      {
        nume: 'Pătârş',
        simplu: 'Patars',
        comuna: 'Dorgos'
      },
      {
        nume: 'Ususău',
        simplu: 'Ususau',
        comuna: 'Dorgos'
      },
      {
        nume: 'Zăbalţ',
        simplu: 'Zabalt',
        comuna: 'Dorgos'
      },
      {
        nume: 'Aluniş',
        simplu: 'Alunis',
        comuna: 'Fantinele'
      },
      {
        nume: 'Frumuşeni',
        simplu: 'Frumuseni',
        comuna: 'Fantinele'
      },
      {
        nume: 'Fântânele',
        simplu: 'Fantanele',
        comuna: 'Fantinele'
      },
      {
        nume: 'Tisa Nouă',
        simplu: 'Tisa Noua',
        comuna: 'Fantinele'
      },
      {
        nume: 'Bodrogu Nou',
        comuna: 'Felnac'
      },
      {
        nume: 'Călugăreni',
        simplu: 'Calugareni',
        comuna: 'Felnac'
      },
      {
        nume: 'Zădăreni',
        simplu: 'Zadareni',
        comuna: 'Felnac'
      },
      {
        nume: 'Cuvin',
        comuna: 'Ghioroc'
      },
      {
        nume: 'Miniş',
        simplu: 'Minis',
        comuna: 'Ghioroc'
      },
      {
        nume: 'Siclău',
        simplu: 'Siclau',
        comuna: 'Graniceri'
      },
      {
        nume: 'Bonţeşti',
        simplu: 'Bontesti',
        comuna: 'Gurahont'
      },
      {
        nume: 'Dulcele',
        comuna: 'Gurahont'
      },
      {
        nume: 'Feniş',
        simplu: 'Fenis',
        comuna: 'Gurahont'
      },
      {
        nume: 'Honţişor',
        simplu: 'Hontisor',
        comuna: 'Gurahont'
      },
      {
        nume: 'Iosaş',
        simplu: 'Iosas',
        comuna: 'Gurahont'
      },
      {
        nume: 'Musteşti',
        simplu: 'Mustesti',
        comuna: 'Gurahont'
      },
      {
        nume: 'Pescari',
        comuna: 'Gurahont'
      },
      {
        nume: 'Valea Mare',
        comuna: 'Gurahont'
      },
      {
        nume: 'Zimbru',
        comuna: 'Gurahont'
      },
      {
        nume: 'Luncşoara',
        simplu: 'Luncsoara',
        comuna: 'Halmagel'
      },
      {
        nume: 'Sârbi',
        simplu: 'Sarbi',
        comuna: 'Halmagel'
      },
      {
        nume: 'Târnăviţa',
        simplu: 'Tarnavita',
        comuna: 'Halmagel'
      },
      {
        nume: 'Toheşti',
        simplu: 'Tohesti',
        comuna: 'Halmagel'
      },
      {
        nume: 'Bodeşti',
        simplu: 'Bodesti',
        comuna: 'Halmagiu'
      },
      {
        nume: 'Brusturi',
        comuna: 'Halmagiu'
      },
      {
        nume: 'Băneşti',
        simplu: 'Banesti',
        comuna: 'Halmagiu'
      },
      {
        nume: 'Cristeşti',
        simplu: 'Cristesti',
        comuna: 'Halmagiu'
      },
      {
        nume: 'Ioneşti',
        simplu: 'Ionesti',
        comuna: 'Halmagiu'
      },
      {
        nume: 'Leasa',
        comuna: 'Halmagiu'
      },
      {
        nume: 'Leştioara',
        simplu: 'Lestioara',
        comuna: 'Halmagiu'
      },
      {
        nume: 'Poienari',
        comuna: 'Halmagiu'
      },
      {
        nume: 'Tisa',
        comuna: 'Halmagiu'
      },
      {
        nume: 'Tărmure',
        simplu: 'Tarmure',
        comuna: 'Halmagiu'
      },
      {
        nume: 'Agrişu Mic',
        simplu: 'Agrisu Mic',
        comuna: 'Hasmas'
      },
      {
        nume: 'Botfei',
        comuna: 'Hasmas'
      },
      {
        nume: 'Clit',
        comuna: 'Hasmas'
      },
      {
        nume: 'Comăneşti',
        simplu: 'Comanesti',
        comuna: 'Hasmas'
      },
      {
        nume: 'Urvişu de Beliu',
        simplu: 'Urvisu de Beliu',
        comuna: 'Hasmas'
      },
      {
        nume: 'Minead',
        comuna: 'Ignesti'
      },
      {
        nume: 'Nădălbeşti',
        simplu: 'Nadalbesti',
        comuna: 'Ignesti'
      },
      {
        nume: 'Susani',
        comuna: 'Ignesti'
      },
      {
        nume: 'Mocrea',
        comuna: 'Ineu'
      },
      {
        nume: 'Variaşu Mare',
        simplu: 'Variasu Mare',
        comuna: 'Iratosu'
      },
      {
        nume: 'Variaşu Mic',
        simplu: 'Variasu Mic',
        comuna: 'Iratosu'
      },
      {
        nume: 'Radna',
        comuna: 'Lipova'
      },
      {
        nume: 'Soimoş',
        simplu: 'Soimos',
        comuna: 'Lipova'
      },
      {
        nume: 'Sânleani',
        simplu: 'Sanleani',
        comuna: 'Livada'
      },
      {
        nume: 'Sânmartin',
        simplu: 'Sanmartin',
        comuna: 'Macea'
      },
      {
        nume: 'Satu Nou',
        comuna: 'Misca'
      },
      {
        nume: 'Vânători',
        simplu: 'Vanatori',
        comuna: 'Misca'
      },
      {
        nume: 'Zerindu Mic',
        comuna: 'Misca'
      },
      {
        nume: 'Rănuşa',
        simplu: 'Ranusa',
        comuna: 'Moneasa'
      },
      {
        nume: 'Sintea Mică',
        simplu: 'Sintea Mica',
        comuna: 'Olari'
      },
      {
        nume: 'Măderat',
        simplu: 'Maderat',
        comuna: 'Pancota'
      },
      {
        nume: 'Baraţca',
        simplu: 'Baratca',
        comuna: 'Paulis'
      },
      {
        nume: 'Cladova',
        comuna: 'Paulis'
      },
      {
        nume: 'Sâmbăteni',
        simplu: 'Sambateni',
        comuna: 'Paulis'
      },
      {
        nume: 'Bodrogu Vechi',
        comuna: 'Pecica'
      },
      {
        nume: 'Sederhat',
        comuna: 'Pecica'
      },
      {
        nume: 'Turnu',
        comuna: 'Pecica'
      },
      {
        nume: 'Peregu Mic',
        comuna: 'Peregu Mare'
      },
      {
        nume: 'Corbeşti',
        simplu: 'Corbesti',
        comuna: 'Petris'
      },
      {
        nume: 'Ilteu',
        comuna: 'Petris'
      },
      {
        nume: 'Obârşia',
        simplu: 'Obarsia',
        comuna: 'Petris'
      },
      {
        nume: 'Roşia Nouă',
        simplu: 'Rosia Noua',
        comuna: 'Petris'
      },
      {
        nume: 'Selişte',
        simplu: 'Seliste',
        comuna: 'Petris'
      },
      {
        nume: 'Vărşand',
        simplu: 'Varsand',
        comuna: 'Pilu'
      },
      {
        nume: 'Aciuţa',
        simplu: 'Aciuta',
        comuna: 'Plescuta'
      },
      {
        nume: 'Budeşti',
        simplu: 'Budesti',
        comuna: 'Plescuta'
      },
      {
        nume: 'Dumbrava',
        comuna: 'Plescuta'
      },
      {
        nume: 'Gura Văii',
        simplu: 'Gura Vaii',
        comuna: 'Plescuta'
      },
      {
        nume: 'Rostoci',
        comuna: 'Plescuta'
      },
      {
        nume: 'Tălagiu',
        simplu: 'Talagiu',
        comuna: 'Plescuta'
      },
      {
        nume: 'Cruceni',
        comuna: 'Sagu'
      },
      {
        nume: 'Firiteaz',
        comuna: 'Sagu'
      },
      {
        nume: 'Fiscut',
        comuna: 'Sagu'
      },
      {
        nume: 'Hunedoara Timişană',
        simplu: 'Hunedoara Timisana',
        comuna: 'Sagu'
      },
      {
        nume: 'Caporal Alexa',
        comuna: 'Santana'
      },
      {
        nume: 'Cuiaş',
        simplu: 'Cuias',
        comuna: 'Savarsin'
      },
      {
        nume: 'Căprioara',
        simplu: 'Caprioara',
        comuna: 'Savarsin'
      },
      {
        nume: 'Hălăliş',
        simplu: 'Halalis',
        comuna: 'Savarsin'
      },
      {
        nume: 'Pârneşti',
        simplu: 'Parnesti',
        comuna: 'Savarsin'
      },
      {
        nume: 'Temeşeşti',
        simplu: 'Temesesti',
        comuna: 'Savarsin'
      },
      {
        nume: 'Toc',
        comuna: 'Savarsin'
      },
      {
        nume: 'Troaş',
        simplu: 'Troas',
        comuna: 'Savarsin'
      },
      {
        nume: 'Valea Mare',
        comuna: 'Savarsin'
      },
      {
        nume: 'Donceni',
        comuna: 'Sebis'
      },
      {
        nume: 'Prunişor',
        simplu: 'Prunisor',
        comuna: 'Sebis'
      },
      {
        nume: 'Sălăjeni',
        simplu: 'Salajeni',
        comuna: 'Sebis'
      },
      {
        nume: 'Munar',
        comuna: 'Secusigiu'
      },
      {
        nume: 'Satu Mare',
        comuna: 'Secusigiu'
      },
      {
        nume: 'Sânpetru German',
        simplu: 'Sanpetru German',
        comuna: 'Secusigiu'
      },
      {
        nume: 'Iermata',
        comuna: 'Seleus'
      },
      {
        nume: 'Moroda',
        comuna: 'Seleus'
      },
      {
        nume: 'Chereluş',
        simplu: 'Cherelus',
        comuna: 'Sicula'
      },
      {
        nume: 'Gurba',
        comuna: 'Sicula'
      },
      {
        nume: 'Camna',
        comuna: 'Silindia'
      },
      {
        nume: 'Iercoşeni',
        simplu: 'Iercoseni',
        comuna: 'Silindia'
      },
      {
        nume: 'Luguzău',
        simplu: 'Luguzau',
        comuna: 'Silindia'
      },
      {
        nume: 'Satu Mic',
        comuna: 'Silindia'
      },
      {
        nume: 'Adea',
        comuna: 'Sintea Mare'
      },
      {
        nume: 'Tipar',
        simplu: 'Tipar',
        comuna: 'Sintea Mare'
      },
      {
        nume: 'Galşa',
        simplu: 'Galsa',
        comuna: 'Siria'
      },
      {
        nume: 'Mâsca',
        simplu: 'Masca',
        comuna: 'Siria'
      },
      {
        nume: 'Cuveşdia',
        simplu: 'Cuvesdia',
        comuna: 'Sistarovat'
      },
      {
        nume: 'Labaşinţ',
        simplu: 'Labasint',
        comuna: 'Sistarovat'
      },
      {
        nume: 'Varniţa',
        simplu: 'Varnita',
        comuna: 'Sistarovat'
      },
      {
        nume: 'Sânpaul',
        simplu: 'Sanpaul',
        comuna: 'Sofronea'
      },
      {
        nume: 'Agrişu Mare',
        simplu: 'Agrisu Mare',
        comuna: 'Tarnova'
      },
      {
        nume: 'Arăneag',
        simplu: 'Araneag',
        comuna: 'Tarnova'
      },
      {
        nume: 'Chier',
        comuna: 'Tarnova'
      },
      {
        nume: 'Drauţ',
        simplu: 'Draut',
        comuna: 'Tarnova'
      },
      {
        nume: 'Dud',
        comuna: 'Tarnova'
      },
      {
        nume: 'Minişel',
        simplu: 'Minisel',
        comuna: 'Taut'
      },
      {
        nume: 'Minişu de Sus',
        simplu: 'Minisu de Sus',
        comuna: 'Taut'
      },
      {
        nume: 'Nadăş',
        simplu: 'Nadas',
        comuna: 'Taut'
      },
      {
        nume: 'Baia',
        comuna: 'Varadia De Mures'
      },
      {
        nume: 'Juliţa',
        simplu: 'Julita',
        comuna: 'Varadia De Mures'
      },
      {
        nume: 'Lupeşti',
        simplu: 'Lupesti',
        comuna: 'Varadia De Mures'
      },
      {
        nume: 'Nicolae Bălcescu',
        simplu: 'Nicolae Balcescu',
        comuna: 'Varadia De Mures'
      },
      {
        nume: 'Stejar',
        comuna: 'Varadia De Mures'
      },
      {
        nume: 'Avram Iancu',
        comuna: 'Varfurile'
      },
      {
        nume: 'Groşi',
        simplu: 'Grosi',
        comuna: 'Varfurile'
      },
      {
        nume: 'Lazuri',
        comuna: 'Varfurile'
      },
      {
        nume: 'Mermeşti',
        simplu: 'Mermesti',
        comuna: 'Varfurile'
      },
      {
        nume: 'Măgulicea',
        simplu: 'Magulicea',
        comuna: 'Varfurile'
      },
      {
        nume: 'Poiana',
        comuna: 'Varfurile'
      },
      {
        nume: 'Vidra',
        comuna: 'Varfurile'
      },
      {
        nume: 'Mailat',
        comuna: 'Vinga'
      },
      {
        nume: 'Mănăştur',
        simplu: 'Manastur',
        comuna: 'Vinga'
      },
      {
        nume: 'Cicir',
        comuna: 'Vladimirescu'
      },
      {
        nume: 'Horia',
        comuna: 'Vladimirescu'
      },
      {
        nume: 'Mândruloc',
        simplu: 'Mandruloc',
        comuna: 'Vladimirescu'
      },
      {
        nume: 'Chesinţ',
        simplu: 'Chesint',
        comuna: 'Zabrani'
      },
      {
        nume: 'Neudorf',
        comuna: 'Zabrani'
      },
      {
        nume: 'Cintei',
        comuna: 'Zarand'
      },
      {
        nume: 'Iermata Neagră',
        simplu: 'Iermata Neagra',
        comuna: 'Zerind'
      },
      {
        nume: 'Andrei Saguna',
        simplu: 'Andrei Saguna',
        comuna: 'Zimandu Nou'
      },
      {
        nume: 'Zimandcuz',
        comuna: 'Zimandu Nou'
      }
    ]
  },
  {
    auto: 'AG',
    nume: 'Argeş',
    localitati: [
      {
        nume: 'Albota'
      },
      {
        nume: 'Aninoasa'
      },
      {
        nume: 'Arefu'
      },
      {
        nume: 'Bascov'
      },
      {
        nume: 'Berevoeşti',
        simplu: 'Berevoesti'
      },
      {
        nume: 'Bogaţi',
        simplu: 'Bogati'
      },
      {
        nume: 'Boteni'
      },
      {
        nume: 'Boţeşti',
        simplu: 'Botesti'
      },
      {
        nume: 'Bradu'
      },
      {
        nume: 'Brăduleţ',
        simplu: 'Bradulet'
      },
      {
        nume: 'Bughea de Jos'
      },
      {
        nume: 'Buzoeşti',
        simplu: 'Buzoesti'
      },
      {
        nume: 'Bârla',
        simplu: 'Barla'
      },
      {
        nume: 'Băbana',
        simplu: 'Babana'
      },
      {
        nume: 'Băiculeşti',
        simplu: 'Baiculesti'
      },
      {
        nume: 'Bălileşti',
        simplu: 'Balilesti'
      },
      {
        nume: 'Cetăţeni',
        simplu: 'Cetateni'
      },
      {
        nume: 'Cicăneşti',
        simplu: 'Cicanesti'
      },
      {
        nume: 'Ciofrângeni',
        simplu: 'Ciofrangeni'
      },
      {
        nume: 'Ciomăgeşti',
        simplu: 'Ciomagesti'
      },
      {
        nume: 'Cocu'
      },
      {
        nume: 'Corbeni'
      },
      {
        nume: 'Corbi'
      },
      {
        nume: 'Costeşti',
        simplu: 'Costesti'
      },
      {
        nume: 'Cotmeana'
      },
      {
        nume: 'Coşeşti',
        simplu: 'Cosesti'
      },
      {
        nume: 'Cuca'
      },
      {
        nume: 'Curtea de Argeş',
        simplu: 'Curtea de Arges'
      },
      {
        nume: 'Câmpulung',
        simplu: 'Campulung'
      },
      {
        nume: 'Căldăraru',
        simplu: 'Caldararu'
      },
      {
        nume: 'Călineşti',
        simplu: 'Calinesti'
      },
      {
        nume: 'Căteasca',
        simplu: 'Cateasca'
      },
      {
        nume: 'Davideşti',
        simplu: 'Davidesti'
      },
      {
        nume: 'Dobreşti',
        simplu: 'Dobresti'
      },
      {
        nume: 'Domneşti',
        simplu: 'Domnesti'
      },
      {
        nume: 'Dragoslavele'
      },
      {
        nume: 'Dâmbovicioara',
        simplu: 'Dambovicioara'
      },
      {
        nume: 'Dârmăneşti',
        simplu: 'Darmanesti'
      },
      {
        nume: 'Godeni'
      },
      {
        nume: 'Hârseşti',
        simplu: 'Harsesti'
      },
      {
        nume: 'Hârtieşti',
        simplu: 'Hartiesti'
      },
      {
        nume: 'Izvoru'
      },
      {
        nume: 'Leordeni'
      },
      {
        nume: 'Lereşti',
        simplu: 'Leresti'
      },
      {
        nume: 'Lunca Corbului'
      },
      {
        nume: 'Merişani',
        simplu: 'Merisani'
      },
      {
        nume: 'Miceşti',
        simplu: 'Micesti'
      },
      {
        nume: 'Mihăeşti',
        simplu: 'Mihaesti'
      },
      {
        nume: 'Mioveni'
      },
      {
        nume: 'Miroşi',
        simplu: 'Mirosi'
      },
      {
        nume: 'Morăreşti',
        simplu: 'Moraresti'
      },
      {
        nume: 'Mozăceni',
        simplu: 'Mozaceni'
      },
      {
        nume: 'Moşoaia',
        simplu: 'Mosoaia'
      },
      {
        nume: 'Musăţeşti',
        simplu: 'Musatesti'
      },
      {
        nume: 'Mălureni',
        simplu: 'Malureni'
      },
      {
        nume: 'Mărăcineni',
        simplu: 'Maracineni'
      },
      {
        nume: 'Negraşi',
        simplu: 'Negrasi'
      },
      {
        nume: 'Nucşoara',
        simplu: 'Nucsoara'
      },
      {
        nume: 'Oarja'
      },
      {
        nume: 'Pietroşani',
        simplu: 'Pietrosani'
      },
      {
        nume: 'Piteşti',
        simplu: 'Pitesti'
      },
      {
        nume: 'Poiana Lacului'
      },
      {
        nume: 'Popeşti',
        simplu: 'Popesti'
      },
      {
        nume: 'Priboieni'
      },
      {
        nume: 'Recea'
      },
      {
        nume: 'Rociu'
      },
      {
        nume: 'Rucăr',
        simplu: 'Rucar'
      },
      {
        nume: 'Râca',
        simplu: 'Raca'
      },
      {
        nume: 'Răteşti',
        simplu: 'Ratesti'
      },
      {
        nume: 'Schitu Goleşti',
        simplu: 'Schitu Golesti'
      },
      {
        nume: 'Slobozia'
      },
      {
        nume: 'Stoeneşti',
        simplu: 'Stoenesti'
      },
      {
        nume: 'Stolnici'
      },
      {
        nume: 'Stâlpeni',
        simplu: 'Stalpeni'
      },
      {
        nume: 'Suseni'
      },
      {
        nume: 'Sălătrucu',
        simplu: 'Salatrucu'
      },
      {
        nume: 'Teiu'
      },
      {
        nume: 'Tigveni'
      },
      {
        nume: 'Topoloveni'
      },
      {
        nume: 'Uda'
      },
      {
        nume: 'Ungheni'
      },
      {
        nume: 'Valea Danului'
      },
      {
        nume: 'Valea Iaşului',
        simplu: 'Valea Iasului'
      },
      {
        nume: 'Valea Mare Pravăţ',
        simplu: 'Valea Mare Pravat'
      },
      {
        nume: 'Vedea'
      },
      {
        nume: 'Vlădeşti',
        simplu: 'Vladesti'
      },
      {
        nume: 'Vultureşti',
        simplu: 'Vulturesti'
      },
      {
        nume: 'Stefan cel Mare',
        simplu: 'Stefan cel Mare'
      },
      {
        nume: 'Stefăneşti',
        simplu: 'Stefanesti'
      },
      {
        nume: 'Suici',
        simplu: 'Suici'
      },
      {
        nume: 'Tiţeşti',
        simplu: 'Titesti'
      },
      {
        nume: 'Albeştii Pamânteni',
        simplu: 'Albestii Pamanteni',
        comuna: 'Albestii De Arges'
      },
      {
        nume: 'Albeştii Ungureni',
        simplu: 'Albestii Ungureni',
        comuna: 'Albestii De Arges'
      },
      {
        nume: 'Brăteşti',
        simplu: 'Bratesti',
        comuna: 'Albestii De Arges'
      },
      {
        nume: 'Doblea',
        comuna: 'Albestii De Arges'
      },
      {
        nume: 'Dobrotu',
        comuna: 'Albestii De Arges'
      },
      {
        nume: 'Dumireşti',
        simplu: 'Dumiresti',
        comuna: 'Albestii De Arges'
      },
      {
        nume: 'Florieni',
        comuna: 'Albestii De Arges'
      },
      {
        nume: 'Albeşti',
        simplu: 'Albesti',
        comuna: 'Albestii De Muscel'
      },
      {
        nume: 'Bughea de Sus',
        comuna: 'Albestii De Muscel'
      },
      {
        nume: 'Cândeşti',
        simplu: 'Candesti',
        comuna: 'Albestii De Muscel'
      },
      {
        nume: 'Cerbu',
        comuna: 'Albota'
      },
      {
        nume: 'Frăteşti',
        simplu: 'Fratesti',
        comuna: 'Albota'
      },
      {
        nume: 'Gura Văii',
        simplu: 'Gura Vaii',
        comuna: 'Albota'
      },
      {
        nume: 'Mareş',
        simplu: 'Mares',
        comuna: 'Albota'
      },
      {
        nume: 'Broşteni',
        simplu: 'Brosteni',
        comuna: 'Aninoasa'
      },
      {
        nume: 'Slănic',
        simplu: 'Slanic',
        comuna: 'Aninoasa'
      },
      {
        nume: 'Valea Siliştii',
        simplu: 'Valea Silistii',
        comuna: 'Aninoasa'
      },
      {
        nume: 'Căpăţânenii Pământeni',
        simplu: 'Capatanenii Pamanteni',
        comuna: 'Arefu'
      },
      {
        nume: 'Căpăţânenii Ungureni',
        simplu: 'Capatanenii Ungureni',
        comuna: 'Arefu'
      },
      {
        nume: 'Băjăneşti',
        simplu: 'Bajanesti',
        comuna: 'Babana'
      },
      {
        nume: 'Ciobăneşti',
        simplu: 'Ciobanesti',
        comuna: 'Babana'
      },
      {
        nume: 'Cotmeniţa',
        simplu: 'Cotmenita',
        comuna: 'Babana'
      },
      {
        nume: 'Groşi',
        simplu: 'Grosi',
        comuna: 'Babana'
      },
      {
        nume: 'Lupueni',
        comuna: 'Babana'
      },
      {
        nume: 'Slăatioarele',
        simplu: 'Slaatioarele',
        comuna: 'Babana'
      },
      {
        nume: 'Alunişu',
        simplu: 'Alunisu',
        comuna: 'Baiculesti'
      },
      {
        nume: 'Anghineşti',
        simplu: 'Anghinesti',
        comuna: 'Baiculesti'
      },
      {
        nume: 'Argeşani',
        simplu: 'Argesani',
        comuna: 'Baiculesti'
      },
      {
        nume: 'Măniceşti',
        simplu: 'Manicesti',
        comuna: 'Baiculesti'
      },
      {
        nume: 'Stejari',
        comuna: 'Baiculesti'
      },
      {
        nume: 'Tutana',
        comuna: 'Baiculesti'
      },
      {
        nume: 'Valea Brazilor',
        comuna: 'Baiculesti'
      },
      {
        nume: 'Valea lui Enache',
        comuna: 'Baiculesti'
      },
      {
        nume: 'Zigoneni',
        comuna: 'Baiculesti'
      },
      {
        nume: 'Băjeşti',
        simplu: 'Bajesti',
        comuna: 'Balilesti'
      },
      {
        nume: 'Goleşti',
        simplu: 'Golesti',
        comuna: 'Balilesti'
      },
      {
        nume: 'Poieniţa',
        simplu: 'Poienita',
        comuna: 'Balilesti'
      },
      {
        nume: 'Priboaia',
        comuna: 'Balilesti'
      },
      {
        nume: 'Uliţa',
        simplu: 'Ulita',
        comuna: 'Balilesti'
      },
      {
        nume: 'Valea Mare-Bratia',
        comuna: 'Balilesti'
      },
      {
        nume: 'Afrimeşti',
        simplu: 'Afrimesti',
        comuna: 'Barla'
      },
      {
        nume: 'Brabeţi',
        simplu: 'Brabeti',
        comuna: 'Barla'
      },
      {
        nume: 'Bădeşti',
        simplu: 'Badesti',
        comuna: 'Barla'
      },
      {
        nume: 'Cioceşti',
        simplu: 'Ciocesti',
        comuna: 'Barla'
      },
      {
        nume: 'Malu',
        comuna: 'Barla'
      },
      {
        nume: 'Mozăcenii-Vale',
        simplu: 'Mozacenii-Vale',
        comuna: 'Barla'
      },
      {
        nume: 'Mândra',
        simplu: 'Mandra',
        comuna: 'Barla'
      },
      {
        nume: 'Podişoru',
        simplu: 'Podisoru',
        comuna: 'Barla'
      },
      {
        nume: 'Urlueni',
        comuna: 'Barla'
      },
      {
        nume: 'Zuvelcaţi',
        simplu: 'Zuvelcati',
        comuna: 'Barla'
      },
      {
        nume: 'Selăreasca',
        simplu: 'Selareasca',
        comuna: 'Barla'
      },
      {
        nume: 'Brăileni',
        simplu: 'Braileni',
        comuna: 'Bascov'
      },
      {
        nume: 'Glâmbocu',
        simplu: 'Glambocu',
        comuna: 'Bascov'
      },
      {
        nume: 'Mica',
        comuna: 'Bascov'
      },
      {
        nume: 'Prislopu Mic',
        comuna: 'Bascov'
      },
      {
        nume: 'Scheau',
        comuna: 'Bascov'
      },
      {
        nume: 'Uiasca',
        comuna: 'Bascov'
      },
      {
        nume: 'Valea Ursului',
        comuna: 'Bascov'
      },
      {
        nume: 'Beleţi',
        simplu: 'Beleti',
        comuna: 'Beleti-Negresti'
      },
      {
        nume: 'Lenţea',
        simplu: 'Lentea',
        comuna: 'Beleti-Negresti'
      },
      {
        nume: 'Negreşti',
        simplu: 'Negresti',
        comuna: 'Beleti-Negresti'
      },
      {
        nume: 'Zgripceşti',
        simplu: 'Zgripcesti',
        comuna: 'Beleti-Negresti'
      },
      {
        nume: 'Bratia',
        comuna: 'Berevoesti'
      },
      {
        nume: 'Gămăceşti',
        simplu: 'Gamacesti',
        comuna: 'Berevoesti'
      },
      {
        nume: 'Oţelu',
        simplu: 'Otelu',
        comuna: 'Berevoesti'
      },
      {
        nume: 'Bujoi',
        comuna: 'Bogati'
      },
      {
        nume: 'Bârloi',
        simplu: 'Barloi',
        comuna: 'Bogati'
      },
      {
        nume: 'Chiţeşti',
        simplu: 'Chitesti',
        comuna: 'Bogati'
      },
      {
        nume: 'Dumbrava',
        comuna: 'Bogati'
      },
      {
        nume: 'Glâmbocel',
        simplu: 'Glambocel',
        comuna: 'Bogati'
      },
      {
        nume: 'Glâmbocelu',
        simplu: 'Glambocelu',
        comuna: 'Bogati'
      },
      {
        nume: 'Suseni',
        comuna: 'Bogati'
      },
      {
        nume: 'Balabani',
        comuna: 'Boteni'
      },
      {
        nume: 'Lunca',
        comuna: 'Boteni'
      },
      {
        nume: 'Muşcel',
        simplu: 'Muscel',
        comuna: 'Boteni'
      },
      {
        nume: 'Moşteni-Greci',
        simplu: 'Mosteni-Greci',
        comuna: 'Botesti'
      },
      {
        nume: 'Geamăna',
        simplu: 'Geamana',
        comuna: 'Bradu'
      },
      {
        nume: 'Alunişu',
        simplu: 'Alunisu',
        comuna: 'Bradulet'
      },
      {
        nume: 'Brădetu',
        simplu: 'Bradetu',
        comuna: 'Bradulet'
      },
      {
        nume: 'Cosaci',
        comuna: 'Bradulet'
      },
      {
        nume: 'Galeşu',
        simplu: 'Galesu',
        comuna: 'Bradulet'
      },
      {
        nume: 'Piatra',
        comuna: 'Bradulet'
      },
      {
        nume: 'Slămneşti',
        simplu: 'Slamnesti',
        comuna: 'Bradulet'
      },
      {
        nume: 'Uleni',
        comuna: 'Bradulet'
      },
      {
        nume: 'Ungureni',
        comuna: 'Bradulet'
      },
      {
        nume: 'Budeasa Mare',
        comuna: 'Budeasa'
      },
      {
        nume: 'Budeasa Mică',
        simplu: 'Budeasa Mica',
        comuna: 'Budeasa'
      },
      {
        nume: 'Caloteşti',
        simplu: 'Calotesti',
        comuna: 'Budeasa'
      },
      {
        nume: 'Gălăşeşti',
        simplu: 'Galasesti',
        comuna: 'Budeasa'
      },
      {
        nume: 'Rogojina',
        comuna: 'Budeasa'
      },
      {
        nume: 'Valea Mărului',
        simplu: 'Valea Marului',
        comuna: 'Budeasa'
      },
      {
        nume: 'Bujoreni',
        comuna: 'Buzoesti'
      },
      {
        nume: 'Cornăţel',
        simplu: 'Cornatel',
        comuna: 'Buzoesti'
      },
      {
        nume: 'Curteanca',
        comuna: 'Buzoesti'
      },
      {
        nume: 'Ioneşti',
        simplu: 'Ionesti',
        comuna: 'Buzoesti'
      },
      {
        nume: 'Podeni',
        comuna: 'Buzoesti'
      },
      {
        nume: 'Redea',
        comuna: 'Buzoesti'
      },
      {
        nume: 'Tomşanca',
        simplu: 'Tomsanca',
        comuna: 'Buzoesti'
      },
      {
        nume: 'Vlăduţa',
        simplu: 'Vladuta',
        comuna: 'Buzoesti'
      },
      {
        nume: 'Vulpeşti',
        simplu: 'Vulpesti',
        comuna: 'Buzoesti'
      },
      {
        nume: 'Serboeni',
        simplu: 'Serboeni',
        comuna: 'Buzoesti'
      },
      {
        nume: 'Burdea',
        comuna: 'Caldararu'
      },
      {
        nume: 'Strâmbeni',
        simplu: 'Strambeni',
        comuna: 'Caldararu'
      },
      {
        nume: 'Ciocăneşti',
        simplu: 'Ciocanesti',
        comuna: 'Calinesti'
      },
      {
        nume: 'Cârstieni',
        simplu: 'Carstieni',
        comuna: 'Calinesti'
      },
      {
        nume: 'Glodu',
        comuna: 'Calinesti'
      },
      {
        nume: 'Gorganu',
        comuna: 'Calinesti'
      },
      {
        nume: 'Radu Negru',
        comuna: 'Calinesti'
      },
      {
        nume: 'Râncăciov',
        simplu: 'Rancaciov',
        comuna: 'Calinesti'
      },
      {
        nume: 'Udeni-Zăvoi',
        simplu: 'Udeni-Zavoi',
        comuna: 'Calinesti'
      },
      {
        nume: 'Urlucea',
        comuna: 'Calinesti'
      },
      {
        nume: 'Valea Corbului',
        comuna: 'Calinesti'
      },
      {
        nume: 'Vrăneşti',
        simplu: 'Vranesti',
        comuna: 'Calinesti'
      },
      {
        nume: 'Văleni-Podgoria',
        simplu: 'Valeni-Podgoria',
        comuna: 'Calinesti'
      },
      {
        nume: 'Valea Rumâneştilor',
        simplu: 'Valea Rumanestilor',
        comuna: 'Campulung'
      },
      {
        nume: 'Catanele',
        comuna: 'Cateasca'
      },
      {
        nume: 'Cireşu',
        simplu: 'Ciresu',
        comuna: 'Cateasca'
      },
      {
        nume: 'Coşeri',
        simplu: 'Coseri',
        comuna: 'Cateasca'
      },
      {
        nume: 'Gruiu',
        comuna: 'Cateasca'
      },
      {
        nume: 'Recea',
        comuna: 'Cateasca'
      },
      {
        nume: 'Siliştea',
        simplu: 'Silistea',
        comuna: 'Cateasca'
      },
      {
        nume: 'Ceparii Pământeni',
        simplu: 'Ceparii Pamanteni',
        comuna: 'Cepari'
      },
      {
        nume: 'Ceparii Ungureni',
        comuna: 'Cepari'
      },
      {
        nume: 'Cărpeniş',
        simplu: 'Carpenis',
        comuna: 'Cepari'
      },
      {
        nume: 'Morăşti',
        simplu: 'Morasti',
        comuna: 'Cepari'
      },
      {
        nume: 'Urluieşti',
        simplu: 'Urluiesti',
        comuna: 'Cepari'
      },
      {
        nume: 'Valea Măgurei',
        simplu: 'Valea Magurei',
        comuna: 'Cepari'
      },
      {
        nume: 'Zamfireşti',
        simplu: 'Zamfiresti',
        comuna: 'Cepari'
      },
      {
        nume: 'Sendruleşti',
        simplu: 'Sendrulesti',
        comuna: 'Cepari'
      },
      {
        nume: 'Lăicăi',
        simplu: 'Laicai',
        comuna: 'Cetateni'
      },
      {
        nume: 'Valea Cetăţuia',
        simplu: 'Valea Cetatuia',
        comuna: 'Cetateni'
      },
      {
        nume: 'Bărăşti',
        simplu: 'Barasti',
        comuna: 'Cicanesti'
      },
      {
        nume: 'Mioarele',
        comuna: 'Cicanesti'
      },
      {
        nume: 'Urecheşti',
        simplu: 'Urechesti',
        comuna: 'Cicanesti'
      },
      {
        nume: 'Burluşi',
        simplu: 'Burlusi',
        comuna: 'Ciofrangeni'
      },
      {
        nume: 'Lacurile',
        comuna: 'Ciofrangeni'
      },
      {
        nume: 'Piatra',
        comuna: 'Ciofrangeni'
      },
      {
        nume: 'Schitu-Matei',
        comuna: 'Ciofrangeni'
      },
      {
        nume: 'Beculeşti',
        simplu: 'Beculesti',
        comuna: 'Ciomagesti'
      },
      {
        nume: 'Bratia',
        comuna: 'Ciomagesti'
      },
      {
        nume: 'Cungrea',
        comuna: 'Ciomagesti'
      },
      {
        nume: 'Dogari',
        comuna: 'Ciomagesti'
      },
      {
        nume: 'Fedeleşoiu',
        simplu: 'Fedelesoiu',
        comuna: 'Ciomagesti'
      },
      {
        nume: 'Giuclani',
        comuna: 'Ciomagesti'
      },
      {
        nume: 'Păuneşti',
        simplu: 'Paunesti',
        comuna: 'Ciomagesti'
      },
      {
        nume: 'Răduţeşti',
        simplu: 'Radutesti',
        comuna: 'Ciomagesti'
      },
      {
        nume: 'Bărbăteşti',
        simplu: 'Barbatesti',
        comuna: 'Cocu'
      },
      {
        nume: 'Crucişoara',
        simplu: 'Crucisoara',
        comuna: 'Cocu'
      },
      {
        nume: 'Făcăleţeşti',
        simplu: 'Facaletesti',
        comuna: 'Cocu'
      },
      {
        nume: 'Greabănu',
        simplu: 'Greabanu',
        comuna: 'Cocu'
      },
      {
        nume: 'Popeşti',
        simplu: 'Popesti',
        comuna: 'Cocu'
      },
      {
        nume: 'Răchiţele de Jos',
        simplu: 'Rachitele de Jos',
        comuna: 'Cocu'
      },
      {
        nume: 'Răchiţele de Sus',
        simplu: 'Rachitele de Sus',
        comuna: 'Cocu'
      },
      {
        nume: 'Berindeşti',
        simplu: 'Berindesti',
        comuna: 'Corbeni'
      },
      {
        nume: 'Bucşeneşti',
        simplu: 'Bucsenesti',
        comuna: 'Corbeni'
      },
      {
        nume: 'Oeştii Pamânteni',
        simplu: 'Oestii Pamanteni',
        comuna: 'Corbeni'
      },
      {
        nume: 'Oeştii Ungureni',
        simplu: 'Oestii Ungureni',
        comuna: 'Corbeni'
      },
      {
        nume: 'Poienari',
        comuna: 'Corbeni'
      },
      {
        nume: 'Rotunda',
        comuna: 'Corbeni'
      },
      {
        nume: 'Turburea',
        comuna: 'Corbeni'
      },
      {
        nume: 'Corbşori',
        simplu: 'Corbsori',
        comuna: 'Corbi'
      },
      {
        nume: 'Jgheaburi',
        comuna: 'Corbi'
      },
      {
        nume: 'Poduri',
        comuna: 'Corbi'
      },
      {
        nume: 'Poienărei',
        simplu: 'Poienarei',
        comuna: 'Corbi'
      },
      {
        nume: 'Stăneşti',
        simplu: 'Stanesti',
        comuna: 'Corbi'
      },
      {
        nume: 'Jupâneşti',
        simplu: 'Jupanesti',
        comuna: 'Cosesti'
      },
      {
        nume: 'Leiceşti',
        simplu: 'Leicesti',
        comuna: 'Cosesti'
      },
      {
        nume: 'Lăpuşani',
        simplu: 'Lapusani',
        comuna: 'Cosesti'
      },
      {
        nume: 'Petreşti',
        simplu: 'Petresti',
        comuna: 'Cosesti'
      },
      {
        nume: 'Priseaca',
        comuna: 'Cosesti'
      },
      {
        nume: 'Păcioiu',
        simplu: 'Pacioiu',
        comuna: 'Cosesti'
      },
      {
        nume: 'Broşteni',
        simplu: 'Brosteni',
        comuna: 'Costesti'
      },
      {
        nume: 'Lăceni',
        simplu: 'Laceni',
        comuna: 'Costesti'
      },
      {
        nume: 'Podu Broşteni',
        simplu: 'Podu Brosteni',
        comuna: 'Costesti'
      },
      {
        nume: 'Pârvu Roşu',
        simplu: 'Parvu Rosu',
        comuna: 'Costesti'
      },
      {
        nume: 'Smei',
        comuna: 'Costesti'
      },
      {
        nume: 'Stârci',
        simplu: 'Starci',
        comuna: 'Costesti'
      },
      {
        nume: 'Bascovele',
        comuna: 'Cotmeana'
      },
      {
        nume: 'Buneşti',
        simplu: 'Bunesti',
        comuna: 'Cotmeana'
      },
      {
        nume: 'Costeşti',
        simplu: 'Costesti',
        comuna: 'Cotmeana'
      },
      {
        nume: 'Dealu Pădurii',
        simplu: 'Dealu Padurii',
        comuna: 'Cotmeana'
      },
      {
        nume: 'Drăgoleşti',
        simplu: 'Dragolesti',
        comuna: 'Cotmeana'
      },
      {
        nume: 'Linteşti',
        simplu: 'Lintesti',
        comuna: 'Cotmeana'
      },
      {
        nume: 'Negeşti',
        simplu: 'Negesti',
        comuna: 'Cotmeana'
      },
      {
        nume: 'Pieleşti',
        simplu: 'Pielesti',
        comuna: 'Cotmeana'
      },
      {
        nume: 'Spiridoni',
        comuna: 'Cotmeana'
      },
      {
        nume: 'Sănduleşti',
        simplu: 'Sandulesti',
        comuna: 'Cotmeana'
      },
      {
        nume: 'Ursoaia',
        comuna: 'Cotmeana'
      },
      {
        nume: 'Vârloveni',
        simplu: 'Varloveni',
        comuna: 'Cotmeana'
      },
      {
        nume: 'Zamfireşti',
        simplu: 'Zamfiresti',
        comuna: 'Cotmeana'
      },
      {
        nume: 'Bălţata',
        simplu: 'Baltata',
        comuna: 'Cuca'
      },
      {
        nume: 'Bărbălani',
        simplu: 'Barbalani',
        comuna: 'Cuca'
      },
      {
        nume: 'Cotu',
        comuna: 'Cuca'
      },
      {
        nume: 'Crivăţu',
        simplu: 'Crivatu',
        comuna: 'Cuca'
      },
      {
        nume: 'Cârceşti',
        simplu: 'Carcesti',
        comuna: 'Cuca'
      },
      {
        nume: 'Lăunele de Sus',
        simplu: 'Launele de Sus',
        comuna: 'Cuca'
      },
      {
        nume: 'Măcăi',
        simplu: 'Macai',
        comuna: 'Cuca'
      },
      {
        nume: 'Măneşti',
        simplu: 'Manesti',
        comuna: 'Cuca'
      },
      {
        nume: 'Sineşti',
        simplu: 'Sinesti',
        comuna: 'Cuca'
      },
      {
        nume: 'Stănicei',
        simplu: 'Stanicei',
        comuna: 'Cuca'
      },
      {
        nume: 'Teodoreşti',
        simplu: 'Teodoresti',
        comuna: 'Cuca'
      },
      {
        nume: 'Valea Cucii',
        comuna: 'Cuca'
      },
      {
        nume: 'Vonigeasa',
        comuna: 'Cuca'
      },
      {
        nume: 'Noapteş',
        simplu: 'Noaptes',
        comuna: 'Curtea De Arges'
      },
      {
        nume: 'Ciocanu',
        comuna: 'Dambovicioara'
      },
      {
        nume: 'Podu Dâmboviţei',
        simplu: 'Podu Dambovitei',
        comuna: 'Dambovicioara'
      },
      {
        nume: 'Negreni',
        comuna: 'Darmanesti'
      },
      {
        nume: 'Piscani',
        comuna: 'Darmanesti'
      },
      {
        nume: 'Valea Nandrii',
        comuna: 'Darmanesti'
      },
      {
        nume: 'Valea Rizii',
        comuna: 'Darmanesti'
      },
      {
        nume: 'Conţeşti',
        simplu: 'Contesti',
        comuna: 'Davidesti'
      },
      {
        nume: 'Voroveni',
        comuna: 'Davidesti'
      },
      {
        nume: 'Fureşti',
        simplu: 'Furesti',
        comuna: 'Dobresti'
      },
      {
        nume: 'Băceşti',
        simplu: 'Bacesti',
        comuna: 'Draganu'
      },
      {
        nume: 'Drăganu-Olteni',
        simplu: 'Draganu-Olteni',
        comuna: 'Draganu'
      },
      {
        nume: 'Dumbrăveşti',
        simplu: 'Dumbravesti',
        comuna: 'Draganu'
      },
      {
        nume: 'Prislopu Mare',
        comuna: 'Draganu'
      },
      {
        nume: 'Valea Hotarului',
        comuna: 'Dragoslavele'
      },
      {
        nume: 'Bordeieni',
        comuna: 'Godeni'
      },
      {
        nume: 'Capu Piscului',
        comuna: 'Godeni'
      },
      {
        nume: 'Coteşti',
        simplu: 'Cotesti',
        comuna: 'Godeni'
      },
      {
        nume: 'Malu',
        comuna: 'Godeni'
      },
      {
        nume: 'Ciobani',
        comuna: 'Harsesti'
      },
      {
        nume: 'Martalogi',
        comuna: 'Harsesti'
      },
      {
        nume: 'Dealu',
        comuna: 'Hartiesti'
      },
      {
        nume: 'Lespezi',
        comuna: 'Hartiesti'
      },
      {
        nume: 'Lucieni',
        comuna: 'Hartiesti'
      },
      {
        nume: 'Baloteasca',
        comuna: 'Leordeni'
      },
      {
        nume: 'Budişteni',
        simplu: 'Budisteni',
        comuna: 'Leordeni'
      },
      {
        nume: 'Bântău',
        simplu: 'Bantau',
        comuna: 'Leordeni'
      },
      {
        nume: 'Băila',
        simplu: 'Baila',
        comuna: 'Leordeni'
      },
      {
        nume: 'Ciolceşti',
        simplu: 'Ciolcesti',
        comuna: 'Leordeni'
      },
      {
        nume: 'Ciulniţa',
        simplu: 'Ciulnita',
        comuna: 'Leordeni'
      },
      {
        nume: 'Cotu Malului',
        comuna: 'Leordeni'
      },
      {
        nume: 'Cârciumăreşti',
        simplu: 'Carciumaresti',
        comuna: 'Leordeni'
      },
      {
        nume: 'Glodu',
        comuna: 'Leordeni'
      },
      {
        nume: 'Glâmbocata',
        simplu: 'Glambocata',
        comuna: 'Leordeni'
      },
      {
        nume: 'Glâmbocata-Deal',
        simplu: 'Glambocata-Deal',
        comuna: 'Leordeni'
      },
      {
        nume: 'Moara Mocanului',
        comuna: 'Leordeni'
      },
      {
        nume: 'Schitu Scoiceşti',
        simplu: 'Schitu Scoicesti',
        comuna: 'Leordeni'
      },
      {
        nume: 'Pojorâta',
        simplu: 'Pojorata',
        comuna: 'Leresti'
      },
      {
        nume: 'Voineşti',
        simplu: 'Voinesti',
        comuna: 'Leresti'
      },
      {
        nume: 'Bumbueni',
        comuna: 'Lunca Corbului'
      },
      {
        nume: 'Catane',
        comuna: 'Lunca Corbului'
      },
      {
        nume: 'Cieşti',
        simplu: 'Ciesti',
        comuna: 'Lunca Corbului'
      },
      {
        nume: 'Lăngeşti',
        simplu: 'Langesti',
        comuna: 'Lunca Corbului'
      },
      {
        nume: 'Mârghia de Jos',
        simplu: 'Marghia de Jos',
        comuna: 'Lunca Corbului'
      },
      {
        nume: 'Mârghia de Sus',
        simplu: 'Marghia de Sus',
        comuna: 'Lunca Corbului'
      },
      {
        nume: 'Pădureţi',
        simplu: 'Padureti',
        comuna: 'Lunca Corbului'
      },
      {
        nume: 'Silişteni',
        simplu: 'Silisteni',
        comuna: 'Lunca Corbului'
      },
      {
        nume: 'Buneşti',
        simplu: 'Bunesti',
        comuna: 'Malureni'
      },
      {
        nume: 'Păuleasca',
        simplu: 'Pauleasca',
        comuna: 'Malureni'
      },
      {
        nume: 'Topliţa',
        simplu: 'Toplita',
        comuna: 'Malureni'
      },
      {
        nume: 'Zărneşti',
        simplu: 'Zarnesti',
        comuna: 'Malureni'
      },
      {
        nume: 'Argeşelu',
        simplu: 'Argeselu',
        comuna: 'Maracineni'
      },
      {
        nume: 'Borleşti',
        simplu: 'Borlesti',
        comuna: 'Merisani'
      },
      {
        nume: 'Brăteasca',
        simplu: 'Brateasca',
        comuna: 'Merisani'
      },
      {
        nume: 'Capu Piscului',
        comuna: 'Merisani'
      },
      {
        nume: 'Crâmpotani',
        simplu: 'Crampotani',
        comuna: 'Merisani'
      },
      {
        nume: 'Dobrogostea',
        comuna: 'Merisani'
      },
      {
        nume: 'Malu Vânăt',
        simplu: 'Malu Vanat',
        comuna: 'Merisani'
      },
      {
        nume: 'Vâlcelele',
        simplu: 'Valcelele',
        comuna: 'Merisani'
      },
      {
        nume: 'Vărzaru',
        simplu: 'Varzaru',
        comuna: 'Merisani'
      },
      {
        nume: 'Brânzari',
        simplu: 'Branzari',
        comuna: 'Micesti'
      },
      {
        nume: 'Purcăreni',
        simplu: 'Purcareni',
        comuna: 'Micesti'
      },
      {
        nume: 'Păuleasca',
        simplu: 'Pauleasca',
        comuna: 'Micesti'
      },
      {
        nume: 'Drăghici',
        simplu: 'Draghici',
        comuna: 'Mihaesti'
      },
      {
        nume: 'Furnicoşi',
        simplu: 'Furnicosi',
        comuna: 'Mihaesti'
      },
      {
        nume: 'Rudeni',
        comuna: 'Mihaesti'
      },
      {
        nume: 'Valea Bradului',
        comuna: 'Mihaesti'
      },
      {
        nume: 'Valea Popii',
        comuna: 'Mihaesti'
      },
      {
        nume: 'Văcarea',
        simplu: 'Vacarea',
        comuna: 'Mihaesti'
      },
      {
        nume: 'Aluniş',
        simplu: 'Alunis',
        comuna: 'Mioarele'
      },
      {
        nume: 'Chilii',
        comuna: 'Mioarele'
      },
      {
        nume: 'Coceneşti',
        simplu: 'Cocenesti',
        comuna: 'Mioarele'
      },
      {
        nume: 'Măţău',
        simplu: 'Matau',
        comuna: 'Mioarele'
      },
      {
        nume: 'Suslăneşti',
        simplu: 'Suslanesti',
        comuna: 'Mioarele'
      },
      {
        nume: 'Clucereasa',
        comuna: 'Mioveni'
      },
      {
        nume: 'Colibaşi',
        simplu: 'Colibasi',
        comuna: 'Mioveni'
      },
      {
        nume: 'Făgetu',
        simplu: 'Fagetu',
        comuna: 'Mioveni'
      },
      {
        nume: 'Racoviţa',
        simplu: 'Racovita',
        comuna: 'Mioveni'
      },
      {
        nume: 'Surduleşti',
        simplu: 'Surdulesti',
        comuna: 'Mirosi'
      },
      {
        nume: 'Dealu Obejdeanului',
        comuna: 'Moraresti'
      },
      {
        nume: 'Deduleşti',
        simplu: 'Dedulesti',
        comuna: 'Moraresti'
      },
      {
        nume: 'Luminile',
        comuna: 'Moraresti'
      },
      {
        nume: 'Măncioiu',
        simplu: 'Mancioiu',
        comuna: 'Moraresti'
      },
      {
        nume: 'Săpunari',
        simplu: 'Sapunari',
        comuna: 'Moraresti'
      },
      {
        nume: 'Bătrâni',
        simplu: 'Batrani',
        comuna: 'Mosoaia'
      },
      {
        nume: 'Ciocănăi',
        simplu: 'Ciocanai',
        comuna: 'Mosoaia'
      },
      {
        nume: 'Dealu Viilor',
        comuna: 'Mosoaia'
      },
      {
        nume: 'Hinţeşti',
        simplu: 'Hintesti',
        comuna: 'Mosoaia'
      },
      {
        nume: 'Lăzăreşti',
        simplu: 'Lazaresti',
        comuna: 'Mosoaia'
      },
      {
        nume: 'Smeura',
        comuna: 'Mosoaia'
      },
      {
        nume: 'Babaroaga',
        comuna: 'Mozaceni'
      },
      {
        nume: 'Zidurile',
        comuna: 'Mozaceni'
      },
      {
        nume: 'Bolovăneşti',
        simplu: 'Bolovanesti',
        comuna: 'Musatesti'
      },
      {
        nume: 'Costeşti-Vâslan',
        simplu: 'Costesti-Vaslan',
        comuna: 'Musatesti'
      },
      {
        nume: 'Prosia',
        comuna: 'Musatesti'
      },
      {
        nume: 'Robaia',
        comuna: 'Musatesti'
      },
      {
        nume: 'Stroeşti',
        simplu: 'Stroesti',
        comuna: 'Musatesti'
      },
      {
        nume: 'Valea Faurului',
        comuna: 'Musatesti'
      },
      {
        nume: 'Valea Muscelului',
        comuna: 'Musatesti'
      },
      {
        nume: 'Valea lui Maş',
        simplu: 'Valea lui Mas',
        comuna: 'Musatesti'
      },
      {
        nume: 'Vâlsăneşti',
        simplu: 'Valsanesti',
        comuna: 'Musatesti'
      },
      {
        nume: 'Buta',
        comuna: 'Negrasi'
      },
      {
        nume: 'Bârlogu',
        simplu: 'Barlogu',
        comuna: 'Negrasi'
      },
      {
        nume: 'Mozacu',
        comuna: 'Negrasi'
      },
      {
        nume: 'Gruiu',
        comuna: 'Nucsoara'
      },
      {
        nume: 'Sboghiţeşti',
        simplu: 'Sboghitesti',
        comuna: 'Nucsoara'
      },
      {
        nume: 'Slatina',
        comuna: 'Nucsoara'
      },
      {
        nume: 'Ceauşeşti',
        simplu: 'Ceausesti',
        comuna: 'Oarja'
      },
      {
        nume: 'Bădeşti',
        simplu: 'Badesti',
        comuna: 'Pietrosani'
      },
      {
        nume: 'Găneşti',
        simplu: 'Ganesti',
        comuna: 'Pietrosani'
      },
      {
        nume: 'Retevoieşti',
        simplu: 'Retevoiesti',
        comuna: 'Pietrosani'
      },
      {
        nume: 'Vărzăroaia',
        simplu: 'Varzaroaia',
        comuna: 'Pietrosani'
      },
      {
        nume: 'Cepari',
        comuna: 'Poiana Lacului'
      },
      {
        nume: 'Cătunaşi',
        simplu: 'Catunasi',
        comuna: 'Poiana Lacului'
      },
      {
        nume: 'Dealu Oraşului',
        simplu: 'Dealu Orasului',
        comuna: 'Poiana Lacului'
      },
      {
        nume: 'Dealu Viilor',
        comuna: 'Poiana Lacului'
      },
      {
        nume: 'Dinculeşti',
        simplu: 'Dinculesti',
        comuna: 'Poiana Lacului'
      },
      {
        nume: 'Gâlceşti',
        simplu: 'Galcesti',
        comuna: 'Poiana Lacului'
      },
      {
        nume: 'Găleţeanu',
        simplu: 'Galeteanu',
        comuna: 'Poiana Lacului'
      },
      {
        nume: 'Gărdineşti',
        simplu: 'Gardinesti',
        comuna: 'Poiana Lacului'
      },
      {
        nume: 'Metofu',
        comuna: 'Poiana Lacului'
      },
      {
        nume: 'Păduroiu din Deal',
        simplu: 'Paduroiu din Deal',
        comuna: 'Poiana Lacului'
      },
      {
        nume: 'Păduroiu din Vale',
        simplu: 'Paduroiu din Vale',
        comuna: 'Poiana Lacului'
      },
      {
        nume: 'Sămara',
        simplu: 'Samara',
        comuna: 'Poiana Lacului'
      },
      {
        nume: 'Ceaureşti',
        simplu: 'Ceauresti',
        comuna: 'Poienarii De Arges'
      },
      {
        nume: 'Ioaniceşti',
        simplu: 'Ioanicesti',
        comuna: 'Poienarii De Arges'
      },
      {
        nume: 'Poienari',
        comuna: 'Poienarii De Arges'
      },
      {
        nume: 'Tomuleşti',
        simplu: 'Tomulesti',
        comuna: 'Poienarii De Arges'
      },
      {
        nume: 'Groşani',
        simplu: 'Grosani',
        comuna: 'Poienarii De Muscel'
      },
      {
        nume: 'Jugur',
        comuna: 'Poienarii De Muscel'
      },
      {
        nume: 'Poienari',
        comuna: 'Poienarii De Muscel'
      },
      {
        nume: 'Valea Îndărăt',
        simplu: 'Valea Indarat',
        comuna: 'Poienarii De Muscel'
      },
      {
        nume: 'Serbăneşti',
        simplu: 'Serbanesti',
        comuna: 'Poienarii De Muscel'
      },
      {
        nume: 'Palanga',
        comuna: 'Popesti'
      },
      {
        nume: 'Purcăreni',
        simplu: 'Purcareni',
        comuna: 'Popesti'
      },
      {
        nume: 'Slobozia',
        comuna: 'Popesti'
      },
      {
        nume: 'Albotele',
        comuna: 'Priboieni'
      },
      {
        nume: 'Paraschiveşti',
        simplu: 'Paraschivesti',
        comuna: 'Priboieni'
      },
      {
        nume: 'Pitoi',
        comuna: 'Priboieni'
      },
      {
        nume: 'Sămăila',
        simplu: 'Samaila',
        comuna: 'Priboieni'
      },
      {
        nume: 'Valea Mare',
        comuna: 'Priboieni'
      },
      {
        nume: 'Valea Nenii',
        comuna: 'Priboieni'
      },
      {
        nume: 'Valea Popii',
        comuna: 'Priboieni'
      },
      {
        nume: 'Adunaţi',
        simplu: 'Adunati',
        comuna: 'Raca'
      },
      {
        nume: 'Bucov',
        comuna: 'Raca'
      },
      {
        nume: 'Ciupa-Mănciulescu',
        simplu: 'Ciupa-Manciulescu',
        comuna: 'Ratesti'
      },
      {
        nume: 'Furdueşti',
        simplu: 'Furduesti',
        comuna: 'Ratesti'
      },
      {
        nume: 'Mavrodolu',
        comuna: 'Ratesti'
      },
      {
        nume: 'Nejlovelu',
        comuna: 'Ratesti'
      },
      {
        nume: 'Pătuleni',
        simplu: 'Patuleni',
        comuna: 'Ratesti'
      },
      {
        nume: 'Tigveni',
        comuna: 'Ratesti'
      },
      {
        nume: 'Deagu de Jos',
        comuna: 'Recea'
      },
      {
        nume: 'Deagu de Sus',
        comuna: 'Recea'
      },
      {
        nume: 'Goleasca',
        comuna: 'Recea'
      },
      {
        nume: 'Orodel',
        comuna: 'Recea'
      },
      {
        nume: 'Gliganu de Jos',
        comuna: 'Rociu'
      },
      {
        nume: 'Gliganu de Sus',
        comuna: 'Rociu'
      },
      {
        nume: 'Serbăneşti',
        simplu: 'Serbanesti',
        comuna: 'Rociu'
      },
      {
        nume: 'Sătic',
        simplu: 'Satic',
        comuna: 'Rucar'
      },
      {
        nume: 'Văleni',
        simplu: 'Valeni',
        comuna: 'Salatrucu'
      },
      {
        nume: 'Bănăreşti',
        simplu: 'Banaresti',
        comuna: 'Sapata'
      },
      {
        nume: 'Dealu Bradului',
        comuna: 'Sapata'
      },
      {
        nume: 'Drăghiceşti',
        simplu: 'Draghicesti',
        comuna: 'Sapata'
      },
      {
        nume: 'Găinuşa',
        simplu: 'Gainusa',
        comuna: 'Sapata'
      },
      {
        nume: 'Lipia',
        comuna: 'Sapata'
      },
      {
        nume: 'Mârţeşti',
        simplu: 'Martesti',
        comuna: 'Sapata'
      },
      {
        nume: 'Popeşti',
        simplu: 'Popesti',
        comuna: 'Sapata'
      },
      {
        nume: 'Turceşti',
        simplu: 'Turcesti',
        comuna: 'Sapata'
      },
      {
        nume: 'Burneşti',
        simplu: 'Burnesti',
        comuna: 'Schitu Golesti'
      },
      {
        nume: 'Costiţă',
        simplu: 'Costita',
        comuna: 'Schitu Golesti'
      },
      {
        nume: 'Loturi',
        comuna: 'Schitu Golesti'
      },
      {
        nume: 'Lăzăreşti',
        simplu: 'Lazaresti',
        comuna: 'Schitu Golesti'
      },
      {
        nume: 'Valea Pechii',
        comuna: 'Schitu Golesti'
      },
      {
        nume: 'Nigrişoara',
        simplu: 'Nigrisoara',
        comuna: 'Slobozia'
      },
      {
        nume: 'Dealu Frumos',
        comuna: 'Stalpeni'
      },
      {
        nume: 'Livezeni',
        comuna: 'Stalpeni'
      },
      {
        nume: 'Ogrezea',
        comuna: 'Stalpeni'
      },
      {
        nume: 'Opreşti',
        simplu: 'Opresti',
        comuna: 'Stalpeni'
      },
      {
        nume: 'Piţigaia',
        simplu: 'Pitigaia',
        comuna: 'Stalpeni'
      },
      {
        nume: 'Rădeşti',
        simplu: 'Radesti',
        comuna: 'Stalpeni'
      },
      {
        nume: 'Glavacioc',
        comuna: 'Stefan Cel Mare'
      },
      {
        nume: 'Enculeşti',
        simplu: 'Enculesti',
        comuna: 'Stefanesti'
      },
      {
        nume: 'Goleşti',
        simplu: 'Golesti',
        comuna: 'Stefanesti'
      },
      {
        nume: 'Izvorani',
        comuna: 'Stefanesti'
      },
      {
        nume: 'Valea Mare-Podgoria',
        comuna: 'Stefanesti'
      },
      {
        nume: 'Viişoara',
        simplu: 'Viisoara',
        comuna: 'Stefanesti'
      },
      {
        nume: 'Zăvoi',
        simplu: 'Zavoi',
        comuna: 'Stefanesti'
      },
      {
        nume: 'Stefăneştii Noi',
        simplu: 'Stefanestii Noi',
        comuna: 'Stefanesti'
      },
      {
        nume: 'Bădeni',
        simplu: 'Badeni',
        comuna: 'Stoenesti'
      },
      {
        nume: 'Coteneşti',
        simplu: 'Cotenesti',
        comuna: 'Stoenesti'
      },
      {
        nume: 'Lunca Gârtii',
        simplu: 'Lunca Gartii',
        comuna: 'Stoenesti'
      },
      {
        nume: 'Piatra',
        comuna: 'Stoenesti'
      },
      {
        nume: 'Slobozia',
        comuna: 'Stoenesti'
      },
      {
        nume: 'Valea Bădenilor',
        simplu: 'Valea Badenilor',
        comuna: 'Stoenesti'
      },
      {
        nume: 'Cochineşti',
        simplu: 'Cochinesti',
        comuna: 'Stolnici'
      },
      {
        nume: 'Cotmeana',
        comuna: 'Stolnici'
      },
      {
        nume: 'Fâlfani',
        simplu: 'Falfani',
        comuna: 'Stolnici'
      },
      {
        nume: 'Izbăşeşti',
        simplu: 'Izbasesti',
        comuna: 'Stolnici'
      },
      {
        nume: 'Vlăşcuţa',
        simplu: 'Vlascuta',
        comuna: 'Stolnici'
      },
      {
        nume: 'Ianculeşti',
        simplu: 'Ianculesti',
        comuna: 'Suici'
      },
      {
        nume: 'Paltenu',
        comuna: 'Suici'
      },
      {
        nume: 'Păuleni',
        simplu: 'Pauleni',
        comuna: 'Suici'
      },
      {
        nume: 'Rudeni',
        comuna: 'Suici'
      },
      {
        nume: 'Valea Calului',
        comuna: 'Suici'
      },
      {
        nume: 'Burdeşti',
        simplu: 'Burdesti',
        comuna: 'Suseni'
      },
      {
        nume: 'Cerşani',
        simplu: 'Cersani',
        comuna: 'Suseni'
      },
      {
        nume: 'Chiriţeşti',
        simplu: 'Chiritesti',
        comuna: 'Suseni'
      },
      {
        nume: 'Găleşeşti',
        simplu: 'Galesesti',
        comuna: 'Suseni'
      },
      {
        nume: 'Odăeni',
        simplu: 'Odaeni',
        comuna: 'Suseni'
      },
      {
        nume: 'Pădureni',
        simplu: 'Padureni',
        comuna: 'Suseni'
      },
      {
        nume: 'Stefăneşti',
        simplu: 'Stefanesti',
        comuna: 'Suseni'
      },
      {
        nume: 'Strâmbeni',
        simplu: 'Strambeni',
        comuna: 'Suseni'
      },
      {
        nume: 'Tuţuleşti',
        simplu: 'Tutulesti',
        comuna: 'Suseni'
      },
      {
        nume: 'Leşile',
        simplu: 'Lesile',
        comuna: 'Teiu'
      },
      {
        nume: 'Blaju',
        comuna: 'Tigveni'
      },
      {
        nume: 'Bârseştii de Jos',
        simplu: 'Barsestii de Jos',
        comuna: 'Tigveni'
      },
      {
        nume: 'Bârseştii de Sus',
        simplu: 'Barsestii de Sus',
        comuna: 'Tigveni'
      },
      {
        nume: 'Bădislava',
        simplu: 'Badislava',
        comuna: 'Tigveni'
      },
      {
        nume: 'Bălileşti',
        simplu: 'Balilesti',
        comuna: 'Tigveni'
      },
      {
        nume: 'Bălteni',
        simplu: 'Balteni',
        comuna: 'Tigveni'
      },
      {
        nume: 'Vlădeşti',
        simplu: 'Vladesti',
        comuna: 'Tigveni'
      },
      {
        nume: 'Bucşeneşti-Lotaşi',
        simplu: 'Bucsenesti-Lotasi',
        comuna: 'Titesti'
      },
      {
        nume: 'Cişmea',
        simplu: 'Cismea',
        comuna: 'Titesti'
      },
      {
        nume: 'Valea Mănăstirii',
        simplu: 'Valea Manastirii',
        comuna: 'Titesti'
      },
      {
        nume: 'Valea Stânii',
        simplu: 'Valea Stanii',
        comuna: 'Titesti'
      },
      {
        nume: 'Boţârcani',
        simplu: 'Botarcani',
        comuna: 'Topoloveni'
      },
      {
        nume: 'Crinteşti',
        simplu: 'Crintesti',
        comuna: 'Topoloveni'
      },
      {
        nume: 'Gorăneşti',
        simplu: 'Goranesti',
        comuna: 'Topoloveni'
      },
      {
        nume: 'Tigăneşti',
        simplu: 'Tiganesti',
        comuna: 'Topoloveni'
      },
      {
        nume: 'Braniştea',
        simplu: 'Branistea',
        comuna: 'Uda'
      },
      {
        nume: 'Băduleşti',
        simplu: 'Badulesti',
        comuna: 'Uda'
      },
      {
        nume: 'Bărăneşti',
        simplu: 'Baranesti',
        comuna: 'Uda'
      },
      {
        nume: 'Chiriţeşti',
        simplu: 'Chiritesti',
        comuna: 'Uda'
      },
      {
        nume: 'Cotu',
        comuna: 'Uda'
      },
      {
        nume: 'Dealu Bisericii',
        comuna: 'Uda'
      },
      {
        nume: 'Dealu Tolcesii',
        comuna: 'Uda'
      },
      {
        nume: 'Diconeşti',
        simplu: 'Diconesti',
        comuna: 'Uda'
      },
      {
        nume: 'Gorani',
        comuna: 'Uda'
      },
      {
        nume: 'Greabăn',
        simplu: 'Greaban',
        comuna: 'Uda'
      },
      {
        nume: 'Lunguleşti',
        simplu: 'Lungulesti',
        comuna: 'Uda'
      },
      {
        nume: 'Miercani',
        comuna: 'Uda'
      },
      {
        nume: 'Romana',
        comuna: 'Uda'
      },
      {
        nume: 'Râjleţu-Govora',
        simplu: 'Rajletu-Govora',
        comuna: 'Uda'
      },
      {
        nume: 'Săliştea',
        simplu: 'Salistea',
        comuna: 'Uda'
      },
      {
        nume: 'Colţu',
        simplu: 'Coltu',
        comuna: 'Ungheni'
      },
      {
        nume: 'Goia',
        comuna: 'Ungheni'
      },
      {
        nume: 'Găujani',
        simplu: 'Gaujani',
        comuna: 'Ungheni'
      },
      {
        nume: 'Humele',
        comuna: 'Ungheni'
      },
      {
        nume: 'Satu Nou',
        comuna: 'Ungheni'
      },
      {
        nume: 'Bolculeşti',
        simplu: 'Bolculesti',
        comuna: 'Valea Danului'
      },
      {
        nume: 'Borobăneşti',
        simplu: 'Borobanesti',
        comuna: 'Valea Danului'
      },
      {
        nume: 'Băniceşti',
        simplu: 'Banicesti',
        comuna: 'Valea Danului'
      },
      {
        nume: 'Verneşti',
        simplu: 'Vernesti',
        comuna: 'Valea Danului'
      },
      {
        nume: 'Borovineşti',
        simplu: 'Borovinesti',
        comuna: 'Valea Iasului'
      },
      {
        nume: 'Bădila',
        simplu: 'Badila',
        comuna: 'Valea Iasului'
      },
      {
        nume: 'Bărbălăteşti',
        simplu: 'Barbalatesti',
        comuna: 'Valea Iasului'
      },
      {
        nume: 'Cerbureni',
        comuna: 'Valea Iasului'
      },
      {
        nume: 'Mustăţeşti',
        simplu: 'Mustatesti',
        comuna: 'Valea Iasului'
      },
      {
        nume: 'Ruginoasa',
        comuna: 'Valea Iasului'
      },
      {
        nume: 'Ungureni',
        comuna: 'Valea Iasului'
      },
      {
        nume: 'Valea Uleiului',
        comuna: 'Valea Iasului'
      },
      {
        nume: 'Bilceşti',
        simplu: 'Bilcesti',
        comuna: 'Valea Mare Pravat'
      },
      {
        nume: 'Colnic',
        comuna: 'Valea Mare Pravat'
      },
      {
        nume: 'Fântânea',
        simplu: 'Fantanea',
        comuna: 'Valea Mare Pravat'
      },
      {
        nume: 'Gura Pravăţ',
        simplu: 'Gura Pravat',
        comuna: 'Valea Mare Pravat'
      },
      {
        nume: 'Nămăeşti',
        simplu: 'Namaesti',
        comuna: 'Valea Mare Pravat'
      },
      {
        nume: 'Pietroasa',
        comuna: 'Valea Mare Pravat'
      },
      {
        nume: 'Selari',
        simplu: 'Selari',
        comuna: 'Valea Mare Pravat'
      },
      {
        nume: 'Blejani',
        comuna: 'Vedea'
      },
      {
        nume: 'Bureţeşti',
        simplu: 'Buretesti',
        comuna: 'Vedea'
      },
      {
        nume: 'Bădicea',
        simplu: 'Badicea',
        comuna: 'Vedea'
      },
      {
        nume: 'Chiriţeşti',
        simplu: 'Chiritesti',
        comuna: 'Vedea'
      },
      {
        nume: 'Chiţani',
        simplu: 'Chitani',
        comuna: 'Vedea'
      },
      {
        nume: 'Ciureşti',
        simplu: 'Ciuresti',
        comuna: 'Vedea'
      },
      {
        nume: 'Dincani',
        comuna: 'Vedea'
      },
      {
        nume: 'Fata',
        comuna: 'Vedea'
      },
      {
        nume: 'Frătici',
        simplu: 'Fratici',
        comuna: 'Vedea'
      },
      {
        nume: 'Izvoru de Jos',
        comuna: 'Vedea'
      },
      {
        nume: 'Izvoru de Sus',
        comuna: 'Vedea'
      },
      {
        nume: 'Lungani',
        comuna: 'Vedea'
      },
      {
        nume: 'Mogoşeşti',
        simplu: 'Mogosesti',
        comuna: 'Vedea'
      },
      {
        nume: 'Prodani',
        comuna: 'Vedea'
      },
      {
        nume: 'Răţoi',
        simplu: 'Ratoi',
        comuna: 'Vedea'
      },
      {
        nume: 'Vaţa',
        simplu: 'Vata',
        comuna: 'Vedea'
      },
      {
        nume: 'Veţişoara',
        simplu: 'Vetisoara',
        comuna: 'Vedea'
      },
      {
        nume: 'Vârşeşti',
        simplu: 'Varsesti',
        comuna: 'Vedea'
      },
      {
        nume: 'Coteasca',
        comuna: 'Vladesti'
      },
      {
        nume: 'Drăghescu',
        simplu: 'Draghescu',
        comuna: 'Vladesti'
      },
      {
        nume: 'Putina',
        comuna: 'Vladesti'
      },
      {
        nume: 'Bârzeşti',
        simplu: 'Barzesti',
        comuna: 'Vulturesti'
      },
      {
        nume: 'Huluba',
        comuna: 'Vulturesti'
      }
    ]
  },
  {
    auto: 'BC',
    nume: 'Bacău',
    localitati: [
      {
        nume: 'Agăş',
        simplu: 'Agas'
      },
      {
        nume: 'Ardeoani'
      },
      {
        nume: 'Asău',
        simplu: 'Asau'
      },
      {
        nume: 'Bacău',
        simplu: 'Bacau'
      },
      {
        nume: 'Balcani'
      },
      {
        nume: 'Bereşti-Bistriţa',
        simplu: 'Beresti-Bistrita'
      },
      {
        nume: 'Bereşti-Tazlău',
        simplu: 'Beresti-Tazlau'
      },
      {
        nume: 'Berzunţi',
        simplu: 'Berzunti'
      },
      {
        nume: 'Blăgeşti',
        simplu: 'Blagesti'
      },
      {
        nume: 'Bogdăneşti',
        simplu: 'Bogdanesti'
      },
      {
        nume: 'Brusturoasa'
      },
      {
        nume: 'Buhoci'
      },
      {
        nume: 'Buhuşi',
        simplu: 'Buhusi'
      },
      {
        nume: 'Bârsăneşti',
        simplu: 'Barsanesti'
      },
      {
        nume: 'Caşin',
        simplu: 'Casin'
      },
      {
        nume: 'Cleja'
      },
      {
        nume: 'Coloneşti',
        simplu: 'Colonesti'
      },
      {
        nume: 'Comăneşti',
        simplu: 'Comanesti'
      },
      {
        nume: 'Corbasca'
      },
      {
        nume: 'Coţofăneşti',
        simplu: 'Cotofanesti'
      },
      {
        nume: 'Căiuţi',
        simplu: 'Caiuti'
      },
      {
        nume: 'Dealu Morii'
      },
      {
        nume: 'Dofteana'
      },
      {
        nume: 'Dămieneşti',
        simplu: 'Damienesti'
      },
      {
        nume: 'Dărmăneşti',
        simplu: 'Darmanesti'
      },
      {
        nume: 'Faraoani'
      },
      {
        nume: 'Filipeni'
      },
      {
        nume: 'Filipeşti',
        simplu: 'Filipesti'
      },
      {
        nume: 'Glăvăneşti',
        simplu: 'Glavanesti'
      },
      {
        nume: 'Gura Văii',
        simplu: 'Gura Vaii'
      },
      {
        nume: 'Gârleni',
        simplu: 'Garleni'
      },
      {
        nume: 'Găiceana',
        simplu: 'Gaiceana'
      },
      {
        nume: 'Helegiu'
      },
      {
        nume: 'Hemeiuş',
        simplu: 'Hemeius'
      },
      {
        nume: 'Horgeşti',
        simplu: 'Horgesti'
      },
      {
        nume: 'Huruieşti',
        simplu: 'Huruiesti'
      },
      {
        nume: 'Izvoru Berheciului'
      },
      {
        nume: 'Letea Veche'
      },
      {
        nume: 'Lipova'
      },
      {
        nume: 'Livezi'
      },
      {
        nume: 'Luizi-Călugăra',
        simplu: 'Luizi-Calugara'
      },
      {
        nume: 'Moineşti',
        simplu: 'Moinesti'
      },
      {
        nume: 'Motoşeni',
        simplu: 'Motoseni'
      },
      {
        nume: 'Măgireşti',
        simplu: 'Magiresti'
      },
      {
        nume: 'Măgura',
        simplu: 'Magura'
      },
      {
        nume: 'Mănăstirea Caşin',
        simplu: 'Manastirea Casin'
      },
      {
        nume: 'Mărgineni',
        simplu: 'Margineni'
      },
      {
        nume: 'Negri'
      },
      {
        nume: 'Nicolae Bălcescu',
        simplu: 'Nicolae Balcescu'
      },
      {
        nume: 'Oituz'
      },
      {
        nume: 'Onceşti',
        simplu: 'Oncesti'
      },
      {
        nume: 'Oneşti',
        simplu: 'Onesti'
      },
      {
        nume: 'Orbeni'
      },
      {
        nume: 'Palanca'
      },
      {
        nume: 'Parava'
      },
      {
        nume: 'Parincea'
      },
      {
        nume: 'Plopana'
      },
      {
        nume: 'Podu Turcului'
      },
      {
        nume: 'Poduri'
      },
      {
        nume: 'Pânceşti',
        simplu: 'Pancesti'
      },
      {
        nume: 'Pârgăreşti',
        simplu: 'Pargaresti'
      },
      {
        nume: 'Pârjol',
        simplu: 'Parjol'
      },
      {
        nume: 'Racova'
      },
      {
        nume: 'Roşiori',
        simplu: 'Rosiori'
      },
      {
        nume: 'Răchitoasa',
        simplu: 'Rachitoasa'
      },
      {
        nume: 'Răcăciuni',
        simplu: 'Racaciuni'
      },
      {
        nume: 'Sascut'
      },
      {
        nume: 'Scorţeni',
        simplu: 'Scorteni'
      },
      {
        nume: 'Secuieni'
      },
      {
        nume: 'Solonţ',
        simplu: 'Solont'
      },
      {
        nume: 'Strugari'
      },
      {
        nume: 'Stănişeşti',
        simplu: 'Stanisesti'
      },
      {
        nume: 'Sănduleni',
        simplu: 'Sanduleni'
      },
      {
        nume: 'Săuceşti',
        simplu: 'Saucesti'
      },
      {
        nume: 'Tamaşi',
        simplu: 'Tamasi'
      },
      {
        nume: 'Traian'
      },
      {
        nume: 'Târgu Ocna',
        simplu: 'Targu Ocna'
      },
      {
        nume: 'Târgu Trotuş',
        simplu: 'Targu Trotus'
      },
      {
        nume: 'Tătărăşti',
        simplu: 'Tatarasti'
      },
      {
        nume: 'Ungureni'
      },
      {
        nume: 'Urecheşti',
        simplu: 'Urechesti'
      },
      {
        nume: 'Valea Seacă',
        simplu: 'Valea Seaca'
      },
      {
        nume: 'Vultureni'
      },
      {
        nume: 'Zemeş',
        simplu: 'Zemes'
      },
      {
        nume: 'Stefan cel Mare',
        simplu: 'Stefan cel Mare'
      },
      {
        nume: 'Beleghet',
        comuna: 'Agas'
      },
      {
        nume: 'Cotumba',
        comuna: 'Agas'
      },
      {
        nume: 'Coşnea',
        simplu: 'Cosnea',
        comuna: 'Agas'
      },
      {
        nume: 'Diaconeşti',
        simplu: 'Diaconesti',
        comuna: 'Agas'
      },
      {
        nume: 'Goioasa',
        comuna: 'Agas'
      },
      {
        nume: 'Preluci',
        comuna: 'Agas'
      },
      {
        nume: 'Sulţa',
        simplu: 'Sulta',
        comuna: 'Agas'
      },
      {
        nume: 'Leontineşti',
        simplu: 'Leontinesti',
        comuna: 'Ardeoani'
      },
      {
        nume: 'Apa Asău',
        simplu: 'Apa Asau',
        comuna: 'Asau'
      },
      {
        nume: 'Ciobănuş',
        simplu: 'Ciobanus',
        comuna: 'Asau'
      },
      {
        nume: 'Lunca Asău',
        simplu: 'Lunca Asau',
        comuna: 'Asau'
      },
      {
        nume: 'Păltiniş',
        simplu: 'Paltinis',
        comuna: 'Asau'
      },
      {
        nume: 'Straja',
        comuna: 'Asau'
      },
      {
        nume: 'Frumoasa',
        comuna: 'Balcani'
      },
      {
        nume: 'Ludaşi',
        simplu: 'Ludasi',
        comuna: 'Balcani'
      },
      {
        nume: 'Schitu Frumoasa',
        comuna: 'Balcani'
      },
      {
        nume: 'Albele',
        comuna: 'Barsanesti'
      },
      {
        nume: 'Brăteşti',
        simplu: 'Bratesti',
        comuna: 'Barsanesti'
      },
      {
        nume: 'Caraclău',
        simplu: 'Caraclau',
        comuna: 'Barsanesti'
      },
      {
        nume: 'Brad',
        comuna: 'Beresti-Bistrita'
      },
      {
        nume: 'Ciumaşi',
        simplu: 'Ciumasi',
        comuna: 'Beresti-Bistrita'
      },
      {
        nume: 'Climeşti',
        simplu: 'Climesti',
        comuna: 'Beresti-Bistrita'
      },
      {
        nume: 'Dumbrava',
        comuna: 'Beresti-Bistrita'
      },
      {
        nume: 'Făgeţel',
        simplu: 'Fagetel',
        comuna: 'Beresti-Bistrita'
      },
      {
        nume: 'Iteşti',
        simplu: 'Itesti',
        comuna: 'Beresti-Bistrita'
      },
      {
        nume: 'Pădureni',
        simplu: 'Padureni',
        comuna: 'Beresti-Bistrita'
      },
      {
        nume: 'Boşoteni',
        simplu: 'Bosoteni',
        comuna: 'Beresti-Tazlau'
      },
      {
        nume: 'Enăcheşti',
        simplu: 'Enachesti',
        comuna: 'Beresti-Tazlau'
      },
      {
        nume: 'Prisaca',
        comuna: 'Beresti-Tazlau'
      },
      {
        nume: 'Româneşti',
        simplu: 'Romanesti',
        comuna: 'Beresti-Tazlau'
      },
      {
        nume: 'Tescani',
        comuna: 'Beresti-Tazlau'
      },
      {
        nume: 'Turluianu',
        comuna: 'Beresti-Tazlau'
      },
      {
        nume: 'Buda',
        comuna: 'Berzunti'
      },
      {
        nume: 'Dragomir',
        comuna: 'Berzunti'
      },
      {
        nume: 'Buda',
        comuna: 'Blagesti'
      },
      {
        nume: 'Poiana Negustorului',
        comuna: 'Blagesti'
      },
      {
        nume: 'Valea lui Ion',
        comuna: 'Blagesti'
      },
      {
        nume: 'Târdenii Mari',
        simplu: 'Tardenii Mari',
        comuna: 'Blagesti'
      },
      {
        nume: 'Filipeşti',
        simplu: 'Filipesti',
        comuna: 'Bogdanesti'
      },
      {
        nume: 'Buruieniş',
        simplu: 'Buruienis',
        comuna: 'Brusturoasa'
      },
      {
        nume: 'Buruienişu de Sus',
        simplu: 'Buruienisu de Sus',
        comuna: 'Brusturoasa'
      },
      {
        nume: 'Camenca',
        comuna: 'Brusturoasa'
      },
      {
        nume: 'Cuchiniş',
        simplu: 'Cuchinis',
        comuna: 'Brusturoasa'
      },
      {
        nume: 'Hângăneşti',
        simplu: 'Hanganesti',
        comuna: 'Brusturoasa'
      },
      {
        nume: 'Bijghir',
        comuna: 'Buhoci'
      },
      {
        nume: 'Buhocel',
        comuna: 'Buhoci'
      },
      {
        nume: 'Coteni',
        comuna: 'Buhoci'
      },
      {
        nume: 'Dospineşti',
        simplu: 'Dospinesti',
        comuna: 'Buhoci'
      },
      {
        nume: 'Marginea',
        comuna: 'Buhusi'
      },
      {
        nume: 'Runcu',
        comuna: 'Buhusi'
      },
      {
        nume: 'Blidari',
        comuna: 'Caiuti'
      },
      {
        nume: 'Boiştea',
        simplu: 'Boistea',
        comuna: 'Caiuti'
      },
      {
        nume: 'Floreşti',
        simplu: 'Floresti',
        comuna: 'Caiuti'
      },
      {
        nume: 'Heltiu',
        comuna: 'Caiuti'
      },
      {
        nume: 'Mărceşti',
        simplu: 'Marcesti',
        comuna: 'Caiuti'
      },
      {
        nume: 'Popeni',
        comuna: 'Caiuti'
      },
      {
        nume: 'Pralea',
        comuna: 'Caiuti'
      },
      {
        nume: 'Vrânceni',
        simplu: 'Vranceni',
        comuna: 'Caiuti'
      },
      {
        nume: 'Curiţa',
        simplu: 'Curita',
        comuna: 'Casin'
      },
      {
        nume: 'Somuşca',
        simplu: 'Somusca',
        comuna: 'Cleja'
      },
      {
        nume: 'Valea Mică',
        simplu: 'Valea Mica',
        comuna: 'Cleja'
      },
      {
        nume: 'Călini',
        simplu: 'Calini',
        comuna: 'Colonesti'
      },
      {
        nume: 'Poiana',
        comuna: 'Colonesti'
      },
      {
        nume: 'Satu Nou',
        comuna: 'Colonesti'
      },
      {
        nume: 'Spria',
        comuna: 'Colonesti'
      },
      {
        nume: 'Valea Mare',
        comuna: 'Colonesti'
      },
      {
        nume: 'Zăpodia',
        simplu: 'Zapodia',
        comuna: 'Colonesti'
      },
      {
        nume: 'Podei',
        comuna: 'Comanesti'
      },
      {
        nume: 'Vermeşti',
        simplu: 'Vermesti',
        comuna: 'Comanesti'
      },
      {
        nume: 'Băcioiu',
        simplu: 'Bacioiu',
        comuna: 'Corbasca'
      },
      {
        nume: 'Marvila',
        comuna: 'Corbasca'
      },
      {
        nume: 'Pogleţ',
        simplu: 'Poglet',
        comuna: 'Corbasca'
      },
      {
        nume: 'Rogoaza',
        comuna: 'Corbasca'
      },
      {
        nume: 'Scărişoara',
        simplu: 'Scarisoara',
        comuna: 'Corbasca'
      },
      {
        nume: 'Vâlcele',
        simplu: 'Valcele',
        comuna: 'Corbasca'
      },
      {
        nume: 'Boiştea de Jos',
        simplu: 'Boistea de Jos',
        comuna: 'Cotofanesti'
      },
      {
        nume: 'Borşani',
        simplu: 'Borsani',
        comuna: 'Cotofanesti'
      },
      {
        nume: 'Bâlca',
        simplu: 'Balca',
        comuna: 'Cotofanesti'
      },
      {
        nume: 'Tămăşoaia',
        simplu: 'Tamasoaia',
        comuna: 'Cotofanesti'
      },
      {
        nume: 'Călugăreni',
        simplu: 'Calugareni',
        comuna: 'Damienesti'
      },
      {
        nume: 'Drăgeşti',
        simplu: 'Dragesti',
        comuna: 'Damienesti'
      },
      {
        nume: 'Pădureni',
        simplu: 'Padureni',
        comuna: 'Damienesti'
      },
      {
        nume: 'Dărmăneasca',
        simplu: 'Darmaneasca',
        comuna: 'Darmanesti'
      },
      {
        nume: 'Lăpoş',
        simplu: 'Lapos',
        comuna: 'Darmanesti'
      },
      {
        nume: 'Plopu',
        comuna: 'Darmanesti'
      },
      {
        nume: 'Păgubeni',
        simplu: 'Pagubeni',
        comuna: 'Darmanesti'
      },
      {
        nume: 'Sălătruc',
        simplu: 'Salatruc',
        comuna: 'Darmanesti'
      },
      {
        nume: 'Banca',
        comuna: 'Dealu Morii'
      },
      {
        nume: 'Blaga',
        comuna: 'Dealu Morii'
      },
      {
        nume: 'Boboş',
        simplu: 'Bobos',
        comuna: 'Dealu Morii'
      },
      {
        nume: 'Bodeasa',
        comuna: 'Dealu Morii'
      },
      {
        nume: 'Bostăneşti',
        simplu: 'Bostanesti',
        comuna: 'Dealu Morii'
      },
      {
        nume: 'Bălăneşti',
        simplu: 'Balanesti',
        comuna: 'Dealu Morii'
      },
      {
        nume: 'Calapodeşti',
        simplu: 'Calapodesti',
        comuna: 'Dealu Morii'
      },
      {
        nume: 'Căuia',
        simplu: 'Cauia',
        comuna: 'Dealu Morii'
      },
      {
        nume: 'Dorofei',
        comuna: 'Dealu Morii'
      },
      {
        nume: 'Ghionoaia',
        comuna: 'Dealu Morii'
      },
      {
        nume: 'Grădeşti',
        simplu: 'Gradesti',
        comuna: 'Dealu Morii'
      },
      {
        nume: 'Neguleşti',
        simplu: 'Negulesti',
        comuna: 'Dealu Morii'
      },
      {
        nume: 'Tăvădăreşti',
        simplu: 'Tavadaresti',
        comuna: 'Dealu Morii'
      },
      {
        nume: 'Bogata',
        comuna: 'Dofteana'
      },
      {
        nume: 'Cucuieţi',
        simplu: 'Cucuieti',
        comuna: 'Dofteana'
      },
      {
        nume: 'Hăghiac',
        simplu: 'Haghiac',
        comuna: 'Dofteana'
      },
      {
        nume: 'Larga',
        comuna: 'Dofteana'
      },
      {
        nume: 'Seaca',
        comuna: 'Dofteana'
      },
      {
        nume: 'Stefan Vodă',
        simplu: 'Stefan Voda',
        comuna: 'Dofteana'
      },
      {
        nume: 'Brad',
        comuna: 'Filipeni'
      },
      {
        nume: 'Bălaia',
        simplu: 'Balaia',
        comuna: 'Filipeni'
      },
      {
        nume: 'Frunteşti',
        simplu: 'Fruntesti',
        comuna: 'Filipeni'
      },
      {
        nume: 'Mărăşti',
        simplu: 'Marasti',
        comuna: 'Filipeni'
      },
      {
        nume: 'Pădureni',
        simplu: 'Padureni',
        comuna: 'Filipeni'
      },
      {
        nume: 'Slobozia',
        comuna: 'Filipeni'
      },
      {
        nume: 'Valea Boţului',
        simplu: 'Valea Botului',
        comuna: 'Filipeni'
      },
      {
        nume: 'Boanţa',
        simplu: 'Boanta',
        comuna: 'Filipesti'
      },
      {
        nume: 'Corneşti',
        simplu: 'Cornesti',
        comuna: 'Filipesti'
      },
      {
        nume: 'Cotu Grosului',
        comuna: 'Filipesti'
      },
      {
        nume: 'Cârligi',
        simplu: 'Carligi',
        comuna: 'Filipesti'
      },
      {
        nume: 'Galbeni',
        comuna: 'Filipesti'
      },
      {
        nume: 'Hârleşti',
        simplu: 'Harlesti',
        comuna: 'Filipesti'
      },
      {
        nume: 'Onişcani',
        simplu: 'Oniscani',
        comuna: 'Filipesti'
      },
      {
        nume: 'Arini',
        comuna: 'Gaiceana'
      },
      {
        nume: 'Huţu',
        simplu: 'Hutu',
        comuna: 'Gaiceana'
      },
      {
        nume: 'Popeşti',
        simplu: 'Popesti',
        comuna: 'Gaiceana'
      },
      {
        nume: 'Gârlenii de Sus',
        simplu: 'Garlenii de Sus',
        comuna: 'Garleni'
      },
      {
        nume: 'Lespezi',
        comuna: 'Garleni'
      },
      {
        nume: 'Surina',
        simplu: 'Surina',
        comuna: 'Garleni'
      },
      {
        nume: 'Bolovăniş',
        simplu: 'Bolovanis',
        comuna: 'Ghimes-Faget'
      },
      {
        nume: 'Făget',
        simplu: 'Faget',
        comuna: 'Ghimes-Faget'
      },
      {
        nume: 'Făgetu de Sus',
        simplu: 'Fagetu de Sus',
        comuna: 'Ghimes-Faget'
      },
      {
        nume: 'Ghimeş',
        simplu: 'Ghimes',
        comuna: 'Ghimes-Faget'
      },
      {
        nume: 'Răchitiş',
        simplu: 'Rachitis',
        comuna: 'Ghimes-Faget'
      },
      {
        nume: 'Tărhăuşi',
        simplu: 'Tarhausi',
        comuna: 'Ghimes-Faget'
      },
      {
        nume: 'Frumuşelu',
        simplu: 'Frumuselu',
        comuna: 'Glavanesti'
      },
      {
        nume: 'Muncelu',
        comuna: 'Glavanesti'
      },
      {
        nume: 'Putredeni',
        comuna: 'Glavanesti'
      },
      {
        nume: 'Răzeşu',
        simplu: 'Razesu',
        comuna: 'Glavanesti'
      },
      {
        nume: 'Capăta',
        simplu: 'Capata',
        comuna: 'Gura Vaii'
      },
      {
        nume: 'Dumbrava',
        comuna: 'Gura Vaii'
      },
      {
        nume: 'Motoceşti',
        simplu: 'Motocesti',
        comuna: 'Gura Vaii'
      },
      {
        nume: 'Păltinata',
        simplu: 'Paltinata',
        comuna: 'Gura Vaii'
      },
      {
        nume: 'Temelia',
        comuna: 'Gura Vaii'
      },
      {
        nume: 'Brătila',
        simplu: 'Bratila',
        comuna: 'Helegiu'
      },
      {
        nume: 'Deleni',
        comuna: 'Helegiu'
      },
      {
        nume: 'Drăgugeşti',
        simplu: 'Dragugesti',
        comuna: 'Helegiu'
      },
      {
        nume: 'Fântânele',
        simplu: 'Fantanele',
        comuna: 'Hemeius'
      },
      {
        nume: 'Lilieci',
        comuna: 'Hemeius'
      },
      {
        nume: 'Bazga',
        comuna: 'Horgesti'
      },
      {
        nume: 'Galeri',
        comuna: 'Horgesti'
      },
      {
        nume: 'Mărăscu',
        simplu: 'Marascu',
        comuna: 'Horgesti'
      },
      {
        nume: 'Recea',
        comuna: 'Horgesti'
      },
      {
        nume: 'Răcătău de Jos',
        simplu: 'Racatau de Jos',
        comuna: 'Horgesti'
      },
      {
        nume: 'Răcătău-Răzeşi',
        simplu: 'Racatau-Razesi',
        comuna: 'Horgesti'
      },
      {
        nume: 'Sohodor',
        comuna: 'Horgesti'
      },
      {
        nume: 'Căpoteşti',
        simplu: 'Capotesti',
        comuna: 'Huruiesti'
      },
      {
        nume: 'Floreşti',
        simplu: 'Floresti',
        comuna: 'Huruiesti'
      },
      {
        nume: 'Fundoaia',
        comuna: 'Huruiesti'
      },
      {
        nume: 'Ocheni',
        comuna: 'Huruiesti'
      },
      {
        nume: 'Perchiu',
        comuna: 'Huruiesti'
      },
      {
        nume: 'Prădaiş',
        simplu: 'Pradais',
        comuna: 'Huruiesti'
      },
      {
        nume: 'Antoheşti',
        simplu: 'Antohesti',
        comuna: 'Izvoru Berheciului'
      },
      {
        nume: 'Băimac',
        simplu: 'Baimac',
        comuna: 'Izvoru Berheciului'
      },
      {
        nume: 'Făghieni',
        simplu: 'Faghieni',
        comuna: 'Izvoru Berheciului'
      },
      {
        nume: 'Obârşia',
        simplu: 'Obarsia',
        comuna: 'Izvoru Berheciului'
      },
      {
        nume: 'Oţeleşti',
        simplu: 'Otelesti',
        comuna: 'Izvoru Berheciului'
      },
      {
        nume: 'Pădureni',
        simplu: 'Padureni',
        comuna: 'Izvoru Berheciului'
      },
      {
        nume: 'Holt',
        comuna: 'Letea Veche'
      },
      {
        nume: 'Radomireşti',
        simplu: 'Radomiresti',
        comuna: 'Letea Veche'
      },
      {
        nume: 'Ruşi-Ciutea',
        simplu: 'Rusi-Ciutea',
        comuna: 'Letea Veche'
      },
      {
        nume: 'Siretu',
        comuna: 'Letea Veche'
      },
      {
        nume: 'Mâlosu',
        simplu: 'Malosu',
        comuna: 'Lipova'
      },
      {
        nume: 'Satu Nou',
        comuna: 'Lipova'
      },
      {
        nume: 'Valea Caselor',
        comuna: 'Lipova'
      },
      {
        nume: 'Valea Hogei',
        comuna: 'Lipova'
      },
      {
        nume: 'Valea Moşneagului',
        simplu: 'Valea Mosneagului',
        comuna: 'Lipova'
      },
      {
        nume: 'Valea Mărului',
        simplu: 'Valea Marului',
        comuna: 'Lipova'
      },
      {
        nume: 'Bălăneasa',
        simplu: 'Balaneasa',
        comuna: 'Livezi'
      },
      {
        nume: 'Orăşa',
        simplu: 'Orasa',
        comuna: 'Livezi'
      },
      {
        nume: 'Poiana',
        comuna: 'Livezi'
      },
      {
        nume: 'Prajoaia',
        comuna: 'Livezi'
      },
      {
        nume: 'Scăriga',
        simplu: 'Scariga',
        comuna: 'Livezi'
      },
      {
        nume: 'Osebiţi',
        simplu: 'Osebiti',
        comuna: 'Luizi-Calugara'
      },
      {
        nume: 'Prăjeşti',
        simplu: 'Prajesti',
        comuna: 'Magiresti'
      },
      {
        nume: 'Stăneşti',
        simplu: 'Stanesti',
        comuna: 'Magiresti'
      },
      {
        nume: 'Valea Arinilor',
        comuna: 'Magiresti'
      },
      {
        nume: 'Sesuri',
        simplu: 'Sesuri',
        comuna: 'Magiresti'
      },
      {
        nume: 'Crihan',
        comuna: 'Magura'
      },
      {
        nume: 'Dealu-Mare',
        comuna: 'Magura'
      },
      {
        nume: 'Sohodol',
        comuna: 'Magura'
      },
      {
        nume: 'Lupeşti',
        simplu: 'Lupesti',
        comuna: 'Manastirea Casin'
      },
      {
        nume: 'Pârvuleşti',
        simplu: 'Parvulesti',
        comuna: 'Manastirea Casin'
      },
      {
        nume: 'Scutaru',
        comuna: 'Manastirea Casin'
      },
      {
        nume: 'Baraţi',
        simplu: 'Barati',
        comuna: 'Margineni'
      },
      {
        nume: 'Luncani',
        comuna: 'Margineni'
      },
      {
        nume: 'Podiş',
        simplu: 'Podis',
        comuna: 'Margineni'
      },
      {
        nume: 'Poiana',
        comuna: 'Margineni'
      },
      {
        nume: 'Pădureni',
        simplu: 'Padureni',
        comuna: 'Margineni'
      },
      {
        nume: 'Trebeş',
        simplu: 'Trebes',
        comuna: 'Margineni'
      },
      {
        nume: 'Valea Budului',
        comuna: 'Margineni'
      },
      {
        nume: 'Găzărie',
        simplu: 'Gazarie',
        comuna: 'Moinesti'
      },
      {
        nume: 'Bâcleşti',
        simplu: 'Baclesti',
        comuna: 'Motoseni'
      },
      {
        nume: 'Chetreni',
        comuna: 'Motoseni'
      },
      {
        nume: 'Chicerea',
        comuna: 'Motoseni'
      },
      {
        nume: 'Cociu',
        comuna: 'Motoseni'
      },
      {
        nume: 'Cornăţelu',
        simplu: 'Cornatelu',
        comuna: 'Motoseni'
      },
      {
        nume: 'Fundătura',
        simplu: 'Fundatura',
        comuna: 'Motoseni'
      },
      {
        nume: 'Fântânele',
        simplu: 'Fantanele',
        comuna: 'Motoseni'
      },
      {
        nume: 'Gura Crăieşti',
        simplu: 'Gura Craiesti',
        comuna: 'Motoseni'
      },
      {
        nume: 'Poiana',
        comuna: 'Motoseni'
      },
      {
        nume: 'Praja',
        comuna: 'Motoseni'
      },
      {
        nume: 'Rotăria',
        simplu: 'Rotaria',
        comuna: 'Motoseni'
      },
      {
        nume: 'Sendreşti',
        simplu: 'Sendresti',
        comuna: 'Motoseni'
      },
      {
        nume: 'Tepoaia',
        simplu: 'Tepoaia',
        comuna: 'Motoseni'
      },
      {
        nume: 'Brad',
        comuna: 'Negri'
      },
      {
        nume: 'Călineşti',
        simplu: 'Calinesti',
        comuna: 'Negri'
      },
      {
        nume: 'Mâgla',
        simplu: 'Magla',
        comuna: 'Negri'
      },
      {
        nume: 'Poiana',
        comuna: 'Negri'
      },
      {
        nume: 'Ursoaia',
        comuna: 'Negri'
      },
      {
        nume: 'Buchila',
        comuna: 'Nicolae Balcescu'
      },
      {
        nume: 'Bălţata',
        simplu: 'Baltata',
        comuna: 'Nicolae Balcescu'
      },
      {
        nume: 'Galbeni',
        comuna: 'Nicolae Balcescu'
      },
      {
        nume: 'Lărguţa',
        simplu: 'Larguta',
        comuna: 'Nicolae Balcescu'
      },
      {
        nume: 'Sărata',
        simplu: 'Sarata',
        comuna: 'Nicolae Balcescu'
      },
      {
        nume: 'Valea Seacă',
        simplu: 'Valea Seaca',
        comuna: 'Nicolae Balcescu'
      },
      {
        nume: 'Călcâi',
        simplu: 'Calcai',
        comuna: 'Oituz'
      },
      {
        nume: 'Ferestrău-Oituz',
        simplu: 'Ferestrau-Oituz',
        comuna: 'Oituz'
      },
      {
        nume: 'Hârja',
        simplu: 'Harja',
        comuna: 'Oituz'
      },
      {
        nume: 'Marginea',
        comuna: 'Oituz'
      },
      {
        nume: 'Poiana Sărată',
        simplu: 'Poiana Sarata',
        comuna: 'Oituz'
      },
      {
        nume: 'Bărboasa',
        simplu: 'Barboasa',
        comuna: 'Oncesti'
      },
      {
        nume: 'Dealu Perjului',
        comuna: 'Oncesti'
      },
      {
        nume: 'Onceştii Vechi',
        simplu: 'Oncestii Vechi',
        comuna: 'Oncesti'
      },
      {
        nume: 'Satu Nou',
        comuna: 'Oncesti'
      },
      {
        nume: 'Tarniţa',
        simplu: 'Tarnita',
        comuna: 'Oncesti'
      },
      {
        nume: 'Taula',
        comuna: 'Oncesti'
      },
      {
        nume: 'Borzeşti',
        simplu: 'Borzesti',
        comuna: 'Onesti'
      },
      {
        nume: 'Slobozia',
        comuna: 'Onesti'
      },
      {
        nume: 'Scurta',
        comuna: 'Orbeni'
      },
      {
        nume: 'Ciugheş',
        simplu: 'Ciughes',
        comuna: 'Palanca'
      },
      {
        nume: 'Cădăreşti',
        simplu: 'Cadaresti',
        comuna: 'Palanca'
      },
      {
        nume: 'Pajiştea',
        simplu: 'Pajistea',
        comuna: 'Palanca'
      },
      {
        nume: 'Popoiu',
        comuna: 'Palanca'
      },
      {
        nume: 'Chilia Benei',
        comuna: 'Pancesti'
      },
      {
        nume: 'Dieneţ',
        simplu: 'Dienet',
        comuna: 'Pancesti'
      },
      {
        nume: 'Fulgeriş',
        simplu: 'Fulgeris',
        comuna: 'Pancesti'
      },
      {
        nume: 'Fundu Văii',
        simplu: 'Fundu Vaii',
        comuna: 'Pancesti'
      },
      {
        nume: 'Motoc',
        comuna: 'Pancesti'
      },
      {
        nume: 'Petreşti',
        simplu: 'Petresti',
        comuna: 'Pancesti'
      },
      {
        nume: 'Soci',
        comuna: 'Pancesti'
      },
      {
        nume: 'Drăguşani',
        simplu: 'Dragusani',
        comuna: 'Parava'
      },
      {
        nume: 'Rădoaia',
        simplu: 'Radoaia',
        comuna: 'Parava'
      },
      {
        nume: 'Teiuş',
        simplu: 'Teius',
        comuna: 'Parava'
      },
      {
        nume: 'Bahna',
        comuna: 'Pargaresti'
      },
      {
        nume: 'Nicoreşti',
        simplu: 'Nicoresti',
        comuna: 'Pargaresti'
      },
      {
        nume: 'Pârâu Boghii',
        simplu: 'Parau Boghii',
        comuna: 'Pargaresti'
      },
      {
        nume: 'Satu Nou',
        comuna: 'Pargaresti'
      },
      {
        nume: 'Barna',
        comuna: 'Parincea'
      },
      {
        nume: 'Mileştii de Jos',
        simplu: 'Milestii de Jos',
        comuna: 'Parincea'
      },
      {
        nume: 'Mileştii de Sus',
        simplu: 'Milestii de Sus',
        comuna: 'Parincea'
      },
      {
        nume: 'Năneşti',
        simplu: 'Nanesti',
        comuna: 'Parincea'
      },
      {
        nume: 'Năstăseni',
        simplu: 'Nastaseni',
        comuna: 'Parincea'
      },
      {
        nume: 'Poieni',
        comuna: 'Parincea'
      },
      {
        nume: 'Satu Nou',
        comuna: 'Parincea'
      },
      {
        nume: 'Vladnic',
        comuna: 'Parincea'
      },
      {
        nume: 'Văleni',
        simplu: 'Valeni',
        comuna: 'Parincea'
      },
      {
        nume: 'Băhnăşeni',
        simplu: 'Bahnaseni',
        comuna: 'Parjol'
      },
      {
        nume: 'Bărneşti',
        simplu: 'Barnesti',
        comuna: 'Parjol'
      },
      {
        nume: 'Băsăşti',
        simplu: 'Basasti',
        comuna: 'Parjol'
      },
      {
        nume: 'Câmpeni',
        simplu: 'Campeni',
        comuna: 'Parjol'
      },
      {
        nume: 'Hemieni',
        comuna: 'Parjol'
      },
      {
        nume: 'Hâineala',
        simplu: 'Haineala',
        comuna: 'Parjol'
      },
      {
        nume: 'Pustiana',
        comuna: 'Parjol'
      },
      {
        nume: 'Tărâţa',
        simplu: 'Tarata',
        comuna: 'Parjol'
      },
      {
        nume: 'Budeşti',
        simplu: 'Budesti',
        comuna: 'Plopana'
      },
      {
        nume: 'Dorneni',
        comuna: 'Plopana'
      },
      {
        nume: 'Fundu Tutovei',
        comuna: 'Plopana'
      },
      {
        nume: 'Iţcani',
        simplu: 'Itcani',
        comuna: 'Plopana'
      },
      {
        nume: 'Rusenii Răzeşi',
        simplu: 'Rusenii Razesi',
        comuna: 'Plopana'
      },
      {
        nume: 'Rusenii de Sus',
        comuna: 'Plopana'
      },
      {
        nume: 'Străminoasa',
        simplu: 'Straminoasa',
        comuna: 'Plopana'
      },
      {
        nume: 'Tâgâra',
        simplu: 'Tagara',
        comuna: 'Plopana'
      },
      {
        nume: 'Bălăneşti',
        simplu: 'Balanesti',
        comuna: 'Podu Turcului'
      },
      {
        nume: 'Căbeşti',
        simplu: 'Cabesti',
        comuna: 'Podu Turcului'
      },
      {
        nume: 'Fichiteşti',
        simplu: 'Fichitesti',
        comuna: 'Podu Turcului'
      },
      {
        nume: 'Giurgioana',
        comuna: 'Podu Turcului'
      },
      {
        nume: 'Hanţa',
        simplu: 'Hanta',
        comuna: 'Podu Turcului'
      },
      {
        nume: 'Lehancea',
        comuna: 'Podu Turcului'
      },
      {
        nume: 'Plopu',
        comuna: 'Podu Turcului'
      },
      {
        nume: 'Răcuşana',
        simplu: 'Racusana',
        comuna: 'Podu Turcului'
      },
      {
        nume: 'Sârbi',
        simplu: 'Sarbi',
        comuna: 'Podu Turcului'
      },
      {
        nume: 'Bucşeşti',
        simplu: 'Bucsesti',
        comuna: 'Poduri'
      },
      {
        nume: 'Cernu',
        comuna: 'Poduri'
      },
      {
        nume: 'Cornet',
        comuna: 'Poduri'
      },
      {
        nume: 'Negreni',
        comuna: 'Poduri'
      },
      {
        nume: 'Prohozeşti',
        simplu: 'Prohozesti',
        comuna: 'Poduri'
      },
      {
        nume: 'Valea Soşii',
        simplu: 'Valea Sosii',
        comuna: 'Poduri'
      },
      {
        nume: 'Ciucani',
        comuna: 'Racaciuni'
      },
      {
        nume: 'Fundu Răcăciuni',
        simplu: 'Fundu Racaciuni',
        comuna: 'Racaciuni'
      },
      {
        nume: 'Gheorghe Doja',
        comuna: 'Racaciuni'
      },
      {
        nume: 'Gâşteni',
        simplu: 'Gasteni',
        comuna: 'Racaciuni'
      },
      {
        nume: 'Răstoaca',
        simplu: 'Rastoaca',
        comuna: 'Racaciuni'
      },
      {
        nume: 'Barcana',
        comuna: 'Rachitoasa'
      },
      {
        nume: 'Bucşa',
        simplu: 'Bucsa',
        comuna: 'Rachitoasa'
      },
      {
        nume: 'Buda',
        comuna: 'Rachitoasa'
      },
      {
        nume: 'Burdusaci',
        comuna: 'Rachitoasa'
      },
      {
        nume: 'Dumbrava',
        comuna: 'Rachitoasa'
      },
      {
        nume: 'Dănăila',
        simplu: 'Danaila',
        comuna: 'Rachitoasa'
      },
      {
        nume: 'Farcaşa',
        simplu: 'Farcasa',
        comuna: 'Rachitoasa'
      },
      {
        nume: 'Fundătura Răchitoasa',
        simplu: 'Fundatura Rachitoasa',
        comuna: 'Rachitoasa'
      },
      {
        nume: 'Hăghiac',
        simplu: 'Haghiac',
        comuna: 'Rachitoasa'
      },
      {
        nume: 'Magazia',
        comuna: 'Rachitoasa'
      },
      {
        nume: 'Moviliţa',
        simplu: 'Movilita',
        comuna: 'Rachitoasa'
      },
      {
        nume: 'Oprişeşti',
        simplu: 'Oprisesti',
        comuna: 'Rachitoasa'
      },
      {
        nume: 'Putini',
        comuna: 'Rachitoasa'
      },
      {
        nume: 'Tochilea',
        comuna: 'Rachitoasa'
      },
      {
        nume: 'Gura Văii',
        simplu: 'Gura Vaii',
        comuna: 'Racova'
      },
      {
        nume: 'Hălmăcioaia',
        simplu: 'Halmacioaia',
        comuna: 'Racova'
      },
      {
        nume: 'Ilieşi',
        simplu: 'Iliesi',
        comuna: 'Racova'
      },
      {
        nume: 'Misihăneşti',
        simplu: 'Misihanesti',
        comuna: 'Rosiori'
      },
      {
        nume: 'Neguşeni',
        simplu: 'Neguseni',
        comuna: 'Rosiori'
      },
      {
        nume: 'Poieni',
        comuna: 'Rosiori'
      },
      {
        nume: 'Valea Mare',
        comuna: 'Rosiori'
      },
      {
        nume: 'Valea Mică',
        simplu: 'Valea Mica',
        comuna: 'Rosiori'
      },
      {
        nume: 'Bârzuleşti',
        simplu: 'Barzulesti',
        comuna: 'Sanduleni'
      },
      {
        nume: 'Coman',
        comuna: 'Sanduleni'
      },
      {
        nume: 'Mateieşti',
        simplu: 'Mateiesti',
        comuna: 'Sanduleni'
      },
      {
        nume: 'Stufu',
        comuna: 'Sanduleni'
      },
      {
        nume: 'Tisa',
        comuna: 'Sanduleni'
      },
      {
        nume: 'Verşeşti',
        simplu: 'Versesti',
        comuna: 'Sanduleni'
      },
      {
        nume: 'Bereşti',
        simplu: 'Beresti',
        comuna: 'Sascut'
      },
      {
        nume: 'Conţeşti',
        simplu: 'Contesti',
        comuna: 'Sascut'
      },
      {
        nume: 'Pănceşti',
        simplu: 'Pancesti',
        comuna: 'Sascut'
      },
      {
        nume: 'Sascut-Sat',
        comuna: 'Sascut'
      },
      {
        nume: 'Schineni',
        comuna: 'Sascut'
      },
      {
        nume: 'Valea Nacului',
        comuna: 'Sascut'
      },
      {
        nume: 'Bogdan Vodă',
        simplu: 'Bogdan Voda',
        comuna: 'Saucesti'
      },
      {
        nume: 'Costei',
        comuna: 'Saucesti'
      },
      {
        nume: 'Schineni',
        comuna: 'Saucesti'
      },
      {
        nume: 'Siretu',
        comuna: 'Saucesti'
      },
      {
        nume: 'Serbeşti',
        simplu: 'Serbesti',
        comuna: 'Saucesti'
      },
      {
        nume: 'Bogdăneşti',
        simplu: 'Bogdanesti',
        comuna: 'Scorteni'
      },
      {
        nume: 'Floreşti',
        simplu: 'Floresti',
        comuna: 'Scorteni'
      },
      {
        nume: 'Grigoreni',
        comuna: 'Scorteni'
      },
      {
        nume: 'Stejaru',
        comuna: 'Scorteni'
      },
      {
        nume: 'Serpeni',
        simplu: 'Serpeni',
        comuna: 'Scorteni'
      },
      {
        nume: 'Berbinceni',
        comuna: 'Secuieni'
      },
      {
        nume: 'Băluşa',
        simplu: 'Balusa',
        comuna: 'Secuieni'
      },
      {
        nume: 'Chiticeni',
        comuna: 'Secuieni'
      },
      {
        nume: 'Ciutureşti',
        simplu: 'Ciuturesti',
        comuna: 'Secuieni'
      },
      {
        nume: 'Fundeni',
        comuna: 'Secuieni'
      },
      {
        nume: 'Glodişoarele',
        simplu: 'Glodisoarele',
        comuna: 'Secuieni'
      },
      {
        nume: 'Odobeşti',
        simplu: 'Odobesti',
        comuna: 'Secuieni'
      },
      {
        nume: 'Tisa-Silvestri',
        comuna: 'Secuieni'
      },
      {
        nume: 'Valea Fânaţului',
        simplu: 'Valea Fanatului',
        comuna: 'Secuieni'
      },
      {
        nume: 'Văleni',
        simplu: 'Valeni',
        comuna: 'Secuieni'
      },
      {
        nume: 'Cerdac',
        comuna: 'Slanic Moldova'
      },
      {
        nume: 'Cireşoaia',
        simplu: 'Ciresoaia',
        comuna: 'Slanic Moldova'
      },
      {
        nume: 'Slănic-Moldova',
        simplu: 'Slanic-Moldova',
        comuna: 'Slanic Moldova'
      },
      {
        nume: 'Cucuieţi',
        simplu: 'Cucuieti',
        comuna: 'Solont'
      },
      {
        nume: 'Sărata',
        simplu: 'Sarata',
        comuna: 'Solont'
      },
      {
        nume: 'Baloteşti',
        simplu: 'Balotesti',
        comuna: 'Stanisesti'
      },
      {
        nume: 'Belciuneasa',
        comuna: 'Stanisesti'
      },
      {
        nume: 'Beneşti',
        simplu: 'Benesti',
        comuna: 'Stanisesti'
      },
      {
        nume: 'Crăieşti',
        simplu: 'Craiesti',
        comuna: 'Stanisesti'
      },
      {
        nume: 'Gorgheşti',
        simplu: 'Gorghesti',
        comuna: 'Stanisesti'
      },
      {
        nume: 'Slobozia',
        comuna: 'Stanisesti'
      },
      {
        nume: 'Slobozia Nouă',
        simplu: 'Slobozia Noua',
        comuna: 'Stanisesti'
      },
      {
        nume: 'Văleni',
        simplu: 'Valeni',
        comuna: 'Stanisesti'
      },
      {
        nume: 'Bogdana',
        comuna: 'Stefan Cel Mare'
      },
      {
        nume: 'Buciumi',
        comuna: 'Stefan Cel Mare'
      },
      {
        nume: 'Gutinaş',
        simplu: 'Gutinas',
        comuna: 'Stefan Cel Mare'
      },
      {
        nume: 'Negoieşti',
        simplu: 'Negoiesti',
        comuna: 'Stefan Cel Mare'
      },
      {
        nume: 'Răcăuţi',
        simplu: 'Racauti',
        comuna: 'Stefan Cel Mare'
      },
      {
        nume: 'Rădeana',
        simplu: 'Radeana',
        comuna: 'Stefan Cel Mare'
      },
      {
        nume: 'Viişoara',
        simplu: 'Viisoara',
        comuna: 'Stefan Cel Mare'
      },
      {
        nume: 'Cetăţuia',
        simplu: 'Cetatuia',
        comuna: 'Strugari'
      },
      {
        nume: 'Iaz',
        comuna: 'Strugari'
      },
      {
        nume: 'Nadişa',
        simplu: 'Nadisa',
        comuna: 'Strugari'
      },
      {
        nume: 'Petricica',
        comuna: 'Strugari'
      },
      {
        nume: 'Răchitişu',
        simplu: 'Rachitisu',
        comuna: 'Strugari'
      },
      {
        nume: 'Chetriş',
        simplu: 'Chetris',
        comuna: 'Tamasi'
      },
      {
        nume: 'Furnicari',
        comuna: 'Tamasi'
      },
      {
        nume: 'Gioseni',
        comuna: 'Tamasi'
      },
      {
        nume: 'Poieni',
        comuna: 'Targu Ocna'
      },
      {
        nume: 'Vâlcele',
        simplu: 'Valcele',
        comuna: 'Targu Ocna'
      },
      {
        nume: 'Tuta',
        comuna: 'Targu Trotus'
      },
      {
        nume: 'Viişoara',
        simplu: 'Viisoara',
        comuna: 'Targu Trotus'
      },
      {
        nume: 'Cornii de Jos',
        comuna: 'Tatarasti'
      },
      {
        nume: 'Cornii de Sus',
        comuna: 'Tatarasti'
      },
      {
        nume: 'Drăgeşti',
        simplu: 'Dragesti',
        comuna: 'Tatarasti'
      },
      {
        nume: 'Gherdana',
        comuna: 'Tatarasti'
      },
      {
        nume: 'Giurgeni',
        comuna: 'Tatarasti'
      },
      {
        nume: 'Ungureni',
        comuna: 'Tatarasti'
      },
      {
        nume: 'Bogdăneşti',
        simplu: 'Bogdanesti',
        comuna: 'Traian'
      },
      {
        nume: 'Hertioana de Jos',
        comuna: 'Traian'
      },
      {
        nume: 'Hertioana-Răzeşi',
        simplu: 'Hertioana-Razesi',
        comuna: 'Traian'
      },
      {
        nume: 'Prăjeşti',
        simplu: 'Prajesti',
        comuna: 'Traian'
      },
      {
        nume: 'Zăpodia',
        simplu: 'Zapodia',
        comuna: 'Traian'
      },
      {
        nume: 'Bibireşti',
        simplu: 'Bibiresti',
        comuna: 'Ungureni'
      },
      {
        nume: 'Bota',
        comuna: 'Ungureni'
      },
      {
        nume: 'Boteşti',
        simplu: 'Botesti',
        comuna: 'Ungureni'
      },
      {
        nume: 'Bărtăşeşti',
        simplu: 'Bartasesti',
        comuna: 'Ungureni'
      },
      {
        nume: 'Gârla Anei',
        simplu: 'Garla Anei',
        comuna: 'Ungureni'
      },
      {
        nume: 'Viforeni',
        comuna: 'Ungureni'
      },
      {
        nume: 'Zlătari',
        simplu: 'Zlatari',
        comuna: 'Ungureni'
      },
      {
        nume: 'Cornăţel',
        simplu: 'Cornatel',
        comuna: 'Urechesti'
      },
      {
        nume: 'Lunca Dochiei',
        comuna: 'Urechesti'
      },
      {
        nume: 'Satu Nou',
        comuna: 'Urechesti'
      },
      {
        nume: 'Slobozia',
        comuna: 'Urechesti'
      },
      {
        nume: 'Cucova',
        comuna: 'Valea Seaca'
      },
      {
        nume: 'Bosia',
        comuna: 'Vultureni'
      },
      {
        nume: 'Dorneni',
        comuna: 'Vultureni'
      },
      {
        nume: 'Dădeşti',
        simplu: 'Dadesti',
        comuna: 'Vultureni'
      },
      {
        nume: 'Ghilăveşti',
        simplu: 'Ghilavesti',
        comuna: 'Vultureni'
      },
      {
        nume: 'Godineştii de Jos',
        simplu: 'Godinestii de Jos',
        comuna: 'Vultureni'
      },
      {
        nume: 'Godineştii de Sus',
        simplu: 'Godinestii de Sus',
        comuna: 'Vultureni'
      },
      {
        nume: 'Lichitişeni',
        simplu: 'Lichitiseni',
        comuna: 'Vultureni'
      },
      {
        nume: 'Medeleni',
        comuna: 'Vultureni'
      },
      {
        nume: 'Năzărioaia',
        simplu: 'Nazarioaia',
        comuna: 'Vultureni'
      },
      {
        nume: 'Reprivăţ',
        simplu: 'Reprivat',
        comuna: 'Vultureni'
      },
      {
        nume: 'Tomozia',
        comuna: 'Vultureni'
      },
      {
        nume: 'Valea Lupului',
        comuna: 'Vultureni'
      },
      {
        nume: 'Valea Merilor',
        comuna: 'Vultureni'
      },
      {
        nume: 'Valea Salciei',
        comuna: 'Vultureni'
      },
      {
        nume: 'Tigăneşti',
        simplu: 'Tiganesti',
        comuna: 'Vultureni'
      },
      {
        nume: 'Bolătău',
        simplu: 'Bolatau',
        comuna: 'Zemes'
      }
    ]
  },
  {
    auto: 'BH',
    nume: 'Bihor',
    localitati: [
      {
        nume: 'Abram'
      },
      {
        nume: 'Abrămuţ',
        simplu: 'Abramut'
      },
      {
        nume: 'Aleşd',
        simplu: 'Alesd'
      },
      {
        nume: 'Auşeu',
        simplu: 'Auseu'
      },
      {
        nume: 'Avram Iancu'
      },
      {
        nume: 'Aştileu',
        simplu: 'Astileu'
      },
      {
        nume: 'Balc'
      },
      {
        nume: 'Batăr',
        simplu: 'Batar'
      },
      {
        nume: 'Beiuş',
        simplu: 'Beius'
      },
      {
        nume: 'Biharia'
      },
      {
        nume: 'Boianu Mare'
      },
      {
        nume: 'Borod'
      },
      {
        nume: 'Borş',
        simplu: 'Bors'
      },
      {
        nume: 'Bratca'
      },
      {
        nume: 'Brusturi'
      },
      {
        nume: 'Budureasa'
      },
      {
        nume: 'Buduslău',
        simplu: 'Buduslau'
      },
      {
        nume: 'Bulz'
      },
      {
        nume: 'Bunteşti',
        simplu: 'Buntesti'
      },
      {
        nume: 'Cefa'
      },
      {
        nume: 'Ceica'
      },
      {
        nume: 'Cetariu'
      },
      {
        nume: 'Cherechiu'
      },
      {
        nume: 'Chişlaz',
        simplu: 'Chislaz'
      },
      {
        nume: 'Ciuhoi'
      },
      {
        nume: 'Ciumeghiu'
      },
      {
        nume: 'Cociuba Mare'
      },
      {
        nume: 'Copăcel',
        simplu: 'Copacel'
      },
      {
        nume: 'Criştioru de Jos',
        simplu: 'Cristioru de Jos'
      },
      {
        nume: 'Curtuişeni',
        simplu: 'Curtuiseni'
      },
      {
        nume: 'Curăţele',
        simplu: 'Curatele'
      },
      {
        nume: 'Câmpani',
        simplu: 'Campani'
      },
      {
        nume: 'Căbeşti',
        simplu: 'Cabesti'
      },
      {
        nume: 'Căpâlna',
        simplu: 'Capalna'
      },
      {
        nume: 'Cărpinet',
        simplu: 'Carpinet'
      },
      {
        nume: 'Derna'
      },
      {
        nume: 'Diosig'
      },
      {
        nume: 'Dobreşti',
        simplu: 'Dobresti'
      },
      {
        nume: 'Drăgeşti',
        simplu: 'Dragesti'
      },
      {
        nume: 'Drăgăneşti',
        simplu: 'Draganesti'
      },
      {
        nume: 'Finiş',
        simplu: 'Finis'
      },
      {
        nume: 'Gepiu'
      },
      {
        nume: 'Girişu de Criş',
        simplu: 'Girisu de Cris'
      },
      {
        nume: 'Hidişelu de Sus',
        simplu: 'Hidiselu de Sus'
      },
      {
        nume: 'Holod'
      },
      {
        nume: 'Husasău de Tinca',
        simplu: 'Husasau de Tinca'
      },
      {
        nume: 'Ineu'
      },
      {
        nume: 'Lazuri de Beiuş',
        simplu: 'Lazuri de Beius'
      },
      {
        nume: 'Lugaşu de Jos',
        simplu: 'Lugasu de Jos'
      },
      {
        nume: 'Lunca'
      },
      {
        nume: 'Lăzăreni',
        simplu: 'Lazareni'
      },
      {
        nume: 'Marghita'
      },
      {
        nume: 'Mădăraş',
        simplu: 'Madaras'
      },
      {
        nume: 'Măgeşti',
        simplu: 'Magesti'
      },
      {
        nume: 'Nojorid'
      },
      {
        nume: 'Nucet'
      },
      {
        nume: 'Olcea'
      },
      {
        nume: 'Oradea'
      },
      {
        nume: 'Oşorhei',
        simplu: 'Osorhei'
      },
      {
        nume: 'Paleu'
      },
      {
        nume: 'Pietroasa'
      },
      {
        nume: 'Pocola'
      },
      {
        nume: 'Pomezeu'
      },
      {
        nume: 'Popeşti',
        simplu: 'Popesti'
      },
      {
        nume: 'Remetea'
      },
      {
        nume: 'Rieni'
      },
      {
        nume: 'Roşia',
        simplu: 'Rosia'
      },
      {
        nume: 'Roşiori',
        simplu: 'Rosiori'
      },
      {
        nume: 'Răbăgani',
        simplu: 'Rabagani'
      },
      {
        nume: 'Salonta'
      },
      {
        nume: 'Spinuş',
        simplu: 'Spinus'
      },
      {
        nume: 'Suplacu de Barcău',
        simplu: 'Suplacu de Barcau'
      },
      {
        nume: 'Sâmbăta',
        simplu: 'Sambata'
      },
      {
        nume: 'Sânmartin',
        simplu: 'Sanmartin'
      },
      {
        nume: 'Sânnicolau Român',
        simplu: 'Sannicolau Roman'
      },
      {
        nume: 'Sântandrei',
        simplu: 'Santandrei'
      },
      {
        nume: 'Sârbi',
        simplu: 'Sarbi'
      },
      {
        nume: 'Săcueni',
        simplu: 'Sacueni'
      },
      {
        nume: 'Săcădat',
        simplu: 'Sacadat'
      },
      {
        nume: 'Sălacea',
        simplu: 'Salacea'
      },
      {
        nume: 'Sălard',
        simplu: 'Salard'
      },
      {
        nume: 'Tarcea'
      },
      {
        nume: 'Tileagd'
      },
      {
        nume: 'Tinca'
      },
      {
        nume: 'Tulca'
      },
      {
        nume: 'Tămăşeu',
        simplu: 'Tamaseu'
      },
      {
        nume: 'Tărcaia',
        simplu: 'Tarcaia'
      },
      {
        nume: 'Tăuteu',
        simplu: 'Tauteu'
      },
      {
        nume: 'Uileacu de Beiuş',
        simplu: 'Uileacu de Beius'
      },
      {
        nume: 'Vadu Crişului',
        simplu: 'Vadu Crisului'
      },
      {
        nume: 'Valea lui Mihai'
      },
      {
        nume: 'Vaşcău',
        simplu: 'Vascau'
      },
      {
        nume: 'Viişoara',
        simplu: 'Viisoara'
      },
      {
        nume: 'Vârciorog',
        simplu: 'Varciorog'
      },
      {
        nume: 'Simian',
        simplu: 'Simian'
      },
      {
        nume: 'Sinteu',
        simplu: 'Sinteu'
      },
      {
        nume: 'Soimi',
        simplu: 'Soimi'
      },
      {
        nume: 'Stei',
        simplu: 'Stei'
      },
      {
        nume: 'Suncuiuş',
        simplu: 'Suncuius'
      },
      {
        nume: 'Teţchea',
        simplu: 'Tetchea'
      },
      {
        nume: 'Cohani',
        comuna: 'Abram'
      },
      {
        nume: 'Dijir',
        comuna: 'Abram'
      },
      {
        nume: 'Iteu',
        comuna: 'Abram'
      },
      {
        nume: 'Iteu Nou',
        comuna: 'Abram'
      },
      {
        nume: 'Margine',
        comuna: 'Abram'
      },
      {
        nume: 'Satu Barbă',
        simplu: 'Satu Barba',
        comuna: 'Abram'
      },
      {
        nume: 'Suiug',
        comuna: 'Abram'
      },
      {
        nume: 'Crestur',
        comuna: 'Abramut'
      },
      {
        nume: 'Făncica',
        simplu: 'Fancica',
        comuna: 'Abramut'
      },
      {
        nume: 'Petreu',
        comuna: 'Abramut'
      },
      {
        nume: 'Peştiş',
        simplu: 'Pestis',
        comuna: 'Alesd'
      },
      {
        nume: 'Pădurea Neagră',
        simplu: 'Padurea Neagra',
        comuna: 'Alesd'
      },
      {
        nume: 'Tinăud',
        simplu: 'Tinaud',
        comuna: 'Alesd'
      },
      {
        nume: 'Chistag',
        comuna: 'Astileu'
      },
      {
        nume: 'Călăţea',
        simplu: 'Calatea',
        comuna: 'Astileu'
      },
      {
        nume: 'Peştere',
        simplu: 'Pestere',
        comuna: 'Astileu'
      },
      {
        nume: 'Cacuciu Vechi',
        comuna: 'Auseu'
      },
      {
        nume: 'Codrişoru',
        simplu: 'Codrisoru',
        comuna: 'Auseu'
      },
      {
        nume: 'Gheghie',
        comuna: 'Auseu'
      },
      {
        nume: 'Groşi',
        simplu: 'Grosi',
        comuna: 'Auseu'
      },
      {
        nume: 'Luncşoara',
        simplu: 'Luncsoara',
        comuna: 'Auseu'
      },
      {
        nume: 'Ant',
        comuna: 'Avram Iancu'
      },
      {
        nume: 'Tămaşda',
        simplu: 'Tamasda',
        comuna: 'Avram Iancu'
      },
      {
        nume: 'Almaşu Mare',
        simplu: 'Almasu Mare',
        comuna: 'Balc'
      },
      {
        nume: 'Almaşu Mic',
        simplu: 'Almasu Mic',
        comuna: 'Balc'
      },
      {
        nume: 'Ghida',
        comuna: 'Balc'
      },
      {
        nume: 'Săldăbagiu de Barcău',
        simplu: 'Saldabagiu de Barcau',
        comuna: 'Balc'
      },
      {
        nume: 'Arpăşel',
        simplu: 'Arpasel',
        comuna: 'Batar'
      },
      {
        nume: 'Talpoş',
        simplu: 'Talpos',
        comuna: 'Batar'
      },
      {
        nume: 'Tăut',
        simplu: 'Taut',
        comuna: 'Batar'
      },
      {
        nume: 'Delani',
        comuna: 'Beius'
      },
      {
        nume: 'Cauaceu',
        comuna: 'Biharia'
      },
      {
        nume: 'Corboaia',
        comuna: 'Boianu Mare'
      },
      {
        nume: 'Huta',
        comuna: 'Boianu Mare'
      },
      {
        nume: 'Păgaia',
        simplu: 'Pagaia',
        comuna: 'Boianu Mare'
      },
      {
        nume: 'Rugea',
        comuna: 'Boianu Mare'
      },
      {
        nume: 'Borozel',
        comuna: 'Borod'
      },
      {
        nume: 'Cetea',
        comuna: 'Borod'
      },
      {
        nume: 'Corniţel',
        simplu: 'Cornitel',
        comuna: 'Borod'
      },
      {
        nume: 'Valea Mare de Criş',
        simplu: 'Valea Mare de Cris',
        comuna: 'Borod'
      },
      {
        nume: 'Serani',
        simplu: 'Serani',
        comuna: 'Borod'
      },
      {
        nume: 'Santăul Mare',
        simplu: 'Santaul Mare',
        comuna: 'Bors'
      },
      {
        nume: 'Santăul Mic',
        simplu: 'Santaul Mic',
        comuna: 'Bors'
      },
      {
        nume: 'Sântion',
        simplu: 'Santion',
        comuna: 'Bors'
      },
      {
        nume: 'Beznea',
        comuna: 'Bratca'
      },
      {
        nume: 'Damiş',
        simplu: 'Damis',
        comuna: 'Bratca'
      },
      {
        nume: 'Lorău',
        simplu: 'Lorau',
        comuna: 'Bratca'
      },
      {
        nume: 'Ponoară',
        simplu: 'Ponoara',
        comuna: 'Bratca'
      },
      {
        nume: 'Valea Crişului',
        simplu: 'Valea Crisului',
        comuna: 'Bratca'
      },
      {
        nume: 'Cuieşd',
        simplu: 'Cuiesd',
        comuna: 'Brusturi'
      },
      {
        nume: 'Loranta',
        comuna: 'Brusturi'
      },
      {
        nume: 'Orvişele',
        simplu: 'Orvisele',
        comuna: 'Brusturi'
      },
      {
        nume: 'Picleu',
        comuna: 'Brusturi'
      },
      {
        nume: 'Păuleşti',
        simplu: 'Paulesti',
        comuna: 'Brusturi'
      },
      {
        nume: 'Varasău',
        simplu: 'Varasau',
        comuna: 'Brusturi'
      },
      {
        nume: 'Tigăneştii de Criş',
        simplu: 'Tiganestii de Cris',
        comuna: 'Brusturi'
      },
      {
        nume: 'Burda',
        comuna: 'Budureasa'
      },
      {
        nume: 'Saca',
        comuna: 'Budureasa'
      },
      {
        nume: 'Sălişte de Beiuş',
        simplu: 'Saliste de Beius',
        comuna: 'Budureasa'
      },
      {
        nume: 'Teleac',
        comuna: 'Budureasa'
      },
      {
        nume: 'Albiş',
        simplu: 'Albis',
        comuna: 'Buduslau'
      },
      {
        nume: 'Munteni',
        comuna: 'Bulz'
      },
      {
        nume: 'Remeţi',
        simplu: 'Remeti',
        comuna: 'Bulz'
      },
      {
        nume: 'Brădet',
        simplu: 'Bradet',
        comuna: 'Buntesti'
      },
      {
        nume: 'Dumbrăvani',
        simplu: 'Dumbravani',
        comuna: 'Buntesti'
      },
      {
        nume: 'Ferice',
        comuna: 'Buntesti'
      },
      {
        nume: 'Leleşti',
        simplu: 'Lelesti',
        comuna: 'Buntesti'
      },
      {
        nume: 'Poienii de Jos',
        comuna: 'Buntesti'
      },
      {
        nume: 'Poienii de Sus',
        comuna: 'Buntesti'
      },
      {
        nume: 'Stânceşti',
        simplu: 'Stancesti',
        comuna: 'Buntesti'
      },
      {
        nume: 'Săud',
        simplu: 'Saud',
        comuna: 'Buntesti'
      },
      {
        nume: 'Goila',
        comuna: 'Cabesti'
      },
      {
        nume: 'Gurbeşti',
        simplu: 'Gurbesti',
        comuna: 'Cabesti'
      },
      {
        nume: 'Josani',
        comuna: 'Cabesti'
      },
      {
        nume: 'Sohodol',
        comuna: 'Cabesti'
      },
      {
        nume: 'Fânaţe',
        simplu: 'Fanate',
        comuna: 'Campani'
      },
      {
        nume: 'Hârseşti',
        simplu: 'Harsesti',
        comuna: 'Campani'
      },
      {
        nume: 'Sighiştel',
        simplu: 'Sighistel',
        comuna: 'Campani'
      },
      {
        nume: 'Valea de Sus',
        comuna: 'Campani'
      },
      {
        nume: 'Ginta',
        comuna: 'Capalna'
      },
      {
        nume: 'Rohani',
        comuna: 'Capalna'
      },
      {
        nume: 'Suplacu de Tinca',
        comuna: 'Capalna'
      },
      {
        nume: 'Săldăbagiu Mic',
        simplu: 'Saldabagiu Mic',
        comuna: 'Capalna'
      },
      {
        nume: 'Călugări',
        simplu: 'Calugari',
        comuna: 'Carpinet'
      },
      {
        nume: 'Izbuc',
        comuna: 'Carpinet'
      },
      {
        nume: 'Leheceni',
        comuna: 'Carpinet'
      },
      {
        nume: 'Ateaş',
        simplu: 'Ateas',
        comuna: 'Cefa'
      },
      {
        nume: 'Inand',
        comuna: 'Cefa'
      },
      {
        nume: 'Bucium',
        comuna: 'Ceica'
      },
      {
        nume: 'Ceişoara',
        simplu: 'Ceisoara',
        comuna: 'Ceica'
      },
      {
        nume: 'Corbeşti',
        simplu: 'Corbesti',
        comuna: 'Ceica'
      },
      {
        nume: 'Cotiglet',
        comuna: 'Ceica'
      },
      {
        nume: 'Duşeşti',
        simplu: 'Dusesti',
        comuna: 'Ceica'
      },
      {
        nume: 'Inceşti',
        simplu: 'Incesti',
        comuna: 'Ceica'
      },
      {
        nume: 'Tăutelec',
        simplu: 'Tautelec',
        comuna: 'Cetariu'
      },
      {
        nume: 'Sişterea',
        simplu: 'Sisterea',
        comuna: 'Cetariu'
      },
      {
        nume: 'Suşturogi',
        simplu: 'Susturogi',
        comuna: 'Cetariu'
      },
      {
        nume: 'Cheşereu',
        simplu: 'Chesereu',
        comuna: 'Cherechiu'
      },
      {
        nume: 'Târguşor',
        simplu: 'Targusor',
        comuna: 'Cherechiu'
      },
      {
        nume: 'Chiraleu',
        comuna: 'Chislaz'
      },
      {
        nume: 'Hăuceşti',
        simplu: 'Haucesti',
        comuna: 'Chislaz'
      },
      {
        nume: 'Mişca',
        simplu: 'Misca',
        comuna: 'Chislaz'
      },
      {
        nume: 'Pocluşa de Barcău',
        simplu: 'Poclusa de Barcau',
        comuna: 'Chislaz'
      },
      {
        nume: 'Sânlazăr',
        simplu: 'Sanlazar',
        comuna: 'Chislaz'
      },
      {
        nume: 'Sărsig',
        simplu: 'Sarsig',
        comuna: 'Chislaz'
      },
      {
        nume: 'Cenaloş',
        simplu: 'Cenalos',
        comuna: 'Ciuhoi'
      },
      {
        nume: 'Sfârnaş',
        simplu: 'Sfarnas',
        comuna: 'Ciuhoi'
      },
      {
        nume: 'Sâniob',
        simplu: 'Saniob',
        comuna: 'Ciuhoi'
      },
      {
        nume: 'Boiu',
        comuna: 'Ciumeghiu'
      },
      {
        nume: 'Ghiorac',
        comuna: 'Ciumeghiu'
      },
      {
        nume: 'Cheşa',
        simplu: 'Chesa',
        comuna: 'Cociuba Mare'
      },
      {
        nume: 'Cărăsău',
        simplu: 'Carasau',
        comuna: 'Cociuba Mare'
      },
      {
        nume: 'Petid',
        comuna: 'Cociuba Mare'
      },
      {
        nume: 'Bucuroaia',
        comuna: 'Copacel'
      },
      {
        nume: 'Chijic',
        comuna: 'Copacel'
      },
      {
        nume: 'Poiana Tăşad',
        simplu: 'Poiana Tasad',
        comuna: 'Copacel'
      },
      {
        nume: 'Surduc',
        comuna: 'Copacel'
      },
      {
        nume: 'Sărand',
        simplu: 'Sarand',
        comuna: 'Copacel'
      },
      {
        nume: 'Bâlc',
        simplu: 'Balc',
        comuna: 'Cristioru De Jos'
      },
      {
        nume: 'Criştioru de Sus',
        simplu: 'Cristioru de Sus',
        comuna: 'Cristioru De Jos'
      },
      {
        nume: 'Poiana',
        comuna: 'Cristioru De Jos'
      },
      {
        nume: 'Sălişte de Vaşcău',
        simplu: 'Saliste de Vascau',
        comuna: 'Cristioru De Jos'
      },
      {
        nume: 'Beiuşele',
        simplu: 'Beiusele',
        comuna: 'Curatele'
      },
      {
        nume: 'Cresuia',
        comuna: 'Curatele'
      },
      {
        nume: 'Nimăieşti',
        simplu: 'Nimaiesti',
        comuna: 'Curatele'
      },
      {
        nume: 'Pociovelişte',
        simplu: 'Pocioveliste',
        comuna: 'Curatele'
      },
      {
        nume: 'Văşad',
        simplu: 'Vasad',
        comuna: 'Curtuiseni'
      },
      {
        nume: 'Dernişoara',
        simplu: 'Dernisoara',
        comuna: 'Derna'
      },
      {
        nume: 'Sacalasău',
        simplu: 'Sacalasau',
        comuna: 'Derna'
      },
      {
        nume: 'Sacalasău Nou',
        simplu: 'Sacalasau Nou',
        comuna: 'Derna'
      },
      {
        nume: 'Tria',
        comuna: 'Derna'
      },
      {
        nume: 'Ianca',
        comuna: 'Diosig'
      },
      {
        nume: 'Cornişeşti',
        simplu: 'Cornisesti',
        comuna: 'Dobresti'
      },
      {
        nume: 'Crânceşti',
        simplu: 'Crancesti',
        comuna: 'Dobresti'
      },
      {
        nume: 'Hidişel',
        simplu: 'Hidisel',
        comuna: 'Dobresti'
      },
      {
        nume: 'Luncasprie',
        comuna: 'Dobresti'
      },
      {
        nume: 'Răcaş',
        simplu: 'Racas',
        comuna: 'Dobresti'
      },
      {
        nume: 'Topa de Jos',
        comuna: 'Dobresti'
      },
      {
        nume: 'Topa de Sus',
        comuna: 'Dobresti'
      },
      {
        nume: 'Belejeni',
        comuna: 'Draganesti'
      },
      {
        nume: 'Grădinari',
        simplu: 'Gradinari',
        comuna: 'Draganesti'
      },
      {
        nume: 'Livada Beiuşului',
        simplu: 'Livada Beiusului',
        comuna: 'Draganesti'
      },
      {
        nume: 'Mizieş',
        simplu: 'Mizies',
        comuna: 'Draganesti'
      },
      {
        nume: 'Păntăşeşti',
        simplu: 'Pantasesti',
        comuna: 'Draganesti'
      },
      {
        nume: 'Talpe',
        comuna: 'Draganesti'
      },
      {
        nume: 'Sebiş',
        simplu: 'Sebis',
        comuna: 'Draganesti'
      },
      {
        nume: 'Tigăneştii de Beiuş',
        simplu: 'Tiganestii de Beius',
        comuna: 'Draganesti'
      },
      {
        nume: 'Dicăneşti',
        simplu: 'Dicanesti',
        comuna: 'Dragesti'
      },
      {
        nume: 'Stracoş',
        simplu: 'Stracos',
        comuna: 'Dragesti'
      },
      {
        nume: 'Topeşti',
        simplu: 'Topesti',
        comuna: 'Dragesti'
      },
      {
        nume: 'Tăşad',
        simplu: 'Tasad',
        comuna: 'Dragesti'
      },
      {
        nume: 'Păcăleşti',
        simplu: 'Pacalesti',
        comuna: 'Drăgănești'
      },
      {
        nume: 'Brusturi',
        comuna: 'Finis'
      },
      {
        nume: 'Fiziş',
        simplu: 'Fizis',
        comuna: 'Finis'
      },
      {
        nume: 'Ioaniş',
        simplu: 'Ioanis',
        comuna: 'Finis'
      },
      {
        nume: 'Suncuiş',
        simplu: 'Suncuis',
        comuna: 'Finis'
      },
      {
        nume: 'Bicaci',
        comuna: 'Gepiu'
      },
      {
        nume: 'Cheresig',
        comuna: 'Girisu De Cris'
      },
      {
        nume: 'Toboliu',
        comuna: 'Girisu De Cris'
      },
      {
        nume: 'Tărian',
        simplu: 'Tarian',
        comuna: 'Girisu De Cris'
      },
      {
        nume: 'Hidişelu de Jos',
        simplu: 'Hidiselu de Jos',
        comuna: 'Hidiselu De Sus'
      },
      {
        nume: 'Mierlău',
        simplu: 'Mierlau',
        comuna: 'Hidiselu De Sus'
      },
      {
        nume: 'Sântelec',
        simplu: 'Santelec',
        comuna: 'Hidiselu De Sus'
      },
      {
        nume: 'Sumugiu',
        simplu: 'Sumugiu',
        comuna: 'Hidiselu De Sus'
      },
      {
        nume: 'Dumbrava',
        comuna: 'Holod'
      },
      {
        nume: 'Dumbrăviţa',
        simplu: 'Dumbravita',
        comuna: 'Holod'
      },
      {
        nume: 'Forosig',
        comuna: 'Holod'
      },
      {
        nume: 'Hodiş',
        simplu: 'Hodis',
        comuna: 'Holod'
      },
      {
        nume: 'Lupoaia',
        comuna: 'Holod'
      },
      {
        nume: 'Valea Mare de Codru',
        comuna: 'Holod'
      },
      {
        nume: 'Vintere',
        comuna: 'Holod'
      },
      {
        nume: 'Fonău',
        simplu: 'Fonau',
        comuna: 'Husasau De Tinca'
      },
      {
        nume: 'Miersig',
        comuna: 'Husasau De Tinca'
      },
      {
        nume: 'Oşand',
        simplu: 'Osand',
        comuna: 'Husasau De Tinca'
      },
      {
        nume: 'Sititelec',
        comuna: 'Husasau De Tinca'
      },
      {
        nume: 'Botean',
        comuna: 'Ineu'
      },
      {
        nume: 'Husasău de Criş',
        simplu: 'Husasau de Cris',
        comuna: 'Ineu'
      },
      {
        nume: 'Bicăcel',
        simplu: 'Bicacel',
        comuna: 'Lazareni'
      },
      {
        nume: 'Calea Mare',
        comuna: 'Lazareni'
      },
      {
        nume: 'Cărăndeni',
        simplu: 'Carandeni',
        comuna: 'Lazareni'
      },
      {
        nume: 'Cărănzel',
        simplu: 'Caranzel',
        comuna: 'Lazareni'
      },
      {
        nume: 'Gepiş',
        simplu: 'Gepis',
        comuna: 'Lazareni'
      },
      {
        nume: 'Gruilung',
        comuna: 'Lazareni'
      },
      {
        nume: 'Miheleu',
        comuna: 'Lazareni'
      },
      {
        nume: 'Băleni',
        simplu: 'Baleni',
        comuna: 'Lazuri De Beius'
      },
      {
        nume: 'Cusuiuş',
        simplu: 'Cusuius',
        comuna: 'Lazuri De Beius'
      },
      {
        nume: 'Hinchiriş',
        simplu: 'Hinchiris',
        comuna: 'Lazuri De Beius'
      },
      {
        nume: 'Lugaşu de Sus',
        simplu: 'Lugasu de Sus',
        comuna: 'Lugasu De Jos'
      },
      {
        nume: 'Urvind',
        comuna: 'Lugasu De Jos'
      },
      {
        nume: 'Briheni',
        comuna: 'Lunca'
      },
      {
        nume: 'Hotărel',
        simplu: 'Hotarel',
        comuna: 'Lunca'
      },
      {
        nume: 'Seghişte',
        simplu: 'Seghiste',
        comuna: 'Lunca'
      },
      {
        nume: 'Sârbeşti',
        simplu: 'Sarbesti',
        comuna: 'Lunca'
      },
      {
        nume: 'Suştiu',
        simplu: 'Sustiu',
        comuna: 'Lunca'
      },
      {
        nume: 'Homorog',
        comuna: 'Madaras'
      },
      {
        nume: 'Ianoşda',
        simplu: 'Ianosda',
        comuna: 'Madaras'
      },
      {
        nume: 'Marţihaz',
        simplu: 'Martihaz',
        comuna: 'Madaras'
      },
      {
        nume: 'Butani',
        comuna: 'Magesti'
      },
      {
        nume: 'Cacuciu Nou',
        comuna: 'Magesti'
      },
      {
        nume: 'Dobricioneşti',
        simplu: 'Dobricionesti',
        comuna: 'Magesti'
      },
      {
        nume: 'Gălăşeni',
        simplu: 'Galaseni',
        comuna: 'Magesti'
      },
      {
        nume: 'Josani',
        comuna: 'Magesti'
      },
      {
        nume: 'Ortiteag',
        comuna: 'Magesti'
      },
      {
        nume: 'Cheţ',
        simplu: 'Chet',
        comuna: 'Marghita'
      },
      {
        nume: 'Ghenetea',
        comuna: 'Marghita'
      },
      {
        nume: 'Apateu',
        comuna: 'Nojorid'
      },
      {
        nume: 'Chişirid',
        simplu: 'Chisirid',
        comuna: 'Nojorid'
      },
      {
        nume: 'Leş',
        simplu: 'Les',
        comuna: 'Nojorid'
      },
      {
        nume: 'Livada de Bihor',
        comuna: 'Nojorid'
      },
      {
        nume: 'Păuşa',
        simplu: 'Pausa',
        comuna: 'Nojorid'
      },
      {
        nume: 'Sauaieu',
        simplu: 'Sauaieu',
        comuna: 'Nojorid'
      },
      {
        nume: 'Băiţa',
        simplu: 'Baita',
        comuna: 'Nucet'
      },
      {
        nume: 'Băiţa-Plai',
        simplu: 'Baita-Plai',
        comuna: 'Nucet'
      },
      {
        nume: 'Călacea',
        simplu: 'Calacea',
        comuna: 'Olcea'
      },
      {
        nume: 'Hodişel',
        simplu: 'Hodisel',
        comuna: 'Olcea'
      },
      {
        nume: 'Ucuriş',
        simplu: 'Ucuris',
        comuna: 'Olcea'
      },
      {
        nume: 'Alparea',
        comuna: 'Osorhei'
      },
      {
        nume: 'Cheriu',
        comuna: 'Osorhei'
      },
      {
        nume: 'Felcheriu',
        comuna: 'Osorhei'
      },
      {
        nume: 'Fughiu',
        comuna: 'Osorhei'
      },
      {
        nume: 'Săldăbagiu de Munte',
        simplu: 'Saldabagiu de Munte',
        comuna: 'Paleu'
      },
      {
        nume: 'Uileacu de Munte',
        comuna: 'Paleu'
      },
      {
        nume: 'Chişcău',
        simplu: 'Chiscau',
        comuna: 'Pietroasa'
      },
      {
        nume: 'Cociuba Mică',
        simplu: 'Cociuba Mica',
        comuna: 'Pietroasa'
      },
      {
        nume: 'Giuleşti',
        simplu: 'Giulesti',
        comuna: 'Pietroasa'
      },
      {
        nume: 'Gurani',
        comuna: 'Pietroasa'
      },
      {
        nume: 'Moţeşti',
        simplu: 'Motesti',
        comuna: 'Pietroasa'
      },
      {
        nume: 'Măgura',
        simplu: 'Magura',
        comuna: 'Pietroasa'
      },
      {
        nume: 'Feneriş',
        simplu: 'Feneris',
        comuna: 'Pocola'
      },
      {
        nume: 'Petrani',
        comuna: 'Pocola'
      },
      {
        nume: 'Poietari',
        comuna: 'Pocola'
      },
      {
        nume: 'Sânmartin de Beiuş',
        simplu: 'Sanmartin de Beius',
        comuna: 'Pocola'
      },
      {
        nume: 'Coşdeni',
        simplu: 'Cosdeni',
        comuna: 'Pomezeu'
      },
      {
        nume: 'Câmpani de Pomezeu',
        simplu: 'Campani de Pomezeu',
        comuna: 'Pomezeu'
      },
      {
        nume: 'Hidiş',
        simplu: 'Hidis',
        comuna: 'Pomezeu'
      },
      {
        nume: 'Lacu Sărat',
        simplu: 'Lacu Sarat',
        comuna: 'Pomezeu'
      },
      {
        nume: 'Sitani',
        comuna: 'Pomezeu'
      },
      {
        nume: 'Spinuş de Pomezeu',
        simplu: 'Spinus de Pomezeu',
        comuna: 'Pomezeu'
      },
      {
        nume: 'Vălani de Pomezeu',
        simplu: 'Valani de Pomezeu',
        comuna: 'Pomezeu'
      },
      {
        nume: 'Bistra',
        comuna: 'Popesti'
      },
      {
        nume: 'Budoi',
        comuna: 'Popesti'
      },
      {
        nume: 'Cuzap',
        comuna: 'Popesti'
      },
      {
        nume: 'Varviz',
        comuna: 'Popesti'
      },
      {
        nume: 'Voivozi',
        comuna: 'Popesti'
      },
      {
        nume: 'Vărzari',
        simplu: 'Varzari',
        comuna: 'Popesti'
      },
      {
        nume: 'Albeşti',
        simplu: 'Albesti',
        comuna: 'Rabagani'
      },
      {
        nume: 'Brăteşti',
        simplu: 'Bratesti',
        comuna: 'Rabagani'
      },
      {
        nume: 'Salişte de Pomezeu',
        simplu: 'Saliste de Pomezeu',
        comuna: 'Rabagani'
      },
      {
        nume: 'Săucani',
        simplu: 'Saucani',
        comuna: 'Rabagani'
      },
      {
        nume: 'Vărăşeni',
        simplu: 'Varaseni',
        comuna: 'Rabagani'
      },
      {
        nume: 'Drăgoteni',
        simplu: 'Dragoteni',
        comuna: 'Remetea'
      },
      {
        nume: 'Meziad',
        comuna: 'Remetea'
      },
      {
        nume: 'Petreasa',
        comuna: 'Remetea'
      },
      {
        nume: 'Soimuş',
        simplu: 'Soimus',
        comuna: 'Remetea'
      },
      {
        nume: 'Cucuceni',
        comuna: 'Rieni'
      },
      {
        nume: 'Ghighişeni',
        simplu: 'Ghighiseni',
        comuna: 'Rieni'
      },
      {
        nume: 'Petrileni',
        comuna: 'Rieni'
      },
      {
        nume: 'Sudrigiu',
        comuna: 'Rieni'
      },
      {
        nume: 'Valea de Jos',
        comuna: 'Rieni'
      },
      {
        nume: 'Lazuri',
        comuna: 'Rosia'
      },
      {
        nume: 'Mihai Bravu',
        comuna: 'Rosiori'
      },
      {
        nume: 'Vaida',
        comuna: 'Rosiori'
      },
      {
        nume: 'Borşa',
        simplu: 'Borsa',
        comuna: 'Sacadat'
      },
      {
        nume: 'Săbolciu',
        simplu: 'Sabolciu',
        comuna: 'Sacadat'
      },
      {
        nume: 'Cadea',
        comuna: 'Sacueni'
      },
      {
        nume: 'Ciocaia',
        comuna: 'Sacueni'
      },
      {
        nume: 'Cubulcut',
        comuna: 'Sacueni'
      },
      {
        nume: 'Olosig',
        comuna: 'Sacueni'
      },
      {
        nume: 'Sânnicolau de Munte',
        simplu: 'Sannicolau de Munte',
        comuna: 'Sacueni'
      },
      {
        nume: 'Otomani',
        comuna: 'Salacea'
      },
      {
        nume: 'Hodoş',
        simplu: 'Hodos',
        comuna: 'Salard'
      },
      {
        nume: 'Sântimreu',
        simplu: 'Santimreu',
        comuna: 'Salard'
      },
      {
        nume: 'Copăceni',
        simplu: 'Copaceni',
        comuna: 'Sambata'
      },
      {
        nume: 'Ogeşti',
        simplu: 'Ogesti',
        comuna: 'Sambata'
      },
      {
        nume: 'Rogoz',
        comuna: 'Sambata'
      },
      {
        nume: 'Rotăreşti',
        simplu: 'Rotaresti',
        comuna: 'Sambata'
      },
      {
        nume: 'Zăvoiu',
        simplu: 'Zavoiu',
        comuna: 'Sambata'
      },
      {
        nume: 'Betfia',
        comuna: 'Sanmartin'
      },
      {
        nume: 'Băile Felix',
        simplu: 'Baile Felix',
        comuna: 'Sanmartin'
      },
      {
        nume: 'Cihei',
        comuna: 'Sanmartin'
      },
      {
        nume: 'Cordău',
        simplu: 'Cordau',
        comuna: 'Sanmartin'
      },
      {
        nume: 'Haieu',
        comuna: 'Sanmartin'
      },
      {
        nume: 'Rontău',
        simplu: 'Rontau',
        comuna: 'Sanmartin'
      },
      {
        nume: 'Berechiu',
        comuna: 'Sannicolau Roman'
      },
      {
        nume: 'Roit',
        comuna: 'Sannicolau Roman'
      },
      {
        nume: 'Palota',
        comuna: 'Santandrei'
      },
      {
        nume: 'Almaşu Mic',
        simplu: 'Almasu Mic',
        comuna: 'Sarbi'
      },
      {
        nume: 'Burzuc',
        comuna: 'Sarbi'
      },
      {
        nume: 'Chioag',
        comuna: 'Sarbi'
      },
      {
        nume: 'Fegernic',
        comuna: 'Sarbi'
      },
      {
        nume: 'Fegernicu Nou',
        comuna: 'Sarbi'
      },
      {
        nume: 'Sarcău',
        simplu: 'Sarcau',
        comuna: 'Sarbi'
      },
      {
        nume: 'Voivozi',
        comuna: 'Simian'
      },
      {
        nume: 'Silindru',
        simplu: 'Silindru',
        comuna: 'Simian'
      },
      {
        nume: 'Huta Voivozi',
        comuna: 'Sinteu'
      },
      {
        nume: 'Socet',
        comuna: 'Sinteu'
      },
      {
        nume: 'Valea Târnei',
        simplu: 'Valea Tarnei',
        comuna: 'Sinteu'
      },
      {
        nume: 'Borz',
        comuna: 'Soimi'
      },
      {
        nume: 'Codru',
        comuna: 'Soimi'
      },
      {
        nume: 'Dumbrăviţa de Codru',
        simplu: 'Dumbravita de Codru',
        comuna: 'Soimi'
      },
      {
        nume: 'Pocluşa de Beiuş',
        simplu: 'Poclusa de Beius',
        comuna: 'Soimi'
      },
      {
        nume: 'Sânnicolau de Beiuş',
        simplu: 'Sannicolau de Beius',
        comuna: 'Soimi'
      },
      {
        nume: 'Ursad',
        comuna: 'Soimi'
      },
      {
        nume: 'Urviş de Beiuş',
        simplu: 'Urvis de Beius',
        comuna: 'Soimi'
      },
      {
        nume: 'Ciuleşti',
        simplu: 'Ciulesti',
        comuna: 'Spinus'
      },
      {
        nume: 'Gurbeşti',
        simplu: 'Gurbesti',
        comuna: 'Spinus'
      },
      {
        nume: 'Nădar',
        simplu: 'Nadar',
        comuna: 'Spinus'
      },
      {
        nume: 'Sălişte',
        simplu: 'Saliste',
        comuna: 'Spinus'
      },
      {
        nume: 'Bălnaca',
        simplu: 'Balnaca',
        comuna: 'Suncuius'
      },
      {
        nume: 'Bălnaca-Groşi',
        simplu: 'Balnaca-Grosi',
        comuna: 'Suncuius'
      },
      {
        nume: 'Zece Hotare',
        comuna: 'Suncuius'
      },
      {
        nume: 'Borumlaca',
        comuna: 'Suplacu De Barcau'
      },
      {
        nume: 'Dolea',
        comuna: 'Suplacu De Barcau'
      },
      {
        nume: 'Foglaş',
        simplu: 'Foglas',
        comuna: 'Suplacu De Barcau'
      },
      {
        nume: 'Valea Cerului',
        comuna: 'Suplacu De Barcau'
      },
      {
        nume: 'Vâlcelele',
        simplu: 'Valcelele',
        comuna: 'Suplacu De Barcau'
      },
      {
        nume: 'Niuved',
        comuna: 'Tamaseu'
      },
      {
        nume: 'Parhida',
        comuna: 'Tamaseu'
      },
      {
        nume: 'Satu Nou',
        comuna: 'Tamaseu'
      },
      {
        nume: 'Mierag',
        comuna: 'Tarcaia'
      },
      {
        nume: 'Totoreni',
        comuna: 'Tarcaia'
      },
      {
        nume: 'Tărcăiţa',
        simplu: 'Tarcaita',
        comuna: 'Tarcaia'
      },
      {
        nume: 'Adoni',
        comuna: 'Tarcea'
      },
      {
        nume: 'Galoşpetreu',
        simplu: 'Galospetreu',
        comuna: 'Tarcea'
      },
      {
        nume: 'Bogei',
        comuna: 'Tauteu'
      },
      {
        nume: 'Chiribiş',
        simplu: 'Chiribis',
        comuna: 'Tauteu'
      },
      {
        nume: 'Ciutelec',
        comuna: 'Tauteu'
      },
      {
        nume: 'Poiana',
        comuna: 'Tauteu'
      },
      {
        nume: 'Hotar',
        comuna: 'Tetchea'
      },
      {
        nume: 'Subpiatră',
        simplu: 'Subpiatra',
        comuna: 'Tetchea'
      },
      {
        nume: 'Telechiu',
        comuna: 'Tetchea'
      },
      {
        nume: 'Bălaia',
        simplu: 'Balaia',
        comuna: 'Tileagd'
      },
      {
        nume: 'Călătani',
        simplu: 'Calatani',
        comuna: 'Tileagd'
      },
      {
        nume: 'Poşoloaca',
        simplu: 'Posoloaca',
        comuna: 'Tileagd'
      },
      {
        nume: 'Tilecuş',
        simplu: 'Tilecus',
        comuna: 'Tileagd'
      },
      {
        nume: 'Uileacu de Criş',
        simplu: 'Uileacu de Cris',
        comuna: 'Tileagd'
      },
      {
        nume: 'Belfir',
        comuna: 'Tinca'
      },
      {
        nume: 'Girişu Negru',
        simplu: 'Girisu Negru',
        comuna: 'Tinca'
      },
      {
        nume: 'Gurbediu',
        comuna: 'Tinca'
      },
      {
        nume: 'Râpa',
        simplu: 'Rapa',
        comuna: 'Tinca'
      },
      {
        nume: 'Căuaşd',
        simplu: 'Cauasd',
        comuna: 'Tulca'
      },
      {
        nume: 'Forău',
        simplu: 'Forau',
        comuna: 'Uileacu De Beius'
      },
      {
        nume: 'Prisaca',
        comuna: 'Uileacu De Beius'
      },
      {
        nume: 'Vălanii de Beiuş',
        simplu: 'Valanii de Beius',
        comuna: 'Uileacu De Beius'
      },
      {
        nume: 'Birtin',
        comuna: 'Vadu Crisului'
      },
      {
        nume: 'Tomnatic',
        comuna: 'Vadu Crisului'
      },
      {
        nume: 'Topa de Criş',
        simplu: 'Topa de Cris',
        comuna: 'Vadu Crisului'
      },
      {
        nume: 'Fâşca',
        simplu: 'Fasca',
        comuna: 'Varciorog'
      },
      {
        nume: 'Surducel',
        comuna: 'Varciorog'
      },
      {
        nume: 'Serghiş',
        simplu: 'Serghis',
        comuna: 'Varciorog'
      },
      {
        nume: 'Coleşti',
        simplu: 'Colesti',
        comuna: 'Vascau'
      },
      {
        nume: 'Câmp',
        simplu: 'Camp',
        comuna: 'Vascau'
      },
      {
        nume: 'Câmp-Moţi',
        simplu: 'Camp-Moti',
        comuna: 'Vascau'
      },
      {
        nume: 'Vărzarii de Jos',
        simplu: 'Varzarii de Jos',
        comuna: 'Vascau'
      },
      {
        nume: 'Vărzarii de Sus',
        simplu: 'Varzarii de Sus',
        comuna: 'Vascau'
      },
      {
        nume: 'Izvoarele',
        comuna: 'Viisoara'
      },
      {
        nume: 'Pădureni',
        simplu: 'Padureni',
        comuna: 'Viisoara'
      },
      {
        nume: 'Reghea',
        comuna: 'Viisoara'
      }
    ]
  },
  {
    auto: 'BN',
    nume: 'Bistriţa-Năsăud',
    localitati: [
      {
        nume: 'Beclean'
      },
      {
        nume: 'Bistriţa',
        simplu: 'Bistrita'
      },
      {
        nume: 'Bistriţa Bârgăului',
        simplu: 'Bistrita Bargaului'
      },
      {
        nume: 'Braniştea',
        simplu: 'Branistea'
      },
      {
        nume: 'Budacu de Jos'
      },
      {
        nume: 'Budeşti',
        simplu: 'Budesti'
      },
      {
        nume: 'Chiochiş',
        simplu: 'Chiochis'
      },
      {
        nume: 'Chiuza'
      },
      {
        nume: 'Ciceu-Giurgeşti',
        simplu: 'Ciceu-Giurgesti'
      },
      {
        nume: 'Coşbuc',
        simplu: 'Cosbuc'
      },
      {
        nume: 'Căianu Mic',
        simplu: 'Caianu Mic'
      },
      {
        nume: 'Dumitra'
      },
      {
        nume: 'Dumitriţa',
        simplu: 'Dumitrita'
      },
      {
        nume: 'Feldru'
      },
      {
        nume: 'Galaţii Bistriţei',
        simplu: 'Galatii Bistritei'
      },
      {
        nume: 'Ilva Mare'
      },
      {
        nume: 'Ilva Mica'
      },
      {
        nume: 'Josenii Bârgăului',
        simplu: 'Josenii Bargaului'
      },
      {
        nume: 'Lechinţa',
        simplu: 'Lechinta'
      },
      {
        nume: 'Leşu',
        simplu: 'Lesu'
      },
      {
        nume: 'Livezile'
      },
      {
        nume: 'Lunca Ilvei'
      },
      {
        nume: 'Maieru'
      },
      {
        nume: 'Matei'
      },
      {
        nume: 'Miceştii de Câmpie',
        simplu: 'Micestii de Campie'
      },
      {
        nume: 'Milaş',
        simplu: 'Milas'
      },
      {
        nume: 'Monor'
      },
      {
        nume: 'Măgura Ilvei',
        simplu: 'Magura Ilvei'
      },
      {
        nume: 'Mărişelu',
        simplu: 'Mariselu'
      },
      {
        nume: 'Negrileşti',
        simplu: 'Negrilesti'
      },
      {
        nume: 'Nuşeni',
        simplu: 'Nuseni'
      },
      {
        nume: 'Năsăud',
        simplu: 'Nasaud'
      },
      {
        nume: 'Parva'
      },
      {
        nume: 'Poiana Ilvei'
      },
      {
        nume: 'Prundu Bârgăului',
        simplu: 'Prundu Bargaului'
      },
      {
        nume: 'Rebra'
      },
      {
        nume: 'Rebrişoara',
        simplu: 'Rebrisoara'
      },
      {
        nume: 'Rodna'
      },
      {
        nume: 'Romuli'
      },
      {
        nume: 'Salva'
      },
      {
        nume: 'Spermezeu'
      },
      {
        nume: 'Sângeorz-Băi',
        simplu: 'Sangeorz-Bai'
      },
      {
        nume: 'Teaca'
      },
      {
        nume: 'Telciu'
      },
      {
        nume: 'Tiha Bârgăului',
        simplu: 'Tiha Bargaului'
      },
      {
        nume: 'Târlişua',
        simplu: 'Tarlisua'
      },
      {
        nume: 'Uriu'
      },
      {
        nume: 'Urmeniş',
        simplu: 'Urmenis'
      },
      {
        nume: 'Zagra'
      },
      {
        nume: 'Sanţ',
        simplu: 'Sant'
      },
      {
        nume: 'Sieu',
        simplu: 'Sieu'
      },
      {
        nume: 'Sieu-Măgheruş',
        simplu: 'Sieu-Magherus'
      },
      {
        nume: 'Sieu-Odorhei',
        simplu: 'Sieu-Odorhei'
      },
      {
        nume: 'Sieuţ',
        simplu: 'Sieut'
      },
      {
        nume: 'Sintereag',
        simplu: 'Sintereag'
      },
      {
        nume: 'Coldău',
        simplu: 'Coldau',
        comuna: 'Beclean'
      },
      {
        nume: 'Figa',
        comuna: 'Beclean'
      },
      {
        nume: 'Rusu de Jos',
        comuna: 'Beclean'
      },
      {
        nume: 'Ghinda',
        comuna: 'Bistrita'
      },
      {
        nume: 'Sigmir',
        comuna: 'Bistrita'
      },
      {
        nume: 'Slătiniţa',
        simplu: 'Slatinita',
        comuna: 'Bistrita'
      },
      {
        nume: 'Sărata',
        simplu: 'Sarata',
        comuna: 'Bistrita'
      },
      {
        nume: 'Unirea',
        comuna: 'Bistrita'
      },
      {
        nume: 'Viişoara',
        simplu: 'Viisoara',
        comuna: 'Bistrita'
      },
      {
        nume: 'Colibiţa',
        simplu: 'Colibita',
        comuna: 'Bistrita Bargaului'
      },
      {
        nume: 'Cireşoaia',
        simplu: 'Ciresoaia',
        comuna: 'Branistea'
      },
      {
        nume: 'Măluţ',
        simplu: 'Malut',
        comuna: 'Branistea'
      },
      {
        nume: 'Buduş',
        simplu: 'Budus',
        comuna: 'Budacu De Jos'
      },
      {
        nume: 'Jelna',
        comuna: 'Budacu De Jos'
      },
      {
        nume: 'Monariu',
        comuna: 'Budacu De Jos'
      },
      {
        nume: 'Simioneşti',
        simplu: 'Simionesti',
        comuna: 'Budacu De Jos'
      },
      {
        nume: 'Budeşti-Fânaţe',
        simplu: 'Budesti-Fanate',
        comuna: 'Budesti'
      },
      {
        nume: 'Tagu',
        simplu: 'Tagu',
        comuna: 'Budesti'
      },
      {
        nume: 'Tăgşoru',
        simplu: 'Tagsoru',
        comuna: 'Budesti'
      },
      {
        nume: 'Ciceu-Poieni',
        comuna: 'Caianu Mic'
      },
      {
        nume: 'Căianu Mare',
        simplu: 'Caianu Mare',
        comuna: 'Caianu Mic'
      },
      {
        nume: 'Dobric',
        comuna: 'Caianu Mic'
      },
      {
        nume: 'Dobricel',
        comuna: 'Caianu Mic'
      },
      {
        nume: 'Dumbrăviţa',
        simplu: 'Dumbravita',
        comuna: 'Caianu Mic'
      },
      {
        nume: 'Orheiu Bistriţei',
        simplu: 'Orheiu Bistritei',
        comuna: 'Cetate'
      },
      {
        nume: 'Petriş',
        simplu: 'Petris',
        comuna: 'Cetate'
      },
      {
        nume: 'Satu Nou',
        comuna: 'Cetate'
      },
      {
        nume: 'Apatiu',
        comuna: 'Chiochis'
      },
      {
        nume: 'Bozieş',
        simplu: 'Bozies',
        comuna: 'Chiochis'
      },
      {
        nume: 'Buza Cătun',
        simplu: 'Buza Catun',
        comuna: 'Chiochis'
      },
      {
        nume: 'Cheţiu',
        simplu: 'Chetiu',
        comuna: 'Chiochis'
      },
      {
        nume: 'Jimbor',
        comuna: 'Chiochis'
      },
      {
        nume: 'Manic',
        comuna: 'Chiochis'
      },
      {
        nume: 'Strugureni',
        comuna: 'Chiochis'
      },
      {
        nume: 'Sânnicoară',
        simplu: 'Sannicoara',
        comuna: 'Chiochis'
      },
      {
        nume: 'Tentea',
        simplu: 'Tentea',
        comuna: 'Chiochis'
      },
      {
        nume: 'Mireş',
        simplu: 'Mires',
        comuna: 'Chiuza'
      },
      {
        nume: 'Piatra',
        comuna: 'Chiuza'
      },
      {
        nume: 'Săsarm',
        simplu: 'Sasarm',
        comuna: 'Chiuza'
      },
      {
        nume: 'Dumbrăveni',
        simplu: 'Dumbraveni',
        comuna: 'Ciceu-Giurgesti'
      },
      {
        nume: 'Bichigiu',
        comuna: 'Cosbuc'
      },
      {
        nume: 'Cepari',
        comuna: 'Dumitra'
      },
      {
        nume: 'Tărpiu',
        simplu: 'Tarpiu',
        comuna: 'Dumitra'
      },
      {
        nume: 'Budacu de Sus',
        comuna: 'Dumitrita'
      },
      {
        nume: 'Ragla',
        comuna: 'Dumitrita'
      },
      {
        nume: 'Nepos',
        comuna: 'Feldru'
      },
      {
        nume: 'Albeştii Bistriţei',
        simplu: 'Albestii Bistritei',
        comuna: 'Galatii Bistritei'
      },
      {
        nume: 'Dipşa',
        simplu: 'Dipsa',
        comuna: 'Galatii Bistritei'
      },
      {
        nume: 'Herina',
        comuna: 'Galatii Bistritei'
      },
      {
        nume: 'Tonciu',
        comuna: 'Galatii Bistritei'
      },
      {
        nume: 'Ivăneasa',
        simplu: 'Ivaneasa',
        comuna: 'Ilva Mare'
      },
      {
        nume: 'Mijlocenii Bârgăului',
        simplu: 'Mijlocenii Bargaului',
        comuna: 'Josenii Bargaului'
      },
      {
        nume: 'Rusu Bârgăului',
        simplu: 'Rusu Bargaului',
        comuna: 'Josenii Bargaului'
      },
      {
        nume: 'Strâmba',
        simplu: 'Stramba',
        comuna: 'Josenii Bargaului'
      },
      {
        nume: 'Bungard',
        comuna: 'Lechinta'
      },
      {
        nume: 'Chiraleş',
        simplu: 'Chirales',
        comuna: 'Lechinta'
      },
      {
        nume: 'Sângeorzu Nou',
        simplu: 'Sangeorzu Nou',
        comuna: 'Lechinta'
      },
      {
        nume: 'Sâniacob',
        simplu: 'Saniacob',
        comuna: 'Lechinta'
      },
      {
        nume: 'Vermeş',
        simplu: 'Vermes',
        comuna: 'Lechinta'
      },
      {
        nume: 'Tigău',
        simplu: 'Tigau',
        comuna: 'Lechinta'
      },
      {
        nume: 'Lunca Leşului',
        simplu: 'Lunca Lesului',
        comuna: 'Lesu'
      },
      {
        nume: 'Cuşma',
        simplu: 'Cusma',
        comuna: 'Livezile'
      },
      {
        nume: 'Dorolea',
        comuna: 'Livezile'
      },
      {
        nume: 'Dumbrava',
        comuna: 'Livezile'
      },
      {
        nume: 'Valea Poenii',
        comuna: 'Livezile'
      },
      {
        nume: 'Arşiţa',
        simplu: 'Arsita',
        comuna: 'Magura Ilvei'
      },
      {
        nume: 'Anieş',
        simplu: 'Anies',
        comuna: 'Maieru'
      },
      {
        nume: 'Bârla',
        simplu: 'Barla',
        comuna: 'Mariselu'
      },
      {
        nume: 'Domneşti',
        simplu: 'Domnesti',
        comuna: 'Mariselu'
      },
      {
        nume: 'Jeica',
        comuna: 'Mariselu'
      },
      {
        nume: 'Măgurele',
        simplu: 'Magurele',
        comuna: 'Mariselu'
      },
      {
        nume: 'Neţeni',
        simplu: 'Neteni',
        comuna: 'Mariselu'
      },
      {
        nume: 'Sântioana',
        simplu: 'Santioana',
        comuna: 'Mariselu'
      },
      {
        nume: 'Bidiu',
        comuna: 'Matei'
      },
      {
        nume: 'Corvineşti',
        simplu: 'Corvinesti',
        comuna: 'Matei'
      },
      {
        nume: 'Enciu',
        comuna: 'Matei'
      },
      {
        nume: 'Fântânele',
        simplu: 'Fantanele',
        comuna: 'Matei'
      },
      {
        nume: 'Moruţ',
        simplu: 'Morut',
        comuna: 'Matei'
      },
      {
        nume: 'Fântâniţa',
        simplu: 'Fantanita',
        comuna: 'Micestii De Campie'
      },
      {
        nume: 'Visuia',
        comuna: 'Micestii De Campie'
      },
      {
        nume: 'Comlod',
        comuna: 'Milas'
      },
      {
        nume: 'Dupa Deal',
        comuna: 'Milas'
      },
      {
        nume: 'Ghemeş',
        simplu: 'Ghemes',
        comuna: 'Milas'
      },
      {
        nume: 'Hirean',
        comuna: 'Milas'
      },
      {
        nume: 'Orosfaia',
        comuna: 'Milas'
      },
      {
        nume: 'Gledin',
        comuna: 'Monor'
      },
      {
        nume: 'Liviu Rebreanu',
        comuna: 'Nasaud'
      },
      {
        nume: 'Luşca',
        simplu: 'Lusca',
        comuna: 'Nasaud'
      },
      {
        nume: 'Breaza',
        comuna: 'Negrilesti'
      },
      {
        nume: 'Purcărete',
        simplu: 'Purcarete',
        comuna: 'Negrilesti'
      },
      {
        nume: 'Floreşti',
        simplu: 'Floresti',
        comuna: 'Nimigea'
      },
      {
        nume: 'Mintiu',
        comuna: 'Nimigea'
      },
      {
        nume: 'Mititei',
        comuna: 'Nimigea'
      },
      {
        nume: 'Mocod',
        comuna: 'Nimigea'
      },
      {
        nume: 'Mogoşeni',
        simplu: 'Mogoseni',
        comuna: 'Nimigea'
      },
      {
        nume: 'Nimigea de Jos',
        comuna: 'Nimigea'
      },
      {
        nume: 'Nimigea de Sus',
        comuna: 'Nimigea'
      },
      {
        nume: 'Tăure',
        simplu: 'Taure',
        comuna: 'Nimigea'
      },
      {
        nume: 'Beudiu',
        comuna: 'Nuseni'
      },
      {
        nume: 'Dumbrava',
        comuna: 'Nuseni'
      },
      {
        nume: 'Feleac',
        comuna: 'Nuseni'
      },
      {
        nume: 'Malin',
        comuna: 'Nuseni'
      },
      {
        nume: 'Rusu de Sus',
        comuna: 'Nuseni'
      },
      {
        nume: 'Viţa',
        simplu: 'Vita',
        comuna: 'Nuseni'
      },
      {
        nume: 'Baţa',
        simplu: 'Bata',
        comuna: 'Petru Rares'
      },
      {
        nume: 'Ciceu-Corabia',
        comuna: 'Petru Rares'
      },
      {
        nume: 'Ciceu-Mihăieşti',
        simplu: 'Ciceu-Mihaiesti',
        comuna: 'Petru Rares'
      },
      {
        nume: 'Leleşti',
        simplu: 'Lelesti',
        comuna: 'Petru Rares'
      },
      {
        nume: 'Reteag',
        comuna: 'Petru Rares'
      },
      {
        nume: 'Susenii Bârgăului',
        simplu: 'Susenii Bargaului',
        comuna: 'Prundu Bargaului'
      },
      {
        nume: 'Gersa I',
        comuna: 'Rebrisoara'
      },
      {
        nume: 'Gersa Ii',
        comuna: 'Rebrisoara'
      },
      {
        nume: 'Poderei',
        comuna: 'Rebrisoara'
      },
      {
        nume: 'Valea Vinului',
        comuna: 'Rodna'
      },
      {
        nume: 'Dealu Stefăniţei',
        simplu: 'Dealu Stefanitei',
        comuna: 'Romuli'
      },
      {
        nume: 'Runcu Salvei',
        comuna: 'Salva'
      },
      {
        nume: 'Cormaia',
        comuna: 'Sangeorz-Bai'
      },
      {
        nume: 'Valea Borcutului',
        comuna: 'Sangeorz-Bai'
      },
      {
        nume: 'Brăteni',
        simplu: 'Brateni',
        comuna: 'Sanmihaiu De Cimpie'
      },
      {
        nume: 'La Curte',
        comuna: 'Sanmihaiu De Cimpie'
      },
      {
        nume: 'Stupini',
        comuna: 'Sanmihaiu De Cimpie'
      },
      {
        nume: 'Sânmihaiu de Câmpie',
        simplu: 'Sanmihaiu de Campie',
        comuna: 'Sanmihaiu De Cimpie'
      },
      {
        nume: 'Sălcuţa',
        simplu: 'Salcuta',
        comuna: 'Sanmihaiu De Cimpie'
      },
      {
        nume: 'Zoreni',
        comuna: 'Sanmihaiu De Cimpie'
      },
      {
        nume: 'Valea Mare',
        comuna: 'Sant'
      },
      {
        nume: 'Ardan',
        comuna: 'Sieu'
      },
      {
        nume: 'Posmuş',
        simplu: 'Posmus',
        comuna: 'Sieu'
      },
      {
        nume: 'Soimuş',
        simplu: 'Soimus',
        comuna: 'Sieu'
      },
      {
        nume: 'Arcalia',
        comuna: 'Sieu-Magherus'
      },
      {
        nume: 'Chintelnic',
        comuna: 'Sieu-Magherus'
      },
      {
        nume: 'Crainimăt',
        simplu: 'Crainimat',
        comuna: 'Sieu-Magherus'
      },
      {
        nume: 'Podirei',
        comuna: 'Sieu-Magherus'
      },
      {
        nume: 'Sărăţel',
        simplu: 'Saratel',
        comuna: 'Sieu-Magherus'
      },
      {
        nume: 'Valea Măgheruşului',
        simplu: 'Valea Magherusului',
        comuna: 'Sieu-Magherus'
      },
      {
        nume: 'Agrişu de Jos',
        simplu: 'Agrisu de Jos',
        comuna: 'Sieu-Odorhei'
      },
      {
        nume: 'Agrişu de Sus',
        simplu: 'Agrisu de Sus',
        comuna: 'Sieu-Odorhei'
      },
      {
        nume: 'Bretea',
        comuna: 'Sieu-Odorhei'
      },
      {
        nume: 'Coasta',
        comuna: 'Sieu-Odorhei'
      },
      {
        nume: 'Cristur-Sieu',
        simplu: 'Cristur-Sieu',
        comuna: 'Sieu-Odorhei'
      },
      {
        nume: 'Sirioara',
        simplu: 'Sirioara',
        comuna: 'Sieu-Odorhei'
      },
      {
        nume: 'Lunca',
        comuna: 'Sieut'
      },
      {
        nume: 'Ruştior',
        simplu: 'Rustior',
        comuna: 'Sieut'
      },
      {
        nume: 'Sebiş',
        simplu: 'Sebis',
        comuna: 'Sieut'
      },
      {
        nume: 'Draga',
        comuna: 'Silivasu De Cimpie'
      },
      {
        nume: 'Fânaţele Silivaşului',
        simplu: 'Fanatele Silivasului',
        comuna: 'Silivasu De Cimpie'
      },
      {
        nume: 'Porumbenii',
        comuna: 'Silivasu De Cimpie'
      },
      {
        nume: 'Silivaşu de Câmpie',
        simplu: 'Silivasu de Campie',
        comuna: 'Silivasu De Cimpie'
      },
      {
        nume: 'Blăjenii de Jos',
        simplu: 'Blajenii de Jos',
        comuna: 'Sintereag'
      },
      {
        nume: 'Blăjenii de Sus',
        simplu: 'Blajenii de Sus',
        comuna: 'Sintereag'
      },
      {
        nume: 'Caila',
        comuna: 'Sintereag'
      },
      {
        nume: 'Cociu',
        comuna: 'Sintereag'
      },
      {
        nume: 'Sieu-Sfântu',
        simplu: 'Sieu-Sfantu',
        comuna: 'Sintereag'
      },
      {
        nume: 'Sintereag-Gară',
        simplu: 'Sintereag-Gara',
        comuna: 'Sintereag'
      },
      {
        nume: 'Hălmăsău',
        simplu: 'Halmasau',
        comuna: 'Spermezeu'
      },
      {
        nume: 'Lunca Borlesei',
        comuna: 'Spermezeu'
      },
      {
        nume: 'Păltineasa',
        simplu: 'Paltineasa',
        comuna: 'Spermezeu'
      },
      {
        nume: 'Sita',
        comuna: 'Spermezeu'
      },
      {
        nume: 'Seşuri Spermezeu-Vale',
        simplu: 'Sesuri Spermezeu-Vale',
        comuna: 'Spermezeu'
      },
      {
        nume: 'Agrieş',
        simplu: 'Agries',
        comuna: 'Tarlisua'
      },
      {
        nume: 'Agrieşel',
        simplu: 'Agriesel',
        comuna: 'Tarlisua'
      },
      {
        nume: 'Borleasa',
        comuna: 'Tarlisua'
      },
      {
        nume: 'Cireaşi',
        simplu: 'Cireasi',
        comuna: 'Tarlisua'
      },
      {
        nume: 'Lunca Săteasca',
        simplu: 'Lunca Sateasca',
        comuna: 'Tarlisua'
      },
      {
        nume: 'Molişet',
        simplu: 'Moliset',
        comuna: 'Tarlisua'
      },
      {
        nume: 'Oarzina',
        comuna: 'Tarlisua'
      },
      {
        nume: 'Răcăteşu',
        simplu: 'Racatesu',
        comuna: 'Tarlisua'
      },
      {
        nume: 'Sendroaia',
        simplu: 'Sendroaia',
        comuna: 'Tarlisua'
      },
      {
        nume: 'Archiud',
        comuna: 'Teaca'
      },
      {
        nume: 'Budurleni',
        comuna: 'Teaca'
      },
      {
        nume: 'Ocniţa',
        simplu: 'Ocnita',
        comuna: 'Teaca'
      },
      {
        nume: 'Pinticu',
        comuna: 'Teaca'
      },
      {
        nume: 'Viile Tecii',
        comuna: 'Teaca'
      },
      {
        nume: 'Fiad',
        comuna: 'Telciu'
      },
      {
        nume: 'Telcişor',
        simplu: 'Telcisor',
        comuna: 'Telciu'
      },
      {
        nume: 'Ciosa',
        comuna: 'Tiha Bargaului'
      },
      {
        nume: 'Mureşenii Bârgăului',
        simplu: 'Muresenii Bargaului',
        comuna: 'Tiha Bargaului'
      },
      {
        nume: 'Piatra Fântânele',
        simplu: 'Piatra Fantanele',
        comuna: 'Tiha Bargaului'
      },
      {
        nume: 'Tureac',
        comuna: 'Tiha Bargaului'
      },
      {
        nume: 'Cristeştii Ciceului',
        simplu: 'Cristestii Ciceului',
        comuna: 'Uriu'
      },
      {
        nume: 'Hăşmaşu Ciceului',
        simplu: 'Hasmasu Ciceului',
        comuna: 'Uriu'
      },
      {
        nume: 'Ilişua',
        simplu: 'Ilisua',
        comuna: 'Uriu'
      },
      {
        nume: 'Coşeriu',
        simplu: 'Coseriu',
        comuna: 'Urmenis'
      },
      {
        nume: 'Câmp',
        simplu: 'Camp',
        comuna: 'Urmenis'
      },
      {
        nume: 'Delureni',
        comuna: 'Urmenis'
      },
      {
        nume: 'Fânaţe',
        simplu: 'Fanate',
        comuna: 'Urmenis'
      },
      {
        nume: 'Podenii',
        comuna: 'Urmenis'
      },
      {
        nume: 'Scoabe',
        comuna: 'Urmenis'
      },
      {
        nume: 'Valea',
        comuna: 'Urmenis'
      },
      {
        nume: 'Valea Mare',
        comuna: 'Urmenis'
      },
      {
        nume: 'Sopteriu',
        simplu: 'Sopteriu',
        comuna: 'Urmenis'
      },
      {
        nume: 'Alunişul',
        simplu: 'Alunisul',
        comuna: 'Zagra'
      },
      {
        nume: 'Perişor',
        simplu: 'Perisor',
        comuna: 'Zagra'
      },
      {
        nume: 'Poienile Zagrei',
        comuna: 'Zagra'
      },
      {
        nume: 'Suplai',
        comuna: 'Zagra'
      }
    ]
  },
  {
    auto: 'BT',
    nume: 'Botoşani',
    localitati: [
      {
        nume: 'Albeşti',
        simplu: 'Albesti'
      },
      {
        nume: 'Avrămeni',
        simplu: 'Avrameni'
      },
      {
        nume: 'Botoşani',
        simplu: 'Botosani'
      },
      {
        nume: 'Broscăuţi',
        simplu: 'Broscauti'
      },
      {
        nume: 'Brăeşti',
        simplu: 'Braesti'
      },
      {
        nume: 'Bucecea'
      },
      {
        nume: 'Băluşeni',
        simplu: 'Baluseni'
      },
      {
        nume: 'Conceşti',
        simplu: 'Concesti'
      },
      {
        nume: 'Copălău',
        simplu: 'Copalau'
      },
      {
        nume: 'Cordăreni',
        simplu: 'Cordareni'
      },
      {
        nume: 'Corlăteni',
        simplu: 'Corlateni'
      },
      {
        nume: 'Corni'
      },
      {
        nume: 'Coşula',
        simplu: 'Cosula'
      },
      {
        nume: 'Coţuşca',
        simplu: 'Cotusca'
      },
      {
        nume: 'Cristeşti',
        simplu: 'Cristesti'
      },
      {
        nume: 'Cristineşti',
        simplu: 'Cristinesti'
      },
      {
        nume: 'Curteşti',
        simplu: 'Curtesti'
      },
      {
        nume: 'Cândeşti',
        simplu: 'Candesti'
      },
      {
        nume: 'Călăraşi',
        simplu: 'Calarasi'
      },
      {
        nume: 'Darabani'
      },
      {
        nume: 'Dersca'
      },
      {
        nume: 'Dimăcheni',
        simplu: 'Dimacheni'
      },
      {
        nume: 'Dobârceni',
        simplu: 'Dobarceni'
      },
      {
        nume: 'Dorohoi'
      },
      {
        nume: 'Drăguşeni',
        simplu: 'Draguseni'
      },
      {
        nume: 'Durneşti',
        simplu: 'Durnesti'
      },
      {
        nume: 'Dângeni',
        simplu: 'Dangeni'
      },
      {
        nume: 'Flamânzi',
        simplu: 'Flamanzi'
      },
      {
        nume: 'Frumuşica',
        simplu: 'Frumusica'
      },
      {
        nume: 'George Enescu'
      },
      {
        nume: 'Gorbăneşti',
        simplu: 'Gorbanesti'
      },
      {
        nume: 'Havârna',
        simplu: 'Havarna'
      },
      {
        nume: 'Hilişeu-Horia',
        simplu: 'Hiliseu-Horia'
      },
      {
        nume: 'Hlipiceni'
      },
      {
        nume: 'Hudeşti',
        simplu: 'Hudesti'
      },
      {
        nume: 'Hăneşti',
        simplu: 'Hanesti'
      },
      {
        nume: 'Ibăneşti',
        simplu: 'Ibanesti'
      },
      {
        nume: 'Leorda'
      },
      {
        nume: 'Lozna'
      },
      {
        nume: 'Lunca'
      },
      {
        nume: 'Manoleasa'
      },
      {
        nume: 'Mihăileni',
        simplu: 'Mihaileni'
      },
      {
        nume: 'Mihălăşeni',
        simplu: 'Mihalaseni'
      },
      {
        nume: 'Mileanca'
      },
      {
        nume: 'Mitoc'
      },
      {
        nume: 'Nicşeni',
        simplu: 'Nicseni'
      },
      {
        nume: 'Pomârla',
        simplu: 'Pomarla'
      },
      {
        nume: 'Prăjeni',
        simplu: 'Prajeni'
      },
      {
        nume: 'Păltiniş',
        simplu: 'Paltinis'
      },
      {
        nume: 'Ripiceni'
      },
      {
        nume: 'Roma'
      },
      {
        nume: 'Româneşti',
        simplu: 'Romanesti'
      },
      {
        nume: 'Răchiţi',
        simplu: 'Rachiti'
      },
      {
        nume: 'Rădăuţi-Prut',
        simplu: 'Radauti-Prut'
      },
      {
        nume: 'Răuşeni',
        simplu: 'Rauseni'
      },
      {
        nume: 'Santa Mare'
      },
      {
        nume: 'Stăuceni',
        simplu: 'Stauceni'
      },
      {
        nume: 'Suharău',
        simplu: 'Suharau'
      },
      {
        nume: 'Suliţa',
        simplu: 'Sulita'
      },
      {
        nume: 'Săveni',
        simplu: 'Saveni'
      },
      {
        nume: 'Todireni'
      },
      {
        nume: 'Truşeşti',
        simplu: 'Trusesti'
      },
      {
        nume: 'Tudora'
      },
      {
        nume: 'Ungureni'
      },
      {
        nume: 'Unţeni',
        simplu: 'Unteni'
      },
      {
        nume: 'Viişoara',
        simplu: 'Viisoara'
      },
      {
        nume: 'Vlădeni',
        simplu: 'Vladeni'
      },
      {
        nume: 'Vlăsineşti',
        simplu: 'Vlasinesti'
      },
      {
        nume: 'Vorniceni'
      },
      {
        nume: 'Vorona'
      },
      {
        nume: 'Vârfu Câmpului',
        simplu: 'Varfu Campului'
      },
      {
        nume: 'Văculeşti',
        simplu: 'Vaculesti'
      },
      {
        nume: 'Sendriceni',
        simplu: 'Sendriceni'
      },
      {
        nume: 'Stefăneşti',
        simplu: 'Stefanesti'
      },
      {
        nume: 'Stiubieni',
        simplu: 'Stiubieni'
      },
      {
        nume: 'Buimăceni',
        simplu: 'Buimaceni',
        comuna: 'Albesti'
      },
      {
        nume: 'Coştiugeni',
        simplu: 'Costiugeni',
        comuna: 'Albesti'
      },
      {
        nume: 'Jijia',
        comuna: 'Albesti'
      },
      {
        nume: 'Măşcăteni',
        simplu: 'Mascateni',
        comuna: 'Albesti'
      },
      {
        nume: 'Tudor Vladimirescu',
        comuna: 'Albesti'
      },
      {
        nume: 'Adăşeni',
        simplu: 'Adaseni',
        comuna: 'Avrameni'
      },
      {
        nume: 'Aurel Vlaicu',
        comuna: 'Avrameni'
      },
      {
        nume: 'Dimitrie Cantemir',
        comuna: 'Avrameni'
      },
      {
        nume: 'Ichimeni',
        comuna: 'Avrameni'
      },
      {
        nume: 'Panaitoaia',
        comuna: 'Avrameni'
      },
      {
        nume: 'Timuş',
        simplu: 'Timus',
        comuna: 'Avrameni'
      },
      {
        nume: 'Tudor Vladimirescu',
        comuna: 'Avrameni'
      },
      {
        nume: 'Zoiţani',
        simplu: 'Zoitani',
        comuna: 'Avrameni'
      },
      {
        nume: 'Buzeni',
        comuna: 'Baluseni'
      },
      {
        nume: 'Băluşenii Noi',
        simplu: 'Balusenii Noi',
        comuna: 'Baluseni'
      },
      {
        nume: 'Coşuleni',
        simplu: 'Cosuleni',
        comuna: 'Baluseni'
      },
      {
        nume: 'Draxini',
        comuna: 'Baluseni'
      },
      {
        nume: 'Zăiceşti',
        simplu: 'Zaicesti',
        comuna: 'Baluseni'
      },
      {
        nume: 'Blândeşti',
        simplu: 'Blandesti',
        comuna: 'Blindesti'
      },
      {
        nume: 'Cerchejeni',
        comuna: 'Blindesti'
      },
      {
        nume: 'Soldăneşti',
        simplu: 'Soldanesti',
        comuna: 'Blindesti'
      },
      {
        nume: 'Poiana',
        comuna: 'Braesti'
      },
      {
        nume: 'Popeni',
        comuna: 'Braesti'
      },
      {
        nume: 'Vâlcelele',
        simplu: 'Valcelele',
        comuna: 'Braesti'
      },
      {
        nume: 'Slobozia',
        comuna: 'Broscauti'
      },
      {
        nume: 'Bohoghina',
        comuna: 'Bucecea'
      },
      {
        nume: 'Călineşti',
        simplu: 'Calinesti',
        comuna: 'Bucecea'
      },
      {
        nume: 'Libertatea',
        comuna: 'Calarasi'
      },
      {
        nume: 'Pleşani',
        simplu: 'Plesani',
        comuna: 'Calarasi'
      },
      {
        nume: 'Călineşti',
        simplu: 'Calinesti',
        comuna: 'Candesti'
      },
      {
        nume: 'Talpa',
        comuna: 'Candesti'
      },
      {
        nume: 'Viţcani',
        simplu: 'Vitcani',
        comuna: 'Candesti'
      },
      {
        nume: 'Movileni',
        comuna: 'Concesti'
      },
      {
        nume: 'Cerbu',
        comuna: 'Copalau'
      },
      {
        nume: 'Cotu',
        comuna: 'Copalau'
      },
      {
        nume: 'Griviţa',
        simplu: 'Grivita',
        comuna: 'Cordareni'
      },
      {
        nume: 'Slobozia',
        comuna: 'Cordăreni'
      },
      {
        nume: 'Carasa',
        comuna: 'Corlateni'
      },
      {
        nume: 'Podeni',
        comuna: 'Corlateni'
      },
      {
        nume: 'Vlădeni',
        simplu: 'Vladeni',
        comuna: 'Corlateni'
      },
      {
        nume: 'Balta Arsă',
        simplu: 'Balta Arsa',
        comuna: 'Corni'
      },
      {
        nume: 'Mesteacăn',
        simplu: 'Mesteacan',
        comuna: 'Corni'
      },
      {
        nume: 'Sarafineşti',
        simplu: 'Sarafinesti',
        comuna: 'Corni'
      },
      {
        nume: 'Buda',
        comuna: 'Cosula'
      },
      {
        nume: 'Pădureni',
        simplu: 'Padureni',
        comuna: 'Cosula'
      },
      {
        nume: 'Supitca',
        simplu: 'Supitca',
        comuna: 'Cosula'
      },
      {
        nume: 'Avram Iancu',
        comuna: 'Cotusca'
      },
      {
        nume: 'Cotu Miculinţi',
        simplu: 'Cotu Miculinti',
        comuna: 'Cotusca'
      },
      {
        nume: 'Crasnaleuca',
        comuna: 'Cotusca'
      },
      {
        nume: 'Ghireni',
        comuna: 'Cotusca'
      },
      {
        nume: 'Mihail Kogălniceanu',
        simplu: 'Mihail Kogalniceanu',
        comuna: 'Cotusca'
      },
      {
        nume: 'Nichiteni',
        comuna: 'Cotusca'
      },
      {
        nume: 'Nicolae Bălcescu',
        simplu: 'Nicolae Balcescu',
        comuna: 'Cotusca'
      },
      {
        nume: 'Puţureni',
        simplu: 'Putureni',
        comuna: 'Cotusca'
      },
      {
        nume: 'Oneaga',
        comuna: 'Cristesti'
      },
      {
        nume: 'Schit-Orăşeni',
        simplu: 'Schit-Oraseni',
        comuna: 'Cristesti'
      },
      {
        nume: 'Unguroaia',
        comuna: 'Cristesti'
      },
      {
        nume: 'Baranca',
        comuna: 'Cristinesti'
      },
      {
        nume: 'Dragalina',
        comuna: 'Cristinesti'
      },
      {
        nume: 'Fundu Herţii',
        simplu: 'Fundu Hertii',
        comuna: 'Cristinesti'
      },
      {
        nume: 'Poiana',
        comuna: 'Cristinesti'
      },
      {
        nume: 'Dămileni',
        simplu: 'Damileni',
        comuna: 'Cristinești'
      },
      {
        nume: 'Agafton',
        comuna: 'Curtesti'
      },
      {
        nume: 'Băiceni',
        simplu: 'Baiceni',
        comuna: 'Curtesti'
      },
      {
        nume: 'Hudum',
        comuna: 'Curtesti'
      },
      {
        nume: 'Mănăstirea Doamnei',
        simplu: 'Manastirea Doamnei',
        comuna: 'Curtesti'
      },
      {
        nume: 'Orăşeni-Deal',
        simplu: 'Oraseni-Deal',
        comuna: 'Curtesti'
      },
      {
        nume: 'Orăşeni-Vale',
        simplu: 'Oraseni-Vale',
        comuna: 'Curtesti'
      },
      {
        nume: 'Hulub',
        comuna: 'Dangeni'
      },
      {
        nume: 'Iacobeni',
        comuna: 'Dangeni'
      },
      {
        nume: 'Strahotin',
        comuna: 'Dangeni'
      },
      {
        nume: 'Bajura',
        comuna: 'Darabani'
      },
      {
        nume: 'Eşanca',
        simplu: 'Esanca',
        comuna: 'Darabani'
      },
      {
        nume: 'Lismăniţa',
        simplu: 'Lismanita',
        comuna: 'Darabani'
      },
      {
        nume: 'Mateieni',
        comuna: 'Dimacheni'
      },
      {
        nume: 'Recia-Verbia',
        comuna: 'Dimacheni'
      },
      {
        nume: 'Bivolari',
        comuna: 'Dobarceni'
      },
      {
        nume: 'Brăteni',
        simplu: 'Brateni',
        comuna: 'Dobarceni'
      },
      {
        nume: 'Cişmăneşti',
        simplu: 'Cismanesti',
        comuna: 'Dobarceni'
      },
      {
        nume: 'Livada',
        comuna: 'Dobarceni'
      },
      {
        nume: 'Murguţa',
        simplu: 'Murguta',
        comuna: 'Dobarceni'
      },
      {
        nume: 'Dealu Mare',
        comuna: 'Dorohoi'
      },
      {
        nume: 'Loturi Enescu',
        comuna: 'Dorohoi'
      },
      {
        nume: 'Progresul',
        comuna: 'Dorohoi'
      },
      {
        nume: 'Podriga',
        comuna: 'Draguseni'
      },
      {
        nume: 'Sarata-Drăguşeni',
        simplu: 'Sarata-Draguseni',
        comuna: 'Draguseni'
      },
      {
        nume: 'Broşteni',
        simplu: 'Brosteni',
        comuna: 'Durnesti'
      },
      {
        nume: 'Bârsăneşti',
        simplu: 'Barsanesti',
        comuna: 'Durnesti'
      },
      {
        nume: 'Băbiceni',
        simplu: 'Babiceni',
        comuna: 'Durnesti'
      },
      {
        nume: 'Cucuteni',
        comuna: 'Durnesti'
      },
      {
        nume: 'Guranda',
        comuna: 'Durnesti'
      },
      {
        nume: 'Chiţoveni',
        simplu: 'Chitoveni',
        comuna: 'Flamanzi'
      },
      {
        nume: 'Nicolae Bălcescu',
        simplu: 'Nicolae Balcescu',
        comuna: 'Flamanzi'
      },
      {
        nume: 'Poiana',
        comuna: 'Flamanzi'
      },
      {
        nume: 'Prisăcani',
        simplu: 'Prisacani',
        comuna: 'Flamanzi'
      },
      {
        nume: 'Boscoteni',
        comuna: 'Frumusica'
      },
      {
        nume: 'Rădeni',
        simplu: 'Radeni',
        comuna: 'Frumusica'
      },
      {
        nume: 'Storeşti',
        simplu: 'Storesti',
        comuna: 'Frumusica'
      },
      {
        nume: 'Vlădeni-Deal',
        simplu: 'Vladeni-Deal',
        comuna: 'Frumusica'
      },
      {
        nume: 'Sendreni',
        simplu: 'Sendreni',
        comuna: 'Frumusica'
      },
      {
        nume: 'Arborea',
        comuna: 'George Enescu'
      },
      {
        nume: 'Dumeni',
        comuna: 'George Enescu'
      },
      {
        nume: 'Popeni',
        comuna: 'George Enescu'
      },
      {
        nume: 'Stânca',
        simplu: 'Stanca',
        comuna: 'George Enescu'
      },
      {
        nume: 'Bătrâneşti',
        simplu: 'Batranesti',
        comuna: 'Gorbanesti'
      },
      {
        nume: 'George Coşbuc',
        simplu: 'George Cosbuc',
        comuna: 'Gorbanesti'
      },
      {
        nume: 'Mihai Eminescu',
        comuna: 'Gorbanesti'
      },
      {
        nume: 'Silişcani',
        simplu: 'Siliscani',
        comuna: 'Gorbanesti'
      },
      {
        nume: 'Socrujeni',
        comuna: 'Gorbanesti'
      },
      {
        nume: 'Viforeni',
        comuna: 'Gorbanesti'
      },
      {
        nume: 'Vânători',
        simplu: 'Vanatori',
        comuna: 'Gorbanesti'
      },
      {
        nume: 'Borolea',
        comuna: 'Hanesti'
      },
      {
        nume: 'Moara Jorii',
        comuna: 'Hanesti'
      },
      {
        nume: 'Sarata-Basarab',
        comuna: 'Hanesti'
      },
      {
        nume: 'Slobozia Hăneşti',
        simplu: 'Slobozia Hanesti',
        comuna: 'Hanesti'
      },
      {
        nume: 'Balinţi',
        simplu: 'Balinti',
        comuna: 'Havarna'
      },
      {
        nume: 'Galbeni',
        comuna: 'Havarna'
      },
      {
        nume: 'Gârbeni',
        simplu: 'Garbeni',
        comuna: 'Havarna'
      },
      {
        nume: 'Niculcea',
        comuna: 'Havarna'
      },
      {
        nume: 'Tătărăşeni',
        simplu: 'Tataraseni',
        comuna: 'Havarna'
      },
      {
        nume: 'Corjăuţi',
        simplu: 'Corjauti',
        comuna: 'Hiliseu-Horia'
      },
      {
        nume: 'Hilişeu-Cloşca',
        simplu: 'Hiliseu-Closca',
        comuna: 'Hiliseu-Horia'
      },
      {
        nume: 'Hilişeu-Crişan',
        simplu: 'Hiliseu-Crisan',
        comuna: 'Hiliseu-Horia'
      },
      {
        nume: 'Iezer',
        comuna: 'Hiliseu-Horia'
      },
      {
        nume: 'Dragalina',
        comuna: 'Hlipiceni'
      },
      {
        nume: 'Victoria',
        comuna: 'Hlipiceni'
      },
      {
        nume: 'Alba',
        comuna: 'Hudesti'
      },
      {
        nume: 'Baranca',
        comuna: 'Hudesti'
      },
      {
        nume: 'Mlenăuţi',
        simplu: 'Mlenauti',
        comuna: 'Hudesti'
      },
      {
        nume: 'Başeu',
        simplu: 'Baseu',
        comuna: 'Hudești'
      },
      {
        nume: 'Vatra',
        comuna: 'Hudești'
      },
      {
        nume: 'Dumbrăviţa',
        simplu: 'Dumbravita',
        comuna: 'Ibanesti'
      },
      {
        nume: 'Belcea',
        comuna: 'Leorda'
      },
      {
        nume: 'Costineşti',
        simplu: 'Costinesti',
        comuna: 'Leorda'
      },
      {
        nume: 'Dolina',
        comuna: 'Leorda'
      },
      {
        nume: 'Mitoc',
        comuna: 'Leorda'
      },
      {
        nume: 'Străteni',
        simplu: 'Strateni',
        comuna: 'Lozna'
      },
      {
        nume: 'Stroieşti',
        simplu: 'Stroiesti',
        comuna: 'Lunca'
      },
      {
        nume: 'Zlătunoaia',
        simplu: 'Zlatunoaia',
        comuna: 'Lunca'
      },
      {
        nume: 'Bold',
        comuna: 'Manoleasa'
      },
      {
        nume: 'Flondora',
        comuna: 'Manoleasa'
      },
      {
        nume: 'Iorga',
        comuna: 'Manoleasa'
      },
      {
        nume: 'Liveni',
        comuna: 'Manoleasa'
      },
      {
        nume: 'Loturi',
        comuna: 'Manoleasa'
      },
      {
        nume: 'Manoleasa-Prut',
        comuna: 'Manoleasa'
      },
      {
        nume: 'Sadoveni',
        comuna: 'Manoleasa'
      },
      {
        nume: 'Zahoreni',
        comuna: 'Manoleasa'
      },
      {
        nume: 'Serpeniţa',
        simplu: 'Serpenita',
        comuna: 'Manoleasa'
      },
      {
        nume: 'Baisa',
        comuna: 'Mihai Eminescu'
      },
      {
        nume: 'Cerviceşti',
        simplu: 'Cervicesti',
        comuna: 'Mihai Eminescu'
      },
      {
        nume: 'Cerviceşti-Deal',
        simplu: 'Cervicesti-Deal',
        comuna: 'Mihai Eminescu'
      },
      {
        nume: 'Cucorăni',
        simplu: 'Cucorani',
        comuna: 'Mihai Eminescu'
      },
      {
        nume: 'Cătămăreşti',
        simplu: 'Catamaresti',
        comuna: 'Mihai Eminescu'
      },
      {
        nume: 'Cătămăreşti-Deal',
        simplu: 'Catamaresti-Deal',
        comuna: 'Mihai Eminescu'
      },
      {
        nume: 'Ipoteşti',
        simplu: 'Ipotesti',
        comuna: 'Mihai Eminescu'
      },
      {
        nume: 'Manoleşti',
        simplu: 'Manolesti',
        comuna: 'Mihai Eminescu'
      },
      {
        nume: 'Stânceşti',
        simplu: 'Stancesti',
        comuna: 'Mihai Eminescu'
      },
      {
        nume: 'Pârâu Negru',
        simplu: 'Parau Negru',
        comuna: 'Mihaileni'
      },
      {
        nume: 'Rogojeşti',
        simplu: 'Rogojesti',
        comuna: 'Mihaileni'
      },
      {
        nume: 'Caraiman',
        comuna: 'Mihalaseni'
      },
      {
        nume: 'Negreşti',
        simplu: 'Negresti',
        comuna: 'Mihalaseni'
      },
      {
        nume: 'Năstase',
        simplu: 'Nastase',
        comuna: 'Mihalaseni'
      },
      {
        nume: 'Păun',
        simplu: 'Paun',
        comuna: 'Mihalaseni'
      },
      {
        nume: 'Sarata',
        comuna: 'Mihalaseni'
      },
      {
        nume: 'Slobozia Silişcani',
        simplu: 'Slobozia Siliscani',
        comuna: 'Mihalaseni'
      },
      {
        nume: 'Codreni',
        comuna: 'Mileanca'
      },
      {
        nume: 'Scutari',
        comuna: 'Mileanca'
      },
      {
        nume: 'Seliştea',
        simplu: 'Selistea',
        comuna: 'Mileanca'
      },
      {
        nume: 'Horia',
        comuna: 'Mitoc'
      },
      {
        nume: 'Dacia',
        comuna: 'Nicseni'
      },
      {
        nume: 'Dorobanţi',
        simplu: 'Dorobanti',
        comuna: 'Nicseni'
      },
      {
        nume: 'Cuzlău',
        simplu: 'Cuzlau',
        comuna: 'Paltinis'
      },
      {
        nume: 'Horodiştea',
        simplu: 'Horodistea',
        comuna: 'Paltinis'
      },
      {
        nume: 'Slobozia',
        comuna: 'Paltinis'
      },
      {
        nume: 'Hulubeşti',
        simplu: 'Hulubesti',
        comuna: 'Pomarla'
      },
      {
        nume: 'Racovăţ',
        simplu: 'Racovat',
        comuna: 'Pomarla'
      },
      {
        nume: 'Câmpeni',
        simplu: 'Campeni',
        comuna: 'Prajeni'
      },
      {
        nume: 'Lupăria',
        simplu: 'Luparia',
        comuna: 'Prajeni'
      },
      {
        nume: 'Miletin',
        comuna: 'Prajeni'
      },
      {
        nume: 'Cişmea',
        simplu: 'Cismea',
        comuna: 'Rachiti'
      },
      {
        nume: 'Costeşti',
        simplu: 'Costesti',
        comuna: 'Rachiti'
      },
      {
        nume: 'Roşiori',
        simplu: 'Rosiori',
        comuna: 'Rachiti'
      },
      {
        nume: 'Miorcani',
        comuna: 'Radauti-Prut'
      },
      {
        nume: 'Rediu',
        comuna: 'Radauti-Prut'
      },
      {
        nume: 'Doina',
        comuna: 'Rauseni'
      },
      {
        nume: 'Pogorăşti',
        simplu: 'Pogorasti',
        comuna: 'Rauseni'
      },
      {
        nume: 'Rediu',
        comuna: 'Rauseni'
      },
      {
        nume: 'Stolniceni',
        comuna: 'Rauseni'
      },
      {
        nume: 'Cinghiniia',
        comuna: 'Ripiceni'
      },
      {
        nume: 'Lehneşti',
        simplu: 'Lehnesti',
        comuna: 'Ripiceni'
      },
      {
        nume: 'Movila Ruptă',
        simplu: 'Movila Rupta',
        comuna: 'Ripiceni'
      },
      {
        nume: 'Popoaia',
        comuna: 'Ripiceni'
      },
      {
        nume: 'Ripicenii Vechi',
        comuna: 'Ripiceni'
      },
      {
        nume: 'Râşca',
        simplu: 'Rasca',
        comuna: 'Ripiceni'
      },
      {
        nume: 'Cotârgaci',
        simplu: 'Cotargaci',
        comuna: 'Roma'
      },
      {
        nume: 'Dămideni',
        simplu: 'Damideni',
        comuna: 'Romanesti'
      },
      {
        nume: 'Româneşti-Vale',
        simplu: 'Romanesti-Vale',
        comuna: 'Romanesti'
      },
      {
        nume: 'Sărata',
        simplu: 'Sarata',
        comuna: 'Romanesti'
      },
      {
        nume: 'Berza',
        comuna: 'Santa Mare'
      },
      {
        nume: 'Bogdăneşti',
        simplu: 'Bogdanesti',
        comuna: 'Santa Mare'
      },
      {
        nume: 'Bădărăi',
        simplu: 'Badarai',
        comuna: 'Santa Mare'
      },
      {
        nume: 'Durneşti',
        simplu: 'Durnesti',
        comuna: 'Santa Mare'
      },
      {
        nume: 'Ilişeni',
        simplu: 'Iliseni',
        comuna: 'Santa Mare'
      },
      {
        nume: 'Rânghileşti',
        simplu: 'Ranghilesti',
        comuna: 'Santa Mare'
      },
      {
        nume: 'Rânghileşti-Deal',
        simplu: 'Ranghilesti-Deal',
        comuna: 'Santa Mare'
      },
      {
        nume: 'Bodeasa',
        comuna: 'Saveni'
      },
      {
        nume: 'Bozieni',
        comuna: 'Saveni'
      },
      {
        nume: 'Chişcăreni',
        simplu: 'Chiscareni',
        comuna: 'Saveni'
      },
      {
        nume: 'Petricani',
        comuna: 'Saveni'
      },
      {
        nume: 'Sat Nou',
        comuna: 'Saveni'
      },
      {
        nume: 'Horlăceni',
        simplu: 'Horlaceni',
        comuna: 'Sendriceni'
      },
      {
        nume: 'Pădureni',
        simplu: 'Padureni',
        comuna: 'Sendriceni'
      },
      {
        nume: 'Siliştea',
        simplu: 'Silistea',
        comuna: 'Stauceni'
      },
      {
        nume: 'Tocileni',
        comuna: 'Stauceni'
      },
      {
        nume: 'Victoria',
        comuna: 'Stauceni'
      },
      {
        nume: 'Bobuleşti',
        simplu: 'Bobulesti',
        comuna: 'Stefanesti'
      },
      {
        nume: 'Bădiuţi',
        simplu: 'Badiuti',
        comuna: 'Stefanesti'
      },
      {
        nume: 'Stânca',
        simplu: 'Stanca',
        comuna: 'Stefanesti'
      },
      {
        nume: 'Stefăneşti-Sat',
        simplu: 'Stefanesti-Sat',
        comuna: 'Stefanesti'
      },
      {
        nume: 'Ibăneasa',
        simplu: 'Ibaneasa',
        comuna: 'Stiubieni'
      },
      {
        nume: 'Negreni',
        comuna: 'Stiubieni'
      },
      {
        nume: 'Izvoare',
        comuna: 'Suharau'
      },
      {
        nume: 'Lişna',
        simplu: 'Lisna',
        comuna: 'Suharau'
      },
      {
        nume: 'Oroftiana',
        comuna: 'Suharau'
      },
      {
        nume: 'Plevna',
        comuna: 'Suharau'
      },
      {
        nume: 'Smârdan',
        simplu: 'Smardan',
        comuna: 'Suharau'
      },
      {
        nume: 'Cheliş',
        simplu: 'Chelis',
        comuna: 'Sulita'
      },
      {
        nume: 'Dracşani',
        simplu: 'Dracsani',
        comuna: 'Sulita'
      },
      {
        nume: 'Cerneşti',
        simplu: 'Cernesti',
        comuna: 'Todireni'
      },
      {
        nume: 'Floreşti',
        simplu: 'Floresti',
        comuna: 'Todireni'
      },
      {
        nume: 'Gârbeşti',
        simplu: 'Garbesti',
        comuna: 'Todireni'
      },
      {
        nume: 'Iureşti',
        simplu: 'Iuresti',
        comuna: 'Todireni'
      },
      {
        nume: 'Buhăceni',
        simplu: 'Buhaceni',
        comuna: 'Trusesti'
      },
      {
        nume: 'Ciritei',
        comuna: 'Trusesti'
      },
      {
        nume: 'Drislea',
        comuna: 'Trusesti'
      },
      {
        nume: 'Ionăşeni',
        simplu: 'Ionaseni',
        comuna: 'Trusesti'
      },
      {
        nume: 'Păsăteni',
        simplu: 'Pasateni',
        comuna: 'Trusesti'
      },
      {
        nume: 'Borzeşti',
        simplu: 'Borzesti',
        comuna: 'Ungureni'
      },
      {
        nume: 'Călugăreni',
        simplu: 'Calugareni',
        comuna: 'Ungureni'
      },
      {
        nume: 'Călugărenii Noi',
        simplu: 'Calugarenii Noi',
        comuna: 'Ungureni'
      },
      {
        nume: 'Durneşti',
        simplu: 'Durnesti',
        comuna: 'Ungureni'
      },
      {
        nume: 'Mihai Viteazu',
        comuna: 'Ungureni'
      },
      {
        nume: 'Mândreşti',
        simplu: 'Mandresti',
        comuna: 'Ungureni'
      },
      {
        nume: 'Plopenii Mari',
        comuna: 'Ungureni'
      },
      {
        nume: 'Plopenii Mici',
        comuna: 'Ungureni'
      },
      {
        nume: 'Tăuteşti',
        simplu: 'Tautesti',
        comuna: 'Ungureni'
      },
      {
        nume: 'Vicoleni',
        comuna: 'Ungureni'
      },
      {
        nume: 'Burla',
        comuna: 'Unteni'
      },
      {
        nume: 'Burleşti',
        simplu: 'Burlesti',
        comuna: 'Unteni'
      },
      {
        nume: 'Mânăstireni',
        simplu: 'Manastireni',
        comuna: 'Unteni'
      },
      {
        nume: 'Soroceni',
        comuna: 'Unteni'
      },
      {
        nume: 'Valea Grajdului',
        comuna: 'Unteni'
      },
      {
        nume: 'Vultureni',
        comuna: 'Unteni'
      },
      {
        nume: 'Gorovei',
        comuna: 'Vaculesti'
      },
      {
        nume: 'Sauceniţa',
        simplu: 'Saucenita',
        comuna: 'Vaculesti'
      },
      {
        nume: 'Ionăşeni',
        simplu: 'Ionaseni',
        comuna: 'Varfu Campului'
      },
      {
        nume: 'Lunca',
        comuna: 'Varfu Campului'
      },
      {
        nume: 'Cuza Vodă',
        simplu: 'Cuza Voda',
        comuna: 'Viisoara'
      },
      {
        nume: 'Viişoara Mică',
        simplu: 'Viisoara Mica',
        comuna: 'Viisoara'
      },
      {
        nume: 'Brehuieşti',
        simplu: 'Brehuiesti',
        comuna: 'Vladeni'
      },
      {
        nume: 'Hrişcani',
        simplu: 'Hriscani',
        comuna: 'Vladeni'
      },
      {
        nume: 'Huţani',
        simplu: 'Hutani',
        comuna: 'Vladeni'
      },
      {
        nume: 'Mândreşti',
        simplu: 'Mandresti',
        comuna: 'Vladeni'
      },
      {
        nume: 'Miron Costin',
        comuna: 'Vlasinesti'
      },
      {
        nume: 'Sârbi',
        simplu: 'Sarbi',
        comuna: 'Vlasinesti'
      },
      {
        nume: 'Davidoaia',
        comuna: 'Vorniceni'
      },
      {
        nume: 'Dealu Crucii',
        comuna: 'Vorniceni'
      },
      {
        nume: 'Icuşeni',
        simplu: 'Icuseni',
        comuna: 'Vorona'
      },
      {
        nume: 'Joldeşti',
        simplu: 'Joldesti',
        comuna: 'Vorona'
      },
      {
        nume: 'Poiana',
        comuna: 'Vorona'
      },
      {
        nume: 'Vorona Mare',
        comuna: 'Vorona'
      },
      {
        nume: 'Vorona-Teodoru',
        comuna: 'Vorona'
      },
      {
        nume: 'Dobrinăuţi-Hapăi',
        simplu: 'Dobrinauti-Hapai',
        comuna: 'Vârfu Câmpului'
      },
      {
        nume: 'Maghera',
        comuna: 'Vârfu Câmpului'
      },
      {
        nume: 'Pustoaia',
        comuna: 'Vârfu Câmpului'
      }
    ]
  },
  {
    auto: 'BR',
    nume: 'Brăila',
    localitati: [
      {
        nume: 'Berteştii de Jos',
        simplu: 'Bertestii de Jos'
      },
      {
        nume: 'Bordei Verde'
      },
      {
        nume: 'Brăila',
        simplu: 'Braila'
      },
      {
        nume: 'Bărăganul',
        simplu: 'Baraganul'
      },
      {
        nume: 'Cazasu'
      },
      {
        nume: 'Chişcani',
        simplu: 'Chiscani'
      },
      {
        nume: 'Ciocile'
      },
      {
        nume: 'Cireşu',
        simplu: 'Ciresu'
      },
      {
        nume: 'Dudeşti',
        simplu: 'Dudesti'
      },
      {
        nume: 'Frecăţei',
        simplu: 'Frecatei'
      },
      {
        nume: 'Făurei',
        simplu: 'Faurei'
      },
      {
        nume: 'Galbenu'
      },
      {
        nume: 'Gemenele'
      },
      {
        nume: 'Gradiştea',
        simplu: 'Gradistea'
      },
      {
        nume: 'Gropeni'
      },
      {
        nume: 'Ianca'
      },
      {
        nume: 'Jirlău',
        simplu: 'Jirlau'
      },
      {
        nume: 'Mircea Vodă',
        simplu: 'Mircea Voda'
      },
      {
        nume: 'Movila Miresii'
      },
      {
        nume: 'Măraşu',
        simplu: 'Marasu'
      },
      {
        nume: 'Măxineni',
        simplu: 'Maxineni'
      },
      {
        nume: 'Racoviţă',
        simplu: 'Racovita'
      },
      {
        nume: 'Romanu'
      },
      {
        nume: 'Roşiori',
        simplu: 'Rosiori'
      },
      {
        nume: 'Râmnicelu',
        simplu: 'Ramnicelu'
      },
      {
        nume: 'Salcia Tudor'
      },
      {
        nume: 'Scorţaru Nou',
        simplu: 'Scortaru Nou'
      },
      {
        nume: 'Siliştea',
        simplu: 'Silistea'
      },
      {
        nume: 'Stăncuţa',
        simplu: 'Stancuta'
      },
      {
        nume: 'Surdila-Greci'
      },
      {
        nume: 'Surdila-Găiseanca',
        simplu: 'Surdila-Gaiseanca'
      },
      {
        nume: 'Tichileşti',
        simplu: 'Tichilesti'
      },
      {
        nume: 'Traian'
      },
      {
        nume: 'Tudor Vladimirescu'
      },
      {
        nume: 'Tufeşti',
        simplu: 'Tufesti'
      },
      {
        nume: 'Ulmu'
      },
      {
        nume: 'Unirea'
      },
      {
        nume: 'Victoria'
      },
      {
        nume: 'Viziru'
      },
      {
        nume: 'Vişani',
        simplu: 'Visani'
      },
      {
        nume: 'Vădeni',
        simplu: 'Vadeni'
      },
      {
        nume: 'Zăvoaia',
        simplu: 'Zavoaia'
      },
      {
        nume: 'Însurăţei',
        simplu: 'Insuratei'
      },
      {
        nume: 'Suţeşti',
        simplu: 'Sutesti'
      },
      {
        nume: 'Berteştii de Sus',
        simplu: 'Bertestii de Sus',
        comuna: 'Bertestii De Jos'
      },
      {
        nume: 'Gura Călmăţui',
        simplu: 'Gura Calmatui',
        comuna: 'Bertestii De Jos'
      },
      {
        nume: 'Gura Gârluţei',
        simplu: 'Gura Garlutei',
        comuna: 'Bertestii De Jos'
      },
      {
        nume: 'Nicoleşti',
        simplu: 'Nicolesti',
        comuna: 'Bertestii De Jos'
      },
      {
        nume: 'Spiru Haret',
        comuna: 'Bertestii De Jos'
      },
      {
        nume: 'Constantin Gabrielescu',
        comuna: 'Bordei Verde'
      },
      {
        nume: 'Filiu',
        comuna: 'Bordei Verde'
      },
      {
        nume: 'Lişcoteanca',
        simplu: 'Liscoteanca',
        comuna: 'Bordei Verde'
      },
      {
        nume: 'Lacu Sărat',
        simplu: 'Lacu Sarat',
        comuna: 'Chiscani'
      },
      {
        nume: 'Vărsătura',
        simplu: 'Varsatura',
        comuna: 'Chiscani'
      },
      {
        nume: 'Chichineţu',
        simplu: 'Chichinetu',
        comuna: 'Ciocile'
      },
      {
        nume: 'Chioibaşeşti',
        simplu: 'Chioibasesti',
        comuna: 'Ciocile'
      },
      {
        nume: 'Odăieni',
        simplu: 'Odaieni',
        comuna: 'Ciocile'
      },
      {
        nume: 'Batogu',
        comuna: 'Ciresu'
      },
      {
        nume: 'Ioneşti',
        simplu: 'Ionesti',
        comuna: 'Ciresu'
      },
      {
        nume: 'Scărlăteşti',
        simplu: 'Scarlatesti',
        comuna: 'Ciresu'
      },
      {
        nume: 'Vultureni',
        comuna: 'Ciresu'
      },
      {
        nume: 'Bumbăcari',
        simplu: 'Bumbacari',
        comuna: 'Dudesti'
      },
      {
        nume: 'Tătaru',
        simplu: 'Tataru',
        comuna: 'Dudesti'
      },
      {
        nume: 'Agaua',
        comuna: 'Frecatei'
      },
      {
        nume: 'Cistia',
        comuna: 'Frecatei'
      },
      {
        nume: 'Salcia',
        comuna: 'Frecatei'
      },
      {
        nume: 'Stoieneşti',
        simplu: 'Stoienesti',
        comuna: 'Frecatei'
      },
      {
        nume: 'Titcov',
        comuna: 'Frecatei'
      },
      {
        nume: 'Drogu',
        comuna: 'Galbenu'
      },
      {
        nume: 'Pântecani',
        simplu: 'Pantecani',
        comuna: 'Galbenu'
      },
      {
        nume: 'Sătuc',
        simplu: 'Satuc',
        comuna: 'Galbenu'
      },
      {
        nume: 'Zamfireşti',
        simplu: 'Zamfiresti',
        comuna: 'Galbenu'
      },
      {
        nume: 'Găvani',
        simplu: 'Gavani',
        comuna: 'Gemenele'
      },
      {
        nume: 'Ibrianu',
        comuna: 'Gradistea'
      },
      {
        nume: 'Maraloiu',
        comuna: 'Gradistea'
      },
      {
        nume: 'Berleşti',
        simplu: 'Berlesti',
        comuna: 'Ianca'
      },
      {
        nume: 'Gara Ianca',
        comuna: 'Ianca'
      },
      {
        nume: 'Oprişeneşti',
        simplu: 'Oprisenesti',
        comuna: 'Ianca'
      },
      {
        nume: 'Perişoru',
        simplu: 'Perisoru',
        comuna: 'Ianca'
      },
      {
        nume: 'Plopu',
        comuna: 'Ianca'
      },
      {
        nume: 'Târlele Filiu',
        simplu: 'Tarlele Filiu',
        comuna: 'Ianca'
      },
      {
        nume: 'Lacu Rezii',
        comuna: 'Insuratei'
      },
      {
        nume: 'Măru Roşu',
        simplu: 'Maru Rosu',
        comuna: 'Insuratei'
      },
      {
        nume: 'Valea Călmăţuiului',
        simplu: 'Valea Calmatuiului',
        comuna: 'Insuratei'
      },
      {
        nume: 'Brădeanca',
        simplu: 'Bradeanca',
        comuna: 'Jirlau'
      },
      {
        nume: 'Băndoiu',
        simplu: 'Bandoiu',
        comuna: 'Marasu'
      },
      {
        nume: 'Măgureni',
        simplu: 'Magureni',
        comuna: 'Marasu'
      },
      {
        nume: 'Nedeicu',
        comuna: 'Marasu'
      },
      {
        nume: 'Plopi',
        comuna: 'Marasu'
      },
      {
        nume: 'Tăcău',
        simplu: 'Tacau',
        comuna: 'Marasu'
      },
      {
        nume: 'Corbu Nou',
        comuna: 'Maxineni'
      },
      {
        nume: 'Corbu Vechi',
        comuna: 'Maxineni'
      },
      {
        nume: 'Latinu',
        comuna: 'Maxineni'
      },
      {
        nume: 'Voineşti',
        simplu: 'Voinesti',
        comuna: 'Maxineni'
      },
      {
        nume: 'Deduleşti',
        simplu: 'Dedulesti',
        comuna: 'Mircea Voda'
      },
      {
        nume: 'Esna',
        comuna: 'Movila Miresii'
      },
      {
        nume: 'Tepeş Vodă',
        simplu: 'Tepes Voda',
        comuna: 'Movila Miresii'
      },
      {
        nume: 'Corbeni',
        comuna: 'Racovita'
      },
      {
        nume: 'Custura',
        comuna: 'Racovita'
      },
      {
        nume: 'Boarca',
        comuna: 'Ramnicelu'
      },
      {
        nume: 'Constantineşti',
        simplu: 'Constantinesti',
        comuna: 'Ramnicelu'
      },
      {
        nume: 'Mihail Kogălniceanu',
        simplu: 'Mihail Kogalniceanu',
        comuna: 'Ramnicelu'
      },
      {
        nume: 'Oancea',
        comuna: 'Romanu'
      },
      {
        nume: 'Colţea',
        simplu: 'Coltea',
        comuna: 'Rosiori'
      },
      {
        nume: 'Florica',
        comuna: 'Rosiori'
      },
      {
        nume: 'Pribeagu',
        comuna: 'Rosiori'
      },
      {
        nume: 'Ariciu',
        comuna: 'Salcia Tudor'
      },
      {
        nume: 'Cuza Vodă',
        simplu: 'Cuza Voda',
        comuna: 'Salcia Tudor'
      },
      {
        nume: 'Gulianca',
        comuna: 'Salcia Tudor'
      },
      {
        nume: 'Olăneasca',
        simplu: 'Olaneasca',
        comuna: 'Salcia Tudor'
      },
      {
        nume: 'Deşiraţi',
        simplu: 'Desirati',
        comuna: 'Scortaru Nou'
      },
      {
        nume: 'Gurguieţi',
        simplu: 'Gurguieti',
        comuna: 'Scortaru Nou'
      },
      {
        nume: 'Nicolae Bălcescu',
        simplu: 'Nicolae Balcescu',
        comuna: 'Scortaru Nou'
      },
      {
        nume: 'Pitulaţi',
        simplu: 'Pitulati',
        comuna: 'Scortaru Nou'
      },
      {
        nume: 'Sihleanu',
        comuna: 'Scortaru Nou'
      },
      {
        nume: 'Cotu Lung',
        comuna: 'Silistea'
      },
      {
        nume: 'Cotu Mihalea',
        comuna: 'Silistea'
      },
      {
        nume: 'Muchea',
        comuna: 'Silistea'
      },
      {
        nume: 'Mărtăceşti',
        simplu: 'Martacesti',
        comuna: 'Silistea'
      },
      {
        nume: 'Vameşu',
        simplu: 'Vamesu',
        comuna: 'Silistea'
      },
      {
        nume: 'Cuza Vodă',
        simplu: 'Cuza Voda',
        comuna: 'Stancuta'
      },
      {
        nume: 'Polizeşti',
        simplu: 'Polizesti',
        comuna: 'Stancuta'
      },
      {
        nume: 'Stanca',
        comuna: 'Stancuta'
      },
      {
        nume: 'Filipeşti',
        simplu: 'Filipesti',
        comuna: 'Surdila-Gaiseanca'
      },
      {
        nume: 'Brateşu Vechi',
        simplu: 'Bratesu Vechi',
        comuna: 'Surdila-Greci'
      },
      {
        nume: 'Făurei Sat',
        simplu: 'Faurei Sat',
        comuna: 'Surdila-Greci'
      },
      {
        nume: 'Horia',
        comuna: 'Surdila-Greci'
      },
      {
        nume: 'Mihail Kogălniceanu',
        simplu: 'Mihail Kogalniceanu',
        comuna: 'Sutesti'
      },
      {
        nume: 'Albina',
        comuna: 'Tichilesti'
      },
      {
        nume: 'Căldăruşa',
        simplu: 'Caldarusa',
        comuna: 'Traian'
      },
      {
        nume: 'Silistraru',
        comuna: 'Traian'
      },
      {
        nume: 'Urleasca',
        comuna: 'Traian'
      },
      {
        nume: 'Comăneasca',
        simplu: 'Comaneasca',
        comuna: 'Tudor Vladimirescu'
      },
      {
        nume: 'Scorţaru Vechi',
        simplu: 'Scortaru Vechi',
        comuna: 'Tudor Vladimirescu'
      },
      {
        nume: 'Jugureanu',
        comuna: 'Ulmu'
      },
      {
        nume: 'Moroteşti',
        simplu: 'Morotesti',
        comuna: 'Unirea'
      },
      {
        nume: 'Valea Cânepii',
        simplu: 'Valea Canepii',
        comuna: 'Unirea'
      },
      {
        nume: 'Baldovineşti',
        simplu: 'Baldovinesti',
        comuna: 'Vadeni'
      },
      {
        nume: 'Pietroiu',
        comuna: 'Vadeni'
      },
      {
        nume: 'Mihai Bravu',
        comuna: 'Victoria'
      },
      {
        nume: 'Câineni-Băi',
        simplu: 'Caineni-Bai',
        comuna: 'Visani'
      },
      {
        nume: 'Plăsoiu',
        simplu: 'Plasoiu',
        comuna: 'Visani'
      },
      {
        nume: 'Lanurile',
        comuna: 'Viziru'
      },
      {
        nume: 'Dudescu',
        comuna: 'Zavoaia'
      }
    ]
  },
  {
    auto: 'BV',
    nume: 'Braşov',
    localitati: [
      {
        nume: 'Apaţa',
        simplu: 'Apata'
      },
      {
        nume: 'Beclean'
      },
      {
        nume: 'Bod'
      },
      {
        nume: 'Bran'
      },
      {
        nume: 'Braşov',
        simplu: 'Brasov'
      },
      {
        nume: 'Budila'
      },
      {
        nume: 'Buneşti',
        simplu: 'Bunesti'
      },
      {
        nume: 'Caţa',
        simplu: 'Cata'
      },
      {
        nume: 'Cincu'
      },
      {
        nume: 'Codlea'
      },
      {
        nume: 'Cristian'
      },
      {
        nume: 'Crizbav'
      },
      {
        nume: 'Dumbrăviţa',
        simplu: 'Dumbravita'
      },
      {
        nume: 'Feldioara'
      },
      {
        nume: 'Fundata'
      },
      {
        nume: 'Făgăraş',
        simplu: 'Fagaras'
      },
      {
        nume: 'Ghimbav'
      },
      {
        nume: 'Hoghiz'
      },
      {
        nume: 'Homorod'
      },
      {
        nume: 'Hârseni',
        simplu: 'Harseni'
      },
      {
        nume: 'Hălchiu',
        simplu: 'Halchiu'
      },
      {
        nume: 'Hărman',
        simplu: 'Harman'
      },
      {
        nume: 'Jibert'
      },
      {
        nume: 'Lisa'
      },
      {
        nume: 'Mândra',
        simplu: 'Mandra'
      },
      {
        nume: 'Măieruş',
        simplu: 'Maierus'
      },
      {
        nume: 'Ormeniş',
        simplu: 'Ormenis'
      },
      {
        nume: 'Poiana Mărului',
        simplu: 'Poiana Marului'
      },
      {
        nume: 'Predeal'
      },
      {
        nume: 'Prejmer'
      },
      {
        nume: 'Părău',
        simplu: 'Parau'
      },
      {
        nume: 'Racoş',
        simplu: 'Racos'
      },
      {
        nume: 'Recea'
      },
      {
        nume: 'Rupea'
      },
      {
        nume: 'Râşnov',
        simplu: 'Rasnov'
      },
      {
        nume: 'Sâmbăta de Sus',
        simplu: 'Sambata de Sus'
      },
      {
        nume: 'Sânpetru',
        simplu: 'Sanpetru'
      },
      {
        nume: 'Săcele',
        simplu: 'Sacele'
      },
      {
        nume: 'Teliu'
      },
      {
        nume: 'Tărlungeni',
        simplu: 'Tarlungeni'
      },
      {
        nume: 'Ungra'
      },
      {
        nume: 'Vama Buzăului',
        simplu: 'Vama Buzaului'
      },
      {
        nume: 'Victoria'
      },
      {
        nume: 'Voila'
      },
      {
        nume: 'Vulcan'
      },
      {
        nume: 'Zărneşti',
        simplu: 'Zarnesti'
      },
      {
        nume: 'Sercaia',
        simplu: 'Sercaia'
      },
      {
        nume: 'Sinca Nouă',
        simplu: 'Sinca Noua'
      },
      {
        nume: 'Soarş',
        simplu: 'Soars'
      },
      {
        nume: 'Boholţ',
        simplu: 'Boholt',
        comuna: 'Beclean'
      },
      {
        nume: 'Calbor',
        comuna: 'Beclean'
      },
      {
        nume: 'Hurez',
        comuna: 'Beclean'
      },
      {
        nume: 'Luţa',
        simplu: 'Luta',
        comuna: 'Beclean'
      },
      {
        nume: 'Colonia Bod',
        comuna: 'Bod'
      },
      {
        nume: 'Predeluţ',
        simplu: 'Predelut',
        comuna: 'Bran'
      },
      {
        nume: 'Sohodol',
        comuna: 'Bran'
      },
      {
        nume: 'Simon',
        simplu: 'Simon',
        comuna: 'Bran'
      },
      {
        nume: 'Poiana Braşov',
        simplu: 'Poiana Brasov',
        comuna: 'Brasov'
      },
      {
        nume: 'Criţ',
        simplu: 'Crit',
        comuna: 'Bunesti'
      },
      {
        nume: 'Meşendorf',
        simplu: 'Mesendorf',
        comuna: 'Bunesti'
      },
      {
        nume: 'Roadeş',
        simplu: 'Roades',
        comuna: 'Bunesti'
      },
      {
        nume: 'Viscri',
        comuna: 'Bunesti'
      },
      {
        nume: 'Beia',
        comuna: 'Cata'
      },
      {
        nume: 'Drăuşeni',
        simplu: 'Drauseni',
        comuna: 'Cata'
      },
      {
        nume: 'Ioneşti',
        simplu: 'Ionesti',
        comuna: 'Cata'
      },
      {
        nume: 'Paloş',
        simplu: 'Palos',
        comuna: 'Cata'
      },
      {
        nume: 'Toarcla',
        comuna: 'Cincu'
      },
      {
        nume: 'Comana de Jos',
        comuna: 'Comana'
      },
      {
        nume: 'Comana de Sus',
        comuna: 'Comana'
      },
      {
        nume: 'Crihalma',
        comuna: 'Comana'
      },
      {
        nume: 'Ticuşu Nou',
        simplu: 'Ticusu Nou',
        comuna: 'Comana'
      },
      {
        nume: 'Cutuş',
        simplu: 'Cutus',
        comuna: 'Crizbav'
      },
      {
        nume: 'Vlădeni',
        simplu: 'Vladeni',
        comuna: 'Dumbravita'
      },
      {
        nume: 'Colonia Reconstrucţia',
        simplu: 'Colonia Reconstructia',
        comuna: 'Feldioara'
      },
      {
        nume: 'Rotbav',
        comuna: 'Feldioara'
      },
      {
        nume: 'Fundăţica',
        simplu: 'Fundatica',
        comuna: 'Fundata'
      },
      {
        nume: 'Sirnea',
        simplu: 'Sirnea',
        comuna: 'Fundata'
      },
      {
        nume: 'Satu Nou',
        comuna: 'Halchiu'
      },
      {
        nume: 'Podu Oltului',
        comuna: 'Harman'
      },
      {
        nume: 'Copăcel',
        simplu: 'Copacel',
        comuna: 'Harseni'
      },
      {
        nume: 'Măliniş',
        simplu: 'Malinis',
        comuna: 'Harseni'
      },
      {
        nume: 'Mărgineni',
        simplu: 'Margineni',
        comuna: 'Harseni'
      },
      {
        nume: 'Sebeş',
        simplu: 'Sebes',
        comuna: 'Harseni'
      },
      {
        nume: 'Bogata Olteană',
        simplu: 'Bogata Olteana',
        comuna: 'Hoghiz'
      },
      {
        nume: 'Cuciulata',
        comuna: 'Hoghiz'
      },
      {
        nume: 'Dopca',
        comuna: 'Hoghiz'
      },
      {
        nume: 'Fântâna',
        simplu: 'Fantana',
        comuna: 'Hoghiz'
      },
      {
        nume: 'Lupşa',
        simplu: 'Lupsa',
        comuna: 'Hoghiz'
      },
      {
        nume: 'Jimbor',
        comuna: 'Homorod'
      },
      {
        nume: 'Mercheaşa',
        simplu: 'Mercheasa',
        comuna: 'Homorod'
      },
      {
        nume: 'Dacia',
        comuna: 'Jibert'
      },
      {
        nume: 'Grânari',
        simplu: 'Granari',
        comuna: 'Jibert'
      },
      {
        nume: 'Lovnic',
        comuna: 'Jibert'
      },
      {
        nume: 'Văleni',
        simplu: 'Valeni',
        comuna: 'Jibert'
      },
      {
        nume: 'Breaza',
        comuna: 'Lisa'
      },
      {
        nume: 'Pojorta',
        comuna: 'Lisa'
      },
      {
        nume: 'Arini',
        comuna: 'Maierus'
      },
      {
        nume: 'Ileni',
        comuna: 'Mandra'
      },
      {
        nume: 'Râuşor',
        simplu: 'Rausor',
        comuna: 'Mandra'
      },
      {
        nume: 'Toderiţa',
        simplu: 'Toderita',
        comuna: 'Mandra'
      },
      {
        nume: 'Sona',
        simplu: 'Sona',
        comuna: 'Mandra'
      },
      {
        nume: 'Cheia',
        comuna: 'Moieciu'
      },
      {
        nume: 'Drumul Carului',
        comuna: 'Moieciu'
      },
      {
        nume: 'Moieciu de Jos',
        comuna: 'Moieciu'
      },
      {
        nume: 'Moieciu de Sus',
        comuna: 'Moieciu'
      },
      {
        nume: 'Măgura',
        simplu: 'Magura',
        comuna: 'Moieciu'
      },
      {
        nume: 'Peştera',
        simplu: 'Pestera',
        comuna: 'Moieciu'
      },
      {
        nume: 'Augustin',
        comuna: 'Ormenis'
      },
      {
        nume: 'Grid',
        comuna: 'Parau'
      },
      {
        nume: 'Veneţia de Jos',
        simplu: 'Venetia de Jos',
        comuna: 'Parau'
      },
      {
        nume: 'Veneţia de Sus',
        simplu: 'Venetia de Sus',
        comuna: 'Parau'
      },
      {
        nume: 'Pârâul Rece',
        simplu: 'Paraul Rece',
        comuna: 'Predeal'
      },
      {
        nume: 'Timişu de Jos',
        simplu: 'Timisu de Jos',
        comuna: 'Predeal'
      },
      {
        nume: 'Timişu de Sus',
        simplu: 'Timisu de Sus',
        comuna: 'Predeal'
      },
      {
        nume: 'Lunca Calnicului',
        comuna: 'Prejmer'
      },
      {
        nume: 'Stupinii Prejmerului',
        comuna: 'Prejmer'
      },
      {
        nume: 'Mateiaş',
        simplu: 'Mateias',
        comuna: 'Racos'
      },
      {
        nume: 'Berivoi',
        comuna: 'Recea'
      },
      {
        nume: 'Dejani',
        comuna: 'Recea'
      },
      {
        nume: 'Gura Văii',
        simplu: 'Gura Vaii',
        comuna: 'Recea'
      },
      {
        nume: 'Iaşi',
        simplu: 'Iasi',
        comuna: 'Recea'
      },
      {
        nume: 'Săsciori',
        simplu: 'Sasciori',
        comuna: 'Recea'
      },
      {
        nume: 'Săvăstreni',
        simplu: 'Savastreni',
        comuna: 'Recea'
      },
      {
        nume: 'Fişer',
        simplu: 'Fiser',
        comuna: 'Rupea'
      },
      {
        nume: 'Staţiunea Climaterică Sâmbăta',
        simplu: 'Statiunea Climaterica Sambata',
        comuna: 'Sambata De Sus'
      },
      {
        nume: 'Hălmeag',
        simplu: 'Halmeag',
        comuna: 'Sercaia'
      },
      {
        nume: 'Vad',
        comuna: 'Sercaia'
      },
      {
        nume: 'Bucium',
        comuna: 'Sinca'
      },
      {
        nume: 'Ohaba',
        comuna: 'Sinca'
      },
      {
        nume: 'Perşani',
        simplu: 'Persani',
        comuna: 'Sinca'
      },
      {
        nume: 'Vâlcea',
        simplu: 'Valcea',
        comuna: 'Sinca'
      },
      {
        nume: 'Sercăiţa',
        simplu: 'Sercaita',
        comuna: 'Sinca'
      },
      {
        nume: 'Sinca Veche',
        simplu: 'Sinca Veche',
        comuna: 'Sinca'
      },
      {
        nume: 'Paltin',
        comuna: 'Sinca Noua'
      },
      {
        nume: 'Bărcut',
        simplu: 'Barcut',
        comuna: 'Soars'
      },
      {
        nume: 'Felmer',
        comuna: 'Soars'
      },
      {
        nume: 'Rodbav',
        comuna: 'Soars'
      },
      {
        nume: 'Seliştat',
        simplu: 'Selistat',
        comuna: 'Soars'
      },
      {
        nume: 'Cărpiniş',
        simplu: 'Carpinis',
        comuna: 'Tarlungeni'
      },
      {
        nume: 'Purcăreni',
        simplu: 'Purcareni',
        comuna: 'Tarlungeni'
      },
      {
        nume: 'Zizin',
        comuna: 'Tarlungeni'
      },
      {
        nume: 'Cobor',
        comuna: 'Ticusu'
      },
      {
        nume: 'Ticuşu Vechi',
        simplu: 'Ticusu Vechi',
        comuna: 'Ticusu'
      },
      {
        nume: 'Corbi',
        comuna: 'Ucea'
      },
      {
        nume: 'Feldioara',
        comuna: 'Ucea'
      },
      {
        nume: 'Ucea de Jos',
        comuna: 'Ucea'
      },
      {
        nume: 'Ucea de Sus',
        comuna: 'Ucea'
      },
      {
        nume: 'Dăişoara',
        simplu: 'Daisoara',
        comuna: 'Ungra'
      },
      {
        nume: 'Acriş',
        simplu: 'Acris',
        comuna: 'Vama Buzaului'
      },
      {
        nume: 'Buzăiel',
        simplu: 'Buzaiel',
        comuna: 'Vama Buzaului'
      },
      {
        nume: 'Dălghiu',
        simplu: 'Dalghiu',
        comuna: 'Vama Buzaului'
      },
      {
        nume: 'Drăguş',
        simplu: 'Dragus',
        comuna: 'Vistea'
      },
      {
        nume: 'Olteţ',
        simplu: 'Oltet',
        comuna: 'Vistea'
      },
      {
        nume: 'Rucăr',
        simplu: 'Rucar',
        comuna: 'Vistea'
      },
      {
        nume: 'Viştea de Jos',
        simplu: 'Vistea de Jos',
        comuna: 'Vistea'
      },
      {
        nume: 'Viştea de Sus',
        simplu: 'Vistea de Sus',
        comuna: 'Vistea'
      },
      {
        nume: 'Viştişoara',
        simplu: 'Vistisoara',
        comuna: 'Vistea'
      },
      {
        nume: 'Cincşor',
        simplu: 'Cincsor',
        comuna: 'Voila'
      },
      {
        nume: 'Dridif',
        comuna: 'Voila'
      },
      {
        nume: 'Ludişor',
        simplu: 'Ludisor',
        comuna: 'Voila'
      },
      {
        nume: 'Sâmbăta de Jos',
        simplu: 'Sambata de Jos',
        comuna: 'Voila'
      },
      {
        nume: 'Voivodeni',
        comuna: 'Voila'
      },
      {
        nume: 'Colonia 1 Mai',
        comuna: 'Vulcan'
      },
      {
        nume: 'Holbav',
        comuna: 'Vulcan'
      },
      {
        nume: 'Tohanu Nou',
        comuna: 'Zarnesti'
      }
    ]
  },
  {
    auto: 'B',
    nume: 'Bucureşti',
    localitati: [
      {
        nume: 'Sector 1'
      },
      {
        nume: 'Sector 2'
      },
      {
        nume: 'Sector 3'
      },
      {
        nume: 'Sector 4'
      },
      {
        nume: 'Sector 5'
      },
      {
        nume: 'Sector 6'
      }
    ]
  },
  {
    auto: 'BZ',
    nume: 'Buzău',
    localitati: [
      {
        nume: 'Amaru'
      },
      {
        nume: 'Balta Albă',
        simplu: 'Balta Alba'
      },
      {
        nume: 'Beceni'
      },
      {
        nume: 'Berca'
      },
      {
        nume: 'Bisoca'
      },
      {
        nume: 'Blăjani',
        simplu: 'Blajani'
      },
      {
        nume: 'Boldu'
      },
      {
        nume: 'Bozioru'
      },
      {
        nume: 'Breaza'
      },
      {
        nume: 'Brădeanu',
        simplu: 'Bradeanu'
      },
      {
        nume: 'Brăeşti',
        simplu: 'Braesti'
      },
      {
        nume: 'Buda'
      },
      {
        nume: 'Buzău',
        simplu: 'Buzau'
      },
      {
        nume: 'Bălăceanu',
        simplu: 'Balaceanu'
      },
      {
        nume: 'C.a. Rosetti'
      },
      {
        nume: 'Calvini'
      },
      {
        nume: 'Cernăteşti',
        simplu: 'Cernatesti'
      },
      {
        nume: 'Chiliile'
      },
      {
        nume: 'Chiojdu'
      },
      {
        nume: 'Cilibia'
      },
      {
        nume: 'Cislău',
        simplu: 'Cislau'
      },
      {
        nume: 'Cochirleanca'
      },
      {
        nume: 'Colţi',
        simplu: 'Colti'
      },
      {
        nume: 'Costeşti',
        simplu: 'Costesti'
      },
      {
        nume: 'Cozieni'
      },
      {
        nume: 'Căneşti',
        simplu: 'Canesti'
      },
      {
        nume: 'Cătina',
        simplu: 'Catina'
      },
      {
        nume: 'Ghergheasa'
      },
      {
        nume: 'Gherăseni',
        simplu: 'Gheraseni'
      },
      {
        nume: 'Glodeanu Sărat',
        simplu: 'Glodeanu Sarat'
      },
      {
        nume: 'Glodeanu-Siliştea',
        simplu: 'Glodeanu-Silistea'
      },
      {
        nume: 'Grebănu',
        simplu: 'Grebanu'
      },
      {
        nume: 'Gura Teghii'
      },
      {
        nume: 'Gălbinaşi',
        simplu: 'Galbinasi'
      },
      {
        nume: 'Largu'
      },
      {
        nume: 'Lopătari',
        simplu: 'Lopatari'
      },
      {
        nume: 'Luciu'
      },
      {
        nume: 'Merei'
      },
      {
        nume: 'Mihăileşti',
        simplu: 'Mihailesti'
      },
      {
        nume: 'Movila Banului'
      },
      {
        nume: 'Murgeşti',
        simplu: 'Murgesti'
      },
      {
        nume: 'Mânzăleşti',
        simplu: 'Manzalesti'
      },
      {
        nume: 'Măgura',
        simplu: 'Magura'
      },
      {
        nume: 'Mărgăriteşti',
        simplu: 'Margaritesti'
      },
      {
        nume: 'Mărăcineni',
        simplu: 'Maracineni'
      },
      {
        nume: 'Nehoiu'
      },
      {
        nume: 'Năeni',
        simplu: 'Naeni'
      },
      {
        nume: 'Odăile',
        simplu: 'Odaile'
      },
      {
        nume: 'Padina'
      },
      {
        nume: 'Pardoşi',
        simplu: 'Pardosi'
      },
      {
        nume: 'Pietroasele'
      },
      {
        nume: 'Podgoria'
      },
      {
        nume: 'Pogoanele'
      },
      {
        nume: 'Poşta Câlnău',
        simplu: 'Posta Calnau'
      },
      {
        nume: 'Pârscov',
        simplu: 'Parscov'
      },
      {
        nume: 'Pănătău',
        simplu: 'Panatau'
      },
      {
        nume: 'Pătârlagele',
        simplu: 'Patarlagele'
      },
      {
        nume: 'Racoviţeni',
        simplu: 'Racoviteni'
      },
      {
        nume: 'Robeasca'
      },
      {
        nume: 'Ruşeţu',
        simplu: 'Rusetu'
      },
      {
        nume: 'Râmnicelu',
        simplu: 'Ramnicelu'
      },
      {
        nume: 'Râmnicu Sărat',
        simplu: 'Ramnicu Sarat'
      },
      {
        nume: 'Scorţoasa',
        simplu: 'Scortoasa'
      },
      {
        nume: 'Scutelnici'
      },
      {
        nume: 'Smeeni'
      },
      {
        nume: 'Stâlpu',
        simplu: 'Stalpu'
      },
      {
        nume: 'Săgeata',
        simplu: 'Sageata'
      },
      {
        nume: 'Săhăteni',
        simplu: 'Sahateni'
      },
      {
        nume: 'Săpoca',
        simplu: 'Sapoca'
      },
      {
        nume: 'Săruleşti',
        simplu: 'Sarulesti'
      },
      {
        nume: 'Tisău',
        simplu: 'Tisau'
      },
      {
        nume: 'Topliceni'
      },
      {
        nume: 'Ulmeni'
      },
      {
        nume: 'Vadu Paşii',
        simplu: 'Vadu Pasii'
      },
      {
        nume: 'Valea Râmnicului',
        simplu: 'Valea Ramnicului'
      },
      {
        nume: 'Valea Salciei'
      },
      {
        nume: 'Verneşti',
        simplu: 'Vernesti'
      },
      {
        nume: 'Vintilă Vodă',
        simplu: 'Vintila Voda'
      },
      {
        nume: 'Vipereşti',
        simplu: 'Viperesti'
      },
      {
        nume: 'Vâlcelele',
        simplu: 'Valcelele'
      },
      {
        nume: 'Ziduri'
      },
      {
        nume: 'Zărneşti',
        simplu: 'Zarnesti'
      },
      {
        nume: 'Tinteşti',
        simplu: 'Tintesti'
      },
      {
        nume: 'Câmpeni',
        simplu: 'Campeni',
        comuna: 'Amaru'
      },
      {
        nume: 'Dulbanu',
        comuna: 'Amaru'
      },
      {
        nume: 'Lacu Sinaia',
        comuna: 'Amaru'
      },
      {
        nume: 'Lunca',
        comuna: 'Amaru'
      },
      {
        nume: 'Scorţeanca',
        simplu: 'Scorteanca',
        comuna: 'Amaru'
      },
      {
        nume: 'Amara',
        comuna: 'Balta Alba'
      },
      {
        nume: 'Băile',
        simplu: 'Baile',
        comuna: 'Balta Alba'
      },
      {
        nume: 'Stăvărăşti',
        simplu: 'Stavarasti',
        comuna: 'Balta Alba'
      },
      {
        nume: 'Arbănaşi',
        simplu: 'Arbanasi',
        comuna: 'Beceni'
      },
      {
        nume: 'Cărpiniştea',
        simplu: 'Carpinistea',
        comuna: 'Beceni'
      },
      {
        nume: 'Dogari',
        comuna: 'Beceni'
      },
      {
        nume: 'Floreşti',
        simplu: 'Floresti',
        comuna: 'Beceni'
      },
      {
        nume: 'Gura Dimienii',
        comuna: 'Beceni'
      },
      {
        nume: 'Izvoru Dulce',
        comuna: 'Beceni'
      },
      {
        nume: 'Mărgăriţi',
        simplu: 'Margariti',
        comuna: 'Beceni'
      },
      {
        nume: 'Valea Părului',
        simplu: 'Valea Parului',
        comuna: 'Beceni'
      },
      {
        nume: 'Băceni',
        simplu: 'Baceni',
        comuna: 'Berca'
      },
      {
        nume: 'Cojanu',
        comuna: 'Berca'
      },
      {
        nume: 'Joseni',
        comuna: 'Berca'
      },
      {
        nume: 'Mânăstirea Răteşti',
        simplu: 'Manastirea Ratesti',
        comuna: 'Berca'
      },
      {
        nume: 'Pleşcoi',
        simplu: 'Plescoi',
        comuna: 'Berca'
      },
      {
        nume: 'Pleşeşti',
        simplu: 'Plesesti',
        comuna: 'Berca'
      },
      {
        nume: 'Pâclele',
        simplu: 'Paclele',
        comuna: 'Berca'
      },
      {
        nume: 'Răteşti',
        simplu: 'Ratesti',
        comuna: 'Berca'
      },
      {
        nume: 'Sătuc',
        simplu: 'Satuc',
        comuna: 'Berca'
      },
      {
        nume: 'Tâţârligu',
        simplu: 'Tatarligu',
        comuna: 'Berca'
      },
      {
        nume: 'Valea Nucului',
        comuna: 'Berca'
      },
      {
        nume: 'Viforâta',
        simplu: 'Viforata',
        comuna: 'Berca'
      },
      {
        nume: 'Băltăgari',
        simplu: 'Baltagari',
        comuna: 'Bisoca'
      },
      {
        nume: 'Lacurile',
        comuna: 'Bisoca'
      },
      {
        nume: 'Lopătăreasa',
        simplu: 'Lopatareasa',
        comuna: 'Bisoca'
      },
      {
        nume: 'Pleşi',
        simplu: 'Plesi',
        comuna: 'Bisoca'
      },
      {
        nume: 'Recea',
        comuna: 'Bisoca'
      },
      {
        nume: 'Sările',
        simplu: 'Sarile',
        comuna: 'Bisoca'
      },
      {
        nume: 'Sindrila',
        simplu: 'Sindrila',
        comuna: 'Bisoca'
      },
      {
        nume: 'Soreşti',
        simplu: 'Soresti',
        comuna: 'Blajani'
      },
      {
        nume: 'Buduile',
        comuna: 'Bozioru'
      },
      {
        nume: 'Fişici',
        simplu: 'Fisici',
        comuna: 'Bozioru'
      },
      {
        nume: 'Gresia',
        comuna: 'Bozioru'
      },
      {
        nume: 'Găvanele',
        simplu: 'Gavanele',
        comuna: 'Bozioru'
      },
      {
        nume: 'Izvoarele',
        comuna: 'Bozioru'
      },
      {
        nume: 'Nucu',
        comuna: 'Bozioru'
      },
      {
        nume: 'Scăeni',
        simplu: 'Scaeni',
        comuna: 'Bozioru'
      },
      {
        nume: 'Ulmet',
        comuna: 'Bozioru'
      },
      {
        nume: 'Văvălucile',
        simplu: 'Vavalucile',
        comuna: 'Bozioru'
      },
      {
        nume: 'Mitropolia',
        comuna: 'Bradeanu'
      },
      {
        nume: 'Smârdan',
        simplu: 'Smardan',
        comuna: 'Bradeanu'
      },
      {
        nume: 'Brătileşti',
        simplu: 'Bratilesti',
        comuna: 'Braesti'
      },
      {
        nume: 'Goideşti',
        simplu: 'Goidesti',
        comuna: 'Braesti'
      },
      {
        nume: 'Ivăneţu',
        simplu: 'Ivanetu',
        comuna: 'Braesti'
      },
      {
        nume: 'Pinu',
        comuna: 'Braesti'
      },
      {
        nume: 'Pârscovelu',
        simplu: 'Parscovelu',
        comuna: 'Braesti'
      },
      {
        nume: 'Ruginoasa',
        comuna: 'Braesti'
      },
      {
        nume: 'Bădeni',
        simplu: 'Badeni',
        comuna: 'Breaza'
      },
      {
        nume: 'Greceanca',
        comuna: 'Breaza'
      },
      {
        nume: 'Vispeşti',
        simplu: 'Vispesti',
        comuna: 'Breaza'
      },
      {
        nume: 'Văleanca-Vilăneşti',
        simplu: 'Valeanca-Vilanesti',
        comuna: 'Breaza'
      },
      {
        nume: 'Alexandru Odobescu',
        comuna: 'Buda'
      },
      {
        nume: 'Dănuleşti',
        simplu: 'Danulesti',
        comuna: 'Buda'
      },
      {
        nume: 'Muceşti-Dănuleşti',
        simplu: 'Mucesti-Danulesti',
        comuna: 'Buda'
      },
      {
        nume: 'Spidele',
        comuna: 'Buda'
      },
      {
        nume: 'Toropăleşti',
        simplu: 'Toropalesti',
        comuna: 'Buda'
      },
      {
        nume: 'Valea Largă',
        simplu: 'Valea Larga',
        comuna: 'Buda'
      },
      {
        nume: 'Bâlhacu',
        simplu: 'Balhacu',
        comuna: 'C.A. Rosetti'
      },
      {
        nume: 'Bălteni',
        simplu: 'Balteni',
        comuna: 'C.A. Rosetti'
      },
      {
        nume: 'Cotu Ciorii',
        comuna: 'C.A. Rosetti'
      },
      {
        nume: 'Lunca',
        comuna: 'C.A. Rosetti'
      },
      {
        nume: 'Vizireni',
        comuna: 'C.A. Rosetti'
      },
      {
        nume: 'Bâscenii de Jos',
        simplu: 'Bascenii de Jos',
        comuna: 'Calvini'
      },
      {
        nume: 'Bâscenii de Sus',
        simplu: 'Bascenii de Sus',
        comuna: 'Calvini'
      },
      {
        nume: 'Frăsinet',
        simplu: 'Frasinet',
        comuna: 'Calvini'
      },
      {
        nume: 'Olari',
        comuna: 'Calvini'
      },
      {
        nume: 'Gonţeşti',
        simplu: 'Gontesti',
        comuna: 'Canesti'
      },
      {
        nume: 'Negoşina',
        simplu: 'Negosina',
        comuna: 'Canesti'
      },
      {
        nume: 'Păcurile',
        simplu: 'Pacurile',
        comuna: 'Canesti'
      },
      {
        nume: 'Valea Verzei',
        comuna: 'Canesti'
      },
      {
        nume: 'Suchea',
        simplu: 'Suchea',
        comuna: 'Canesti'
      },
      {
        nume: 'Corbu',
        comuna: 'Catina'
      },
      {
        nume: 'Slobozia',
        comuna: 'Catina'
      },
      {
        nume: 'Valea Cătinei',
        simplu: 'Valea Catinei',
        comuna: 'Catina'
      },
      {
        nume: 'Zeletin',
        comuna: 'Catina'
      },
      {
        nume: 'Aldeni',
        comuna: 'Cernatesti'
      },
      {
        nume: 'Băeşti',
        simplu: 'Baesti',
        comuna: 'Cernatesti'
      },
      {
        nume: 'Căldăruşa',
        simplu: 'Caldarusa',
        comuna: 'Cernatesti'
      },
      {
        nume: 'Fulga',
        comuna: 'Cernatesti'
      },
      {
        nume: 'Manasia',
        comuna: 'Cernatesti'
      },
      {
        nume: 'Vlădeni',
        simplu: 'Vladeni',
        comuna: 'Cernatesti'
      },
      {
        nume: 'Zărneştii de Slănic',
        simplu: 'Zarnestii de Slanic',
        comuna: 'Cernatesti'
      },
      {
        nume: 'Budeşti',
        simplu: 'Budesti',
        comuna: 'Chiliile'
      },
      {
        nume: 'Creveleşti',
        simplu: 'Crevelesti',
        comuna: 'Chiliile'
      },
      {
        nume: 'Ghiocari',
        comuna: 'Chiliile'
      },
      {
        nume: 'Glodu-Petcari',
        comuna: 'Chiliile'
      },
      {
        nume: 'Poiana Pletari',
        comuna: 'Chiliile'
      },
      {
        nume: 'Trestioara',
        comuna: 'Chiliile'
      },
      {
        nume: 'Bâsca Chiojdului',
        simplu: 'Basca Chiojdului',
        comuna: 'Chiojdu'
      },
      {
        nume: 'Cătiaşu',
        simplu: 'Catiasu',
        comuna: 'Chiojdu'
      },
      {
        nume: 'Lera',
        comuna: 'Chiojdu'
      },
      {
        nume: 'Plescioara',
        comuna: 'Chiojdu'
      },
      {
        nume: 'Poeniţele',
        simplu: 'Poenitele',
        comuna: 'Chiojdu'
      },
      {
        nume: 'Gara Cilibia',
        comuna: 'Cilibia'
      },
      {
        nume: 'Movila Oii',
        comuna: 'Cilibia'
      },
      {
        nume: 'Mânzu',
        simplu: 'Manzu',
        comuna: 'Cilibia'
      },
      {
        nume: 'Poşta',
        simplu: 'Posta',
        comuna: 'Cilibia'
      },
      {
        nume: 'Buda Crăciuneşti',
        simplu: 'Buda Craciunesti',
        comuna: 'Cislau'
      },
      {
        nume: 'Bărăşti',
        simplu: 'Barasti',
        comuna: 'Cislau'
      },
      {
        nume: 'Gura Bâscei',
        simplu: 'Gura Bascei',
        comuna: 'Cislau'
      },
      {
        nume: 'Scărişoara',
        simplu: 'Scarisoara',
        comuna: 'Cislau'
      },
      {
        nume: 'Boboc',
        comuna: 'Cochirleanca'
      },
      {
        nume: 'Gara Bobocu',
        comuna: 'Cochirleanca'
      },
      {
        nume: 'Roşioru',
        simplu: 'Rosioru',
        comuna: 'Cochirleanca'
      },
      {
        nume: 'Târlele',
        simplu: 'Tarlele',
        comuna: 'Cochirleanca'
      },
      {
        nume: 'Aluniş',
        simplu: 'Alunis',
        comuna: 'Colti'
      },
      {
        nume: 'Colţii de Jos',
        simplu: 'Coltii de Jos',
        comuna: 'Colti'
      },
      {
        nume: 'Muscelu Cărămăneşti',
        simplu: 'Muscelu Caramanesti',
        comuna: 'Colti'
      },
      {
        nume: 'Budişteni',
        simplu: 'Budisteni',
        comuna: 'Costesti'
      },
      {
        nume: 'Gomoeşti',
        simplu: 'Gomoesti',
        comuna: 'Costesti'
      },
      {
        nume: 'Groşani',
        simplu: 'Grosani',
        comuna: 'Costesti'
      },
      {
        nume: 'Pietrosu',
        comuna: 'Costesti'
      },
      {
        nume: 'Spătaru',
        simplu: 'Spataru',
        comuna: 'Costesti'
      },
      {
        nume: 'Anini',
        comuna: 'Cozieni'
      },
      {
        nume: 'Berceşti',
        simplu: 'Bercesti',
        comuna: 'Cozieni'
      },
      {
        nume: 'Bălăneşti',
        simplu: 'Balanesti',
        comuna: 'Cozieni'
      },
      {
        nume: 'Ciocăneşti',
        simplu: 'Ciocanesti',
        comuna: 'Cozieni'
      },
      {
        nume: 'Cocârceni',
        simplu: 'Cocarceni',
        comuna: 'Cozieni'
      },
      {
        nume: 'Colţeni',
        simplu: 'Colteni',
        comuna: 'Cozieni'
      },
      {
        nume: 'Faţa lui Nan',
        simplu: 'Fata lui Nan',
        comuna: 'Cozieni'
      },
      {
        nume: 'Glodurile',
        comuna: 'Cozieni'
      },
      {
        nume: 'Izvoru',
        comuna: 'Cozieni'
      },
      {
        nume: 'Lungeşti',
        simplu: 'Lungesti',
        comuna: 'Cozieni'
      },
      {
        nume: 'Nistoreşti',
        simplu: 'Nistoresti',
        comuna: 'Cozieni'
      },
      {
        nume: 'Pietraru',
        comuna: 'Cozieni'
      },
      {
        nume: 'Punga',
        comuna: 'Cozieni'
      },
      {
        nume: 'Teişu',
        simplu: 'Teisu',
        comuna: 'Cozieni'
      },
      {
        nume: 'Trestia',
        comuna: 'Cozieni'
      },
      {
        nume: 'Tulburea',
        comuna: 'Cozieni'
      },
      {
        nume: 'Valea Banului',
        comuna: 'Cozieni'
      },
      {
        nume: 'Valea Roatei',
        comuna: 'Cozieni'
      },
      {
        nume: 'Zăpodia',
        simplu: 'Zapodia',
        comuna: 'Cozieni'
      },
      {
        nume: 'Bentu',
        comuna: 'Galbinasi'
      },
      {
        nume: 'Tăbărăşti',
        simplu: 'Tabarasti',
        comuna: 'Galbinasi'
      },
      {
        nume: 'Sudiţi',
        simplu: 'Suditi',
        comuna: 'Gheraseni'
      },
      {
        nume: 'Sălcioara',
        simplu: 'Salcioara',
        comuna: 'Ghergheasa'
      },
      {
        nume: 'Căldăruşeanca',
        simplu: 'Caldaruseanca',
        comuna: 'Glodeanu Sarat'
      },
      {
        nume: 'Ileana',
        comuna: 'Glodeanu Sarat'
      },
      {
        nume: 'Pitulicea',
        comuna: 'Glodeanu Sarat'
      },
      {
        nume: 'Casota',
        comuna: 'Glodeanu-Silistea'
      },
      {
        nume: 'Corbu',
        comuna: 'Glodeanu-Silistea'
      },
      {
        nume: 'Cotorca',
        comuna: 'Glodeanu-Silistea'
      },
      {
        nume: 'Cârligu Mare',
        simplu: 'Carligu Mare',
        comuna: 'Glodeanu-Silistea'
      },
      {
        nume: 'Cârligu Mic',
        simplu: 'Carligu Mic',
        comuna: 'Glodeanu-Silistea'
      },
      {
        nume: 'Satu Nou',
        comuna: 'Glodeanu-Silistea'
      },
      {
        nume: 'Văcăreasca',
        simplu: 'Vacareasca',
        comuna: 'Glodeanu-Silistea'
      },
      {
        nume: 'Homeşti',
        simplu: 'Homesti',
        comuna: 'Grebanu'
      },
      {
        nume: 'Livada',
        comuna: 'Grebanu'
      },
      {
        nume: 'Livada Mică',
        simplu: 'Livada Mica',
        comuna: 'Grebanu'
      },
      {
        nume: 'Plevna',
        comuna: 'Grebanu'
      },
      {
        nume: 'Zăplazi',
        simplu: 'Zaplazi',
        comuna: 'Grebanu'
      },
      {
        nume: 'Furtuneşti',
        simplu: 'Furtunesti',
        comuna: 'Gura Teghii'
      },
      {
        nume: 'Nemertea',
        comuna: 'Gura Teghii'
      },
      {
        nume: 'Păltiniş',
        simplu: 'Paltinis',
        comuna: 'Gura Teghii'
      },
      {
        nume: 'Secuiu',
        comuna: 'Gura Teghii'
      },
      {
        nume: 'Vadu Oii',
        comuna: 'Gura Teghii'
      },
      {
        nume: 'Varlaam',
        comuna: 'Gura Teghii'
      },
      {
        nume: 'Scărlăteşti',
        simplu: 'Scarlatesti',
        comuna: 'Largu'
      },
      {
        nume: 'Brebu',
        comuna: 'Lopatari'
      },
      {
        nume: 'Fundata',
        comuna: 'Lopatari'
      },
      {
        nume: 'Luncile',
        comuna: 'Lopatari'
      },
      {
        nume: 'Pestriţu',
        simplu: 'Pestritu',
        comuna: 'Lopatari'
      },
      {
        nume: 'Plaiu Nucului',
        comuna: 'Lopatari'
      },
      {
        nume: 'Ploştina',
        simplu: 'Plostina',
        comuna: 'Lopatari'
      },
      {
        nume: 'Potecu',
        comuna: 'Lopatari'
      },
      {
        nume: 'Săreni',
        simplu: 'Sareni',
        comuna: 'Lopatari'
      },
      {
        nume: 'Terca',
        comuna: 'Lopatari'
      },
      {
        nume: 'Vârteju',
        simplu: 'Varteju',
        comuna: 'Lopatari'
      },
      {
        nume: 'Caragele',
        comuna: 'Luciu'
      },
      {
        nume: 'Ciuta',
        comuna: 'Magura'
      },
      {
        nume: 'Unguriu',
        comuna: 'Magura'
      },
      {
        nume: 'Beşlii',
        simplu: 'Beslii',
        comuna: 'Manzalesti'
      },
      {
        nume: 'Buştea',
        simplu: 'Bustea',
        comuna: 'Manzalesti'
      },
      {
        nume: 'Cireşu',
        simplu: 'Ciresu',
        comuna: 'Manzalesti'
      },
      {
        nume: 'Ghizdita',
        comuna: 'Manzalesti'
      },
      {
        nume: 'Gura Bădicului',
        simplu: 'Gura Badicului',
        comuna: 'Manzalesti'
      },
      {
        nume: 'Jghiab',
        comuna: 'Manzalesti'
      },
      {
        nume: 'Plavăţu',
        simplu: 'Plavatu',
        comuna: 'Manzalesti'
      },
      {
        nume: 'Poiana Vâlcului',
        simplu: 'Poiana Valcului',
        comuna: 'Manzalesti'
      },
      {
        nume: 'Satu Vechi',
        comuna: 'Manzalesti'
      },
      {
        nume: 'Trestioara',
        comuna: 'Manzalesti'
      },
      {
        nume: 'Valea Cotoarei',
        comuna: 'Manzalesti'
      },
      {
        nume: 'Valea Ursului',
        comuna: 'Manzalesti'
      },
      {
        nume: 'Căpăţâneşti',
        simplu: 'Capatanesti',
        comuna: 'Maracineni'
      },
      {
        nume: 'Potoceni',
        comuna: 'Maracineni'
      },
      {
        nume: 'Câmpulungeanca',
        simplu: 'Campulungeanca',
        comuna: 'Margaritesti'
      },
      {
        nume: 'Fântânele',
        simplu: 'Fantanele',
        comuna: 'Margaritesti'
      },
      {
        nume: 'Ciobănoaia',
        simplu: 'Ciobanoaia',
        comuna: 'Merei'
      },
      {
        nume: 'Dealul Viei',
        comuna: 'Merei'
      },
      {
        nume: 'Dobrileşti',
        simplu: 'Dobrilesti',
        comuna: 'Merei'
      },
      {
        nume: 'Gura Sărăţii',
        simplu: 'Gura Saratii',
        comuna: 'Merei'
      },
      {
        nume: 'Izvoru Dulce',
        comuna: 'Merei'
      },
      {
        nume: 'Lipia',
        comuna: 'Merei'
      },
      {
        nume: 'Nenciuleşti',
        simplu: 'Nenciulesti',
        comuna: 'Merei'
      },
      {
        nume: 'Ogrăzile',
        simplu: 'Ograzile',
        comuna: 'Merei'
      },
      {
        nume: 'Sărata-Monteoru',
        simplu: 'Sarata-Monteoru',
        comuna: 'Merei'
      },
      {
        nume: 'Valea Puţului Merei',
        simplu: 'Valea Putului Merei',
        comuna: 'Merei'
      },
      {
        nume: 'Colţăneni',
        simplu: 'Coltaneni',
        comuna: 'Mihailesti'
      },
      {
        nume: 'Florica',
        comuna: 'Mihailesti'
      },
      {
        nume: 'Mărgineanu',
        simplu: 'Margineanu',
        comuna: 'Mihailesti'
      },
      {
        nume: 'Satu Nou',
        comuna: 'Mihailesti'
      },
      {
        nume: 'Cioranca',
        comuna: 'Movila Banului'
      },
      {
        nume: 'Limpeziş',
        simplu: 'Limpezis',
        comuna: 'Movila Banului'
      },
      {
        nume: 'Batogu',
        comuna: 'Murgesti'
      },
      {
        nume: 'Valea Ratei',
        comuna: 'Murgesti'
      },
      {
        nume: 'Finţeşti',
        simplu: 'Fintesti',
        comuna: 'Naeni'
      },
      {
        nume: 'Fântânele',
        simplu: 'Fantanele',
        comuna: 'Naeni'
      },
      {
        nume: 'Proşca',
        simplu: 'Prosca',
        comuna: 'Naeni'
      },
      {
        nume: 'Vârf',
        simplu: 'Varf',
        comuna: 'Naeni'
      },
      {
        nume: 'Bâsca Rozilei',
        simplu: 'Basca Rozilei',
        comuna: 'Nehoiu'
      },
      {
        nume: 'Chirleşti',
        simplu: 'Chirlesti',
        comuna: 'Nehoiu'
      },
      {
        nume: 'Curmătura',
        simplu: 'Curmatura',
        comuna: 'Nehoiu'
      },
      {
        nume: 'Lunca Priporului',
        comuna: 'Nehoiu'
      },
      {
        nume: 'Mlăjet',
        simplu: 'Mlajet',
        comuna: 'Nehoiu'
      },
      {
        nume: 'Nehoiaşu',
        simplu: 'Nehoiasu',
        comuna: 'Nehoiu'
      },
      {
        nume: 'Păltineni',
        simplu: 'Paltineni',
        comuna: 'Nehoiu'
      },
      {
        nume: 'Stănila',
        simplu: 'Stanila',
        comuna: 'Nehoiu'
      },
      {
        nume: 'Vineţişu',
        simplu: 'Vinetisu',
        comuna: 'Nehoiu'
      },
      {
        nume: 'Capu Satului',
        comuna: 'Odaile'
      },
      {
        nume: 'Corneanu',
        comuna: 'Odaile'
      },
      {
        nume: 'Gorâni',
        simplu: 'Gorani',
        comuna: 'Odaile'
      },
      {
        nume: 'Lacu',
        comuna: 'Odaile'
      },
      {
        nume: 'Piatra Albă',
        simplu: 'Piatra Alba',
        comuna: 'Odaile'
      },
      {
        nume: 'Posobeşti',
        simplu: 'Posobesti',
        comuna: 'Odaile'
      },
      {
        nume: 'Scoroşeşti',
        simplu: 'Scorosesti',
        comuna: 'Odaile'
      },
      {
        nume: 'Valea Fântânei',
        simplu: 'Valea Fantanei',
        comuna: 'Odaile'
      },
      {
        nume: 'Valea Stefanului',
        simplu: 'Valea Stefanului',
        comuna: 'Odaile'
      },
      {
        nume: 'Tătuleşti',
        simplu: 'Tatulesti',
        comuna: 'Padina'
      },
      {
        nume: 'Begu',
        comuna: 'Panatau'
      },
      {
        nume: 'Lacu cu Anini',
        comuna: 'Panatau'
      },
      {
        nume: 'Măguricea',
        simplu: 'Maguricea',
        comuna: 'Panatau'
      },
      {
        nume: 'Plăişor',
        simplu: 'Plaisor',
        comuna: 'Panatau'
      },
      {
        nume: 'Râpile',
        simplu: 'Rapile',
        comuna: 'Panatau'
      },
      {
        nume: 'Sibiciu de Jos',
        comuna: 'Panatau'
      },
      {
        nume: 'Tega',
        comuna: 'Panatau'
      },
      {
        nume: 'Zahareşti',
        simplu: 'Zaharesti',
        comuna: 'Panatau'
      },
      {
        nume: 'Chiperu',
        comuna: 'Pardosi'
      },
      {
        nume: 'Costomiru',
        comuna: 'Pardosi'
      },
      {
        nume: 'Valea lui Lalu',
        comuna: 'Pardosi'
      },
      {
        nume: 'Valea Schiopului',
        simplu: 'Valea Schiopului',
        comuna: 'Pardosi'
      },
      {
        nume: 'Badila',
        comuna: 'Parscov'
      },
      {
        nume: 'Curcăneşti',
        simplu: 'Curcanesti',
        comuna: 'Parscov'
      },
      {
        nume: 'Lunca Frumoasă',
        simplu: 'Lunca Frumoasa',
        comuna: 'Parscov'
      },
      {
        nume: 'Oleşeşti',
        simplu: 'Olesesti',
        comuna: 'Parscov'
      },
      {
        nume: 'Pârjoleşti',
        simplu: 'Parjolesti',
        comuna: 'Parscov'
      },
      {
        nume: 'Robeşti',
        simplu: 'Robesti',
        comuna: 'Parscov'
      },
      {
        nume: 'Runcu',
        comuna: 'Parscov'
      },
      {
        nume: 'Tocileni',
        comuna: 'Parscov'
      },
      {
        nume: 'Trestieni',
        comuna: 'Parscov'
      },
      {
        nume: 'Târcov',
        simplu: 'Tarcov',
        comuna: 'Parscov'
      },
      {
        nume: 'Valea Purcarului',
        comuna: 'Parscov'
      },
      {
        nume: 'Calea Chiojdului',
        comuna: 'Patarlagele'
      },
      {
        nume: 'Crâng',
        simplu: 'Crang',
        comuna: 'Patarlagele'
      },
      {
        nume: 'Fundăturile',
        simplu: 'Fundaturile',
        comuna: 'Patarlagele'
      },
      {
        nume: 'Gornet',
        comuna: 'Patarlagele'
      },
      {
        nume: 'Lunca',
        comuna: 'Patarlagele'
      },
      {
        nume: 'Muşcel',
        simplu: 'Muscel',
        comuna: 'Patarlagele'
      },
      {
        nume: 'Mănăstirea',
        simplu: 'Manastirea',
        comuna: 'Patarlagele'
      },
      {
        nume: 'Mărunţişu',
        simplu: 'Maruntisu',
        comuna: 'Patarlagele'
      },
      {
        nume: 'Poienile',
        comuna: 'Patarlagele'
      },
      {
        nume: 'Sibiciu de Sus',
        comuna: 'Patarlagele'
      },
      {
        nume: 'Stroeşti',
        simplu: 'Stroesti',
        comuna: 'Patarlagele'
      },
      {
        nume: 'Valea Lupului',
        comuna: 'Patarlagele'
      },
      {
        nume: 'Valea Sibiciului',
        comuna: 'Patarlagele'
      },
      {
        nume: 'Valea Viei',
        comuna: 'Patarlagele'
      },
      {
        nume: 'Clondiru de Sus',
        comuna: 'Pietroasele'
      },
      {
        nume: 'Câlţeşti',
        simplu: 'Caltesti',
        comuna: 'Pietroasele'
      },
      {
        nume: 'Dara',
        comuna: 'Pietroasele'
      },
      {
        nume: 'Pietroasa Mică',
        simplu: 'Pietroasa Mica',
        comuna: 'Pietroasele'
      },
      {
        nume: 'Sarânga',
        simplu: 'Saranga',
        comuna: 'Pietroasele'
      },
      {
        nume: 'Coţatcu',
        simplu: 'Cotatcu',
        comuna: 'Podgoria'
      },
      {
        nume: 'Oratia',
        comuna: 'Podgoria'
      },
      {
        nume: 'Pleşeşti',
        simplu: 'Plesesti',
        comuna: 'Podgoria'
      },
      {
        nume: 'Tăbăcari',
        simplu: 'Tabacari',
        comuna: 'Podgoria'
      },
      {
        nume: 'Căldărăşti',
        simplu: 'Caldarasti',
        comuna: 'Pogoanele'
      },
      {
        nume: 'Aliceni',
        comuna: 'Posta Calnau'
      },
      {
        nume: 'Coconari',
        comuna: 'Posta Calnau'
      },
      {
        nume: 'Potârnicheşti',
        simplu: 'Potarnichesti',
        comuna: 'Posta Calnau'
      },
      {
        nume: 'Sudiţi',
        simplu: 'Suditi',
        comuna: 'Posta Calnau'
      },
      {
        nume: 'Zilişteanca',
        simplu: 'Zilisteanca',
        comuna: 'Posta Calnau'
      },
      {
        nume: 'Dăscăleşti',
        simplu: 'Dascalesti',
        comuna: 'Puiesti'
      },
      {
        nume: 'Lunca',
        comuna: 'Puiesti'
      },
      {
        nume: 'Măcrina',
        simplu: 'Macrina',
        comuna: 'Puiesti'
      },
      {
        nume: 'Nicoleşti',
        simplu: 'Nicolesti',
        comuna: 'Puiesti'
      },
      {
        nume: 'Plopi',
        comuna: 'Puiesti'
      },
      {
        nume: 'Puieştii de Jos',
        simplu: 'Puiestii de Jos',
        comuna: 'Puiesti'
      },
      {
        nume: 'Puieştii de Sus',
        simplu: 'Puiestii de Sus',
        comuna: 'Puiesti'
      },
      {
        nume: 'Budrea',
        comuna: 'Racoviteni'
      },
      {
        nume: 'Petrişoru',
        simplu: 'Petrisoru',
        comuna: 'Racoviteni'
      },
      {
        nume: 'Colibaşi',
        simplu: 'Colibasi',
        comuna: 'Ramnicelu'
      },
      {
        nume: 'Fotin',
        comuna: 'Ramnicelu'
      },
      {
        nume: 'Stiubei',
        simplu: 'Stiubei',
        comuna: 'Ramnicelu'
      },
      {
        nume: 'Moşeşti',
        simplu: 'Mosesti',
        comuna: 'Robeasca'
      },
      {
        nume: 'Sergent Ionel Stefan',
        simplu: 'Sergent Ionel Stefan',
        comuna: 'Rusetu'
      },
      {
        nume: 'Baniţa',
        simplu: 'Banita',
        comuna: 'Sageata'
      },
      {
        nume: 'Beilic',
        comuna: 'Sageata'
      },
      {
        nume: 'Borduşani',
        simplu: 'Bordusani',
        comuna: 'Sageata'
      },
      {
        nume: 'Dâmbroca',
        simplu: 'Dambroca',
        comuna: 'Sageata'
      },
      {
        nume: 'Găvăneşti',
        simplu: 'Gavanesti',
        comuna: 'Sageata'
      },
      {
        nume: 'Moviliţa',
        simplu: 'Movilita',
        comuna: 'Sageata'
      },
      {
        nume: 'Găgeni',
        simplu: 'Gageni',
        comuna: 'Sahateni'
      },
      {
        nume: 'Istriţa de Jos',
        simplu: 'Istrita de Jos',
        comuna: 'Sahateni'
      },
      {
        nume: 'Vintileanca',
        comuna: 'Sahateni'
      },
      {
        nume: 'Măteşti',
        simplu: 'Matesti',
        comuna: 'Sapoca'
      },
      {
        nume: 'Cărătnău de Jos',
        simplu: 'Caratnau de Jos',
        comuna: 'Sarulesti'
      },
      {
        nume: 'Cărătnău de Sus',
        simplu: 'Caratnau de Sus',
        comuna: 'Sarulesti'
      },
      {
        nume: 'Goicelu',
        comuna: 'Sarulesti'
      },
      {
        nume: 'Sările-Cătun',
        simplu: 'Sarile-Catun',
        comuna: 'Sarulesti'
      },
      {
        nume: 'Valea Largă-Săruleşti',
        simplu: 'Valea Larga-Sarulesti',
        comuna: 'Sarulesti'
      },
      {
        nume: 'Valea Stânei',
        simplu: 'Valea Stanei',
        comuna: 'Sarulesti'
      },
      {
        nume: 'Balta Tocila',
        comuna: 'Scortoasa'
      },
      {
        nume: 'Beciu',
        comuna: 'Scortoasa'
      },
      {
        nume: 'Deleni',
        comuna: 'Scortoasa'
      },
      {
        nume: 'Dâlma',
        simplu: 'Dalma',
        comuna: 'Scortoasa'
      },
      {
        nume: 'Golu Grabicina',
        comuna: 'Scortoasa'
      },
      {
        nume: 'Grabicina de Jos',
        comuna: 'Scortoasa'
      },
      {
        nume: 'Grabicina de Sus',
        comuna: 'Scortoasa'
      },
      {
        nume: 'Gura Văii',
        simplu: 'Gura Vaii',
        comuna: 'Scortoasa'
      },
      {
        nume: 'Plopeasa',
        comuna: 'Scortoasa'
      },
      {
        nume: 'Policiori',
        comuna: 'Scortoasa'
      },
      {
        nume: 'Arcanu',
        comuna: 'Scutelnici'
      },
      {
        nume: 'Brăgăreasa',
        simplu: 'Bragareasa',
        comuna: 'Scutelnici'
      },
      {
        nume: 'Lipănescu',
        simplu: 'Lipanescu',
        comuna: 'Scutelnici'
      },
      {
        nume: 'Caşoca',
        simplu: 'Casoca',
        comuna: 'Siriu'
      },
      {
        nume: 'Colţu Pietrii',
        simplu: 'Coltu Pietrii',
        comuna: 'Siriu'
      },
      {
        nume: 'Gura Siriului',
        comuna: 'Siriu'
      },
      {
        nume: 'Lunca Jariştei',
        simplu: 'Lunca Jaristei',
        comuna: 'Siriu'
      },
      {
        nume: 'Muşceluşa',
        simplu: 'Muscelusa',
        comuna: 'Siriu'
      },
      {
        nume: 'Albeşti',
        simplu: 'Albesti',
        comuna: 'Smeeni'
      },
      {
        nume: 'Bălaia',
        simplu: 'Balaia',
        comuna: 'Smeeni'
      },
      {
        nume: 'Călţuna',
        simplu: 'Caltuna',
        comuna: 'Smeeni'
      },
      {
        nume: 'Moisica',
        comuna: 'Smeeni'
      },
      {
        nume: 'Udaţi-Lucieni',
        simplu: 'Udati-Lucieni',
        comuna: 'Smeeni'
      },
      {
        nume: 'Udaţi-Mânzu',
        simplu: 'Udati-Manzu',
        comuna: 'Smeeni'
      },
      {
        nume: 'Maxenu',
        comuna: 'Tintesti'
      },
      {
        nume: 'Odaia Banului',
        comuna: 'Tintesti'
      },
      {
        nume: 'Pogonele',
        comuna: 'Tintesti'
      },
      {
        nume: 'Bărbunceşti',
        simplu: 'Barbuncesti',
        comuna: 'Tisau'
      },
      {
        nume: 'Grăjdana',
        simplu: 'Grajdana',
        comuna: 'Tisau'
      },
      {
        nume: 'Haleş',
        simplu: 'Hales',
        comuna: 'Tisau'
      },
      {
        nume: 'Izvoranu',
        comuna: 'Tisau'
      },
      {
        nume: 'Izvoru',
        comuna: 'Tisau'
      },
      {
        nume: 'Leiculeşti',
        simplu: 'Leiculesti',
        comuna: 'Tisau'
      },
      {
        nume: 'Pădurenii',
        simplu: 'Padurenii',
        comuna: 'Tisau'
      },
      {
        nume: 'Salcia',
        comuna: 'Tisau'
      },
      {
        nume: 'Strezeni',
        comuna: 'Tisau'
      },
      {
        nume: 'Valea Sălciilor',
        simplu: 'Valea Salciilor',
        comuna: 'Tisau'
      },
      {
        nume: 'Băbeni',
        simplu: 'Babeni',
        comuna: 'Topliceni'
      },
      {
        nume: 'Ceairu',
        comuna: 'Topliceni'
      },
      {
        nume: 'Deduleşti',
        simplu: 'Dedulesti',
        comuna: 'Topliceni'
      },
      {
        nume: 'Gura Făgetului',
        simplu: 'Gura Fagetului',
        comuna: 'Topliceni'
      },
      {
        nume: 'Poşta',
        simplu: 'Posta',
        comuna: 'Topliceni'
      },
      {
        nume: 'Răduceşti',
        simplu: 'Raducesti',
        comuna: 'Topliceni'
      },
      {
        nume: 'Băltăreţi',
        simplu: 'Baltareti',
        comuna: 'Ulmeni'
      },
      {
        nume: 'Clondiru',
        comuna: 'Ulmeni'
      },
      {
        nume: 'Sărata',
        simplu: 'Sarata',
        comuna: 'Ulmeni'
      },
      {
        nume: 'Vâlcele',
        simplu: 'Valcele',
        comuna: 'Ulmeni'
      },
      {
        nume: 'Ojasca',
        comuna: 'Unguriu'
      },
      {
        nume: 'Băjani',
        simplu: 'Bajani',
        comuna: 'Vadu Pasii'
      },
      {
        nume: 'Focşănei',
        simplu: 'Focsanei',
        comuna: 'Vadu Pasii'
      },
      {
        nume: 'Gura Câlnăului',
        simplu: 'Gura Calnaului',
        comuna: 'Vadu Pasii'
      },
      {
        nume: 'Scurteşti',
        simplu: 'Scurtesti',
        comuna: 'Vadu Pasii'
      },
      {
        nume: 'Stănceşti',
        simplu: 'Stancesti',
        comuna: 'Vadu Pasii'
      },
      {
        nume: 'Oreavul',
        comuna: 'Valea Ramnicului'
      },
      {
        nume: 'Rubla',
        comuna: 'Valea Ramnicului'
      },
      {
        nume: 'Modreni',
        comuna: 'Valea Salciei'
      },
      {
        nume: 'Valea Salciei-Cătun',
        simplu: 'Valea Salciei-Catun',
        comuna: 'Valea Salciei'
      },
      {
        nume: 'Brădeanca',
        simplu: 'Bradeanca',
        comuna: 'Vernesti'
      },
      {
        nume: 'Cândeşti',
        simplu: 'Candesti',
        comuna: 'Vernesti'
      },
      {
        nume: 'Cârlomăneşti',
        simplu: 'Carlomanesti',
        comuna: 'Vernesti'
      },
      {
        nume: 'Mierea',
        comuna: 'Vernesti'
      },
      {
        nume: 'Nenciu',
        comuna: 'Vernesti'
      },
      {
        nume: 'Nişcov',
        simplu: 'Niscov',
        comuna: 'Vernesti'
      },
      {
        nume: 'Săsenii Noi',
        simplu: 'Sasenii Noi',
        comuna: 'Vernesti'
      },
      {
        nume: 'Săsenii Vechi',
        simplu: 'Sasenii Vechi',
        comuna: 'Vernesti'
      },
      {
        nume: 'Săsenii pe Vale',
        simplu: 'Sasenii pe Vale',
        comuna: 'Vernesti'
      },
      {
        nume: 'Zoreşti',
        simplu: 'Zoresti',
        comuna: 'Vernesti'
      },
      {
        nume: 'Bodineşti',
        simplu: 'Bodinesti',
        comuna: 'Vintila Voda'
      },
      {
        nume: 'Coca-Antimireşti',
        simplu: 'Coca-Antimiresti',
        comuna: 'Vintila Voda'
      },
      {
        nume: 'Coca-Niculeşti',
        simplu: 'Coca-Niculesti',
        comuna: 'Vintila Voda'
      },
      {
        nume: 'Niculeşti',
        simplu: 'Niculesti',
        comuna: 'Vintila Voda'
      },
      {
        nume: 'Petrăcheşti',
        simplu: 'Petrachesti',
        comuna: 'Vintila Voda'
      },
      {
        nume: 'Podu Muncii',
        comuna: 'Vintila Voda'
      },
      {
        nume: 'Smeeşti',
        simplu: 'Smeesti',
        comuna: 'Vintila Voda'
      },
      {
        nume: 'Sârbeşti',
        simplu: 'Sarbesti',
        comuna: 'Vintila Voda'
      },
      {
        nume: 'Muscel',
        comuna: 'Viperesti'
      },
      {
        nume: 'Pălici',
        simplu: 'Palici',
        comuna: 'Viperesti'
      },
      {
        nume: 'Ruşavăţ',
        simplu: 'Rusavat',
        comuna: 'Viperesti'
      },
      {
        nume: 'Tronari',
        comuna: 'Viperesti'
      },
      {
        nume: 'Ursoaia',
        comuna: 'Viperesti'
      },
      {
        nume: 'Comisoaia',
        comuna: 'Zarnesti'
      },
      {
        nume: 'Fundeni',
        comuna: 'Zarnesti'
      },
      {
        nume: 'Pruneni',
        comuna: 'Zarnesti'
      },
      {
        nume: 'Vadu Soreşti',
        simplu: 'Vadu Soresti',
        comuna: 'Zarnesti'
      },
      {
        nume: 'Costieni',
        comuna: 'Ziduri'
      },
      {
        nume: 'Cuculeasa',
        comuna: 'Ziduri'
      },
      {
        nume: 'Heliade Rădulescu',
        simplu: 'Heliade Radulescu',
        comuna: 'Ziduri'
      },
      {
        nume: 'Lanurile',
        comuna: 'Ziduri'
      },
      {
        nume: 'Zoiţa',
        simplu: 'Zoita',
        comuna: 'Ziduri'
      }
    ]
  },
  {
    auto: 'CL',
    nume: 'Călăraşi',
    localitati: [
      {
        nume: 'Alexandru Odobescu'
      },
      {
        nume: 'Belciugatele'
      },
      {
        nume: 'Borcea'
      },
      {
        nume: 'Budeşti',
        simplu: 'Budesti'
      },
      {
        nume: 'Chirnogi'
      },
      {
        nume: 'Chiselet'
      },
      {
        nume: 'Ciocăneşti',
        simplu: 'Ciocanesti'
      },
      {
        nume: 'Curcani'
      },
      {
        nume: 'Cuza Vodă',
        simplu: 'Cuza Voda'
      },
      {
        nume: 'Călăraşi',
        simplu: 'Calarasi'
      },
      {
        nume: 'Căscioarele',
        simplu: 'Cascioarele'
      },
      {
        nume: 'Dichiseni'
      },
      {
        nume: 'Dor Mărunt',
        simplu: 'Dor Marunt'
      },
      {
        nume: 'Dorobanţu',
        simplu: 'Dorobantu'
      },
      {
        nume: 'Dragalina'
      },
      {
        nume: 'Dragoş Vodă',
        simplu: 'Dragos Voda'
      },
      {
        nume: 'Frumuşani',
        simplu: 'Frumusani'
      },
      {
        nume: 'Frăsinet',
        simplu: 'Frasinet'
      },
      {
        nume: 'Fundeni'
      },
      {
        nume: 'Fundulea'
      },
      {
        nume: 'Grădiştea',
        simplu: 'Gradistea'
      },
      {
        nume: 'Gurbăneşti',
        simplu: 'Gurbanesti'
      },
      {
        nume: 'Ileana'
      },
      {
        nume: 'Independenţa',
        simplu: 'Independenta'
      },
      {
        nume: 'Jegălia',
        simplu: 'Jegalia'
      },
      {
        nume: 'Lehliu'
      },
      {
        nume: 'Luica'
      },
      {
        nume: 'Lupşanu',
        simplu: 'Lupsanu'
      },
      {
        nume: 'Mitreni'
      },
      {
        nume: 'Modelu'
      },
      {
        nume: 'Mânăstirea',
        simplu: 'Manastirea'
      },
      {
        nume: 'Nana'
      },
      {
        nume: 'Nicolae Bălcescu',
        simplu: 'Nicolae Balcescu'
      },
      {
        nume: 'Olteniţa',
        simplu: 'Oltenita'
      },
      {
        nume: 'Perişoru',
        simplu: 'Perisoru'
      },
      {
        nume: 'Plătăreşti',
        simplu: 'Plataresti'
      },
      {
        nume: 'Radovanu'
      },
      {
        nume: 'Roseţi',
        simplu: 'Roseti'
      },
      {
        nume: 'Sohatu'
      },
      {
        nume: 'Spanţov',
        simplu: 'Spantov'
      },
      {
        nume: 'Săruleşti',
        simplu: 'Sarulesti'
      },
      {
        nume: 'Tămădău Mare',
        simplu: 'Tamadau Mare'
      },
      {
        nume: 'Ulmeni'
      },
      {
        nume: 'Ulmu'
      },
      {
        nume: 'Unirea'
      },
      {
        nume: 'Valea Argovei'
      },
      {
        nume: 'Vasilaţi',
        simplu: 'Vasilati'
      },
      {
        nume: 'Vlad Tepeş',
        simplu: 'Vlad Tepes'
      },
      {
        nume: 'Vâlcelele',
        simplu: 'Valcelele'
      },
      {
        nume: 'Soldanu',
        simplu: 'Soldanu'
      },
      {
        nume: 'Stefan Vodă',
        simplu: 'Stefan Voda'
      },
      {
        nume: 'Stefan cel Mare',
        simplu: 'Stefan cel Mare'
      },
      {
        nume: 'Gălăţui',
        simplu: 'Galatui',
        comuna: 'Alexandru Odobescu'
      },
      {
        nume: 'Nicolae Bălcescu',
        simplu: 'Nicolae Balcescu',
        comuna: 'Alexandru Odobescu'
      },
      {
        nume: 'Cojeşti',
        simplu: 'Cojesti',
        comuna: 'Belciugatele'
      },
      {
        nume: 'Cândeasca',
        simplu: 'Candeasca',
        comuna: 'Belciugatele'
      },
      {
        nume: 'Mataraua',
        comuna: 'Belciugatele'
      },
      {
        nume: 'Măriuţa',
        simplu: 'Mariuta',
        comuna: 'Belciugatele'
      },
      {
        nume: 'Aprozi',
        comuna: 'Budesti'
      },
      {
        nume: 'Buciumeni',
        comuna: 'Budesti'
      },
      {
        nume: 'Crivăţ',
        simplu: 'Crivat',
        comuna: 'Budesti'
      },
      {
        nume: 'Gruiu',
        comuna: 'Budesti'
      },
      {
        nume: 'Sălcioara',
        simplu: 'Salcioara',
        comuna: 'Curcani'
      },
      {
        nume: 'Ceacu',
        comuna: 'Cuza Voda'
      },
      {
        nume: 'Călăraşii Vechi',
        simplu: 'Calarasii Vechi',
        comuna: 'Cuza Voda'
      },
      {
        nume: 'Coslogeni',
        comuna: 'Dichiseni'
      },
      {
        nume: 'Libertatea',
        comuna: 'Dichiseni'
      },
      {
        nume: 'Satnoeni',
        comuna: 'Dichiseni'
      },
      {
        nume: 'Dâlga',
        simplu: 'Dalga',
        comuna: 'Dor Marunt'
      },
      {
        nume: 'Dâlga-Gară',
        simplu: 'Dalga-Gara',
        comuna: 'Dor Marunt'
      },
      {
        nume: 'Ogoru',
        comuna: 'Dor Marunt'
      },
      {
        nume: 'Pelinu',
        comuna: 'Dor Marunt'
      },
      {
        nume: 'Înfrăţirea',
        simplu: 'Infratirea',
        comuna: 'Dor Marunt'
      },
      {
        nume: 'Boşneagu',
        simplu: 'Bosneagu',
        comuna: 'Dorobantu'
      },
      {
        nume: 'Vărăşti',
        simplu: 'Varasti',
        comuna: 'Dorobantu'
      },
      {
        nume: 'Constantin Brâncoveanu',
        simplu: 'Constantin Brancoveanu',
        comuna: 'Dragalina'
      },
      {
        nume: 'Drajna Nouă',
        simplu: 'Drajna Noua',
        comuna: 'Dragalina'
      },
      {
        nume: 'Bogdana',
        comuna: 'Dragos Voda'
      },
      {
        nume: 'Socoalele',
        comuna: 'Dragos Voda'
      },
      {
        nume: 'Curăteşti',
        simplu: 'Curatesti',
        comuna: 'Frasinet'
      },
      {
        nume: 'Dăneşti',
        simplu: 'Danesti',
        comuna: 'Frasinet'
      },
      {
        nume: 'Frăsinetu de Jos',
        simplu: 'Frasinetu de Jos',
        comuna: 'Frasinet'
      },
      {
        nume: 'Luptători',
        simplu: 'Luptatori',
        comuna: 'Frasinet'
      },
      {
        nume: 'Tăriceni',
        simplu: 'Tariceni',
        comuna: 'Frasinet'
      },
      {
        nume: 'Orăşti',
        simplu: 'Orasti',
        comuna: 'Frumusani'
      },
      {
        nume: 'Pasărea',
        simplu: 'Pasarea',
        comuna: 'Frumusani'
      },
      {
        nume: 'Piţigaia',
        simplu: 'Pitigaia',
        comuna: 'Frumusani'
      },
      {
        nume: 'Postăvari',
        simplu: 'Postavari',
        comuna: 'Frumusani'
      },
      {
        nume: 'Pădurişu',
        simplu: 'Padurisu',
        comuna: 'Frumusani'
      },
      {
        nume: 'Alexandru I. Cuza',
        comuna: 'Fundulea'
      },
      {
        nume: 'Gostilele',
        comuna: 'Fundulea'
      },
      {
        nume: 'Bogata',
        comuna: 'Gradistea'
      },
      {
        nume: 'Cuneşti',
        simplu: 'Cunesti',
        comuna: 'Gradistea'
      },
      {
        nume: 'Rasa',
        comuna: 'Gradistea'
      },
      {
        nume: 'Codreni',
        comuna: 'Gurbanesti'
      },
      {
        nume: 'Coţofanca',
        simplu: 'Cotofanca',
        comuna: 'Gurbanesti'
      },
      {
        nume: 'Preasna',
        comuna: 'Gurbanesti'
      },
      {
        nume: 'Preasna Veche',
        comuna: 'Gurbanesti'
      },
      {
        nume: 'Valea Presnei',
        comuna: 'Gurbanesti'
      },
      {
        nume: 'Arţari',
        simplu: 'Artari',
        comuna: 'Ileana'
      },
      {
        nume: 'Florica',
        comuna: 'Ileana'
      },
      {
        nume: 'Podari',
        comuna: 'Ileana'
      },
      {
        nume: 'Răsurile',
        simplu: 'Rasurile',
        comuna: 'Ileana'
      },
      {
        nume: 'Răzoarele',
        simplu: 'Razoarele',
        comuna: 'Ileana'
      },
      {
        nume: 'Satu Nou',
        comuna: 'Ileana'
      },
      {
        nume: 'Vlăiculeşti',
        simplu: 'Vlaiculesti',
        comuna: 'Ileana'
      },
      {
        nume: 'Stefăneşti',
        simplu: 'Stefanesti',
        comuna: 'Ileana'
      },
      {
        nume: 'Potcoava',
        comuna: 'Independenta'
      },
      {
        nume: 'Vişinii',
        simplu: 'Visinii',
        comuna: 'Independenta'
      },
      {
        nume: 'Gâldău',
        simplu: 'Galdau',
        comuna: 'Jegalia'
      },
      {
        nume: 'Iezeru',
        comuna: 'Jegalia'
      },
      {
        nume: 'Săpunari',
        simplu: 'Sapunari',
        comuna: 'Lehliu'
      },
      {
        nume: 'Buzoeni',
        comuna: 'Lehliu Gara'
      },
      {
        nume: 'Lehliu-Gară',
        simplu: 'Lehliu-Gara',
        comuna: 'Lehliu Gara'
      },
      {
        nume: 'Răzvani',
        simplu: 'Razvani',
        comuna: 'Lehliu Gara'
      },
      {
        nume: 'Valea Seacă',
        simplu: 'Valea Seaca',
        comuna: 'Lehliu Gara'
      },
      {
        nume: 'Valea Stânii',
        simplu: 'Valea Stanii',
        comuna: 'Luica'
      },
      {
        nume: 'Nucetu',
        comuna: 'Lupsanu'
      },
      {
        nume: 'Plevna',
        comuna: 'Lupsanu'
      },
      {
        nume: 'Radu Vodă',
        simplu: 'Radu Voda',
        comuna: 'Lupsanu'
      },
      {
        nume: 'Valea Rusului',
        comuna: 'Lupsanu'
      },
      {
        nume: 'Coconi',
        comuna: 'Manastirea'
      },
      {
        nume: 'Sultana',
        comuna: 'Manastirea'
      },
      {
        nume: 'Clăteşti',
        simplu: 'Clatesti',
        comuna: 'Mitreni'
      },
      {
        nume: 'Valea Roşie',
        simplu: 'Valea Rosie',
        comuna: 'Mitreni'
      },
      {
        nume: 'Radu Negru',
        comuna: 'Modelu'
      },
      {
        nume: 'Stoeneşti',
        simplu: 'Stoenesti',
        comuna: 'Modelu'
      },
      {
        nume: 'Tonea',
        comuna: 'Modelu'
      },
      {
        nume: 'Fântâna Doamnei',
        simplu: 'Fantana Doamnei',
        comuna: 'Nicolae Balcescu'
      },
      {
        nume: 'Paicu',
        comuna: 'Nicolae Balcescu'
      },
      {
        nume: 'Mărculeşti-Gară',
        simplu: 'Marculesti-Gara',
        comuna: 'Perisoru'
      },
      {
        nume: 'Tudor Vladimirescu',
        comuna: 'Perisoru'
      },
      {
        nume: 'Cucuieţi',
        simplu: 'Cucuieti',
        comuna: 'Plataresti'
      },
      {
        nume: 'Dorobanţu',
        simplu: 'Dorobantu',
        comuna: 'Plataresti'
      },
      {
        nume: 'Podu Pitarului',
        comuna: 'Plataresti'
      },
      {
        nume: 'Valea Popii',
        comuna: 'Radovanu'
      },
      {
        nume: 'Măgureni',
        simplu: 'Magureni',
        comuna: 'Sarulesti'
      },
      {
        nume: 'Polceşti',
        simplu: 'Polcesti',
        comuna: 'Sarulesti'
      },
      {
        nume: 'Solacolu',
        comuna: 'Sarulesti'
      },
      {
        nume: 'Sănduliţa',
        simplu: 'Sandulita',
        comuna: 'Sarulesti'
      },
      {
        nume: 'Săruleşti-Gară',
        simplu: 'Sarulesti-Gara',
        comuna: 'Sarulesti'
      },
      {
        nume: 'Sătucu',
        simplu: 'Satucu',
        comuna: 'Sarulesti'
      },
      {
        nume: 'Progresu',
        comuna: 'Sohatu'
      },
      {
        nume: 'Negoeşti',
        simplu: 'Negoesti',
        comuna: 'Soldanu'
      },
      {
        nume: 'Cetatea Veche',
        comuna: 'Spantov'
      },
      {
        nume: 'Stancea',
        comuna: 'Spantov'
      },
      {
        nume: 'Călăreţi',
        simplu: 'Calareti',
        comuna: 'Tamadau Mare'
      },
      {
        nume: 'Dârvari',
        simplu: 'Darvari',
        comuna: 'Tamadau Mare'
      },
      {
        nume: 'Plumbuita',
        comuna: 'Tamadau Mare'
      },
      {
        nume: 'Săcele',
        simplu: 'Sacele',
        comuna: 'Tamadau Mare'
      },
      {
        nume: 'Tămădău Mic',
        simplu: 'Tamadau Mic',
        comuna: 'Tamadau Mare'
      },
      {
        nume: 'Seinoiu',
        simplu: 'Seinoiu',
        comuna: 'Tamadau Mare'
      },
      {
        nume: 'Chirnogi',
        comuna: 'Ulmu'
      },
      {
        nume: 'Făurei',
        simplu: 'Faurei',
        comuna: 'Ulmu'
      },
      {
        nume: 'Zimbru',
        comuna: 'Ulmu'
      },
      {
        nume: 'Oltina',
        comuna: 'Unirea'
      },
      {
        nume: 'Floroaica',
        comuna: 'Valcelele'
      },
      {
        nume: 'Lunca',
        comuna: 'Valea Argovei'
      },
      {
        nume: 'Ostrovu',
        comuna: 'Valea Argovei'
      },
      {
        nume: 'Siliştea',
        simplu: 'Silistea',
        comuna: 'Valea Argovei'
      },
      {
        nume: 'Vlădiceasca',
        simplu: 'Vladiceasca',
        comuna: 'Valea Argovei'
      },
      {
        nume: 'Gălbinaşi',
        simplu: 'Galbinasi',
        comuna: 'Vasilati'
      },
      {
        nume: 'Nuci',
        comuna: 'Vasilati'
      },
      {
        nume: 'Popeşti',
        simplu: 'Popesti',
        comuna: 'Vasilati'
      },
      {
        nume: 'Mihai Viteazu',
        comuna: 'Vlad Tepes'
      }
    ]
  },
  {
    auto: 'CS',
    nume: 'Caraş-Severin',
    localitati: [
      {
        nume: 'Anina'
      },
      {
        nume: 'Armeniş',
        simplu: 'Armenis'
      },
      {
        nume: 'Berlişte',
        simplu: 'Berliste'
      },
      {
        nume: 'Berzasca'
      },
      {
        nume: 'Berzovia'
      },
      {
        nume: 'Bocşa',
        simplu: 'Bocsa'
      },
      {
        nume: 'Bolvaşniţa',
        simplu: 'Bolvasnita'
      },
      {
        nume: 'Bozovici'
      },
      {
        nume: 'Brebu'
      },
      {
        nume: 'Brebu Nou'
      },
      {
        nume: 'Buchin'
      },
      {
        nume: 'Bucoşniţa',
        simplu: 'Bucosnita'
      },
      {
        nume: 'Băile Herculane',
        simplu: 'Baile Herculane'
      },
      {
        nume: 'Bănia',
        simplu: 'Bania'
      },
      {
        nume: 'Băuţar',
        simplu: 'Bautar'
      },
      {
        nume: 'Caransebeş',
        simplu: 'Caransebes'
      },
      {
        nume: 'Caraşova',
        simplu: 'Carasova'
      },
      {
        nume: 'Ciclova Română',
        simplu: 'Ciclova Romana'
      },
      {
        nume: 'Ciuchici'
      },
      {
        nume: 'Ciudanoviţa',
        simplu: 'Ciudanovita'
      },
      {
        nume: 'Copăcele',
        simplu: 'Copacele'
      },
      {
        nume: 'Cornea'
      },
      {
        nume: 'Cornereva'
      },
      {
        nume: 'Coronini'
      },
      {
        nume: 'Cărbunari',
        simplu: 'Carbunari'
      },
      {
        nume: 'Dalboşeţ',
        simplu: 'Dalboset'
      },
      {
        nume: 'Doclin'
      },
      {
        nume: 'Dognecea'
      },
      {
        nume: 'Domaşnea',
        simplu: 'Domasnea'
      },
      {
        nume: 'Eftimie Murgu'
      },
      {
        nume: 'Ezeriş',
        simplu: 'Ezeris'
      },
      {
        nume: 'Forotic'
      },
      {
        nume: 'Fârliug',
        simplu: 'Farliug'
      },
      {
        nume: 'Glimboca'
      },
      {
        nume: 'Goruia'
      },
      {
        nume: 'Grădinari',
        simplu: 'Gradinari'
      },
      {
        nume: 'Gârnic',
        simplu: 'Garnic'
      },
      {
        nume: 'Iablaniţa',
        simplu: 'Iablanita'
      },
      {
        nume: 'Luncaviţa',
        simplu: 'Luncavita'
      },
      {
        nume: 'Lupac'
      },
      {
        nume: 'Lăpuşnicel',
        simplu: 'Lapusnicel'
      },
      {
        nume: 'Lăpuşnicu Mare',
        simplu: 'Lapusnicu Mare'
      },
      {
        nume: 'Marga'
      },
      {
        nume: 'Mehadia'
      },
      {
        nume: 'Mehadica'
      },
      {
        nume: 'Moldova Nouă',
        simplu: 'Moldova Noua'
      },
      {
        nume: 'Măureni',
        simplu: 'Maureni'
      },
      {
        nume: 'Naidăş',
        simplu: 'Naidas'
      },
      {
        nume: 'Obreja'
      },
      {
        nume: 'Ocna de Fier'
      },
      {
        nume: 'Oraviţa',
        simplu: 'Oravita'
      },
      {
        nume: 'Oţelu Roşu',
        simplu: 'Otelu Rosu'
      },
      {
        nume: 'Pojejena'
      },
      {
        nume: 'Prigor'
      },
      {
        nume: 'Păltiniş',
        simplu: 'Paltinis'
      },
      {
        nume: 'Ramna'
      },
      {
        nume: 'Reşiţa',
        simplu: 'Resita'
      },
      {
        nume: 'Rusca Montană',
        simplu: 'Rusca Montana'
      },
      {
        nume: 'Răcăşdia',
        simplu: 'Racasdia'
      },
      {
        nume: 'Sacu'
      },
      {
        nume: 'Sasca Montană',
        simplu: 'Sasca Montana'
      },
      {
        nume: 'Sicheviţa',
        simplu: 'Sichevita'
      },
      {
        nume: 'Slatina-Timiş',
        simplu: 'Slatina-Timis'
      },
      {
        nume: 'Socol'
      },
      {
        nume: 'Teregova'
      },
      {
        nume: 'Ticvaniu Mare'
      },
      {
        nume: 'Topleţ',
        simplu: 'Toplet'
      },
      {
        nume: 'Turnu Ruieni'
      },
      {
        nume: 'Târnova',
        simplu: 'Tarnova'
      },
      {
        nume: 'Vermeş',
        simplu: 'Vermes'
      },
      {
        nume: 'Vrani'
      },
      {
        nume: 'Văliug',
        simplu: 'Valiug'
      },
      {
        nume: 'Vărădia',
        simplu: 'Varadia'
      },
      {
        nume: 'Zorlenţu Mare',
        simplu: 'Zorlentu Mare'
      },
      {
        nume: 'Zăvoi',
        simplu: 'Zavoi'
      },
      {
        nume: 'Sopotu Nou',
        simplu: 'Sopotu Nou'
      },
      {
        nume: 'Steierdorf',
        comuna: 'Anina'
      },
      {
        nume: 'Feneş',
        simplu: 'Fenes',
        comuna: 'Armenis'
      },
      {
        nume: 'Plopu',
        comuna: 'Armenis'
      },
      {
        nume: 'Sat Bătrân',
        simplu: 'Sat Batran',
        comuna: 'Armenis'
      },
      {
        nume: 'Sub Margine',
        comuna: 'Armenis'
      },
      {
        nume: 'Pecinişca',
        simplu: 'Pecinisca',
        comuna: 'Baile Herculane'
      },
      {
        nume: 'Gârbovăţ',
        simplu: 'Garbovat',
        comuna: 'Bania'
      },
      {
        nume: 'Bucova',
        comuna: 'Bautar'
      },
      {
        nume: 'Cornişoru',
        simplu: 'Cornisoru',
        comuna: 'Bautar'
      },
      {
        nume: 'Preveciori',
        comuna: 'Bautar'
      },
      {
        nume: 'Iam',
        comuna: 'Berliste'
      },
      {
        nume: 'Milcoveni',
        comuna: 'Berliste'
      },
      {
        nume: 'Rusova Nouă',
        simplu: 'Rusova Noua',
        comuna: 'Berliste'
      },
      {
        nume: 'Rusova Veche',
        comuna: 'Berliste'
      },
      {
        nume: 'Bigăr',
        simplu: 'Bigar',
        comuna: 'Berzasca'
      },
      {
        nume: 'Cozla',
        comuna: 'Berzasca'
      },
      {
        nume: 'Drencova',
        comuna: 'Berzasca'
      },
      {
        nume: 'Liubcova',
        comuna: 'Berzasca'
      },
      {
        nume: 'Fizeş',
        simplu: 'Fizes',
        comuna: 'Berzovia'
      },
      {
        nume: 'Gherteniş',
        simplu: 'Ghertenis',
        comuna: 'Berzovia'
      },
      {
        nume: 'Vârciorova',
        simplu: 'Varciorova',
        comuna: 'Bolvasnita'
      },
      {
        nume: 'Poneasca',
        comuna: 'Bozovici'
      },
      {
        nume: 'Prilipeţ',
        simplu: 'Prilipet',
        comuna: 'Bozovici'
      },
      {
        nume: 'Valea Minişului',
        simplu: 'Valea Minisului',
        comuna: 'Bozovici'
      },
      {
        nume: 'Apadia',
        comuna: 'Brebu'
      },
      {
        nume: 'Valeadeni',
        comuna: 'Brebu'
      },
      {
        nume: 'Gărâna',
        simplu: 'Garana',
        comuna: 'Brebu Nou'
      },
      {
        nume: 'Lindenfeld',
        comuna: 'Buchin'
      },
      {
        nume: 'Poiana',
        comuna: 'Buchin'
      },
      {
        nume: 'Prisian',
        comuna: 'Buchin'
      },
      {
        nume: 'Valea Timişului',
        simplu: 'Valea Timisului',
        comuna: 'Buchin'
      },
      {
        nume: 'Goleţ',
        simplu: 'Golet',
        comuna: 'Bucosnita'
      },
      {
        nume: 'Petroşniţa',
        simplu: 'Petrosnita',
        comuna: 'Bucosnita'
      },
      {
        nume: 'Vălişoara',
        simplu: 'Valisoara',
        comuna: 'Bucosnita'
      },
      {
        nume: 'Jupa',
        comuna: 'Caransebes'
      },
      {
        nume: 'Iabalcea',
        comuna: 'Carasova'
      },
      {
        nume: 'Nermed',
        comuna: 'Carasova'
      },
      {
        nume: 'Stinăpari',
        simplu: 'Stinapari',
        comuna: 'Carbunari'
      },
      {
        nume: 'Ilidia',
        comuna: 'Ciclova Romana'
      },
      {
        nume: 'Socolari',
        comuna: 'Ciclova Romana'
      },
      {
        nume: 'Macovişte',
        simplu: 'Macoviste',
        comuna: 'Ciuchici'
      },
      {
        nume: 'Nicolinţ',
        simplu: 'Nicolint',
        comuna: 'Ciuchici'
      },
      {
        nume: 'Petrilova',
        comuna: 'Ciuchici'
      },
      {
        nume: 'Jitin',
        comuna: 'Ciudanovita'
      },
      {
        nume: 'Căvăran',
        simplu: 'Cavaran',
        comuna: 'Constantin Daicoviciu'
      },
      {
        nume: 'Maciova',
        comuna: 'Constantin Daicoviciu'
      },
      {
        nume: 'Mâtnicu Mare',
        simplu: 'Matnicu Mare',
        comuna: 'Constantin Daicoviciu'
      },
      {
        nume: 'Peştere',
        simplu: 'Pestere',
        comuna: 'Constantin Daicoviciu'
      },
      {
        nume: 'Prisaca',
        comuna: 'Constantin Daicoviciu'
      },
      {
        nume: 'Zăgujeni',
        simplu: 'Zagujeni',
        comuna: 'Constantin Daicoviciu'
      },
      {
        nume: 'Ohaba-Mâtnic',
        simplu: 'Ohaba-Matnic',
        comuna: 'Copacele'
      },
      {
        nume: 'Ruginosu',
        comuna: 'Copacele'
      },
      {
        nume: 'Zorile',
        comuna: 'Copacele'
      },
      {
        nume: 'Cruşovăţ',
        simplu: 'Crusovat',
        comuna: 'Cornea'
      },
      {
        nume: 'Cuptoare',
        comuna: 'Cornea'
      },
      {
        nume: 'Macovişte',
        simplu: 'Macoviste',
        comuna: 'Cornea'
      },
      {
        nume: 'Arsuri',
        comuna: 'Cornereva'
      },
      {
        nume: 'Bogâltin',
        simplu: 'Bogaltin',
        comuna: 'Cornereva'
      },
      {
        nume: 'Bojia',
        comuna: 'Cornereva'
      },
      {
        nume: 'Borugi',
        comuna: 'Cornereva'
      },
      {
        nume: 'Camena',
        comuna: 'Cornereva'
      },
      {
        nume: 'Cireşel',
        simplu: 'Ciresel',
        comuna: 'Cornereva'
      },
      {
        nume: 'Costiş',
        simplu: 'Costis',
        comuna: 'Cornereva'
      },
      {
        nume: 'Cozia',
        comuna: 'Cornereva'
      },
      {
        nume: 'Cracu Mare',
        comuna: 'Cornereva'
      },
      {
        nume: 'Cracu Teiului',
        comuna: 'Cornereva'
      },
      {
        nume: 'Dobraia',
        comuna: 'Cornereva'
      },
      {
        nume: 'Dolina',
        comuna: 'Cornereva'
      },
      {
        nume: 'Gruni',
        comuna: 'Cornereva'
      },
      {
        nume: 'Hora Mare',
        comuna: 'Cornereva'
      },
      {
        nume: 'Hora Mică',
        simplu: 'Hora Mica',
        comuna: 'Cornereva'
      },
      {
        nume: 'Ineleţ',
        simplu: 'Inelet',
        comuna: 'Cornereva'
      },
      {
        nume: 'Izvor',
        comuna: 'Cornereva'
      },
      {
        nume: 'Lunca Florii',
        comuna: 'Cornereva'
      },
      {
        nume: 'Lunca Zaicii',
        comuna: 'Cornereva'
      },
      {
        nume: 'Mesteacăn',
        simplu: 'Mesteacan',
        comuna: 'Cornereva'
      },
      {
        nume: 'Negiudin',
        comuna: 'Cornereva'
      },
      {
        nume: 'Obiţa',
        simplu: 'Obita',
        comuna: 'Cornereva'
      },
      {
        nume: 'Pogara',
        comuna: 'Cornereva'
      },
      {
        nume: 'Pogara de Sus',
        comuna: 'Cornereva'
      },
      {
        nume: 'Poiana Lungă',
        simplu: 'Poiana Lunga',
        comuna: 'Cornereva'
      },
      {
        nume: 'Prisacina',
        comuna: 'Cornereva'
      },
      {
        nume: 'Prislop',
        comuna: 'Cornereva'
      },
      {
        nume: 'Ruştin',
        simplu: 'Rustin',
        comuna: 'Cornereva'
      },
      {
        nume: 'Scărişoara',
        simplu: 'Scarisoara',
        comuna: 'Cornereva'
      },
      {
        nume: 'Strugasca',
        comuna: 'Cornereva'
      },
      {
        nume: 'Studena',
        comuna: 'Cornereva'
      },
      {
        nume: 'Sub Crâng',
        simplu: 'Sub Crang',
        comuna: 'Cornereva'
      },
      {
        nume: 'Sub Plai',
        comuna: 'Cornereva'
      },
      {
        nume: 'Topla',
        comuna: 'Cornereva'
      },
      {
        nume: 'Zbegu',
        comuna: 'Cornereva'
      },
      {
        nume: 'Zmogotin',
        comuna: 'Cornereva'
      },
      {
        nume: 'Zoina',
        comuna: 'Cornereva'
      },
      {
        nume: 'Zănogi',
        simplu: 'Zanogi',
        comuna: 'Cornereva'
      },
      {
        nume: 'Taţu',
        simplu: 'Tatu',
        comuna: 'Cornereva'
      },
      {
        nume: 'Sfânta Elena',
        simplu: 'Sfanta Elena',
        comuna: 'Coronini'
      },
      {
        nume: 'Boina',
        comuna: 'Dalboset'
      },
      {
        nume: 'Boiniţa',
        simplu: 'Boinita',
        comuna: 'Dalboset'
      },
      {
        nume: 'Bârz',
        simplu: 'Barz',
        comuna: 'Dalboset'
      },
      {
        nume: 'Prislop',
        comuna: 'Dalboset'
      },
      {
        nume: 'Reşiţa Mică',
        simplu: 'Resita Mica',
        comuna: 'Dalboset'
      },
      {
        nume: 'Sopotu Vechi',
        simplu: 'Sopotu Vechi',
        comuna: 'Dalboset'
      },
      {
        nume: 'Biniş',
        simplu: 'Binis',
        comuna: 'Doclin'
      },
      {
        nume: 'Tirol',
        comuna: 'Doclin'
      },
      {
        nume: 'Calina',
        comuna: 'Dognecea'
      },
      {
        nume: 'Cănicea',
        simplu: 'Canicea',
        comuna: 'Domasnea'
      },
      {
        nume: 'Soceni',
        comuna: 'Ezeris'
      },
      {
        nume: 'Dezeşti',
        simplu: 'Dezesti',
        comuna: 'Farliug'
      },
      {
        nume: 'Duleu',
        comuna: 'Farliug'
      },
      {
        nume: 'Remetea-Pogănici',
        simplu: 'Remetea-Poganici',
        comuna: 'Farliug'
      },
      {
        nume: 'Scăiuş',
        simplu: 'Scaius',
        comuna: 'Farliug'
      },
      {
        nume: 'Valea Mare',
        comuna: 'Farliug'
      },
      {
        nume: 'Brezon',
        comuna: 'Forotic'
      },
      {
        nume: 'Comorâşte',
        simplu: 'Comoraste',
        comuna: 'Forotic'
      },
      {
        nume: 'Surducu Mare',
        comuna: 'Forotic'
      },
      {
        nume: 'Padina Matei',
        comuna: 'Garnic'
      },
      {
        nume: 'Giurgiova',
        comuna: 'Goruia'
      },
      {
        nume: 'Gârlişte',
        simplu: 'Garliste',
        comuna: 'Goruia'
      },
      {
        nume: 'Greoni',
        comuna: 'Gradinari'
      },
      {
        nume: 'Globu Craiovei',
        comuna: 'Iablanita'
      },
      {
        nume: 'Petnic',
        comuna: 'Iablanita'
      },
      {
        nume: 'Pârvova',
        simplu: 'Parvova',
        comuna: 'Lapusnicel'
      },
      {
        nume: 'Sumiţa',
        simplu: 'Sumita',
        comuna: 'Lapusnicel'
      },
      {
        nume: 'Moceriş',
        simplu: 'Moceris',
        comuna: 'Lapusnicu Mare'
      },
      {
        nume: 'Verendin',
        comuna: 'Luncavita'
      },
      {
        nume: 'Clocotici',
        comuna: 'Lupac'
      },
      {
        nume: 'Rafnic',
        comuna: 'Lupac'
      },
      {
        nume: 'Vodnic',
        comuna: 'Lupac'
      },
      {
        nume: 'Vama Marga',
        comuna: 'Marga'
      },
      {
        nume: 'Soşdea',
        simplu: 'Sosdea',
        comuna: 'Maureni'
      },
      {
        nume: 'Globurău',
        simplu: 'Globurau',
        comuna: 'Mehadia'
      },
      {
        nume: 'Plugova',
        comuna: 'Mehadia'
      },
      {
        nume: 'Valea Bolvaşniţa',
        simplu: 'Valea Bolvasnita',
        comuna: 'Mehadia'
      },
      {
        nume: 'Moldova Veche',
        comuna: 'Moldova Noua'
      },
      {
        nume: 'Moldoviţa',
        simplu: 'Moldovita',
        comuna: 'Moldova Noua'
      },
      {
        nume: 'Măceşti',
        simplu: 'Macesti',
        comuna: 'Moldova Noua'
      },
      {
        nume: 'Lescoviţa',
        simplu: 'Lescovita',
        comuna: 'Naidas'
      },
      {
        nume: 'Ciuta',
        comuna: 'Obreja'
      },
      {
        nume: 'Iaz',
        comuna: 'Obreja'
      },
      {
        nume: 'Var',
        comuna: 'Obreja'
      },
      {
        nume: 'Agadici',
        comuna: 'Oravita'
      },
      {
        nume: 'Broşteni',
        simplu: 'Brosteni',
        comuna: 'Oravita'
      },
      {
        nume: 'Brădişoru de Jos',
        simplu: 'Bradisoru de Jos',
        comuna: 'Oravita'
      },
      {
        nume: 'Ciclova Montană',
        simplu: 'Ciclova Montana',
        comuna: 'Oravita'
      },
      {
        nume: 'Marila',
        comuna: 'Oravita'
      },
      {
        nume: 'Răchitova',
        simplu: 'Rachitova',
        comuna: 'Oravita'
      },
      {
        nume: 'Cireşa',
        simplu: 'Ciresa',
        comuna: 'Otelu Rosu'
      },
      {
        nume: 'Mal',
        comuna: 'Otelu Rosu'
      },
      {
        nume: 'Cornuţel',
        simplu: 'Cornutel',
        comuna: 'Paltinis'
      },
      {
        nume: 'Delineşti',
        simplu: 'Delinesti',
        comuna: 'Paltinis'
      },
      {
        nume: 'Ohabiţa',
        simplu: 'Ohabita',
        comuna: 'Paltinis'
      },
      {
        nume: 'Rugi',
        comuna: 'Paltinis'
      },
      {
        nume: 'Belobreşca',
        simplu: 'Belobresca',
        comuna: 'Pojejena'
      },
      {
        nume: 'Divici',
        comuna: 'Pojejena'
      },
      {
        nume: 'Radimna',
        comuna: 'Pojejena'
      },
      {
        nume: 'Suşca',
        simplu: 'Susca',
        comuna: 'Pojejena'
      },
      {
        nume: 'Borlovenii Noi',
        comuna: 'Prigor'
      },
      {
        nume: 'Borlovenii Vechi',
        comuna: 'Prigor'
      },
      {
        nume: 'Putna',
        comuna: 'Prigor'
      },
      {
        nume: 'Pătaş',
        simplu: 'Patas',
        comuna: 'Prigor'
      },
      {
        nume: 'Vrăniuţ',
        simplu: 'Vraniut',
        comuna: 'Racasdia'
      },
      {
        nume: 'Bărbosu',
        simplu: 'Barbosu',
        comuna: 'Ramna'
      },
      {
        nume: 'Valeapai',
        comuna: 'Ramna'
      },
      {
        nume: 'Cuptoare',
        comuna: 'Resita'
      },
      {
        nume: 'Câlnic',
        simplu: 'Calnic',
        comuna: 'Resita'
      },
      {
        nume: 'Doman',
        comuna: 'Resita'
      },
      {
        nume: 'Moniom',
        comuna: 'Resita'
      },
      {
        nume: 'Secu',
        comuna: 'Resita'
      },
      {
        nume: 'Terova',
        simplu: 'Terova',
        comuna: 'Resita'
      },
      {
        nume: 'Ruşchiţa',
        simplu: 'Ruschita',
        comuna: 'Rusca Montana'
      },
      {
        nume: 'Sălbăgelu Nou',
        simplu: 'Salbagelu Nou',
        comuna: 'Sacu'
      },
      {
        nume: 'Tincova',
        comuna: 'Sacu'
      },
      {
        nume: 'Bogodinţ',
        simplu: 'Bogodint',
        comuna: 'Sasca Montana'
      },
      {
        nume: 'Potoc',
        comuna: 'Sasca Montana'
      },
      {
        nume: 'Sasca Română',
        simplu: 'Sasca Romana',
        comuna: 'Sasca Montana'
      },
      {
        nume: 'Slatina-Nera',
        comuna: 'Sasca Montana'
      },
      {
        nume: 'Brestelnic',
        comuna: 'Sichevita'
      },
      {
        nume: 'Cameniţa',
        simplu: 'Camenita',
        comuna: 'Sichevita'
      },
      {
        nume: 'Cracu Almăj',
        simplu: 'Cracu Almaj',
        comuna: 'Sichevita'
      },
      {
        nume: 'Cruşoviţa',
        simplu: 'Crusovita',
        comuna: 'Sichevita'
      },
      {
        nume: 'Curmătura',
        simplu: 'Curmatura',
        comuna: 'Sichevita'
      },
      {
        nume: 'Cârşie',
        simplu: 'Carsie',
        comuna: 'Sichevita'
      },
      {
        nume: 'Frăsiniş',
        simplu: 'Frasinis',
        comuna: 'Sichevita'
      },
      {
        nume: 'Gornea',
        comuna: 'Sichevita'
      },
      {
        nume: 'Liborajdea',
        comuna: 'Sichevita'
      },
      {
        nume: 'Lucacevăţ',
        simplu: 'Lucacevat',
        comuna: 'Sichevita'
      },
      {
        nume: 'Martinovăţ',
        simplu: 'Martinovat',
        comuna: 'Sichevita'
      },
      {
        nume: 'Ogaşu Podului',
        simplu: 'Ogasu Podului',
        comuna: 'Sichevita'
      },
      {
        nume: 'Streneac',
        comuna: 'Sichevita'
      },
      {
        nume: 'Valea Oreviţa',
        simplu: 'Valea Orevita',
        comuna: 'Sichevita'
      },
      {
        nume: 'Valea Ravensca',
        comuna: 'Sichevita'
      },
      {
        nume: 'Valea Sicheviţei',
        simplu: 'Valea Sichevitei',
        comuna: 'Sichevita'
      },
      {
        nume: 'Zănou',
        simplu: 'Zanou',
        comuna: 'Sichevita'
      },
      {
        nume: 'Zăsloane',
        simplu: 'Zasloane',
        comuna: 'Sichevita'
      },
      {
        nume: 'Ilova',
        comuna: 'Slatina-Timis'
      },
      {
        nume: 'Sadova Nouă',
        simplu: 'Sadova Noua',
        comuna: 'Slatina-Timis'
      },
      {
        nume: 'Sadova Veche',
        comuna: 'Slatina-Timis'
      },
      {
        nume: 'Baziaş',
        simplu: 'Bazias',
        comuna: 'Socol'
      },
      {
        nume: 'Câmpia',
        simplu: 'Campia',
        comuna: 'Socol'
      },
      {
        nume: 'Pârneaura',
        simplu: 'Parneaura',
        comuna: 'Socol'
      },
      {
        nume: 'Zlatiţa',
        simplu: 'Zlatita',
        comuna: 'Socol'
      },
      {
        nume: 'Cârşa Roşie',
        simplu: 'Carsa Rosie',
        comuna: 'Sopotu Nou'
      },
      {
        nume: 'Driştie',
        simplu: 'Dristie',
        comuna: 'Sopotu Nou'
      },
      {
        nume: 'Poienile Boinei',
        comuna: 'Sopotu Nou'
      },
      {
        nume: 'Ravensca',
        comuna: 'Sopotu Nou'
      },
      {
        nume: 'Răchita',
        simplu: 'Rachita',
        comuna: 'Sopotu Nou'
      },
      {
        nume: 'Stăncilova',
        simplu: 'Stancilova',
        comuna: 'Sopotu Nou'
      },
      {
        nume: 'Urcu',
        comuna: 'Sopotu Nou'
      },
      {
        nume: 'Valea Roşie',
        simplu: 'Valea Rosie',
        comuna: 'Sopotu Nou'
      },
      {
        nume: 'Valea Răchitei',
        simplu: 'Valea Rachitei',
        comuna: 'Sopotu Nou'
      },
      {
        nume: 'Bratova',
        comuna: 'Tarnova'
      },
      {
        nume: 'Rusca',
        comuna: 'Teregova'
      },
      {
        nume: 'Cârnecea',
        simplu: 'Carnecea',
        comuna: 'Ticvaniu Mare'
      },
      {
        nume: 'Secăşeni',
        simplu: 'Secaseni',
        comuna: 'Ticvaniu Mare'
      },
      {
        nume: 'Ticvaniu Mic',
        comuna: 'Ticvaniu Mare'
      },
      {
        nume: 'Bârza',
        simplu: 'Barza',
        comuna: 'Toplet'
      },
      {
        nume: 'Borlova',
        comuna: 'Turnu Ruieni'
      },
      {
        nume: 'Cicleni',
        comuna: 'Turnu Ruieni'
      },
      {
        nume: 'Dalci',
        comuna: 'Turnu Ruieni'
      },
      {
        nume: 'Zerveşti',
        simplu: 'Zervesti',
        comuna: 'Turnu Ruieni'
      },
      {
        nume: 'Zlagna',
        comuna: 'Turnu Ruieni'
      },
      {
        nume: 'Mercina',
        comuna: 'Varadia'
      },
      {
        nume: 'Ersig',
        comuna: 'Vermes'
      },
      {
        nume: 'Izgar',
        comuna: 'Vermes'
      },
      {
        nume: 'Ciortea',
        comuna: 'Vrani'
      },
      {
        nume: 'Iertof',
        comuna: 'Vrani'
      },
      {
        nume: '23 August',
        comuna: 'Zavoi'
      },
      {
        nume: 'Măgura',
        simplu: 'Magura',
        comuna: 'Zavoi'
      },
      {
        nume: 'Măru',
        simplu: 'Maru',
        comuna: 'Zavoi'
      },
      {
        nume: 'Poiana Mărului',
        simplu: 'Poiana Marului',
        comuna: 'Zavoi'
      },
      {
        nume: 'Valea Bistrei',
        comuna: 'Zavoi'
      },
      {
        nume: 'Voislova',
        comuna: 'Zavoi'
      },
      {
        nume: 'Zorlencior',
        comuna: 'Zorlentu Mare'
      }
    ]
  },
  {
    auto: 'CJ',
    nume: 'Cluj',
    localitati: [
      {
        nume: 'Aghireşu',
        simplu: 'Aghiresu'
      },
      {
        nume: 'Aiton'
      },
      {
        nume: 'Aluniş',
        simplu: 'Alunis'
      },
      {
        nume: 'Apahida'
      },
      {
        nume: 'Baciu'
      },
      {
        nume: 'Beliş',
        simplu: 'Belis'
      },
      {
        nume: 'Bobâlna',
        simplu: 'Bobalna'
      },
      {
        nume: 'Bonţida',
        simplu: 'Bontida'
      },
      {
        nume: 'Borşa',
        simplu: 'Borsa'
      },
      {
        nume: 'Buza'
      },
      {
        nume: 'Băişoara',
        simplu: 'Baisoara'
      },
      {
        nume: 'Ceanu Mare'
      },
      {
        nume: 'Chinteni'
      },
      {
        nume: 'Chiuieşti',
        simplu: 'Chiuiesti'
      },
      {
        nume: 'Ciucea'
      },
      {
        nume: 'Ciurila'
      },
      {
        nume: 'Cluj-Napoca'
      },
      {
        nume: 'Cojocna'
      },
      {
        nume: 'Corneşti',
        simplu: 'Cornesti'
      },
      {
        nume: 'Cuzdrioara'
      },
      {
        nume: 'Câmpia Turzii',
        simplu: 'Campia Turzii'
      },
      {
        nume: 'Câţcău',
        simplu: 'Catcau'
      },
      {
        nume: 'Căianu',
        simplu: 'Caianu'
      },
      {
        nume: 'Călăraşi',
        simplu: 'Calarasi'
      },
      {
        nume: 'Călăţele',
        simplu: 'Calatele'
      },
      {
        nume: 'Cămăraşu',
        simplu: 'Camarasu'
      },
      {
        nume: 'Căpuşu Mare',
        simplu: 'Capusu Mare'
      },
      {
        nume: 'Cătina',
        simplu: 'Catina'
      },
      {
        nume: 'Căşeiu',
        simplu: 'Caseiu'
      },
      {
        nume: 'Dej'
      },
      {
        nume: 'Dăbâca',
        simplu: 'Dabaca'
      },
      {
        nume: 'Feleacu'
      },
      {
        nume: 'Fizeşu Gherlii',
        simplu: 'Fizesu Gherlii'
      },
      {
        nume: 'Floreşti',
        simplu: 'Floresti'
      },
      {
        nume: 'Frata'
      },
      {
        nume: 'Geaca'
      },
      {
        nume: 'Gherla'
      },
      {
        nume: 'Gilău',
        simplu: 'Gilau'
      },
      {
        nume: 'Gârbău',
        simplu: 'Garbau'
      },
      {
        nume: 'Huedin'
      },
      {
        nume: 'Iara'
      },
      {
        nume: 'Iclod'
      },
      {
        nume: 'Izvoru Crişului',
        simplu: 'Izvoru Crisului'
      },
      {
        nume: 'Jichişu de Jos',
        simplu: 'Jichisu de Jos'
      },
      {
        nume: 'Luna'
      },
      {
        nume: 'Mica'
      },
      {
        nume: 'Mihai Viteazu'
      },
      {
        nume: 'Mintiu Gherlii'
      },
      {
        nume: 'Mociu'
      },
      {
        nume: 'Moldoveneşti',
        simplu: 'Moldovenesti'
      },
      {
        nume: 'Măguri-Răcătău',
        simplu: 'Maguri-Racatau'
      },
      {
        nume: 'Mănăstireni',
        simplu: 'Manastireni'
      },
      {
        nume: 'Mărgău',
        simplu: 'Margau'
      },
      {
        nume: 'Mărişel',
        simplu: 'Marisel'
      },
      {
        nume: 'Negreni'
      },
      {
        nume: 'Panticeu'
      },
      {
        nume: 'Petreştii de Jos',
        simplu: 'Petrestii de Jos'
      },
      {
        nume: 'Ploscoş',
        simplu: 'Ploscos'
      },
      {
        nume: 'Poieni'
      },
      {
        nume: 'Pălatca',
        simplu: 'Palatca'
      },
      {
        nume: 'Recea-Cristur'
      },
      {
        nume: 'Rişca',
        simplu: 'Risca'
      },
      {
        nume: 'Sic'
      },
      {
        nume: 'Suatu'
      },
      {
        nume: 'Sâncraiu',
        simplu: 'Sancraiu'
      },
      {
        nume: 'Sânmărtin',
        simplu: 'Sanmartin'
      },
      {
        nume: 'Sânpaul',
        simplu: 'Sanpaul'
      },
      {
        nume: 'Săcuieu',
        simplu: 'Sacuieu'
      },
      {
        nume: 'Sănduleşti',
        simplu: 'Sandulesti'
      },
      {
        nume: 'Săvădisla',
        simplu: 'Savadisla'
      },
      {
        nume: 'Tritenii de Jos'
      },
      {
        nume: 'Turda'
      },
      {
        nume: 'Tureni'
      },
      {
        nume: 'Unguraş',
        simplu: 'Unguras'
      },
      {
        nume: 'Vad'
      },
      {
        nume: 'Valea Ierii'
      },
      {
        nume: 'Viişoara',
        simplu: 'Viisoara'
      },
      {
        nume: 'Vultureni'
      },
      {
        nume: 'Taga',
        simplu: 'Taga'
      },
      {
        nume: 'Aghireşu-Fabrici',
        simplu: 'Aghiresu-Fabrici',
        comuna: 'Aghiresu'
      },
      {
        nume: 'Arghişu',
        simplu: 'Arghisu',
        comuna: 'Aghiresu'
      },
      {
        nume: 'Băgara',
        simplu: 'Bagara',
        comuna: 'Aghiresu'
      },
      {
        nume: 'Dorolţu',
        simplu: 'Doroltu',
        comuna: 'Aghiresu'
      },
      {
        nume: 'Dâncu',
        simplu: 'Dancu',
        comuna: 'Aghiresu'
      },
      {
        nume: 'Inucu',
        comuna: 'Aghiresu'
      },
      {
        nume: 'Leghia',
        comuna: 'Aghiresu'
      },
      {
        nume: 'Macău',
        simplu: 'Macau',
        comuna: 'Aghiresu'
      },
      {
        nume: 'Ticu',
        comuna: 'Aghiresu'
      },
      {
        nume: 'Ticu-Colonie',
        comuna: 'Aghiresu'
      },
      {
        nume: 'Rediu',
        comuna: 'Aiton'
      },
      {
        nume: 'Corneni',
        comuna: 'Alunis'
      },
      {
        nume: 'Ghirolt',
        comuna: 'Alunis'
      },
      {
        nume: 'Pruneni',
        comuna: 'Alunis'
      },
      {
        nume: 'Vale',
        comuna: 'Alunis'
      },
      {
        nume: 'Bodrog',
        comuna: 'Apahida'
      },
      {
        nume: 'Corpadea',
        comuna: 'Apahida'
      },
      {
        nume: 'Câmpeneşti',
        simplu: 'Campenesti',
        comuna: 'Apahida'
      },
      {
        nume: 'Dezmir',
        comuna: 'Apahida'
      },
      {
        nume: 'Pata',
        comuna: 'Apahida'
      },
      {
        nume: 'Sub Coastă',
        simplu: 'Sub Coasta',
        comuna: 'Apahida'
      },
      {
        nume: 'Sânnicoară',
        simplu: 'Sannicoara',
        comuna: 'Apahida'
      },
      {
        nume: 'Cristorel',
        comuna: 'Aschileu'
      },
      {
        nume: 'Dorna',
        comuna: 'Aschileu'
      },
      {
        nume: 'Fodora',
        comuna: 'Aschileu'
      },
      {
        nume: 'Ăşchileu Mare',
        simplu: 'Aschileu Mare',
        comuna: 'Aschileu'
      },
      {
        nume: 'Ăşchileu Mic',
        simplu: 'Aschileu Mic',
        comuna: 'Aschileu'
      },
      {
        nume: 'Coruşu',
        simplu: 'Corusu',
        comuna: 'Baciu'
      },
      {
        nume: 'Mera',
        comuna: 'Baciu'
      },
      {
        nume: 'Popeşti',
        simplu: 'Popesti',
        comuna: 'Baciu'
      },
      {
        nume: 'Rădaia',
        simplu: 'Radaia',
        comuna: 'Baciu'
      },
      {
        nume: 'Suceagu',
        comuna: 'Baciu'
      },
      {
        nume: 'Săliştea Nouă',
        simplu: 'Salistea Noua',
        comuna: 'Baciu'
      },
      {
        nume: 'Frăsinet',
        simplu: 'Frasinet',
        comuna: 'Baisoara'
      },
      {
        nume: 'Moara de Pădure',
        simplu: 'Moara de Padure',
        comuna: 'Baisoara'
      },
      {
        nume: 'Muntele Bocului',
        comuna: 'Baisoara'
      },
      {
        nume: 'Muntele Băişorii',
        simplu: 'Muntele Baisorii',
        comuna: 'Baisoara'
      },
      {
        nume: 'Muntele Cacovei',
        comuna: 'Baisoara'
      },
      {
        nume: 'Muntele Filii',
        comuna: 'Baisoara'
      },
      {
        nume: 'Muntele Săcelului',
        simplu: 'Muntele Sacelului',
        comuna: 'Baisoara'
      },
      {
        nume: 'Săcel',
        simplu: 'Sacel',
        comuna: 'Baisoara'
      },
      {
        nume: 'Bălceşti',
        simplu: 'Balcesti',
        comuna: 'Belis'
      },
      {
        nume: 'Dealu Botii',
        comuna: 'Belis'
      },
      {
        nume: 'Giurcuţa de Jos',
        simplu: 'Giurcuta de Jos',
        comuna: 'Belis'
      },
      {
        nume: 'Giurcuţa de Sus',
        simplu: 'Giurcuta de Sus',
        comuna: 'Belis'
      },
      {
        nume: 'Poiana Horea',
        comuna: 'Belis'
      },
      {
        nume: 'Smida',
        comuna: 'Belis'
      },
      {
        nume: 'Antăş',
        simplu: 'Antas',
        comuna: 'Bobalna'
      },
      {
        nume: 'Blidăreşti',
        simplu: 'Blidaresti',
        comuna: 'Bobalna'
      },
      {
        nume: 'Băbdiu',
        simplu: 'Babdiu',
        comuna: 'Bobalna'
      },
      {
        nume: 'Cremenea',
        comuna: 'Bobalna'
      },
      {
        nume: 'Maia',
        comuna: 'Bobalna'
      },
      {
        nume: 'Oşorhel',
        simplu: 'Osorhel',
        comuna: 'Bobalna'
      },
      {
        nume: 'Pruni',
        comuna: 'Bobalna'
      },
      {
        nume: 'Răzbuneni',
        simplu: 'Razbuneni',
        comuna: 'Bobalna'
      },
      {
        nume: 'Suarăş',
        simplu: 'Suaras',
        comuna: 'Bobalna'
      },
      {
        nume: 'Vâlcelele',
        simplu: 'Valcelele',
        comuna: 'Bobalna'
      },
      {
        nume: 'Coasta',
        comuna: 'Bontida'
      },
      {
        nume: 'Răscruci',
        simplu: 'Rascruci',
        comuna: 'Bontida'
      },
      {
        nume: 'Tăuşeni',
        simplu: 'Tauseni',
        comuna: 'Bontida'
      },
      {
        nume: 'Borşa-Crestaia',
        simplu: 'Borsa-Crestaia',
        comuna: 'Borsa'
      },
      {
        nume: 'Borşa-Cătun',
        simplu: 'Borsa-Catun',
        comuna: 'Borsa'
      },
      {
        nume: 'Ciumăfaia',
        simplu: 'Ciumafaia',
        comuna: 'Borsa'
      },
      {
        nume: 'Giula',
        comuna: 'Borsa'
      },
      {
        nume: 'Rotunda',
        comuna: 'Buza'
      },
      {
        nume: 'Bărăi',
        simplu: 'Barai',
        comuna: 'Caianu'
      },
      {
        nume: 'Căianu Mic',
        simplu: 'Caianu Mic',
        comuna: 'Caianu'
      },
      {
        nume: 'Căianu-Vamă',
        simplu: 'Caianu-Vama',
        comuna: 'Caianu'
      },
      {
        nume: 'Vaida-Cămăraş',
        simplu: 'Vaida-Camaras',
        comuna: 'Caianu'
      },
      {
        nume: 'Văleni',
        simplu: 'Valeni',
        comuna: 'Caianu'
      },
      {
        nume: 'Bogata',
        comuna: 'Calarasi'
      },
      {
        nume: 'Călăraşi-Gară',
        simplu: 'Calarasi-Gara',
        comuna: 'Calarasi'
      },
      {
        nume: 'Călata',
        simplu: 'Calata',
        comuna: 'Calatele'
      },
      {
        nume: 'Dealu Negru',
        comuna: 'Calatele'
      },
      {
        nume: 'Finciu',
        comuna: 'Calatele'
      },
      {
        nume: 'Văleni',
        simplu: 'Valeni',
        comuna: 'Calatele'
      },
      {
        nume: 'Năoiu',
        simplu: 'Naoiu',
        comuna: 'Camarasu'
      },
      {
        nume: 'Sâmboleni',
        simplu: 'Samboleni',
        comuna: 'Camarasu'
      },
      {
        nume: 'Agârbiciu',
        simplu: 'Agarbiciu',
        comuna: 'Capusu Mare'
      },
      {
        nume: 'Bălceşti',
        simplu: 'Balcesti',
        comuna: 'Capusu Mare'
      },
      {
        nume: 'Căpuşu Mic',
        simplu: 'Capusu Mic',
        comuna: 'Capusu Mare'
      },
      {
        nume: 'Dumbrava',
        comuna: 'Capusu Mare'
      },
      {
        nume: 'Dângău Mare',
        simplu: 'Dangau Mare',
        comuna: 'Capusu Mare'
      },
      {
        nume: 'Dângău Mic',
        simplu: 'Dangau Mic',
        comuna: 'Capusu Mare'
      },
      {
        nume: 'Păniceni',
        simplu: 'Paniceni',
        comuna: 'Capusu Mare'
      },
      {
        nume: 'Straja',
        comuna: 'Capusu Mare'
      },
      {
        nume: 'Comorâţa',
        simplu: 'Comorata',
        comuna: 'Caseiu'
      },
      {
        nume: 'Coplean',
        comuna: 'Caseiu'
      },
      {
        nume: 'Custura',
        comuna: 'Caseiu'
      },
      {
        nume: 'Guga',
        comuna: 'Caseiu'
      },
      {
        nume: 'Gârbău Dejului',
        simplu: 'Garbau Dejului',
        comuna: 'Caseiu'
      },
      {
        nume: 'Leurda',
        comuna: 'Caseiu'
      },
      {
        nume: 'Rugăşeşti',
        simplu: 'Rugasesti',
        comuna: 'Caseiu'
      },
      {
        nume: 'Sălătruc',
        simplu: 'Salatruc',
        comuna: 'Caseiu'
      },
      {
        nume: 'Urişor',
        simplu: 'Urisor',
        comuna: 'Caseiu'
      },
      {
        nume: 'Muncel',
        comuna: 'Catcau'
      },
      {
        nume: 'Sălişca',
        simplu: 'Salisca',
        comuna: 'Catcau'
      },
      {
        nume: 'Copru',
        comuna: 'Catina'
      },
      {
        nume: 'Feldioara',
        comuna: 'Catina'
      },
      {
        nume: 'Hagău',
        simplu: 'Hagau',
        comuna: 'Catina'
      },
      {
        nume: 'Hodaie',
        comuna: 'Catina'
      },
      {
        nume: 'Valea Caldă',
        simplu: 'Valea Calda',
        comuna: 'Catina'
      },
      {
        nume: 'Andici',
        comuna: 'Ceanu Mare'
      },
      {
        nume: 'Boian',
        comuna: 'Ceanu Mare'
      },
      {
        nume: 'Bolduţ',
        simplu: 'Boldut',
        comuna: 'Ceanu Mare'
      },
      {
        nume: 'Ciurgău',
        simplu: 'Ciurgau',
        comuna: 'Ceanu Mare'
      },
      {
        nume: 'Dosu Napului',
        comuna: 'Ceanu Mare'
      },
      {
        nume: 'Fânaţe',
        simplu: 'Fanate',
        comuna: 'Ceanu Mare'
      },
      {
        nume: 'Hodăi Boian',
        simplu: 'Hodai Boian',
        comuna: 'Ceanu Mare'
      },
      {
        nume: 'Iacobeni',
        comuna: 'Ceanu Mare'
      },
      {
        nume: 'Morţeşti',
        simplu: 'Mortesti',
        comuna: 'Ceanu Mare'
      },
      {
        nume: 'Strucut',
        comuna: 'Ceanu Mare'
      },
      {
        nume: 'Stârcu',
        simplu: 'Starcu',
        comuna: 'Ceanu Mare'
      },
      {
        nume: 'Valea lui Cati',
        comuna: 'Ceanu Mare'
      },
      {
        nume: 'Deuşu',
        simplu: 'Deusu',
        comuna: 'Chinteni'
      },
      {
        nume: 'Feiurdeni',
        comuna: 'Chinteni'
      },
      {
        nume: 'Măcicaşu',
        simplu: 'Macicasu',
        comuna: 'Chinteni'
      },
      {
        nume: 'Pădureni',
        simplu: 'Padureni',
        comuna: 'Chinteni'
      },
      {
        nume: 'Satu Lung',
        comuna: 'Chinteni'
      },
      {
        nume: 'Sânmărtin',
        simplu: 'Sanmartin',
        comuna: 'Chinteni'
      },
      {
        nume: 'Săliştea Veche',
        simplu: 'Salistea Veche',
        comuna: 'Chinteni'
      },
      {
        nume: 'Vechea',
        comuna: 'Chinteni'
      },
      {
        nume: 'Dosu Bricii',
        comuna: 'Chiuiesti'
      },
      {
        nume: 'Huta',
        comuna: 'Chiuiesti'
      },
      {
        nume: 'Măgoaja',
        simplu: 'Magoaja',
        comuna: 'Chiuiesti'
      },
      {
        nume: 'Strâmbu',
        simplu: 'Strambu',
        comuna: 'Chiuiesti'
      },
      {
        nume: 'Valea Căşeielului',
        simplu: 'Valea Caseielului',
        comuna: 'Chiuiesti'
      },
      {
        nume: 'Valea lui Opriş',
        simplu: 'Valea lui Opris',
        comuna: 'Chiuiesti'
      },
      {
        nume: 'Vânători',
        simplu: 'Vanatori',
        comuna: 'Ciucea'
      },
      {
        nume: 'Filea de Jos',
        comuna: 'Ciurila'
      },
      {
        nume: 'Filea de Sus',
        comuna: 'Ciurila'
      },
      {
        nume: 'Pruniş',
        simplu: 'Prunis',
        comuna: 'Ciurila'
      },
      {
        nume: 'Pădureni',
        simplu: 'Padureni',
        comuna: 'Ciurila'
      },
      {
        nume: 'Sălicea',
        simplu: 'Salicea',
        comuna: 'Ciurila'
      },
      {
        nume: 'Sălişte',
        simplu: 'Saliste',
        comuna: 'Ciurila'
      },
      {
        nume: 'Sutu',
        simplu: 'Sutu',
        comuna: 'Ciurila'
      },
      {
        nume: 'Boj-Cătun',
        simplu: 'Boj-Catun',
        comuna: 'Cojocna'
      },
      {
        nume: 'Boju',
        comuna: 'Cojocna'
      },
      {
        nume: 'Cara',
        comuna: 'Cojocna'
      },
      {
        nume: 'Huci',
        comuna: 'Cojocna'
      },
      {
        nume: 'Iuriu de Câmpie',
        simplu: 'Iuriu de Campie',
        comuna: 'Cojocna'
      },
      {
        nume: 'Morişti',
        simplu: 'Moristi',
        comuna: 'Cojocna'
      },
      {
        nume: 'Straja',
        comuna: 'Cojocna'
      },
      {
        nume: 'Bârlea',
        simplu: 'Barlea',
        comuna: 'Cornesti'
      },
      {
        nume: 'Igriţia',
        simplu: 'Igritia',
        comuna: 'Cornesti'
      },
      {
        nume: 'Lujerdiu',
        comuna: 'Cornesti'
      },
      {
        nume: 'Morău',
        simplu: 'Morau',
        comuna: 'Cornesti'
      },
      {
        nume: 'Stoiana',
        comuna: 'Cornesti'
      },
      {
        nume: 'Tiocu de Jos',
        comuna: 'Cornesti'
      },
      {
        nume: 'Tiocu de Sus',
        comuna: 'Cornesti'
      },
      {
        nume: 'Tioltiur',
        comuna: 'Cornesti'
      },
      {
        nume: 'Mănăşturel',
        simplu: 'Manasturel',
        comuna: 'Cuzdrioara'
      },
      {
        nume: 'Valea Gârboului',
        simplu: 'Valea Garboului',
        comuna: 'Cuzdrioara'
      },
      {
        nume: 'Luna de Jos',
        comuna: 'Dabaca'
      },
      {
        nume: 'Pâglişa',
        simplu: 'Paglisa',
        comuna: 'Dabaca'
      },
      {
        nume: 'Ocna Dejului',
        comuna: 'Dej'
      },
      {
        nume: 'Peştera',
        simplu: 'Pestera',
        comuna: 'Dej'
      },
      {
        nume: 'Pintic',
        comuna: 'Dej'
      },
      {
        nume: 'Somcutu Mic',
        simplu: 'Somcutu Mic',
        comuna: 'Dej'
      },
      {
        nume: 'Casele Miceşti',
        simplu: 'Casele Micesti',
        comuna: 'Feleacu'
      },
      {
        nume: 'Gheorghieni',
        comuna: 'Feleacu'
      },
      {
        nume: 'Sărădiş',
        simplu: 'Saradis',
        comuna: 'Feleacu'
      },
      {
        nume: 'Vâlcele',
        simplu: 'Valcele',
        comuna: 'Feleacu'
      },
      {
        nume: 'Bonţ',
        simplu: 'Bont',
        comuna: 'Fizesu Gherlii'
      },
      {
        nume: 'Lunca Bonţului',
        simplu: 'Lunca Bontului',
        comuna: 'Fizesu Gherlii'
      },
      {
        nume: 'Nicula',
        comuna: 'Fizesu Gherlii'
      },
      {
        nume: 'Săcălaia',
        simplu: 'Sacalaia',
        comuna: 'Fizesu Gherlii'
      },
      {
        nume: 'Luna de Sus',
        comuna: 'Floresti'
      },
      {
        nume: 'Tăuţi',
        simplu: 'Tauti',
        comuna: 'Floresti'
      },
      {
        nume: 'Berchieşu',
        simplu: 'Berchiesu',
        comuna: 'Frata'
      },
      {
        nume: 'Oaş',
        simplu: 'Oas',
        comuna: 'Frata'
      },
      {
        nume: 'Olăriu',
        simplu: 'Olariu',
        comuna: 'Frata'
      },
      {
        nume: 'Poiana Frăţii',
        simplu: 'Poiana Fratii',
        comuna: 'Frata'
      },
      {
        nume: 'Pădurea Iacobeni',
        simplu: 'Padurea Iacobeni',
        comuna: 'Frata'
      },
      {
        nume: 'Răzoare',
        simplu: 'Razoare',
        comuna: 'Frata'
      },
      {
        nume: 'Soporu de Câmpie',
        simplu: 'Soporu de Campie',
        comuna: 'Frata'
      },
      {
        nume: 'Corneşti',
        simplu: 'Cornesti',
        comuna: 'Garbau'
      },
      {
        nume: 'Nădăşelu',
        simplu: 'Nadaselu',
        comuna: 'Garbau'
      },
      {
        nume: 'Turea',
        comuna: 'Garbau'
      },
      {
        nume: 'Viştea',
        simplu: 'Vistea',
        comuna: 'Garbau'
      },
      {
        nume: 'Chiriş',
        simplu: 'Chiris',
        comuna: 'Geaca'
      },
      {
        nume: 'Lacu',
        comuna: 'Geaca'
      },
      {
        nume: 'Legii',
        comuna: 'Geaca'
      },
      {
        nume: 'Puini',
        comuna: 'Geaca'
      },
      {
        nume: 'Sucutard',
        comuna: 'Geaca'
      },
      {
        nume: 'Băiţa',
        simplu: 'Baita',
        comuna: 'Gherla'
      },
      {
        nume: 'Hăşdate',
        simplu: 'Hasdate',
        comuna: 'Gherla'
      },
      {
        nume: 'Silivaş',
        simplu: 'Silivas',
        comuna: 'Gherla'
      },
      {
        nume: 'Someşu Cald',
        simplu: 'Somesu Cald',
        comuna: 'Gilau'
      },
      {
        nume: 'Someşu Rece',
        simplu: 'Somesu Rece',
        comuna: 'Gilau'
      },
      {
        nume: 'Bicălatu',
        simplu: 'Bicalatu',
        comuna: 'Huedin'
      },
      {
        nume: 'Agriş',
        simplu: 'Agris',
        comuna: 'Iara'
      },
      {
        nume: 'Borzeşti',
        simplu: 'Borzesti',
        comuna: 'Iara'
      },
      {
        nume: 'Buru',
        comuna: 'Iara'
      },
      {
        nume: 'Cacova Ierii',
        comuna: 'Iara'
      },
      {
        nume: 'Făgetu Ierii',
        simplu: 'Fagetu Ierii',
        comuna: 'Iara'
      },
      {
        nume: 'Lungeşti',
        simplu: 'Lungesti',
        comuna: 'Iara'
      },
      {
        nume: 'Maşca',
        simplu: 'Masca',
        comuna: 'Iara'
      },
      {
        nume: 'Măgura Ierii',
        simplu: 'Magura Ierii',
        comuna: 'Iara'
      },
      {
        nume: 'Ocolişel',
        simplu: 'Ocolisel',
        comuna: 'Iara'
      },
      {
        nume: 'Surduc',
        comuna: 'Iara'
      },
      {
        nume: 'Valea Agrişului',
        simplu: 'Valea Agrisului',
        comuna: 'Iara'
      },
      {
        nume: 'Valea Vadului',
        comuna: 'Iara'
      },
      {
        nume: 'Fundătura',
        simplu: 'Fundatura',
        comuna: 'Iclod'
      },
      {
        nume: 'Iclozel',
        comuna: 'Iclod'
      },
      {
        nume: 'Livada',
        comuna: 'Iclod'
      },
      {
        nume: 'Orman',
        comuna: 'Iclod'
      },
      {
        nume: 'Nadăşu',
        simplu: 'Nadasu',
        comuna: 'Izvoru Crisului'
      },
      {
        nume: 'Nearşova',
        simplu: 'Nearsova',
        comuna: 'Izvoru Crisului'
      },
      {
        nume: 'Saula',
        simplu: 'Saula',
        comuna: 'Izvoru Crisului'
      },
      {
        nume: 'Codor',
        comuna: 'Jichisu De Jos'
      },
      {
        nume: 'Jichişu de Sus',
        simplu: 'Jichisu de Sus',
        comuna: 'Jichisu De Jos'
      },
      {
        nume: 'Tărpiu',
        simplu: 'Tarpiu',
        comuna: 'Jichisu De Jos'
      },
      {
        nume: 'Sigău',
        simplu: 'Sigau',
        comuna: 'Jichisu De Jos'
      },
      {
        nume: 'Gădălin',
        simplu: 'Gadalin',
        comuna: 'Jucu'
      },
      {
        nume: 'Juc-Herghelie',
        comuna: 'Jucu'
      },
      {
        nume: 'Jucu de Mijloc',
        comuna: 'Jucu'
      },
      {
        nume: 'Jucu de Sus',
        comuna: 'Jucu'
      },
      {
        nume: 'Vişea',
        simplu: 'Visea',
        comuna: 'Jucu'
      },
      {
        nume: 'Gligoreşti',
        simplu: 'Gligoresti',
        comuna: 'Luna'
      },
      {
        nume: 'Luncani',
        comuna: 'Luna'
      },
      {
        nume: 'Muntele Rece',
        comuna: 'Maguri-Racatau'
      },
      {
        nume: 'Măguri',
        simplu: 'Maguri',
        comuna: 'Maguri-Racatau'
      },
      {
        nume: 'Ardeova',
        comuna: 'Manastireni'
      },
      {
        nume: 'Bedeciu',
        comuna: 'Manastireni'
      },
      {
        nume: 'Bica',
        comuna: 'Manastireni'
      },
      {
        nume: 'Dretea',
        comuna: 'Manastireni'
      },
      {
        nume: 'Mănăşturu Românesc',
        simplu: 'Manasturu Romanesc',
        comuna: 'Manastireni'
      },
      {
        nume: 'Bociu',
        comuna: 'Margau'
      },
      {
        nume: 'Buteni',
        comuna: 'Margau'
      },
      {
        nume: 'Ciuleni',
        comuna: 'Margau'
      },
      {
        nume: 'Răchiţele',
        simplu: 'Rachitele',
        comuna: 'Margau'
      },
      {
        nume: 'Scrind-Frăsinet',
        simplu: 'Scrind-Frasinet',
        comuna: 'Margau'
      },
      {
        nume: 'Dâmbu Mare',
        simplu: 'Dambu Mare',
        comuna: 'Mica'
      },
      {
        nume: 'Mănăstirea',
        simplu: 'Manastirea',
        comuna: 'Mica'
      },
      {
        nume: 'Nireş',
        simplu: 'Nires',
        comuna: 'Mica'
      },
      {
        nume: 'Sânmărghita',
        simplu: 'Sanmarghita',
        comuna: 'Mica'
      },
      {
        nume: 'Valea Cireşoii',
        simplu: 'Valea Ciresoii',
        comuna: 'Mica'
      },
      {
        nume: 'Valea Luncii',
        comuna: 'Mica'
      },
      {
        nume: 'Cheia',
        comuna: 'Mihai Viteazu'
      },
      {
        nume: 'Corneşti',
        simplu: 'Cornesti',
        comuna: 'Mihai Viteazu'
      },
      {
        nume: 'Buneşti',
        simplu: 'Bunesti',
        comuna: 'Mintiu Gherlii'
      },
      {
        nume: 'Nima',
        comuna: 'Mintiu Gherlii'
      },
      {
        nume: 'Petreşti',
        simplu: 'Petresti',
        comuna: 'Mintiu Gherlii'
      },
      {
        nume: 'Pădurenii',
        simplu: 'Padurenii',
        comuna: 'Mintiu Gherlii'
      },
      {
        nume: 'Salatiu',
        comuna: 'Mintiu Gherlii'
      },
      {
        nume: 'Boteni',
        comuna: 'Mociu'
      },
      {
        nume: 'Chesău',
        simplu: 'Chesau',
        comuna: 'Mociu'
      },
      {
        nume: 'Crişeni',
        simplu: 'Criseni',
        comuna: 'Mociu'
      },
      {
        nume: 'Falca',
        comuna: 'Mociu'
      },
      {
        nume: 'Ghirişu Român',
        simplu: 'Ghirisu Roman',
        comuna: 'Mociu'
      },
      {
        nume: 'Roşieni',
        simplu: 'Rosieni',
        comuna: 'Mociu'
      },
      {
        nume: 'Turmaşi',
        simplu: 'Turmasi',
        comuna: 'Mociu'
      },
      {
        nume: 'Zorenii de Vale',
        comuna: 'Mociu'
      },
      {
        nume: 'Bădeni',
        simplu: 'Badeni',
        comuna: 'Moldovenesti'
      },
      {
        nume: 'Pietroasa',
        comuna: 'Moldovenesti'
      },
      {
        nume: 'Plăieşti',
        simplu: 'Plaiesti',
        comuna: 'Moldovenesti'
      },
      {
        nume: 'Podeni',
        comuna: 'Moldovenesti'
      },
      {
        nume: 'Stejeriş',
        simplu: 'Stejeris',
        comuna: 'Moldovenesti'
      },
      {
        nume: 'Bucea',
        comuna: 'Negreni'
      },
      {
        nume: 'Prelucele',
        comuna: 'Negreni'
      },
      {
        nume: 'Băgaciu',
        simplu: 'Bagaciu',
        comuna: 'Palatca'
      },
      {
        nume: 'Mureşenii de Câmpie',
        simplu: 'Muresenii de Campie',
        comuna: 'Palatca'
      },
      {
        nume: 'Petea',
        comuna: 'Palatca'
      },
      {
        nume: 'Sava',
        comuna: 'Palatca'
      },
      {
        nume: 'Cubleşu Someşan',
        simplu: 'Cublesu Somesan',
        comuna: 'Panticeu'
      },
      {
        nume: 'Cătălina',
        simplu: 'Catalina',
        comuna: 'Panticeu'
      },
      {
        nume: 'Dârja',
        simplu: 'Darja',
        comuna: 'Panticeu'
      },
      {
        nume: 'Sărata',
        simplu: 'Sarata',
        comuna: 'Panticeu'
      },
      {
        nume: 'Crăeşti',
        simplu: 'Craesti',
        comuna: 'Petrestii De Jos'
      },
      {
        nume: 'Deleni',
        comuna: 'Petrestii De Jos'
      },
      {
        nume: 'Livada',
        comuna: 'Petrestii De Jos'
      },
      {
        nume: 'Petreştii de Mijloc',
        simplu: 'Petrestii de Mijloc',
        comuna: 'Petrestii De Jos'
      },
      {
        nume: 'Petreştii de Sus',
        simplu: 'Petrestii de Sus',
        comuna: 'Petrestii De Jos'
      },
      {
        nume: 'Plaiuri',
        comuna: 'Petrestii De Jos'
      },
      {
        nume: 'Crairât',
        simplu: 'Crairat',
        comuna: 'Ploscos'
      },
      {
        nume: 'Lobodaş',
        simplu: 'Lobodas',
        comuna: 'Ploscos'
      },
      {
        nume: 'Valea Florilor',
        comuna: 'Ploscos'
      },
      {
        nume: 'Bologa',
        comuna: 'Poieni'
      },
      {
        nume: 'Cerbeşti',
        simplu: 'Cerbesti',
        comuna: 'Poieni'
      },
      {
        nume: 'Hodişu',
        simplu: 'Hodisu',
        comuna: 'Poieni'
      },
      {
        nume: 'Lunca Vişagului',
        simplu: 'Lunca Visagului',
        comuna: 'Poieni'
      },
      {
        nume: 'Morlaca',
        comuna: 'Poieni'
      },
      {
        nume: 'Tranişu',
        simplu: 'Tranisu',
        comuna: 'Poieni'
      },
      {
        nume: 'Valea Drăganului',
        simplu: 'Valea Draganului',
        comuna: 'Poieni'
      },
      {
        nume: 'Ciubanca',
        comuna: 'Recea-Cristur'
      },
      {
        nume: 'Ciubăncuţa',
        simplu: 'Ciubancuta',
        comuna: 'Recea-Cristur'
      },
      {
        nume: 'Căprioara',
        simplu: 'Caprioara',
        comuna: 'Recea-Cristur'
      },
      {
        nume: 'Elciu',
        comuna: 'Recea-Cristur'
      },
      {
        nume: 'Escu',
        comuna: 'Recea-Cristur'
      },
      {
        nume: 'Jurca',
        comuna: 'Recea-Cristur'
      },
      {
        nume: 'Osoi',
        comuna: 'Recea-Cristur'
      },
      {
        nume: 'Pustuţa',
        simplu: 'Pustuta',
        comuna: 'Recea-Cristur'
      },
      {
        nume: 'Dealu Mare',
        comuna: 'Risca'
      },
      {
        nume: 'Lăpuşteşti',
        simplu: 'Lapustesti',
        comuna: 'Risca'
      },
      {
        nume: 'Mărceşti',
        simplu: 'Marcesti',
        comuna: 'Risca'
      },
      {
        nume: 'Rogojel',
        comuna: 'Sacuieu'
      },
      {
        nume: 'Vişagu',
        simplu: 'Visagu',
        comuna: 'Sacuieu'
      },
      {
        nume: 'Alunişu',
        simplu: 'Alunisu',
        comuna: 'Sancraiu'
      },
      {
        nume: 'Brăişoru',
        simplu: 'Braisoru',
        comuna: 'Sancraiu'
      },
      {
        nume: 'Domoşu',
        simplu: 'Domosu',
        comuna: 'Sancraiu'
      },
      {
        nume: 'Horlacea',
        comuna: 'Sancraiu'
      },
      {
        nume: 'Copăceni',
        simplu: 'Copaceni',
        comuna: 'Sandulesti'
      },
      {
        nume: 'Ceaba',
        comuna: 'Sanmartin'
      },
      {
        nume: 'Cutca',
        comuna: 'Sanmartin'
      },
      {
        nume: 'Diviciorii Mari',
        comuna: 'Sanmartin'
      },
      {
        nume: 'Diviciorii Mici',
        comuna: 'Sanmartin'
      },
      {
        nume: 'Măhal',
        simplu: 'Mahal',
        comuna: 'Sanmartin'
      },
      {
        nume: 'Sâmboieni',
        simplu: 'Samboieni',
        comuna: 'Sanmartin'
      },
      {
        nume: 'Târguşor',
        simplu: 'Targusor',
        comuna: 'Sanmartin'
      },
      {
        nume: 'Berindu',
        comuna: 'Sanpaul'
      },
      {
        nume: 'Mihăieşti',
        simplu: 'Mihaiesti',
        comuna: 'Sanpaul'
      },
      {
        nume: 'Sumurducu',
        comuna: 'Sanpaul'
      },
      {
        nume: 'Topa Mică',
        simplu: 'Topa Mica',
        comuna: 'Sanpaul'
      },
      {
        nume: 'Sardu',
        simplu: 'Sardu',
        comuna: 'Sanpaul'
      },
      {
        nume: 'Finişel',
        simplu: 'Finisel',
        comuna: 'Savadisla'
      },
      {
        nume: 'Hăşdate',
        simplu: 'Hasdate',
        comuna: 'Savadisla'
      },
      {
        nume: 'Lita',
        comuna: 'Savadisla'
      },
      {
        nume: 'Liteni',
        comuna: 'Savadisla'
      },
      {
        nume: 'Stolna',
        comuna: 'Savadisla'
      },
      {
        nume: 'Vlaha',
        comuna: 'Savadisla'
      },
      {
        nume: 'Vălişoara',
        simplu: 'Valisoara',
        comuna: 'Savadisla'
      },
      {
        nume: 'Aruncuta',
        comuna: 'Suatu'
      },
      {
        nume: 'Dâmburile',
        simplu: 'Damburile',
        comuna: 'Suatu'
      },
      {
        nume: 'Năsal',
        simplu: 'Nasal',
        comuna: 'Taga'
      },
      {
        nume: 'Sântejude',
        simplu: 'Santejude',
        comuna: 'Taga'
      },
      {
        nume: 'Sântejude Vale',
        simplu: 'Santejude Vale',
        comuna: 'Taga'
      },
      {
        nume: 'Sântioana',
        simplu: 'Santioana',
        comuna: 'Taga'
      },
      {
        nume: 'Clapa',
        comuna: 'Tritenii De Jos'
      },
      {
        nume: 'Colonia',
        comuna: 'Tritenii De Jos'
      },
      {
        nume: 'Pădureni',
        simplu: 'Padureni',
        comuna: 'Tritenii De Jos'
      },
      {
        nume: 'Tritenii de Sus',
        comuna: 'Tritenii De Jos'
      },
      {
        nume: 'Tritenii-Hotar',
        comuna: 'Tritenii De Jos'
      },
      {
        nume: 'Ceanu Mic',
        comuna: 'Tureni'
      },
      {
        nume: 'Comşeşti',
        simplu: 'Comsesti',
        comuna: 'Tureni'
      },
      {
        nume: 'Miceşti',
        simplu: 'Micesti',
        comuna: 'Tureni'
      },
      {
        nume: 'Mărtineşti',
        simplu: 'Martinesti',
        comuna: 'Tureni'
      },
      {
        nume: 'Batin',
        comuna: 'Unguras'
      },
      {
        nume: 'Daroţ',
        simplu: 'Darot',
        comuna: 'Unguras'
      },
      {
        nume: 'Sicfa',
        comuna: 'Unguras'
      },
      {
        nume: 'Valea Unguraşului',
        simplu: 'Valea Ungurasului',
        comuna: 'Unguras'
      },
      {
        nume: 'Bogata de Jos',
        comuna: 'Vad'
      },
      {
        nume: 'Bogata de Sus',
        comuna: 'Vad'
      },
      {
        nume: 'Calna',
        comuna: 'Vad'
      },
      {
        nume: 'Cetan',
        comuna: 'Vad'
      },
      {
        nume: 'Curtuiuşu Dejului',
        simplu: 'Curtuiusu Dejului',
        comuna: 'Vad'
      },
      {
        nume: 'Valea Groşilor',
        simplu: 'Valea Grosilor',
        comuna: 'Vad'
      },
      {
        nume: 'Cerc',
        comuna: 'Valea Ierii'
      },
      {
        nume: 'Plopi',
        comuna: 'Valea Ierii'
      },
      {
        nume: 'Urca',
        comuna: 'Viisoara'
      },
      {
        nume: 'Băbuţiu',
        simplu: 'Babutiu',
        comuna: 'Vultureni'
      },
      {
        nume: 'Bădeşti',
        simplu: 'Badesti',
        comuna: 'Vultureni'
      },
      {
        nume: 'Chidea',
        comuna: 'Vultureni'
      },
      {
        nume: 'Făureni',
        simplu: 'Faureni',
        comuna: 'Vultureni'
      },
      {
        nume: 'Soimeni',
        simplu: 'Soimeni',
        comuna: 'Vultureni'
      }
    ]
  },
  {
    auto: 'CT',
    nume: 'Constanţa',
    localitati: [
      {
        nume: '23 August'
      },
      {
        nume: 'Adamclisi'
      },
      {
        nume: 'Agigea'
      },
      {
        nume: 'Albeşti',
        simplu: 'Albesti'
      },
      {
        nume: 'Aliman'
      },
      {
        nume: 'Amzacea'
      },
      {
        nume: 'Băneasa',
        simplu: 'Baneasa'
      },
      {
        nume: 'Bărăganu',
        simplu: 'Baraganu'
      },
      {
        nume: 'Castelu'
      },
      {
        nume: 'Cerchezu'
      },
      {
        nume: 'Cernavodă',
        simplu: 'Cernavoda'
      },
      {
        nume: 'Chirnogeni'
      },
      {
        nume: 'Ciobanu'
      },
      {
        nume: 'Ciocârlia',
        simplu: 'Ciocarlia'
      },
      {
        nume: 'Cobadin'
      },
      {
        nume: 'Cogealac'
      },
      {
        nume: 'Comana'
      },
      {
        nume: 'Constanţa',
        simplu: 'Constanta'
      },
      {
        nume: 'Corbu'
      },
      {
        nume: 'Costineşti',
        simplu: 'Costinesti'
      },
      {
        nume: 'Crucea'
      },
      {
        nume: 'Cumpăna',
        simplu: 'Cumpana'
      },
      {
        nume: 'Deleni'
      },
      {
        nume: 'Dobromir'
      },
      {
        nume: 'Dumbrăveni',
        simplu: 'Dumbraveni'
      },
      {
        nume: 'Ghindăreşti',
        simplu: 'Ghindaresti'
      },
      {
        nume: 'Gârliciu',
        simplu: 'Garliciu'
      },
      {
        nume: 'Horia'
      },
      {
        nume: 'Hârşova',
        simplu: 'Harsova'
      },
      {
        nume: 'Independenţa',
        simplu: 'Independenta'
      },
      {
        nume: 'Ion Corvin'
      },
      {
        nume: 'Istria'
      },
      {
        nume: 'Limanu'
      },
      {
        nume: 'Lipniţa',
        simplu: 'Lipnita'
      },
      {
        nume: 'Lumina'
      },
      {
        nume: 'Mangalia'
      },
      {
        nume: 'Medgidia'
      },
      {
        nume: 'Mereni'
      },
      {
        nume: 'Mihai Viteazu'
      },
      {
        nume: 'Mihail Kogălniceanu',
        simplu: 'Mihail Kogalniceanu'
      },
      {
        nume: 'Mircea Vodă',
        simplu: 'Mircea Voda'
      },
      {
        nume: 'Negru Vodă',
        simplu: 'Negru Voda'
      },
      {
        nume: 'Nicolae Bălcescu',
        simplu: 'Nicolae Balcescu'
      },
      {
        nume: 'Năvodari',
        simplu: 'Navodari'
      },
      {
        nume: 'Oltina'
      },
      {
        nume: 'Ostrov'
      },
      {
        nume: 'Ovidiu'
      },
      {
        nume: 'Pantelimon'
      },
      {
        nume: 'Pecineaga'
      },
      {
        nume: 'Peştera',
        simplu: 'Pestera'
      },
      {
        nume: 'Poarta Albă',
        simplu: 'Poarta Alba'
      },
      {
        nume: 'Rasova'
      },
      {
        nume: 'Saraiu'
      },
      {
        nume: 'Seimeni'
      },
      {
        nume: 'Siliştea',
        simplu: 'Silistea'
      },
      {
        nume: 'Săcele',
        simplu: 'Sacele'
      },
      {
        nume: 'Techirghiol'
      },
      {
        nume: 'Topalu'
      },
      {
        nume: 'Topraisar'
      },
      {
        nume: 'Tortoman'
      },
      {
        nume: 'Tuzla'
      },
      {
        nume: 'Târguşor',
        simplu: 'Targusor'
      },
      {
        nume: 'Valu lui Traian'
      },
      {
        nume: 'Vulturu'
      },
      {
        nume: 'Dulceşti',
        simplu: 'Dulcesti',
        comuna: '23 August'
      },
      {
        nume: 'Moşneni',
        simplu: 'Mosneni',
        comuna: '23 August'
      },
      {
        nume: 'Abrud',
        comuna: 'Adamclisi'
      },
      {
        nume: 'Haţeg',
        simplu: 'Hateg',
        comuna: 'Adamclisi'
      },
      {
        nume: 'Urluia',
        comuna: 'Adamclisi'
      },
      {
        nume: 'Zorile',
        comuna: 'Adamclisi'
      },
      {
        nume: 'Lazu',
        comuna: 'Agigea'
      },
      {
        nume: 'Sanatoriul Agigea',
        comuna: 'Agigea'
      },
      {
        nume: 'Staţiunea Zoologică Marină Agigea',
        simplu: 'Statiunea Zoologica Marina Agigea',
        comuna: 'Agigea'
      },
      {
        nume: 'Arsa',
        comuna: 'Albesti'
      },
      {
        nume: 'Coroana',
        comuna: 'Albesti'
      },
      {
        nume: 'Cotu Văii',
        simplu: 'Cotu Vaii',
        comuna: 'Albesti'
      },
      {
        nume: 'Vârtop',
        simplu: 'Vartop',
        comuna: 'Albesti'
      },
      {
        nume: 'Dunăreni',
        simplu: 'Dunareni',
        comuna: 'Aliman'
      },
      {
        nume: 'Floriile',
        comuna: 'Aliman'
      },
      {
        nume: 'Vlahii',
        comuna: 'Aliman'
      },
      {
        nume: 'Casicea',
        comuna: 'Amzacea'
      },
      {
        nume: 'General Scărişoreanu',
        simplu: 'General Scarisoreanu',
        comuna: 'Amzacea'
      },
      {
        nume: 'Făurei',
        simplu: 'Faurei',
        comuna: 'Baneasa'
      },
      {
        nume: 'Negureni',
        comuna: 'Baneasa'
      },
      {
        nume: 'Tudor Vladimirescu',
        comuna: 'Baneasa'
      },
      {
        nume: 'Lanurile',
        comuna: 'Baraganu'
      },
      {
        nume: 'Murfatlar',
        comuna: 'Basarabi'
      },
      {
        nume: 'Siminoc',
        comuna: 'Basarabi'
      },
      {
        nume: 'Cuza Vodă',
        simplu: 'Cuza Voda',
        comuna: 'Castelu'
      },
      {
        nume: 'Nisipari',
        comuna: 'Castelu'
      },
      {
        nume: 'Căscioarele',
        simplu: 'Cascioarele',
        comuna: 'Cerchezu'
      },
      {
        nume: 'Măgura',
        simplu: 'Magura',
        comuna: 'Cerchezu'
      },
      {
        nume: 'Viroaga',
        comuna: 'Cerchezu'
      },
      {
        nume: 'Credinţa',
        simplu: 'Credinta',
        comuna: 'Chirnogeni'
      },
      {
        nume: 'Plopeni',
        comuna: 'Chirnogeni'
      },
      {
        nume: 'Mioriţa',
        simplu: 'Miorita',
        comuna: 'Ciobanu'
      },
      {
        nume: 'Ciocârlia de Sus',
        simplu: 'Ciocarlia de Sus',
        comuna: 'Ciocarlia'
      },
      {
        nume: 'Conacu',
        comuna: 'Cobadin'
      },
      {
        nume: 'Curcani',
        comuna: 'Cobadin'
      },
      {
        nume: 'Negreşti',
        simplu: 'Negresti',
        comuna: 'Cobadin'
      },
      {
        nume: 'Viişoara',
        simplu: 'Viisoara',
        comuna: 'Cobadin'
      },
      {
        nume: 'Fântânele',
        simplu: 'Fantanele',
        comuna: 'Cogealac'
      },
      {
        nume: 'Gura Dobrogei',
        comuna: 'Cogealac'
      },
      {
        nume: 'Râmnicu de Jos',
        simplu: 'Ramnicu de Jos',
        comuna: 'Cogealac'
      },
      {
        nume: 'Râmnicu de Sus',
        simplu: 'Ramnicu de Sus',
        comuna: 'Cogealac'
      },
      {
        nume: 'Tariverde',
        comuna: 'Cogealac'
      },
      {
        nume: 'Pelinu',
        comuna: 'Comana'
      },
      {
        nume: 'Tătaru',
        simplu: 'Tataru',
        comuna: 'Comana'
      },
      {
        nume: 'Mamaia',
        comuna: 'Constanta'
      },
      {
        nume: 'Palazu Mare',
        comuna: 'Constanta'
      },
      {
        nume: 'Luminiţa',
        simplu: 'Luminita',
        comuna: 'Corbu'
      },
      {
        nume: 'Vadu',
        comuna: 'Corbu'
      },
      {
        nume: 'Schitu',
        comuna: 'Costinesti'
      },
      {
        nume: 'Băltăgeşti',
        simplu: 'Baltagesti',
        comuna: 'Crucea'
      },
      {
        nume: 'Crişan',
        simplu: 'Crisan',
        comuna: 'Crucea'
      },
      {
        nume: 'Gălbiori',
        simplu: 'Galbiori',
        comuna: 'Crucea'
      },
      {
        nume: 'Stupina',
        comuna: 'Crucea'
      },
      {
        nume: 'Siriu',
        simplu: 'Siriu',
        comuna: 'Crucea'
      },
      {
        nume: 'Straja',
        comuna: 'Cumpana'
      },
      {
        nume: 'Petroşani',
        simplu: 'Petrosani',
        comuna: 'Deleni'
      },
      {
        nume: 'Pietreni',
        comuna: 'Deleni'
      },
      {
        nume: 'Sipotele',
        simplu: 'Sipotele',
        comuna: 'Deleni'
      },
      {
        nume: 'Cetatea',
        comuna: 'Dobromir'
      },
      {
        nume: 'Dobromiru din Deal',
        comuna: 'Dobromir'
      },
      {
        nume: 'Lespezi',
        comuna: 'Dobromir'
      },
      {
        nume: 'Pădureni',
        simplu: 'Padureni',
        comuna: 'Dobromir'
      },
      {
        nume: 'Văleni',
        simplu: 'Valeni',
        comuna: 'Dobromir'
      },
      {
        nume: 'Furnica',
        comuna: 'Dumbraveni'
      },
      {
        nume: 'Eforie Nord',
        comuna: 'Eforie'
      },
      {
        nume: 'Eforie Sud',
        comuna: 'Eforie'
      },
      {
        nume: 'Vadu Oii',
        comuna: 'Harsova'
      },
      {
        nume: 'Cloşca',
        simplu: 'Closca',
        comuna: 'Horia'
      },
      {
        nume: 'Tichileşti',
        simplu: 'Tichilesti',
        comuna: 'Horia'
      },
      {
        nume: 'Fântâna Mare',
        simplu: 'Fantana Mare',
        comuna: 'Independenta'
      },
      {
        nume: 'Movila Verde',
        comuna: 'Independenta'
      },
      {
        nume: 'Olteni',
        comuna: 'Independenta'
      },
      {
        nume: 'Tufani',
        comuna: 'Independenta'
      },
      {
        nume: 'Brebeni',
        comuna: 'Ion Corvin'
      },
      {
        nume: 'Crângu',
        simplu: 'Crangu',
        comuna: 'Ion Corvin'
      },
      {
        nume: 'Rariştea',
        simplu: 'Raristea',
        comuna: 'Ion Corvin'
      },
      {
        nume: 'Viile',
        comuna: 'Ion Corvin'
      },
      {
        nume: 'Nuntaşi',
        simplu: 'Nuntasi',
        comuna: 'Istria'
      },
      {
        nume: '2 Mai',
        comuna: 'Limanu'
      },
      {
        nume: 'Hagieni',
        comuna: 'Limanu'
      },
      {
        nume: 'Vama Veche',
        comuna: 'Limanu'
      },
      {
        nume: 'Canlia',
        comuna: 'Lipnita'
      },
      {
        nume: 'Carvăn',
        simplu: 'Carvan',
        comuna: 'Lipnita'
      },
      {
        nume: 'Coslugea',
        comuna: 'Lipnita'
      },
      {
        nume: 'Cuiugiuc',
        comuna: 'Lipnita'
      },
      {
        nume: 'Goruni',
        comuna: 'Lipnita'
      },
      {
        nume: 'Izvoarele',
        comuna: 'Lipnita'
      },
      {
        nume: 'Oituz',
        comuna: 'Lumina'
      },
      {
        nume: 'Sibioara',
        comuna: 'Lumina'
      },
      {
        nume: 'Cap Aurora',
        comuna: 'Mangalia'
      },
      {
        nume: 'Jupiter',
        comuna: 'Mangalia'
      },
      {
        nume: 'Neptun',
        comuna: 'Mangalia'
      },
      {
        nume: 'Olimp',
        comuna: 'Mangalia'
      },
      {
        nume: 'Saturn',
        comuna: 'Mangalia'
      },
      {
        nume: 'Venus',
        comuna: 'Mangalia'
      },
      {
        nume: 'Remus Opreanu',
        comuna: 'Medgidia'
      },
      {
        nume: 'Valea Dacilor',
        comuna: 'Medgidia'
      },
      {
        nume: 'Ciobăniţa',
        simplu: 'Ciobanita',
        comuna: 'Mereni'
      },
      {
        nume: 'Miriştea',
        simplu: 'Miristea',
        comuna: 'Mereni'
      },
      {
        nume: 'Osmancea',
        comuna: 'Mereni'
      },
      {
        nume: 'Sinoie',
        comuna: 'Mihai Viteazu'
      },
      {
        nume: 'Palazu Mic',
        comuna: 'Mihail Kogalniceanu'
      },
      {
        nume: 'Piatra',
        comuna: 'Mihail Kogalniceanu'
      },
      {
        nume: 'Făclia',
        simplu: 'Faclia',
        comuna: 'Mircea Voda'
      },
      {
        nume: 'Gherghina',
        comuna: 'Mircea Voda'
      },
      {
        nume: 'Saligny',
        comuna: 'Mircea Voda'
      },
      {
        nume: 'Satu Nou',
        comuna: 'Mircea Voda'
      },
      {
        nume: 'Stefan cel Mare',
        simplu: 'Stefan cel Mare',
        comuna: 'Mircea Voda'
      },
      {
        nume: 'Tibrinu',
        simplu: 'Tibrinu',
        comuna: 'Mircea Voda'
      },
      {
        nume: 'Mamaia-Sat',
        comuna: 'Navodari'
      },
      {
        nume: 'Darabani',
        comuna: 'Negru Voda'
      },
      {
        nume: 'Grăniceru',
        simplu: 'Graniceru',
        comuna: 'Negru Voda'
      },
      {
        nume: 'Vâlcelele',
        simplu: 'Valcelele',
        comuna: 'Negru Voda'
      },
      {
        nume: 'Dorobanţu',
        simplu: 'Dorobantu',
        comuna: 'Nicolae Balcescu'
      },
      {
        nume: 'Răzoarele',
        simplu: 'Razoarele',
        comuna: 'Oltina'
      },
      {
        nume: 'Satu Nou',
        comuna: 'Oltina'
      },
      {
        nume: 'Strunga',
        comuna: 'Oltina'
      },
      {
        nume: 'Almălău',
        simplu: 'Almalau',
        comuna: 'Ostrov'
      },
      {
        nume: 'Bugeac',
        comuna: 'Ostrov'
      },
      {
        nume: 'Esechioi',
        comuna: 'Ostrov'
      },
      {
        nume: 'Galiţa',
        simplu: 'Galita',
        comuna: 'Ostrov'
      },
      {
        nume: 'Gârliţa',
        simplu: 'Garlita',
        comuna: 'Ostrov'
      },
      {
        nume: 'Poiana',
        comuna: 'Ovidiu'
      },
      {
        nume: 'Călugăreni',
        simplu: 'Calugareni',
        comuna: 'Pantelimon'
      },
      {
        nume: 'Nistoreşti',
        simplu: 'Nistoresti',
        comuna: 'Pantelimon'
      },
      {
        nume: 'Pantelimon de Jos',
        comuna: 'Pantelimon'
      },
      {
        nume: 'Runcu',
        comuna: 'Pantelimon'
      },
      {
        nume: 'Vânători',
        simplu: 'Vanatori',
        comuna: 'Pecineaga'
      },
      {
        nume: 'Ivrinezu Mare',
        comuna: 'Pestera'
      },
      {
        nume: 'Ivrinezu Mic',
        comuna: 'Pestera'
      },
      {
        nume: 'Izvoru Mare',
        comuna: 'Pestera'
      },
      {
        nume: 'Veteranu',
        comuna: 'Pestera'
      },
      {
        nume: 'Nazarcea',
        comuna: 'Poarta Alba'
      },
      {
        nume: 'Cochirleni',
        comuna: 'Rasova'
      },
      {
        nume: 'Traian',
        comuna: 'Sacele'
      },
      {
        nume: 'Dulgheru',
        comuna: 'Saraiu'
      },
      {
        nume: 'Stejaru',
        comuna: 'Saraiu'
      },
      {
        nume: 'Dunărea',
        simplu: 'Dunarea',
        comuna: 'Seimeni'
      },
      {
        nume: 'Seimenii Mici',
        comuna: 'Seimeni'
      },
      {
        nume: 'Tepeş Vodă',
        simplu: 'Tepes Voda',
        comuna: 'Silistea'
      },
      {
        nume: 'Casian',
        comuna: 'Targusor'
      },
      {
        nume: 'Cheia',
        comuna: 'Targusor'
      },
      {
        nume: 'Grădina',
        simplu: 'Gradina',
        comuna: 'Targusor'
      },
      {
        nume: 'Mireasa',
        comuna: 'Targusor'
      },
      {
        nume: 'Capidava',
        comuna: 'Topalu'
      },
      {
        nume: 'Biruinţa',
        simplu: 'Biruinta',
        comuna: 'Topraisar'
      },
      {
        nume: 'Moviliţa',
        simplu: 'Movilita',
        comuna: 'Topraisar'
      },
      {
        nume: 'Potârnichea',
        simplu: 'Potarnichea',
        comuna: 'Topraisar'
      },
      {
        nume: 'Dropia',
        comuna: 'Tortoman'
      }
    ]
  },
  {
    auto: 'CV',
    nume: 'Covasna',
    localitati: [
      {
        nume: 'Aita Mare'
      },
      {
        nume: 'Baraolt'
      },
      {
        nume: 'Barcani'
      },
      {
        nume: 'Belin'
      },
      {
        nume: 'Bodoc'
      },
      {
        nume: 'Boroşneu Mare',
        simplu: 'Borosneu Mare'
      },
      {
        nume: 'Brateş',
        simplu: 'Brates'
      },
      {
        nume: 'Breţcu',
        simplu: 'Bretcu'
      },
      {
        nume: 'Brăduţ',
        simplu: 'Bradut'
      },
      {
        nume: 'Catalina'
      },
      {
        nume: 'Cernat'
      },
      {
        nume: 'Chichiş',
        simplu: 'Chichis'
      },
      {
        nume: 'Comandău',
        simplu: 'Comandau'
      },
      {
        nume: 'Covasna'
      },
      {
        nume: 'Dobârlău',
        simplu: 'Dobarlau'
      },
      {
        nume: 'Ghelinţa',
        simplu: 'Ghelinta'
      },
      {
        nume: 'Ghidfalău',
        simplu: 'Ghidfalau'
      },
      {
        nume: 'Hăghig',
        simplu: 'Haghig'
      },
      {
        nume: 'Ilieni'
      },
      {
        nume: 'Lemnia'
      },
      {
        nume: 'Malnaş',
        simplu: 'Malnas'
      },
      {
        nume: 'Moacşa',
        simplu: 'Moacsa'
      },
      {
        nume: 'Ojdula'
      },
      {
        nume: 'Ozun'
      },
      {
        nume: 'Poian'
      },
      {
        nume: 'Reci'
      },
      {
        nume: 'Sita Buzăului',
        simplu: 'Sita Buzaului'
      },
      {
        nume: 'Sânzieni',
        simplu: 'Sanzieni'
      },
      {
        nume: 'Turia'
      },
      {
        nume: 'Valea Crişului',
        simplu: 'Valea Crisului'
      },
      {
        nume: 'Valea Mare'
      },
      {
        nume: 'Vâlcele',
        simplu: 'Valcele'
      },
      {
        nume: 'Vârghiş',
        simplu: 'Varghis'
      },
      {
        nume: 'Zagon'
      },
      {
        nume: 'Zăbala',
        simplu: 'Zabala'
      },
      {
        nume: 'Întorsura Buzăului',
        simplu: 'Intorsura Buzaului'
      },
      {
        nume: 'Aita Medie',
        comuna: 'Aita Mare'
      },
      {
        nume: 'Biborţeni',
        simplu: 'Biborteni',
        comuna: 'Baraolt'
      },
      {
        nume: 'Bodoş',
        simplu: 'Bodos',
        comuna: 'Baraolt'
      },
      {
        nume: 'Căpeni',
        simplu: 'Capeni',
        comuna: 'Baraolt'
      },
      {
        nume: 'Micloşoara',
        simplu: 'Miclosoara',
        comuna: 'Baraolt'
      },
      {
        nume: 'Racoşul de Sus',
        simplu: 'Racosul de Sus',
        comuna: 'Baraolt'
      },
      {
        nume: 'Lădăuţi',
        simplu: 'Ladauti',
        comuna: 'Barcani'
      },
      {
        nume: 'Sărămăş',
        simplu: 'Saramas',
        comuna: 'Barcani'
      },
      {
        nume: 'Aita Seacă',
        simplu: 'Aita Seaca',
        comuna: 'Batani'
      },
      {
        nume: 'Băţanii Mari',
        simplu: 'Batanii Mari',
        comuna: 'Batani'
      },
      {
        nume: 'Băţanii Mici',
        simplu: 'Batanii Mici',
        comuna: 'Batani'
      },
      {
        nume: 'Herculian',
        comuna: 'Batani'
      },
      {
        nume: 'Ozunca-Băi',
        simplu: 'Ozunca-Bai',
        comuna: 'Batani'
      },
      {
        nume: 'Belin-Vale',
        comuna: 'Belin'
      },
      {
        nume: 'Olteni',
        comuna: 'Bodoc'
      },
      {
        nume: 'Zălan',
        simplu: 'Zalan',
        comuna: 'Bodoc'
      },
      {
        nume: 'Boroşneu Mic',
        simplu: 'Borosneu Mic',
        comuna: 'Borosneu Mare'
      },
      {
        nume: 'Dobolii de Sus',
        comuna: 'Borosneu Mare'
      },
      {
        nume: 'Leţ',
        simplu: 'Let',
        comuna: 'Borosneu Mare'
      },
      {
        nume: 'Valea Mică',
        simplu: 'Valea Mica',
        comuna: 'Borosneu Mare'
      },
      {
        nume: 'Tufalău',
        simplu: 'Tufalau',
        comuna: 'Borosneu Mare'
      },
      {
        nume: 'Doboşeni',
        simplu: 'Doboseni',
        comuna: 'Bradut'
      },
      {
        nume: 'Filia',
        comuna: 'Bradut'
      },
      {
        nume: 'Tălişoara',
        simplu: 'Talisoara',
        comuna: 'Bradut'
      },
      {
        nume: 'Pachia',
        comuna: 'Brates'
      },
      {
        nume: 'Telechia',
        comuna: 'Brates'
      },
      {
        nume: 'Mărtănuş',
        simplu: 'Martanus',
        comuna: 'Bretcu'
      },
      {
        nume: 'Oituz',
        comuna: 'Bretcu'
      },
      {
        nume: 'Hătuica',
        simplu: 'Hatuica',
        comuna: 'Catalina'
      },
      {
        nume: 'Imeni',
        comuna: 'Catalina'
      },
      {
        nume: 'Mărcuşa',
        simplu: 'Marcusa',
        comuna: 'Catalina'
      },
      {
        nume: 'Mărtineni',
        simplu: 'Martineni',
        comuna: 'Catalina'
      },
      {
        nume: 'Albiş',
        simplu: 'Albis',
        comuna: 'Cernat'
      },
      {
        nume: 'Icafalău',
        simplu: 'Icafalau',
        comuna: 'Cernat'
      },
      {
        nume: 'Băcel',
        simplu: 'Bacel',
        comuna: 'Chichis'
      },
      {
        nume: 'Chiuruş',
        simplu: 'Chiurus',
        comuna: 'Covasna'
      },
      {
        nume: 'Lunca Mărcuşului',
        simplu: 'Lunca Marcusului',
        comuna: 'Dobarlau'
      },
      {
        nume: 'Mărcuş',
        simplu: 'Marcus',
        comuna: 'Dobarlau'
      },
      {
        nume: 'Valea Dobârlăului',
        simplu: 'Valea Dobarlaului',
        comuna: 'Dobarlau'
      },
      {
        nume: 'Harale',
        comuna: 'Ghelinta'
      },
      {
        nume: 'Angheluş',
        simplu: 'Anghelus',
        comuna: 'Ghidfalau'
      },
      {
        nume: 'Fotoş',
        simplu: 'Fotos',
        comuna: 'Ghidfalau'
      },
      {
        nume: 'Zoltan',
        comuna: 'Ghidfalau'
      },
      {
        nume: 'Iarăş',
        simplu: 'Iaras',
        comuna: 'Haghig'
      },
      {
        nume: 'Dobolii de Jos',
        comuna: 'Ilieni'
      },
      {
        nume: 'Sâncraiu',
        simplu: 'Sancraiu',
        comuna: 'Ilieni'
      },
      {
        nume: 'Brădet',
        simplu: 'Bradet',
        comuna: 'Intorsura Buzaului'
      },
      {
        nume: 'Floroaia',
        comuna: 'Intorsura Buzaului'
      },
      {
        nume: 'Scrădoasa',
        simplu: 'Scradoasa',
        comuna: 'Intorsura Buzaului'
      },
      {
        nume: 'Lutoasa',
        comuna: 'Lemnia'
      },
      {
        nume: 'Mereni',
        comuna: 'Lemnia'
      },
      {
        nume: 'Bixad',
        comuna: 'Malnas'
      },
      {
        nume: 'Malnaş-Băi',
        simplu: 'Malnas-Bai',
        comuna: 'Malnas'
      },
      {
        nume: 'Micfalău',
        simplu: 'Micfalau',
        comuna: 'Malnas'
      },
      {
        nume: 'Valea Zălanului',
        simplu: 'Valea Zalanului',
        comuna: 'Malnas'
      },
      {
        nume: 'Dalnic',
        comuna: 'Moacsa'
      },
      {
        nume: 'Pădureni',
        simplu: 'Padureni',
        comuna: 'Moacsa'
      },
      {
        nume: 'Hilib',
        comuna: 'Ojdula'
      },
      {
        nume: 'Bicfalău',
        simplu: 'Bicfalau',
        comuna: 'Ozun'
      },
      {
        nume: 'Lisnău',
        simplu: 'Lisnau',
        comuna: 'Ozun'
      },
      {
        nume: 'Lisnău-Vale',
        simplu: 'Lisnau-Vale',
        comuna: 'Ozun'
      },
      {
        nume: 'Lunca Ozunului',
        comuna: 'Ozun'
      },
      {
        nume: 'Măgheruş',
        simplu: 'Magherus',
        comuna: 'Ozun'
      },
      {
        nume: 'Sântionlunca',
        simplu: 'Santionlunca',
        comuna: 'Ozun'
      },
      {
        nume: 'Belani',
        comuna: 'Poian'
      },
      {
        nume: 'Cărpinenii',
        simplu: 'Carpinenii',
        comuna: 'Poian'
      },
      {
        nume: 'Estelnic',
        comuna: 'Poian'
      },
      {
        nume: 'Valea Scurtă',
        simplu: 'Valea Scurta',
        comuna: 'Poian'
      },
      {
        nume: 'Aninoasa',
        comuna: 'Reci'
      },
      {
        nume: 'Bita',
        comuna: 'Reci'
      },
      {
        nume: 'Saciova',
        comuna: 'Reci'
      },
      {
        nume: 'Caşinu Mic',
        simplu: 'Casinu Mic',
        comuna: 'Sanzieni'
      },
      {
        nume: 'Petriceni',
        comuna: 'Sanzieni'
      },
      {
        nume: 'Valea Seacă',
        simplu: 'Valea Seaca',
        comuna: 'Sanzieni'
      },
      {
        nume: 'Chilieni',
        comuna: 'Sfantul Gheorghe'
      },
      {
        nume: 'Coşeni',
        simplu: 'Coseni',
        comuna: 'Sfantul Gheorghe'
      },
      {
        nume: 'Sfântu  Gheorghe',
        simplu: 'Sfantu  Gheorghe',
        comuna: 'Sfantul Gheorghe'
      },
      {
        nume: 'Crasna',
        comuna: 'Sita Buzaului'
      },
      {
        nume: 'Merişor',
        simplu: 'Merisor',
        comuna: 'Sita Buzaului'
      },
      {
        nume: 'Zăbrătău',
        simplu: 'Zabratau',
        comuna: 'Sita Buzaului'
      },
      {
        nume: 'Lunga',
        comuna: 'Tirgu Secuiesc'
      },
      {
        nume: 'Târgu Secuiesc',
        simplu: 'Targu Secuiesc',
        comuna: 'Tirgu Secuiesc'
      },
      {
        nume: 'Alungeni',
        comuna: 'Turia'
      },
      {
        nume: 'Araci',
        comuna: 'Valcele'
      },
      {
        nume: 'Ariuşd',
        simplu: 'Ariusd',
        comuna: 'Valcele'
      },
      {
        nume: 'Hetea',
        comuna: 'Valcele'
      },
      {
        nume: 'Arcuş',
        simplu: 'Arcus',
        comuna: 'Valea Crisului'
      },
      {
        nume: 'Calnic',
        comuna: 'Valea Crisului'
      },
      {
        nume: 'Peteni',
        comuna: 'Zabala'
      },
      {
        nume: 'Surcea',
        comuna: 'Zabala'
      },
      {
        nume: 'Tamaşfalău',
        simplu: 'Tamasfalau',
        comuna: 'Zabala'
      },
      {
        nume: 'Păpăuţi',
        simplu: 'Papauti',
        comuna: 'Zagon'
      }
    ]
  },
  {
    auto: 'DB',
    nume: 'Dâmboviţa',
    localitati: [
      {
        nume: 'Aninoasa'
      },
      {
        nume: 'Bezdead'
      },
      {
        nume: 'Bilciureşti',
        simplu: 'Bilciuresti'
      },
      {
        nume: 'Braniştea',
        simplu: 'Branistea'
      },
      {
        nume: 'Brezoaele'
      },
      {
        nume: 'Brăneşti',
        simplu: 'Branesti'
      },
      {
        nume: 'Buciumeni'
      },
      {
        nume: 'Bucşani',
        simplu: 'Bucsani'
      },
      {
        nume: 'Butimanu'
      },
      {
        nume: 'Bărbuleţu',
        simplu: 'Barbuletu'
      },
      {
        nume: 'Ciocăneşti',
        simplu: 'Ciocanesti'
      },
      {
        nume: 'Cojasca'
      },
      {
        nume: 'Comişani',
        simplu: 'Comisani'
      },
      {
        nume: 'Conţeşti',
        simplu: 'Contesti'
      },
      {
        nume: 'Corbii Mari'
      },
      {
        nume: 'Corneşti',
        simplu: 'Cornesti'
      },
      {
        nume: 'Cornăţelu',
        simplu: 'Cornatelu'
      },
      {
        nume: 'Costeştii din Vale',
        simplu: 'Costestii din Vale'
      },
      {
        nume: 'Crevedia'
      },
      {
        nume: 'Dobra'
      },
      {
        nume: 'Doiceşti',
        simplu: 'Doicesti'
      },
      {
        nume: 'Dragodana'
      },
      {
        nume: 'Dragomireşti',
        simplu: 'Dragomiresti'
      },
      {
        nume: 'Dărmăneşti',
        simplu: 'Darmanesti'
      },
      {
        nume: 'Fieni'
      },
      {
        nume: 'Glodeni'
      },
      {
        nume: 'Gura Foii'
      },
      {
        nume: 'Gura Ocniţei',
        simplu: 'Gura Ocnitei'
      },
      {
        nume: 'Gura Suţii',
        simplu: 'Gura Sutii'
      },
      {
        nume: 'Găeşti',
        simplu: 'Gaesti'
      },
      {
        nume: 'Hulubeşti',
        simplu: 'Hulubesti'
      },
      {
        nume: 'I. L. Caragiale'
      },
      {
        nume: 'Lucieni'
      },
      {
        nume: 'Ludeşti',
        simplu: 'Ludesti'
      },
      {
        nume: 'Lunguleţu',
        simplu: 'Lunguletu'
      },
      {
        nume: 'Malu cu Flori'
      },
      {
        nume: 'Mogoşani',
        simplu: 'Mogosani'
      },
      {
        nume: 'Moreni'
      },
      {
        nume: 'Moroeni'
      },
      {
        nume: 'Morteni'
      },
      {
        nume: 'Moţăieni',
        simplu: 'Motaieni'
      },
      {
        nume: 'Măneşti',
        simplu: 'Manesti'
      },
      {
        nume: 'Mătăsaru',
        simplu: 'Matasaru'
      },
      {
        nume: 'Niculeşti',
        simplu: 'Niculesti'
      },
      {
        nume: 'Nucet'
      },
      {
        nume: 'Ocniţa',
        simplu: 'Ocnita'
      },
      {
        nume: 'Odobeşti',
        simplu: 'Odobesti'
      },
      {
        nume: 'Petreşti',
        simplu: 'Petresti'
      },
      {
        nume: 'Pietroşiţa',
        simplu: 'Pietrosita'
      },
      {
        nume: 'Poiana'
      },
      {
        nume: 'Potlogi'
      },
      {
        nume: 'Produleşti',
        simplu: 'Produlesti'
      },
      {
        nume: 'Pucheni'
      },
      {
        nume: 'Pucioasa'
      },
      {
        nume: 'Runcu'
      },
      {
        nume: 'Răcari',
        simplu: 'Racari'
      },
      {
        nume: 'Răzvad',
        simplu: 'Razvad'
      },
      {
        nume: 'Slobozia Moară',
        simplu: 'Slobozia Moara'
      },
      {
        nume: 'Sălcioara',
        simplu: 'Salcioara'
      },
      {
        nume: 'Titu'
      },
      {
        nume: 'Târgovişte',
        simplu: 'Targoviste'
      },
      {
        nume: 'Tărtăşeşti',
        simplu: 'Tartasesti'
      },
      {
        nume: 'Tătărani',
        simplu: 'Tatarani'
      },
      {
        nume: 'Ulieşti',
        simplu: 'Uliesti'
      },
      {
        nume: 'Ulmi'
      },
      {
        nume: 'Valea Mare'
      },
      {
        nume: 'Vişina',
        simplu: 'Visina'
      },
      {
        nume: 'Vişineşti',
        simplu: 'Visinesti'
      },
      {
        nume: 'Vlădeni',
        simplu: 'Vladeni'
      },
      {
        nume: 'Voineşti',
        simplu: 'Voinesti'
      },
      {
        nume: 'Vulcana-Băi',
        simplu: 'Vulcana-Bai'
      },
      {
        nume: 'Vulcana-Pandele'
      },
      {
        nume: 'Văcăreşti',
        simplu: 'Vacaresti'
      },
      {
        nume: 'Selaru',
        simplu: 'Selaru'
      },
      {
        nume: 'Sotânga',
        simplu: 'Sotanga'
      },
      {
        nume: 'Săteni',
        simplu: 'Sateni',
        comuna: 'Aninoasa'
      },
      {
        nume: 'Viforâta',
        simplu: 'Viforata',
        comuna: 'Aninoasa'
      },
      {
        nume: 'Băleni-Români',
        simplu: 'Baleni-Romani',
        comuna: 'Baleni'
      },
      {
        nume: 'Băleni-Sârbi',
        simplu: 'Baleni-Sarbi',
        comuna: 'Baleni'
      },
      {
        nume: 'Aluniş',
        simplu: 'Alunis',
        comuna: 'Barbuletu'
      },
      {
        nume: 'Cetăţuia',
        simplu: 'Cetatuia',
        comuna: 'Barbuletu'
      },
      {
        nume: 'După Deal',
        simplu: 'Dupa Deal',
        comuna: 'Barbuletu'
      },
      {
        nume: 'Gura Bărbuleţului',
        simplu: 'Gura Barbuletului',
        comuna: 'Barbuletu'
      },
      {
        nume: 'Pietrari',
        comuna: 'Barbuletu'
      },
      {
        nume: 'Râu Alb de Jos',
        simplu: 'Rau Alb de Jos',
        comuna: 'Barbuletu'
      },
      {
        nume: 'Râu Alb de Sus',
        simplu: 'Rau Alb de Sus',
        comuna: 'Barbuletu'
      },
      {
        nume: 'Valea',
        comuna: 'Barbuletu'
      },
      {
        nume: 'Sipot',
        simplu: 'Sipot',
        comuna: 'Barbuletu'
      },
      {
        nume: 'Broşteni',
        simplu: 'Brosteni',
        comuna: 'Bezdead'
      },
      {
        nume: 'Costişata',
        simplu: 'Costisata',
        comuna: 'Bezdead'
      },
      {
        nume: 'Măgura',
        simplu: 'Magura',
        comuna: 'Bezdead'
      },
      {
        nume: 'Tunari',
        comuna: 'Bezdead'
      },
      {
        nume: 'Valea Morii',
        comuna: 'Bezdead'
      },
      {
        nume: 'Suseni Socetu',
        comuna: 'Bilciuresti'
      },
      {
        nume: 'Priboiu',
        comuna: 'Branesti'
      },
      {
        nume: 'Dâmbovicioara',
        simplu: 'Dambovicioara',
        comuna: 'Branistea'
      },
      {
        nume: 'Săveşti',
        simplu: 'Savesti',
        comuna: 'Branistea'
      },
      {
        nume: 'Brezoaia',
        comuna: 'Brezoaele'
      },
      {
        nume: 'Dealu Mare',
        comuna: 'Buciumeni'
      },
      {
        nume: 'Valea Leurzii',
        comuna: 'Buciumeni'
      },
      {
        nume: 'Hăbeni',
        simplu: 'Habeni',
        comuna: 'Bucsani'
      },
      {
        nume: 'Racoviţa',
        simplu: 'Racovita',
        comuna: 'Bucsani'
      },
      {
        nume: 'Răţoaia',
        simplu: 'Ratoaia',
        comuna: 'Bucsani'
      },
      {
        nume: 'Bărbuceanu',
        simplu: 'Barbuceanu',
        comuna: 'Butimanu'
      },
      {
        nume: 'Lucianca',
        comuna: 'Butimanu'
      },
      {
        nume: 'Ungureni',
        comuna: 'Butimanu'
      },
      {
        nume: 'Aninoşani',
        simplu: 'Aninosani',
        comuna: 'Candesti'
      },
      {
        nume: 'Cândeşti Deal',
        simplu: 'Candesti Deal',
        comuna: 'Candesti'
      },
      {
        nume: 'Cândeşti-Vale',
        simplu: 'Candesti-Vale',
        comuna: 'Candesti'
      },
      {
        nume: 'Dragodăneşti',
        simplu: 'Dragodanesti',
        comuna: 'Candesti'
      },
      {
        nume: 'Valea Mare',
        comuna: 'Candesti'
      },
      {
        nume: 'Creţu',
        simplu: 'Cretu',
        comuna: 'Ciocanesti'
      },
      {
        nume: 'Decindea',
        comuna: 'Ciocanesti'
      },
      {
        nume: 'Urziceanca',
        comuna: 'Ciocanesti'
      },
      {
        nume: 'Vizureşti',
        simplu: 'Vizuresti',
        comuna: 'Ciocanesti'
      },
      {
        nume: 'Blidari',
        comuna: 'Cobia'
      },
      {
        nume: 'Cobiuţa',
        simplu: 'Cobiuta',
        comuna: 'Cobia'
      },
      {
        nume: 'Crăciuneşti',
        simplu: 'Craciunesti',
        comuna: 'Cobia'
      },
      {
        nume: 'Călugăreni',
        simplu: 'Calugareni',
        comuna: 'Cobia'
      },
      {
        nume: 'Căpşuna',
        simplu: 'Capsuna',
        comuna: 'Cobia'
      },
      {
        nume: 'Frasin Deal',
        comuna: 'Cobia'
      },
      {
        nume: 'Frasin Vale',
        comuna: 'Cobia'
      },
      {
        nume: 'Gherghiţeşti',
        simplu: 'Gherghitesti',
        comuna: 'Cobia'
      },
      {
        nume: 'Mislea',
        comuna: 'Cobia'
      },
      {
        nume: 'Mănăstirea',
        simplu: 'Manastirea',
        comuna: 'Cobia'
      },
      {
        nume: 'Fântânele',
        simplu: 'Fantanele',
        comuna: 'Cojasca'
      },
      {
        nume: 'Iazu',
        comuna: 'Cojasca'
      },
      {
        nume: 'Lazuri',
        comuna: 'Comisani'
      },
      {
        nume: 'Boteni',
        comuna: 'Contesti'
      },
      {
        nume: 'Bălteni',
        simplu: 'Balteni',
        comuna: 'Contesti'
      },
      {
        nume: 'Crângaşi',
        simplu: 'Crangasi',
        comuna: 'Contesti'
      },
      {
        nume: 'Călugăreni',
        simplu: 'Calugareni',
        comuna: 'Contesti'
      },
      {
        nume: 'Gămăneşti',
        simplu: 'Gamanesti',
        comuna: 'Contesti'
      },
      {
        nume: 'Heleşteu',
        simplu: 'Helesteu',
        comuna: 'Contesti'
      },
      {
        nume: 'Mereni',
        comuna: 'Contesti'
      },
      {
        nume: 'Bărăceni',
        simplu: 'Baraceni',
        comuna: 'Corbii Mari'
      },
      {
        nume: 'Grozăveşti',
        simplu: 'Grozavesti',
        comuna: 'Corbii Mari'
      },
      {
        nume: 'Moara din Groapă',
        simplu: 'Moara din Groapa',
        comuna: 'Corbii Mari'
      },
      {
        nume: 'Petreşti',
        simplu: 'Petresti',
        comuna: 'Corbii Mari'
      },
      {
        nume: 'Podu Corbencii',
        comuna: 'Corbii Mari'
      },
      {
        nume: 'Satu Nou',
        comuna: 'Corbii Mari'
      },
      {
        nume: 'Ungureni',
        comuna: 'Corbii Mari'
      },
      {
        nume: 'Vadu Stanchii',
        comuna: 'Corbii Mari'
      },
      {
        nume: 'Alunişu',
        simplu: 'Alunisu',
        comuna: 'Cornatelu'
      },
      {
        nume: 'Bolovani',
        comuna: 'Cornatelu'
      },
      {
        nume: 'Corni',
        comuna: 'Cornatelu'
      },
      {
        nume: 'Slobozia',
        comuna: 'Cornatelu'
      },
      {
        nume: 'Bujoreanca',
        comuna: 'Cornesti'
      },
      {
        nume: 'Cristeasca',
        comuna: 'Cornesti'
      },
      {
        nume: 'Crivăţu',
        simplu: 'Crivatu',
        comuna: 'Cornesti'
      },
      {
        nume: 'Cătunu',
        simplu: 'Catunu',
        comuna: 'Cornesti'
      },
      {
        nume: 'Frasinu',
        comuna: 'Cornesti'
      },
      {
        nume: 'Hodărăşti',
        simplu: 'Hodarasti',
        comuna: 'Cornesti'
      },
      {
        nume: 'Ibrianu',
        comuna: 'Cornesti'
      },
      {
        nume: 'Postârnacu',
        simplu: 'Postarnacu',
        comuna: 'Cornesti'
      },
      {
        nume: 'Ungureni',
        comuna: 'Cornesti'
      },
      {
        nume: 'Mărunţişu',
        simplu: 'Maruntisu',
        comuna: 'Costestii Din Vale'
      },
      {
        nume: 'Tomşani',
        simplu: 'Tomsani',
        comuna: 'Costestii Din Vale'
      },
      {
        nume: 'Băduleşti',
        simplu: 'Badulesti',
        comuna: 'Crangurile'
      },
      {
        nume: 'Crângurile de Jos',
        simplu: 'Crangurile de Jos',
        comuna: 'Crangurile'
      },
      {
        nume: 'Crângurile de Sus',
        simplu: 'Crangurile de Sus',
        comuna: 'Crangurile'
      },
      {
        nume: 'Potlogeni Vale',
        comuna: 'Crangurile'
      },
      {
        nume: 'Pătroaia Deal',
        simplu: 'Patroaia Deal',
        comuna: 'Crangurile'
      },
      {
        nume: 'Pătroaia Vale',
        simplu: 'Patroaia Vale',
        comuna: 'Crangurile'
      },
      {
        nume: 'Răţeşti',
        simplu: 'Ratesti',
        comuna: 'Crangurile'
      },
      {
        nume: 'Voia',
        comuna: 'Crangurile'
      },
      {
        nume: 'Cocani',
        comuna: 'Crevedia'
      },
      {
        nume: 'Dârza',
        simplu: 'Darza',
        comuna: 'Crevedia'
      },
      {
        nume: 'Mânăstirea',
        simplu: 'Manastirea',
        comuna: 'Crevedia'
      },
      {
        nume: 'Samurcaşi',
        simplu: 'Samurcasi',
        comuna: 'Crevedia'
      },
      {
        nume: 'Mărginenii de Sus',
        simplu: 'Marginenii de Sus',
        comuna: 'Darmanesti'
      },
      {
        nume: 'Mărceşti',
        simplu: 'Marcesti',
        comuna: 'Dobra'
      },
      {
        nume: 'Boboci',
        comuna: 'Dragodana'
      },
      {
        nume: 'Burduca',
        comuna: 'Dragodana'
      },
      {
        nume: 'Cuparu',
        comuna: 'Dragodana'
      },
      {
        nume: 'Picior de Munte',
        comuna: 'Dragodana'
      },
      {
        nume: 'Pădureni',
        simplu: 'Padureni',
        comuna: 'Dragodana'
      },
      {
        nume: 'Străoşti',
        simplu: 'Straosti',
        comuna: 'Dragodana'
      },
      {
        nume: 'Decindeni',
        comuna: 'Dragomiresti'
      },
      {
        nume: 'Geangoeşti',
        simplu: 'Geangoesti',
        comuna: 'Dragomiresti'
      },
      {
        nume: 'Mogoşeşti',
        simplu: 'Mogosesti',
        comuna: 'Dragomiresti'
      },
      {
        nume: 'Râncăciov',
        simplu: 'Rancaciov',
        comuna: 'Dragomiresti'
      },
      {
        nume: 'Ungureni',
        comuna: 'Dragomiresti'
      },
      {
        nume: 'Berevoeşti',
        simplu: 'Berevoesti',
        comuna: 'Fieni'
      },
      {
        nume: 'Costeşti',
        simplu: 'Costesti',
        comuna: 'Fieni'
      },
      {
        nume: 'Bechineşti',
        simplu: 'Bechinesti',
        comuna: 'Finta'
      },
      {
        nume: 'Finta Mare',
        comuna: 'Finta'
      },
      {
        nume: 'Finta Veche',
        comuna: 'Finta'
      },
      {
        nume: 'Gheboaia',
        comuna: 'Finta'
      },
      {
        nume: 'Guşoiu',
        simplu: 'Gusoiu',
        comuna: 'Glodeni'
      },
      {
        nume: 'Livezile',
        comuna: 'Glodeni'
      },
      {
        nume: 'Lăculeţe',
        simplu: 'Laculete',
        comuna: 'Glodeni'
      },
      {
        nume: 'Malu Mierii',
        comuna: 'Glodeni'
      },
      {
        nume: 'Schela',
        comuna: 'Glodeni'
      },
      {
        nume: 'Bumbuia',
        comuna: 'Gura Foii'
      },
      {
        nume: 'Catanele',
        comuna: 'Gura Foii'
      },
      {
        nume: 'Făgetu',
        simplu: 'Fagetu',
        comuna: 'Gura Foii'
      },
      {
        nume: 'Adânca',
        simplu: 'Adanca',
        comuna: 'Gura Ocnitei'
      },
      {
        nume: 'Ochiuri',
        comuna: 'Gura Ocnitei'
      },
      {
        nume: 'Săcueni',
        simplu: 'Sacueni',
        comuna: 'Gura Ocnitei'
      },
      {
        nume: 'Sperieţeni',
        simplu: 'Sperieteni',
        comuna: 'Gura Sutii'
      },
      {
        nume: 'Butoiu de Jos',
        comuna: 'Hulubesti'
      },
      {
        nume: 'Butoiu de Sus',
        comuna: 'Hulubesti'
      },
      {
        nume: 'Măgura',
        simplu: 'Magura',
        comuna: 'Hulubesti'
      },
      {
        nume: 'Valea Dadei',
        comuna: 'Hulubesti'
      },
      {
        nume: 'Ghirdoveni',
        comuna: 'I. L. Caragiale'
      },
      {
        nume: 'Mija',
        comuna: 'I. L. Caragiale'
      },
      {
        nume: 'Colibaşi',
        simplu: 'Colibasi',
        comuna: 'Iedera'
      },
      {
        nume: 'Cricovu Dulce',
        comuna: 'Iedera'
      },
      {
        nume: 'Iedera de Jos',
        comuna: 'Iedera'
      },
      {
        nume: 'Iedera de Sus',
        comuna: 'Iedera'
      },
      {
        nume: 'Olteni',
        comuna: 'Lucieni'
      },
      {
        nume: 'Raciu',
        comuna: 'Lucieni'
      },
      {
        nume: 'Siliştea',
        simplu: 'Silistea',
        comuna: 'Lucieni'
      },
      {
        nume: 'Suţa Seacă',
        simplu: 'Suta Seaca',
        comuna: 'Lucieni'
      },
      {
        nume: 'Miloşari',
        simplu: 'Milosari',
        comuna: 'Ludesti'
      },
      {
        nume: 'Potocelu',
        comuna: 'Ludesti'
      },
      {
        nume: 'Scheiu de Jos',
        comuna: 'Ludesti'
      },
      {
        nume: 'Scheiu de Sus',
        comuna: 'Ludesti'
      },
      {
        nume: 'Teleşti',
        simplu: 'Telesti',
        comuna: 'Ludesti'
      },
      {
        nume: 'Oreasca',
        comuna: 'Lunguletu'
      },
      {
        nume: 'Serdanu',
        comuna: 'Lunguletu'
      },
      {
        nume: 'Capu Coastei',
        comuna: 'Malu Cu Flori'
      },
      {
        nume: 'Copăceni',
        simplu: 'Copaceni',
        comuna: 'Malu Cu Flori'
      },
      {
        nume: 'Micloşanii Mari',
        simplu: 'Miclosanii Mari',
        comuna: 'Malu Cu Flori'
      },
      {
        nume: 'Micloşanii Mici',
        simplu: 'Miclosanii Mici',
        comuna: 'Malu Cu Flori'
      },
      {
        nume: 'Drăgăeşti Pământeni',
        simplu: 'Dragaesti Pamanteni',
        comuna: 'Manesti'
      },
      {
        nume: 'Drăgăeşti Ungureni',
        simplu: 'Dragaesti Ungureni',
        comuna: 'Manesti'
      },
      {
        nume: 'Creţuleşti',
        simplu: 'Cretulesti',
        comuna: 'Matasaru'
      },
      {
        nume: 'Odaia Turcului',
        comuna: 'Matasaru'
      },
      {
        nume: 'Poroinica',
        comuna: 'Matasaru'
      },
      {
        nume: 'Puţu cu Salcie',
        simplu: 'Putu cu Salcie',
        comuna: 'Matasaru'
      },
      {
        nume: 'Sălcioara',
        simplu: 'Salcioara',
        comuna: 'Matasaru'
      },
      {
        nume: 'Teţcoiu',
        simplu: 'Tetcoiu',
        comuna: 'Matasaru'
      },
      {
        nume: 'Chirca',
        comuna: 'Mogosani'
      },
      {
        nume: 'Cojocaru',
        comuna: 'Mogosani'
      },
      {
        nume: 'Merii',
        comuna: 'Mogosani'
      },
      {
        nume: 'Zăvoiu',
        simplu: 'Zavoiu',
        comuna: 'Mogosani'
      },
      {
        nume: 'Dobreşti',
        simplu: 'Dobresti',
        comuna: 'Moroeni'
      },
      {
        nume: 'Glod',
        comuna: 'Moroeni'
      },
      {
        nume: 'Lunca',
        comuna: 'Moroeni'
      },
      {
        nume: 'Muşcel',
        simplu: 'Muscel',
        comuna: 'Moroeni'
      },
      {
        nume: 'Pucheni',
        comuna: 'Moroeni'
      },
      {
        nume: 'Neajlovu',
        comuna: 'Morteni'
      },
      {
        nume: 'Cucuteni',
        comuna: 'Motaieni'
      },
      {
        nume: 'Ciocănari',
        simplu: 'Ciocanari',
        comuna: 'Niculesti'
      },
      {
        nume: 'Movila',
        comuna: 'Niculesti'
      },
      {
        nume: 'Cazaci',
        comuna: 'Nucet'
      },
      {
        nume: 'Ilfoveni',
        comuna: 'Nucet'
      },
      {
        nume: 'Brâncoveanu',
        simplu: 'Brancoveanu',
        comuna: 'Odobesti'
      },
      {
        nume: 'Crovu',
        comuna: 'Odobesti'
      },
      {
        nume: 'Miuleşti',
        simplu: 'Miulesti',
        comuna: 'Odobesti'
      },
      {
        nume: 'Zidurile',
        comuna: 'Odobesti'
      },
      {
        nume: 'Coada Izvorului',
        comuna: 'Petresti'
      },
      {
        nume: 'Ghergheşti',
        simplu: 'Gherghesti',
        comuna: 'Petresti'
      },
      {
        nume: 'Greci',
        comuna: 'Petresti'
      },
      {
        nume: 'Ioneşti',
        simplu: 'Ionesti',
        comuna: 'Petresti'
      },
      {
        nume: 'Potlogeni-Deal',
        comuna: 'Petresti'
      },
      {
        nume: 'Puntea de Greci',
        comuna: 'Petresti'
      },
      {
        nume: 'Dealu Frumos',
        comuna: 'Pietrosita'
      },
      {
        nume: 'Poieniţa',
        simplu: 'Poienita',
        comuna: 'Poiana'
      },
      {
        nume: 'Pitaru',
        comuna: 'Potlogi'
      },
      {
        nume: 'Podu Cristinii',
        comuna: 'Potlogi'
      },
      {
        nume: 'Româneşti',
        simplu: 'Romanesti',
        comuna: 'Potlogi'
      },
      {
        nume: 'Vlăsceni',
        simplu: 'Vlasceni',
        comuna: 'Potlogi'
      },
      {
        nume: 'Broşteni',
        simplu: 'Brosteni',
        comuna: 'Produlesti'
      },
      {
        nume: 'Costeştii din Deal',
        simplu: 'Costestii din Deal',
        comuna: 'Produlesti'
      },
      {
        nume: 'Brădăţel',
        simplu: 'Bradatel',
        comuna: 'Pucheni'
      },
      {
        nume: 'Meişoare',
        simplu: 'Meisoare',
        comuna: 'Pucheni'
      },
      {
        nume: 'Valea Largă',
        simplu: 'Valea Larga',
        comuna: 'Pucheni'
      },
      {
        nume: 'Vârfureni',
        simplu: 'Varfureni',
        comuna: 'Pucheni'
      },
      {
        nume: 'Bela',
        comuna: 'Pucioasa'
      },
      {
        nume: 'Diaconeşti',
        simplu: 'Diaconesti',
        comuna: 'Pucioasa'
      },
      {
        nume: 'Glodeni',
        comuna: 'Pucioasa'
      },
      {
        nume: 'Malurile',
        comuna: 'Pucioasa'
      },
      {
        nume: 'Miculeşti',
        simplu: 'Miculesti',
        comuna: 'Pucioasa'
      },
      {
        nume: 'Pucioasa-Sat',
        comuna: 'Pucioasa'
      },
      {
        nume: 'Bălăneşti',
        simplu: 'Balanesti',
        comuna: 'Racari'
      },
      {
        nume: 'Colacu',
        comuna: 'Racari'
      },
      {
        nume: 'Ghergani',
        comuna: 'Racari'
      },
      {
        nume: 'Ghimpaţi',
        simplu: 'Ghimpati',
        comuna: 'Racari'
      },
      {
        nume: 'Mavrodin',
        comuna: 'Racari'
      },
      {
        nume: 'Stăneşti',
        simplu: 'Stanesti',
        comuna: 'Racari'
      },
      {
        nume: 'Săbieşti',
        simplu: 'Sabiesti',
        comuna: 'Racari'
      },
      {
        nume: 'Gorgota',
        comuna: 'Razvad'
      },
      {
        nume: 'Valea Voivozilor',
        comuna: 'Razvad'
      },
      {
        nume: 'Brebu',
        comuna: 'Runcu'
      },
      {
        nume: 'Bădeni',
        simplu: 'Badeni',
        comuna: 'Runcu'
      },
      {
        nume: 'Ferestre',
        comuna: 'Runcu'
      },
      {
        nume: 'Piatra',
        comuna: 'Runcu'
      },
      {
        nume: 'Siliştea',
        simplu: 'Silistea',
        comuna: 'Runcu'
      },
      {
        nume: 'Băneşti',
        simplu: 'Banesti',
        comuna: 'Salcioara'
      },
      {
        nume: 'Cuza Vodă',
        simplu: 'Cuza Voda',
        comuna: 'Salcioara'
      },
      {
        nume: 'Cătunu',
        simplu: 'Catunu',
        comuna: 'Salcioara'
      },
      {
        nume: 'Ghineşti',
        simplu: 'Ghinesti',
        comuna: 'Salcioara'
      },
      {
        nume: 'Mircea Vodă',
        simplu: 'Mircea Voda',
        comuna: 'Salcioara'
      },
      {
        nume: 'Moara Nouă',
        simplu: 'Moara Noua',
        comuna: 'Salcioara'
      },
      {
        nume: 'Movila',
        comuna: 'Salcioara'
      },
      {
        nume: 'Podu Rizii',
        comuna: 'Salcioara'
      },
      {
        nume: 'Fierbinţi',
        simplu: 'Fierbinti',
        comuna: 'Selaru'
      },
      {
        nume: 'Glogoveanu',
        comuna: 'Selaru'
      },
      {
        nume: 'Teiş',
        simplu: 'Teis',
        comuna: 'Sotanga'
      },
      {
        nume: 'Priseaca',
        comuna: 'Targoviste'
      },
      {
        nume: 'Bâldana',
        simplu: 'Baldana',
        comuna: 'Tartasesti'
      },
      {
        nume: 'Gulia',
        comuna: 'Tartasesti'
      },
      {
        nume: 'Căprioru',
        simplu: 'Caprioru',
        comuna: 'Tatarani'
      },
      {
        nume: 'Gheboieni',
        comuna: 'Tatarani'
      },
      {
        nume: 'Priboiu',
        comuna: 'Tatarani'
      },
      {
        nume: 'Fusea',
        comuna: 'Titu'
      },
      {
        nume: 'Hagioaica',
        comuna: 'Titu'
      },
      {
        nume: 'Mereni',
        comuna: 'Titu'
      },
      {
        nume: 'Plopu',
        comuna: 'Titu'
      },
      {
        nume: 'Sălcuţa',
        simplu: 'Salcuta',
        comuna: 'Titu'
      },
      {
        nume: 'Croitori',
        comuna: 'Uliesti'
      },
      {
        nume: 'Hanu lui Pală',
        simplu: 'Hanu lui Pala',
        comuna: 'Uliesti'
      },
      {
        nume: 'Jugureni',
        comuna: 'Uliesti'
      },
      {
        nume: 'Mânăstioara',
        simplu: 'Manastioara',
        comuna: 'Uliesti'
      },
      {
        nume: 'Olteni',
        comuna: 'Uliesti'
      },
      {
        nume: 'Ragu',
        comuna: 'Uliesti'
      },
      {
        nume: 'Stavropolia',
        comuna: 'Uliesti'
      },
      {
        nume: 'Colanu',
        comuna: 'Ulmi'
      },
      {
        nume: 'Dimoiu',
        comuna: 'Ulmi'
      },
      {
        nume: 'Dumbrava',
        comuna: 'Ulmi'
      },
      {
        nume: 'Matraca',
        comuna: 'Ulmi'
      },
      {
        nume: 'Nisipurile',
        comuna: 'Ulmi'
      },
      {
        nume: 'Udreşti',
        simplu: 'Udresti',
        comuna: 'Ulmi'
      },
      {
        nume: 'Viişoara',
        simplu: 'Viisoara',
        comuna: 'Ulmi'
      },
      {
        nume: 'Brăteştii de Jos',
        simplu: 'Bratestii de Jos',
        comuna: 'Vacaresti'
      },
      {
        nume: 'Bungetu',
        comuna: 'Vacaresti'
      },
      {
        nume: 'Perşinari',
        simplu: 'Persinari',
        comuna: 'Vacaresti'
      },
      {
        nume: 'Băceşti',
        simplu: 'Bacesti',
        comuna: 'Valea Lunga'
      },
      {
        nume: 'Izvoru',
        comuna: 'Valea Lunga'
      },
      {
        nume: 'Moşia Mică',
        simplu: 'Mosia Mica',
        comuna: 'Valea Lunga'
      },
      {
        nume: 'Valea Lungă Gorgota',
        simplu: 'Valea Lunga Gorgota',
        comuna: 'Valea Lunga'
      },
      {
        nume: 'Valea Lungă Ogrea',
        simplu: 'Valea Lunga Ogrea',
        comuna: 'Valea Lunga'
      },
      {
        nume: 'Valea Lungă-Cricov',
        simplu: 'Valea Lunga-Cricov',
        comuna: 'Valea Lunga'
      },
      {
        nume: 'Valea Mare',
        comuna: 'Valea Lunga'
      },
      {
        nume: 'Valea lui Dan',
        comuna: 'Valea Lunga'
      },
      {
        nume: 'Serbăneasa',
        simplu: 'Serbaneasa',
        comuna: 'Valea Lunga'
      },
      {
        nume: 'Stubeie Tisa',
        simplu: 'Stubeie Tisa',
        comuna: 'Valea Lunga'
      },
      {
        nume: 'Feţeni',
        simplu: 'Feteni',
        comuna: 'Valea Mare'
      },
      {
        nume: 'Gârleni',
        simplu: 'Garleni',
        comuna: 'Valea Mare'
      },
      {
        nume: 'Livezile',
        comuna: 'Valea Mare'
      },
      {
        nume: 'Saru',
        comuna: 'Valea Mare'
      },
      {
        nume: 'Stratoneşti',
        simplu: 'Stratonesti',
        comuna: 'Valea Mare'
      },
      {
        nume: 'Valea Caselor',
        comuna: 'Valea Mare'
      },
      {
        nume: 'Mesteacăn',
        simplu: 'Mesteacan',
        comuna: 'Valeni-Dimbovita'
      },
      {
        nume: 'Văleni-Dâmboviţa',
        simplu: 'Valeni-Dambovita',
        comuna: 'Valeni-Dimbovita'
      },
      {
        nume: 'Cojoiu',
        comuna: 'Virfuri'
      },
      {
        nume: 'Cârlăneşti',
        simplu: 'Carlanesti',
        comuna: 'Virfuri'
      },
      {
        nume: 'Merişoru',
        simplu: 'Merisoru',
        comuna: 'Virfuri'
      },
      {
        nume: 'Stăteşti',
        simplu: 'Statesti',
        comuna: 'Virfuri'
      },
      {
        nume: 'Ulmetu',
        comuna: 'Virfuri'
      },
      {
        nume: 'Vârfuri',
        simplu: 'Varfuri',
        comuna: 'Virfuri'
      },
      {
        nume: 'Suviţa',
        simplu: 'Suvita',
        comuna: 'Virfuri'
      },
      {
        nume: 'Broşteni',
        simplu: 'Brosteni',
        comuna: 'Visina'
      },
      {
        nume: 'Izvoru',
        comuna: 'Visina'
      },
      {
        nume: 'Răscăeţi',
        simplu: 'Rascaeti',
        comuna: 'Visina'
      },
      {
        nume: 'Vultureanca',
        comuna: 'Visina'
      },
      {
        nume: 'Dospineşti',
        simplu: 'Dospinesti',
        comuna: 'Visinesti'
      },
      {
        nume: 'Sultanu',
        comuna: 'Visinesti'
      },
      {
        nume: 'Urseiu',
        comuna: 'Visinesti'
      },
      {
        nume: 'Gemenea Brătuleşti',
        simplu: 'Gemenea Bratulesti',
        comuna: 'Voinesti'
      },
      {
        nume: 'Izvoarele',
        comuna: 'Voinesti'
      },
      {
        nume: 'Lunca',
        comuna: 'Voinesti'
      },
      {
        nume: 'Manga',
        comuna: 'Voinesti'
      },
      {
        nume: 'Mânjina',
        simplu: 'Manjina',
        comuna: 'Voinesti'
      },
      {
        nume: 'Onceşti',
        simplu: 'Oncesti',
        comuna: 'Voinesti'
      },
      {
        nume: 'Suduleni',
        comuna: 'Voinesti'
      },
      {
        nume: 'Nicolaeşti',
        simplu: 'Nicolaesti',
        comuna: 'Vulcana-Bai'
      },
      {
        nume: 'Vulcana de Sus',
        comuna: 'Vulcana-Bai'
      },
      {
        nume: 'Gura Vulcanei',
        comuna: 'Vulcana-Pandele'
      },
      {
        nume: 'Lăculeţe-Gară',
        simplu: 'Laculete-Gara',
        comuna: 'Vulcana-Pandele'
      },
      {
        nume: 'Toculeşti',
        simplu: 'Toculesti',
        comuna: 'Vulcana-Pandele'
      }
    ]
  },
  {
    auto: 'DJ',
    nume: 'Dolj',
    localitati: [
      {
        nume: 'Afumaţi',
        simplu: 'Afumati'
      },
      {
        nume: 'Almăj',
        simplu: 'Almaj'
      },
      {
        nume: 'Amărăştii de Jos',
        simplu: 'Amarastii de Jos'
      },
      {
        nume: 'Amărăştii de Sus',
        simplu: 'Amarastii de Sus'
      },
      {
        nume: 'Apele Vii'
      },
      {
        nume: 'Argetoaia'
      },
      {
        nume: 'Bechet'
      },
      {
        nume: 'Bistreţ',
        simplu: 'Bistret'
      },
      {
        nume: 'Botoşeşti-Paia',
        simplu: 'Botosesti-Paia'
      },
      {
        nume: 'Brabova'
      },
      {
        nume: 'Braloştiţa',
        simplu: 'Bralostita'
      },
      {
        nume: 'Bratovoeşti',
        simplu: 'Bratovoesti'
      },
      {
        nume: 'Breasta'
      },
      {
        nume: 'Brădeşti',
        simplu: 'Bradesti'
      },
      {
        nume: 'Bucovăţ',
        simplu: 'Bucovat'
      },
      {
        nume: 'Bulzeşti',
        simplu: 'Bulzesti'
      },
      {
        nume: 'Băileşti',
        simplu: 'Bailesti'
      },
      {
        nume: 'Calafat'
      },
      {
        nume: 'Calopăr',
        simplu: 'Calopar'
      },
      {
        nume: 'Caraula'
      },
      {
        nume: 'Carpen'
      },
      {
        nume: 'Castranova'
      },
      {
        nume: 'Celaru'
      },
      {
        nume: 'Cernăteşti',
        simplu: 'Cernatesti'
      },
      {
        nume: 'Cerăt',
        simplu: 'Cerat'
      },
      {
        nume: 'Cetate'
      },
      {
        nume: 'Cioroiaşi',
        simplu: 'Cioroiasi'
      },
      {
        nume: 'Ciupercenii Noi'
      },
      {
        nume: 'Coşoveni',
        simplu: 'Cosoveni'
      },
      {
        nume: 'Coţofenii din Dos',
        simplu: 'Cotofenii din Dos'
      },
      {
        nume: 'Craiova'
      },
      {
        nume: 'Călăraşi',
        simplu: 'Calarasi'
      },
      {
        nume: 'Daneţi',
        simplu: 'Daneti'
      },
      {
        nume: 'Desa'
      },
      {
        nume: 'Dioşti',
        simplu: 'Diosti'
      },
      {
        nume: 'Dobreşti',
        simplu: 'Dobresti'
      },
      {
        nume: 'Drăgoteşti',
        simplu: 'Dragotesti'
      },
      {
        nume: 'Drănic',
        simplu: 'Dranic'
      },
      {
        nume: 'Dăbuleni',
        simplu: 'Dabuleni'
      },
      {
        nume: 'Filiaşi',
        simplu: 'Filiasi'
      },
      {
        nume: 'Fărcaş',
        simplu: 'Farcas'
      },
      {
        nume: 'Galicea Mare'
      },
      {
        nume: 'Gherceşti',
        simplu: 'Ghercesti'
      },
      {
        nume: 'Gighera'
      },
      {
        nume: 'Giubega'
      },
      {
        nume: 'Giurgiţa',
        simplu: 'Giurgita'
      },
      {
        nume: 'Gogoşu',
        simplu: 'Gogosu'
      },
      {
        nume: 'Goicea'
      },
      {
        nume: 'Goieşti',
        simplu: 'Goiesti'
      },
      {
        nume: 'Greceşti',
        simplu: 'Grecesti'
      },
      {
        nume: 'Izvoare'
      },
      {
        nume: 'Işalniţa',
        simplu: 'Isalnita'
      },
      {
        nume: 'Leu'
      },
      {
        nume: 'Lipovu'
      },
      {
        nume: 'Maglavit'
      },
      {
        nume: 'Malu Mare'
      },
      {
        nume: 'Melineşti',
        simplu: 'Melinesti'
      },
      {
        nume: 'Mischii'
      },
      {
        nume: 'Moţăţei',
        simplu: 'Motatei'
      },
      {
        nume: 'Murgaşi',
        simplu: 'Murgasi'
      },
      {
        nume: 'Mârşani',
        simplu: 'Marsani'
      },
      {
        nume: 'Măceşu de Jos',
        simplu: 'Macesu de Jos'
      },
      {
        nume: 'Măceşu de Sus',
        simplu: 'Macesu de Sus'
      },
      {
        nume: 'Negoi'
      },
      {
        nume: 'Orodel'
      },
      {
        nume: 'Ostroveni'
      },
      {
        nume: 'Perişor',
        simplu: 'Perisor'
      },
      {
        nume: 'Pieleşti',
        simplu: 'Pielesti'
      },
      {
        nume: 'Piscu Vechi'
      },
      {
        nume: 'Pleniţa',
        simplu: 'Plenita'
      },
      {
        nume: 'Podari'
      },
      {
        nume: 'Poiana Mare'
      },
      {
        nume: 'Predeşti',
        simplu: 'Predesti'
      },
      {
        nume: 'Radovan'
      },
      {
        nume: 'Rast'
      },
      {
        nume: 'Sadova'
      },
      {
        nume: 'Scăeşti',
        simplu: 'Scaesti'
      },
      {
        nume: 'Seaca de Câmp',
        simplu: 'Seaca de Camp'
      },
      {
        nume: 'Seaca de Pădure',
        simplu: 'Seaca de Padure'
      },
      {
        nume: 'Secu'
      },
      {
        nume: 'Segarcea'
      },
      {
        nume: 'Siliştea Crucii',
        simplu: 'Silistea Crucii'
      },
      {
        nume: 'Sopot'
      },
      {
        nume: 'Sălcuţa',
        simplu: 'Salcuta'
      },
      {
        nume: 'Teasc'
      },
      {
        nume: 'Terpeziţa',
        simplu: 'Terpezita'
      },
      {
        nume: 'Teslui'
      },
      {
        nume: 'Unirea'
      },
      {
        nume: 'Urzicuţa',
        simplu: 'Urzicuta'
      },
      {
        nume: 'Valea Stanciului'
      },
      {
        nume: 'Vela'
      },
      {
        nume: 'Verbiţa',
        simplu: 'Verbita'
      },
      {
        nume: 'Vârtop',
        simplu: 'Vartop'
      },
      {
        nume: 'Vârvoru de Jos',
        simplu: 'Varvoru de Jos'
      },
      {
        nume: 'Simnicu de Sus',
        simplu: 'Simnicu de Sus'
      },
      {
        nume: 'Tuglui',
        simplu: 'Tuglui'
      },
      {
        nume: 'Boureni',
        comuna: 'Afumati'
      },
      {
        nume: 'Covei',
        comuna: 'Afumati'
      },
      {
        nume: 'Beharca',
        comuna: 'Almaj'
      },
      {
        nume: 'Bogea',
        comuna: 'Almaj'
      },
      {
        nume: 'Coţofenii din Faţă',
        simplu: 'Cotofenii din Fata',
        comuna: 'Almaj'
      },
      {
        nume: 'Moşneni',
        simplu: 'Mosneni',
        comuna: 'Almaj'
      },
      {
        nume: 'Sitoaia',
        simplu: 'Sitoaia',
        comuna: 'Almaj'
      },
      {
        nume: 'Ocolna',
        comuna: 'Amarastii De Jos'
      },
      {
        nume: 'Prapor',
        comuna: 'Amarastii De Jos'
      },
      {
        nume: 'Dobroteşti',
        simplu: 'Dobrotesti',
        comuna: 'Amarastii De Sus'
      },
      {
        nume: 'Nisipuri',
        comuna: 'Amarastii De Sus'
      },
      {
        nume: 'Zvorsca',
        comuna: 'Amarastii De Sus'
      },
      {
        nume: 'Berbeşu',
        simplu: 'Berbesu',
        comuna: 'Argetoaia'
      },
      {
        nume: 'Băranu',
        simplu: 'Baranu',
        comuna: 'Argetoaia'
      },
      {
        nume: 'Iordăcheşti',
        simplu: 'Iordachesti',
        comuna: 'Argetoaia'
      },
      {
        nume: 'Leordoasa',
        comuna: 'Argetoaia'
      },
      {
        nume: 'Malumnic',
        comuna: 'Argetoaia'
      },
      {
        nume: 'Novac',
        comuna: 'Argetoaia'
      },
      {
        nume: 'Piria',
        comuna: 'Argetoaia'
      },
      {
        nume: 'Poiana Fântânii',
        simplu: 'Poiana Fantanii',
        comuna: 'Argetoaia'
      },
      {
        nume: 'Salcia',
        comuna: 'Argetoaia'
      },
      {
        nume: 'Teascu din Deal',
        comuna: 'Argetoaia'
      },
      {
        nume: 'Ursoaia',
        comuna: 'Argetoaia'
      },
      {
        nume: 'Balasan',
        comuna: 'Bailesti'
      },
      {
        nume: 'Bârca',
        simplu: 'Barca',
        comuna: 'Birca'
      },
      {
        nume: 'Bistreţu Nou',
        simplu: 'Bistretu Nou',
        comuna: 'Bistret'
      },
      {
        nume: 'Brânduşa',
        simplu: 'Brandusa',
        comuna: 'Bistret'
      },
      {
        nume: 'Plosca',
        comuna: 'Bistret'
      },
      {
        nume: 'Caraiman',
        comuna: 'Brabova'
      },
      {
        nume: 'Mosna',
        comuna: 'Brabova'
      },
      {
        nume: 'Răchita de Jos',
        simplu: 'Rachita de Jos',
        comuna: 'Brabova'
      },
      {
        nume: 'Urdiniţa',
        simplu: 'Urdinita',
        comuna: 'Brabova'
      },
      {
        nume: 'Voita',
        comuna: 'Brabova'
      },
      {
        nume: 'Brădeştii Bătrâni',
        simplu: 'Bradestii Batrani',
        comuna: 'Bradesti'
      },
      {
        nume: 'Meteu',
        comuna: 'Bradesti'
      },
      {
        nume: 'Piscani',
        comuna: 'Bradesti'
      },
      {
        nume: 'Răcarii de Jos',
        simplu: 'Racarii de Jos',
        comuna: 'Bradesti'
      },
      {
        nume: 'Tatomireşti',
        simplu: 'Tatomiresti',
        comuna: 'Bradesti'
      },
      {
        nume: 'Ciocanele',
        comuna: 'Bralostita'
      },
      {
        nume: 'Racoviţa',
        simplu: 'Racovita',
        comuna: 'Bralostita'
      },
      {
        nume: 'Schitu',
        comuna: 'Bralostita'
      },
      {
        nume: 'Sfârcea',
        simplu: 'Sfarcea',
        comuna: 'Bralostita'
      },
      {
        nume: 'Valea Fântânilor',
        simplu: 'Valea Fantanilor',
        comuna: 'Bralostita'
      },
      {
        nume: 'Bădoşi',
        simplu: 'Badosi',
        comuna: 'Bratovoesti'
      },
      {
        nume: 'Georocu Mare',
        comuna: 'Bratovoesti'
      },
      {
        nume: 'Prunet',
        comuna: 'Bratovoesti'
      },
      {
        nume: 'Rojişte',
        simplu: 'Rojiste',
        comuna: 'Bratovoesti'
      },
      {
        nume: 'Tâmbureşti',
        simplu: 'Tamburesti',
        comuna: 'Bratovoesti'
      },
      {
        nume: 'Cotu',
        comuna: 'Breasta'
      },
      {
        nume: 'Crovna',
        comuna: 'Breasta'
      },
      {
        nume: 'Făget',
        simplu: 'Faget',
        comuna: 'Breasta'
      },
      {
        nume: 'Obedin',
        comuna: 'Breasta'
      },
      {
        nume: 'Roşieni',
        simplu: 'Rosieni',
        comuna: 'Breasta'
      },
      {
        nume: 'Valea Lungului',
        comuna: 'Breasta'
      },
      {
        nume: 'Cârligei',
        simplu: 'Carligei',
        comuna: 'Bucovat'
      },
      {
        nume: 'Italieni',
        comuna: 'Bucovat'
      },
      {
        nume: 'Leamna de Jos',
        comuna: 'Bucovat'
      },
      {
        nume: 'Leamna de Sus',
        comuna: 'Bucovat'
      },
      {
        nume: 'Palilula',
        comuna: 'Bucovat'
      },
      {
        nume: 'Sărbătoarea',
        simplu: 'Sarbatoarea',
        comuna: 'Bucovat'
      },
      {
        nume: 'Frăţila',
        simplu: 'Fratila',
        comuna: 'Bulzesti'
      },
      {
        nume: 'Gura Racului',
        comuna: 'Bulzesti'
      },
      {
        nume: 'Piscu Lung',
        comuna: 'Bulzesti'
      },
      {
        nume: 'Poienile',
        comuna: 'Bulzesti'
      },
      {
        nume: 'Prejoi',
        comuna: 'Bulzesti'
      },
      {
        nume: 'Seculeşti',
        simplu: 'Seculesti',
        comuna: 'Bulzesti'
      },
      {
        nume: 'Stoiceşti',
        simplu: 'Stoicesti',
        comuna: 'Bulzesti'
      },
      {
        nume: 'Sălişte',
        simplu: 'Saliste',
        comuna: 'Bulzesti'
      },
      {
        nume: 'Înfrăţirea',
        simplu: 'Infratirea',
        comuna: 'Bulzesti'
      },
      {
        nume: 'Basarabi',
        comuna: 'Calafat'
      },
      {
        nume: 'Ciupercenii Vechi',
        comuna: 'Calafat'
      },
      {
        nume: 'Golenţi',
        simplu: 'Golenti',
        comuna: 'Calafat'
      },
      {
        nume: 'Sărata',
        simplu: 'Sarata',
        comuna: 'Calarasi'
      },
      {
        nume: 'Belcinu',
        comuna: 'Calopar'
      },
      {
        nume: 'Bâzdâna',
        simplu: 'Bazdana',
        comuna: 'Calopar'
      },
      {
        nume: 'Panaghia',
        comuna: 'Calopar'
      },
      {
        nume: 'Sălcuţa',
        simplu: 'Salcuta',
        comuna: 'Calopar'
      },
      {
        nume: 'Cleanov',
        comuna: 'Carpen'
      },
      {
        nume: 'Gebleşti',
        simplu: 'Geblesti',
        comuna: 'Carpen'
      },
      {
        nume: 'Puţuri',
        simplu: 'Puturi',
        comuna: 'Castranova'
      },
      {
        nume: 'Ghizdăveşti',
        simplu: 'Ghizdavesti',
        comuna: 'Celaru'
      },
      {
        nume: 'Marotinu de Jos',
        comuna: 'Celaru'
      },
      {
        nume: 'Marotinu de Sus',
        comuna: 'Celaru'
      },
      {
        nume: 'Soreni',
        comuna: 'Celaru'
      },
      {
        nume: 'Malaica',
        comuna: 'Cerat'
      },
      {
        nume: 'Corniţa',
        simplu: 'Cornita',
        comuna: 'Cernatesti'
      },
      {
        nume: 'Rasnicu Bătrân',
        simplu: 'Rasnicu Batran',
        comuna: 'Cernatesti'
      },
      {
        nume: 'Rasnicu Oghian',
        comuna: 'Cernatesti'
      },
      {
        nume: 'Tiu',
        simplu: 'Tiu',
        comuna: 'Cernatesti'
      },
      {
        nume: 'Moreni',
        comuna: 'Cetate'
      },
      {
        nume: 'Cetăţuia',
        simplu: 'Cetatuia',
        comuna: 'Cioroiasi'
      },
      {
        nume: 'Cioroiu Nou',
        comuna: 'Cioroiasi'
      },
      {
        nume: 'Smârdan',
        simplu: 'Smardan',
        comuna: 'Ciupercenii Noi'
      },
      {
        nume: 'Cârcea',
        simplu: 'Carcea',
        comuna: 'Cosoveni'
      },
      {
        nume: 'Mihăiţa',
        simplu: 'Mihaita',
        comuna: 'Cotofenii Din Dos'
      },
      {
        nume: 'Potmelţu',
        simplu: 'Potmeltu',
        comuna: 'Cotofenii Din Dos'
      },
      {
        nume: 'Cernele',
        comuna: 'Craiova'
      },
      {
        nume: 'Cernelele de Sus',
        comuna: 'Craiova'
      },
      {
        nume: 'Făcăi',
        simplu: 'Facai',
        comuna: 'Craiova'
      },
      {
        nume: 'Izvorul Rece',
        comuna: 'Craiova'
      },
      {
        nume: 'Mofleni',
        comuna: 'Craiova'
      },
      {
        nume: 'Popoveni',
        comuna: 'Craiova'
      },
      {
        nume: 'Rovine',
        comuna: 'Craiova'
      },
      {
        nume: 'Simnicu de Jos',
        simplu: 'Simnicu de Jos',
        comuna: 'Craiova'
      },
      {
        nume: 'Chiaşu',
        simplu: 'Chiasu',
        comuna: 'Dabuleni'
      },
      {
        nume: 'Brabeţi',
        simplu: 'Brabeti',
        comuna: 'Daneti'
      },
      {
        nume: 'Branişte',
        simplu: 'Braniste',
        comuna: 'Daneti'
      },
      {
        nume: 'Locusteni',
        comuna: 'Daneti'
      },
      {
        nume: 'Ciocăneşti',
        simplu: 'Ciocanesti',
        comuna: 'Diosti'
      },
      {
        nume: 'Radomir',
        comuna: 'Diosti'
      },
      {
        nume: 'Căciulăteşti',
        simplu: 'Caciulatesti',
        comuna: 'Dobresti'
      },
      {
        nume: 'Georocel',
        comuna: 'Dobresti'
      },
      {
        nume: 'Murta',
        comuna: 'Dobresti'
      },
      {
        nume: 'Toceni',
        comuna: 'Dobresti'
      },
      {
        nume: 'Beneşti',
        simplu: 'Benesti',
        comuna: 'Dragotesti'
      },
      {
        nume: 'Bobeanu',
        comuna: 'Dragotesti'
      },
      {
        nume: 'Buzduc',
        comuna: 'Dragotesti'
      },
      {
        nume: 'Popânzăleşti',
        simplu: 'Popanzalesti',
        comuna: 'Dragotesti'
      },
      {
        nume: 'Viişoara',
        simplu: 'Viisoara',
        comuna: 'Dragotesti'
      },
      {
        nume: 'Booveni',
        comuna: 'Dranic'
      },
      {
        nume: 'Foişor',
        simplu: 'Foisor',
        comuna: 'Dranic'
      },
      {
        nume: 'Padea',
        comuna: 'Dranic'
      },
      {
        nume: 'Amărăşti',
        simplu: 'Amarasti',
        comuna: 'Farcas'
      },
      {
        nume: 'Golumbelu',
        comuna: 'Farcas'
      },
      {
        nume: 'Golumbu',
        comuna: 'Farcas'
      },
      {
        nume: 'Mofleşti',
        simplu: 'Moflesti',
        comuna: 'Farcas'
      },
      {
        nume: 'Nistoi',
        comuna: 'Farcas'
      },
      {
        nume: 'Plopu Amărăşti',
        simplu: 'Plopu Amarasti',
        comuna: 'Farcas'
      },
      {
        nume: 'Puţinei',
        simplu: 'Putinei',
        comuna: 'Farcas'
      },
      {
        nume: 'Soceni',
        comuna: 'Farcas'
      },
      {
        nume: 'Tălpaş',
        simplu: 'Talpas',
        comuna: 'Farcas'
      },
      {
        nume: 'Almăjel',
        simplu: 'Almajel',
        comuna: 'Filiasi'
      },
      {
        nume: 'Branişte',
        simplu: 'Braniste',
        comuna: 'Filiasi'
      },
      {
        nume: 'Bâlta',
        simplu: 'Balta',
        comuna: 'Filiasi'
      },
      {
        nume: 'Fratoştiţa',
        simplu: 'Fratostita',
        comuna: 'Filiasi'
      },
      {
        nume: 'Răcarii de Sus',
        simplu: 'Racarii de Sus',
        comuna: 'Filiasi'
      },
      {
        nume: 'Uscăci',
        simplu: 'Uscaci',
        comuna: 'Filiasi'
      },
      {
        nume: 'Gârleşti',
        simplu: 'Garlesti',
        comuna: 'Ghercesti'
      },
      {
        nume: 'Luncşoru',
        simplu: 'Luncsoru',
        comuna: 'Ghercesti'
      },
      {
        nume: 'Ungureni',
        comuna: 'Ghercesti'
      },
      {
        nume: 'Ungurenii Mici',
        comuna: 'Ghercesti'
      },
      {
        nume: 'Nedeia',
        comuna: 'Gighera'
      },
      {
        nume: 'Zăval',
        simplu: 'Zaval',
        comuna: 'Gighera'
      },
      {
        nume: 'Comoşteni',
        simplu: 'Comosteni',
        comuna: 'Gingiova'
      },
      {
        nume: 'Gângiova',
        simplu: 'Gangiova',
        comuna: 'Gingiova'
      },
      {
        nume: 'Galiciuica',
        comuna: 'Giubega'
      },
      {
        nume: 'Curmătura',
        simplu: 'Curmatura',
        comuna: 'Giurgita'
      },
      {
        nume: 'Filaret',
        comuna: 'Giurgita'
      },
      {
        nume: 'Gogoşiţa',
        simplu: 'Gogosita',
        comuna: 'Gogosu'
      },
      {
        nume: 'Stefănel',
        simplu: 'Stefanel',
        comuna: 'Gogosu'
      },
      {
        nume: 'Cârna',
        simplu: 'Carna',
        comuna: 'Goicea'
      },
      {
        nume: 'Adâncata',
        simplu: 'Adancata',
        comuna: 'Goiesti'
      },
      {
        nume: 'Fântâni',
        simplu: 'Fantani',
        comuna: 'Goiesti'
      },
      {
        nume: 'Gruiţa',
        simplu: 'Gruita',
        comuna: 'Goiesti'
      },
      {
        nume: 'Mogoşeşti',
        simplu: 'Mogosesti',
        comuna: 'Goiesti'
      },
      {
        nume: 'Muereni',
        comuna: 'Goiesti'
      },
      {
        nume: 'Mălăeşti',
        simplu: 'Malaesti',
        comuna: 'Goiesti'
      },
      {
        nume: 'Pioreşti',
        simplu: 'Pioresti',
        comuna: 'Goiesti'
      },
      {
        nume: 'Pometeşti',
        simplu: 'Pometesti',
        comuna: 'Goiesti'
      },
      {
        nume: 'Popeasa',
        comuna: 'Goiesti'
      },
      {
        nume: 'Vladimir',
        comuna: 'Goiesti'
      },
      {
        nume: 'Zlătari',
        simplu: 'Zlatari',
        comuna: 'Goiesti'
      },
      {
        nume: 'Tandăra',
        simplu: 'Tandara',
        comuna: 'Goiesti'
      },
      {
        nume: 'Busu',
        comuna: 'Grecesti'
      },
      {
        nume: 'Busuleţu',
        simplu: 'Busuletu',
        comuna: 'Grecesti'
      },
      {
        nume: 'Bărboi',
        simplu: 'Barboi',
        comuna: 'Grecesti'
      },
      {
        nume: 'Gropanele',
        comuna: 'Grecesti'
      },
      {
        nume: 'Grădiştea',
        simplu: 'Gradistea',
        comuna: 'Grecesti'
      },
      {
        nume: 'Corlate',
        comuna: 'Izvoare'
      },
      {
        nume: 'Domnu Tudor',
        comuna: 'Izvoare'
      },
      {
        nume: 'Zănoaga',
        simplu: 'Zanoaga',
        comuna: 'Leu'
      },
      {
        nume: 'Lipovu de Sus',
        comuna: 'Lipovu'
      },
      {
        nume: 'Săpata',
        simplu: 'Sapata',
        comuna: 'Macesu De Jos'
      },
      {
        nume: 'Hunia',
        comuna: 'Maglavit'
      },
      {
        nume: 'Ghindeni',
        comuna: 'Malu Mare'
      },
      {
        nume: 'Preajba',
        comuna: 'Malu Mare'
      },
      {
        nume: 'Bodăieşti',
        simplu: 'Bodaiesti',
        comuna: 'Melinesti'
      },
      {
        nume: 'Bodăieştii de Sus',
        simplu: 'Bodaiestii de Sus',
        comuna: 'Melinesti'
      },
      {
        nume: 'Godeni',
        comuna: 'Melinesti'
      },
      {
        nume: 'Muieruşu',
        simplu: 'Muierusu',
        comuna: 'Melinesti'
      },
      {
        nume: 'Negoieşti',
        simplu: 'Negoiesti',
        comuna: 'Melinesti'
      },
      {
        nume: 'Odoleni',
        comuna: 'Melinesti'
      },
      {
        nume: 'Ohaba',
        comuna: 'Melinesti'
      },
      {
        nume: 'Ploştina',
        simplu: 'Plostina',
        comuna: 'Melinesti'
      },
      {
        nume: 'Popeşti',
        simplu: 'Popesti',
        comuna: 'Melinesti'
      },
      {
        nume: 'Spineni',
        comuna: 'Melinesti'
      },
      {
        nume: 'Valea Mare',
        comuna: 'Melinesti'
      },
      {
        nume: 'Valea Muierii de Jos',
        comuna: 'Melinesti'
      },
      {
        nume: 'Călineşti',
        simplu: 'Calinesti',
        comuna: 'Mischii'
      },
      {
        nume: 'Gogoşeşti',
        simplu: 'Gogosesti',
        comuna: 'Mischii'
      },
      {
        nume: 'Mlecăneşti',
        simplu: 'Mlecanesti',
        comuna: 'Mischii'
      },
      {
        nume: 'Motoci',
        comuna: 'Mischii'
      },
      {
        nume: 'Urecheşti',
        simplu: 'Urechesti',
        comuna: 'Mischii'
      },
      {
        nume: 'Dobridor',
        comuna: 'Motatei'
      },
      {
        nume: 'Moţăţei Gară',
        simplu: 'Motatei Gara',
        comuna: 'Motatei'
      },
      {
        nume: 'Balota de Jos',
        comuna: 'Murgasi'
      },
      {
        nume: 'Balota de Sus',
        comuna: 'Murgasi'
      },
      {
        nume: 'Buşteni',
        simplu: 'Busteni',
        comuna: 'Murgasi'
      },
      {
        nume: 'Gaia',
        comuna: 'Murgasi'
      },
      {
        nume: 'Picăturile',
        simplu: 'Picaturile',
        comuna: 'Murgasi'
      },
      {
        nume: 'Rupturile',
        comuna: 'Murgasi'
      },
      {
        nume: 'Velesti',
        comuna: 'Murgasi'
      },
      {
        nume: 'Catane',
        comuna: 'Negoi'
      },
      {
        nume: 'Catanele Noi',
        comuna: 'Negoi'
      },
      {
        nume: 'Bechet',
        comuna: 'Orodel'
      },
      {
        nume: 'Cornu',
        comuna: 'Orodel'
      },
      {
        nume: 'Călugărei',
        simplu: 'Calugarei',
        comuna: 'Orodel'
      },
      {
        nume: 'Teiu',
        comuna: 'Orodel'
      },
      {
        nume: 'Lişteava',
        simplu: 'Listeava',
        comuna: 'Ostroveni'
      },
      {
        nume: 'Mărăcinele',
        simplu: 'Maracinele',
        comuna: 'Perisor'
      },
      {
        nume: 'Câmpeni',
        simplu: 'Campeni',
        comuna: 'Pielesti'
      },
      {
        nume: 'Lânga',
        simplu: 'Langa',
        comuna: 'Pielesti'
      },
      {
        nume: 'Ghidici',
        comuna: 'Piscu Vechi'
      },
      {
        nume: 'Pisculeţ',
        simplu: 'Pisculet',
        comuna: 'Piscu Vechi'
      },
      {
        nume: 'Castrele Traiane',
        comuna: 'Plenita'
      },
      {
        nume: 'Balta Verde',
        comuna: 'Podari'
      },
      {
        nume: 'Branişte',
        simplu: 'Braniste',
        comuna: 'Podari'
      },
      {
        nume: 'Gura Văii',
        simplu: 'Gura Vaii',
        comuna: 'Podari'
      },
      {
        nume: 'Livezi',
        comuna: 'Podari'
      },
      {
        nume: 'Tunarii Noi',
        comuna: 'Poiana Mare'
      },
      {
        nume: 'Tunarii Vechi',
        comuna: 'Poiana Mare'
      },
      {
        nume: 'Bucicani',
        comuna: 'Predesti'
      },
      {
        nume: 'Cârstovani',
        simplu: 'Carstovani',
        comuna: 'Predesti'
      },
      {
        nume: 'Frasin',
        comuna: 'Predesti'
      },
      {
        nume: 'Milovan',
        comuna: 'Predesti'
      },
      {
        nume: 'Pleşoi',
        simplu: 'Plesoi',
        comuna: 'Predesti'
      },
      {
        nume: 'Predeştii Mici',
        simplu: 'Predestii Mici',
        comuna: 'Predesti'
      },
      {
        nume: 'Fântânele',
        simplu: 'Fantanele',
        comuna: 'Radovan'
      },
      {
        nume: 'Târnava',
        simplu: 'Tarnava',
        comuna: 'Radovan'
      },
      {
        nume: 'Întorsura',
        simplu: 'Intorsura',
        comuna: 'Radovan'
      },
      {
        nume: 'Bojoiu',
        comuna: 'Robanesti'
      },
      {
        nume: 'Golfin',
        comuna: 'Robanesti'
      },
      {
        nume: 'Lăcriţa Mare',
        simplu: 'Lacrita Mare',
        comuna: 'Robanesti'
      },
      {
        nume: 'Lăcriţa Mică',
        simplu: 'Lacrita Mica',
        comuna: 'Robanesti'
      },
      {
        nume: 'Robăneştii de Jos',
        simplu: 'Robanestii de Jos',
        comuna: 'Robanesti'
      },
      {
        nume: 'Robăneştii de Sus',
        simplu: 'Robanestii de Sus',
        comuna: 'Robanesti'
      },
      {
        nume: 'Piscu Sadovei',
        comuna: 'Sadova'
      },
      {
        nume: 'Mârza',
        simplu: 'Marza',
        comuna: 'Salcuta'
      },
      {
        nume: 'Plopşor',
        simplu: 'Plopsor',
        comuna: 'Salcuta'
      },
      {
        nume: 'Tencănău',
        simplu: 'Tencanau',
        comuna: 'Salcuta'
      },
      {
        nume: 'Valea lui Pătru',
        simplu: 'Valea lui Patru',
        comuna: 'Scaesti'
      },
      {
        nume: 'Piscu Nou',
        comuna: 'Seaca De Camp'
      },
      {
        nume: 'Răchita de Sus',
        simplu: 'Rachita de Sus',
        comuna: 'Seaca De Padure'
      },
      {
        nume: 'Veleni',
        comuna: 'Seaca De Padure'
      },
      {
        nume: 'Comănicea',
        simplu: 'Comanicea',
        comuna: 'Secu'
      },
      {
        nume: 'Smadovicioara de Secu',
        comuna: 'Secu'
      },
      {
        nume: 'Sumandra',
        simplu: 'Sumandra',
        comuna: 'Secu'
      },
      {
        nume: 'Albeşti',
        simplu: 'Albesti',
        comuna: 'Simnicu De Sus'
      },
      {
        nume: 'Cornetu',
        comuna: 'Simnicu De Sus'
      },
      {
        nume: 'Deleni',
        comuna: 'Simnicu De Sus'
      },
      {
        nume: 'Dudoviceşti',
        simplu: 'Dudovicesti',
        comuna: 'Simnicu De Sus'
      },
      {
        nume: 'Duţuleşti',
        simplu: 'Dutulesti',
        comuna: 'Simnicu De Sus'
      },
      {
        nume: 'Floreşti',
        simplu: 'Floresti',
        comuna: 'Simnicu De Sus'
      },
      {
        nume: 'Izvor',
        comuna: 'Simnicu De Sus'
      },
      {
        nume: 'Jieni',
        comuna: 'Simnicu De Sus'
      },
      {
        nume: 'Leşile',
        simplu: 'Lesile',
        comuna: 'Simnicu De Sus'
      },
      {
        nume: 'Mileşti',
        simplu: 'Milesti',
        comuna: 'Simnicu De Sus'
      },
      {
        nume: 'Româneşti',
        simplu: 'Romanesti',
        comuna: 'Simnicu De Sus'
      },
      {
        nume: 'Başcov',
        simplu: 'Bascov',
        comuna: 'Sopot'
      },
      {
        nume: 'Beloţ',
        simplu: 'Belot',
        comuna: 'Sopot'
      },
      {
        nume: 'Cernat',
        comuna: 'Sopot'
      },
      {
        nume: 'Pereni',
        comuna: 'Sopot'
      },
      {
        nume: 'Pietroaia',
        comuna: 'Sopot'
      },
      {
        nume: 'Sârsca',
        simplu: 'Sarsca',
        comuna: 'Sopot'
      },
      {
        nume: 'Secui',
        comuna: 'Teasc'
      },
      {
        nume: 'Căciulatu',
        simplu: 'Caciulatu',
        comuna: 'Terpezita'
      },
      {
        nume: 'Căruia',
        simplu: 'Caruia',
        comuna: 'Terpezita'
      },
      {
        nume: 'Floran',
        comuna: 'Terpezita'
      },
      {
        nume: 'Lazu',
        comuna: 'Terpezita'
      },
      {
        nume: 'Coşereni',
        simplu: 'Cosereni',
        comuna: 'Teslui'
      },
      {
        nume: 'Fântânele',
        simplu: 'Fantanele',
        comuna: 'Teslui'
      },
      {
        nume: 'Preajba de Jos',
        comuna: 'Teslui'
      },
      {
        nume: 'Preajba de Pădure',
        simplu: 'Preajba de Padure',
        comuna: 'Teslui'
      },
      {
        nume: 'Urieni',
        comuna: 'Teslui'
      },
      {
        nume: 'Viişoara Moşneni',
        simplu: 'Viisoara Mosneni',
        comuna: 'Teslui'
      },
      {
        nume: 'Tărţăl',
        simplu: 'Tartal',
        comuna: 'Teslui'
      },
      {
        nume: 'Jiul',
        comuna: 'Tuglui'
      },
      {
        nume: 'Urzica Mare',
        comuna: 'Urzicuta'
      },
      {
        nume: 'Horezu Poenari',
        comuna: 'Valea Stanciului'
      },
      {
        nume: 'Bujor',
        comuna: 'Varvoru De Jos'
      },
      {
        nume: 'Ciutura',
        comuna: 'Varvoru De Jos'
      },
      {
        nume: 'Criva',
        comuna: 'Varvoru De Jos'
      },
      {
        nume: 'Dobromira',
        comuna: 'Varvoru De Jos'
      },
      {
        nume: 'Drăgoaia',
        simplu: 'Dragoaia',
        comuna: 'Varvoru De Jos'
      },
      {
        nume: 'Gabru',
        comuna: 'Varvoru De Jos'
      },
      {
        nume: 'Vârvor',
        simplu: 'Varvor',
        comuna: 'Varvoru De Jos'
      },
      {
        nume: 'Bucovicior',
        comuna: 'Vela'
      },
      {
        nume: 'Cetăţuia',
        simplu: 'Cetatuia',
        comuna: 'Vela'
      },
      {
        nume: 'Desnăţui',
        simplu: 'Desnatui',
        comuna: 'Vela'
      },
      {
        nume: 'Gubaucea',
        comuna: 'Vela'
      },
      {
        nume: 'Segleţ',
        simplu: 'Seglet',
        comuna: 'Vela'
      },
      {
        nume: 'Suharu',
        comuna: 'Vela'
      },
      {
        nume: 'Stiubei',
        simplu: 'Stiubei',
        comuna: 'Vela'
      },
      {
        nume: 'Verbicioara',
        comuna: 'Verbita'
      }
    ]
  },
  {
    auto: 'GL',
    nume: 'Galaţi',
    localitati: [
      {
        nume: 'Barcea'
      },
      {
        nume: 'Bereşti',
        simplu: 'Beresti'
      },
      {
        nume: 'Bereşti-Meria',
        simplu: 'Beresti-Meria'
      },
      {
        nume: 'Braniştea',
        simplu: 'Branistea'
      },
      {
        nume: 'Brăhăşeşti',
        simplu: 'Brahasesti'
      },
      {
        nume: 'Buciumeni'
      },
      {
        nume: 'Băleni',
        simplu: 'Baleni'
      },
      {
        nume: 'Bălăbăneşti',
        simplu: 'Balabanesti'
      },
      {
        nume: 'Bălăşeşti',
        simplu: 'Balasesti'
      },
      {
        nume: 'Băneasa',
        simplu: 'Baneasa'
      },
      {
        nume: 'Cavadineşti',
        simplu: 'Cavadinesti'
      },
      {
        nume: 'Cerţeşti',
        simplu: 'Certesti'
      },
      {
        nume: 'Corni'
      },
      {
        nume: 'Corod'
      },
      {
        nume: 'Cosmeşti',
        simplu: 'Cosmesti'
      },
      {
        nume: 'Costache Negri'
      },
      {
        nume: 'Cuca'
      },
      {
        nume: 'Cudalbi'
      },
      {
        nume: 'Drăguşeni',
        simplu: 'Draguseni'
      },
      {
        nume: 'Drăgăneşti',
        simplu: 'Draganesti'
      },
      {
        nume: 'Folteşti',
        simplu: 'Foltesti'
      },
      {
        nume: 'Frumuşiţa',
        simplu: 'Frumusita'
      },
      {
        nume: 'Fundeni'
      },
      {
        nume: 'Fârţăneşti',
        simplu: 'Fartanesti'
      },
      {
        nume: 'Galaţi',
        simplu: 'Galati'
      },
      {
        nume: 'Ghidigeni'
      },
      {
        nume: 'Gohor'
      },
      {
        nume: 'Griviţa',
        simplu: 'Grivita'
      },
      {
        nume: 'Independenţa',
        simplu: 'Independenta'
      },
      {
        nume: 'Iveşti',
        simplu: 'Ivesti'
      },
      {
        nume: 'Jorăşti',
        simplu: 'Jorasti'
      },
      {
        nume: 'Lieşti',
        simplu: 'Liesti'
      },
      {
        nume: 'Matca'
      },
      {
        nume: 'Movileni'
      },
      {
        nume: 'Munteni'
      },
      {
        nume: 'Măstăcani',
        simplu: 'Mastacani'
      },
      {
        nume: 'Nicoreşti',
        simplu: 'Nicoresti'
      },
      {
        nume: 'Nămoloasa',
        simplu: 'Namoloasa'
      },
      {
        nume: 'Oancea'
      },
      {
        nume: 'Pechea'
      },
      {
        nume: 'Piscu'
      },
      {
        nume: 'Priponeşti',
        simplu: 'Priponesti'
      },
      {
        nume: 'Rediu'
      },
      {
        nume: 'Schela'
      },
      {
        nume: 'Scânteieşti',
        simplu: 'Scanteiesti'
      },
      {
        nume: 'Slobozia Conachi'
      },
      {
        nume: 'Smulţi',
        simplu: 'Smulti'
      },
      {
        nume: 'Smârdan',
        simplu: 'Smardan'
      },
      {
        nume: 'Suceveni'
      },
      {
        nume: 'Tecuci'
      },
      {
        nume: 'Tudor Vladimirescu'
      },
      {
        nume: 'Tuluceşti',
        simplu: 'Tulucesti'
      },
      {
        nume: 'Târgu Bujor',
        simplu: 'Targu Bujor'
      },
      {
        nume: 'Umbrăreşti',
        simplu: 'Umbraresti'
      },
      {
        nume: 'Valea Mărului',
        simplu: 'Valea Marului'
      },
      {
        nume: 'Vlădeşti',
        simplu: 'Vladesti'
      },
      {
        nume: 'Vânători',
        simplu: 'Vanatori'
      },
      {
        nume: 'Vărlezi',
        simplu: 'Varlezi'
      },
      {
        nume: 'Sendreni',
        simplu: 'Sendreni'
      },
      {
        nume: 'Tepu',
        simplu: 'Tepu'
      },
      {
        nume: 'Bursucani',
        comuna: 'Balabanesti'
      },
      {
        nume: 'Cruceanu',
        comuna: 'Balabanesti'
      },
      {
        nume: 'Lungeşti',
        simplu: 'Lungesti',
        comuna: 'Balabanesti'
      },
      {
        nume: 'Rădeşti',
        simplu: 'Radesti',
        comuna: 'Balabanesti'
      },
      {
        nume: 'Zimbru',
        comuna: 'Balabanesti'
      },
      {
        nume: 'Ciureşti',
        simplu: 'Ciuresti',
        comuna: 'Balasesti'
      },
      {
        nume: 'Ciureştii Noi',
        simplu: 'Ciurestii Noi',
        comuna: 'Balasesti'
      },
      {
        nume: 'Pupezeni',
        comuna: 'Balasesti'
      },
      {
        nume: 'Roşcani',
        simplu: 'Roscani',
        comuna: 'Baneasa'
      },
      {
        nume: 'Podoleni',
        comuna: 'Barcea'
      },
      {
        nume: 'Aldeşti',
        simplu: 'Aldesti',
        comuna: 'Beresti-Meria'
      },
      {
        nume: 'Balinteşti',
        simplu: 'Balintesti',
        comuna: 'Beresti-Meria'
      },
      {
        nume: 'Onciu',
        comuna: 'Beresti-Meria'
      },
      {
        nume: 'Pleşa',
        simplu: 'Plesa',
        comuna: 'Beresti-Meria'
      },
      {
        nume: 'Prodăneşti',
        simplu: 'Prodanesti',
        comuna: 'Beresti-Meria'
      },
      {
        nume: 'Puricani',
        comuna: 'Beresti-Meria'
      },
      {
        nume: 'Slivna',
        comuna: 'Beresti-Meria'
      },
      {
        nume: 'Săseni',
        simplu: 'Saseni',
        comuna: 'Beresti-Meria'
      },
      {
        nume: 'Sipote',
        simplu: 'Sipote',
        comuna: 'Beresti-Meria'
      },
      {
        nume: 'Corcioveni',
        comuna: 'Brahasesti'
      },
      {
        nume: 'Cosiţeni',
        simplu: 'Cositeni',
        comuna: 'Brahasesti'
      },
      {
        nume: 'Toflea',
        comuna: 'Brahasesti'
      },
      {
        nume: 'Lozova',
        comuna: 'Branistea'
      },
      {
        nume: 'Traian',
        comuna: 'Branistea'
      },
      {
        nume: 'Vasile Alecsandri',
        comuna: 'Branistea'
      },
      {
        nume: 'Hănţeşti',
        simplu: 'Hantesti',
        comuna: 'Buciumeni'
      },
      {
        nume: 'Tecucelu Sec',
        comuna: 'Buciumeni'
      },
      {
        nume: 'Vizureşti',
        simplu: 'Vizuresti',
        comuna: 'Buciumeni'
      },
      {
        nume: 'Comăneşti',
        simplu: 'Comanesti',
        comuna: 'Cavadinesti'
      },
      {
        nume: 'Găneşti',
        simplu: 'Ganesti',
        comuna: 'Cavadinesti'
      },
      {
        nume: 'Vădeni',
        simplu: 'Vadeni',
        comuna: 'Cavadinesti'
      },
      {
        nume: 'Cotoroaia',
        comuna: 'Certesti'
      },
      {
        nume: 'Cârlomăneşti',
        simplu: 'Carlomanesti',
        comuna: 'Certesti'
      },
      {
        nume: 'Măcişeni',
        simplu: 'Maciseni',
        comuna: 'Corni'
      },
      {
        nume: 'Urleşti',
        simplu: 'Urlesti',
        comuna: 'Corni'
      },
      {
        nume: 'Blânzi',
        simplu: 'Blanzi',
        comuna: 'Corod'
      },
      {
        nume: 'Brătuleşti',
        simplu: 'Bratulesti',
        comuna: 'Corod'
      },
      {
        nume: 'Cărăpceşti',
        simplu: 'Carapcesti',
        comuna: 'Corod'
      },
      {
        nume: 'Băltăreţi',
        simplu: 'Baltareti',
        comuna: 'Cosmesti'
      },
      {
        nume: 'Cosmeştii-Vale',
        simplu: 'Cosmestii-Vale',
        comuna: 'Cosmesti'
      },
      {
        nume: 'Furcenii Noi',
        comuna: 'Cosmesti'
      },
      {
        nume: 'Furcenii Vechi',
        comuna: 'Cosmesti'
      },
      {
        nume: 'Satu Nou',
        comuna: 'Cosmesti'
      },
      {
        nume: 'Malu Alb',
        comuna: 'Draganesti'
      },
      {
        nume: 'Adam',
        comuna: 'Draguseni'
      },
      {
        nume: 'Căuieşti',
        simplu: 'Cauiesti',
        comuna: 'Draguseni'
      },
      {
        nume: 'Fundeanu',
        comuna: 'Draguseni'
      },
      {
        nume: 'Ghingheşti',
        simplu: 'Ghinghesti',
        comuna: 'Draguseni'
      },
      {
        nume: 'Nicopole',
        comuna: 'Draguseni'
      },
      {
        nume: 'Stieţeşti',
        simplu: 'Stietesti',
        comuna: 'Draguseni'
      },
      {
        nume: 'Viile',
        comuna: 'Fartanesti'
      },
      {
        nume: 'Stoicani',
        comuna: 'Foltesti'
      },
      {
        nume: 'Ijdileni',
        comuna: 'Frumusita'
      },
      {
        nume: 'Tămăoani',
        simplu: 'Tamaoani',
        comuna: 'Frumusita'
      },
      {
        nume: 'Fundenii Noi',
        comuna: 'Fundeni'
      },
      {
        nume: 'Hanu Conachi',
        comuna: 'Fundeni'
      },
      {
        nume: 'Lungoci',
        comuna: 'Fundeni'
      },
      {
        nume: 'Gara Ghidigeni',
        comuna: 'Ghidigeni'
      },
      {
        nume: 'Gefu',
        comuna: 'Ghidigeni'
      },
      {
        nume: 'Gura Gârbovăţului',
        simplu: 'Gura Garbovatului',
        comuna: 'Ghidigeni'
      },
      {
        nume: 'Gârbovăţ',
        simplu: 'Garbovat',
        comuna: 'Ghidigeni'
      },
      {
        nume: 'Slobozia Corni',
        comuna: 'Ghidigeni'
      },
      {
        nume: 'Tălpigi',
        simplu: 'Talpigi',
        comuna: 'Ghidigeni'
      },
      {
        nume: 'Tăplău',
        simplu: 'Taplau',
        comuna: 'Ghidigeni'
      },
      {
        nume: 'Gara Berheci',
        comuna: 'Gohor'
      },
      {
        nume: 'Ireasca',
        comuna: 'Gohor'
      },
      {
        nume: 'Nărteşti',
        simplu: 'Nartesti',
        comuna: 'Gohor'
      },
      {
        nume: 'Poşta',
        simplu: 'Posta',
        comuna: 'Gohor'
      },
      {
        nume: 'Călmăţui',
        simplu: 'Calmatui',
        comuna: 'Grivita'
      },
      {
        nume: 'Buceşti',
        simplu: 'Bucesti',
        comuna: 'Ivesti'
      },
      {
        nume: 'Lunca',
        comuna: 'Jorasti'
      },
      {
        nume: 'Zărneşti',
        simplu: 'Zarnesti',
        comuna: 'Jorasti'
      },
      {
        nume: 'Chiraftei',
        comuna: 'Mastacani'
      },
      {
        nume: 'Frunzeasca',
        comuna: 'Munteni'
      },
      {
        nume: 'Negrileşti',
        simplu: 'Negrilesti',
        comuna: 'Munteni'
      },
      {
        nume: 'Slobozia Blăneasa',
        simplu: 'Slobozia Blaneasa',
        comuna: 'Munteni'
      },
      {
        nume: 'Ungureni',
        comuna: 'Munteni'
      },
      {
        nume: 'Tigăneşti',
        simplu: 'Tiganesti',
        comuna: 'Munteni'
      },
      {
        nume: 'Crângeni',
        simplu: 'Crangeni',
        comuna: 'Namoloasa'
      },
      {
        nume: 'Nămoloasa Sat',
        simplu: 'Namoloasa Sat',
        comuna: 'Namoloasa'
      },
      {
        nume: 'Brăniştea',
        simplu: 'Branistea',
        comuna: 'Nicoresti'
      },
      {
        nume: 'Coasta Lupei',
        comuna: 'Nicoresti'
      },
      {
        nume: 'Dobrineşti',
        simplu: 'Dobrinesti',
        comuna: 'Nicoresti'
      },
      {
        nume: 'Fântâni',
        simplu: 'Fantani',
        comuna: 'Nicoresti'
      },
      {
        nume: 'Grozăveşti',
        simplu: 'Grozavesti',
        comuna: 'Nicoresti'
      },
      {
        nume: 'Ionăşeşti',
        simplu: 'Ionasesti',
        comuna: 'Nicoresti'
      },
      {
        nume: 'Mălureni',
        simplu: 'Malureni',
        comuna: 'Nicoresti'
      },
      {
        nume: 'Piscu Corbului',
        comuna: 'Nicoresti'
      },
      {
        nume: 'Poiana',
        comuna: 'Nicoresti'
      },
      {
        nume: 'Sârbi',
        simplu: 'Sarbi',
        comuna: 'Nicoresti'
      },
      {
        nume: 'Vişina',
        simplu: 'Visina',
        comuna: 'Nicoresti'
      },
      {
        nume: 'Slobozia Oancea',
        comuna: 'Oancea'
      },
      {
        nume: 'Lupele',
        comuna: 'Pechea'
      },
      {
        nume: 'Vameş',
        simplu: 'Vames',
        comuna: 'Piscu'
      },
      {
        nume: 'Ciorăşti',
        simplu: 'Ciorasti',
        comuna: 'Priponesti'
      },
      {
        nume: 'Huştiu',
        simplu: 'Hustiu',
        comuna: 'Priponesti'
      },
      {
        nume: 'Lieşti',
        simplu: 'Liesti',
        comuna: 'Priponesti'
      },
      {
        nume: 'Priponeştii de Jos',
        simplu: 'Priponestii de Jos',
        comuna: 'Priponesti'
      },
      {
        nume: 'Plevna',
        comuna: 'Rediu'
      },
      {
        nume: 'Suhurlui',
        comuna: 'Rediu'
      },
      {
        nume: 'Fântânele',
        simplu: 'Fantanele',
        comuna: 'Scanteiesti'
      },
      {
        nume: 'Negrea',
        comuna: 'Schela'
      },
      {
        nume: 'Movileni',
        comuna: 'Sendreni'
      },
      {
        nume: 'Serbeştii Vechi',
        simplu: 'Serbestii Vechi',
        comuna: 'Sendreni'
      },
      {
        nume: 'Cuza Vodă',
        simplu: 'Cuza Voda',
        comuna: 'Slobozia Conachi'
      },
      {
        nume: 'Izvoarele',
        comuna: 'Slobozia Conachi'
      },
      {
        nume: 'Cişmele',
        simplu: 'Cismele',
        comuna: 'Smardan'
      },
      {
        nume: 'Mihail Kogălniceanu',
        simplu: 'Mihail Kogalniceanu',
        comuna: 'Smardan'
      },
      {
        nume: 'Rogojeni',
        comuna: 'Suceveni'
      },
      {
        nume: 'Moscu',
        comuna: 'Targu Bujor'
      },
      {
        nume: 'Umbrăreşti',
        simplu: 'Umbraresti',
        comuna: 'Targu Bujor'
      },
      {
        nume: 'Tepu de Sus',
        simplu: 'Tepu de Sus',
        comuna: 'Tepu'
      },
      {
        nume: 'Tătarca',
        simplu: 'Tatarca',
        comuna: 'Tulucesti'
      },
      {
        nume: 'Siviţa',
        simplu: 'Sivita',
        comuna: 'Tulucesti'
      },
      {
        nume: 'Condrea',
        comuna: 'Umbraresti'
      },
      {
        nume: 'Salcia',
        comuna: 'Umbraresti'
      },
      {
        nume: 'Siliştea',
        simplu: 'Silistea',
        comuna: 'Umbraresti'
      },
      {
        nume: 'Torceşti',
        simplu: 'Torcesti',
        comuna: 'Umbraresti'
      },
      {
        nume: 'Umbrăreşti-Deal',
        simplu: 'Umbraresti-Deal',
        comuna: 'Umbraresti'
      },
      {
        nume: 'Mândreşti',
        simplu: 'Mandresti',
        comuna: 'Valea Marului'
      },
      {
        nume: 'Costi',
        comuna: 'Vanatori'
      },
      {
        nume: 'Odaia Manolache',
        comuna: 'Vanatori'
      },
      {
        nume: 'Crăieşti',
        simplu: 'Craiesti',
        comuna: 'Varlezi'
      },
      {
        nume: 'Brăneşti',
        simplu: 'Branesti',
        comuna: 'Vladesti'
      }
    ]
  },
  {
    auto: 'GR',
    nume: 'Giurgiu',
    localitati: [
      {
        nume: 'Adunaţii-Copăceni',
        simplu: 'Adunatii-Copaceni'
      },
      {
        nume: 'Bolintin-Deal'
      },
      {
        nume: 'Bolintin-Vale'
      },
      {
        nume: 'Bucşani',
        simplu: 'Bucsani'
      },
      {
        nume: 'Bulbucata'
      },
      {
        nume: 'Buturugeni'
      },
      {
        nume: 'Băneasa',
        simplu: 'Baneasa'
      },
      {
        nume: 'Clejani'
      },
      {
        nume: 'Colibaşi',
        simplu: 'Colibasi'
      },
      {
        nume: 'Comana'
      },
      {
        nume: 'Crevedia Mare'
      },
      {
        nume: 'Călugăreni',
        simplu: 'Calugareni'
      },
      {
        nume: 'Daia'
      },
      {
        nume: 'Frăteşti',
        simplu: 'Fratesti'
      },
      {
        nume: 'Ghimpaţi',
        simplu: 'Ghimpati'
      },
      {
        nume: 'Giurgiu'
      },
      {
        nume: 'Gogoşari',
        simplu: 'Gogosari'
      },
      {
        nume: 'Gostinari'
      },
      {
        nume: 'Gostinu'
      },
      {
        nume: 'Greaca'
      },
      {
        nume: 'Grădinari',
        simplu: 'Gradinari'
      },
      {
        nume: 'Găiseni',
        simplu: 'Gaiseni'
      },
      {
        nume: 'Găujani',
        simplu: 'Gaujani'
      },
      {
        nume: 'Hotarele'
      },
      {
        nume: 'Iepureşti',
        simplu: 'Iepuresti'
      },
      {
        nume: 'Izvoarele'
      },
      {
        nume: 'Joiţa',
        simplu: 'Joita'
      },
      {
        nume: 'Letca Nouă',
        simplu: 'Letca Noua'
      },
      {
        nume: 'Malu'
      },
      {
        nume: 'Mihai Bravu'
      },
      {
        nume: 'Mihăileşti',
        simplu: 'Mihailesti'
      },
      {
        nume: 'Mârşa',
        simplu: 'Marsa'
      },
      {
        nume: 'Ogrezeni'
      },
      {
        nume: 'Oinacu'
      },
      {
        nume: 'Prundu'
      },
      {
        nume: 'Putineiu'
      },
      {
        nume: 'Roata de Jos'
      },
      {
        nume: 'Răsuceni',
        simplu: 'Rasuceni'
      },
      {
        nume: 'Schitu'
      },
      {
        nume: 'Singureni'
      },
      {
        nume: 'Slobozia'
      },
      {
        nume: 'Stoeneşti',
        simplu: 'Stoenesti'
      },
      {
        nume: 'Stăneşti',
        simplu: 'Stanesti'
      },
      {
        nume: 'Toporu'
      },
      {
        nume: 'Ulmi'
      },
      {
        nume: 'Valea Dragului'
      },
      {
        nume: 'Vedea'
      },
      {
        nume: 'Vânătorii Mici',
        simplu: 'Vanatorii Mici'
      },
      {
        nume: 'Vărăşti',
        simplu: 'Varasti'
      },
      {
        nume: 'Dărăşti-Vlaşca',
        simplu: 'Darasti-Vlasca',
        comuna: 'Adunatii-Copaceni'
      },
      {
        nume: 'Mogoşeşti',
        simplu: 'Mogosesti',
        comuna: 'Adunatii-Copaceni'
      },
      {
        nume: 'Varlaam',
        comuna: 'Adunatii-Copaceni'
      },
      {
        nume: 'Frasinu',
        comuna: 'Baneasa'
      },
      {
        nume: 'Pietrele',
        comuna: 'Baneasa'
      },
      {
        nume: 'Sfântu Gheorghe',
        simplu: 'Sfantu Gheorghe',
        comuna: 'Baneasa'
      },
      {
        nume: 'Mihai Vodă',
        simplu: 'Mihai Voda',
        comuna: 'Bolintin-Deal'
      },
      {
        nume: 'Crivina',
        comuna: 'Bolintin-Vale'
      },
      {
        nume: 'Malu Spart',
        comuna: 'Bolintin-Vale'
      },
      {
        nume: 'Suseni',
        comuna: 'Bolintin-Vale'
      },
      {
        nume: 'Angheleşti',
        simplu: 'Anghelesti',
        comuna: 'Bucsani'
      },
      {
        nume: 'Goleasca',
        comuna: 'Bucsani'
      },
      {
        nume: 'Obedeni',
        comuna: 'Bucsani'
      },
      {
        nume: 'Podişor',
        simplu: 'Podisor',
        comuna: 'Bucsani'
      },
      {
        nume: 'Uieşti',
        simplu: 'Uiesti',
        comuna: 'Bucsani'
      },
      {
        nume: 'Vadu Lat',
        comuna: 'Bucsani'
      },
      {
        nume: 'Coteni',
        comuna: 'Bulbucata'
      },
      {
        nume: 'Făcău',
        simplu: 'Facau',
        comuna: 'Bulbucata'
      },
      {
        nume: 'Teişori',
        simplu: 'Teisori',
        comuna: 'Bulbucata'
      },
      {
        nume: 'Podu Ilfovăţului',
        simplu: 'Podu Ilfovatului',
        comuna: 'Buturugeni'
      },
      {
        nume: 'Poşta',
        simplu: 'Posta',
        comuna: 'Buturugeni'
      },
      {
        nume: 'Pădureni',
        simplu: 'Padureni',
        comuna: 'Buturugeni'
      },
      {
        nume: 'Brăniştari',
        simplu: 'Branistari',
        comuna: 'Calugareni'
      },
      {
        nume: 'Crucea de Piatră',
        simplu: 'Crucea de Piatra',
        comuna: 'Calugareni'
      },
      {
        nume: 'Hulubeşti',
        simplu: 'Hulubesti',
        comuna: 'Calugareni'
      },
      {
        nume: 'Uzunu',
        comuna: 'Calugareni'
      },
      {
        nume: 'Neajlov',
        comuna: 'Clejani'
      },
      {
        nume: 'Podu Doamnei',
        comuna: 'Clejani'
      },
      {
        nume: 'Sterea',
        comuna: 'Clejani'
      },
      {
        nume: 'Câmpurelu',
        simplu: 'Campurelu',
        comuna: 'Colibasi'
      },
      {
        nume: 'Budeni',
        comuna: 'Comana'
      },
      {
        nume: 'Falaştoaca',
        simplu: 'Falastoaca',
        comuna: 'Comana'
      },
      {
        nume: 'Grădiştea',
        simplu: 'Gradistea',
        comuna: 'Comana'
      },
      {
        nume: 'Vlad Tepeş',
        simplu: 'Vlad Tepes',
        comuna: 'Comana'
      },
      {
        nume: 'Crevedia Mică',
        simplu: 'Crevedia Mica',
        comuna: 'Crevedia Mare'
      },
      {
        nume: 'Dealu',
        comuna: 'Crevedia Mare'
      },
      {
        nume: 'Găiseanca',
        simplu: 'Gaiseanca',
        comuna: 'Crevedia Mare'
      },
      {
        nume: 'Priboiu',
        comuna: 'Crevedia Mare'
      },
      {
        nume: 'Sfântu Gheorghe',
        simplu: 'Sfantu Gheorghe',
        comuna: 'Crevedia Mare'
      },
      {
        nume: 'Plopşoru',
        simplu: 'Plopsoru',
        comuna: 'Daia'
      },
      {
        nume: 'Floreşti',
        simplu: 'Floresti',
        comuna: 'Floresti-Stoenesti'
      },
      {
        nume: 'Palanca',
        comuna: 'Floresti-Stoenesti'
      },
      {
        nume: 'Stoeneşti',
        simplu: 'Stoenesti',
        comuna: 'Floresti-Stoenesti'
      },
      {
        nume: 'Cetatea',
        comuna: 'Fratesti'
      },
      {
        nume: 'Remuş',
        simplu: 'Remus',
        comuna: 'Fratesti'
      },
      {
        nume: 'Cărpenişu',
        simplu: 'Carpenisu',
        comuna: 'Gaiseni'
      },
      {
        nume: 'Căscioarele',
        simplu: 'Cascioarele',
        comuna: 'Gaiseni'
      },
      {
        nume: 'Podu Popa Nae',
        comuna: 'Gaiseni'
      },
      {
        nume: 'Cetăţuia',
        simplu: 'Cetatuia',
        comuna: 'Gaujani'
      },
      {
        nume: 'Pietrişu',
        simplu: 'Pietrisu',
        comuna: 'Gaujani'
      },
      {
        nume: 'Copaciu',
        comuna: 'Ghimpati'
      },
      {
        nume: 'Naipu',
        comuna: 'Ghimpati'
      },
      {
        nume: 'Valea Plopilor',
        comuna: 'Ghimpati'
      },
      {
        nume: 'Drăghiceanu',
        simplu: 'Draghiceanu',
        comuna: 'Gogosari'
      },
      {
        nume: 'Izvoru',
        comuna: 'Gogosari'
      },
      {
        nume: 'Răleşti',
        simplu: 'Ralesti',
        comuna: 'Gogosari'
      },
      {
        nume: 'Mironeşti',
        simplu: 'Mironesti',
        comuna: 'Gostinari'
      },
      {
        nume: 'Tântava',
        simplu: 'Tantava',
        comuna: 'Gradinari'
      },
      {
        nume: 'Zorile',
        comuna: 'Gradinari'
      },
      {
        nume: 'Puţu Greci',
        simplu: 'Putu Greci',
        comuna: 'Greaca'
      },
      {
        nume: 'Zboiu',
        comuna: 'Greaca'
      },
      {
        nume: 'Herăşti',
        simplu: 'Herasti',
        comuna: 'Hotarele'
      },
      {
        nume: 'Isvoarele',
        comuna: 'Hotarele'
      },
      {
        nume: 'Miloşeşti',
        simplu: 'Milosesti',
        comuna: 'Hotarele'
      },
      {
        nume: 'Teiuşu',
        simplu: 'Teiusu',
        comuna: 'Hotarele'
      },
      {
        nume: 'Băneşti',
        simplu: 'Banesti',
        comuna: 'Iepuresti'
      },
      {
        nume: 'Chirculeşti',
        simplu: 'Chirculesti',
        comuna: 'Iepuresti'
      },
      {
        nume: 'Gorneni',
        comuna: 'Iepuresti'
      },
      {
        nume: 'Stâlpu',
        simplu: 'Stalpu',
        comuna: 'Iepuresti'
      },
      {
        nume: 'Valter Mărăcineanu',
        simplu: 'Valter Maracineanu',
        comuna: 'Iepuresti'
      },
      {
        nume: 'Chiriacu',
        comuna: 'Izvoarele'
      },
      {
        nume: 'Dimitrie Cantemir',
        comuna: 'Izvoarele'
      },
      {
        nume: 'Petru Rareş',
        simplu: 'Petru Rares',
        comuna: 'Izvoarele'
      },
      {
        nume: 'Radu Vodă',
        simplu: 'Radu Voda',
        comuna: 'Izvoarele'
      },
      {
        nume: 'Valea Bujorului',
        comuna: 'Izvoarele'
      },
      {
        nume: 'Bâcu',
        simplu: 'Bacu',
        comuna: 'Joita'
      },
      {
        nume: 'Cosoba',
        comuna: 'Joita'
      },
      {
        nume: 'Săbăreni',
        simplu: 'Sabareni',
        comuna: 'Joita'
      },
      {
        nume: 'Letca Veche',
        comuna: 'Letca Noua'
      },
      {
        nume: 'Milcovăţu',
        simplu: 'Milcovatu',
        comuna: 'Letca Noua'
      },
      {
        nume: 'Drăgănescu',
        simplu: 'Draganescu',
        comuna: 'Mihailesti'
      },
      {
        nume: 'Novaci',
        comuna: 'Mihailesti'
      },
      {
        nume: 'Popeşti',
        simplu: 'Popesti',
        comuna: 'Mihailesti'
      },
      {
        nume: 'Hobaia',
        comuna: 'Ogrezeni'
      },
      {
        nume: 'Braniştea',
        simplu: 'Branistea',
        comuna: 'Oinacu'
      },
      {
        nume: 'Comasca',
        comuna: 'Oinacu'
      },
      {
        nume: 'Puieni',
        comuna: 'Prundu'
      },
      {
        nume: 'Hodivoaia',
        comuna: 'Putineiu'
      },
      {
        nume: 'Vieru',
        comuna: 'Putineiu'
      },
      {
        nume: 'Carapancea',
        comuna: 'Rasuceni'
      },
      {
        nume: 'Cucuruzu',
        comuna: 'Rasuceni'
      },
      {
        nume: 'Satu Nou',
        comuna: 'Rasuceni'
      },
      {
        nume: 'Cartojani',
        comuna: 'Roata De Jos'
      },
      {
        nume: 'Roata Mică',
        simplu: 'Roata Mica',
        comuna: 'Roata De Jos'
      },
      {
        nume: 'Sadina',
        comuna: 'Roata De Jos'
      },
      {
        nume: 'Bila',
        comuna: 'Schitu'
      },
      {
        nume: 'Cămineasca',
        simplu: 'Camineasca',
        comuna: 'Schitu'
      },
      {
        nume: 'Vlaşin',
        simplu: 'Vlasin',
        comuna: 'Schitu'
      },
      {
        nume: 'Crânguri',
        simplu: 'Cranguri',
        comuna: 'Singureni'
      },
      {
        nume: 'Stejaru',
        comuna: 'Singureni'
      },
      {
        nume: 'Bălanu',
        simplu: 'Balanu',
        comuna: 'Stanesti'
      },
      {
        nume: 'Ghizdaru',
        comuna: 'Stanesti'
      },
      {
        nume: 'Onceşti',
        simplu: 'Oncesti',
        comuna: 'Stanesti'
      },
      {
        nume: 'Ianculeşti',
        simplu: 'Ianculesti',
        comuna: 'Stoenesti'
      },
      {
        nume: 'Mirău',
        simplu: 'Mirau',
        comuna: 'Stoenesti'
      },
      {
        nume: 'Tomuleşti',
        simplu: 'Tomulesti',
        comuna: 'Toporu'
      },
      {
        nume: 'Căscioarele',
        simplu: 'Cascioarele',
        comuna: 'Ulmi'
      },
      {
        nume: 'Drăgăneasca',
        simplu: 'Draganeasca',
        comuna: 'Ulmi'
      },
      {
        nume: 'Ghionea',
        comuna: 'Ulmi'
      },
      {
        nume: 'Icoana',
        comuna: 'Ulmi'
      },
      {
        nume: 'Moşteni',
        simplu: 'Mosteni',
        comuna: 'Ulmi'
      },
      {
        nume: 'Poenari',
        comuna: 'Ulmi'
      },
      {
        nume: 'Trestieni',
        comuna: 'Ulmi'
      },
      {
        nume: 'Corbeanca',
        comuna: 'Vanatorii Mici'
      },
      {
        nume: 'Cupele',
        comuna: 'Vanatorii Mici'
      },
      {
        nume: 'Izvoru',
        comuna: 'Vanatorii Mici'
      },
      {
        nume: 'Poiana lui Stângă',
        simplu: 'Poiana lui Stanga',
        comuna: 'Vanatorii Mici'
      },
      {
        nume: 'Vâlcele',
        simplu: 'Valcele',
        comuna: 'Vanatorii Mici'
      },
      {
        nume: 'Vânătorii Mari',
        simplu: 'Vanatorii Mari',
        comuna: 'Vanatorii Mici'
      },
      {
        nume: 'Zădăriciu',
        simplu: 'Zadariciu',
        comuna: 'Vanatorii Mici'
      },
      {
        nume: 'Dobreni',
        comuna: 'Varasti'
      }
    ]
  },
  {
    auto: 'GJ',
    nume: 'Gorj',
    localitati: [
      {
        nume: 'Albeni'
      },
      {
        nume: 'Alimpeşti',
        simplu: 'Alimpesti'
      },
      {
        nume: 'Aninoasa'
      },
      {
        nume: 'Arcani'
      },
      {
        nume: 'Baia de Fier'
      },
      {
        nume: 'Berleşti',
        simplu: 'Berlesti'
      },
      {
        nume: 'Bolboşi',
        simplu: 'Bolbosi'
      },
      {
        nume: 'Borăscu',
        simplu: 'Borascu'
      },
      {
        nume: 'Brăneşti',
        simplu: 'Branesti'
      },
      {
        nume: 'Bumbeşti-Jiu',
        simplu: 'Bumbesti-Jiu'
      },
      {
        nume: 'Bumbeşti-Piţic',
        simplu: 'Bumbesti-Pitic'
      },
      {
        nume: 'Bustuchin'
      },
      {
        nume: 'Bâlteni',
        simplu: 'Balteni'
      },
      {
        nume: 'Băleşti',
        simplu: 'Balesti'
      },
      {
        nume: 'Bălăneşti',
        simplu: 'Balanesti'
      },
      {
        nume: 'Bărbăteşti',
        simplu: 'Barbatesti'
      },
      {
        nume: 'Ciuperceni'
      },
      {
        nume: 'Crasna'
      },
      {
        nume: 'Cruşet',
        simplu: 'Cruset'
      },
      {
        nume: 'Câlnic',
        simplu: 'Calnic'
      },
      {
        nume: 'Căpreni',
        simplu: 'Capreni'
      },
      {
        nume: 'Cătunele',
        simplu: 'Catunele'
      },
      {
        nume: 'Drăgoteşti',
        simplu: 'Dragotesti'
      },
      {
        nume: 'Drăguţeşti',
        simplu: 'Dragutesti'
      },
      {
        nume: 'Dănciuleşti',
        simplu: 'Danciulesti'
      },
      {
        nume: 'Dăneşti',
        simplu: 'Danesti'
      },
      {
        nume: 'Fărcăşeşti',
        simplu: 'Farcasesti'
      },
      {
        nume: 'Glogova'
      },
      {
        nume: 'Godineşti',
        simplu: 'Godinesti'
      },
      {
        nume: 'Hurezani'
      },
      {
        nume: 'Ioneşti',
        simplu: 'Ionesti'
      },
      {
        nume: 'Jupâneşti',
        simplu: 'Jupanesti'
      },
      {
        nume: 'Leleşti',
        simplu: 'Lelesti'
      },
      {
        nume: 'Licurici'
      },
      {
        nume: 'Motru'
      },
      {
        nume: 'Muşeteşti',
        simplu: 'Musetesti'
      },
      {
        nume: 'Mătăsari',
        simplu: 'Matasari'
      },
      {
        nume: 'Negomir'
      },
      {
        nume: 'Novaci'
      },
      {
        nume: 'Padeş',
        simplu: 'Pades'
      },
      {
        nume: 'Peştişani',
        simplu: 'Pestisani'
      },
      {
        nume: 'Plopşoru',
        simplu: 'Plopsoru'
      },
      {
        nume: 'Polovragi'
      },
      {
        nume: 'Prigoria'
      },
      {
        nume: 'Rovinari'
      },
      {
        nume: 'Roşia de Amaradia',
        simplu: 'Rosia de Amaradia'
      },
      {
        nume: 'Runcu'
      },
      {
        nume: 'Samarineşti',
        simplu: 'Samarinesti'
      },
      {
        nume: 'Schela'
      },
      {
        nume: 'Scoarţa',
        simplu: 'Scoarta'
      },
      {
        nume: 'Slivileşti',
        simplu: 'Slivilesti'
      },
      {
        nume: 'Stejari'
      },
      {
        nume: 'Stoina'
      },
      {
        nume: 'Stăneşti',
        simplu: 'Stanesti'
      },
      {
        nume: 'Săcelu',
        simplu: 'Sacelu'
      },
      {
        nume: 'Săuleşti',
        simplu: 'Saulesti'
      },
      {
        nume: 'Teleşti',
        simplu: 'Telesti'
      },
      {
        nume: 'Tismana'
      },
      {
        nume: 'Turburea'
      },
      {
        nume: 'Turceni'
      },
      {
        nume: 'Turcineşti',
        simplu: 'Turcinesti'
      },
      {
        nume: 'Târgu Jiu',
        simplu: 'Targu Jiu'
      },
      {
        nume: 'Urdari'
      },
      {
        nume: 'Vladimir'
      },
      {
        nume: 'Văgiuleşti',
        simplu: 'Vagiulesti'
      },
      {
        nume: 'Ticleni',
        simplu: 'Ticleni'
      },
      {
        nume: 'Tânţăreni',
        simplu: 'Tantareni'
      },
      {
        nume: 'Bolboceşti',
        simplu: 'Bolbocesti',
        comuna: 'Albeni'
      },
      {
        nume: 'Bârzeiu de Gilort',
        simplu: 'Barzeiu de Gilort',
        comuna: 'Albeni'
      },
      {
        nume: 'Doseni',
        comuna: 'Albeni'
      },
      {
        nume: 'Mirosloveni',
        comuna: 'Albeni'
      },
      {
        nume: 'Pruneşti',
        simplu: 'Prunesti',
        comuna: 'Albeni'
      },
      {
        nume: 'Ciupercenii de Olteţ',
        simplu: 'Ciupercenii de Oltet',
        comuna: 'Alimpesti'
      },
      {
        nume: 'Corşoru',
        simplu: 'Corsoru',
        comuna: 'Alimpesti'
      },
      {
        nume: 'Nistoreşti',
        simplu: 'Nistoresti',
        comuna: 'Alimpesti'
      },
      {
        nume: 'Sârbeşti',
        simplu: 'Sarbesti',
        comuna: 'Alimpesti'
      },
      {
        nume: 'Bobaia',
        comuna: 'Aninoasa'
      },
      {
        nume: 'Costeşti',
        simplu: 'Costesti',
        comuna: 'Aninoasa'
      },
      {
        nume: 'Groşerea',
        simplu: 'Groserea',
        comuna: 'Aninoasa'
      },
      {
        nume: 'Sterpoaia',
        comuna: 'Aninoasa'
      },
      {
        nume: 'Câmpofeni',
        simplu: 'Campofeni',
        comuna: 'Arcani'
      },
      {
        nume: 'Stroieşti',
        simplu: 'Stroiesti',
        comuna: 'Arcani'
      },
      {
        nume: 'Sănăteşti',
        simplu: 'Sanatesti',
        comuna: 'Arcani'
      },
      {
        nume: 'Cernădia',
        simplu: 'Cernadia',
        comuna: 'Baia De Fier'
      },
      {
        nume: 'Blidari',
        comuna: 'Balanesti'
      },
      {
        nume: 'Cânepeşti',
        simplu: 'Canepesti',
        comuna: 'Balanesti'
      },
      {
        nume: 'Glodeni',
        comuna: 'Balanesti'
      },
      {
        nume: 'Ohaba',
        comuna: 'Balanesti'
      },
      {
        nume: 'Voiteştii de Vale',
        simplu: 'Voitestii de Vale',
        comuna: 'Balanesti'
      },
      {
        nume: 'Voiteştii din Deal',
        simplu: 'Voitestii din Deal',
        comuna: 'Balanesti'
      },
      {
        nume: 'Ceauru',
        comuna: 'Balesti'
      },
      {
        nume: 'Corneşti',
        simplu: 'Cornesti',
        comuna: 'Balesti'
      },
      {
        nume: 'Găvăneşti',
        simplu: 'Gavanesti',
        comuna: 'Balesti'
      },
      {
        nume: 'Rasova',
        comuna: 'Balesti'
      },
      {
        nume: 'Stolojani',
        comuna: 'Balesti'
      },
      {
        nume: 'Tălpăşeşti',
        simplu: 'Talpasesti',
        comuna: 'Balesti'
      },
      {
        nume: 'Tămăşeşti',
        simplu: 'Tamasesti',
        comuna: 'Balesti'
      },
      {
        nume: 'Voinigeşti',
        simplu: 'Voinigesti',
        comuna: 'Balesti'
      },
      {
        nume: 'Cocoreni',
        comuna: 'Balteni'
      },
      {
        nume: 'Moi',
        comuna: 'Balteni'
      },
      {
        nume: 'Peşteana Jiu',
        simplu: 'Pesteana Jiu',
        comuna: 'Balteni'
      },
      {
        nume: 'Vlăduleni',
        simplu: 'Vladuleni',
        comuna: 'Balteni'
      },
      {
        nume: 'Musculeşti',
        simplu: 'Musculesti',
        comuna: 'Barbatesti'
      },
      {
        nume: 'Petreşti',
        simplu: 'Petresti',
        comuna: 'Barbatesti'
      },
      {
        nume: 'Socu',
        comuna: 'Barbatesti'
      },
      {
        nume: 'Bengeşti',
        simplu: 'Bengesti',
        comuna: 'Bengesti-Ciocadia'
      },
      {
        nume: 'Bircii',
        comuna: 'Bengesti-Ciocadia'
      },
      {
        nume: 'Bălceşti',
        simplu: 'Balcesti',
        comuna: 'Bengesti-Ciocadia'
      },
      {
        nume: 'Ciocadia',
        comuna: 'Bengesti-Ciocadia'
      },
      {
        nume: 'Bârzeiu',
        simplu: 'Barzeiu',
        comuna: 'Berlesti'
      },
      {
        nume: 'Gâlceşti',
        simplu: 'Galcesti',
        comuna: 'Berlesti'
      },
      {
        nume: 'Lihuleşti',
        simplu: 'Lihulesti',
        comuna: 'Berlesti'
      },
      {
        nume: 'Pârâu Viu',
        simplu: 'Parau Viu',
        comuna: 'Berlesti'
      },
      {
        nume: 'Scrada',
        comuna: 'Berlesti'
      },
      {
        nume: 'Scurtu',
        comuna: 'Berlesti'
      },
      {
        nume: 'Bolboasa',
        comuna: 'Bolbosi'
      },
      {
        nume: 'Bălăceşti',
        simplu: 'Balacesti',
        comuna: 'Bolbosi'
      },
      {
        nume: 'Igirosu',
        comuna: 'Bolbosi'
      },
      {
        nume: 'Miclosu',
        comuna: 'Bolbosi'
      },
      {
        nume: 'Ohaba Jiu',
        comuna: 'Bolbosi'
      },
      {
        nume: 'Valea',
        comuna: 'Bolbosi'
      },
      {
        nume: 'Baniu',
        comuna: 'Borascu'
      },
      {
        nume: 'Calapăru',
        simplu: 'Calaparu',
        comuna: 'Borascu'
      },
      {
        nume: 'Gura Menţi',
        simplu: 'Gura Menti',
        comuna: 'Borascu'
      },
      {
        nume: 'Menţii din Dos',
        simplu: 'Mentii din Dos',
        comuna: 'Borascu'
      },
      {
        nume: 'Miluta',
        comuna: 'Borascu'
      },
      {
        nume: 'Scoruşu',
        simplu: 'Scorusu',
        comuna: 'Borascu'
      },
      {
        nume: 'Brebenei',
        comuna: 'Branesti'
      },
      {
        nume: 'Bădeşti',
        simplu: 'Badesti',
        comuna: 'Branesti'
      },
      {
        nume: 'Capu Dealului',
        comuna: 'Branesti'
      },
      {
        nume: 'Gilortu',
        comuna: 'Branesti'
      },
      {
        nume: 'Pârâu',
        simplu: 'Parau',
        comuna: 'Branesti'
      },
      {
        nume: 'Curtişoara',
        simplu: 'Curtisoara',
        comuna: 'Bumbesti-Jiu'
      },
      {
        nume: 'Lăzăreşti',
        simplu: 'Lazaresti',
        comuna: 'Bumbesti-Jiu'
      },
      {
        nume: 'Pleşa',
        simplu: 'Plesa',
        comuna: 'Bumbesti-Jiu'
      },
      {
        nume: 'Tetila',
        comuna: 'Bumbesti-Jiu'
      },
      {
        nume: 'Cârligei',
        simplu: 'Carligei',
        comuna: 'Bumbesti-Pitic'
      },
      {
        nume: 'Poienari',
        comuna: 'Bumbesti-Pitic'
      },
      {
        nume: 'Cionţi',
        simplu: 'Cionti',
        comuna: 'Bustuchin'
      },
      {
        nume: 'Motorgi',
        comuna: 'Bustuchin'
      },
      {
        nume: 'Nămete',
        simplu: 'Namete',
        comuna: 'Bustuchin'
      },
      {
        nume: 'Poiana Seciuri',
        comuna: 'Bustuchin'
      },
      {
        nume: 'Poieniţa',
        simplu: 'Poienita',
        comuna: 'Bustuchin'
      },
      {
        nume: 'Pojaru',
        comuna: 'Bustuchin'
      },
      {
        nume: 'Valea Pojarului',
        comuna: 'Bustuchin'
      },
      {
        nume: 'Câlnicu de Sus',
        simplu: 'Calnicu de Sus',
        comuna: 'Calnic'
      },
      {
        nume: 'Didileşti',
        simplu: 'Didilesti',
        comuna: 'Calnic'
      },
      {
        nume: 'Găleşoaia',
        simplu: 'Galesoaia',
        comuna: 'Calnic'
      },
      {
        nume: 'Hodoreasca',
        comuna: 'Calnic'
      },
      {
        nume: 'Pieptani',
        comuna: 'Calnic'
      },
      {
        nume: 'Pinoasa',
        comuna: 'Calnic'
      },
      {
        nume: 'Stejerei',
        comuna: 'Calnic'
      },
      {
        nume: 'Vâlceaua',
        simplu: 'Valceaua',
        comuna: 'Calnic'
      },
      {
        nume: 'Aluniş',
        simplu: 'Alunis',
        comuna: 'Capreni'
      },
      {
        nume: 'Brăteşti',
        simplu: 'Bratesti',
        comuna: 'Capreni'
      },
      {
        nume: 'Bulbuceni',
        comuna: 'Capreni'
      },
      {
        nume: 'Cetatea',
        comuna: 'Capreni'
      },
      {
        nume: 'Cornetu',
        comuna: 'Capreni'
      },
      {
        nume: 'Dealu Spirei',
        comuna: 'Capreni'
      },
      {
        nume: 'Satu Nou',
        comuna: 'Capreni'
      },
      {
        nume: 'Dealu Viilor',
        comuna: 'Catunele'
      },
      {
        nume: 'Lupoaia',
        comuna: 'Catunele'
      },
      {
        nume: 'Steic',
        comuna: 'Catunele'
      },
      {
        nume: 'Valea Mânăstirii',
        simplu: 'Valea Manastirii',
        comuna: 'Catunele'
      },
      {
        nume: 'Valea Perilor',
        comuna: 'Catunele'
      },
      {
        nume: 'Boboieşti',
        simplu: 'Boboiesti',
        comuna: 'Ciuperceni'
      },
      {
        nume: 'Peşteana Vulcan',
        simplu: 'Pesteana Vulcan',
        comuna: 'Ciuperceni'
      },
      {
        nume: 'Priporu',
        comuna: 'Ciuperceni'
      },
      {
        nume: 'Strâmba Vulcan',
        simplu: 'Stramba Vulcan',
        comuna: 'Ciuperceni'
      },
      {
        nume: 'Vârtopu',
        simplu: 'Vartopu',
        comuna: 'Ciuperceni'
      },
      {
        nume: 'Zorzila',
        comuna: 'Ciuperceni'
      },
      {
        nume: 'Aninişu din Deal',
        simplu: 'Aninisu din Deal',
        comuna: 'Crasna'
      },
      {
        nume: 'Aninişu din Vale',
        simplu: 'Aninisu din Vale',
        comuna: 'Crasna'
      },
      {
        nume: 'Buzeşti',
        simplu: 'Buzesti',
        comuna: 'Crasna'
      },
      {
        nume: 'Crasna din Deal',
        comuna: 'Crasna'
      },
      {
        nume: 'Cărpiniş',
        simplu: 'Carpinis',
        comuna: 'Crasna'
      },
      {
        nume: 'Drăgoieşti',
        simplu: 'Dragoiesti',
        comuna: 'Crasna'
      },
      {
        nume: 'Dumbrăveni',
        simplu: 'Dumbraveni',
        comuna: 'Crasna'
      },
      {
        nume: 'Radoşi',
        simplu: 'Radosi',
        comuna: 'Crasna'
      },
      {
        nume: 'Bojinu',
        comuna: 'Cruset'
      },
      {
        nume: 'Marineşti',
        simplu: 'Marinesti',
        comuna: 'Cruset'
      },
      {
        nume: 'Mierea',
        comuna: 'Cruset'
      },
      {
        nume: 'Miericeaua',
        comuna: 'Cruset'
      },
      {
        nume: 'Măiag',
        simplu: 'Maiag',
        comuna: 'Cruset'
      },
      {
        nume: 'Slămneşti',
        simplu: 'Slamnesti',
        comuna: 'Cruset'
      },
      {
        nume: 'Slăvuţa',
        simplu: 'Slavuta',
        comuna: 'Cruset'
      },
      {
        nume: 'Urda de Jos',
        comuna: 'Cruset'
      },
      {
        nume: 'Văluţa',
        simplu: 'Valuta',
        comuna: 'Cruset'
      },
      {
        nume: 'Bibuleşti',
        simplu: 'Bibulesti',
        comuna: 'Danciulesti'
      },
      {
        nume: 'Hălăngeşti',
        simplu: 'Halangesti',
        comuna: 'Danciulesti'
      },
      {
        nume: 'Obârşia',
        simplu: 'Obarsia',
        comuna: 'Danciulesti'
      },
      {
        nume: 'Petrăchei',
        simplu: 'Petrachei',
        comuna: 'Danciulesti'
      },
      {
        nume: 'Rădineşti',
        simplu: 'Radinesti',
        comuna: 'Danciulesti'
      },
      {
        nume: 'Zăicoiu',
        simplu: 'Zaicoiu',
        comuna: 'Danciulesti'
      },
      {
        nume: 'Barza',
        comuna: 'Danesti'
      },
      {
        nume: 'Botorogi',
        comuna: 'Danesti'
      },
      {
        nume: 'Brătuia',
        simplu: 'Bratuia',
        comuna: 'Danesti'
      },
      {
        nume: 'Bucureasa',
        comuna: 'Danesti'
      },
      {
        nume: 'Merfuleşti',
        simplu: 'Merfulesti',
        comuna: 'Danesti'
      },
      {
        nume: 'Trocani',
        comuna: 'Danesti'
      },
      {
        nume: 'Ungureni',
        comuna: 'Danesti'
      },
      {
        nume: 'Văcarea',
        simplu: 'Vacarea',
        comuna: 'Danesti'
      },
      {
        nume: 'Sasa',
        simplu: 'Sasa',
        comuna: 'Danesti'
      },
      {
        nume: 'Târculeşti',
        simplu: 'Tarculesti',
        comuna: 'Danesti'
      },
      {
        nume: 'Corobăi',
        simplu: 'Corobai',
        comuna: 'Dragotesti'
      },
      {
        nume: 'Trestioara',
        comuna: 'Dragotesti'
      },
      {
        nume: 'Cârbeşti',
        simplu: 'Carbesti',
        comuna: 'Dragutesti'
      },
      {
        nume: 'Dâmbova',
        simplu: 'Dambova',
        comuna: 'Dragutesti'
      },
      {
        nume: 'Iaşi-Gorj',
        simplu: 'Iasi-Gorj',
        comuna: 'Dragutesti'
      },
      {
        nume: 'Tâlveşti',
        simplu: 'Talvesti',
        comuna: 'Dragutesti'
      },
      {
        nume: 'Urecheşti',
        simplu: 'Urechesti',
        comuna: 'Dragutesti'
      },
      {
        nume: 'Fărcăşeşti Moşneni',
        simplu: 'Farcasesti Mosneni',
        comuna: 'Farcasesti'
      },
      {
        nume: 'Peşteana de Jos',
        simplu: 'Pesteana de Jos',
        comuna: 'Farcasesti'
      },
      {
        nume: 'Rogojel',
        comuna: 'Farcasesti'
      },
      {
        nume: 'Roşia Jiu',
        simplu: 'Rosia Jiu',
        comuna: 'Farcasesti'
      },
      {
        nume: 'Timişeni',
        simplu: 'Timiseni',
        comuna: 'Farcasesti'
      },
      {
        nume: 'Valea cu Apă',
        simplu: 'Valea cu Apa',
        comuna: 'Farcasesti'
      },
      {
        nume: 'Cleşneşti',
        simplu: 'Clesnesti',
        comuna: 'Glogova'
      },
      {
        nume: 'Cămuieşti',
        simplu: 'Camuiesti',
        comuna: 'Glogova'
      },
      {
        nume: 'Iormăneşti',
        simplu: 'Iormanesti',
        comuna: 'Glogova'
      },
      {
        nume: 'Olteanu',
        comuna: 'Glogova'
      },
      {
        nume: 'Arjoci',
        comuna: 'Godinesti'
      },
      {
        nume: 'Chiliu',
        comuna: 'Godinesti'
      },
      {
        nume: 'Câlceşti',
        simplu: 'Calcesti',
        comuna: 'Godinesti'
      },
      {
        nume: 'Pârâu de Pripor',
        simplu: 'Parau de Pripor',
        comuna: 'Godinesti'
      },
      {
        nume: 'Pârâu de Vale',
        simplu: 'Parau de Vale',
        comuna: 'Godinesti'
      },
      {
        nume: 'Rătez',
        simplu: 'Ratez',
        comuna: 'Godinesti'
      },
      {
        nume: 'Busuioci',
        comuna: 'Hurezani'
      },
      {
        nume: 'Pegeni',
        comuna: 'Hurezani'
      },
      {
        nume: 'Plopu',
        comuna: 'Hurezani'
      },
      {
        nume: 'Totea de Hurezani',
        comuna: 'Hurezani'
      },
      {
        nume: 'Gura Suşiţei',
        simplu: 'Gura Susitei',
        comuna: 'Ionesti'
      },
      {
        nume: 'Ilieşti',
        simplu: 'Iliesti',
        comuna: 'Ionesti'
      },
      {
        nume: 'Picu',
        comuna: 'Ionesti'
      },
      {
        nume: 'Boia',
        comuna: 'Jupanesti'
      },
      {
        nume: 'Pârâu Boia',
        simplu: 'Parau Boia',
        comuna: 'Jupanesti'
      },
      {
        nume: 'Vidin',
        comuna: 'Jupanesti'
      },
      {
        nume: 'Vierşani',
        simplu: 'Viersani',
        comuna: 'Jupanesti'
      },
      {
        nume: 'Frăteşti',
        simplu: 'Fratesti',
        comuna: 'Lelesti'
      },
      {
        nume: 'Rasoviţa',
        simplu: 'Rasovita',
        comuna: 'Lelesti'
      },
      {
        nume: 'Frumuşei',
        simplu: 'Frumusei',
        comuna: 'Licurici'
      },
      {
        nume: 'Negreni',
        comuna: 'Licurici'
      },
      {
        nume: 'Totea',
        comuna: 'Licurici'
      },
      {
        nume: 'Colţeşti',
        simplu: 'Coltesti',
        comuna: 'Logresti'
      },
      {
        nume: 'Frunza',
        comuna: 'Logresti'
      },
      {
        nume: 'Logreşti Moşteni',
        simplu: 'Logresti Mosteni',
        comuna: 'Logresti'
      },
      {
        nume: 'Măru',
        simplu: 'Maru',
        comuna: 'Logresti'
      },
      {
        nume: 'Popeşti',
        simplu: 'Popesti',
        comuna: 'Logresti'
      },
      {
        nume: 'Seaca',
        comuna: 'Logresti'
      },
      {
        nume: 'Târgu Logreşti',
        simplu: 'Targu Logresti',
        comuna: 'Logresti'
      },
      {
        nume: 'Brădet',
        simplu: 'Bradet',
        comuna: 'Matasari'
      },
      {
        nume: 'Brădeţel',
        simplu: 'Bradetel',
        comuna: 'Matasari'
      },
      {
        nume: 'Croici',
        comuna: 'Matasari'
      },
      {
        nume: 'Runcurel',
        comuna: 'Matasari'
      },
      {
        nume: 'Dealu Pomilor',
        comuna: 'Motru'
      },
      {
        nume: 'Horăşti',
        simplu: 'Horasti',
        comuna: 'Motru'
      },
      {
        nume: 'Leurda',
        comuna: 'Motru'
      },
      {
        nume: 'Lupoiţa',
        simplu: 'Lupoita',
        comuna: 'Motru'
      },
      {
        nume: 'Ploştina',
        simplu: 'Plostina',
        comuna: 'Motru'
      },
      {
        nume: 'Roşiuţa',
        simplu: 'Rosiuta',
        comuna: 'Motru'
      },
      {
        nume: 'Râpa',
        simplu: 'Rapa',
        comuna: 'Motru'
      },
      {
        nume: 'Însurăţei',
        simplu: 'Insuratei',
        comuna: 'Motru'
      },
      {
        nume: 'Arşeni',
        simplu: 'Arseni',
        comuna: 'Musetesti'
      },
      {
        nume: 'Bârcaciu',
        simplu: 'Barcaciu',
        comuna: 'Musetesti'
      },
      {
        nume: 'Grui',
        comuna: 'Musetesti'
      },
      {
        nume: 'Gămani',
        simplu: 'Gamani',
        comuna: 'Musetesti'
      },
      {
        nume: 'Stănceşti',
        simplu: 'Stancesti',
        comuna: 'Musetesti'
      },
      {
        nume: 'Stănceşti Larga',
        simplu: 'Stancesti Larga',
        comuna: 'Musetesti'
      },
      {
        nume: 'Artanu',
        comuna: 'Negomir'
      },
      {
        nume: 'Bohorel',
        comuna: 'Negomir'
      },
      {
        nume: 'Condeieşti',
        simplu: 'Condeiesti',
        comuna: 'Negomir'
      },
      {
        nume: 'Nucetu',
        comuna: 'Negomir'
      },
      {
        nume: 'Orzu',
        comuna: 'Negomir'
      },
      {
        nume: 'Paltinu',
        comuna: 'Negomir'
      },
      {
        nume: 'Raci',
        comuna: 'Negomir'
      },
      {
        nume: 'Ursoaia',
        comuna: 'Negomir'
      },
      {
        nume: 'Valea Racilor',
        comuna: 'Negomir'
      },
      {
        nume: 'Berceşti',
        simplu: 'Bercesti',
        comuna: 'Novaci'
      },
      {
        nume: 'Hirişeşti',
        simplu: 'Hirisesti',
        comuna: 'Novaci'
      },
      {
        nume: 'Pociovaliştea',
        simplu: 'Pociovalistea',
        comuna: 'Novaci'
      },
      {
        nume: 'Siteşti',
        simplu: 'Sitesti',
        comuna: 'Novaci'
      },
      {
        nume: 'Apa Neagră',
        simplu: 'Apa Neagra',
        comuna: 'Pades'
      },
      {
        nume: 'Cerna-Sat',
        comuna: 'Pades'
      },
      {
        nume: 'Cloşani',
        simplu: 'Closani',
        comuna: 'Pades'
      },
      {
        nume: 'Călugăreni',
        simplu: 'Calugareni',
        comuna: 'Pades'
      },
      {
        nume: 'Motru Sec',
        comuna: 'Pades'
      },
      {
        nume: 'Orzeşti',
        simplu: 'Orzesti',
        comuna: 'Pades'
      },
      {
        nume: 'Văieni',
        simplu: 'Vaieni',
        comuna: 'Pades'
      },
      {
        nume: 'Boroşteni',
        simplu: 'Borosteni',
        comuna: 'Pestisani'
      },
      {
        nume: 'Brădiceni',
        simplu: 'Bradiceni',
        comuna: 'Pestisani'
      },
      {
        nume: 'Frânceşti',
        simplu: 'Francesti',
        comuna: 'Pestisani'
      },
      {
        nume: 'Gureni',
        comuna: 'Pestisani'
      },
      {
        nume: 'Hobiţa',
        simplu: 'Hobita',
        comuna: 'Pestisani'
      },
      {
        nume: 'Seuca',
        comuna: 'Pestisani'
      },
      {
        nume: 'Broşteni',
        simplu: 'Brosteni',
        comuna: 'Plopsoru'
      },
      {
        nume: 'Broştenii de Sus',
        simplu: 'Brostenii de Sus',
        comuna: 'Plopsoru'
      },
      {
        nume: 'Ceplea',
        comuna: 'Plopsoru'
      },
      {
        nume: 'Cursaru',
        comuna: 'Plopsoru'
      },
      {
        nume: 'Deleni',
        comuna: 'Plopsoru'
      },
      {
        nume: 'Izvoarele',
        comuna: 'Plopsoru'
      },
      {
        nume: 'Olari',
        comuna: 'Plopsoru'
      },
      {
        nume: 'Piscuri',
        comuna: 'Plopsoru'
      },
      {
        nume: 'Sărdăneşti',
        simplu: 'Sardanesti',
        comuna: 'Plopsoru'
      },
      {
        nume: 'Văleni',
        simplu: 'Valeni',
        comuna: 'Plopsoru'
      },
      {
        nume: 'Racoviţa',
        simplu: 'Racovita',
        comuna: 'Polovragi'
      },
      {
        nume: 'Bucşana',
        simplu: 'Bucsana',
        comuna: 'Prigoria'
      },
      {
        nume: 'Burlani',
        comuna: 'Prigoria'
      },
      {
        nume: 'Călugăreasa',
        simplu: 'Calugareasa',
        comuna: 'Prigoria'
      },
      {
        nume: 'Dobrana',
        comuna: 'Prigoria'
      },
      {
        nume: 'Negoieşti',
        simplu: 'Negoiesti',
        comuna: 'Prigoria'
      },
      {
        nume: 'Zorleşti',
        simplu: 'Zorlesti',
        comuna: 'Prigoria'
      },
      {
        nume: 'Becheni',
        comuna: 'Rosia De Amaradia'
      },
      {
        nume: 'Dealu Viei',
        comuna: 'Rosia De Amaradia'
      },
      {
        nume: 'Ruget',
        comuna: 'Rosia De Amaradia'
      },
      {
        nume: 'Seciurile',
        comuna: 'Rosia De Amaradia'
      },
      {
        nume: 'Stejaru',
        comuna: 'Rosia De Amaradia'
      },
      {
        nume: 'Sitoaia',
        simplu: 'Sitoaia',
        comuna: 'Rosia De Amaradia'
      },
      {
        nume: 'Vârt',
        simplu: 'Vart',
        comuna: 'Rovinari'
      },
      {
        nume: 'Bâlta',
        simplu: 'Balta',
        comuna: 'Runcu'
      },
      {
        nume: 'Bâltişoara',
        simplu: 'Baltisoara',
        comuna: 'Runcu'
      },
      {
        nume: 'Dobriţa',
        simplu: 'Dobrita',
        comuna: 'Runcu'
      },
      {
        nume: 'Răchiţi',
        simplu: 'Rachiti',
        comuna: 'Runcu'
      },
      {
        nume: 'Suseni',
        comuna: 'Runcu'
      },
      {
        nume: 'Valea Mare',
        comuna: 'Runcu'
      },
      {
        nume: 'Blahniţa de Sus',
        simplu: 'Blahnita de Sus',
        comuna: 'Sacelu'
      },
      {
        nume: 'Hăieşti',
        simplu: 'Haiesti',
        comuna: 'Sacelu'
      },
      {
        nume: 'Jeriştea',
        simplu: 'Jeristea',
        comuna: 'Sacelu'
      },
      {
        nume: 'Maghereşti',
        simplu: 'Magheresti',
        comuna: 'Sacelu'
      },
      {
        nume: 'Boca',
        comuna: 'Samarinesti'
      },
      {
        nume: 'Băzăvani',
        simplu: 'Bazavani',
        comuna: 'Samarinesti'
      },
      {
        nume: 'Duculeşti',
        simplu: 'Duculesti',
        comuna: 'Samarinesti'
      },
      {
        nume: 'Larga',
        comuna: 'Samarinesti'
      },
      {
        nume: 'Valea Bisericii',
        comuna: 'Samarinesti'
      },
      {
        nume: 'Valea Mică',
        simplu: 'Valea Mica',
        comuna: 'Samarinesti'
      },
      {
        nume: 'Valea Poienii',
        comuna: 'Samarinesti'
      },
      {
        nume: 'Tirioi',
        simplu: 'Tirioi',
        comuna: 'Samarinesti'
      },
      {
        nume: 'Bibeşti',
        simplu: 'Bibesti',
        comuna: 'Saulesti'
      },
      {
        nume: 'Dolceşti',
        simplu: 'Dolcesti',
        comuna: 'Saulesti'
      },
      {
        nume: 'Purcaru',
        comuna: 'Saulesti'
      },
      {
        nume: 'Arsuri',
        comuna: 'Schela'
      },
      {
        nume: 'Gornăcel',
        simplu: 'Gornacel',
        comuna: 'Schela'
      },
      {
        nume: 'Păjiştele',
        simplu: 'Pajistele',
        comuna: 'Schela'
      },
      {
        nume: 'Sâmbotin',
        simplu: 'Sambotin',
        comuna: 'Schela'
      },
      {
        nume: 'Bobu',
        comuna: 'Scoarta'
      },
      {
        nume: 'Budieni',
        comuna: 'Scoarta'
      },
      {
        nume: 'Cerătu de Copăcioasa',
        simplu: 'Ceratu de Copacioasa',
        comuna: 'Scoarta'
      },
      {
        nume: 'Colibaşi',
        simplu: 'Colibasi',
        comuna: 'Scoarta'
      },
      {
        nume: 'Copăcioasa',
        simplu: 'Copacioasa',
        comuna: 'Scoarta'
      },
      {
        nume: 'Câmpu Mare',
        simplu: 'Campu Mare',
        comuna: 'Scoarta'
      },
      {
        nume: 'Lazuri',
        comuna: 'Scoarta'
      },
      {
        nume: 'Lintea',
        comuna: 'Scoarta'
      },
      {
        nume: 'Mogoşani',
        simplu: 'Mogosani',
        comuna: 'Scoarta'
      },
      {
        nume: 'Pişteştii din Deal',
        simplu: 'Pistestii din Deal',
        comuna: 'Scoarta'
      },
      {
        nume: 'Cojmăneşti',
        simplu: 'Cojmanesti',
        comuna: 'Slivilesti'
      },
      {
        nume: 'Miculeşti',
        simplu: 'Miculesti',
        comuna: 'Slivilesti'
      },
      {
        nume: 'Strâmtu',
        simplu: 'Stramtu',
        comuna: 'Slivilesti'
      },
      {
        nume: 'Sura',
        comuna: 'Slivilesti'
      },
      {
        nume: 'Tehomir',
        comuna: 'Slivilesti'
      },
      {
        nume: 'Siacu',
        simplu: 'Siacu',
        comuna: 'Slivilesti'
      },
      {
        nume: 'Stiucani',
        simplu: 'Stiucani',
        comuna: 'Slivilesti'
      },
      {
        nume: 'Alexeni',
        comuna: 'Stanesti'
      },
      {
        nume: 'Bălani',
        simplu: 'Balani',
        comuna: 'Stanesti'
      },
      {
        nume: 'Curpen',
        comuna: 'Stanesti'
      },
      {
        nume: 'Căleşti',
        simplu: 'Calesti',
        comuna: 'Stanesti'
      },
      {
        nume: 'Măzăroi',
        simplu: 'Mazaroi',
        comuna: 'Stanesti'
      },
      {
        nume: 'Obreja',
        comuna: 'Stanesti'
      },
      {
        nume: 'Pârvuleşti',
        simplu: 'Parvulesti',
        comuna: 'Stanesti'
      },
      {
        nume: 'Vaidei',
        comuna: 'Stanesti'
      },
      {
        nume: 'Vălari',
        simplu: 'Valari',
        comuna: 'Stanesti'
      },
      {
        nume: 'Baloşani',
        simplu: 'Balosani',
        comuna: 'Stejari'
      },
      {
        nume: 'Băceşti',
        simplu: 'Bacesti',
        comuna: 'Stejari'
      },
      {
        nume: 'Dealu Leului',
        comuna: 'Stejari'
      },
      {
        nume: 'Piscoiu',
        comuna: 'Stejari'
      },
      {
        nume: 'Popeşti Stejari',
        simplu: 'Popesti Stejari',
        comuna: 'Stejari'
      },
      {
        nume: 'Ciorari',
        comuna: 'Stoina'
      },
      {
        nume: 'Mieluşei',
        simplu: 'Mielusei',
        comuna: 'Stoina'
      },
      {
        nume: 'Păişani',
        simplu: 'Paisani',
        comuna: 'Stoina'
      },
      {
        nume: 'Toiaga',
        comuna: 'Stoina'
      },
      {
        nume: 'Ulmet',
        comuna: 'Stoina'
      },
      {
        nume: 'Urda de Sus',
        comuna: 'Stoina'
      },
      {
        nume: 'Arpadia',
        comuna: 'Tantareni'
      },
      {
        nume: 'Chiciora',
        comuna: 'Tantareni'
      },
      {
        nume: 'Floreşti',
        simplu: 'Floresti',
        comuna: 'Tantareni'
      },
      {
        nume: 'Bârseşti',
        simplu: 'Barsesti',
        comuna: 'Targu Jiu'
      },
      {
        nume: 'Drăgoeni',
        simplu: 'Dragoeni',
        comuna: 'Targu Jiu'
      },
      {
        nume: 'Iezureni',
        comuna: 'Targu Jiu'
      },
      {
        nume: 'Polata',
        comuna: 'Targu Jiu'
      },
      {
        nume: 'Preajba Mare',
        comuna: 'Targu Jiu'
      },
      {
        nume: 'Romaneşti',
        simplu: 'Romanesti',
        comuna: 'Targu Jiu'
      },
      {
        nume: 'Slobozia',
        comuna: 'Targu Jiu'
      },
      {
        nume: 'Ursaţi',
        simplu: 'Ursati',
        comuna: 'Targu Jiu'
      },
      {
        nume: 'Buduhala',
        comuna: 'Telesti'
      },
      {
        nume: 'Somăneşti',
        simplu: 'Somanesti',
        comuna: 'Telesti'
      },
      {
        nume: 'Blahniţa de Jos',
        simplu: 'Blahnita de Jos',
        comuna: 'Tirgu Carbunesti'
      },
      {
        nume: 'Cojani',
        comuna: 'Tirgu Carbunesti'
      },
      {
        nume: 'Creţeşti',
        simplu: 'Cretesti',
        comuna: 'Tirgu Carbunesti'
      },
      {
        nume: 'Curteana',
        comuna: 'Tirgu Carbunesti'
      },
      {
        nume: 'Cărbuneşti-Sat',
        simplu: 'Carbunesti-Sat',
        comuna: 'Tirgu Carbunesti'
      },
      {
        nume: 'Floreşteni',
        simplu: 'Floresteni',
        comuna: 'Tirgu Carbunesti'
      },
      {
        nume: 'Măceşu',
        simplu: 'Macesu',
        comuna: 'Tirgu Carbunesti'
      },
      {
        nume: 'Pojogeni',
        comuna: 'Tirgu Carbunesti'
      },
      {
        nume: 'Rogojeni',
        comuna: 'Tirgu Carbunesti'
      },
      {
        nume: 'Târgu Cărbuneşti',
        simplu: 'Targu Carbunesti',
        comuna: 'Tirgu Carbunesti'
      },
      {
        nume: 'Stefăneşti',
        simplu: 'Stefanesti',
        comuna: 'Tirgu Carbunesti'
      },
      {
        nume: 'Celei',
        comuna: 'Tismana'
      },
      {
        nume: 'Costeni',
        comuna: 'Tismana'
      },
      {
        nume: 'Gornoviţa',
        simplu: 'Gornovita',
        comuna: 'Tismana'
      },
      {
        nume: 'Isvarna',
        comuna: 'Tismana'
      },
      {
        nume: 'Pocruia',
        comuna: 'Tismana'
      },
      {
        nume: 'Racoţi',
        simplu: 'Racoti',
        comuna: 'Tismana'
      },
      {
        nume: 'Sohodol',
        comuna: 'Tismana'
      },
      {
        nume: 'Topeşti',
        simplu: 'Topesti',
        comuna: 'Tismana'
      },
      {
        nume: 'Vâlcele',
        simplu: 'Valcele',
        comuna: 'Tismana'
      },
      {
        nume: 'Vânata',
        simplu: 'Vanata',
        comuna: 'Tismana'
      },
      {
        nume: 'Cocorova',
        comuna: 'Turburea'
      },
      {
        nume: 'Poiana',
        comuna: 'Turburea'
      },
      {
        nume: 'Spahii',
        comuna: 'Turburea'
      },
      {
        nume: 'Sipotu',
        simplu: 'Sipotu',
        comuna: 'Turburea'
      },
      {
        nume: 'Gârbovu',
        simplu: 'Garbovu',
        comuna: 'Turceni'
      },
      {
        nume: 'Jilţu',
        simplu: 'Jiltu',
        comuna: 'Turceni'
      },
      {
        nume: 'Murgeşti',
        simplu: 'Murgesti',
        comuna: 'Turceni'
      },
      {
        nume: 'Strâmba Jiu',
        simplu: 'Stramba Jiu',
        comuna: 'Turceni'
      },
      {
        nume: 'Valea Viei',
        comuna: 'Turceni'
      },
      {
        nume: 'Cartiu',
        comuna: 'Turcinesti'
      },
      {
        nume: 'Horezu',
        comuna: 'Turcinesti'
      },
      {
        nume: 'Rugi',
        comuna: 'Turcinesti'
      },
      {
        nume: 'Fântânele',
        simplu: 'Fantanele',
        comuna: 'Urdari'
      },
      {
        nume: 'Hotăroasa',
        simplu: 'Hotaroasa',
        comuna: 'Urdari'
      },
      {
        nume: 'Covrigi',
        comuna: 'Vagiulesti'
      },
      {
        nume: 'Cârciu',
        simplu: 'Carciu',
        comuna: 'Vagiulesti'
      },
      {
        nume: 'Murgileşti',
        simplu: 'Murgilesti',
        comuna: 'Vagiulesti'
      },
      {
        nume: 'Valea Motrului',
        comuna: 'Vagiulesti'
      },
      {
        nume: 'Andreeşti',
        simplu: 'Andreesti',
        comuna: 'Vladimir'
      },
      {
        nume: 'Frasin',
        comuna: 'Vladimir'
      },
      {
        nume: 'Valea Deşului',
        simplu: 'Valea Desului',
        comuna: 'Vladimir'
      }
    ]
  },
  {
    auto: 'HR',
    nume: 'Harghita',
    localitati: [
      {
        nume: 'Atid'
      },
      {
        nume: 'Avrămeşti',
        simplu: 'Avramesti'
      },
      {
        nume: 'Bilbor'
      },
      {
        nume: 'Borsec'
      },
      {
        nume: 'Brădeşti',
        simplu: 'Bradesti'
      },
      {
        nume: 'Băile Tuşnad',
        simplu: 'Baile Tusnad'
      },
      {
        nume: 'Bălan',
        simplu: 'Balan'
      },
      {
        nume: 'Ciucsângeorgiu',
        simplu: 'Ciucsangeorgiu'
      },
      {
        nume: 'Ciumani'
      },
      {
        nume: 'Corbu'
      },
      {
        nume: 'Corund'
      },
      {
        nume: 'Cozmeni'
      },
      {
        nume: 'Cristuru Secuiesc'
      },
      {
        nume: 'Cârţa',
        simplu: 'Carta'
      },
      {
        nume: 'Căpâlniţa',
        simplu: 'Capalnita'
      },
      {
        nume: 'Dealu'
      },
      {
        nume: 'Ditrău',
        simplu: 'Ditrau'
      },
      {
        nume: 'Dârjiu',
        simplu: 'Darjiu'
      },
      {
        nume: 'Dăneşti',
        simplu: 'Danesti'
      },
      {
        nume: 'Frumoasa'
      },
      {
        nume: 'Gheorgheni'
      },
      {
        nume: 'Gălăuţaş',
        simplu: 'Galautas'
      },
      {
        nume: 'Joseni'
      },
      {
        nume: 'Lueta'
      },
      {
        nume: 'Lunca de Jos'
      },
      {
        nume: 'Lunca de Sus'
      },
      {
        nume: 'Lupeni'
      },
      {
        nume: 'Lăzarea',
        simplu: 'Lazarea'
      },
      {
        nume: 'Mereşti',
        simplu: 'Meresti'
      },
      {
        nume: 'Miercurea Ciuc'
      },
      {
        nume: 'Mihăileni',
        simplu: 'Mihaileni'
      },
      {
        nume: 'Mugeni'
      },
      {
        nume: 'Mădăraş',
        simplu: 'Madaras'
      },
      {
        nume: 'Mărtiniş',
        simplu: 'Martinis'
      },
      {
        nume: 'Ocland'
      },
      {
        nume: 'Odorheiu Secuiesc'
      },
      {
        nume: 'Plăieşii de Jos',
        simplu: 'Plaiesii de Jos'
      },
      {
        nume: 'Praid'
      },
      {
        nume: 'Păuleni-Ciuc',
        simplu: 'Pauleni-Ciuc'
      },
      {
        nume: 'Remetea'
      },
      {
        nume: 'Secuieni'
      },
      {
        nume: 'Siculeni'
      },
      {
        nume: 'Subcetate'
      },
      {
        nume: 'Suseni'
      },
      {
        nume: 'Sâncrăieni',
        simplu: 'Sancraieni'
      },
      {
        nume: 'Sândominic',
        simplu: 'Sandominic'
      },
      {
        nume: 'Sânmartin',
        simplu: 'Sanmartin'
      },
      {
        nume: 'Sânsimion',
        simplu: 'Sansimion'
      },
      {
        nume: 'Săcel',
        simplu: 'Sacel'
      },
      {
        nume: 'Sărmaş',
        simplu: 'Sarmas'
      },
      {
        nume: 'Tomeşti',
        simplu: 'Tomesti'
      },
      {
        nume: 'Topliţa',
        simplu: 'Toplita'
      },
      {
        nume: 'Tulgheş',
        simplu: 'Tulghes'
      },
      {
        nume: 'Tuşnad',
        simplu: 'Tusnad'
      },
      {
        nume: 'Ulieş',
        simplu: 'Ulies'
      },
      {
        nume: 'Vlăhiţa',
        simplu: 'Vlahita'
      },
      {
        nume: 'Voşlăbeni',
        simplu: 'Voslabeni'
      },
      {
        nume: 'Vărşag',
        simplu: 'Varsag'
      },
      {
        nume: 'Zetea'
      },
      {
        nume: 'Simoneşti',
        simplu: 'Simonesti'
      },
      {
        nume: 'Crişeni',
        simplu: 'Criseni',
        comuna: 'Atid'
      },
      {
        nume: 'Cuşmed',
        simplu: 'Cusmed',
        comuna: 'Atid'
      },
      {
        nume: 'Inlăceni',
        simplu: 'Inlaceni',
        comuna: 'Atid'
      },
      {
        nume: 'Siclod',
        simplu: 'Siclod',
        comuna: 'Atid'
      },
      {
        nume: 'Andreeni',
        comuna: 'Avramesti'
      },
      {
        nume: 'Cecheşti',
        simplu: 'Cechesti',
        comuna: 'Avramesti'
      },
      {
        nume: 'Firtănuş',
        simplu: 'Firtanus',
        comuna: 'Avramesti'
      },
      {
        nume: 'Goagiu',
        comuna: 'Avramesti'
      },
      {
        nume: 'Laz-Firtănuş',
        simplu: 'Laz-Firtanus',
        comuna: 'Avramesti'
      },
      {
        nume: 'Laz-Soimuş',
        simplu: 'Laz-Soimus',
        comuna: 'Avramesti'
      },
      {
        nume: 'Medişoru Mic',
        simplu: 'Medisoru Mic',
        comuna: 'Avramesti'
      },
      {
        nume: 'Carpitus',
        comuna: 'Baile Tusnad'
      },
      {
        nume: 'Răchitiş',
        simplu: 'Rachitis',
        comuna: 'Bilbor'
      },
      {
        nume: 'Satu Mare',
        comuna: 'Bradesti'
      },
      {
        nume: 'Târnoviţa',
        simplu: 'Tarnovita',
        comuna: 'Bradesti'
      },
      {
        nume: 'Ineu',
        comuna: 'Carta'
      },
      {
        nume: 'Armăşeni',
        simplu: 'Armaseni',
        comuna: 'Ciucsangeorgiu'
      },
      {
        nume: 'Armăşenii Noi',
        simplu: 'Armasenii Noi',
        comuna: 'Ciucsangeorgiu'
      },
      {
        nume: 'Bancu',
        comuna: 'Ciucsangeorgiu'
      },
      {
        nume: 'Ciobăniş',
        simplu: 'Ciobanis',
        comuna: 'Ciucsangeorgiu'
      },
      {
        nume: 'Cotormani',
        comuna: 'Ciucsangeorgiu'
      },
      {
        nume: 'Eghersec',
        comuna: 'Ciucsangeorgiu'
      },
      {
        nume: 'Ghiurche',
        comuna: 'Ciucsangeorgiu'
      },
      {
        nume: 'Potiond',
        comuna: 'Ciucsangeorgiu'
      },
      {
        nume: 'Capu Corbului',
        comuna: 'Corbu'
      },
      {
        nume: 'Atia',
        comuna: 'Corund'
      },
      {
        nume: 'Calonda',
        comuna: 'Corund'
      },
      {
        nume: 'Fântâna Brazilor',
        simplu: 'Fantana Brazilor',
        comuna: 'Corund'
      },
      {
        nume: 'Valea lui Pavel',
        comuna: 'Corund'
      },
      {
        nume: 'Lăzăreşti',
        simplu: 'Lazaresti',
        comuna: 'Cozmeni'
      },
      {
        nume: 'Filiaş',
        simplu: 'Filias',
        comuna: 'Cristuru Secuiesc'
      },
      {
        nume: 'Mujna',
        comuna: 'Darjiu'
      },
      {
        nume: 'Fâncel',
        simplu: 'Fancel',
        comuna: 'Dealu'
      },
      {
        nume: 'Sâncrai',
        simplu: 'Sancrai',
        comuna: 'Dealu'
      },
      {
        nume: 'Tibod',
        comuna: 'Dealu'
      },
      {
        nume: 'Tămaşu',
        simplu: 'Tamasu',
        comuna: 'Dealu'
      },
      {
        nume: 'Ulcani',
        comuna: 'Dealu'
      },
      {
        nume: 'Valea Rotundă',
        simplu: 'Valea Rotunda',
        comuna: 'Dealu'
      },
      {
        nume: 'Jolotca',
        comuna: 'Ditrau'
      },
      {
        nume: 'Tengheler',
        simplu: 'Tengheler',
        comuna: 'Ditrau'
      },
      {
        nume: 'Alexandriţa',
        simplu: 'Alexandrita',
        comuna: 'Felicieni'
      },
      {
        nume: 'Arvăţeni',
        simplu: 'Arvateni',
        comuna: 'Felicieni'
      },
      {
        nume: 'Cireşeni',
        simplu: 'Cireseni',
        comuna: 'Felicieni'
      },
      {
        nume: 'Feliceni',
        comuna: 'Felicieni'
      },
      {
        nume: 'Forţeni',
        simplu: 'Forteni',
        comuna: 'Felicieni'
      },
      {
        nume: 'Hoghia',
        comuna: 'Felicieni'
      },
      {
        nume: 'Oţeni',
        simplu: 'Oteni',
        comuna: 'Felicieni'
      },
      {
        nume: 'Poloniţa',
        simplu: 'Polonita',
        comuna: 'Felicieni'
      },
      {
        nume: 'Teleac',
        comuna: 'Felicieni'
      },
      {
        nume: 'Tăureni',
        simplu: 'Taureni',
        comuna: 'Felicieni'
      },
      {
        nume: 'Văleni',
        simplu: 'Valeni',
        comuna: 'Felicieni'
      },
      {
        nume: 'Bârzava',
        simplu: 'Barzava',
        comuna: 'Frumoasa'
      },
      {
        nume: 'Făgeţel',
        simplu: 'Fagetel',
        comuna: 'Frumoasa'
      },
      {
        nume: 'Nicoleşti',
        simplu: 'Nicolesti',
        comuna: 'Frumoasa'
      },
      {
        nume: 'Dealu Armanului',
        comuna: 'Galautas'
      },
      {
        nume: 'Gălăuţaş-Pârău',
        simplu: 'Galautas-Parau',
        comuna: 'Galautas'
      },
      {
        nume: 'Nuţeni',
        simplu: 'Nuteni',
        comuna: 'Galautas'
      },
      {
        nume: 'Plopiş',
        simplu: 'Plopis',
        comuna: 'Galautas'
      },
      {
        nume: 'Preluca',
        comuna: 'Galautas'
      },
      {
        nume: 'Toleşeni',
        simplu: 'Toleseni',
        comuna: 'Galautas'
      },
      {
        nume: 'Zăpodea',
        simplu: 'Zapodea',
        comuna: 'Galautas'
      },
      {
        nume: 'Covacipeter',
        comuna: 'Gheorgheni'
      },
      {
        nume: 'Lacu Roşu',
        simplu: 'Lacu Rosu',
        comuna: 'Gheorgheni'
      },
      {
        nume: 'Vargatac',
        comuna: 'Gheorgheni'
      },
      {
        nume: 'Visafolio',
        comuna: 'Gheorgheni'
      },
      {
        nume: 'Borzont',
        comuna: 'Joseni'
      },
      {
        nume: 'Bucin',
        comuna: 'Joseni'
      },
      {
        nume: 'Ghiduţ',
        simplu: 'Ghidut',
        comuna: 'Lazarea'
      },
      {
        nume: 'Băile Chirui',
        simplu: 'Baile Chirui',
        comuna: 'Lueta'
      },
      {
        nume: 'Baraţcoş',
        simplu: 'Baratcos',
        comuna: 'Lunca De Jos'
      },
      {
        nume: 'Poiana Fagului',
        comuna: 'Lunca De Jos'
      },
      {
        nume: 'Puntea Lupului',
        comuna: 'Lunca De Jos'
      },
      {
        nume: 'Valea Boroş',
        simplu: 'Valea Boros',
        comuna: 'Lunca De Jos'
      },
      {
        nume: 'Valea Capelei',
        comuna: 'Lunca De Jos'
      },
      {
        nume: 'Valea Rece',
        comuna: 'Lunca De Jos'
      },
      {
        nume: 'Valea lui Antaloc',
        comuna: 'Lunca De Jos'
      },
      {
        nume: 'Valea Întunecoasă',
        simplu: 'Valea Intunecoasa',
        comuna: 'Lunca De Jos'
      },
      {
        nume: 'Comiat',
        comuna: 'Lunca De Sus'
      },
      {
        nume: 'Izvorul Trotuşului',
        simplu: 'Izvorul Trotusului',
        comuna: 'Lunca De Sus'
      },
      {
        nume: 'Păltiniş-Ciuc',
        simplu: 'Paltinis-Ciuc',
        comuna: 'Lunca De Sus'
      },
      {
        nume: 'Valea Gârbea',
        simplu: 'Valea Garbea',
        comuna: 'Lunca De Sus'
      },
      {
        nume: 'Valea Ugra',
        comuna: 'Lunca De Sus'
      },
      {
        nume: 'Bisericani',
        comuna: 'Lupeni'
      },
      {
        nume: 'Bulgăreni',
        simplu: 'Bulgareni',
        comuna: 'Lupeni'
      },
      {
        nume: 'Firtuşu',
        simplu: 'Firtusu',
        comuna: 'Lupeni'
      },
      {
        nume: 'Morăreni',
        simplu: 'Morareni',
        comuna: 'Lupeni'
      },
      {
        nume: 'Păltiniş',
        simplu: 'Paltinis',
        comuna: 'Lupeni'
      },
      {
        nume: 'Păuleni',
        simplu: 'Pauleni',
        comuna: 'Lupeni'
      },
      {
        nume: 'Satu Mic',
        comuna: 'Lupeni'
      },
      {
        nume: 'Sâncel',
        simplu: 'Sancel',
        comuna: 'Lupeni'
      },
      {
        nume: 'Aldea',
        comuna: 'Martinis'
      },
      {
        nume: 'Bădeni',
        simplu: 'Badeni',
        comuna: 'Martinis'
      },
      {
        nume: 'Chinuşu',
        simplu: 'Chinusu',
        comuna: 'Martinis'
      },
      {
        nume: 'Comăneşti',
        simplu: 'Comanesti',
        comuna: 'Martinis'
      },
      {
        nume: 'Călugăreni',
        simplu: 'Calugareni',
        comuna: 'Martinis'
      },
      {
        nume: 'Ghipeş',
        simplu: 'Ghipes',
        comuna: 'Martinis'
      },
      {
        nume: 'Locodeni',
        comuna: 'Martinis'
      },
      {
        nume: 'Orăşeni',
        simplu: 'Oraseni',
        comuna: 'Martinis'
      },
      {
        nume: 'Petreni',
        comuna: 'Martinis'
      },
      {
        nume: 'Rareş',
        simplu: 'Rares',
        comuna: 'Martinis'
      },
      {
        nume: 'Sânpaul',
        simplu: 'Sanpaul',
        comuna: 'Martinis'
      },
      {
        nume: 'Ciba',
        comuna: 'Miercurea Ciuc'
      },
      {
        nume: 'Harghita-Băi',
        simplu: 'Harghita-Bai',
        comuna: 'Miercurea Ciuc'
      },
      {
        nume: 'Jigodin-Băi',
        simplu: 'Jigodin-Bai',
        comuna: 'Miercurea Ciuc'
      },
      {
        nume: 'Livezi',
        comuna: 'Mihaileni'
      },
      {
        nume: 'Nădejdea',
        simplu: 'Nadejdea',
        comuna: 'Mihaileni'
      },
      {
        nume: 'Văcăreşti',
        simplu: 'Vacaresti',
        comuna: 'Mihaileni'
      },
      {
        nume: 'Aluniş',
        simplu: 'Alunis',
        comuna: 'Mugeni'
      },
      {
        nume: 'Beta',
        comuna: 'Mugeni'
      },
      {
        nume: 'Beteşti',
        simplu: 'Betesti',
        comuna: 'Mugeni'
      },
      {
        nume: 'Dejuţiu',
        simplu: 'Dejutiu',
        comuna: 'Mugeni'
      },
      {
        nume: 'Dobeni',
        comuna: 'Mugeni'
      },
      {
        nume: 'Lutiţa',
        simplu: 'Lutita',
        comuna: 'Mugeni'
      },
      {
        nume: 'Mătişeni',
        simplu: 'Matiseni',
        comuna: 'Mugeni'
      },
      {
        nume: 'Porumbenii Mari',
        comuna: 'Mugeni'
      },
      {
        nume: 'Porumbenii Mici',
        comuna: 'Mugeni'
      },
      {
        nume: 'Tăietura',
        simplu: 'Taietura',
        comuna: 'Mugeni'
      },
      {
        nume: 'Crăciunel',
        simplu: 'Craciunel',
        comuna: 'Ocland'
      },
      {
        nume: 'Satu Nou',
        comuna: 'Ocland'
      },
      {
        nume: 'Delniţa',
        simplu: 'Delnita',
        comuna: 'Pauleni-Ciuc'
      },
      {
        nume: 'Soimeni',
        simplu: 'Soimeni',
        comuna: 'Pauleni-Ciuc'
      },
      {
        nume: 'Casinu Nou',
        comuna: 'Plaiesii De Jos'
      },
      {
        nume: 'Iacobeni',
        comuna: 'Plaiesii De Jos'
      },
      {
        nume: 'Imper',
        comuna: 'Plaiesii De Jos'
      },
      {
        nume: 'Plăieşii de Sus',
        simplu: 'Plaiesii de Sus',
        comuna: 'Plaiesii De Jos'
      },
      {
        nume: 'Becaş',
        simplu: 'Becas',
        comuna: 'Praid'
      },
      {
        nume: 'Bucin',
        comuna: 'Praid'
      },
      {
        nume: 'Ocna de Jos',
        comuna: 'Praid'
      },
      {
        nume: 'Ocna de Sus',
        comuna: 'Praid'
      },
      {
        nume: 'Saşvereş',
        simplu: 'Sasveres',
        comuna: 'Praid'
      },
      {
        nume: 'Făgeţel',
        simplu: 'Fagetel',
        comuna: 'Remetea'
      },
      {
        nume: 'Martonca',
        comuna: 'Remetea'
      },
      {
        nume: 'Sineu',
        comuna: 'Remetea'
      },
      {
        nume: 'Uilac',
        comuna: 'Sacel'
      },
      {
        nume: 'Vidacut',
        comuna: 'Sacel'
      },
      {
        nume: 'Soimuşu Mare',
        simplu: 'Soimusu Mare',
        comuna: 'Sacel'
      },
      {
        nume: 'Soimuşu Mic',
        simplu: 'Soimusu Mic',
        comuna: 'Sacel'
      },
      {
        nume: 'Fitod',
        comuna: 'Sancraieni'
      },
      {
        nume: 'Hosasău',
        simplu: 'Hosasau',
        comuna: 'Sancraieni'
      },
      {
        nume: 'Leliceni',
        comuna: 'Sancraieni'
      },
      {
        nume: 'Misentea',
        comuna: 'Sancraieni'
      },
      {
        nume: 'Sântimbru',
        simplu: 'Santimbru',
        comuna: 'Sancraieni'
      },
      {
        nume: 'Sântimbru-Băi',
        simplu: 'Santimbru-Bai',
        comuna: 'Sancraieni'
      },
      {
        nume: 'Ciucani',
        comuna: 'Sanmartin'
      },
      {
        nume: 'Valea Uzului',
        comuna: 'Sanmartin'
      },
      {
        nume: 'Cetăţuia',
        simplu: 'Cetatuia',
        comuna: 'Sansimion'
      },
      {
        nume: 'Fundoaia',
        comuna: 'Sarmas'
      },
      {
        nume: 'Hodoşa',
        simplu: 'Hodosa',
        comuna: 'Sarmas'
      },
      {
        nume: 'Platoneşti',
        simplu: 'Platonesti',
        comuna: 'Sarmas'
      },
      {
        nume: 'Runc',
        comuna: 'Sarmas'
      },
      {
        nume: 'Bodogaia',
        comuna: 'Secuieni'
      },
      {
        nume: 'Eliseni',
        comuna: 'Secuieni'
      },
      {
        nume: 'Ciaracio',
        comuna: 'Siculeni'
      },
      {
        nume: 'Ciceu',
        comuna: 'Siculeni'
      },
      {
        nume: 'Racu',
        comuna: 'Siculeni'
      },
      {
        nume: 'Satu Nou',
        comuna: 'Siculeni'
      },
      {
        nume: 'Benţid',
        simplu: 'Bentid',
        comuna: 'Simonesti'
      },
      {
        nume: 'Ceheţel',
        simplu: 'Cehetel',
        comuna: 'Simonesti'
      },
      {
        nume: 'Chedia Mare',
        comuna: 'Simonesti'
      },
      {
        nume: 'Chedia Mică',
        simplu: 'Chedia Mica',
        comuna: 'Simonesti'
      },
      {
        nume: 'Cobăteşti',
        simplu: 'Cobatesti',
        comuna: 'Simonesti'
      },
      {
        nume: 'Cădaciu Mare',
        simplu: 'Cadaciu Mare',
        comuna: 'Simonesti'
      },
      {
        nume: 'Cădaciu Mic',
        simplu: 'Cadaciu Mic',
        comuna: 'Simonesti'
      },
      {
        nume: 'Medişoru Mare',
        simplu: 'Medisoru Mare',
        comuna: 'Simonesti'
      },
      {
        nume: 'Mihăileni',
        simplu: 'Mihaileni',
        comuna: 'Simonesti'
      },
      {
        nume: 'Nicoleni',
        comuna: 'Simonesti'
      },
      {
        nume: 'Rugăneşti',
        simplu: 'Ruganesti',
        comuna: 'Simonesti'
      },
      {
        nume: 'Turdeni',
        comuna: 'Simonesti'
      },
      {
        nume: 'Tărceşti',
        simplu: 'Tarcesti',
        comuna: 'Simonesti'
      },
      {
        nume: 'Călnaci',
        simplu: 'Calnaci',
        comuna: 'Subcetate'
      },
      {
        nume: 'Duda',
        comuna: 'Subcetate'
      },
      {
        nume: 'Filpea',
        comuna: 'Subcetate'
      },
      {
        nume: 'Chileni',
        comuna: 'Suseni'
      },
      {
        nume: 'Liban',
        comuna: 'Suseni'
      },
      {
        nume: 'Senetea',
        comuna: 'Suseni'
      },
      {
        nume: 'Valea Strâmbă',
        simplu: 'Valea Stramba',
        comuna: 'Suseni'
      },
      {
        nume: 'Călimănel',
        simplu: 'Calimanel',
        comuna: 'Toplita'
      },
      {
        nume: 'Luncani',
        comuna: 'Toplita'
      },
      {
        nume: 'Moglăneşti',
        simplu: 'Moglanesti',
        comuna: 'Toplita'
      },
      {
        nume: 'Măgheruş',
        simplu: 'Magherus',
        comuna: 'Toplita'
      },
      {
        nume: 'Secu',
        comuna: 'Toplita'
      },
      {
        nume: 'Vale',
        comuna: 'Toplita'
      },
      {
        nume: 'Vâgani',
        simplu: 'Vagani',
        comuna: 'Toplita'
      },
      {
        nume: 'Zencani',
        comuna: 'Toplita'
      },
      {
        nume: 'Hagota',
        comuna: 'Tulghes'
      },
      {
        nume: 'Pintic',
        comuna: 'Tulghes'
      },
      {
        nume: 'Recea',
        comuna: 'Tulghes'
      },
      {
        nume: 'Tuşnadu Nou',
        simplu: 'Tusnadu Nou',
        comuna: 'Tusnad'
      },
      {
        nume: 'Vrabia',
        comuna: 'Tusnad'
      },
      {
        nume: 'Daia',
        comuna: 'Ulies'
      },
      {
        nume: 'Iaşu',
        simplu: 'Iasu',
        comuna: 'Ulies'
      },
      {
        nume: 'Ighiu',
        comuna: 'Ulies'
      },
      {
        nume: 'Nicoleşti',
        simplu: 'Nicolesti',
        comuna: 'Ulies'
      },
      {
        nume: 'Obrăneşti',
        simplu: 'Obranesti',
        comuna: 'Ulies'
      },
      {
        nume: 'Petecu',
        comuna: 'Ulies'
      },
      {
        nume: 'Vasileni',
        comuna: 'Ulies'
      },
      {
        nume: 'Băile Homorod',
        simplu: 'Baile Homorod',
        comuna: 'Vlahita'
      },
      {
        nume: 'Minele Lueta',
        comuna: 'Vlahita'
      },
      {
        nume: 'Izvoru Mureşului',
        simplu: 'Izvoru Muresului',
        comuna: 'Voslabeni'
      },
      {
        nume: 'Desag',
        comuna: 'Zetea'
      },
      {
        nume: 'Izvoare',
        comuna: 'Zetea'
      },
      {
        nume: 'Poiana Târnavei',
        simplu: 'Poiana Tarnavei',
        comuna: 'Zetea'
      },
      {
        nume: 'Sub Cetate',
        comuna: 'Zetea'
      },
      {
        nume: 'Sicasău',
        simplu: 'Sicasau',
        comuna: 'Zetea'
      }
    ]
  },
  {
    auto: 'HD',
    nume: 'Hunedoara',
    localitati: [
      {
        nume: 'Aninoasa'
      },
      {
        nume: 'Baia de Criş',
        simplu: 'Baia de Cris'
      },
      {
        nume: 'Balşa',
        simplu: 'Balsa'
      },
      {
        nume: 'Baru'
      },
      {
        nume: 'Beriu'
      },
      {
        nume: 'Blăjeni',
        simplu: 'Blajeni'
      },
      {
        nume: 'Boşorod',
        simplu: 'Bosorod'
      },
      {
        nume: 'Brad'
      },
      {
        nume: 'Bretea Română',
        simplu: 'Bretea Romana'
      },
      {
        nume: 'Brănişca',
        simplu: 'Branisca'
      },
      {
        nume: 'Buceş',
        simplu: 'Buces'
      },
      {
        nume: 'Bucureşci',
        simplu: 'Bucuresci'
      },
      {
        nume: 'Bulzeştii de Sus',
        simplu: 'Bulzestii de Sus'
      },
      {
        nume: 'Bunila'
      },
      {
        nume: 'Burjuc'
      },
      {
        nume: 'Băcia',
        simplu: 'Bacia'
      },
      {
        nume: 'Băiţa',
        simplu: 'Baita'
      },
      {
        nume: 'Băniţa',
        simplu: 'Banita'
      },
      {
        nume: 'Bătrâna',
        simplu: 'Batrana'
      },
      {
        nume: 'Cerbăl',
        simplu: 'Cerbal'
      },
      {
        nume: 'Certeju de Sus'
      },
      {
        nume: 'Crişcior',
        simplu: 'Criscior'
      },
      {
        nume: 'Cârjiţi',
        simplu: 'Carjiti'
      },
      {
        nume: 'Călan',
        simplu: 'Calan'
      },
      {
        nume: 'Densuş',
        simplu: 'Densus'
      },
      {
        nume: 'Deva'
      },
      {
        nume: 'Dobra'
      },
      {
        nume: 'General Berthelot'
      },
      {
        nume: 'Geoagiu'
      },
      {
        nume: 'Ghelari'
      },
      {
        nume: 'Gurasada'
      },
      {
        nume: 'Haţeg',
        simplu: 'Hateg'
      },
      {
        nume: 'Hunedoara'
      },
      {
        nume: 'Hărău',
        simplu: 'Harau'
      },
      {
        nume: 'Ilia'
      },
      {
        nume: 'Lelese'
      },
      {
        nume: 'Lunca Cernii de Jos'
      },
      {
        nume: 'Luncoiu de Jos'
      },
      {
        nume: 'Lupeni'
      },
      {
        nume: 'Lăpugiu de Jos',
        simplu: 'Lapugiu de Jos'
      },
      {
        nume: 'Mărtineşti',
        simplu: 'Martinesti'
      },
      {
        nume: 'Orăştie',
        simplu: 'Orastie'
      },
      {
        nume: 'Orăştioara de Sus',
        simplu: 'Orastioara de Sus'
      },
      {
        nume: 'Pestişu Mic',
        simplu: 'Pestisu Mic'
      },
      {
        nume: 'Petrila'
      },
      {
        nume: 'Petroşani',
        simplu: 'Petrosani'
      },
      {
        nume: 'Pui'
      },
      {
        nume: 'Rapoltu Mare'
      },
      {
        nume: 'Ribiţa',
        simplu: 'Ribita'
      },
      {
        nume: 'Romos'
      },
      {
        nume: 'Râu de Mori',
        simplu: 'Rau de Mori'
      },
      {
        nume: 'Răchitova',
        simplu: 'Rachitova'
      },
      {
        nume: 'Sarmizegetusa'
      },
      {
        nume: 'Simeria'
      },
      {
        nume: 'Sântămăria-Orlea',
        simplu: 'Santamaria-Orlea'
      },
      {
        nume: 'Sălaşu de Sus',
        simplu: 'Salasu de Sus'
      },
      {
        nume: 'Teliucu Inferior'
      },
      {
        nume: 'Tomeşti',
        simplu: 'Tomesti'
      },
      {
        nume: 'Topliţa',
        simplu: 'Toplita'
      },
      {
        nume: 'Toteşti',
        simplu: 'Totesti'
      },
      {
        nume: 'Turdaş',
        simplu: 'Turdas'
      },
      {
        nume: 'Uricani'
      },
      {
        nume: 'Vaţa de Jos',
        simplu: 'Vata de Jos'
      },
      {
        nume: 'Veţel',
        simplu: 'Vetel'
      },
      {
        nume: 'Vorţa',
        simplu: 'Vorta'
      },
      {
        nume: 'Vulcan'
      },
      {
        nume: 'Vălişoara',
        simplu: 'Valisoara'
      },
      {
        nume: 'Zam'
      },
      {
        nume: 'Soimuş',
        simplu: 'Soimus'
      },
      {
        nume: 'Iscroni',
        comuna: 'Aninoasa'
      },
      {
        nume: 'Petreni',
        comuna: 'Bacia'
      },
      {
        nume: 'Totia',
        comuna: 'Bacia'
      },
      {
        nume: 'Tâmpa',
        simplu: 'Tampa',
        comuna: 'Bacia'
      },
      {
        nume: 'Baldovin',
        comuna: 'Baia De Cris'
      },
      {
        nume: 'Căraci',
        simplu: 'Caraci',
        comuna: 'Baia De Cris'
      },
      {
        nume: 'Cărăstău',
        simplu: 'Carastau',
        comuna: 'Baia De Cris'
      },
      {
        nume: 'Lunca',
        comuna: 'Baia De Cris'
      },
      {
        nume: 'Rişca',
        simplu: 'Risca',
        comuna: 'Baia De Cris'
      },
      {
        nume: 'Rişculiţa',
        simplu: 'Risculita',
        comuna: 'Baia De Cris'
      },
      {
        nume: 'Văleni',
        simplu: 'Valeni',
        comuna: 'Baia De Cris'
      },
      {
        nume: 'Tebea',
        simplu: 'Tebea',
        comuna: 'Baia De Cris'
      },
      {
        nume: 'Barbura',
        comuna: 'Baita'
      },
      {
        nume: 'Crăciuneşti',
        simplu: 'Craciunesti',
        comuna: 'Baita'
      },
      {
        nume: 'Căinelu de Sus',
        simplu: 'Cainelu de Sus',
        comuna: 'Baita'
      },
      {
        nume: 'Fizeş',
        simplu: 'Fizes',
        comuna: 'Baita'
      },
      {
        nume: 'Hărţăgani',
        simplu: 'Hartagani',
        comuna: 'Baita'
      },
      {
        nume: 'Lunca',
        comuna: 'Baita'
      },
      {
        nume: 'Ormindea',
        comuna: 'Baita'
      },
      {
        nume: 'Peştera',
        simplu: 'Pestera',
        comuna: 'Baita'
      },
      {
        nume: 'Sălişte',
        simplu: 'Saliste',
        comuna: 'Baita'
      },
      {
        nume: 'Trestia',
        comuna: 'Baita'
      },
      {
        nume: 'Almaşu Mic de Munte',
        simplu: 'Almasu Mic de Munte',
        comuna: 'Balsa'
      },
      {
        nume: 'Ardeu',
        comuna: 'Balsa'
      },
      {
        nume: 'Buneşti',
        simplu: 'Bunesti',
        comuna: 'Balsa'
      },
      {
        nume: 'Galbina',
        comuna: 'Balsa'
      },
      {
        nume: 'Mada',
        comuna: 'Balsa'
      },
      {
        nume: 'Oprişeşti',
        simplu: 'Oprisesti',
        comuna: 'Balsa'
      },
      {
        nume: 'Poiana',
        comuna: 'Balsa'
      },
      {
        nume: 'Poieniţa',
        simplu: 'Poienita',
        comuna: 'Balsa'
      },
      {
        nume: 'Roşia',
        simplu: 'Rosia',
        comuna: 'Balsa'
      },
      {
        nume: 'Stăuini',
        simplu: 'Stauini',
        comuna: 'Balsa'
      },
      {
        nume: 'Techereu',
        comuna: 'Balsa'
      },
      {
        nume: 'Voia',
        comuna: 'Balsa'
      },
      {
        nume: 'Vălişoara',
        simplu: 'Valisoara',
        comuna: 'Balsa'
      },
      {
        nume: 'Crivadia',
        comuna: 'Banita'
      },
      {
        nume: 'Merişor',
        simplu: 'Merisor',
        comuna: 'Banita'
      },
      {
        nume: 'Livadia',
        comuna: 'Baru'
      },
      {
        nume: 'Petros',
        comuna: 'Baru'
      },
      {
        nume: 'Valea Lupului',
        comuna: 'Baru'
      },
      {
        nume: 'Faţa Roşie',
        simplu: 'Fata Rosie',
        comuna: 'Batrana'
      },
      {
        nume: 'Piatra',
        comuna: 'Batrana'
      },
      {
        nume: 'Răchiţaua',
        simplu: 'Rachitaua',
        comuna: 'Batrana'
      },
      {
        nume: 'Cucuiş',
        simplu: 'Cucuis',
        comuna: 'Beriu'
      },
      {
        nume: 'Căstău',
        simplu: 'Castau',
        comuna: 'Beriu'
      },
      {
        nume: 'Măgureni',
        simplu: 'Magureni',
        comuna: 'Beriu'
      },
      {
        nume: 'Orăştioara de Jos',
        simplu: 'Orastioara de Jos',
        comuna: 'Beriu'
      },
      {
        nume: 'Poieni',
        comuna: 'Beriu'
      },
      {
        nume: 'Sereca',
        comuna: 'Beriu'
      },
      {
        nume: 'Sibişel',
        simplu: 'Sibisel',
        comuna: 'Beriu'
      },
      {
        nume: 'Blăjeni-Vulcan',
        simplu: 'Blajeni-Vulcan',
        comuna: 'Blajeni'
      },
      {
        nume: 'Criş',
        simplu: 'Cris',
        comuna: 'Blajeni'
      },
      {
        nume: 'Dragu-Brad',
        comuna: 'Blajeni'
      },
      {
        nume: 'Groşuri',
        simplu: 'Grosuri',
        comuna: 'Blajeni'
      },
      {
        nume: 'Plai',
        comuna: 'Blajeni'
      },
      {
        nume: 'Reţ',
        simplu: 'Ret',
        comuna: 'Blajeni'
      },
      {
        nume: 'Sălătruc',
        simplu: 'Salatruc',
        comuna: 'Blajeni'
      },
      {
        nume: 'Alun',
        comuna: 'Bosorod'
      },
      {
        nume: 'Bobaia',
        comuna: 'Bosorod'
      },
      {
        nume: 'Chitid',
        comuna: 'Bosorod'
      },
      {
        nume: 'Cioclovina',
        comuna: 'Bosorod'
      },
      {
        nume: 'Luncani',
        comuna: 'Bosorod'
      },
      {
        nume: 'Prihodişte',
        simplu: 'Prihodiste',
        comuna: 'Bosorod'
      },
      {
        nume: 'Târsa',
        simplu: 'Tarsa',
        comuna: 'Bosorod'
      },
      {
        nume: 'Ursici',
        comuna: 'Bosorod'
      },
      {
        nume: 'Mesteacăn',
        simplu: 'Mesteacan',
        comuna: 'Brad'
      },
      {
        nume: 'Potingani',
        comuna: 'Brad'
      },
      {
        nume: 'Ruda-Brad',
        comuna: 'Brad'
      },
      {
        nume: 'Valea Bradului',
        comuna: 'Brad'
      },
      {
        nume: 'Tărăţel',
        simplu: 'Taratel',
        comuna: 'Brad'
      },
      {
        nume: 'Boz',
        comuna: 'Branisca'
      },
      {
        nume: 'Bărăştii Iliei',
        simplu: 'Barastii Iliei',
        comuna: 'Branisca'
      },
      {
        nume: 'Căbeşti',
        simplu: 'Cabesti',
        comuna: 'Branisca'
      },
      {
        nume: 'Furcşoara',
        simplu: 'Furcsoara',
        comuna: 'Branisca'
      },
      {
        nume: 'Gialacuta',
        comuna: 'Branisca'
      },
      {
        nume: 'Rovina',
        comuna: 'Branisca'
      },
      {
        nume: 'Târnava',
        simplu: 'Tarnava',
        comuna: 'Branisca'
      },
      {
        nume: 'Târnăviţa',
        simplu: 'Tarnavita',
        comuna: 'Branisca'
      },
      {
        nume: 'Bercu',
        comuna: 'Bretea Romana'
      },
      {
        nume: 'Bretea Streiului',
        comuna: 'Bretea Romana'
      },
      {
        nume: 'Băţălar',
        simplu: 'Batalar',
        comuna: 'Bretea Romana'
      },
      {
        nume: 'Covragiu',
        comuna: 'Bretea Romana'
      },
      {
        nume: 'Gânţaga',
        simplu: 'Gantaga',
        comuna: 'Bretea Romana'
      },
      {
        nume: 'Măceu',
        simplu: 'Maceu',
        comuna: 'Bretea Romana'
      },
      {
        nume: 'Ocolişu Mare',
        simplu: 'Ocolisu Mare',
        comuna: 'Bretea Romana'
      },
      {
        nume: 'Plopi',
        comuna: 'Bretea Romana'
      },
      {
        nume: 'Ruşi',
        simplu: 'Rusi',
        comuna: 'Bretea Romana'
      },
      {
        nume: 'Vâlcele',
        simplu: 'Valcele',
        comuna: 'Bretea Romana'
      },
      {
        nume: 'Vâlcelele Bune',
        simplu: 'Valcelele Bune',
        comuna: 'Bretea Romana'
      },
      {
        nume: 'Vâlceluţa',
        simplu: 'Valceluta',
        comuna: 'Bretea Romana'
      },
      {
        nume: 'Buceş-Vulcan',
        simplu: 'Buces-Vulcan',
        comuna: 'Buces'
      },
      {
        nume: 'Dupăpiatră',
        simplu: 'Dupapiatra',
        comuna: 'Buces'
      },
      {
        nume: 'Grohoţele',
        simplu: 'Grohotele',
        comuna: 'Buces'
      },
      {
        nume: 'Mihăileni',
        simplu: 'Mihaileni',
        comuna: 'Buces'
      },
      {
        nume: 'Stănija',
        simplu: 'Stanija',
        comuna: 'Buces'
      },
      {
        nume: 'Tarniţa',
        simplu: 'Tarnita',
        comuna: 'Buces'
      },
      {
        nume: 'Curechiu',
        comuna: 'Bucuresci'
      },
      {
        nume: 'Merişor',
        simplu: 'Merisor',
        comuna: 'Bucuresci'
      },
      {
        nume: 'Rovina',
        comuna: 'Bucuresci'
      },
      {
        nume: 'Sesuri',
        simplu: 'Sesuri',
        comuna: 'Bucuresci'
      },
      {
        nume: 'Bulzeştii de Jos',
        simplu: 'Bulzestii de Jos',
        comuna: 'Bulzestii De Sus'
      },
      {
        nume: 'Giurgeşti',
        simplu: 'Giurgesti',
        comuna: 'Bulzestii De Sus'
      },
      {
        nume: 'Grohot',
        comuna: 'Bulzestii De Sus'
      },
      {
        nume: 'Păuleşti',
        simplu: 'Paulesti',
        comuna: 'Bulzestii De Sus'
      },
      {
        nume: 'Ruseşti',
        simplu: 'Rusesti',
        comuna: 'Bulzestii De Sus'
      },
      {
        nume: 'Stănculeşti',
        simplu: 'Stanculesti',
        comuna: 'Bulzestii De Sus'
      },
      {
        nume: 'Ticera',
        comuna: 'Bulzestii De Sus'
      },
      {
        nume: 'Tomnatec',
        comuna: 'Bulzestii De Sus'
      },
      {
        nume: 'Alun',
        comuna: 'Bunila'
      },
      {
        nume: 'Cernişoara Florese',
        simplu: 'Cernisoara Florese',
        comuna: 'Bunila'
      },
      {
        nume: 'Poieniţa Voinii',
        simplu: 'Poienita Voinii',
        comuna: 'Bunila'
      },
      {
        nume: 'Vadu Dobrii',
        comuna: 'Bunila'
      },
      {
        nume: 'Brădăţel',
        simplu: 'Bradatel',
        comuna: 'Burjuc'
      },
      {
        nume: 'Glodghileşti',
        simplu: 'Glodghilesti',
        comuna: 'Burjuc'
      },
      {
        nume: 'Petreşti',
        simplu: 'Petresti',
        comuna: 'Burjuc'
      },
      {
        nume: 'Tisa',
        comuna: 'Burjuc'
      },
      {
        nume: 'Tătărăşti',
        simplu: 'Tatarasti',
        comuna: 'Burjuc'
      },
      {
        nume: 'Batiz',
        comuna: 'Calan'
      },
      {
        nume: 'Călanu Mic',
        simplu: 'Calanu Mic',
        comuna: 'Calan'
      },
      {
        nume: 'Grid',
        comuna: 'Calan'
      },
      {
        nume: 'Nădăştia de Jos',
        simplu: 'Nadastia de Jos',
        comuna: 'Calan'
      },
      {
        nume: 'Nădăştia de Sus',
        simplu: 'Nadastia de Sus',
        comuna: 'Calan'
      },
      {
        nume: 'Ohaba Streiului',
        comuna: 'Calan'
      },
      {
        nume: 'Strei',
        comuna: 'Calan'
      },
      {
        nume: 'Strei-Săcel',
        simplu: 'Strei-Sacel',
        comuna: 'Calan'
      },
      {
        nume: 'Streisângeorgiu',
        simplu: 'Streisangeorgiu',
        comuna: 'Calan'
      },
      {
        nume: 'Sâncrai',
        simplu: 'Sancrai',
        comuna: 'Calan'
      },
      {
        nume: 'Sântămăria de Piatră',
        simplu: 'Santamaria de Piatra',
        comuna: 'Calan'
      },
      {
        nume: 'Valea Sângeorgiului',
        simplu: 'Valea Sangeorgiului',
        comuna: 'Calan'
      },
      {
        nume: 'Almaşu Sec',
        simplu: 'Almasu Sec',
        comuna: 'Carjiti'
      },
      {
        nume: 'Chergheş',
        simplu: 'Cherghes',
        comuna: 'Carjiti'
      },
      {
        nume: 'Cozia',
        comuna: 'Carjiti'
      },
      {
        nume: 'Popeşti',
        simplu: 'Popesti',
        comuna: 'Carjiti'
      },
      {
        nume: 'Arănieş',
        simplu: 'Aranies',
        comuna: 'Cerbal'
      },
      {
        nume: 'Feregi',
        comuna: 'Cerbal'
      },
      {
        nume: 'Merişoru de Munte',
        simplu: 'Merisoru de Munte',
        comuna: 'Cerbal'
      },
      {
        nume: 'Poiana Răchiţelii',
        simplu: 'Poiana Rachitelii',
        comuna: 'Cerbal'
      },
      {
        nume: 'Poieniţa Tomii',
        simplu: 'Poienita Tomii',
        comuna: 'Cerbal'
      },
      {
        nume: 'Socet',
        comuna: 'Cerbal'
      },
      {
        nume: 'Ulm',
        comuna: 'Cerbal'
      },
      {
        nume: 'Bocşa Mare',
        simplu: 'Bocsa Mare',
        comuna: 'Certeju De Sus'
      },
      {
        nume: 'Bocşa Mică',
        simplu: 'Bocsa Mica',
        comuna: 'Certeju De Sus'
      },
      {
        nume: 'Hondol',
        comuna: 'Certeju De Sus'
      },
      {
        nume: 'Măgura-Topliţa',
        simplu: 'Magura-Toplita',
        comuna: 'Certeju De Sus'
      },
      {
        nume: 'Nojag',
        comuna: 'Certeju De Sus'
      },
      {
        nume: 'Săcărâmb',
        simplu: 'Sacaramb',
        comuna: 'Certeju De Sus'
      },
      {
        nume: 'Topliţa Mureşului',
        simplu: 'Toplita Muresului',
        comuna: 'Certeju De Sus'
      },
      {
        nume: 'Vărmaga',
        simplu: 'Varmaga',
        comuna: 'Certeju De Sus'
      },
      {
        nume: 'Barza',
        comuna: 'Criscior'
      },
      {
        nume: 'Valea Arsului',
        comuna: 'Criscior'
      },
      {
        nume: 'Zdrapţi',
        simplu: 'Zdrapti',
        comuna: 'Criscior'
      },
      {
        nume: 'Criva',
        comuna: 'Densus'
      },
      {
        nume: 'Hăţăgel',
        simplu: 'Hatagel',
        comuna: 'Densus'
      },
      {
        nume: 'Peşteana',
        simplu: 'Pesteana',
        comuna: 'Densus'
      },
      {
        nume: 'Peşteniţa',
        simplu: 'Pestenita',
        comuna: 'Densus'
      },
      {
        nume: 'Poieni',
        comuna: 'Densus'
      },
      {
        nume: 'Stei',
        simplu: 'Stei',
        comuna: 'Densus'
      },
      {
        nume: 'Archia',
        comuna: 'Deva'
      },
      {
        nume: 'Bârcea Mică',
        simplu: 'Barcea Mica',
        comuna: 'Deva'
      },
      {
        nume: 'Cristur',
        comuna: 'Deva'
      },
      {
        nume: 'Sântuhalm',
        simplu: 'Santuhalm',
        comuna: 'Deva'
      },
      {
        nume: 'Abucea',
        comuna: 'Dobra'
      },
      {
        nume: 'Bujoru',
        comuna: 'Dobra'
      },
      {
        nume: 'Făgeţel',
        simplu: 'Fagetel',
        comuna: 'Dobra'
      },
      {
        nume: 'Lăpuşnic',
        simplu: 'Lapusnic',
        comuna: 'Dobra'
      },
      {
        nume: 'Mihăieşti',
        simplu: 'Mihaiesti',
        comuna: 'Dobra'
      },
      {
        nume: 'Panc',
        comuna: 'Dobra'
      },
      {
        nume: 'Panc-Sălişte',
        simplu: 'Panc-Saliste',
        comuna: 'Dobra'
      },
      {
        nume: 'Roşcani',
        simplu: 'Roscani',
        comuna: 'Dobra'
      },
      {
        nume: 'Răduleşti',
        simplu: 'Radulesti',
        comuna: 'Dobra'
      },
      {
        nume: 'Stretea',
        comuna: 'Dobra'
      },
      {
        nume: 'Stânceşti',
        simplu: 'Stancesti',
        comuna: 'Dobra'
      },
      {
        nume: 'Stânceşti-Ohaba',
        simplu: 'Stancesti-Ohaba',
        comuna: 'Dobra'
      },
      {
        nume: 'Crăguiş',
        simplu: 'Craguis',
        comuna: 'General Berthelot'
      },
      {
        nume: 'Fărcădin',
        simplu: 'Farcadin',
        comuna: 'General Berthelot'
      },
      {
        nume: 'Livezi',
        comuna: 'General Berthelot'
      },
      {
        nume: 'Tuştea',
        simplu: 'Tustea',
        comuna: 'General Berthelot'
      },
      {
        nume: 'Aurel Vlaicu',
        comuna: 'Geoagiu'
      },
      {
        nume: 'Bacâia',
        simplu: 'Bacaia',
        comuna: 'Geoagiu'
      },
      {
        nume: 'Bozeş',
        simplu: 'Bozes',
        comuna: 'Geoagiu'
      },
      {
        nume: 'Cigmău',
        simplu: 'Cigmau',
        comuna: 'Geoagiu'
      },
      {
        nume: 'Gelmar',
        comuna: 'Geoagiu'
      },
      {
        nume: 'Geoagiu-Băi',
        simplu: 'Geoagiu-Bai',
        comuna: 'Geoagiu'
      },
      {
        nume: 'Homorod',
        comuna: 'Geoagiu'
      },
      {
        nume: 'Mermezeu-Văleni',
        simplu: 'Mermezeu-Valeni',
        comuna: 'Geoagiu'
      },
      {
        nume: 'Renghet',
        comuna: 'Geoagiu'
      },
      {
        nume: 'Văleni',
        simplu: 'Valeni',
        comuna: 'Geoagiu'
      },
      {
        nume: 'Govăjdia',
        simplu: 'Govajdia',
        comuna: 'Ghelari'
      },
      {
        nume: 'Plop',
        comuna: 'Ghelari'
      },
      {
        nume: 'Ruda',
        comuna: 'Ghelari'
      },
      {
        nume: 'Boiu de Jos',
        comuna: 'Gurasada'
      },
      {
        nume: 'Boiu de Sus',
        comuna: 'Gurasada'
      },
      {
        nume: 'Câmpuri de Sus',
        simplu: 'Campuri de Sus',
        comuna: 'Gurasada'
      },
      {
        nume: 'Câmpuri-Surduc',
        simplu: 'Campuri-Surduc',
        comuna: 'Gurasada'
      },
      {
        nume: 'Cărmăzăneşti',
        simplu: 'Carmazanesti',
        comuna: 'Gurasada'
      },
      {
        nume: 'Dănuleşti',
        simplu: 'Danulesti',
        comuna: 'Gurasada'
      },
      {
        nume: 'Gothatea',
        comuna: 'Gurasada'
      },
      {
        nume: 'Runcşor',
        simplu: 'Runcsor',
        comuna: 'Gurasada'
      },
      {
        nume: 'Ulieş',
        simplu: 'Ulies',
        comuna: 'Gurasada'
      },
      {
        nume: 'Vica',
        comuna: 'Gurasada'
      },
      {
        nume: 'Banpotoc',
        comuna: 'Harau'
      },
      {
        nume: 'Bârsău',
        simplu: 'Barsau',
        comuna: 'Harau'
      },
      {
        nume: 'Chimindia',
        comuna: 'Harau'
      },
      {
        nume: 'Nălaţvad',
        simplu: 'Nalatvad',
        comuna: 'Hateg'
      },
      {
        nume: 'Silvaşu de Jos',
        simplu: 'Silvasu de Jos',
        comuna: 'Hateg'
      },
      {
        nume: 'Silvaşu de Sus',
        simplu: 'Silvasu de Sus',
        comuna: 'Hateg'
      },
      {
        nume: 'Boş',
        simplu: 'Bos',
        comuna: 'Hunedoara'
      },
      {
        nume: 'Groş',
        simplu: 'Gros',
        comuna: 'Hunedoara'
      },
      {
        nume: 'Hăşdat',
        simplu: 'Hasdat',
        comuna: 'Hunedoara'
      },
      {
        nume: 'Peştişu Mare',
        simplu: 'Pestisu Mare',
        comuna: 'Hunedoara'
      },
      {
        nume: 'Răcăştia',
        simplu: 'Racastia',
        comuna: 'Hunedoara'
      },
      {
        nume: 'Bacea',
        comuna: 'Ilia'
      },
      {
        nume: 'Bretea Mureşană',
        simplu: 'Bretea Muresana',
        comuna: 'Ilia'
      },
      {
        nume: 'Brâznic',
        simplu: 'Braznic',
        comuna: 'Ilia'
      },
      {
        nume: 'Cuieş',
        simplu: 'Cuies',
        comuna: 'Ilia'
      },
      {
        nume: 'Dumbrăviţa',
        simplu: 'Dumbravita',
        comuna: 'Ilia'
      },
      {
        nume: 'Sârbi',
        simplu: 'Sarbi',
        comuna: 'Ilia'
      },
      {
        nume: 'Săcămaş',
        simplu: 'Sacamas',
        comuna: 'Ilia'
      },
      {
        nume: 'Valea Lungă',
        simplu: 'Valea Lunga',
        comuna: 'Ilia'
      },
      {
        nume: 'Baştea',
        simplu: 'Bastea',
        comuna: 'Lapugiu De Jos'
      },
      {
        nume: 'Coseşti',
        simplu: 'Cosesti',
        comuna: 'Lapugiu De Jos'
      },
      {
        nume: 'Fintoag',
        comuna: 'Lapugiu De Jos'
      },
      {
        nume: 'Grind',
        comuna: 'Lapugiu De Jos'
      },
      {
        nume: 'Holdea',
        comuna: 'Lapugiu De Jos'
      },
      {
        nume: 'Lăpugiu de Sus',
        simplu: 'Lapugiu de Sus',
        comuna: 'Lapugiu De Jos'
      },
      {
        nume: 'Lăsău',
        simplu: 'Lasau',
        comuna: 'Lapugiu De Jos'
      },
      {
        nume: 'Ohaba',
        comuna: 'Lapugiu De Jos'
      },
      {
        nume: 'Teiu',
        comuna: 'Lapugiu De Jos'
      },
      {
        nume: 'Cerişor',
        simplu: 'Cerisor',
        comuna: 'Lelese'
      },
      {
        nume: 'Runcu Mare',
        comuna: 'Lelese'
      },
      {
        nume: 'Sohodol',
        comuna: 'Lelese'
      },
      {
        nume: 'Ciumiţa',
        simplu: 'Ciumita',
        comuna: 'Lunca Cernii De Jos'
      },
      {
        nume: 'Fântâna',
        simplu: 'Fantana',
        comuna: 'Lunca Cernii De Jos'
      },
      {
        nume: 'Gura Bordului',
        comuna: 'Lunca Cernii De Jos'
      },
      {
        nume: 'Lunca Cernii de Sus',
        comuna: 'Lunca Cernii De Jos'
      },
      {
        nume: 'Meria',
        comuna: 'Lunca Cernii De Jos'
      },
      {
        nume: 'Negoiu',
        comuna: 'Lunca Cernii De Jos'
      },
      {
        nume: 'Valea Babii',
        comuna: 'Lunca Cernii De Jos'
      },
      {
        nume: 'Dudeşti',
        simplu: 'Dudesti',
        comuna: 'Luncoiu De Jos'
      },
      {
        nume: 'Luncoiu de Sus',
        comuna: 'Luncoiu De Jos'
      },
      {
        nume: 'Podele',
        comuna: 'Luncoiu De Jos'
      },
      {
        nume: 'Stejărel',
        simplu: 'Stejarel',
        comuna: 'Luncoiu De Jos'
      },
      {
        nume: 'Dâncu Mare',
        simplu: 'Dancu Mare',
        comuna: 'Martinesti'
      },
      {
        nume: 'Dâncu Mic',
        simplu: 'Dancu Mic',
        comuna: 'Martinesti'
      },
      {
        nume: 'Jeledinţi',
        simplu: 'Jeledinti',
        comuna: 'Martinesti'
      },
      {
        nume: 'Măgura',
        simplu: 'Magura',
        comuna: 'Martinesti'
      },
      {
        nume: 'Turmaş',
        simplu: 'Turmas',
        comuna: 'Martinesti'
      },
      {
        nume: 'Tămăşasa',
        simplu: 'Tamasasa',
        comuna: 'Martinesti'
      },
      {
        nume: 'Bucium',
        comuna: 'Orastioara De Sus'
      },
      {
        nume: 'Costeşti',
        simplu: 'Costesti',
        comuna: 'Orastioara De Sus'
      },
      {
        nume: 'Costeşti-Deal',
        simplu: 'Costesti-Deal',
        comuna: 'Orastioara De Sus'
      },
      {
        nume: 'Grădiştea de Munte',
        simplu: 'Gradistea de Munte',
        comuna: 'Orastioara De Sus'
      },
      {
        nume: 'Ludeştii de Jos',
        simplu: 'Ludestii de Jos',
        comuna: 'Orastioara De Sus'
      },
      {
        nume: 'Ludeştii de Sus',
        simplu: 'Ludestii de Sus',
        comuna: 'Orastioara De Sus'
      },
      {
        nume: 'Ocolişu Mic',
        simplu: 'Ocolisu Mic',
        comuna: 'Orastioara De Sus'
      },
      {
        nume: 'Almaşu Mic',
        simplu: 'Almasu Mic',
        comuna: 'Pestisu Mic'
      },
      {
        nume: 'Ciulpăz',
        simplu: 'Ciulpaz',
        comuna: 'Pestisu Mic'
      },
      {
        nume: 'Cutin',
        comuna: 'Pestisu Mic'
      },
      {
        nume: 'Dumbrava',
        comuna: 'Pestisu Mic'
      },
      {
        nume: 'Josani',
        comuna: 'Pestisu Mic'
      },
      {
        nume: 'Mănerău',
        simplu: 'Manerau',
        comuna: 'Pestisu Mic'
      },
      {
        nume: 'Nandru',
        comuna: 'Pestisu Mic'
      },
      {
        nume: 'Valea Nandrului',
        comuna: 'Pestisu Mic'
      },
      {
        nume: 'Cimpa',
        comuna: 'Petrila'
      },
      {
        nume: 'Jieţ',
        simplu: 'Jiet',
        comuna: 'Petrila'
      },
      {
        nume: 'Răscoala',
        simplu: 'Rascoala',
        comuna: 'Petrila'
      },
      {
        nume: 'Tirici',
        comuna: 'Petrila'
      },
      {
        nume: 'Dâlja Mare',
        simplu: 'Dalja Mare',
        comuna: 'Petrosani'
      },
      {
        nume: 'Dâlja Mică',
        simplu: 'Dalja Mica',
        comuna: 'Petrosani'
      },
      {
        nume: 'Peştera',
        simplu: 'Pestera',
        comuna: 'Petrosani'
      },
      {
        nume: 'Slătinioara',
        simplu: 'Slatinioara',
        comuna: 'Petrosani'
      },
      {
        nume: 'Băieşti',
        simplu: 'Baiesti',
        comuna: 'Pui'
      },
      {
        nume: 'Federi',
        comuna: 'Pui'
      },
      {
        nume: 'Fizeşti',
        simplu: 'Fizesti',
        comuna: 'Pui'
      },
      {
        nume: 'Galaţi',
        simplu: 'Galati',
        comuna: 'Pui'
      },
      {
        nume: 'Hobiţa',
        simplu: 'Hobita',
        comuna: 'Pui'
      },
      {
        nume: 'Ohaba-Ponor',
        comuna: 'Pui'
      },
      {
        nume: 'Ponor',
        comuna: 'Pui'
      },
      {
        nume: 'Ruşor',
        simplu: 'Rusor',
        comuna: 'Pui'
      },
      {
        nume: 'Râu Bărbat',
        simplu: 'Rau Barbat',
        comuna: 'Pui'
      },
      {
        nume: 'Uric',
        comuna: 'Pui'
      },
      {
        nume: 'Serel',
        simplu: 'Serel',
        comuna: 'Pui'
      },
      {
        nume: 'Boiţa',
        simplu: 'Boita',
        comuna: 'Rachitova'
      },
      {
        nume: 'Ciula Mare',
        comuna: 'Rachitova'
      },
      {
        nume: 'Ciula Mică',
        simplu: 'Ciula Mica',
        comuna: 'Rachitova'
      },
      {
        nume: 'Goteşti',
        simplu: 'Gotesti',
        comuna: 'Rachitova'
      },
      {
        nume: 'Mesteacăn',
        simplu: 'Mesteacan',
        comuna: 'Rachitova'
      },
      {
        nume: 'Vălioara',
        simplu: 'Valioara',
        comuna: 'Rachitova'
      },
      {
        nume: 'Bobâlna',
        simplu: 'Bobalna',
        comuna: 'Rapoltu Mare'
      },
      {
        nume: 'Boiu',
        comuna: 'Rapoltu Mare'
      },
      {
        nume: 'Folt',
        comuna: 'Rapoltu Mare'
      },
      {
        nume: 'Rapolţel',
        simplu: 'Rapoltel',
        comuna: 'Rapoltu Mare'
      },
      {
        nume: 'Brazi',
        comuna: 'Rau De Mori'
      },
      {
        nume: 'Clopotiva',
        comuna: 'Rau De Mori'
      },
      {
        nume: 'Ohaba-Sibişel',
        simplu: 'Ohaba-Sibisel',
        comuna: 'Rau De Mori'
      },
      {
        nume: 'Ostrov',
        comuna: 'Rau De Mori'
      },
      {
        nume: 'Ostrovel',
        comuna: 'Rau De Mori'
      },
      {
        nume: 'Ostrovu Mic',
        comuna: 'Rau De Mori'
      },
      {
        nume: 'Sibişel',
        simplu: 'Sibisel',
        comuna: 'Rau De Mori'
      },
      {
        nume: 'Suseni',
        comuna: 'Rau De Mori'
      },
      {
        nume: 'Unciuc',
        comuna: 'Rau De Mori'
      },
      {
        nume: 'Valea Dâljii',
        simplu: 'Valea Daljii',
        comuna: 'Rau De Mori'
      },
      {
        nume: 'Crişan',
        simplu: 'Crisan',
        comuna: 'Ribita'
      },
      {
        nume: 'Dumbrava de Jos',
        comuna: 'Ribita'
      },
      {
        nume: 'Dumbrava de Sus',
        comuna: 'Ribita'
      },
      {
        nume: 'Ribicioara',
        comuna: 'Ribita'
      },
      {
        nume: 'Uibăreşti',
        simplu: 'Uibaresti',
        comuna: 'Ribita'
      },
      {
        nume: 'Ciungu Mare',
        comuna: 'Romos'
      },
      {
        nume: 'Pişchinţi',
        simplu: 'Pischinti',
        comuna: 'Romos'
      },
      {
        nume: 'Romoşel',
        simplu: 'Romosel',
        comuna: 'Romos'
      },
      {
        nume: 'Vaidei',
        comuna: 'Romos'
      },
      {
        nume: 'Coroieşti',
        simplu: 'Coroiesti',
        comuna: 'Salasu De Sus'
      },
      {
        nume: 'Mălăieşti',
        simplu: 'Malaiesti',
        comuna: 'Salasu De Sus'
      },
      {
        nume: 'Nucşoara',
        simplu: 'Nucsoara',
        comuna: 'Salasu De Sus'
      },
      {
        nume: 'Ohaba de Sub Piatră',
        simplu: 'Ohaba de Sub Piatra',
        comuna: 'Salasu De Sus'
      },
      {
        nume: 'Paroş',
        simplu: 'Paros',
        comuna: 'Salasu De Sus'
      },
      {
        nume: 'Peştera',
        simplu: 'Pestera',
        comuna: 'Salasu De Sus'
      },
      {
        nume: 'Râu Alb',
        simplu: 'Rau Alb',
        comuna: 'Salasu De Sus'
      },
      {
        nume: 'Râu Mic',
        simplu: 'Rau Mic',
        comuna: 'Salasu De Sus'
      },
      {
        nume: 'Sălaşu de Jos',
        simplu: 'Salasu de Jos',
        comuna: 'Salasu De Sus'
      },
      {
        nume: 'Zăvoi',
        simplu: 'Zavoi',
        comuna: 'Salasu De Sus'
      },
      {
        nume: 'Balomir',
        comuna: 'Santamaria-Orlea'
      },
      {
        nume: 'Bucium-Orlea',
        comuna: 'Santamaria-Orlea'
      },
      {
        nume: 'Bărăştii Haţegului',
        simplu: 'Barastii Hategului',
        comuna: 'Santamaria-Orlea'
      },
      {
        nume: 'Ciopeia',
        comuna: 'Santamaria-Orlea'
      },
      {
        nume: 'Subcetate',
        comuna: 'Santamaria-Orlea'
      },
      {
        nume: 'Sânpetru',
        simplu: 'Sanpetru',
        comuna: 'Santamaria-Orlea'
      },
      {
        nume: 'Săcel',
        simplu: 'Sacel',
        comuna: 'Santamaria-Orlea'
      },
      {
        nume: 'Vadu',
        comuna: 'Santamaria-Orlea'
      },
      {
        nume: 'Breazova',
        comuna: 'Sarmizegetusa'
      },
      {
        nume: 'Hobiţa-Grădişte',
        simplu: 'Hobita-Gradiste',
        comuna: 'Sarmizegetusa'
      },
      {
        nume: 'Păucineşti',
        simplu: 'Paucinesti',
        comuna: 'Sarmizegetusa'
      },
      {
        nume: 'Zeicani',
        comuna: 'Sarmizegetusa'
      },
      {
        nume: 'Bârcea Mare',
        simplu: 'Barcea Mare',
        comuna: 'Simeria'
      },
      {
        nume: 'Cărpiniş',
        simplu: 'Carpinis',
        comuna: 'Simeria'
      },
      {
        nume: 'Simeria Veche',
        comuna: 'Simeria'
      },
      {
        nume: 'Sântandrei',
        simplu: 'Santandrei',
        comuna: 'Simeria'
      },
      {
        nume: 'Uroi',
        comuna: 'Simeria'
      },
      {
        nume: 'Săuleşti',
        simplu: 'Saulesti',
        comuna: 'Simeria'
      },
      {
        nume: 'Bejan',
        comuna: 'Soimus'
      },
      {
        nume: 'Bejan-Târnăviţa',
        simplu: 'Bejan-Tarnavita',
        comuna: 'Soimus'
      },
      {
        nume: 'Boholţ',
        simplu: 'Boholt',
        comuna: 'Soimus'
      },
      {
        nume: 'Bălata',
        simplu: 'Balata',
        comuna: 'Soimus'
      },
      {
        nume: 'Chişcădaga',
        simplu: 'Chiscadaga',
        comuna: 'Soimus'
      },
      {
        nume: 'Căinelu de Jos',
        simplu: 'Cainelu de Jos',
        comuna: 'Soimus'
      },
      {
        nume: 'Fornădia',
        simplu: 'Fornadia',
        comuna: 'Soimus'
      },
      {
        nume: 'Păuliş',
        simplu: 'Paulis',
        comuna: 'Soimus'
      },
      {
        nume: 'Sulighete',
        comuna: 'Soimus'
      },
      {
        nume: 'Cinciş-Cerna',
        simplu: 'Cincis-Cerna',
        comuna: 'Teliucu Inferior'
      },
      {
        nume: 'Izvoarele',
        comuna: 'Teliucu Inferior'
      },
      {
        nume: 'Teliucu Superior',
        comuna: 'Teliucu Inferior'
      },
      {
        nume: 'Dobroţ',
        simplu: 'Dobrot',
        comuna: 'Tomesti'
      },
      {
        nume: 'Leauţ',
        simplu: 'Leaut',
        comuna: 'Tomesti'
      },
      {
        nume: 'Livada',
        comuna: 'Tomesti'
      },
      {
        nume: 'Obârşa',
        simplu: 'Obarsa',
        comuna: 'Tomesti'
      },
      {
        nume: 'Steia',
        comuna: 'Tomesti'
      },
      {
        nume: 'Tiuleşti',
        simplu: 'Tiulesti',
        comuna: 'Tomesti'
      },
      {
        nume: 'Valea Mare de Criş',
        simplu: 'Valea Mare de Cris',
        comuna: 'Tomesti'
      },
      {
        nume: 'Curpenii Silvaşului',
        simplu: 'Curpenii Silvasului',
        comuna: 'Toplita'
      },
      {
        nume: 'Dealu Mic',
        comuna: 'Toplita'
      },
      {
        nume: 'Dăbâca',
        simplu: 'Dabaca',
        comuna: 'Toplita'
      },
      {
        nume: 'Goleş',
        simplu: 'Goles',
        comuna: 'Toplita'
      },
      {
        nume: 'Hăşdău',
        simplu: 'Hasdau',
        comuna: 'Toplita'
      },
      {
        nume: 'Mosoru',
        comuna: 'Toplita'
      },
      {
        nume: 'Vălari',
        simplu: 'Valari',
        comuna: 'Toplita'
      },
      {
        nume: 'Copaci',
        comuna: 'Totesti'
      },
      {
        nume: 'Cârneşti',
        simplu: 'Carnesti',
        comuna: 'Totesti'
      },
      {
        nume: 'Păclişa',
        simplu: 'Paclisa',
        comuna: 'Totesti'
      },
      {
        nume: 'Reea',
        comuna: 'Totesti'
      },
      {
        nume: 'Pricaz',
        comuna: 'Turdas'
      },
      {
        nume: 'Râpaş',
        simplu: 'Rapas',
        comuna: 'Turdas'
      },
      {
        nume: 'Spini',
        comuna: 'Turdas'
      },
      {
        nume: 'Câmpu lui Neag',
        simplu: 'Campu lui Neag',
        comuna: 'Uricani'
      },
      {
        nume: 'Valea de Brazi',
        comuna: 'Uricani'
      },
      {
        nume: 'Dealu Mare',
        comuna: 'Valisoara'
      },
      {
        nume: 'Stoieneasa',
        comuna: 'Valisoara'
      },
      {
        nume: 'Săliştioara',
        simplu: 'Salistioara',
        comuna: 'Valisoara'
      },
      {
        nume: 'Basarabasa',
        comuna: 'Vata De Jos'
      },
      {
        nume: 'Birtin',
        comuna: 'Vata De Jos'
      },
      {
        nume: 'Brotuna',
        comuna: 'Vata De Jos'
      },
      {
        nume: 'Ciungani',
        comuna: 'Vata De Jos'
      },
      {
        nume: 'Căzăneşti',
        simplu: 'Cazanesti',
        comuna: 'Vata De Jos'
      },
      {
        nume: 'Ociu',
        comuna: 'Vata De Jos'
      },
      {
        nume: 'Ocişor',
        simplu: 'Ocisor',
        comuna: 'Vata De Jos'
      },
      {
        nume: 'Prihodişte',
        simplu: 'Prihodiste',
        comuna: 'Vata De Jos'
      },
      {
        nume: 'Prăvăleni',
        simplu: 'Pravaleni',
        comuna: 'Vata De Jos'
      },
      {
        nume: 'Târnava de Criş',
        simplu: 'Tarnava de Cris',
        comuna: 'Vata De Jos'
      },
      {
        nume: 'Tătărăştii de Criş',
        simplu: 'Tatarastii de Cris',
        comuna: 'Vata De Jos'
      },
      {
        nume: 'Vaţa de Sus',
        simplu: 'Vata de Sus',
        comuna: 'Vata De Jos'
      },
      {
        nume: 'Boia Bârzii',
        simplu: 'Boia Barzii',
        comuna: 'Vetel'
      },
      {
        nume: 'Bretelin',
        comuna: 'Vetel'
      },
      {
        nume: 'Căoi',
        simplu: 'Caoi',
        comuna: 'Vetel'
      },
      {
        nume: 'Herepeia',
        comuna: 'Vetel'
      },
      {
        nume: 'Leşnic',
        simplu: 'Lesnic',
        comuna: 'Vetel'
      },
      {
        nume: 'Mintia',
        comuna: 'Vetel'
      },
      {
        nume: 'Muncelu Mare',
        comuna: 'Vetel'
      },
      {
        nume: 'Muncelu Mic',
        comuna: 'Vetel'
      },
      {
        nume: 'Runcu Mic',
        comuna: 'Vetel'
      },
      {
        nume: 'Certeju de Jos',
        comuna: 'Vorta'
      },
      {
        nume: 'Coaja',
        comuna: 'Vorta'
      },
      {
        nume: 'Dumeşti',
        simplu: 'Dumesti',
        comuna: 'Vorta'
      },
      {
        nume: 'Luncşoara',
        simplu: 'Luncsoara',
        comuna: 'Vorta'
      },
      {
        nume: 'Valea Poienii',
        comuna: 'Vorta'
      },
      {
        nume: 'Visca',
        comuna: 'Vorta'
      },
      {
        nume: 'Dealu Babii',
        comuna: 'Vulcan'
      },
      {
        nume: 'Jiu-Paroşeni',
        simplu: 'Jiu-Paroseni',
        comuna: 'Vulcan'
      },
      {
        nume: 'Almaş-Sălişte',
        simplu: 'Almas-Saliste',
        comuna: 'Zam'
      },
      {
        nume: 'Almăşel',
        simplu: 'Almasel',
        comuna: 'Zam'
      },
      {
        nume: 'Brăşeu',
        simplu: 'Braseu',
        comuna: 'Zam'
      },
      {
        nume: 'Cerbia',
        comuna: 'Zam'
      },
      {
        nume: 'Deleni',
        comuna: 'Zam'
      },
      {
        nume: 'Godineşti',
        simplu: 'Godinesti',
        comuna: 'Zam'
      },
      {
        nume: 'Micăneşti',
        simplu: 'Micanesti',
        comuna: 'Zam'
      },
      {
        nume: 'Pogăneşti',
        simplu: 'Poganesti',
        comuna: 'Zam'
      },
      {
        nume: 'Pojoga',
        comuna: 'Zam'
      },
      {
        nume: 'Sălciva',
        simplu: 'Salciva',
        comuna: 'Zam'
      },
      {
        nume: 'Tămăşeşti',
        simplu: 'Tamasesti',
        comuna: 'Zam'
      },
      {
        nume: 'Valea',
        comuna: 'Zam'
      }
    ]
  },
  {
    auto: 'IL',
    nume: 'Ialomiţa',
    localitati: [
      {
        nume: 'Adâncata',
        simplu: 'Adancata'
      },
      {
        nume: 'Albeşti',
        simplu: 'Albesti'
      },
      {
        nume: 'Alexeni'
      },
      {
        nume: 'Amara'
      },
      {
        nume: 'Andrăşeşti',
        simplu: 'Andrasesti'
      },
      {
        nume: 'Armăşeşti',
        simplu: 'Armasesti'
      },
      {
        nume: 'Axintele'
      },
      {
        nume: 'Balaciu'
      },
      {
        nume: 'Borduşani',
        simplu: 'Bordusani'
      },
      {
        nume: 'Brazii'
      },
      {
        nume: 'Bucu'
      },
      {
        nume: 'Bărcăneşti',
        simplu: 'Barcanesti'
      },
      {
        nume: 'Ciochina'
      },
      {
        nume: 'Ciocârlia',
        simplu: 'Ciocarlia'
      },
      {
        nume: 'Ciulniţa',
        simplu: 'Ciulnita'
      },
      {
        nume: 'Cocora'
      },
      {
        nume: 'Cosâmbeşti',
        simplu: 'Cosambesti'
      },
      {
        nume: 'Coşereni',
        simplu: 'Cosereni'
      },
      {
        nume: 'Căzăneşti',
        simplu: 'Cazanesti'
      },
      {
        nume: 'Dridu'
      },
      {
        nume: 'Drăgoeşti',
        simplu: 'Dragoesti'
      },
      {
        nume: 'Feteşti',
        simplu: 'Fetesti'
      },
      {
        nume: 'Fierbinţi-Târg',
        simplu: 'Fierbinti-Targ'
      },
      {
        nume: 'Făcăeni',
        simplu: 'Facaeni'
      },
      {
        nume: 'Gheorghe Doja'
      },
      {
        nume: 'Gheorghe Lazăr',
        simplu: 'Gheorghe Lazar'
      },
      {
        nume: 'Giurgeni'
      },
      {
        nume: 'Grindu'
      },
      {
        nume: 'Griviţa',
        simplu: 'Grivita'
      },
      {
        nume: 'Gârbovi',
        simplu: 'Garbovi'
      },
      {
        nume: 'Ion Roată',
        simplu: 'Ion Roata'
      },
      {
        nume: 'Jilavele'
      },
      {
        nume: 'Manasia'
      },
      {
        nume: 'Mihail Kogălniceanu',
        simplu: 'Mihail Kogalniceanu'
      },
      {
        nume: 'Miloşeşti',
        simplu: 'Milosesti'
      },
      {
        nume: 'Movila'
      },
      {
        nume: 'Moviliţa',
        simplu: 'Movilita'
      },
      {
        nume: 'Munteni-Buzău',
        simplu: 'Munteni-Buzau'
      },
      {
        nume: 'Perieţi',
        simplu: 'Perieti'
      },
      {
        nume: 'Reviga'
      },
      {
        nume: 'Scânteia',
        simplu: 'Scanteia'
      },
      {
        nume: 'Sfântu Gheorghe',
        simplu: 'Sfantu Gheorghe'
      },
      {
        nume: 'Sineşti',
        simplu: 'Sinesti'
      },
      {
        nume: 'Slobozia'
      },
      {
        nume: 'Stelnica'
      },
      {
        nume: 'Sudiţi',
        simplu: 'Suditi'
      },
      {
        nume: 'Sălcioara',
        simplu: 'Salcioara'
      },
      {
        nume: 'Săveni',
        simplu: 'Saveni'
      },
      {
        nume: 'Traian'
      },
      {
        nume: 'Urziceni'
      },
      {
        nume: 'Valea Ciorii'
      },
      {
        nume: 'Valea Măcrişului',
        simplu: 'Valea Macrisului'
      },
      {
        nume: 'Vlădeni',
        simplu: 'Vladeni'
      },
      {
        nume: 'Tăndărei',
        simplu: 'Tandarei'
      },
      {
        nume: 'Patru Fraţi',
        simplu: 'Patru Frati',
        comuna: 'Adancata'
      },
      {
        nume: 'Bataluri',
        comuna: 'Albesti'
      },
      {
        nume: 'Bueşti',
        simplu: 'Buesti',
        comuna: 'Albesti'
      },
      {
        nume: 'Marsilieni',
        comuna: 'Albesti'
      },
      {
        nume: 'Amara Nouă',
        simplu: 'Amara Noua',
        comuna: 'Amara'
      },
      {
        nume: 'Orboeşti',
        simplu: 'Orboesti',
        comuna: 'Andrasesti'
      },
      {
        nume: 'Bărbuleşti',
        simplu: 'Barbulesti',
        comuna: 'Armasesti'
      },
      {
        nume: 'Malu Roşu',
        simplu: 'Malu Rosu',
        comuna: 'Armasesti'
      },
      {
        nume: 'Nenişori',
        simplu: 'Nenisori',
        comuna: 'Armasesti'
      },
      {
        nume: 'Bărbătescu',
        simplu: 'Barbatescu',
        comuna: 'Axintele'
      },
      {
        nume: 'Horia',
        comuna: 'Axintele'
      },
      {
        nume: 'Copuzu',
        comuna: 'Balaciu'
      },
      {
        nume: 'Crăsanii de Jos',
        simplu: 'Crasanii de Jos',
        comuna: 'Balaciu'
      },
      {
        nume: 'Crăsanii de Sus',
        simplu: 'Crasanii de Sus',
        comuna: 'Balaciu'
      },
      {
        nume: 'Sărăţeni',
        simplu: 'Sarateni',
        comuna: 'Balaciu'
      },
      {
        nume: 'Condeeşti',
        simplu: 'Condeesti',
        comuna: 'Barcanesti'
      },
      {
        nume: 'Cegani',
        comuna: 'Bordusani'
      },
      {
        nume: 'Maia',
        comuna: 'Brazii'
      },
      {
        nume: 'Movileanca',
        comuna: 'Brazii'
      },
      {
        nume: 'Răsimnicea',
        simplu: 'Rasimnicea',
        comuna: 'Brazii'
      },
      {
        nume: 'Ograda',
        comuna: 'Bucu'
      },
      {
        nume: 'Cotorca',
        comuna: 'Ciocarlia'
      },
      {
        nume: 'Borduşelu',
        simplu: 'Borduselu',
        comuna: 'Ciochina'
      },
      {
        nume: 'Orezu',
        comuna: 'Ciochina'
      },
      {
        nume: 'Piersica',
        comuna: 'Ciochina'
      },
      {
        nume: 'Ion Ghica',
        comuna: 'Ciulnita'
      },
      {
        nume: 'Ivăneşti',
        simplu: 'Ivanesti',
        comuna: 'Ciulnita'
      },
      {
        nume: 'Poiana',
        comuna: 'Ciulnita'
      },
      {
        nume: 'Colelia',
        comuna: 'Cocora'
      },
      {
        nume: 'Gimbăşani',
        simplu: 'Gimbasani',
        comuna: 'Cosambesti'
      },
      {
        nume: 'Mărculeşti',
        simplu: 'Marculesti',
        comuna: 'Cosambesti'
      },
      {
        nume: 'Borăneşti',
        simplu: 'Boranesti',
        comuna: 'Cosereni'
      },
      {
        nume: 'Sinteşti',
        simplu: 'Sintesti',
        comuna: 'Cosereni'
      },
      {
        nume: 'Chiroiu-Pamânteni',
        simplu: 'Chiroiu-Pamanteni',
        comuna: 'Dragoesti'
      },
      {
        nume: 'Chiroiu-Satu Nou',
        comuna: 'Dragoesti'
      },
      {
        nume: 'Chiroiu-Ungureni',
        comuna: 'Dragoesti'
      },
      {
        nume: 'Valea Bisericii',
        comuna: 'Dragoesti'
      },
      {
        nume: 'Dridu-Snagov',
        comuna: 'Dridu'
      },
      {
        nume: 'Moldoveni',
        comuna: 'Dridu'
      },
      {
        nume: 'Progresu',
        comuna: 'Facaeni'
      },
      {
        nume: 'Buliga',
        comuna: 'Fetesti'
      },
      {
        nume: 'Feteşti-Gară',
        simplu: 'Fetesti-Gara',
        comuna: 'Fetesti'
      },
      {
        nume: 'Vlaşca',
        simplu: 'Vlasca',
        comuna: 'Fetesti'
      },
      {
        nume: 'Fierbinţii de Jos',
        simplu: 'Fierbintii de Jos',
        comuna: 'Fierbinti-Targ'
      },
      {
        nume: 'Fierbinţii de Sus',
        simplu: 'Fierbintii de Sus',
        comuna: 'Fierbinti-Targ'
      },
      {
        nume: 'Grecii de Jos',
        comuna: 'Fierbinti-Targ'
      },
      {
        nume: 'Smirna',
        comuna: 'Grivita'
      },
      {
        nume: 'Broşteni',
        simplu: 'Brosteni',
        comuna: 'Ion Roata'
      },
      {
        nume: 'Slătioarele',
        simplu: 'Slatioarele',
        comuna: 'Jilavele'
      },
      {
        nume: 'Gura Ialomiţei',
        simplu: 'Gura Ialomitei',
        comuna: 'Mihail Kogalniceanu'
      },
      {
        nume: 'Hagieni',
        comuna: 'Mihail Kogalniceanu'
      },
      {
        nume: 'Luciu',
        comuna: 'Mihail Kogalniceanu'
      },
      {
        nume: 'Nicoleşti',
        simplu: 'Nicolesti',
        comuna: 'Milosesti'
      },
      {
        nume: 'Tovărăşia',
        simplu: 'Tovarasia',
        comuna: 'Milosesti'
      },
      {
        nume: 'Biţina-Pământeni',
        simplu: 'Bitina-Pamanteni',
        comuna: 'Movilita'
      },
      {
        nume: 'Biţina-Ungureni',
        simplu: 'Bitina-Ungureni',
        comuna: 'Movilita'
      },
      {
        nume: 'Roşiori',
        simplu: 'Rosiori',
        comuna: 'Movilita'
      },
      {
        nume: 'Fundata',
        comuna: 'Perieti'
      },
      {
        nume: 'Misleanu',
        comuna: 'Perieti'
      },
      {
        nume: 'Păltinişu',
        simplu: 'Paltinisu',
        comuna: 'Perieti'
      },
      {
        nume: 'Stejaru',
        comuna: 'Perieti'
      },
      {
        nume: 'Crunţi',
        simplu: 'Crunti',
        comuna: 'Reviga'
      },
      {
        nume: 'Mircea cel Bătrân',
        simplu: 'Mircea cel Batran',
        comuna: 'Reviga'
      },
      {
        nume: 'Rovine',
        comuna: 'Reviga'
      },
      {
        nume: 'Raşi',
        simplu: 'Rasi',
        comuna: 'Salcioara'
      },
      {
        nume: 'Frăţileşti',
        simplu: 'Fratilesti',
        comuna: 'Saveni'
      },
      {
        nume: 'Lăcusteni',
        simplu: 'Lacusteni',
        comuna: 'Saveni'
      },
      {
        nume: 'Platoneşti',
        simplu: 'Platonesti',
        comuna: 'Saveni'
      },
      {
        nume: 'Iazu',
        comuna: 'Scanteia'
      },
      {
        nume: 'Butoiu',
        comuna: 'Sfantu Gheorghe'
      },
      {
        nume: 'Malu',
        comuna: 'Sfantu Gheorghe'
      },
      {
        nume: 'Boteni',
        comuna: 'Sinesti'
      },
      {
        nume: 'Cătruneşti',
        simplu: 'Catrunesti',
        comuna: 'Sinesti'
      },
      {
        nume: 'Hagieşti',
        simplu: 'Hagiesti',
        comuna: 'Sinesti'
      },
      {
        nume: 'Lilieci',
        comuna: 'Sinesti'
      },
      {
        nume: 'Livedea',
        comuna: 'Sinesti'
      },
      {
        nume: 'Bora',
        comuna: 'Slobozia'
      },
      {
        nume: 'Slobozia Nouă',
        simplu: 'Slobozia Noua',
        comuna: 'Slobozia'
      },
      {
        nume: 'Maltezi',
        comuna: 'Stelnica'
      },
      {
        nume: 'Retezatu',
        comuna: 'Stelnica'
      },
      {
        nume: 'Gura Văii',
        simplu: 'Gura Vaii',
        comuna: 'Suditi'
      },
      {
        nume: 'Bucşa',
        simplu: 'Bucsa',
        comuna: 'Valea Ciorii'
      },
      {
        nume: 'Dumitreşti',
        simplu: 'Dumitresti',
        comuna: 'Valea Ciorii'
      },
      {
        nume: 'Murgeanca',
        comuna: 'Valea Ciorii'
      },
      {
        nume: 'Grindaşi',
        simplu: 'Grindasi',
        comuna: 'Valea Macrisului'
      }
    ]
  },
  {
    auto: 'IS',
    nume: 'Iaşi',
    localitati: [
      {
        nume: 'Alexandru I. Cuza'
      },
      {
        nume: 'Andrieşeni',
        simplu: 'Andrieseni'
      },
      {
        nume: 'Aroneanu'
      },
      {
        nume: 'Balş',
        simplu: 'Bals'
      },
      {
        nume: 'Belceşti',
        simplu: 'Belcesti'
      },
      {
        nume: 'Bivolari'
      },
      {
        nume: 'Brăeşti',
        simplu: 'Braesti'
      },
      {
        nume: 'Butea'
      },
      {
        nume: 'Bârnova',
        simplu: 'Barnova'
      },
      {
        nume: 'Bălţaţi',
        simplu: 'Baltati'
      },
      {
        nume: 'Cepleniţa',
        simplu: 'Ceplenita'
      },
      {
        nume: 'Ciorteşti',
        simplu: 'Ciortesti'
      },
      {
        nume: 'Ciurea'
      },
      {
        nume: 'Coarnele Caprei'
      },
      {
        nume: 'Comarna'
      },
      {
        nume: 'Costeşti',
        simplu: 'Costesti'
      },
      {
        nume: 'Costuleni'
      },
      {
        nume: 'Cotnari'
      },
      {
        nume: 'Cozmeşti',
        simplu: 'Cozmesti'
      },
      {
        nume: 'Cristeşti',
        simplu: 'Cristesti'
      },
      {
        nume: 'Cucuteni'
      },
      {
        nume: 'Dagâţa',
        simplu: 'Dagata'
      },
      {
        nume: 'Deleni'
      },
      {
        nume: 'Dobrovăţ',
        simplu: 'Dobrovat'
      },
      {
        nume: 'Dolheşti',
        simplu: 'Dolhesti'
      },
      {
        nume: 'Drăguşeni',
        simplu: 'Draguseni'
      },
      {
        nume: 'Dumeşti',
        simplu: 'Dumesti'
      },
      {
        nume: 'Erbiceni'
      },
      {
        nume: 'Focuri'
      },
      {
        nume: 'Golăieşti',
        simplu: 'Golaiesti'
      },
      {
        nume: 'Gorban'
      },
      {
        nume: 'Grajduri'
      },
      {
        nume: 'Gropniţa',
        simplu: 'Gropnita'
      },
      {
        nume: 'Grozeşti',
        simplu: 'Grozesti'
      },
      {
        nume: 'Heleşteni',
        simplu: 'Helesteni'
      },
      {
        nume: 'Holboca'
      },
      {
        nume: 'Horleşti',
        simplu: 'Horlesti'
      },
      {
        nume: 'Hârlău',
        simplu: 'Harlau'
      },
      {
        nume: 'Hălăuceşti',
        simplu: 'Halaucesti'
      },
      {
        nume: 'Iaşi',
        simplu: 'Iasi'
      },
      {
        nume: 'Ipatele'
      },
      {
        nume: 'Lespezi'
      },
      {
        nume: 'Leţcani',
        simplu: 'Letcani'
      },
      {
        nume: 'Lungani'
      },
      {
        nume: 'Mirceşti',
        simplu: 'Mircesti'
      },
      {
        nume: 'Mironeasa'
      },
      {
        nume: 'Miroslava'
      },
      {
        nume: 'Mirosloveşti',
        simplu: 'Miroslovesti'
      },
      {
        nume: 'Mogoşeşti',
        simplu: 'Mogosesti'
      },
      {
        nume: 'Mogoşeşti-Siret',
        simplu: 'Mogosesti-Siret'
      },
      {
        nume: 'Movileni'
      },
      {
        nume: 'Moşna',
        simplu: 'Mosna'
      },
      {
        nume: 'Moţca',
        simplu: 'Motca'
      },
      {
        nume: 'Mădârjac',
        simplu: 'Madarjac'
      },
      {
        nume: 'Oţeleni',
        simplu: 'Oteleni'
      },
      {
        nume: 'Paşcani',
        simplu: 'Pascani'
      },
      {
        nume: 'Plugari'
      },
      {
        nume: 'Podu Iloaiei'
      },
      {
        nume: 'Popeşti',
        simplu: 'Popesti'
      },
      {
        nume: 'Popricani'
      },
      {
        nume: 'Prisăcani',
        simplu: 'Prisacani'
      },
      {
        nume: 'Probota'
      },
      {
        nume: 'Rediu'
      },
      {
        nume: 'Româneşti',
        simplu: 'Romanesti'
      },
      {
        nume: 'Ruginoasa'
      },
      {
        nume: 'Răchiteni',
        simplu: 'Rachiteni'
      },
      {
        nume: 'Răducăneni',
        simplu: 'Raducaneni'
      },
      {
        nume: 'Schitu Duca'
      },
      {
        nume: 'Scobinţi',
        simplu: 'Scobinti'
      },
      {
        nume: 'Scânteia',
        simplu: 'Scanteia'
      },
      {
        nume: 'Sineşti',
        simplu: 'Sinesti'
      },
      {
        nume: 'Sireţel',
        simplu: 'Siretel'
      },
      {
        nume: 'Stolniceni-Prăjescu',
        simplu: 'Stolniceni-Prajescu'
      },
      {
        nume: 'Strunga'
      },
      {
        nume: 'Tansa'
      },
      {
        nume: 'Todireşti',
        simplu: 'Todiresti'
      },
      {
        nume: 'Tomeşti',
        simplu: 'Tomesti'
      },
      {
        nume: 'Trifeşti',
        simplu: 'Trifesti'
      },
      {
        nume: 'Târgu Frumos',
        simplu: 'Targu Frumos'
      },
      {
        nume: 'Tătăruşi',
        simplu: 'Tatarusi'
      },
      {
        nume: 'Ungheni'
      },
      {
        nume: 'Valea Lupului'
      },
      {
        nume: 'Valea Seacă',
        simplu: 'Valea Seaca'
      },
      {
        nume: 'Victoria'
      },
      {
        nume: 'Vlădeni',
        simplu: 'Vladeni'
      },
      {
        nume: 'Voineşti',
        simplu: 'Voinesti'
      },
      {
        nume: 'Vânători',
        simplu: 'Vanatori'
      },
      {
        nume: 'Scheia',
        simplu: 'Scheia'
      },
      {
        nume: 'Sipote',
        simplu: 'Sipote'
      },
      {
        nume: 'Tibana',
        simplu: 'Tibana'
      },
      {
        nume: 'Tibăneşti',
        simplu: 'Tibanesti'
      },
      {
        nume: 'Tigănăşi',
        simplu: 'Tiganasi'
      },
      {
        nume: 'Tuţora',
        simplu: 'Tutora'
      },
      {
        nume: 'Kogălniceni',
        simplu: 'Kogalniceni',
        comuna: 'Alexandru I. Cuza'
      },
      {
        nume: 'Scheia',
        comuna: 'Alexandru I. Cuza'
      },
      {
        nume: 'Volintireşti',
        simplu: 'Volintiresti',
        comuna: 'Alexandru I. Cuza'
      },
      {
        nume: 'Buhăeni',
        simplu: 'Buhaeni',
        comuna: 'Andrieseni'
      },
      {
        nume: 'Drăgăneşti',
        simplu: 'Draganesti',
        comuna: 'Andrieseni'
      },
      {
        nume: 'Fântânele',
        simplu: 'Fantanele',
        comuna: 'Andrieseni'
      },
      {
        nume: 'Glăvăneşti',
        simplu: 'Glavanesti',
        comuna: 'Andrieseni'
      },
      {
        nume: 'Iepureni',
        comuna: 'Andrieseni'
      },
      {
        nume: 'Spineni',
        comuna: 'Andrieseni'
      },
      {
        nume: 'Dorobanţ',
        simplu: 'Dorobant',
        comuna: 'Aroneanu'
      },
      {
        nume: 'Rediu Aldei',
        comuna: 'Aroneanu'
      },
      {
        nume: 'Sorogani',
        simplu: 'Sorogani',
        comuna: 'Aroneanu'
      },
      {
        nume: 'Boureni',
        comuna: 'Bals'
      },
      {
        nume: 'Cotârgaci',
        simplu: 'Cotargaci',
        comuna: 'Baltati'
      },
      {
        nume: 'Filiaşi',
        simplu: 'Filiasi',
        comuna: 'Baltati'
      },
      {
        nume: 'Mădârjeşti',
        simplu: 'Madarjesti',
        comuna: 'Baltati'
      },
      {
        nume: 'Podişu',
        simplu: 'Podisu',
        comuna: 'Baltati'
      },
      {
        nume: 'Sârca',
        simplu: 'Sarca',
        comuna: 'Baltati'
      },
      {
        nume: 'Valea Oilor',
        comuna: 'Baltati'
      },
      {
        nume: 'Cercu',
        comuna: 'Barnova'
      },
      {
        nume: 'Pietrăria',
        simplu: 'Pietraria',
        comuna: 'Barnova'
      },
      {
        nume: 'Păun',
        simplu: 'Paun',
        comuna: 'Barnova'
      },
      {
        nume: 'Todirel',
        comuna: 'Barnova'
      },
      {
        nume: 'Vişan',
        simplu: 'Visan',
        comuna: 'Barnova'
      },
      {
        nume: 'Liteni',
        comuna: 'Belcesti'
      },
      {
        nume: 'Munteni',
        comuna: 'Belcesti'
      },
      {
        nume: 'Satu Nou',
        comuna: 'Belcesti'
      },
      {
        nume: 'Tansa',
        comuna: 'Belcesti'
      },
      {
        nume: 'Ulmi',
        comuna: 'Belcesti'
      },
      {
        nume: 'Buruieneşti',
        simplu: 'Buruienesti',
        comuna: 'Bivolari'
      },
      {
        nume: 'Soloneţ',
        simplu: 'Solonet',
        comuna: 'Bivolari'
      },
      {
        nume: 'Tabăra',
        simplu: 'Tabara',
        comuna: 'Bivolari'
      },
      {
        nume: 'Traian',
        comuna: 'Bivolari'
      },
      {
        nume: 'Albeşti',
        simplu: 'Albesti',
        comuna: 'Braesti'
      },
      {
        nume: 'Buda',
        comuna: 'Braesti'
      },
      {
        nume: 'Cristeşti',
        simplu: 'Cristesti',
        comuna: 'Braesti'
      },
      {
        nume: 'Rediu',
        comuna: 'Braesti'
      },
      {
        nume: 'Miclăuşeni',
        simplu: 'Miclauseni',
        comuna: 'Butea'
      },
      {
        nume: 'Buhalniţa',
        simplu: 'Buhalnita',
        comuna: 'Ceplenita'
      },
      {
        nume: 'Poiana Mărului',
        simplu: 'Poiana Marului',
        comuna: 'Ceplenita'
      },
      {
        nume: 'Zlodica',
        comuna: 'Ceplenita'
      },
      {
        nume: 'Coropceni',
        comuna: 'Ciortesti'
      },
      {
        nume: 'Deleni',
        comuna: 'Ciortesti'
      },
      {
        nume: 'Rotăria',
        simplu: 'Rotaria',
        comuna: 'Ciortesti'
      },
      {
        nume: 'Serbeşti',
        simplu: 'Serbesti',
        comuna: 'Ciortesti'
      },
      {
        nume: 'Curături',
        simplu: 'Curaturi',
        comuna: 'Ciurea'
      },
      {
        nume: 'Dumbrava',
        comuna: 'Ciurea'
      },
      {
        nume: 'Hlincea',
        comuna: 'Ciurea'
      },
      {
        nume: 'Lunca Cetăţuii',
        simplu: 'Lunca Cetatuii',
        comuna: 'Ciurea'
      },
      {
        nume: 'Picioru Lupului',
        comuna: 'Ciurea'
      },
      {
        nume: 'Slobozia',
        comuna: 'Ciurea'
      },
      {
        nume: 'Arama',
        comuna: 'Coarnele Caprei'
      },
      {
        nume: 'Petroşica',
        simplu: 'Petrosica',
        comuna: 'Coarnele Caprei'
      },
      {
        nume: 'Curagău',
        simplu: 'Curagau',
        comuna: 'Comarna'
      },
      {
        nume: 'Osoi',
        comuna: 'Comarna'
      },
      {
        nume: 'Stânca',
        simplu: 'Stanca',
        comuna: 'Comarna'
      },
      {
        nume: 'Covasna',
        comuna: 'Costuleni'
      },
      {
        nume: 'Cozia',
        comuna: 'Costuleni'
      },
      {
        nume: 'Hiliţa',
        simplu: 'Hilita',
        comuna: 'Costuleni'
      },
      {
        nume: 'Bahluiu',
        comuna: 'Cotnari'
      },
      {
        nume: 'Cireşeni',
        simplu: 'Cireseni',
        comuna: 'Cotnari'
      },
      {
        nume: 'Cârjoaia',
        simplu: 'Carjoaia',
        comuna: 'Cotnari'
      },
      {
        nume: 'Făgăt',
        simplu: 'Fagat',
        comuna: 'Cotnari'
      },
      {
        nume: 'Hodora',
        comuna: 'Cotnari'
      },
      {
        nume: 'Horodiştea',
        simplu: 'Horodistea',
        comuna: 'Cotnari'
      },
      {
        nume: 'Iosupeni',
        comuna: 'Cotnari'
      },
      {
        nume: 'Lupăria',
        simplu: 'Luparia',
        comuna: 'Cotnari'
      },
      {
        nume: 'Valea Racului',
        comuna: 'Cotnari'
      },
      {
        nume: 'Zbereni',
        comuna: 'Cotnari'
      },
      {
        nume: 'Podolenii de Jos',
        comuna: 'Cozmesti'
      },
      {
        nume: 'Podolenii de Sus',
        comuna: 'Cozmesti'
      },
      {
        nume: 'Homiţa',
        simplu: 'Homita',
        comuna: 'Cristesti'
      },
      {
        nume: 'Băiceni',
        simplu: 'Baiceni',
        comuna: 'Cucuteni'
      },
      {
        nume: 'Bărbăteşti',
        simplu: 'Barbatesti',
        comuna: 'Cucuteni'
      },
      {
        nume: 'Săcăreşti',
        simplu: 'Sacaresti',
        comuna: 'Cucuteni'
      },
      {
        nume: 'Boatca',
        comuna: 'Dagata'
      },
      {
        nume: 'Buzdug',
        comuna: 'Dagata'
      },
      {
        nume: 'Băluşeşti',
        simplu: 'Balusesti',
        comuna: 'Dagata'
      },
      {
        nume: 'Mănăstirea',
        simplu: 'Manastirea',
        comuna: 'Dagata'
      },
      {
        nume: 'Piscu Rusului',
        comuna: 'Dagata'
      },
      {
        nume: 'Poienile',
        comuna: 'Dagata'
      },
      {
        nume: 'Tarniţa',
        simplu: 'Tarnita',
        comuna: 'Dagata'
      },
      {
        nume: 'Zece Prăjini',
        simplu: 'Zece Prajini',
        comuna: 'Dagata'
      },
      {
        nume: 'Feredeni',
        comuna: 'Deleni'
      },
      {
        nume: 'Leahu-Nacu',
        comuna: 'Deleni'
      },
      {
        nume: 'Maxut',
        comuna: 'Deleni'
      },
      {
        nume: 'Poiana',
        comuna: 'Deleni'
      },
      {
        nume: 'Slobozia',
        comuna: 'Deleni'
      },
      {
        nume: 'Brădiceşti',
        simplu: 'Bradicesti',
        comuna: 'Dolhesti'
      },
      {
        nume: 'Pietriş',
        simplu: 'Pietris',
        comuna: 'Dolhesti'
      },
      {
        nume: 'Banu',
        comuna: 'Dumesti'
      },
      {
        nume: 'Chilişoaia',
        simplu: 'Chilisoaia',
        comuna: 'Dumesti'
      },
      {
        nume: 'Hoiseşti',
        simplu: 'Hoisesti',
        comuna: 'Dumesti'
      },
      {
        nume: 'Păuşeşti',
        simplu: 'Pausesti',
        comuna: 'Dumesti'
      },
      {
        nume: 'Bârleşti',
        simplu: 'Barlesti',
        comuna: 'Erbiceni'
      },
      {
        nume: 'Spinoasa',
        comuna: 'Erbiceni'
      },
      {
        nume: 'Sprânceana',
        simplu: 'Spranceana',
        comuna: 'Erbiceni'
      },
      {
        nume: 'Totoeşti',
        simplu: 'Totoesti',
        comuna: 'Erbiceni'
      },
      {
        nume: 'Fântânele',
        simplu: 'Fantanele',
        comuna: 'Fintinele'
      },
      {
        nume: 'Bran',
        comuna: 'Golaiesti'
      },
      {
        nume: 'Cilibiu',
        comuna: 'Golaiesti'
      },
      {
        nume: 'Cotu lui Ivan',
        comuna: 'Golaiesti'
      },
      {
        nume: 'Grădinari',
        simplu: 'Gradinari',
        comuna: 'Golaiesti'
      },
      {
        nume: 'Medeleni',
        comuna: 'Golaiesti'
      },
      {
        nume: 'Petreşti',
        simplu: 'Petresti',
        comuna: 'Golaiesti'
      },
      {
        nume: 'Podu Jijiei',
        comuna: 'Golaiesti'
      },
      {
        nume: 'Gura Bohotin',
        comuna: 'Gorban'
      },
      {
        nume: 'Podu Hagiului',
        comuna: 'Gorban'
      },
      {
        nume: 'Scoposeni',
        comuna: 'Gorban'
      },
      {
        nume: 'Zberoaia',
        comuna: 'Gorban'
      },
      {
        nume: 'Corcodel',
        comuna: 'Grajduri'
      },
      {
        nume: 'Cărbunari',
        simplu: 'Carbunari',
        comuna: 'Grajduri'
      },
      {
        nume: 'Lunca',
        comuna: 'Grajduri'
      },
      {
        nume: 'Poiana cu Cetate',
        comuna: 'Grajduri'
      },
      {
        nume: 'Pădureni',
        simplu: 'Padureni',
        comuna: 'Grajduri'
      },
      {
        nume: 'Valea Satului',
        comuna: 'Grajduri'
      },
      {
        nume: 'Bulbucani',
        comuna: 'Gropnita'
      },
      {
        nume: 'Forăşti',
        simplu: 'Forasti',
        comuna: 'Gropnita'
      },
      {
        nume: 'Mălăeşti',
        simplu: 'Malaesti',
        comuna: 'Gropnita'
      },
      {
        nume: 'Sângeri',
        simplu: 'Sangeri',
        comuna: 'Gropnita'
      },
      {
        nume: 'Săveni',
        simplu: 'Saveni',
        comuna: 'Gropnita'
      },
      {
        nume: 'Colţu Cornii',
        simplu: 'Coltu Cornii',
        comuna: 'Grozesti'
      },
      {
        nume: 'Sălăgeni',
        simplu: 'Salageni',
        comuna: 'Grozesti'
      },
      {
        nume: 'Luncaşi',
        simplu: 'Luncasi',
        comuna: 'Halaucesti'
      },
      {
        nume: 'Pârcovaci',
        simplu: 'Parcovaci',
        comuna: 'Harlau'
      },
      {
        nume: 'Hărmăneasa',
        simplu: 'Harmaneasa',
        comuna: 'Helesteni'
      },
      {
        nume: 'Movileni',
        comuna: 'Helesteni'
      },
      {
        nume: 'Oboroceni',
        comuna: 'Helesteni'
      },
      {
        nume: 'Cristeşti',
        simplu: 'Cristesti',
        comuna: 'Holboca'
      },
      {
        nume: 'Dancu',
        comuna: 'Holboca'
      },
      {
        nume: 'Orzeni',
        comuna: 'Holboca'
      },
      {
        nume: 'Rusenii Noi',
        comuna: 'Holboca'
      },
      {
        nume: 'Rusenii Vechi',
        comuna: 'Holboca'
      },
      {
        nume: 'Valea Lungă',
        simplu: 'Valea Lunga',
        comuna: 'Holboca'
      },
      {
        nume: 'Bogdăneşti',
        simplu: 'Bogdanesti',
        comuna: 'Horlesti'
      },
      {
        nume: 'Scoposeni',
        comuna: 'Horlesti'
      },
      {
        nume: 'Buznea',
        comuna: 'Ion Neculce'
      },
      {
        nume: 'Prigoreni',
        comuna: 'Ion Neculce'
      },
      {
        nume: 'Războieni',
        simplu: 'Razboieni',
        comuna: 'Ion Neculce'
      },
      {
        nume: 'Alexeşti',
        simplu: 'Alexesti',
        comuna: 'Ipatele'
      },
      {
        nume: 'Bâcu',
        simplu: 'Bacu',
        comuna: 'Ipatele'
      },
      {
        nume: 'Cuza Vodă',
        simplu: 'Cuza Voda',
        comuna: 'Ipatele'
      },
      {
        nume: 'Buda',
        comuna: 'Lespezi'
      },
      {
        nume: 'Bursuc-Deal',
        comuna: 'Lespezi'
      },
      {
        nume: 'Bursuc-Vale',
        comuna: 'Lespezi'
      },
      {
        nume: 'Dumbrava',
        comuna: 'Lespezi'
      },
      {
        nume: 'Heci',
        comuna: 'Lespezi'
      },
      {
        nume: 'Bogonos',
        comuna: 'Letcani'
      },
      {
        nume: 'Cogeasca',
        comuna: 'Letcani'
      },
      {
        nume: 'Cucuteni',
        comuna: 'Letcani'
      },
      {
        nume: 'Crucea',
        comuna: 'Lungani'
      },
      {
        nume: 'Goeşti',
        simplu: 'Goesti',
        comuna: 'Lungani'
      },
      {
        nume: 'Zmeu',
        comuna: 'Lungani'
      },
      {
        nume: 'Bojila',
        comuna: 'Madarjac'
      },
      {
        nume: 'Frumuşica',
        simplu: 'Frumusica',
        comuna: 'Madarjac'
      },
      {
        nume: 'Iugani',
        comuna: 'Mircesti'
      },
      {
        nume: 'Ursăreşti',
        simplu: 'Ursaresti',
        comuna: 'Mircesti'
      },
      {
        nume: 'Schitu Hadâmbului',
        simplu: 'Schitu Hadambului',
        comuna: 'Mironeasa'
      },
      {
        nume: 'Urşiţa',
        simplu: 'Ursita',
        comuna: 'Mironeasa'
      },
      {
        nume: 'Balciu',
        comuna: 'Miroslava'
      },
      {
        nume: 'Brătuleni',
        simplu: 'Bratuleni',
        comuna: 'Miroslava'
      },
      {
        nume: 'Ciurbeşti',
        simplu: 'Ciurbesti',
        comuna: 'Miroslava'
      },
      {
        nume: 'Corneşti',
        simplu: 'Cornesti',
        comuna: 'Miroslava'
      },
      {
        nume: 'Dancaş',
        simplu: 'Dancas',
        comuna: 'Miroslava'
      },
      {
        nume: 'Găureni',
        simplu: 'Gaureni',
        comuna: 'Miroslava'
      },
      {
        nume: 'Horpaz',
        comuna: 'Miroslava'
      },
      {
        nume: 'Proselnici',
        comuna: 'Miroslava'
      },
      {
        nume: 'Uricani',
        comuna: 'Miroslava'
      },
      {
        nume: 'Valea Adâncă',
        simplu: 'Valea Adanca',
        comuna: 'Miroslava'
      },
      {
        nume: 'Valea Ursului',
        comuna: 'Miroslava'
      },
      {
        nume: 'Voroveşti',
        simplu: 'Vorovesti',
        comuna: 'Miroslava'
      },
      {
        nume: 'Ciohorăni',
        simplu: 'Ciohorani',
        comuna: 'Miroslovesti'
      },
      {
        nume: 'Miteşti',
        simplu: 'Mitesti',
        comuna: 'Miroslovesti'
      },
      {
        nume: 'Soci',
        comuna: 'Miroslovesti'
      },
      {
        nume: 'Verşeni',
        simplu: 'Verseni',
        comuna: 'Miroslovesti'
      },
      {
        nume: 'Budeşti',
        simplu: 'Budesti',
        comuna: 'Mogosesti'
      },
      {
        nume: 'Hadâmbu',
        simplu: 'Hadambu',
        comuna: 'Mogosesti'
      },
      {
        nume: 'Mânjeşti',
        simplu: 'Manjesti',
        comuna: 'Mogosesti'
      },
      {
        nume: 'Muncelu de Sus',
        comuna: 'Mogosesti-Siret'
      },
      {
        nume: 'Tudor Vladimirescu',
        comuna: 'Mogosesti-Siret'
      },
      {
        nume: 'Boureni',
        comuna: 'Motca'
      },
      {
        nume: 'Iepureni',
        comuna: 'Movileni'
      },
      {
        nume: 'Larga-Jijia',
        comuna: 'Movileni'
      },
      {
        nume: 'Potângeni',
        simplu: 'Potangeni',
        comuna: 'Movileni'
      },
      {
        nume: 'Hândreşti',
        simplu: 'Handresti',
        comuna: 'Oteleni'
      },
      {
        nume: 'Blăgeşti',
        simplu: 'Blagesti',
        comuna: 'Pascani'
      },
      {
        nume: 'Boşteni',
        simplu: 'Bosteni',
        comuna: 'Pascani'
      },
      {
        nume: 'Gâşteşti',
        simplu: 'Gastesti',
        comuna: 'Pascani'
      },
      {
        nume: 'Lunca',
        comuna: 'Pascani'
      },
      {
        nume: 'Sodomeni',
        comuna: 'Pascani'
      },
      {
        nume: 'Borosoaia',
        comuna: 'Plugari'
      },
      {
        nume: 'Oneşti',
        simplu: 'Onesti',
        comuna: 'Plugari'
      },
      {
        nume: 'Budăi',
        simplu: 'Budai',
        comuna: 'Podu Iloaiei'
      },
      {
        nume: 'Cosiţeni',
        simplu: 'Cositeni',
        comuna: 'Podu Iloaiei'
      },
      {
        nume: 'Holm',
        comuna: 'Podu Iloaiei'
      },
      {
        nume: 'Scobâlţeni',
        simplu: 'Scobalteni',
        comuna: 'Podu Iloaiei'
      },
      {
        nume: 'Doroşcani',
        simplu: 'Doroscani',
        comuna: 'Popesti'
      },
      {
        nume: 'Hărpăşeşti',
        simplu: 'Harpasesti',
        comuna: 'Popesti'
      },
      {
        nume: 'Obrijeni',
        comuna: 'Popesti'
      },
      {
        nume: 'Pădureni',
        simplu: 'Padureni',
        comuna: 'Popesti'
      },
      {
        nume: 'Vama',
        comuna: 'Popesti'
      },
      {
        nume: 'Cotu Morii',
        comuna: 'Popricani'
      },
      {
        nume: 'Cuza Vodă',
        simplu: 'Cuza Voda',
        comuna: 'Popricani'
      },
      {
        nume: 'Cârlig',
        simplu: 'Carlig',
        comuna: 'Popricani'
      },
      {
        nume: 'Moimeşti',
        simplu: 'Moimesti',
        comuna: 'Popricani'
      },
      {
        nume: 'Rediu Mitropoliei',
        comuna: 'Popricani'
      },
      {
        nume: 'Vulturi',
        comuna: 'Popricani'
      },
      {
        nume: 'Vânători',
        simplu: 'Vanatori',
        comuna: 'Popricani'
      },
      {
        nume: 'Tipileşti',
        simplu: 'Tipilesti',
        comuna: 'Popricani'
      },
      {
        nume: 'Moreni',
        comuna: 'Prisacani'
      },
      {
        nume: 'Măcăreşti',
        simplu: 'Macaresti',
        comuna: 'Prisacani'
      },
      {
        nume: 'Bălteni',
        simplu: 'Balteni',
        comuna: 'Probota'
      },
      {
        nume: 'Perieni',
        comuna: 'Probota'
      },
      {
        nume: 'Izvoarele',
        comuna: 'Rachiteni'
      },
      {
        nume: 'Bohotin',
        comuna: 'Raducaneni'
      },
      {
        nume: 'Isaiia',
        comuna: 'Raducaneni'
      },
      {
        nume: 'Roşu',
        simplu: 'Rosu',
        comuna: 'Raducaneni'
      },
      {
        nume: 'Breazu',
        comuna: 'Rediu'
      },
      {
        nume: 'Horleşti',
        simplu: 'Horlesti',
        comuna: 'Rediu'
      },
      {
        nume: 'Tăuteşti',
        simplu: 'Tautesti',
        comuna: 'Rediu'
      },
      {
        nume: 'Avântu',
        simplu: 'Avantu',
        comuna: 'Romanesti'
      },
      {
        nume: 'Ursoaia',
        comuna: 'Romanesti'
      },
      {
        nume: 'Rădeni',
        simplu: 'Radeni',
        comuna: 'Roscani'
      },
      {
        nume: 'Dumbrăviţa',
        simplu: 'Dumbravita',
        comuna: 'Ruginoasa'
      },
      {
        nume: 'Rediu',
        comuna: 'Ruginoasa'
      },
      {
        nume: 'Vascani',
        comuna: 'Ruginoasa'
      },
      {
        nume: 'Bodeşti',
        simplu: 'Bodesti',
        comuna: 'Scanteia'
      },
      {
        nume: 'Boroşeşti',
        simplu: 'Borosesti',
        comuna: 'Scanteia'
      },
      {
        nume: 'Ciocârleşti',
        simplu: 'Ciocarlesti',
        comuna: 'Scanteia'
      },
      {
        nume: 'Lunca Rateş',
        simplu: 'Lunca Rates',
        comuna: 'Scanteia'
      },
      {
        nume: 'Rediu',
        comuna: 'Scanteia'
      },
      {
        nume: 'Tufeştii de Sus',
        simplu: 'Tufestii de Sus',
        comuna: 'Scanteia'
      },
      {
        nume: 'Cioca-Boca',
        comuna: 'Scheia'
      },
      {
        nume: 'Căueşti',
        simplu: 'Cauesti',
        comuna: 'Scheia'
      },
      {
        nume: 'Frenciugi',
        comuna: 'Scheia'
      },
      {
        nume: 'Poiana Scheii',
        simplu: 'Poiana Scheii',
        comuna: 'Scheia'
      },
      {
        nume: 'Satu Nou',
        comuna: 'Scheia'
      },
      {
        nume: 'Blaga',
        comuna: 'Schitu Duca'
      },
      {
        nume: 'Dumitreştii Gălăţii',
        simplu: 'Dumitrestii Galatii',
        comuna: 'Schitu Duca'
      },
      {
        nume: 'Pocreaca',
        comuna: 'Schitu Duca'
      },
      {
        nume: 'Poiana',
        comuna: 'Schitu Duca'
      },
      {
        nume: 'Poieni',
        comuna: 'Schitu Duca'
      },
      {
        nume: 'Satu Nou',
        comuna: 'Schitu Duca'
      },
      {
        nume: 'Slobozia',
        comuna: 'Schitu Duca'
      },
      {
        nume: 'Bădeni',
        simplu: 'Badeni',
        comuna: 'Scobinti'
      },
      {
        nume: 'Feteşti',
        simplu: 'Fetesti',
        comuna: 'Scobinti'
      },
      {
        nume: 'Sticlăria',
        simplu: 'Sticlaria',
        comuna: 'Scobinti'
      },
      {
        nume: 'Zagavia',
        comuna: 'Scobinti'
      },
      {
        nume: 'Bocniţa',
        simplu: 'Bocnita',
        comuna: 'Sinesti'
      },
      {
        nume: 'Osoi',
        comuna: 'Sinesti'
      },
      {
        nume: 'Storneşti',
        simplu: 'Stornesti',
        comuna: 'Sinesti'
      },
      {
        nume: 'Chişcăreni',
        simplu: 'Chiscareni',
        comuna: 'Sipote'
      },
      {
        nume: 'Hălceni',
        simplu: 'Halceni',
        comuna: 'Sipote'
      },
      {
        nume: 'Iazu Nou',
        comuna: 'Sipote'
      },
      {
        nume: 'Iazu Vechi',
        comuna: 'Sipote'
      },
      {
        nume: 'Mitoc',
        comuna: 'Sipote'
      },
      {
        nume: 'Berezlogi',
        comuna: 'Siretel'
      },
      {
        nume: 'Humosu',
        comuna: 'Siretel'
      },
      {
        nume: 'Satu Nou',
        comuna: 'Siretel'
      },
      {
        nume: 'Slobozia',
        comuna: 'Siretel'
      },
      {
        nume: 'Brăteşti',
        simplu: 'Bratesti',
        comuna: 'Stolniceni-Prajescu'
      },
      {
        nume: 'Cozmeşti',
        simplu: 'Cozmesti',
        comuna: 'Stolniceni-Prajescu'
      },
      {
        nume: 'Brătuleşti',
        simplu: 'Bratulesti',
        comuna: 'Strunga'
      },
      {
        nume: 'Criveşti',
        simplu: 'Crivesti',
        comuna: 'Strunga'
      },
      {
        nume: 'Cucova',
        comuna: 'Strunga'
      },
      {
        nume: 'Fedeleşeni',
        simplu: 'Fedeleseni',
        comuna: 'Strunga'
      },
      {
        nume: 'Fărcăşeni',
        simplu: 'Farcaseni',
        comuna: 'Strunga'
      },
      {
        nume: 'Gura Văii',
        simplu: 'Gura Vaii',
        comuna: 'Strunga'
      },
      {
        nume: 'Hăbăşeşti',
        simplu: 'Habasesti',
        comuna: 'Strunga'
      },
      {
        nume: 'Suhuleţ',
        simplu: 'Suhulet',
        comuna: 'Tansa'
      },
      {
        nume: 'Coasta Măgurii',
        simplu: 'Coasta Magurii',
        comuna: 'Targu Frumos'
      },
      {
        nume: 'Dădeşti',
        simplu: 'Dadesti',
        comuna: 'Targu Frumos'
      },
      {
        nume: 'Giurgeşti',
        simplu: 'Giurgesti',
        comuna: 'Targu Frumos'
      },
      {
        nume: 'Găneşti',
        simplu: 'Ganesti',
        comuna: 'Targu Frumos'
      },
      {
        nume: 'Ion Neculce',
        comuna: 'Targu Frumos'
      },
      {
        nume: 'Iorcani',
        comuna: 'Tatarusi'
      },
      {
        nume: 'Pietrosu',
        comuna: 'Tatarusi'
      },
      {
        nume: 'Uda',
        comuna: 'Tatarusi'
      },
      {
        nume: 'Vâlcica',
        simplu: 'Valcica',
        comuna: 'Tatarusi'
      },
      {
        nume: 'Alexeni',
        comuna: 'Tibana'
      },
      {
        nume: 'Domniţa',
        simplu: 'Domnita',
        comuna: 'Tibana'
      },
      {
        nume: 'Gârbeşti',
        simplu: 'Garbesti',
        comuna: 'Tibana'
      },
      {
        nume: 'Moara Ciornei',
        comuna: 'Tibana'
      },
      {
        nume: 'Oproaia',
        comuna: 'Tibana'
      },
      {
        nume: 'Poiana Mănăstirii',
        simplu: 'Poiana Manastirii',
        comuna: 'Tibana'
      },
      {
        nume: 'Poiana de Sus',
        comuna: 'Tibana'
      },
      {
        nume: 'Runcu',
        comuna: 'Tibana'
      },
      {
        nume: 'Vadu Vejei',
        comuna: 'Tibana'
      },
      {
        nume: 'Glodenii Gândului',
        simplu: 'Glodenii Gandului',
        comuna: 'Tibanesti'
      },
      {
        nume: 'Grieşti',
        simplu: 'Griesti',
        comuna: 'Tibanesti'
      },
      {
        nume: 'Jigoreni',
        comuna: 'Tibanesti'
      },
      {
        nume: 'Recea',
        comuna: 'Tibanesti'
      },
      {
        nume: 'Răsboieni',
        simplu: 'Rasboieni',
        comuna: 'Tibanesti'
      },
      {
        nume: 'Tungujei',
        comuna: 'Tibanesti'
      },
      {
        nume: 'Vălenii',
        simplu: 'Valenii',
        comuna: 'Tibanesti'
      },
      {
        nume: 'Cârniceni',
        simplu: 'Carniceni',
        comuna: 'Tiganasi'
      },
      {
        nume: 'Mihail Kogălniceanu',
        simplu: 'Mihail Kogalniceanu',
        comuna: 'Tiganasi'
      },
      {
        nume: 'Stejarii',
        comuna: 'Tiganasi'
      },
      {
        nume: 'Boldeşti',
        simplu: 'Boldesti',
        comuna: 'Todiresti'
      },
      {
        nume: 'Băiceni',
        simplu: 'Baiceni',
        comuna: 'Todiresti'
      },
      {
        nume: 'Hărmăneştii Noi',
        simplu: 'Harmanestii Noi',
        comuna: 'Todiresti'
      },
      {
        nume: 'Hărmăneştii Vechi',
        simplu: 'Harmanestii Vechi',
        comuna: 'Todiresti'
      },
      {
        nume: 'Stroeşti',
        simplu: 'Stroesti',
        comuna: 'Todiresti'
      },
      {
        nume: 'Chicerea',
        comuna: 'Tomesti'
      },
      {
        nume: 'Goruni',
        comuna: 'Tomesti'
      },
      {
        nume: 'Vlădiceni',
        simplu: 'Vladiceni',
        comuna: 'Tomesti'
      },
      {
        nume: 'Hermeziu',
        comuna: 'Trifesti'
      },
      {
        nume: 'Roşcani',
        simplu: 'Roscani',
        comuna: 'Trifesti'
      },
      {
        nume: 'Vladomira',
        comuna: 'Trifesti'
      },
      {
        nume: 'Zaboloteni',
        comuna: 'Trifesti'
      },
      {
        nume: 'Chipereşti',
        simplu: 'Chiperesti',
        comuna: 'Tutora'
      },
      {
        nume: 'Oprişeni',
        simplu: 'Opriseni',
        comuna: 'Tutora'
      },
      {
        nume: 'Bosia',
        comuna: 'Ungheni'
      },
      {
        nume: 'Coada Stâncii',
        simplu: 'Coada Stancii',
        comuna: 'Ungheni'
      },
      {
        nume: 'Mânzăteşti',
        simplu: 'Manzatesti',
        comuna: 'Ungheni'
      },
      {
        nume: 'Conţeşti',
        simplu: 'Contesti',
        comuna: 'Valea Seaca'
      },
      {
        nume: 'Topile',
        comuna: 'Valea Seaca'
      },
      {
        nume: 'Criveşti',
        simplu: 'Crivesti',
        comuna: 'Vanatori'
      },
      {
        nume: 'Gura Bâdiliţei',
        simplu: 'Gura Badilitei',
        comuna: 'Vanatori'
      },
      {
        nume: 'Hârtoape',
        simplu: 'Hartoape',
        comuna: 'Vanatori'
      },
      {
        nume: 'Vlădnicuţ',
        simplu: 'Vladnicut',
        comuna: 'Vanatori'
      },
      {
        nume: 'Frăsuleni',
        simplu: 'Frasuleni',
        comuna: 'Victoria'
      },
      {
        nume: 'Icuşeni',
        simplu: 'Icuseni',
        comuna: 'Victoria'
      },
      {
        nume: 'Luceni',
        comuna: 'Victoria'
      },
      {
        nume: 'Sculeni',
        comuna: 'Victoria'
      },
      {
        nume: 'Stânca',
        simplu: 'Stanca',
        comuna: 'Victoria'
      },
      {
        nume: 'Sendreni',
        simplu: 'Sendreni',
        comuna: 'Victoria'
      },
      {
        nume: 'Alexandru cel Bun',
        comuna: 'Vladeni'
      },
      {
        nume: 'Borşa',
        simplu: 'Borsa',
        comuna: 'Vladeni'
      },
      {
        nume: 'Broşteni',
        simplu: 'Brosteni',
        comuna: 'Vladeni'
      },
      {
        nume: 'Iacobeni',
        comuna: 'Vladeni'
      },
      {
        nume: 'Vâlcelele',
        simplu: 'Valcelele',
        comuna: 'Vladeni'
      },
      {
        nume: 'Lungani',
        comuna: 'Voinesti'
      },
      {
        nume: 'Schitu Stavnic',
        comuna: 'Voinesti'
      },
      {
        nume: 'Slobozia',
        comuna: 'Voinesti'
      },
      {
        nume: 'Vocoteşti',
        simplu: 'Vocotesti',
        comuna: 'Voinesti'
      }
    ]
  },
  {
    auto: 'IF',
    nume: 'Ilfov',
    localitati: [
      {
        nume: '1 Decembrie'
      },
      {
        nume: 'Afumaţi',
        simplu: 'Afumati'
      },
      {
        nume: 'Baloteşti',
        simplu: 'Balotesti'
      },
      {
        nume: 'Berceni'
      },
      {
        nume: 'Bragadiru'
      },
      {
        nume: 'Brăneşti',
        simplu: 'Branesti'
      },
      {
        nume: 'Buftea'
      },
      {
        nume: 'Cernica'
      },
      {
        nume: 'Chiajna'
      },
      {
        nume: 'Chitila'
      },
      {
        nume: 'Ciolpani'
      },
      {
        nume: 'Ciorogârla',
        simplu: 'Ciorogarla'
      },
      {
        nume: 'Clinceni'
      },
      {
        nume: 'Corbeanca'
      },
      {
        nume: 'Cornetu'
      },
      {
        nume: 'Dascălu',
        simplu: 'Dascalu'
      },
      {
        nume: 'Domneşti',
        simplu: 'Domnesti'
      },
      {
        nume: 'Dragomireşti-Vale',
        simplu: 'Dragomiresti-Vale'
      },
      {
        nume: 'Dărăşti-Ilfov',
        simplu: 'Darasti-Ilfov'
      },
      {
        nume: 'Glina'
      },
      {
        nume: 'Gruiu'
      },
      {
        nume: 'Grădiştea',
        simplu: 'Gradistea'
      },
      {
        nume: 'Găneasa',
        simplu: 'Ganeasa'
      },
      {
        nume: 'Jilava'
      },
      {
        nume: 'Moara Vlăsiei',
        simplu: 'Moara Vlasiei'
      },
      {
        nume: 'Mogoşoaia',
        simplu: 'Mogosoaia'
      },
      {
        nume: 'Măgurele',
        simplu: 'Magurele'
      },
      {
        nume: 'Nuci'
      },
      {
        nume: 'Otopeni'
      },
      {
        nume: 'Pantelimon'
      },
      {
        nume: 'Periş',
        simplu: 'Peris'
      },
      {
        nume: 'Petrăchioaia',
        simplu: 'Petrachioaia'
      },
      {
        nume: 'Popeşti Leordeni',
        simplu: 'Popesti Leordeni'
      },
      {
        nume: 'Snagov'
      },
      {
        nume: 'Tunari'
      },
      {
        nume: 'Vidra'
      },
      {
        nume: 'Voluntari'
      },
      {
        nume: 'Stefăneştii de Jos',
        simplu: 'Stefanestii de Jos'
      },
      {
        nume: 'Copăceni',
        simplu: 'Copaceni',
        comuna: '1 Decembrie'
      },
      {
        nume: 'Dumbrăveni',
        simplu: 'Dumbraveni',
        comuna: 'Balotesti'
      },
      {
        nume: 'Săftica',
        simplu: 'Saftica',
        comuna: 'Balotesti'
      },
      {
        nume: 'Islaz',
        comuna: 'Branesti'
      },
      {
        nume: 'Pasărea',
        simplu: 'Pasarea',
        comuna: 'Branesti'
      },
      {
        nume: 'Vadu Anei',
        comuna: 'Branesti'
      },
      {
        nume: 'Buciumeni',
        comuna: 'Buftea'
      },
      {
        nume: 'Bălăceanca',
        simplu: 'Balaceanca',
        comuna: 'Cernica'
      },
      {
        nume: 'Căldăraru',
        simplu: 'Caldararu',
        comuna: 'Cernica'
      },
      {
        nume: 'Poşta',
        simplu: 'Posta',
        comuna: 'Cernica'
      },
      {
        nume: 'Tânganu',
        simplu: 'Tanganu',
        comuna: 'Cernica'
      },
      {
        nume: 'Dudu',
        comuna: 'Chiajna'
      },
      {
        nume: 'Roşu',
        simplu: 'Rosu',
        comuna: 'Chiajna'
      },
      {
        nume: 'Rudeni',
        comuna: 'Chitila'
      },
      {
        nume: 'Izvorani',
        comuna: 'Ciolpani'
      },
      {
        nume: 'Lupăria',
        simplu: 'Luparia',
        comuna: 'Ciolpani'
      },
      {
        nume: 'Piscu',
        comuna: 'Ciolpani'
      },
      {
        nume: 'Dârvari',
        simplu: 'Darvari',
        comuna: 'Ciorogarla'
      },
      {
        nume: 'Olteni',
        comuna: 'Clinceni'
      },
      {
        nume: 'Ordoreanu',
        comuna: 'Clinceni'
      },
      {
        nume: 'Ostratu',
        comuna: 'Corbeanca'
      },
      {
        nume: 'Petreşti',
        simplu: 'Petresti',
        comuna: 'Corbeanca'
      },
      {
        nume: 'Tamaşi',
        simplu: 'Tamasi',
        comuna: 'Corbeanca'
      },
      {
        nume: 'Buda',
        comuna: 'Cornetu'
      },
      {
        nume: 'Creaţa',
        simplu: 'Creata',
        comuna: 'Dascalu'
      },
      {
        nume: 'Gagu',
        comuna: 'Dascalu'
      },
      {
        nume: 'Runcu',
        comuna: 'Dascalu'
      },
      {
        nume: 'Dobroeşti',
        simplu: 'Dobroesti',
        comuna: 'Dobroiesti'
      },
      {
        nume: 'Fundeni',
        comuna: 'Dobroiesti'
      },
      {
        nume: 'Tegheş',
        simplu: 'Teghes',
        comuna: 'Domnesti'
      },
      {
        nume: 'Dragomireşti-Deal',
        simplu: 'Dragomiresti-Deal',
        comuna: 'Dragomiresti-Vale'
      },
      {
        nume: 'Zurbaua',
        comuna: 'Dragomiresti-Vale'
      },
      {
        nume: 'Cozieni',
        comuna: 'Ganeasa'
      },
      {
        nume: 'Moara Domnească',
        simplu: 'Moara Domneasca',
        comuna: 'Ganeasa'
      },
      {
        nume: 'Piteasca',
        comuna: 'Ganeasa'
      },
      {
        nume: 'Sindriliţa',
        simplu: 'Sindrilita',
        comuna: 'Ganeasa'
      },
      {
        nume: 'Căţelu',
        simplu: 'Catelu',
        comuna: 'Glina'
      },
      {
        nume: 'Manolache',
        comuna: 'Glina'
      },
      {
        nume: 'Sitaru',
        comuna: 'Gradistea'
      },
      {
        nume: 'Lipia',
        comuna: 'Gruiu'
      },
      {
        nume: 'Siliştea Snagovului',
        simplu: 'Silistea Snagovului',
        comuna: 'Gruiu'
      },
      {
        nume: 'Sanţu Floreşti',
        simplu: 'Santu Floresti',
        comuna: 'Gruiu'
      },
      {
        nume: 'Alunişu',
        simplu: 'Alunisu',
        comuna: 'Magurele'
      },
      {
        nume: 'Dumitrana',
        comuna: 'Magurele'
      },
      {
        nume: 'Pruni',
        comuna: 'Magurele'
      },
      {
        nume: 'Vârteju',
        simplu: 'Varteju',
        comuna: 'Magurele'
      },
      {
        nume: 'Căciulaţi',
        simplu: 'Caciulati',
        comuna: 'Moara Vlasiei'
      },
      {
        nume: 'Balta Neagră',
        simplu: 'Balta Neagra',
        comuna: 'Nuci'
      },
      {
        nume: 'Merii Petchii',
        comuna: 'Nuci'
      },
      {
        nume: 'Micşuneştii Mari',
        simplu: 'Micsunestii Mari',
        comuna: 'Nuci'
      },
      {
        nume: 'Micşuneştii-Moară',
        simplu: 'Micsunestii-Moara',
        comuna: 'Nuci'
      },
      {
        nume: 'Odăile',
        simplu: 'Odaile',
        comuna: 'Otopeni'
      },
      {
        nume: 'Buriaş',
        simplu: 'Burias',
        comuna: 'Peris'
      },
      {
        nume: 'Bălteni',
        simplu: 'Balteni',
        comuna: 'Peris'
      },
      {
        nume: 'Măineasca',
        simplu: 'Maineasca',
        comuna: 'Petrachioaia'
      },
      {
        nume: 'Surlari',
        comuna: 'Petrachioaia'
      },
      {
        nume: 'Vânători',
        simplu: 'Vanatori',
        comuna: 'Petrachioaia'
      },
      {
        nume: 'Cioflinceni',
        comuna: 'Snagov'
      },
      {
        nume: 'Ghermăneşti',
        simplu: 'Ghermanesti',
        comuna: 'Snagov'
      },
      {
        nume: 'Tâncăbeşti',
        simplu: 'Tancabesti',
        comuna: 'Snagov'
      },
      {
        nume: 'Vlădiceasca',
        simplu: 'Vladiceasca',
        comuna: 'Snagov'
      },
      {
        nume: 'Creţuleasca',
        simplu: 'Cretuleasca',
        comuna: 'Stefanestii De Jos'
      },
      {
        nume: 'Stefăneştii de Sus',
        simplu: 'Stefanestii de Sus',
        comuna: 'Stefanestii De Jos'
      },
      {
        nume: 'Dimieni',
        comuna: 'Tunari'
      },
      {
        nume: 'Creţeşti',
        simplu: 'Cretesti',
        comuna: 'Vidra'
      },
      {
        nume: 'Sinteşti',
        simplu: 'Sintesti',
        comuna: 'Vidra'
      }
    ]
  },
  {
    auto: 'MM',
    nume: 'Maramureş',
    localitati: [
      {
        nume: 'Ardusat'
      },
      {
        nume: 'Ariniş',
        simplu: 'Arinis'
      },
      {
        nume: 'Asuaju de Sus'
      },
      {
        nume: 'Baia Mare'
      },
      {
        nume: 'Baia Sprie'
      },
      {
        nume: 'Bicaz'
      },
      {
        nume: 'Bistra'
      },
      {
        nume: 'Bocicoiu Mare'
      },
      {
        nume: 'Bogdan Vodă',
        simplu: 'Bogdan Voda'
      },
      {
        nume: 'Boiu Mare'
      },
      {
        nume: 'Borşa',
        simplu: 'Borsa'
      },
      {
        nume: 'Botiza'
      },
      {
        nume: 'Budeşti',
        simplu: 'Budesti'
      },
      {
        nume: 'Bârsana',
        simplu: 'Barsana'
      },
      {
        nume: 'Băiuţ',
        simplu: 'Baiut'
      },
      {
        nume: 'Băiţa de Sub Codru',
        simplu: 'Baita de Sub Codru'
      },
      {
        nume: 'Băseşti',
        simplu: 'Basesti'
      },
      {
        nume: 'Cavnic'
      },
      {
        nume: 'Cerneşti',
        simplu: 'Cernesti'
      },
      {
        nume: 'Cicârlău',
        simplu: 'Cicarlau'
      },
      {
        nume: 'Copalnic-Mănăştur',
        simplu: 'Copalnic-Manastur'
      },
      {
        nume: 'Coroieni'
      },
      {
        nume: 'Cupşeni',
        simplu: 'Cupseni'
      },
      {
        nume: 'Câmpulung la Tisa',
        simplu: 'Campulung la Tisa'
      },
      {
        nume: 'Călineşti',
        simplu: 'Calinesti'
      },
      {
        nume: 'Deseşti',
        simplu: 'Desesti'
      },
      {
        nume: 'Dragomireşti',
        simplu: 'Dragomiresti'
      },
      {
        nume: 'Dumbrăviţa',
        simplu: 'Dumbravita'
      },
      {
        nume: 'Fărcaşa',
        simplu: 'Farcasa'
      },
      {
        nume: 'Giuleşti',
        simplu: 'Giulesti'
      },
      {
        nume: 'Groşi',
        simplu: 'Grosi'
      },
      {
        nume: 'Groşii Tibleşului',
        simplu: 'Grosii Tiblesului'
      },
      {
        nume: 'Ieud'
      },
      {
        nume: 'Leordina'
      },
      {
        nume: 'Lăpuş',
        simplu: 'Lapus'
      },
      {
        nume: 'Mireşu Mare',
        simplu: 'Miresu Mare'
      },
      {
        nume: 'Moisei'
      },
      {
        nume: 'Oarţa de Jos',
        simplu: 'Oarta de Jos'
      },
      {
        nume: 'Ocna Sugatag',
        simplu: 'Ocna Sugatag'
      },
      {
        nume: 'Petrova'
      },
      {
        nume: 'Poienile Izei'
      },
      {
        nume: 'Poienile de Sub Munte'
      },
      {
        nume: 'Recea'
      },
      {
        nume: 'Remetea Chioarului'
      },
      {
        nume: 'Remeţi',
        simplu: 'Remeti'
      },
      {
        nume: 'Repedea'
      },
      {
        nume: 'Rona de Jos'
      },
      {
        nume: 'Rona de Sus'
      },
      {
        nume: 'Rozavlea'
      },
      {
        nume: 'Ruscova'
      },
      {
        nume: 'Sarasău',
        simplu: 'Sarasau'
      },
      {
        nume: 'Satulung'
      },
      {
        nume: 'Seini'
      },
      {
        nume: 'Sighetu Marmaţiei',
        simplu: 'Sighetu Marmatiei'
      },
      {
        nume: 'Strâmtura',
        simplu: 'Stramtura'
      },
      {
        nume: 'Suciu de Sus'
      },
      {
        nume: 'Săcel',
        simplu: 'Sacel'
      },
      {
        nume: 'Săcălăşeni',
        simplu: 'Sacalaseni'
      },
      {
        nume: 'Săliştea de Sus',
        simplu: 'Salistea de Sus'
      },
      {
        nume: 'Sălsig',
        simplu: 'Salsig'
      },
      {
        nume: 'Săpânţa',
        simplu: 'Sapanta'
      },
      {
        nume: 'Târgu Lăpuş',
        simplu: 'Targu Lapus'
      },
      {
        nume: 'Tăuţii-Măgherăuş',
        simplu: 'Tautii-Magheraus'
      },
      {
        nume: 'Ulmeni'
      },
      {
        nume: 'Vadu Izei'
      },
      {
        nume: 'Valea Chioarului'
      },
      {
        nume: 'Vima Mică',
        simplu: 'Vima Mica'
      },
      {
        nume: 'Vişeu de Jos',
        simplu: 'Viseu de Jos'
      },
      {
        nume: 'Vişeu de Sus',
        simplu: 'Viseu de Sus'
      },
      {
        nume: 'Sieu',
        simplu: 'Sieu'
      },
      {
        nume: 'Siseşti',
        simplu: 'Sisesti'
      },
      {
        nume: 'Somcuţa Mare',
        simplu: 'Somcuta Mare'
      },
      {
        nume: 'Arieşu de Câmp',
        simplu: 'Ariesu de Camp',
        comuna: 'Ardusat'
      },
      {
        nume: 'Colţirea',
        simplu: 'Coltirea',
        comuna: 'Ardusat'
      },
      {
        nume: 'Rodina',
        comuna: 'Arinis'
      },
      {
        nume: 'Tămăşeşti',
        simplu: 'Tamasesti',
        comuna: 'Arinis'
      },
      {
        nume: 'Asuaju de Jos',
        comuna: 'Asuaju De Sus'
      },
      {
        nume: 'Blidari',
        comuna: 'Baia Mare'
      },
      {
        nume: 'Firiza',
        comuna: 'Baia Mare'
      },
      {
        nume: 'Valea Neagră',
        simplu: 'Valea Neagra',
        comuna: 'Baia Mare'
      },
      {
        nume: 'Chiuzbaia',
        comuna: 'Baia Sprie'
      },
      {
        nume: 'Satu Nou de Sus',
        comuna: 'Baia Sprie'
      },
      {
        nume: 'Tăuţii de Sus',
        simplu: 'Tautii de Sus',
        comuna: 'Baia Sprie'
      },
      {
        nume: 'Urmeniş',
        simplu: 'Urmenis',
        comuna: 'Baita De Sub Codru'
      },
      {
        nume: 'Poiana Botizii',
        comuna: 'Baiut'
      },
      {
        nume: 'Strâmbu-Băiuţ',
        simplu: 'Strambu-Baiut',
        comuna: 'Baiut'
      },
      {
        nume: 'Năneşti',
        simplu: 'Nanesti',
        comuna: 'Barsana'
      },
      {
        nume: 'Onceşti',
        simplu: 'Oncesti',
        comuna: 'Barsana'
      },
      {
        nume: 'Odeşti',
        simplu: 'Odesti',
        comuna: 'Basesti'
      },
      {
        nume: 'Stremţ',
        simplu: 'Stremt',
        comuna: 'Basesti'
      },
      {
        nume: 'Sălişte',
        simplu: 'Saliste',
        comuna: 'Basesti'
      },
      {
        nume: 'Ciuta',
        comuna: 'Bicaz'
      },
      {
        nume: 'Corni',
        comuna: 'Bicaz'
      },
      {
        nume: 'Crasna Vişeului',
        simplu: 'Crasna Viseului',
        comuna: 'Bistra'
      },
      {
        nume: 'Valea Vişeului',
        simplu: 'Valea Viseului',
        comuna: 'Bistra'
      },
      {
        nume: 'Crăciuneşti',
        simplu: 'Craciunesti',
        comuna: 'Bocicoiu Mare'
      },
      {
        nume: 'Lunca la Tisa',
        comuna: 'Bocicoiu Mare'
      },
      {
        nume: 'Tisa',
        comuna: 'Bocicoiu Mare'
      },
      {
        nume: 'Bocicoel',
        comuna: 'Bogdan Voda'
      },
      {
        nume: 'Frâncenii Boiului',
        simplu: 'Francenii Boiului',
        comuna: 'Boiu Mare'
      },
      {
        nume: 'Prislop',
        comuna: 'Boiu Mare'
      },
      {
        nume: 'Romăneşti',
        simplu: 'Romanesti',
        comuna: 'Boiu Mare'
      },
      {
        nume: 'Băile Borşa',
        simplu: 'Baile Borsa',
        comuna: 'Borsa'
      },
      {
        nume: 'Sârbi',
        simplu: 'Sarbi',
        comuna: 'Budesti'
      },
      {
        nume: 'Corneşti',
        simplu: 'Cornesti',
        comuna: 'Calinesti'
      },
      {
        nume: 'Văleni',
        simplu: 'Valeni',
        comuna: 'Calinesti'
      },
      {
        nume: 'Brebeni',
        comuna: 'Cernesti'
      },
      {
        nume: 'Ciocotiş',
        simplu: 'Ciocotis',
        comuna: 'Cernesti'
      },
      {
        nume: 'Fânaţe',
        simplu: 'Fanate',
        comuna: 'Cernesti'
      },
      {
        nume: 'Izvoarele',
        comuna: 'Cernesti'
      },
      {
        nume: 'Măgureni',
        simplu: 'Magureni',
        comuna: 'Cernesti'
      },
      {
        nume: 'Trestia',
        comuna: 'Cernesti'
      },
      {
        nume: 'Bârgău',
        simplu: 'Bargau',
        comuna: 'Cicarlau'
      },
      {
        nume: 'Handalu Ilbei',
        comuna: 'Cicarlau'
      },
      {
        nume: 'Ilba',
        comuna: 'Cicarlau'
      },
      {
        nume: 'Berinţa',
        simplu: 'Berinta',
        comuna: 'Copalnic-Manastur'
      },
      {
        nume: 'Copalnic',
        comuna: 'Copalnic-Manastur'
      },
      {
        nume: 'Copalnic-Deal',
        comuna: 'Copalnic-Manastur'
      },
      {
        nume: 'Curtuiuşu Mic',
        simplu: 'Curtuiusu Mic',
        comuna: 'Copalnic-Manastur'
      },
      {
        nume: 'Cărpiniş',
        simplu: 'Carpinis',
        comuna: 'Copalnic-Manastur'
      },
      {
        nume: 'Făureşti',
        simplu: 'Fauresti',
        comuna: 'Copalnic-Manastur'
      },
      {
        nume: 'Lăschia',
        simplu: 'Laschia',
        comuna: 'Copalnic-Manastur'
      },
      {
        nume: 'Preluca Nouă',
        simplu: 'Preluca Noua',
        comuna: 'Copalnic-Manastur'
      },
      {
        nume: 'Preluca Veche',
        comuna: 'Copalnic-Manastur'
      },
      {
        nume: 'Ruşor',
        simplu: 'Rusor',
        comuna: 'Copalnic-Manastur'
      },
      {
        nume: 'Vad',
        comuna: 'Copalnic-Manastur'
      },
      {
        nume: 'Baba',
        comuna: 'Coroieni'
      },
      {
        nume: 'Dealu Mare',
        comuna: 'Coroieni'
      },
      {
        nume: 'Drăghia',
        simplu: 'Draghia',
        comuna: 'Coroieni'
      },
      {
        nume: 'Vălenii Lăpuşului',
        simplu: 'Valenii Lapusului',
        comuna: 'Coroieni'
      },
      {
        nume: 'Costeni',
        comuna: 'Cupseni'
      },
      {
        nume: 'Libotin',
        comuna: 'Cupseni'
      },
      {
        nume: 'Ungureni',
        comuna: 'Cupseni'
      },
      {
        nume: 'Hărniceşti',
        simplu: 'Harnicesti',
        comuna: 'Desesti'
      },
      {
        nume: 'Mara',
        comuna: 'Desesti'
      },
      {
        nume: 'Chechiş',
        simplu: 'Chechis',
        comuna: 'Dumbravita'
      },
      {
        nume: 'Cărbunari',
        simplu: 'Carbunari',
        comuna: 'Dumbravita'
      },
      {
        nume: 'Rus',
        comuna: 'Dumbravita'
      },
      {
        nume: 'Unguraş',
        simplu: 'Unguras',
        comuna: 'Dumbravita'
      },
      {
        nume: 'Sindreşti',
        simplu: 'Sindresti',
        comuna: 'Dumbravita'
      },
      {
        nume: 'Buzeşti',
        simplu: 'Buzesti',
        comuna: 'Farcasa'
      },
      {
        nume: 'Sârbi',
        simplu: 'Sarbi',
        comuna: 'Farcasa'
      },
      {
        nume: 'Tămaia',
        simplu: 'Tamaia',
        comuna: 'Farcasa'
      },
      {
        nume: 'Berbeşti',
        simplu: 'Berbesti',
        comuna: 'Giulesti'
      },
      {
        nume: 'Fereşti',
        simplu: 'Feresti',
        comuna: 'Giulesti'
      },
      {
        nume: 'Mănăstirea',
        simplu: 'Manastirea',
        comuna: 'Giulesti'
      },
      {
        nume: 'Ocoliş',
        simplu: 'Ocolis',
        comuna: 'Grosi'
      },
      {
        nume: 'Satu Nou de Jos',
        comuna: 'Grosi'
      },
      {
        nume: 'Dăneştii Chioarului',
        simplu: 'Danestii Chioarului',
        comuna: 'Miresu Mare'
      },
      {
        nume: 'Iadăra',
        simplu: 'Iadara',
        comuna: 'Miresu Mare'
      },
      {
        nume: 'Lucăceşti',
        simplu: 'Lucacesti',
        comuna: 'Miresu Mare'
      },
      {
        nume: 'Remeţi pe Someş',
        simplu: 'Remeti pe Somes',
        comuna: 'Miresu Mare'
      },
      {
        nume: 'Stejera',
        comuna: 'Miresu Mare'
      },
      {
        nume: 'Tulghieş',
        simplu: 'Tulghies',
        comuna: 'Miresu Mare'
      },
      {
        nume: 'Oarţa de Sus',
        simplu: 'Oarta de Sus',
        comuna: 'Oarta De Jos'
      },
      {
        nume: 'Orţiţa',
        simplu: 'Ortita',
        comuna: 'Oarta De Jos'
      },
      {
        nume: 'Breb',
        comuna: 'Ocna Sugatag'
      },
      {
        nume: 'Hoteni',
        comuna: 'Ocna Sugatag'
      },
      {
        nume: 'Sat-Sugatag',
        simplu: 'Sat-Sugatag',
        comuna: 'Ocna Sugatag'
      },
      {
        nume: 'Bozânta Mică',
        simplu: 'Bozanta Mica',
        comuna: 'Recea'
      },
      {
        nume: 'Lăpuşel',
        simplu: 'Lapusel',
        comuna: 'Recea'
      },
      {
        nume: 'Mocira',
        comuna: 'Recea'
      },
      {
        nume: 'Săsar',
        simplu: 'Sasar',
        comuna: 'Recea'
      },
      {
        nume: 'Berchez',
        comuna: 'Remetea Chioarului'
      },
      {
        nume: 'Berchezoaia',
        comuna: 'Remetea Chioarului'
      },
      {
        nume: 'Posta',
        comuna: 'Remetea Chioarului'
      },
      {
        nume: 'Remecioara',
        comuna: 'Remetea Chioarului'
      },
      {
        nume: 'Piatra',
        comuna: 'Remeti'
      },
      {
        nume: 'Teceu Mic',
        comuna: 'Remeti'
      },
      {
        nume: 'Coştiui',
        simplu: 'Costiui',
        comuna: 'Rona De Sus'
      },
      {
        nume: 'Salta',
        comuna: 'Rozavlea'
      },
      {
        nume: 'Coaş',
        simplu: 'Coas',
        comuna: 'Sacalaseni'
      },
      {
        nume: 'Coltău',
        simplu: 'Coltau',
        comuna: 'Sacalaseni'
      },
      {
        nume: 'Coruia',
        comuna: 'Sacalaseni'
      },
      {
        nume: 'Culcea',
        comuna: 'Sacalaseni'
      },
      {
        nume: 'Cătălina',
        simplu: 'Catalina',
        comuna: 'Sacalaseni'
      },
      {
        nume: 'Întrerâuri',
        simplu: 'Intrerauri',
        comuna: 'Sacalaseni'
      },
      {
        nume: 'Gârdani',
        simplu: 'Gardani',
        comuna: 'Salsig'
      },
      {
        nume: 'Arieşu de Pădure',
        simplu: 'Ariesu de Padure',
        comuna: 'Satulung'
      },
      {
        nume: 'Fersig',
        comuna: 'Satulung'
      },
      {
        nume: 'Finteuşu Mic',
        simplu: 'Finteusu Mic',
        comuna: 'Satulung'
      },
      {
        nume: 'Hideaga',
        comuna: 'Satulung'
      },
      {
        nume: 'Mogoşeşti',
        simplu: 'Mogosesti',
        comuna: 'Satulung'
      },
      {
        nume: 'Pribileşti',
        simplu: 'Pribilesti',
        comuna: 'Satulung'
      },
      {
        nume: 'Săbişa',
        simplu: 'Sabisa',
        comuna: 'Seini'
      },
      {
        nume: 'Viile Apei',
        comuna: 'Seini'
      },
      {
        nume: 'Iapa',
        comuna: 'Sighetu Marmatiei'
      },
      {
        nume: 'Lazu Baciului',
        comuna: 'Sighetu Marmatiei'
      },
      {
        nume: 'Valea Cufundoasă',
        simplu: 'Valea Cufundoasa',
        comuna: 'Sighetu Marmatiei'
      },
      {
        nume: 'Valea Hotarului',
        comuna: 'Sighetu Marmatiei'
      },
      {
        nume: 'Sugău',
        simplu: 'Sugau',
        comuna: 'Sighetu Marmatiei'
      },
      {
        nume: 'Bontăieni',
        simplu: 'Bontaieni',
        comuna: 'Sisesti'
      },
      {
        nume: 'Cetăţele',
        simplu: 'Cetatele',
        comuna: 'Sisesti'
      },
      {
        nume: 'Dăneşti',
        simplu: 'Danesti',
        comuna: 'Sisesti'
      },
      {
        nume: 'Negreia',
        comuna: 'Sisesti'
      },
      {
        nume: 'Plopiş',
        simplu: 'Plopis',
        comuna: 'Sisesti'
      },
      {
        nume: 'Surdeşti',
        simplu: 'Surdesti',
        comuna: 'Sisesti'
      },
      {
        nume: 'Buciumi',
        comuna: 'Somcuta Mare'
      },
      {
        nume: 'Buteasa',
        comuna: 'Somcuta Mare'
      },
      {
        nume: 'Ciolt',
        comuna: 'Somcuta Mare'
      },
      {
        nume: 'Codru Butesii',
        comuna: 'Somcuta Mare'
      },
      {
        nume: 'Finteuşu Mare',
        simplu: 'Finteusu Mare',
        comuna: 'Somcuta Mare'
      },
      {
        nume: 'Hovrila',
        comuna: 'Somcuta Mare'
      },
      {
        nume: 'Vălenii Somcutei',
        simplu: 'Valenii Somcutei',
        comuna: 'Somcuta Mare'
      },
      {
        nume: 'Glod',
        comuna: 'Stramtura'
      },
      {
        nume: 'Slătioara',
        simplu: 'Slatioara',
        comuna: 'Stramtura'
      },
      {
        nume: 'Larga',
        comuna: 'Suciu De Sus'
      },
      {
        nume: 'Suciu de Jos',
        comuna: 'Suciu De Sus'
      },
      {
        nume: 'Boiereni',
        comuna: 'Targu Lapus'
      },
      {
        nume: 'Borcut',
        comuna: 'Targu Lapus'
      },
      {
        nume: 'Cufoaia',
        comuna: 'Targu Lapus'
      },
      {
        nume: 'Dobricu Lăpuşului',
        simplu: 'Dobricu Lapusului',
        comuna: 'Targu Lapus'
      },
      {
        nume: 'Dumbrava',
        comuna: 'Targu Lapus'
      },
      {
        nume: 'Dămăcuşeni',
        simplu: 'Damacuseni',
        comuna: 'Targu Lapus'
      },
      {
        nume: 'Fântânele',
        simplu: 'Fantanele',
        comuna: 'Targu Lapus'
      },
      {
        nume: 'Groape',
        comuna: 'Targu Lapus'
      },
      {
        nume: 'Inău',
        simplu: 'Inau',
        comuna: 'Targu Lapus'
      },
      {
        nume: 'Rogoz',
        comuna: 'Targu Lapus'
      },
      {
        nume: 'Rohia',
        comuna: 'Targu Lapus'
      },
      {
        nume: 'Răzoare',
        simplu: 'Razoare',
        comuna: 'Targu Lapus'
      },
      {
        nume: 'Stoiceni',
        comuna: 'Targu Lapus'
      },
      {
        nume: 'Bozânta Mare',
        simplu: 'Bozanta Mare',
        comuna: 'Tautii-Magheraus'
      },
      {
        nume: 'Buşag',
        simplu: 'Busag',
        comuna: 'Tautii-Magheraus'
      },
      {
        nume: 'Băiţa',
        simplu: 'Baita',
        comuna: 'Tautii-Magheraus'
      },
      {
        nume: 'Merişor',
        simplu: 'Merisor',
        comuna: 'Tautii-Magheraus'
      },
      {
        nume: 'Nistru',
        comuna: 'Tautii-Magheraus'
      },
      {
        nume: 'Ulmoasa',
        comuna: 'Tautii-Magheraus'
      },
      {
        nume: 'Arduzel',
        comuna: 'Ulmeni'
      },
      {
        nume: 'Chelinţa',
        simplu: 'Chelinta',
        comuna: 'Ulmeni'
      },
      {
        nume: 'Mânău',
        simplu: 'Manau',
        comuna: 'Ulmeni'
      },
      {
        nume: 'Someş-Uileac',
        simplu: 'Somes-Uileac',
        comuna: 'Ulmeni'
      },
      {
        nume: 'Tohat',
        comuna: 'Ulmeni'
      },
      {
        nume: 'Vicea',
        comuna: 'Ulmeni'
      },
      {
        nume: 'Ticău',
        simplu: 'Ticau',
        comuna: 'Ulmeni'
      },
      {
        nume: 'Valea Stejarului',
        comuna: 'Vadu Izei'
      },
      {
        nume: 'Curtuiuşu Mare',
        simplu: 'Curtuiusu Mare',
        comuna: 'Valea Chioarului'
      },
      {
        nume: 'Duruşa',
        simplu: 'Durusa',
        comuna: 'Valea Chioarului'
      },
      {
        nume: 'Fericea',
        comuna: 'Valea Chioarului'
      },
      {
        nume: 'Mesteacăn',
        simplu: 'Mesteacan',
        comuna: 'Valea Chioarului'
      },
      {
        nume: 'Vărai',
        simplu: 'Varai',
        comuna: 'Valea Chioarului'
      },
      {
        nume: 'Aspra',
        comuna: 'Vima Mica'
      },
      {
        nume: 'Dealu Corbului',
        comuna: 'Vima Mica'
      },
      {
        nume: 'Jugăstreni',
        simplu: 'Jugastreni',
        comuna: 'Vima Mica'
      },
      {
        nume: 'Peteritea',
        comuna: 'Vima Mica'
      },
      {
        nume: 'Sălniţa',
        simplu: 'Salnita',
        comuna: 'Vima Mica'
      },
      {
        nume: 'Vima Mare',
        comuna: 'Vima Mica'
      },
      {
        nume: 'Vişeu de Mijloc',
        simplu: 'Viseu de Mijloc',
        comuna: 'Viseu De Sus'
      }
    ]
  },
  {
    auto: 'MH',
    nume: 'Mehedinţi',
    localitati: [
      {
        nume: 'Baia de Aramă',
        simplu: 'Baia de Arama'
      },
      {
        nume: 'Bala'
      },
      {
        nume: 'Balta'
      },
      {
        nume: 'Brezniţa-Motru',
        simplu: 'Breznita-Motru'
      },
      {
        nume: 'Brezniţa-Ocol',
        simplu: 'Breznita-Ocol'
      },
      {
        nume: 'Broşteni',
        simplu: 'Brosteni'
      },
      {
        nume: 'Burila Mare'
      },
      {
        nume: 'Butoieşti',
        simplu: 'Butoiesti'
      },
      {
        nume: 'Bâcleş',
        simplu: 'Bacles'
      },
      {
        nume: 'Bâlvăneşti',
        simplu: 'Balvanesti'
      },
      {
        nume: 'Bălăciţa',
        simplu: 'Balacita'
      },
      {
        nume: 'Cireşu',
        simplu: 'Ciresu'
      },
      {
        nume: 'Corcova'
      },
      {
        nume: 'Corlăţel',
        simplu: 'Corlatel'
      },
      {
        nume: 'Cujmir'
      },
      {
        nume: 'Căzăneşti',
        simplu: 'Cazanesti'
      },
      {
        nume: 'Devesel'
      },
      {
        nume: 'Drobeta-Turnu Severin'
      },
      {
        nume: 'Dubova'
      },
      {
        nume: 'Dârvari',
        simplu: 'Darvari'
      },
      {
        nume: 'Eşelniţa',
        simplu: 'Eselnita'
      },
      {
        nume: 'Floreşti',
        simplu: 'Floresti'
      },
      {
        nume: 'Godeanu'
      },
      {
        nume: 'Gogoşu',
        simplu: 'Gogosu'
      },
      {
        nume: 'Greci'
      },
      {
        nume: 'Grozeşti',
        simplu: 'Grozesti'
      },
      {
        nume: 'Gruia'
      },
      {
        nume: 'Gârla Mare',
        simplu: 'Garla Mare'
      },
      {
        nume: 'Hinova'
      },
      {
        nume: 'Husnicioara'
      },
      {
        nume: 'Iloviţa',
        simplu: 'Ilovita'
      },
      {
        nume: 'Ilovăţ',
        simplu: 'Ilovat'
      },
      {
        nume: 'Isverna'
      },
      {
        nume: 'Izvoru Bârzii',
        simplu: 'Izvoru Barzii'
      },
      {
        nume: 'Jiana'
      },
      {
        nume: 'Livezile'
      },
      {
        nume: 'Malovăţ',
        simplu: 'Malovat'
      },
      {
        nume: 'Obârşia de Câmp',
        simplu: 'Obarsia de Camp'
      },
      {
        nume: 'Obârşia-Cloşani',
        simplu: 'Obarsia-Closani'
      },
      {
        nume: 'Oprişor',
        simplu: 'Oprisor'
      },
      {
        nume: 'Orşova',
        simplu: 'Orsova'
      },
      {
        nume: 'Podeni'
      },
      {
        nume: 'Ponoarele'
      },
      {
        nume: 'Poroina Mare'
      },
      {
        nume: 'Pristol'
      },
      {
        nume: 'Prunişor',
        simplu: 'Prunisor'
      },
      {
        nume: 'Punghina'
      },
      {
        nume: 'Pătulele',
        simplu: 'Patulele'
      },
      {
        nume: 'Rogova'
      },
      {
        nume: 'Salcia'
      },
      {
        nume: 'Strehaia'
      },
      {
        nume: 'Stângăceaua',
        simplu: 'Stangaceaua'
      },
      {
        nume: 'Sviniţa',
        simplu: 'Svinita'
      },
      {
        nume: 'Tâmna',
        simplu: 'Tamna'
      },
      {
        nume: 'Vlădaia',
        simplu: 'Vladaia'
      },
      {
        nume: 'Voloiac'
      },
      {
        nume: 'Vânju Mare',
        simplu: 'Vanju Mare'
      },
      {
        nume: 'Vânjuleţ',
        simplu: 'Vanjulet'
      },
      {
        nume: 'Vânători',
        simplu: 'Vanatori'
      },
      {
        nume: 'Simian',
        simplu: 'Simian'
      },
      {
        nume: 'Sişeşti',
        simplu: 'Sisesti'
      },
      {
        nume: 'Sovarna',
        simplu: 'Sovarna'
      },
      {
        nume: 'Corzu',
        comuna: 'Bacles'
      },
      {
        nume: 'Giura',
        comuna: 'Bacles'
      },
      {
        nume: 'Petra',
        comuna: 'Bacles'
      },
      {
        nume: 'Podu Grosului',
        comuna: 'Bacles'
      },
      {
        nume: 'Seliştiuţa',
        simplu: 'Selistiuta',
        comuna: 'Bacles'
      },
      {
        nume: 'Smadoviţa',
        simplu: 'Smadovita',
        comuna: 'Bacles'
      },
      {
        nume: 'Bratilovu',
        comuna: 'Baia De Arama'
      },
      {
        nume: 'Brebina',
        comuna: 'Baia De Arama'
      },
      {
        nume: 'Dealu Mare',
        comuna: 'Baia De Arama'
      },
      {
        nume: 'Mărăşeşti',
        simplu: 'Marasesti',
        comuna: 'Baia De Arama'
      },
      {
        nume: 'Negoeşti',
        simplu: 'Negoesti',
        comuna: 'Baia De Arama'
      },
      {
        nume: 'Pistriţa',
        simplu: 'Pistrita',
        comuna: 'Baia De Arama'
      },
      {
        nume: 'Stăneşti',
        simplu: 'Stanesti',
        comuna: 'Baia De Arama'
      },
      {
        nume: 'Titerleşti',
        simplu: 'Titerlesti',
        comuna: 'Baia De Arama'
      },
      {
        nume: 'Bala de Sus',
        comuna: 'Bala'
      },
      {
        nume: 'Brateşul',
        simplu: 'Bratesul',
        comuna: 'Bala'
      },
      {
        nume: 'Brativoeşti',
        simplu: 'Brativoesti',
        comuna: 'Bala'
      },
      {
        nume: 'Comăneşti',
        simplu: 'Comanesti',
        comuna: 'Bala'
      },
      {
        nume: 'Crainici',
        comuna: 'Bala'
      },
      {
        nume: 'Câmpu Mare',
        simplu: 'Campu Mare',
        comuna: 'Bala'
      },
      {
        nume: 'Cârşu',
        simplu: 'Carsu',
        comuna: 'Bala'
      },
      {
        nume: 'Dâlma',
        simplu: 'Dalma',
        comuna: 'Bala'
      },
      {
        nume: 'Iupca',
        comuna: 'Bala'
      },
      {
        nume: 'Molani',
        comuna: 'Bala'
      },
      {
        nume: 'Rudina',
        comuna: 'Bala'
      },
      {
        nume: 'Runcuşoru',
        simplu: 'Runcusoru',
        comuna: 'Bala'
      },
      {
        nume: 'Sărdăneşti',
        simplu: 'Sardanesti',
        comuna: 'Bala'
      },
      {
        nume: 'Vidimireşti',
        simplu: 'Vidimiresti',
        comuna: 'Bala'
      },
      {
        nume: 'Dobra',
        comuna: 'Balacita'
      },
      {
        nume: 'Gvardiniţa',
        simplu: 'Gvardinita',
        comuna: 'Balacita'
      },
      {
        nume: 'Coada Cornetului',
        comuna: 'Balta'
      },
      {
        nume: 'Costeşti',
        simplu: 'Costesti',
        comuna: 'Balta'
      },
      {
        nume: 'Gornoviţa',
        simplu: 'Gornovita',
        comuna: 'Balta'
      },
      {
        nume: 'Nevăţu',
        simplu: 'Nevatu',
        comuna: 'Balta'
      },
      {
        nume: 'Prejna',
        comuna: 'Balta'
      },
      {
        nume: 'Sfodea',
        comuna: 'Balta'
      },
      {
        nume: 'Bâlvăneştii de Jos',
        simplu: 'Balvanestii de Jos',
        comuna: 'Balvanesti'
      },
      {
        nume: 'Călineştii de Jos',
        simplu: 'Calinestii de Jos',
        comuna: 'Balvanesti'
      },
      {
        nume: 'Călineştii de Sus',
        simplu: 'Calinestii de Sus',
        comuna: 'Balvanesti'
      },
      {
        nume: 'Pârlagele',
        simplu: 'Parlagele',
        comuna: 'Balvanesti'
      },
      {
        nume: 'Cosovăţ',
        simplu: 'Cosovat',
        comuna: 'Breznita-Motru'
      },
      {
        nume: 'Deleni',
        comuna: 'Breznita-Motru'
      },
      {
        nume: 'Făuroaia',
        simplu: 'Fauroaia',
        comuna: 'Breznita-Motru'
      },
      {
        nume: 'Plai',
        comuna: 'Breznita-Motru'
      },
      {
        nume: 'Tălăpanu',
        simplu: 'Talapanu',
        comuna: 'Breznita-Motru'
      },
      {
        nume: 'Valea Teiului',
        comuna: 'Breznita-Motru'
      },
      {
        nume: 'Jidoştiţa',
        simplu: 'Jidostita',
        comuna: 'Breznita-Ocol'
      },
      {
        nume: 'Magheru',
        comuna: 'Breznita-Ocol'
      },
      {
        nume: 'Suşiţa',
        simplu: 'Susita',
        comuna: 'Breznita-Ocol'
      },
      {
        nume: 'Căpăţâneşti',
        simplu: 'Capatanesti',
        comuna: 'Brosteni'
      },
      {
        nume: 'Luncşoara',
        simplu: 'Luncsoara',
        comuna: 'Brosteni'
      },
      {
        nume: 'Lupşa de Jos',
        simplu: 'Lupsa de Jos',
        comuna: 'Brosteni'
      },
      {
        nume: 'Lupşa de Sus',
        simplu: 'Lupsa de Sus',
        comuna: 'Brosteni'
      },
      {
        nume: 'Meriş',
        simplu: 'Meris',
        comuna: 'Brosteni'
      },
      {
        nume: 'Crivina',
        comuna: 'Burila Mare'
      },
      {
        nume: 'Izvoru Frumos',
        comuna: 'Burila Mare'
      },
      {
        nume: 'Vrancea',
        comuna: 'Burila Mare'
      },
      {
        nume: 'Tigănaşi',
        simplu: 'Tiganasi',
        comuna: 'Burila Mare'
      },
      {
        nume: 'Argineşti',
        simplu: 'Arginesti',
        comuna: 'Butoiesti'
      },
      {
        nume: 'Buiceşti',
        simplu: 'Buicesti',
        comuna: 'Butoiesti'
      },
      {
        nume: 'Gura Motrului',
        comuna: 'Butoiesti'
      },
      {
        nume: 'Jugastru',
        comuna: 'Butoiesti'
      },
      {
        nume: 'Pluta',
        comuna: 'Butoiesti'
      },
      {
        nume: 'Răduţeşti',
        simplu: 'Radutesti',
        comuna: 'Butoiesti'
      },
      {
        nume: 'Tânţaru',
        simplu: 'Tantaru',
        comuna: 'Butoiesti'
      },
      {
        nume: 'Ercea',
        comuna: 'Cazanesti'
      },
      {
        nume: 'Govodarva',
        comuna: 'Cazanesti'
      },
      {
        nume: 'Gârbovăţu de Sus',
        simplu: 'Garbovatu de Sus',
        comuna: 'Cazanesti'
      },
      {
        nume: 'Ilovu',
        comuna: 'Cazanesti'
      },
      {
        nume: 'Jigniţa',
        simplu: 'Jignita',
        comuna: 'Cazanesti'
      },
      {
        nume: 'Poiana',
        comuna: 'Cazanesti'
      },
      {
        nume: 'Păltinişu',
        simplu: 'Paltinisu',
        comuna: 'Cazanesti'
      },
      {
        nume: 'Roşia',
        simplu: 'Rosia',
        comuna: 'Cazanesti'
      },
      {
        nume: 'Severineşti',
        simplu: 'Severinesti',
        comuna: 'Cazanesti'
      },
      {
        nume: 'Suharu',
        comuna: 'Cazanesti'
      },
      {
        nume: 'Valea Coşuştei',
        simplu: 'Valea Cosustei',
        comuna: 'Cazanesti'
      },
      {
        nume: 'Bunoaica',
        comuna: 'Ciresu'
      },
      {
        nume: 'Jupâneşti',
        simplu: 'Jupanesti',
        comuna: 'Ciresu'
      },
      {
        nume: 'Negruşa',
        simplu: 'Negrusa',
        comuna: 'Ciresu'
      },
      {
        nume: 'Breţa',
        simplu: 'Breta',
        comuna: 'Corcova'
      },
      {
        nume: 'Cernaia',
        comuna: 'Corcova'
      },
      {
        nume: 'Cordun',
        comuna: 'Corcova'
      },
      {
        nume: 'Croica',
        comuna: 'Corcova'
      },
      {
        nume: 'Gârbovăţu de Jos',
        simplu: 'Garbovatu de Jos',
        comuna: 'Corcova'
      },
      {
        nume: 'Imoasa',
        comuna: 'Corcova'
      },
      {
        nume: 'Jirov',
        comuna: 'Corcova'
      },
      {
        nume: 'Măru Roşu',
        simplu: 'Maru Rosu',
        comuna: 'Corcova'
      },
      {
        nume: 'Puşcaşu',
        simplu: 'Puscasu',
        comuna: 'Corcova'
      },
      {
        nume: 'Pârvuleşti',
        simplu: 'Parvulesti',
        comuna: 'Corcova'
      },
      {
        nume: 'Stejaru',
        comuna: 'Corcova'
      },
      {
        nume: 'Vlădăşeşti',
        simplu: 'Vladasesti',
        comuna: 'Corcova'
      },
      {
        nume: 'Valea Anilor',
        comuna: 'Corlatel'
      },
      {
        nume: 'Aurora',
        comuna: 'Cujmir'
      },
      {
        nume: 'Cujmiru Mic',
        comuna: 'Cujmir'
      },
      {
        nume: 'Gemeni',
        comuna: 'Darvari'
      },
      {
        nume: 'Batoţi',
        simplu: 'Batoti',
        comuna: 'Devesel'
      },
      {
        nume: 'Bistreţu',
        simplu: 'Bistretu',
        comuna: 'Devesel'
      },
      {
        nume: 'Dunărea Mică',
        simplu: 'Dunarea Mica',
        comuna: 'Devesel'
      },
      {
        nume: 'Scăpău',
        simplu: 'Scapau',
        comuna: 'Devesel'
      },
      {
        nume: 'Tismana',
        comuna: 'Devesel'
      },
      {
        nume: 'Dudaşu Schelei',
        simplu: 'Dudasu Schelei',
        comuna: 'Drobeta-Turnu Severin'
      },
      {
        nume: 'Gura Văii',
        simplu: 'Gura Vaii',
        comuna: 'Drobeta-Turnu Severin'
      },
      {
        nume: 'Schela Cladovei',
        comuna: 'Drobeta-Turnu Severin'
      },
      {
        nume: 'Baia Nouă',
        simplu: 'Baia Noua',
        comuna: 'Dubova'
      },
      {
        nume: 'Eibenthal',
        comuna: 'Dubova'
      },
      {
        nume: 'Albuleşti',
        simplu: 'Albulesti',
        comuna: 'Dumbrava'
      },
      {
        nume: 'Brâgleasa',
        simplu: 'Bragleasa',
        comuna: 'Dumbrava'
      },
      {
        nume: 'Dumbrava de Jos',
        comuna: 'Dumbrava'
      },
      {
        nume: 'Dumbrava de Mijloc',
        comuna: 'Dumbrava'
      },
      {
        nume: 'Dumbrava de Sus',
        comuna: 'Dumbrava'
      },
      {
        nume: 'Golineasa',
        comuna: 'Dumbrava'
      },
      {
        nume: 'Higiu',
        comuna: 'Dumbrava'
      },
      {
        nume: 'Rocşoreni',
        simplu: 'Rocsoreni',
        comuna: 'Dumbrava'
      },
      {
        nume: 'Valea Marcului',
        comuna: 'Dumbrava'
      },
      {
        nume: 'Varodia',
        comuna: 'Dumbrava'
      },
      {
        nume: 'Vlădica',
        simplu: 'Vladica',
        comuna: 'Dumbrava'
      },
      {
        nume: 'Copăcioasa',
        simplu: 'Copacioasa',
        comuna: 'Floresti'
      },
      {
        nume: 'Gârdoaia',
        simplu: 'Gardoaia',
        comuna: 'Floresti'
      },
      {
        nume: 'Livezi',
        comuna: 'Floresti'
      },
      {
        nume: 'Moşneni',
        simplu: 'Mosneni',
        comuna: 'Floresti'
      },
      {
        nume: 'Peşteana',
        simplu: 'Pesteana',
        comuna: 'Floresti'
      },
      {
        nume: 'Peştenuţa',
        simplu: 'Pestenuta',
        comuna: 'Floresti'
      },
      {
        nume: 'Stroeşti',
        simplu: 'Stroesti',
        comuna: 'Floresti'
      },
      {
        nume: 'Zegujani',
        comuna: 'Floresti'
      },
      {
        nume: 'Vrata',
        comuna: 'Garla Mare'
      },
      {
        nume: 'Marga',
        comuna: 'Godeanu'
      },
      {
        nume: 'Păuneşti',
        simplu: 'Paunesti',
        comuna: 'Godeanu'
      },
      {
        nume: 'Siroca',
        simplu: 'Siroca',
        comuna: 'Godeanu'
      },
      {
        nume: 'Balta Verde',
        comuna: 'Gogosu'
      },
      {
        nume: 'Burila Mică',
        simplu: 'Burila Mica',
        comuna: 'Gogosu'
      },
      {
        nume: 'Ostrovu Mare',
        comuna: 'Gogosu'
      },
      {
        nume: 'Blidaru',
        comuna: 'Greci'
      },
      {
        nume: 'Bâltanele',
        simplu: 'Baltanele',
        comuna: 'Greci'
      },
      {
        nume: 'Sălătruc',
        simplu: 'Salatruc',
        comuna: 'Greci'
      },
      {
        nume: 'Valea Petrii',
        comuna: 'Greci'
      },
      {
        nume: 'Vişina',
        simplu: 'Visina',
        comuna: 'Greci'
      },
      {
        nume: 'Cârceni',
        simplu: 'Carceni',
        comuna: 'Grozesti'
      },
      {
        nume: 'Păsărani',
        simplu: 'Pasarani',
        comuna: 'Grozesti'
      },
      {
        nume: 'Suşiţa',
        simplu: 'Susita',
        comuna: 'Grozesti'
      },
      {
        nume: 'Izvoarele',
        comuna: 'Gruia'
      },
      {
        nume: 'Poiana Gruii',
        comuna: 'Gruia'
      },
      {
        nume: 'Bistriţa',
        simplu: 'Bistrita',
        comuna: 'Hinova'
      },
      {
        nume: 'Cârjei',
        simplu: 'Carjei',
        comuna: 'Hinova'
      },
      {
        nume: 'Ostrovu Corbului',
        comuna: 'Hinova'
      },
      {
        nume: 'Alunişul',
        simplu: 'Alunisul',
        comuna: 'Husnicioara'
      },
      {
        nume: 'Borogea',
        comuna: 'Husnicioara'
      },
      {
        nume: 'Bădiţeşti',
        simplu: 'Baditesti',
        comuna: 'Husnicioara'
      },
      {
        nume: 'Celnata',
        comuna: 'Husnicioara'
      },
      {
        nume: 'Dumbrăviţa',
        simplu: 'Dumbravita',
        comuna: 'Husnicioara'
      },
      {
        nume: 'Marmanu',
        comuna: 'Husnicioara'
      },
      {
        nume: 'Oprăneşti',
        simplu: 'Opranesti',
        comuna: 'Husnicioara'
      },
      {
        nume: 'Peri',
        comuna: 'Husnicioara'
      },
      {
        nume: 'Priboieşti',
        simplu: 'Priboiesti',
        comuna: 'Husnicioara'
      },
      {
        nume: 'Selişteni',
        simplu: 'Selisteni',
        comuna: 'Husnicioara'
      },
      {
        nume: 'Budăneşti',
        simplu: 'Budanesti',
        comuna: 'Ilovat'
      },
      {
        nume: 'Cracu Lung',
        comuna: 'Ilovat'
      },
      {
        nume: 'Dâlbociţa',
        simplu: 'Dalbocita',
        comuna: 'Ilovat'
      },
      {
        nume: 'Firizu',
        comuna: 'Ilovat'
      },
      {
        nume: 'Racova',
        comuna: 'Ilovat'
      },
      {
        nume: 'Bahna',
        comuna: 'Ilovita'
      },
      {
        nume: 'Moiseşti',
        simplu: 'Moisesti',
        comuna: 'Ilovita'
      },
      {
        nume: 'Buseşti',
        simplu: 'Busesti',
        comuna: 'Isverna'
      },
      {
        nume: 'Cerna-Vârf',
        simplu: 'Cerna-Varf',
        comuna: 'Isverna'
      },
      {
        nume: 'Drăgheşti',
        simplu: 'Draghesti',
        comuna: 'Isverna'
      },
      {
        nume: 'Giurgiani',
        comuna: 'Isverna'
      },
      {
        nume: 'Nadanova',
        comuna: 'Isverna'
      },
      {
        nume: 'Seliştea',
        simplu: 'Selistea',
        comuna: 'Isverna'
      },
      {
        nume: 'Turtaba',
        comuna: 'Isverna'
      },
      {
        nume: 'Baloteşti',
        simplu: 'Balotesti',
        comuna: 'Izvoru Barzii'
      },
      {
        nume: 'Halânga',
        simplu: 'Halanga',
        comuna: 'Izvoru Barzii'
      },
      {
        nume: 'Puţinei',
        simplu: 'Putinei',
        comuna: 'Izvoru Barzii'
      },
      {
        nume: 'Răscoleşti',
        simplu: 'Rascolesti',
        comuna: 'Izvoru Barzii'
      },
      {
        nume: 'Schinteieşti',
        simplu: 'Schinteiesti',
        comuna: 'Izvoru Barzii'
      },
      {
        nume: 'Schitu Topolniţei',
        simplu: 'Schitu Topolnitei',
        comuna: 'Izvoru Barzii'
      },
      {
        nume: 'Cioroboreni',
        comuna: 'Jiana'
      },
      {
        nume: 'Dănceu',
        simplu: 'Danceu',
        comuna: 'Jiana'
      },
      {
        nume: 'Jiana Mare',
        comuna: 'Jiana'
      },
      {
        nume: 'Jiana Veche',
        comuna: 'Jiana'
      },
      {
        nume: 'Izvoru Aneştilor',
        simplu: 'Izvoru Anestilor',
        comuna: 'Livezile'
      },
      {
        nume: 'Izvorălu de Jos',
        simplu: 'Izvoralu de Jos',
        comuna: 'Livezile'
      },
      {
        nume: 'Petriş',
        simplu: 'Petris',
        comuna: 'Livezile'
      },
      {
        nume: 'Stefan Odobleja',
        simplu: 'Stefan Odobleja',
        comuna: 'Livezile'
      },
      {
        nume: '23 August',
        comuna: 'Malovat'
      },
      {
        nume: 'Bobaiţa',
        simplu: 'Bobaita',
        comuna: 'Malovat'
      },
      {
        nume: 'Bârda',
        simplu: 'Barda',
        comuna: 'Malovat'
      },
      {
        nume: 'Colibaşi',
        simplu: 'Colibasi',
        comuna: 'Malovat'
      },
      {
        nume: 'Lazu',
        comuna: 'Malovat'
      },
      {
        nume: 'Negreşti',
        simplu: 'Negresti',
        comuna: 'Malovat'
      },
      {
        nume: 'Izimşa',
        simplu: 'Izimsa',
        comuna: 'Obarsia De Camp'
      },
      {
        nume: 'Godeanu',
        comuna: 'Obarsia-Closani'
      },
      {
        nume: 'Prisăceaua',
        simplu: 'Prisaceaua',
        comuna: 'Oprisor'
      },
      {
        nume: 'Biban',
        comuna: 'Padina'
      },
      {
        nume: 'Iablaniţa',
        simplu: 'Iablanita',
        comuna: 'Padina'
      },
      {
        nume: 'Olteanca',
        comuna: 'Padina'
      },
      {
        nume: 'Pădina Mare',
        simplu: 'Padina Mare',
        comuna: 'Padina'
      },
      {
        nume: 'Pădina Mică',
        simplu: 'Padina Mica',
        comuna: 'Padina'
      },
      {
        nume: 'Slaşoma',
        simplu: 'Slasoma',
        comuna: 'Padina'
      },
      {
        nume: 'Viaşu',
        simplu: 'Viasu',
        comuna: 'Patulele'
      },
      {
        nume: 'Gornenţi',
        simplu: 'Gornenti',
        comuna: 'Podeni'
      },
      {
        nume: 'Mălărişca',
        simplu: 'Malarisca',
        comuna: 'Podeni'
      },
      {
        nume: 'Brânzeni',
        simplu: 'Branzeni',
        comuna: 'Ponoarele'
      },
      {
        nume: 'Buicani',
        comuna: 'Ponoarele'
      },
      {
        nume: 'Bârâiacu',
        simplu: 'Baraiacu',
        comuna: 'Ponoarele'
      },
      {
        nume: 'Băluţa',
        simplu: 'Baluta',
        comuna: 'Ponoarele'
      },
      {
        nume: 'Ceptureni',
        comuna: 'Ponoarele'
      },
      {
        nume: 'Cracu Muntelui',
        comuna: 'Ponoarele'
      },
      {
        nume: 'Delureni',
        comuna: 'Ponoarele'
      },
      {
        nume: 'Gheorgheşti',
        simplu: 'Gheorghesti',
        comuna: 'Ponoarele'
      },
      {
        nume: 'Gărdăneasa',
        simplu: 'Gardaneasa',
        comuna: 'Ponoarele'
      },
      {
        nume: 'Ludu',
        comuna: 'Ponoarele'
      },
      {
        nume: 'Proiteşti',
        simplu: 'Proitesti',
        comuna: 'Ponoarele'
      },
      {
        nume: 'Răiculeşti',
        simplu: 'Raiculesti',
        comuna: 'Ponoarele'
      },
      {
        nume: 'Valea Ursului',
        comuna: 'Ponoarele'
      },
      {
        nume: 'Sipotu',
        simplu: 'Sipotu',
        comuna: 'Ponoarele'
      },
      {
        nume: 'Fântânile Negre',
        simplu: 'Fantanile Negre',
        comuna: 'Poroina Mare'
      },
      {
        nume: 'Stigniţa',
        simplu: 'Stignita',
        comuna: 'Poroina Mare'
      },
      {
        nume: 'Sipotu',
        simplu: 'Sipotu',
        comuna: 'Poroina Mare'
      },
      {
        nume: 'Cozia',
        comuna: 'Pristol'
      },
      {
        nume: 'Arvăteşti',
        simplu: 'Arvatesti',
        comuna: 'Prunisor'
      },
      {
        nume: 'Balota',
        comuna: 'Prunisor'
      },
      {
        nume: 'Bâltanele',
        simplu: 'Baltanele',
        comuna: 'Prunisor'
      },
      {
        nume: 'Cerveniţa',
        simplu: 'Cervenita',
        comuna: 'Prunisor'
      },
      {
        nume: 'Dragoteşti',
        simplu: 'Dragotesti',
        comuna: 'Prunisor'
      },
      {
        nume: 'Fântâna Domnească',
        simplu: 'Fantana Domneasca',
        comuna: 'Prunisor'
      },
      {
        nume: 'Ghelmegioaia',
        comuna: 'Prunisor'
      },
      {
        nume: 'Gutu',
        comuna: 'Prunisor'
      },
      {
        nume: 'Gârniţa',
        simplu: 'Garnita',
        comuna: 'Prunisor'
      },
      {
        nume: 'Igiroasa',
        comuna: 'Prunisor'
      },
      {
        nume: 'Lumnic',
        comuna: 'Prunisor'
      },
      {
        nume: 'Mijarca',
        comuna: 'Prunisor'
      },
      {
        nume: 'Prunaru',
        comuna: 'Prunisor'
      },
      {
        nume: 'Zegaia',
        comuna: 'Prunisor'
      },
      {
        nume: 'Cearângu',
        simplu: 'Cearangu',
        comuna: 'Punghina'
      },
      {
        nume: 'Drincea',
        comuna: 'Punghina'
      },
      {
        nume: 'Măgurele',
        simplu: 'Magurele',
        comuna: 'Punghina'
      },
      {
        nume: 'Recea',
        comuna: 'Punghina'
      },
      {
        nume: 'Satu Nou',
        comuna: 'Punghina'
      },
      {
        nume: 'Poroiniţa',
        simplu: 'Poroinita',
        comuna: 'Rogova'
      },
      {
        nume: 'Cerneţi',
        simplu: 'Cerneti',
        comuna: 'Simian'
      },
      {
        nume: 'Dedoviţa Nouă',
        simplu: 'Dedovita Noua',
        comuna: 'Simian'
      },
      {
        nume: 'Dedoviţa Veche',
        simplu: 'Dedovita Veche',
        comuna: 'Simian'
      },
      {
        nume: 'Dudaşu',
        simplu: 'Dudasu',
        comuna: 'Simian'
      },
      {
        nume: 'Ergheviţa',
        simplu: 'Erghevita',
        comuna: 'Simian'
      },
      {
        nume: 'Poroina',
        comuna: 'Simian'
      },
      {
        nume: 'Valea Copcii',
        comuna: 'Simian'
      },
      {
        nume: 'Ciovârnăşani',
        simplu: 'Ciovarnasani',
        comuna: 'Sisesti'
      },
      {
        nume: 'Cocorova',
        comuna: 'Sisesti'
      },
      {
        nume: 'Crăgueşti',
        simplu: 'Craguesti',
        comuna: 'Sisesti'
      },
      {
        nume: 'Cărămidaru',
        simplu: 'Caramidaru',
        comuna: 'Sisesti'
      },
      {
        nume: 'Noapteşa',
        simplu: 'Noaptesa',
        comuna: 'Sisesti'
      },
      {
        nume: 'Ohaba',
        comuna: 'Sovarna'
      },
      {
        nume: 'Studina',
        comuna: 'Sovarna'
      },
      {
        nume: 'Breznicioara',
        comuna: 'Stangaceaua'
      },
      {
        nume: 'Bârlogeni',
        simplu: 'Barlogeni',
        comuna: 'Stangaceaua'
      },
      {
        nume: 'Cerânganul',
        simplu: 'Ceranganul',
        comuna: 'Stangaceaua'
      },
      {
        nume: 'Faţa Motrului',
        simplu: 'Fata Motrului',
        comuna: 'Stangaceaua'
      },
      {
        nume: 'Poşta Veche',
        simplu: 'Posta Veche',
        comuna: 'Stangaceaua'
      },
      {
        nume: 'Satu Mare',
        comuna: 'Stangaceaua'
      },
      {
        nume: 'Târsa',
        simplu: 'Tarsa',
        comuna: 'Stangaceaua'
      },
      {
        nume: 'Ciochiuţa',
        simplu: 'Ciochiuta',
        comuna: 'Strehaia'
      },
      {
        nume: 'Comanda',
        comuna: 'Strehaia'
      },
      {
        nume: 'Hurduceşti',
        simplu: 'Hurducesti',
        comuna: 'Strehaia'
      },
      {
        nume: 'Lunca Banului',
        comuna: 'Strehaia'
      },
      {
        nume: 'Menţi',
        simplu: 'Menti',
        comuna: 'Strehaia'
      },
      {
        nume: 'Motruleni',
        comuna: 'Strehaia'
      },
      {
        nume: 'Slătinicu Mare',
        simplu: 'Slatinicu Mare',
        comuna: 'Strehaia'
      },
      {
        nume: 'Slătinicu Mic',
        simplu: 'Slatinicu Mic',
        comuna: 'Strehaia'
      },
      {
        nume: 'Stănceşti',
        simplu: 'Stancesti',
        comuna: 'Strehaia'
      },
      {
        nume: 'Adunaţii Teiului',
        simplu: 'Adunatii Teiului',
        comuna: 'Tamna'
      },
      {
        nume: 'Boceni',
        comuna: 'Tamna'
      },
      {
        nume: 'Colareţ',
        simplu: 'Colaret',
        comuna: 'Tamna'
      },
      {
        nume: 'Cremenea',
        comuna: 'Tamna'
      },
      {
        nume: 'Faţa Cremenii',
        simplu: 'Fata Cremenii',
        comuna: 'Tamna'
      },
      {
        nume: 'Izvorălu',
        simplu: 'Izvoralu',
        comuna: 'Tamna'
      },
      {
        nume: 'Manu',
        comuna: 'Tamna'
      },
      {
        nume: 'Pavăţ',
        simplu: 'Pavat',
        comuna: 'Tamna'
      },
      {
        nume: 'Plopi',
        comuna: 'Tamna'
      },
      {
        nume: 'Valea Ursului',
        comuna: 'Tamna'
      },
      {
        nume: 'Braniştea',
        simplu: 'Branistea',
        comuna: 'Vanatori'
      },
      {
        nume: 'Goanţa',
        simplu: 'Goanta',
        comuna: 'Vanatori'
      },
      {
        nume: 'Roşiori',
        simplu: 'Rosiori',
        comuna: 'Vanatori'
      },
      {
        nume: 'Bucura',
        comuna: 'Vanju Mare'
      },
      {
        nume: 'Nicolae Bălcescu',
        simplu: 'Nicolae Balcescu',
        comuna: 'Vanju Mare'
      },
      {
        nume: 'Oreviţa Mare',
        simplu: 'Orevita Mare',
        comuna: 'Vanju Mare'
      },
      {
        nume: 'Traian',
        comuna: 'Vanju Mare'
      },
      {
        nume: 'Hotărani',
        simplu: 'Hotarani',
        comuna: 'Vanjulet'
      },
      {
        nume: 'Almăjel',
        simplu: 'Almajel',
        comuna: 'Vladaia'
      },
      {
        nume: 'Scorila',
        comuna: 'Vladaia'
      },
      {
        nume: 'Stircoviţa',
        simplu: 'Stircovita',
        comuna: 'Vladaia'
      },
      {
        nume: 'Cotoroaia',
        comuna: 'Voloiac'
      },
      {
        nume: 'Lac',
        comuna: 'Voloiac'
      },
      {
        nume: 'Ruptura',
        comuna: 'Voloiac'
      },
      {
        nume: 'Sperleşti',
        simplu: 'Sperlesti',
        comuna: 'Voloiac'
      },
      {
        nume: 'Valea Bună',
        simplu: 'Valea Buna',
        comuna: 'Voloiac'
      },
      {
        nume: 'Voloicel',
        comuna: 'Voloiac'
      },
      {
        nume: 'Tiţirigi',
        simplu: 'Titirigi',
        comuna: 'Voloiac'
      }
    ]
  },
  {
    auto: 'MS',
    nume: 'Mureş',
    localitati: [
      {
        nume: 'Acăţari',
        simplu: 'Acatari'
      },
      {
        nume: 'Adămuş',
        simplu: 'Adamus'
      },
      {
        nume: 'Albeşti',
        simplu: 'Albesti'
      },
      {
        nume: 'Aluniş',
        simplu: 'Alunis'
      },
      {
        nume: 'Apold'
      },
      {
        nume: 'Aţintiş',
        simplu: 'Atintis'
      },
      {
        nume: 'Bahnea'
      },
      {
        nume: 'Band'
      },
      {
        nume: 'Batoş',
        simplu: 'Batos'
      },
      {
        nume: 'Beica de Jos'
      },
      {
        nume: 'Bichiş',
        simplu: 'Bichis'
      },
      {
        nume: 'Bogata'
      },
      {
        nume: 'Breaza'
      },
      {
        nume: 'Brâncoveneşti',
        simplu: 'Brancovenesti'
      },
      {
        nume: 'Băgaciu',
        simplu: 'Bagaciu'
      },
      {
        nume: 'Băla',
        simplu: 'Bala'
      },
      {
        nume: 'Bălăuşeri',
        simplu: 'Balauseri'
      },
      {
        nume: 'Ceuaşu de Câmpie',
        simplu: 'Ceuasu de Campie'
      },
      {
        nume: 'Cheţani',
        simplu: 'Chetani'
      },
      {
        nume: 'Chibed'
      },
      {
        nume: 'Chiheru de Jos'
      },
      {
        nume: 'Coroisânmărtin',
        simplu: 'Coroisanmartin'
      },
      {
        nume: 'Cozma'
      },
      {
        nume: 'Cristeşti',
        simplu: 'Cristesti'
      },
      {
        nume: 'Crăciuneşti',
        simplu: 'Craciunesti'
      },
      {
        nume: 'Crăieşti',
        simplu: 'Craiesti'
      },
      {
        nume: 'Cucerdea'
      },
      {
        nume: 'Cuci'
      },
      {
        nume: 'Daneş',
        simplu: 'Danes'
      },
      {
        nume: 'Deda'
      },
      {
        nume: 'Eremitu'
      },
      {
        nume: 'Ernei'
      },
      {
        nume: 'Fântânele',
        simplu: 'Fantanele'
      },
      {
        nume: 'Fărăgău',
        simplu: 'Faragau'
      },
      {
        nume: 'Gheorghe Doja'
      },
      {
        nume: 'Ghindari'
      },
      {
        nume: 'Glodeni'
      },
      {
        nume: 'Gorneşti',
        simplu: 'Gornesti'
      },
      {
        nume: 'Grebenişu de Câmpie',
        simplu: 'Grebenisu de Campie'
      },
      {
        nume: 'Gurghiu'
      },
      {
        nume: 'Găleşti',
        simplu: 'Galesti'
      },
      {
        nume: 'Găneşti',
        simplu: 'Ganesti'
      },
      {
        nume: 'Hodac'
      },
      {
        nume: 'Hodoşa',
        simplu: 'Hodosa'
      },
      {
        nume: 'Ibăneşti',
        simplu: 'Ibanesti'
      },
      {
        nume: 'Iclănzel',
        simplu: 'Iclanzel'
      },
      {
        nume: 'Ideciu de Jos'
      },
      {
        nume: 'Iernut'
      },
      {
        nume: 'Livezeni'
      },
      {
        nume: 'Luduş',
        simplu: 'Ludus'
      },
      {
        nume: 'Lunca'
      },
      {
        nume: 'Lunca Bradului'
      },
      {
        nume: 'Mica'
      },
      {
        nume: 'Miercurea Nirajului'
      },
      {
        nume: 'Miheşu de Câmpie',
        simplu: 'Mihesu de Campie'
      },
      {
        nume: 'Măgherani',
        simplu: 'Magherani'
      },
      {
        nume: 'Nadeş',
        simplu: 'Nades'
      },
      {
        nume: 'Neaua'
      },
      {
        nume: 'Ogra'
      },
      {
        nume: 'Papiu Ilarian'
      },
      {
        nume: 'Petelea'
      },
      {
        nume: 'Pogăceaua',
        simplu: 'Pogaceaua'
      },
      {
        nume: 'Pănet',
        simplu: 'Panet'
      },
      {
        nume: 'Păsăreni',
        simplu: 'Pasareni'
      },
      {
        nume: 'Reghin'
      },
      {
        nume: 'Ruşii-Munţi',
        simplu: 'Rusii-Munti'
      },
      {
        nume: 'Răstoliţa',
        simplu: 'Rastolita'
      },
      {
        nume: 'Sarmaşu',
        simplu: 'Sarmasu'
      },
      {
        nume: 'Saschiz'
      },
      {
        nume: 'Sighişoara',
        simplu: 'Sighisoara'
      },
      {
        nume: 'Solovăstru',
        simplu: 'Solovastru'
      },
      {
        nume: 'Sovata'
      },
      {
        nume: 'Stânceni',
        simplu: 'Stanceni'
      },
      {
        nume: 'Suplac'
      },
      {
        nume: 'Suseni'
      },
      {
        nume: 'Sâncraiu de Mureş',
        simplu: 'Sancraiu de Mures'
      },
      {
        nume: 'Sângeorgiu de Mureş',
        simplu: 'Sangeorgiu de Mures'
      },
      {
        nume: 'Sângeorgiu de Pădure',
        simplu: 'Sangeorgiu de Padure'
      },
      {
        nume: 'Sânger',
        simplu: 'Sanger'
      },
      {
        nume: 'Sânpaul',
        simplu: 'Sanpaul'
      },
      {
        nume: 'Sânpetru de Câmpie',
        simplu: 'Sanpetru de Campie'
      },
      {
        nume: 'Sântana de Mureş',
        simplu: 'Santana de Mures'
      },
      {
        nume: 'Târgu Mureş',
        simplu: 'Targu Mures'
      },
      {
        nume: 'Târnăveni',
        simplu: 'Tarnaveni'
      },
      {
        nume: 'Tăureni',
        simplu: 'Taureni'
      },
      {
        nume: 'Ungheni'
      },
      {
        nume: 'Valea Largă',
        simplu: 'Valea Larga'
      },
      {
        nume: 'Veţca',
        simplu: 'Vetca'
      },
      {
        nume: 'Viişoara',
        simplu: 'Viisoara'
      },
      {
        nume: 'Voivodeni'
      },
      {
        nume: 'Vânători',
        simplu: 'Vanatori'
      },
      {
        nume: 'Vărgata',
        simplu: 'Vargata'
      },
      {
        nume: 'Vătava',
        simplu: 'Vatava'
      },
      {
        nume: 'Zagăr',
        simplu: 'Zagar'
      },
      {
        nume: 'Zau de Câmpie',
        simplu: 'Zau de Campie'
      },
      {
        nume: 'Sincai',
        simplu: 'Sincai'
      },
      {
        nume: 'Săulia',
        simplu: 'Saulia'
      },
      {
        nume: 'Corbeşti',
        simplu: 'Corbesti',
        comuna: 'Acatari'
      },
      {
        nume: 'Gruişor',
        simplu: 'Gruisor',
        comuna: 'Acatari'
      },
      {
        nume: 'Găieşti',
        simplu: 'Gaiesti',
        comuna: 'Acatari'
      },
      {
        nume: 'Murgeşti',
        simplu: 'Murgesti',
        comuna: 'Acatari'
      },
      {
        nume: 'Roteni',
        comuna: 'Acatari'
      },
      {
        nume: 'Stejeriş',
        simplu: 'Stejeris',
        comuna: 'Acatari'
      },
      {
        nume: 'Suveica',
        comuna: 'Acatari'
      },
      {
        nume: 'Vălenii',
        simplu: 'Valenii',
        comuna: 'Acatari'
      },
      {
        nume: 'Chinciuş',
        simplu: 'Chincius',
        comuna: 'Adamus'
      },
      {
        nume: 'Corneşti',
        simplu: 'Cornesti',
        comuna: 'Adamus'
      },
      {
        nume: 'Crăieşti',
        simplu: 'Craiesti',
        comuna: 'Adamus'
      },
      {
        nume: 'Dâmbău',
        simplu: 'Dambau',
        comuna: 'Adamus'
      },
      {
        nume: 'Herepea',
        comuna: 'Adamus'
      },
      {
        nume: 'Boiu',
        comuna: 'Albesti'
      },
      {
        nume: 'Bârlibăşoaia',
        simplu: 'Barlibasoaia',
        comuna: 'Albesti'
      },
      {
        nume: 'Jacu',
        comuna: 'Albesti'
      },
      {
        nume: 'Valea Albeştiului',
        simplu: 'Valea Albestiului',
        comuna: 'Albesti'
      },
      {
        nume: 'Valea Dăii',
        simplu: 'Valea Daii',
        comuna: 'Albesti'
      },
      {
        nume: 'Valea Sapartocului',
        simplu: 'Valea Sapartocului',
        comuna: 'Albesti'
      },
      {
        nume: 'Sapartoc',
        simplu: 'Sapartoc',
        comuna: 'Albesti'
      },
      {
        nume: 'Topa',
        simplu: 'Topa',
        comuna: 'Albesti'
      },
      {
        nume: 'Fiţcău',
        simplu: 'Fitcau',
        comuna: 'Alunis'
      },
      {
        nume: 'Lunca Mureşului',
        simplu: 'Lunca Muresului',
        comuna: 'Alunis'
      },
      {
        nume: 'Daia',
        comuna: 'Apold'
      },
      {
        nume: 'Vulcan',
        comuna: 'Apold'
      },
      {
        nume: 'Saeş',
        simplu: 'Saes',
        comuna: 'Apold'
      },
      {
        nume: 'Botez',
        comuna: 'Atintis'
      },
      {
        nume: 'Cecălaca',
        simplu: 'Cecalaca',
        comuna: 'Atintis'
      },
      {
        nume: 'Iştihaza',
        simplu: 'Istihaza',
        comuna: 'Atintis'
      },
      {
        nume: 'Maldaoci',
        comuna: 'Atintis'
      },
      {
        nume: 'Sâniacob',
        simplu: 'Saniacob',
        comuna: 'Atintis'
      },
      {
        nume: 'Delenii',
        comuna: 'Bagaciu'
      },
      {
        nume: 'Bernadea',
        comuna: 'Bahnea'
      },
      {
        nume: 'Cund',
        comuna: 'Bahnea'
      },
      {
        nume: 'Daia',
        comuna: 'Bahnea'
      },
      {
        nume: 'Gogan',
        comuna: 'Bahnea'
      },
      {
        nume: 'Idiciu',
        comuna: 'Bahnea'
      },
      {
        nume: 'Lepindea',
        comuna: 'Bahnea'
      },
      {
        nume: 'Ercea',
        comuna: 'Bala'
      },
      {
        nume: 'Agrişteu',
        simplu: 'Agristeu',
        comuna: 'Balauseri'
      },
      {
        nume: 'Chendu',
        comuna: 'Balauseri'
      },
      {
        nume: 'Dumitreni',
        comuna: 'Balauseri'
      },
      {
        nume: 'Filitelnic',
        comuna: 'Balauseri'
      },
      {
        nume: 'Senereuş',
        simplu: 'Senereus',
        comuna: 'Balauseri'
      },
      {
        nume: 'Drăculea Bandului',
        simplu: 'Draculea Bandului',
        comuna: 'Band'
      },
      {
        nume: 'Fânaţe',
        simplu: 'Fanate',
        comuna: 'Band'
      },
      {
        nume: 'Fânaţele Mădăraşului',
        simplu: 'Fanatele Madarasului',
        comuna: 'Band'
      },
      {
        nume: 'Iştan-Tău',
        simplu: 'Istan-Tau',
        comuna: 'Band'
      },
      {
        nume: 'Mădăraş',
        simplu: 'Madaras',
        comuna: 'Band'
      },
      {
        nume: 'Mărăşeşti',
        simplu: 'Marasesti',
        comuna: 'Band'
      },
      {
        nume: 'Negrenii de Câmpie',
        simplu: 'Negrenii de Campie',
        comuna: 'Band'
      },
      {
        nume: 'Oroiu',
        comuna: 'Band'
      },
      {
        nume: 'Petea',
        comuna: 'Band'
      },
      {
        nume: 'Valea Mare',
        comuna: 'Band'
      },
      {
        nume: 'Valea Rece',
        comuna: 'Band'
      },
      {
        nume: 'Tiptelnic',
        simplu: 'Tiptelnic',
        comuna: 'Band'
      },
      {
        nume: 'Dedrad',
        comuna: 'Batos'
      },
      {
        nume: 'Goreni',
        comuna: 'Batos'
      },
      {
        nume: 'Uila',
        comuna: 'Batos'
      },
      {
        nume: 'Beica de Sus',
        comuna: 'Beica De Jos'
      },
      {
        nume: 'Căcuciu',
        simplu: 'Cacuciu',
        comuna: 'Beica De Jos'
      },
      {
        nume: 'Nadăşa',
        simplu: 'Nadasa',
        comuna: 'Beica De Jos'
      },
      {
        nume: 'Sânmihai de Pădure',
        simplu: 'Sanmihai de Padure',
        comuna: 'Beica De Jos'
      },
      {
        nume: 'Serbeni',
        simplu: 'Serbeni',
        comuna: 'Beica De Jos'
      },
      {
        nume: 'Gâmbuţ',
        simplu: 'Gambut',
        comuna: 'Bichis'
      },
      {
        nume: 'Nandra',
        comuna: 'Bichis'
      },
      {
        nume: 'Ozd',
        comuna: 'Bichis'
      },
      {
        nume: 'Ranta',
        comuna: 'Bogata'
      },
      {
        nume: 'Idicel',
        comuna: 'Brancovenesti'
      },
      {
        nume: 'Idicel-Pădure',
        simplu: 'Idicel-Padure',
        comuna: 'Brancovenesti'
      },
      {
        nume: 'Săcalu de Pădure',
        simplu: 'Sacalu de Padure',
        comuna: 'Brancovenesti'
      },
      {
        nume: 'Vălenii de Mureş',
        simplu: 'Valenii de Mures',
        comuna: 'Brancovenesti'
      },
      {
        nume: 'Filpişu Mare',
        simplu: 'Filpisu Mare',
        comuna: 'Breaza'
      },
      {
        nume: 'Filpişu Mic',
        simplu: 'Filpisu Mic',
        comuna: 'Breaza'
      },
      {
        nume: 'Bozed',
        comuna: 'Ceuasu De Campie'
      },
      {
        nume: 'Culpiu',
        comuna: 'Ceuasu De Campie'
      },
      {
        nume: 'Câmpeniţa',
        simplu: 'Campenita',
        comuna: 'Ceuasu De Campie'
      },
      {
        nume: 'Herghelia',
        comuna: 'Ceuasu De Campie'
      },
      {
        nume: 'Porumbeni',
        comuna: 'Ceuasu De Campie'
      },
      {
        nume: 'Săbed',
        simplu: 'Sabed',
        comuna: 'Ceuasu De Campie'
      },
      {
        nume: 'Voiniceni',
        comuna: 'Ceuasu De Campie'
      },
      {
        nume: 'Coasta Grindului',
        comuna: 'Chetani'
      },
      {
        nume: 'Cordoş',
        simplu: 'Cordos',
        comuna: 'Chetani'
      },
      {
        nume: 'Giurgiş',
        simplu: 'Giurgis',
        comuna: 'Chetani'
      },
      {
        nume: 'Grindeni',
        comuna: 'Chetani'
      },
      {
        nume: 'Hădăreni',
        simplu: 'Hadareni',
        comuna: 'Chetani'
      },
      {
        nume: 'Linţ',
        simplu: 'Lint',
        comuna: 'Chetani'
      },
      {
        nume: 'Chiheru de Sus',
        comuna: 'Chiheru De Jos'
      },
      {
        nume: 'Urisiu de Jos',
        comuna: 'Chiheru De Jos'
      },
      {
        nume: 'Urisiu de Sus',
        comuna: 'Chiheru De Jos'
      },
      {
        nume: 'Coroi',
        comuna: 'Coroisanmartin'
      },
      {
        nume: 'Odrihei',
        comuna: 'Coroisanmartin'
      },
      {
        nume: 'Soimuş',
        simplu: 'Soimus',
        comuna: 'Coroisanmartin'
      },
      {
        nume: 'Fânaţele Socolului',
        simplu: 'Fanatele Socolului',
        comuna: 'Cozma'
      },
      {
        nume: 'Socolu de Câmpie',
        simplu: 'Socolu de Campie',
        comuna: 'Cozma'
      },
      {
        nume: 'Valea Sasului',
        comuna: 'Cozma'
      },
      {
        nume: 'Valea Ungurului',
        comuna: 'Cozma'
      },
      {
        nume: 'Budiu Mic',
        comuna: 'Craciunesti'
      },
      {
        nume: 'Cinta',
        comuna: 'Craciunesti'
      },
      {
        nume: 'Corneşti',
        simplu: 'Cornesti',
        comuna: 'Craciunesti'
      },
      {
        nume: 'Tirimioara',
        comuna: 'Craciunesti'
      },
      {
        nume: 'Lefaia',
        comuna: 'Craiesti'
      },
      {
        nume: 'Milăşel',
        simplu: 'Milasel',
        comuna: 'Craiesti'
      },
      {
        nume: 'Nima Milăşelului',
        simplu: 'Nima Milaselului',
        comuna: 'Craiesti'
      },
      {
        nume: 'Vălureni',
        simplu: 'Valureni',
        comuna: 'Cristesti'
      },
      {
        nume: 'Ciba',
        comuna: 'Crăciunești'
      },
      {
        nume: 'Foi',
        comuna: 'Crăciunești'
      },
      {
        nume: 'Nicoleşti',
        simplu: 'Nicolesti',
        comuna: 'Crăciunești'
      },
      {
        nume: 'Bord',
        comuna: 'Cucerdea'
      },
      {
        nume: 'Seulia de Mureş',
        simplu: 'Seulia de Mures',
        comuna: 'Cucerdea'
      },
      {
        nume: 'După Deal',
        simplu: 'Dupa Deal',
        comuna: 'Cuci'
      },
      {
        nume: 'Dătăşeni',
        simplu: 'Dataseni',
        comuna: 'Cuci'
      },
      {
        nume: 'Orosia',
        comuna: 'Cuci'
      },
      {
        nume: 'Petrilaca',
        comuna: 'Cuci'
      },
      {
        nume: 'Criş',
        simplu: 'Cris',
        comuna: 'Danes'
      },
      {
        nume: 'Seleuş',
        simplu: 'Seleus',
        comuna: 'Danes'
      },
      {
        nume: 'Stejărenii',
        simplu: 'Stejarenii',
        comuna: 'Danes'
      },
      {
        nume: 'Bistra Mureşului',
        simplu: 'Bistra Muresului',
        comuna: 'Deda'
      },
      {
        nume: 'Filea',
        comuna: 'Deda'
      },
      {
        nume: 'Pietriş',
        simplu: 'Pietris',
        comuna: 'Deda'
      },
      {
        nume: 'Câmpu Cetăţii',
        simplu: 'Campu Cetatii',
        comuna: 'Eremitu'
      },
      {
        nume: 'Călugăreni',
        simplu: 'Calugareni',
        comuna: 'Eremitu'
      },
      {
        nume: 'Dămieni',
        simplu: 'Damieni',
        comuna: 'Eremitu'
      },
      {
        nume: 'Mătrici',
        simplu: 'Matrici',
        comuna: 'Eremitu'
      },
      {
        nume: 'Căluşeri',
        simplu: 'Caluseri',
        comuna: 'Ernei'
      },
      {
        nume: 'Dumbrăvioara',
        simplu: 'Dumbravioara',
        comuna: 'Ernei'
      },
      {
        nume: 'Icland',
        comuna: 'Ernei'
      },
      {
        nume: 'Sângeru de Pădure',
        simplu: 'Sangeru de Padure',
        comuna: 'Ernei'
      },
      {
        nume: 'Săcăreni',
        simplu: 'Sacareni',
        comuna: 'Ernei'
      },
      {
        nume: 'Bordoşiu',
        simplu: 'Bordosiu',
        comuna: 'Fantanele'
      },
      {
        nume: 'Cibu',
        comuna: 'Fantanele'
      },
      {
        nume: 'Călimăneşti',
        simplu: 'Calimanesti',
        comuna: 'Fantanele'
      },
      {
        nume: 'Roua',
        comuna: 'Fantanele'
      },
      {
        nume: 'Viforoasa',
        comuna: 'Fantanele'
      },
      {
        nume: 'Fânaţe',
        simplu: 'Fanate',
        comuna: 'Faragau'
      },
      {
        nume: 'Hodaia',
        comuna: 'Faragau'
      },
      {
        nume: 'Onuca',
        comuna: 'Faragau'
      },
      {
        nume: 'Poarta',
        comuna: 'Faragau'
      },
      {
        nume: 'Tonciu',
        comuna: 'Faragau'
      },
      {
        nume: 'Adrianu Mare',
        comuna: 'Galesti'
      },
      {
        nume: 'Adrianu Mic',
        comuna: 'Galesti'
      },
      {
        nume: 'Bedeni',
        comuna: 'Galesti'
      },
      {
        nume: 'Maiad',
        comuna: 'Galesti'
      },
      {
        nume: 'Troiţa',
        simplu: 'Troita',
        comuna: 'Galesti'
      },
      {
        nume: 'Păucişoara',
        simplu: 'Paucisoara',
        comuna: 'Ganesti'
      },
      {
        nume: 'Sub Pădure',
        simplu: 'Sub Padure',
        comuna: 'Ganesti'
      },
      {
        nume: 'Ilieni',
        comuna: 'Gheorghe Doja'
      },
      {
        nume: 'Leordeni',
        comuna: 'Gheorghe Doja'
      },
      {
        nume: 'Satu Nou',
        comuna: 'Gheorghe Doja'
      },
      {
        nume: 'Tirimia',
        comuna: 'Gheorghe Doja'
      },
      {
        nume: 'Abud',
        comuna: 'Ghindari'
      },
      {
        nume: 'Ceie',
        comuna: 'Ghindari'
      },
      {
        nume: 'Solocma',
        comuna: 'Ghindari'
      },
      {
        nume: 'Trei Sate',
        comuna: 'Ghindari'
      },
      {
        nume: 'Merişor',
        simplu: 'Merisor',
        comuna: 'Glodeni'
      },
      {
        nume: 'Moişa',
        simplu: 'Moisa',
        comuna: 'Glodeni'
      },
      {
        nume: 'Păcureni',
        simplu: 'Pacureni',
        comuna: 'Glodeni'
      },
      {
        nume: 'Păingeni',
        simplu: 'Paingeni',
        comuna: 'Glodeni'
      },
      {
        nume: 'Iara de Mureş',
        simplu: 'Iara de Mures',
        comuna: 'Gornesti'
      },
      {
        nume: 'Ilioara',
        comuna: 'Gornesti'
      },
      {
        nume: 'Mura Mare',
        comuna: 'Gornesti'
      },
      {
        nume: 'Mura Mică',
        simplu: 'Mura Mica',
        comuna: 'Gornesti'
      },
      {
        nume: 'Periş',
        simplu: 'Peris',
        comuna: 'Gornesti'
      },
      {
        nume: 'Petrilaca de Mureş',
        simplu: 'Petrilaca de Mures',
        comuna: 'Gornesti'
      },
      {
        nume: 'Pădureni',
        simplu: 'Padureni',
        comuna: 'Gornesti'
      },
      {
        nume: 'Teleac',
        comuna: 'Gornesti'
      },
      {
        nume: 'Leorinţa',
        simplu: 'Leorinta',
        comuna: 'Grebenisu De Campie'
      },
      {
        nume: 'Valea Sânpetrului',
        simplu: 'Valea Sanpetrului',
        comuna: 'Grebenisu De Campie'
      },
      {
        nume: 'Adrian',
        comuna: 'Gurghiu'
      },
      {
        nume: 'Caşva',
        simplu: 'Casva',
        comuna: 'Gurghiu'
      },
      {
        nume: 'Comori',
        comuna: 'Gurghiu'
      },
      {
        nume: 'Fundoaia',
        comuna: 'Gurghiu'
      },
      {
        nume: 'Glăjărie',
        simplu: 'Glajarie',
        comuna: 'Gurghiu'
      },
      {
        nume: 'Larga',
        comuna: 'Gurghiu'
      },
      {
        nume: 'Orşova',
        simplu: 'Orsova',
        comuna: 'Gurghiu'
      },
      {
        nume: 'Orşova-Pădure',
        simplu: 'Orsova-Padure',
        comuna: 'Gurghiu'
      },
      {
        nume: 'Păuloaia',
        simplu: 'Pauloaia',
        comuna: 'Gurghiu'
      },
      {
        nume: 'Sânvăsii',
        simplu: 'Sanvasii',
        comuna: 'Gălești'
      },
      {
        nume: 'Seuca',
        comuna: 'Gănești'
      },
      {
        nume: 'Arşiţa',
        simplu: 'Arsita',
        comuna: 'Hodac'
      },
      {
        nume: 'Bicaşu',
        simplu: 'Bicasu',
        comuna: 'Hodac'
      },
      {
        nume: 'Dubiştea de Pădure',
        simplu: 'Dubistea de Padure',
        comuna: 'Hodac'
      },
      {
        nume: 'Mirigioaia',
        comuna: 'Hodac'
      },
      {
        nume: 'Toaca',
        comuna: 'Hodac'
      },
      {
        nume: 'Uricea',
        comuna: 'Hodac'
      },
      {
        nume: 'Ihod',
        comuna: 'Hodosa'
      },
      {
        nume: 'Isla',
        comuna: 'Hodosa'
      },
      {
        nume: 'Sâmbriaş',
        simplu: 'Sambrias',
        comuna: 'Hodosa'
      },
      {
        nume: 'Blidireasa',
        comuna: 'Ibanesti'
      },
      {
        nume: 'Brădeţelu',
        simplu: 'Bradetelu',
        comuna: 'Ibanesti'
      },
      {
        nume: 'Dulcea',
        comuna: 'Ibanesti'
      },
      {
        nume: 'Ibăneşti-Pădure',
        simplu: 'Ibanesti-Padure',
        comuna: 'Ibanesti'
      },
      {
        nume: 'Lăpuşna',
        simplu: 'Lapusna',
        comuna: 'Ibanesti'
      },
      {
        nume: 'Pârâu Mare',
        simplu: 'Parau Mare',
        comuna: 'Ibanesti'
      },
      {
        nume: 'Tireu',
        comuna: 'Ibanesti'
      },
      {
        nume: 'Tisieu',
        comuna: 'Ibanesti'
      },
      {
        nume: 'Zimţi',
        simplu: 'Zimti',
        comuna: 'Ibanesti'
      },
      {
        nume: 'Chisăliţa',
        simplu: 'Chisalita',
        comuna: 'Iclanzel'
      },
      {
        nume: 'Căpuşu de Câmpie',
        simplu: 'Capusu de Campie',
        comuna: 'Iclanzel'
      },
      {
        nume: 'După Deal',
        simplu: 'Dupa Deal',
        comuna: 'Iclanzel'
      },
      {
        nume: 'Fânaţe',
        simplu: 'Fanate',
        comuna: 'Iclanzel'
      },
      {
        nume: 'Fânaţele Căpuşului',
        simplu: 'Fanatele Capusului',
        comuna: 'Iclanzel'
      },
      {
        nume: 'Ghidaşteu',
        simplu: 'Ghidasteu',
        comuna: 'Iclanzel'
      },
      {
        nume: 'Iclandu Mare',
        comuna: 'Iclanzel'
      },
      {
        nume: 'Mădărăşeni',
        simplu: 'Madaraseni',
        comuna: 'Iclanzel'
      },
      {
        nume: 'Tăblăşeni',
        simplu: 'Tablaseni',
        comuna: 'Iclanzel'
      },
      {
        nume: 'Valea Iclandului',
        comuna: 'Iclanzel'
      },
      {
        nume: 'Deleni',
        comuna: 'Ideciu De Jos'
      },
      {
        nume: 'Ideciu de Sus',
        comuna: 'Ideciu De Jos'
      },
      {
        nume: 'Cipău',
        simplu: 'Cipau',
        comuna: 'Iernut'
      },
      {
        nume: 'Deag',
        comuna: 'Iernut'
      },
      {
        nume: 'Lechinţa',
        simplu: 'Lechinta',
        comuna: 'Iernut'
      },
      {
        nume: 'Oarba de Mureş',
        simplu: 'Oarba de Mures',
        comuna: 'Iernut'
      },
      {
        nume: 'Porumbac',
        comuna: 'Iernut'
      },
      {
        nume: 'Racameţ',
        simplu: 'Racamet',
        comuna: 'Iernut'
      },
      {
        nume: 'Sfântu Gheorghe',
        simplu: 'Sfantu Gheorghe',
        comuna: 'Iernut'
      },
      {
        nume: 'Sălcud',
        simplu: 'Salcud',
        comuna: 'Iernut'
      },
      {
        nume: 'Bozeni',
        comuna: 'Livezeni'
      },
      {
        nume: 'Corunca',
        comuna: 'Livezeni'
      },
      {
        nume: 'Ivăneşti',
        simplu: 'Ivanesti',
        comuna: 'Livezeni'
      },
      {
        nume: 'Poieniţa',
        simplu: 'Poienita',
        comuna: 'Livezeni'
      },
      {
        nume: 'Sânişor',
        simplu: 'Sanisor',
        comuna: 'Livezeni'
      },
      {
        nume: 'Avrămeşti',
        simplu: 'Avramesti',
        comuna: 'Ludus'
      },
      {
        nume: 'Cioarga',
        comuna: 'Ludus'
      },
      {
        nume: 'Ciurgău',
        simplu: 'Ciurgau',
        comuna: 'Ludus'
      },
      {
        nume: 'Fundătura',
        simplu: 'Fundatura',
        comuna: 'Ludus'
      },
      {
        nume: 'Gheja',
        comuna: 'Ludus'
      },
      {
        nume: 'Roşiori',
        simplu: 'Rosiori',
        comuna: 'Ludus'
      },
      {
        nume: 'Băiţa',
        simplu: 'Baita',
        comuna: 'Lunca'
      },
      {
        nume: 'Frunzeni',
        comuna: 'Lunca'
      },
      {
        nume: 'Logig',
        comuna: 'Lunca'
      },
      {
        nume: 'Sântu',
        simplu: 'Santu',
        comuna: 'Lunca'
      },
      {
        nume: 'Neagra',
        comuna: 'Lunca Bradului'
      },
      {
        nume: 'Sălard',
        simplu: 'Salard',
        comuna: 'Lunca Bradului'
      },
      {
        nume: 'Bereni',
        comuna: 'Magherani'
      },
      {
        nume: 'Bâra',
        simplu: 'Bara',
        comuna: 'Magherani'
      },
      {
        nume: 'Cându',
        simplu: 'Candu',
        comuna: 'Magherani'
      },
      {
        nume: 'Drojdii',
        comuna: 'Magherani'
      },
      {
        nume: 'Eremieni',
        comuna: 'Magherani'
      },
      {
        nume: 'Maia',
        comuna: 'Magherani'
      },
      {
        nume: 'Mărculeni',
        simplu: 'Marculeni',
        comuna: 'Magherani'
      },
      {
        nume: 'Torba',
        comuna: 'Magherani'
      },
      {
        nume: 'Silea Nirajului',
        simplu: 'Silea Nirajului',
        comuna: 'Magherani'
      },
      {
        nume: 'Abuş',
        simplu: 'Abus',
        comuna: 'Mica'
      },
      {
        nume: 'Ceuaş',
        simplu: 'Ceuas',
        comuna: 'Mica'
      },
      {
        nume: 'Căpâlna de Sus',
        simplu: 'Capalna de Sus',
        comuna: 'Mica'
      },
      {
        nume: 'Deaj',
        comuna: 'Mica'
      },
      {
        nume: 'Hărănglab',
        simplu: 'Haranglab',
        comuna: 'Mica'
      },
      {
        nume: 'Somoştelnic',
        simplu: 'Somostelnic',
        comuna: 'Mica'
      },
      {
        nume: 'Beu',
        comuna: 'Miercurea Nirajului'
      },
      {
        nume: 'Dumitreştii',
        simplu: 'Dumitrestii',
        comuna: 'Miercurea Nirajului'
      },
      {
        nume: 'Lăureni',
        simplu: 'Laureni',
        comuna: 'Miercurea Nirajului'
      },
      {
        nume: 'Moşuni',
        simplu: 'Mosuni',
        comuna: 'Miercurea Nirajului'
      },
      {
        nume: 'Tâmpa',
        simplu: 'Tampa',
        comuna: 'Miercurea Nirajului'
      },
      {
        nume: 'Veţa',
        simplu: 'Veta',
        comuna: 'Miercurea Nirajului'
      },
      {
        nume: 'Sardu Nirajului',
        simplu: 'Sardu Nirajului',
        comuna: 'Miercurea Nirajului'
      },
      {
        nume: 'Bujor',
        comuna: 'Mihesu De Campie'
      },
      {
        nume: 'Cirhagău',
        simplu: 'Cirhagau',
        comuna: 'Mihesu De Campie'
      },
      {
        nume: 'Groapa Rădăii',
        simplu: 'Groapa Radaii',
        comuna: 'Mihesu De Campie'
      },
      {
        nume: 'Mogoaia',
        comuna: 'Mihesu De Campie'
      },
      {
        nume: 'Răzoare',
        simplu: 'Razoare',
        comuna: 'Mihesu De Campie'
      },
      {
        nume: 'Stefanca',
        simplu: 'Stefanca',
        comuna: 'Mihesu De Campie'
      },
      {
        nume: 'Săuliţa',
        simplu: 'Saulita',
        comuna: 'Mihesu De Campie'
      },
      {
        nume: 'Măgheruş',
        simplu: 'Magherus',
        comuna: 'Nades'
      },
      {
        nume: 'Pipea',
        comuna: 'Nades'
      },
      {
        nume: 'Tigmandru',
        simplu: 'Tigmandru',
        comuna: 'Nades'
      },
      {
        nume: 'Ghineşti',
        simplu: 'Ghinesti',
        comuna: 'Neaua'
      },
      {
        nume: 'Rigmani',
        comuna: 'Neaua'
      },
      {
        nume: 'Sânsimion',
        simplu: 'Sansimion',
        comuna: 'Neaua'
      },
      {
        nume: 'Vădaş',
        simplu: 'Vadas',
        comuna: 'Neaua'
      },
      {
        nume: 'Dileu Vechi',
        comuna: 'Ogra'
      },
      {
        nume: 'Giuluş',
        simplu: 'Giulus',
        comuna: 'Ogra'
      },
      {
        nume: 'Lăscud',
        simplu: 'Lascud',
        comuna: 'Ogra'
      },
      {
        nume: 'Vaideiu',
        comuna: 'Ogra'
      },
      {
        nume: 'Berghia',
        comuna: 'Panet'
      },
      {
        nume: 'Cuieşd',
        simplu: 'Cuiesd',
        comuna: 'Panet'
      },
      {
        nume: 'Hărţău',
        simplu: 'Hartau',
        comuna: 'Panet'
      },
      {
        nume: 'Sântioana de Mureş',
        simplu: 'Santioana de Mures',
        comuna: 'Panet'
      },
      {
        nume: 'Dobra',
        comuna: 'Papiu Ilarian'
      },
      {
        nume: 'Merişoru',
        simplu: 'Merisoru',
        comuna: 'Papiu Ilarian'
      },
      {
        nume: 'Ursoaia',
        comuna: 'Papiu Ilarian'
      },
      {
        nume: 'Sandru',
        simplu: 'Sandru',
        comuna: 'Papiu Ilarian'
      },
      {
        nume: 'Bolintineni',
        comuna: 'Pasareni'
      },
      {
        nume: 'Gălăţeni',
        simplu: 'Galateni',
        comuna: 'Pasareni'
      },
      {
        nume: 'Habic',
        comuna: 'Petelea'
      },
      {
        nume: 'Bologaia',
        comuna: 'Pogaceaua'
      },
      {
        nume: 'Ciulea',
        comuna: 'Pogaceaua'
      },
      {
        nume: 'Deleni',
        comuna: 'Pogaceaua'
      },
      {
        nume: 'Fântâna Babii',
        simplu: 'Fantana Babii',
        comuna: 'Pogaceaua'
      },
      {
        nume: 'Pârâu Crucii',
        simplu: 'Parau Crucii',
        comuna: 'Pogaceaua'
      },
      {
        nume: 'Scurta',
        comuna: 'Pogaceaua'
      },
      {
        nume: 'Sicele',
        comuna: 'Pogaceaua'
      },
      {
        nume: 'Valea Sânpetrului',
        simplu: 'Valea Sanpetrului',
        comuna: 'Pogaceaua'
      },
      {
        nume: 'Văleni',
        simplu: 'Valeni',
        comuna: 'Pogaceaua'
      },
      {
        nume: 'Andreneasa',
        comuna: 'Rastolita'
      },
      {
        nume: 'Borzia',
        comuna: 'Rastolita'
      },
      {
        nume: 'Gălăoaia',
        simplu: 'Galaoaia',
        comuna: 'Rastolita'
      },
      {
        nume: 'Iod',
        comuna: 'Rastolita'
      },
      {
        nume: 'Apalina',
        comuna: 'Reghin'
      },
      {
        nume: 'Iernuţeni',
        simplu: 'Iernuteni',
        comuna: 'Reghin'
      },
      {
        nume: 'Coasta Mare',
        comuna: 'Riciu'
      },
      {
        nume: 'Cotorinău',
        simplu: 'Cotorinau',
        comuna: 'Riciu'
      },
      {
        nume: 'Cureţe',
        simplu: 'Curete',
        comuna: 'Riciu'
      },
      {
        nume: 'Căciulata',
        simplu: 'Caciulata',
        comuna: 'Riciu'
      },
      {
        nume: 'Hagău',
        simplu: 'Hagau',
        comuna: 'Riciu'
      },
      {
        nume: 'Leniş',
        simplu: 'Lenis',
        comuna: 'Riciu'
      },
      {
        nume: 'Nima Râciului',
        simplu: 'Nima Raciului',
        comuna: 'Riciu'
      },
      {
        nume: 'Obârşie',
        simplu: 'Obarsie',
        comuna: 'Riciu'
      },
      {
        nume: 'Pârâu Crucii',
        simplu: 'Parau Crucii',
        comuna: 'Riciu'
      },
      {
        nume: 'Râciu',
        simplu: 'Raciu',
        comuna: 'Riciu'
      },
      {
        nume: 'Sânmărtinu de Câmpie',
        simplu: 'Sanmartinu de Campie',
        comuna: 'Riciu'
      },
      {
        nume: 'Ulieş',
        simplu: 'Ulies',
        comuna: 'Riciu'
      },
      {
        nume: 'Valea Seacă',
        simplu: 'Valea Seaca',
        comuna: 'Riciu'
      },
      {
        nume: 'Valea Sânmărtinului',
        simplu: 'Valea Sanmartinului',
        comuna: 'Riciu'
      },
      {
        nume: 'Valea Ulieşului',
        simplu: 'Valea Uliesului',
        comuna: 'Riciu'
      },
      {
        nume: 'Maioreşti',
        simplu: 'Maioresti',
        comuna: 'Rusii-Munti'
      },
      {
        nume: 'Morăreni',
        simplu: 'Morareni',
        comuna: 'Rusii-Munti'
      },
      {
        nume: 'Sebeş',
        simplu: 'Sebes',
        comuna: 'Rusii-Munti'
      },
      {
        nume: 'Nazna',
        comuna: 'Sancraiu De Mures'
      },
      {
        nume: 'Cotuş',
        simplu: 'Cotus',
        comuna: 'Sangeorgiu De Mures'
      },
      {
        nume: 'Tofalău',
        simplu: 'Tofalau',
        comuna: 'Sangeorgiu De Mures'
      },
      {
        nume: 'Bezid',
        comuna: 'Sangeorgiu De Padure'
      },
      {
        nume: 'Bezidu Nou',
        comuna: 'Sangeorgiu De Padure'
      },
      {
        nume: 'Loţu',
        simplu: 'Lotu',
        comuna: 'Sangeorgiu De Padure'
      },
      {
        nume: 'Bârza',
        simplu: 'Barza',
        comuna: 'Sanger'
      },
      {
        nume: 'Cipăieni',
        simplu: 'Cipaieni',
        comuna: 'Sanger'
      },
      {
        nume: 'Dalu',
        comuna: 'Sanger'
      },
      {
        nume: 'Pripoare',
        comuna: 'Sanger'
      },
      {
        nume: 'Vălişoara',
        simplu: 'Valisoara',
        comuna: 'Sanger'
      },
      {
        nume: 'Zăpodea',
        simplu: 'Zapodea',
        comuna: 'Sanger'
      },
      {
        nume: 'Chirileu',
        comuna: 'Sanpaul'
      },
      {
        nume: 'Dileu Nou',
        comuna: 'Sanpaul'
      },
      {
        nume: 'Sânmărghita',
        simplu: 'Sanmarghita',
        comuna: 'Sanpaul'
      },
      {
        nume: 'Valea Izvoarelor',
        comuna: 'Sanpaul'
      },
      {
        nume: 'Bârlibaş',
        simplu: 'Barlibas',
        comuna: 'Sanpetru De Campie'
      },
      {
        nume: 'Dâmbu',
        simplu: 'Dambu',
        comuna: 'Sanpetru De Campie'
      },
      {
        nume: 'Satu Nou',
        comuna: 'Sanpetru De Campie'
      },
      {
        nume: 'Sângeorgiu de Câmpie',
        simplu: 'Sangeorgiu de Campie',
        comuna: 'Sanpetru De Campie'
      },
      {
        nume: 'Tuşinu',
        simplu: 'Tusinu',
        comuna: 'Sanpetru De Campie'
      },
      {
        nume: 'Bărdeşti',
        simplu: 'Bardesti',
        comuna: 'Santana De Mures'
      },
      {
        nume: 'Chinari',
        comuna: 'Santana De Mures'
      },
      {
        nume: 'Curteni',
        comuna: 'Santana De Mures'
      },
      {
        nume: 'Balda',
        comuna: 'Sarmasu'
      },
      {
        nume: 'Larga',
        comuna: 'Sarmasu'
      },
      {
        nume: 'Moruţ',
        simplu: 'Morut',
        comuna: 'Sarmasu'
      },
      {
        nume: 'Sărmăşel',
        simplu: 'Sarmasel',
        comuna: 'Sarmasu'
      },
      {
        nume: 'Sărmăşel-Gară',
        simplu: 'Sarmasel-Gara',
        comuna: 'Sarmasu'
      },
      {
        nume: 'Titiana',
        comuna: 'Sarmasu'
      },
      {
        nume: 'Vişinelu',
        simplu: 'Visinelu',
        comuna: 'Sarmasu'
      },
      {
        nume: 'Cloaşterf',
        simplu: 'Cloasterf',
        comuna: 'Saschiz'
      },
      {
        nume: 'Mihai Viteazu',
        comuna: 'Saschiz'
      },
      {
        nume: 'Leorinţa-Săulia',
        simplu: 'Leorinta-Saulia',
        comuna: 'Saulia'
      },
      {
        nume: 'Măcicăşeşti',
        simplu: 'Macicasesti',
        comuna: 'Saulia'
      },
      {
        nume: 'Pădurea',
        simplu: 'Padurea',
        comuna: 'Saulia'
      },
      {
        nume: 'Angofa',
        comuna: 'Sighisoara'
      },
      {
        nume: 'Aurel Vlaicu',
        comuna: 'Sighisoara'
      },
      {
        nume: 'Hetiur',
        comuna: 'Sighisoara'
      },
      {
        nume: 'Rora',
        comuna: 'Sighisoara'
      },
      {
        nume: 'Venchi',
        comuna: 'Sighisoara'
      },
      {
        nume: 'Viilor',
        comuna: 'Sighisoara'
      },
      {
        nume: 'Soromiclea',
        simplu: 'Soromiclea',
        comuna: 'Sighisoara'
      },
      {
        nume: 'Lechincioara',
        comuna: 'Sincai'
      },
      {
        nume: 'Pusta',
        comuna: 'Sincai'
      },
      {
        nume: 'Sincai-Fânaţe',
        simplu: 'Sincai-Fanate',
        comuna: 'Sincai'
      },
      {
        nume: 'Jabeniţa',
        simplu: 'Jabenita',
        comuna: 'Solovastru'
      },
      {
        nume: 'Căpeţi',
        simplu: 'Capeti',
        comuna: 'Sovata'
      },
      {
        nume: 'Ilieşi',
        simplu: 'Iliesi',
        comuna: 'Sovata'
      },
      {
        nume: 'Săcădat',
        simplu: 'Sacadat',
        comuna: 'Sovata'
      },
      {
        nume: 'Sărăţeni',
        simplu: 'Sarateni',
        comuna: 'Sovata'
      },
      {
        nume: 'Ciobotani',
        comuna: 'Stanceni'
      },
      {
        nume: 'Meştera',
        simplu: 'Mestera',
        comuna: 'Stanceni'
      },
      {
        nume: 'Idrifaia',
        comuna: 'Suplac'
      },
      {
        nume: 'Laslău Mare',
        simplu: 'Laslau Mare',
        comuna: 'Suplac'
      },
      {
        nume: 'Laslău Mic',
        simplu: 'Laslau Mic',
        comuna: 'Suplac'
      },
      {
        nume: 'Vaidacuţa',
        simplu: 'Vaidacuta',
        comuna: 'Suplac'
      },
      {
        nume: 'Luieriu',
        comuna: 'Suseni'
      },
      {
        nume: 'Mureşeni',
        simplu: 'Mureseni',
        comuna: 'Targu Mures'
      },
      {
        nume: 'Remetea',
        comuna: 'Targu Mures'
      },
      {
        nume: 'Bobohalma',
        comuna: 'Tarnaveni'
      },
      {
        nume: 'Botorca',
        comuna: 'Tarnaveni'
      },
      {
        nume: 'Cuştelnic',
        simplu: 'Custelnic',
        comuna: 'Tarnaveni'
      },
      {
        nume: 'Fânaţe',
        simplu: 'Fanate',
        comuna: 'Taureni'
      },
      {
        nume: 'Moara de Jos',
        comuna: 'Taureni'
      },
      {
        nume: 'Cerghid',
        comuna: 'Ungheni'
      },
      {
        nume: 'Cerghizel',
        comuna: 'Ungheni'
      },
      {
        nume: 'Moreşti',
        simplu: 'Moresti',
        comuna: 'Ungheni'
      },
      {
        nume: 'Recea',
        comuna: 'Ungheni'
      },
      {
        nume: 'Vidrasău',
        simplu: 'Vidrasau',
        comuna: 'Ungheni'
      },
      {
        nume: 'Săuşa',
        simplu: 'Sausa',
        comuna: 'Ungheni'
      },
      {
        nume: 'Grădini',
        simplu: 'Gradini',
        comuna: 'Valea Larga'
      },
      {
        nume: 'Mălăeşti',
        simplu: 'Malaesti',
        comuna: 'Valea Larga'
      },
      {
        nume: 'Poduri',
        comuna: 'Valea Larga'
      },
      {
        nume: 'Valea Frăţiei',
        simplu: 'Valea Fratiei',
        comuna: 'Valea Larga'
      },
      {
        nume: 'Valea Glodului',
        comuna: 'Valea Larga'
      },
      {
        nume: 'Valea Pădurii',
        simplu: 'Valea Padurii',
        comuna: 'Valea Larga'
      },
      {
        nume: 'Valea Urieşului',
        simplu: 'Valea Uriesului',
        comuna: 'Valea Larga'
      },
      {
        nume: 'Valea Surii',
        simplu: 'Valea Surii',
        comuna: 'Valea Larga'
      },
      {
        nume: 'Archita',
        comuna: 'Vanatori'
      },
      {
        nume: 'Feleag',
        comuna: 'Vanatori'
      },
      {
        nume: 'Mureni',
        comuna: 'Vanatori'
      },
      {
        nume: 'Soard',
        simplu: 'Soard',
        comuna: 'Vanatori'
      },
      {
        nume: 'Grâuşorul',
        simplu: 'Grausorul',
        comuna: 'Vargata'
      },
      {
        nume: 'Mitreşti',
        simplu: 'Mitresti',
        comuna: 'Vargata'
      },
      {
        nume: 'Vadu',
        comuna: 'Vargata'
      },
      {
        nume: 'Valea',
        comuna: 'Vargata'
      },
      {
        nume: 'Dumbrava',
        comuna: 'Vatava'
      },
      {
        nume: 'Râpa de Jos',
        simplu: 'Rapa de Jos',
        comuna: 'Vatava'
      },
      {
        nume: 'Jacodu',
        comuna: 'Vetca'
      },
      {
        nume: 'Sălaşuri',
        simplu: 'Salasuri',
        comuna: 'Vetca'
      },
      {
        nume: 'Ormeniş',
        simplu: 'Ormenis',
        comuna: 'Viisoara'
      },
      {
        nume: 'Sântioana',
        simplu: 'Santioana',
        comuna: 'Viisoara'
      },
      {
        nume: 'Toldal',
        comuna: 'Voivodeni'
      },
      {
        nume: 'Seleuş',
        simplu: 'Seleus',
        comuna: 'Zagar'
      },
      {
        nume: 'Botei',
        comuna: 'Zau De Campie'
      },
      {
        nume: 'Bujor-Hodaie',
        comuna: 'Zau De Campie'
      },
      {
        nume: 'Bărboşi',
        simplu: 'Barbosi',
        comuna: 'Zau De Campie'
      },
      {
        nume: 'Ciretea',
        comuna: 'Zau De Campie'
      },
      {
        nume: 'Gaura Sângerului',
        simplu: 'Gaura Sangerului',
        comuna: 'Zau De Campie'
      },
      {
        nume: 'Malea',
        comuna: 'Zau De Campie'
      },
      {
        nume: 'Tău',
        simplu: 'Tau',
        comuna: 'Zau De Campie'
      },
      {
        nume: 'Stefăneaca',
        simplu: 'Stefaneaca',
        comuna: 'Zau De Campie'
      }
    ]
  },
  {
    auto: 'NT',
    nume: 'Neamţ',
    localitati: [
      {
        nume: 'Agapia'
      },
      {
        nume: 'Bahna'
      },
      {
        nume: 'Bicaz'
      },
      {
        nume: 'Bicaz-Chei'
      },
      {
        nume: 'Bicazu Ardelean'
      },
      {
        nume: 'Bira'
      },
      {
        nume: 'Bodeşti',
        simplu: 'Bodesti'
      },
      {
        nume: 'Borca'
      },
      {
        nume: 'Borleşti',
        simplu: 'Borlesti'
      },
      {
        nume: 'Boteşti',
        simplu: 'Botesti'
      },
      {
        nume: 'Bozieni'
      },
      {
        nume: 'Bârgauani',
        simplu: 'Bargauani'
      },
      {
        nume: 'Bălţăteşti',
        simplu: 'Baltatesti'
      },
      {
        nume: 'Ceahlău',
        simplu: 'Ceahlau'
      },
      {
        nume: 'Cordun'
      },
      {
        nume: 'Costişa',
        simplu: 'Costisa'
      },
      {
        nume: 'Crăcăoani',
        simplu: 'Cracaoani'
      },
      {
        nume: 'Cândesti',
        simplu: 'Candesti'
      },
      {
        nume: 'Dobreni'
      },
      {
        nume: 'Dochia'
      },
      {
        nume: 'Doljeşti',
        simplu: 'Doljesti'
      },
      {
        nume: 'Dragomireşti',
        simplu: 'Dragomiresti'
      },
      {
        nume: 'Dulceşti',
        simplu: 'Dulcesti'
      },
      {
        nume: 'Dumbrava Roşie',
        simplu: 'Dumbrava Rosie'
      },
      {
        nume: 'Dămuc',
        simplu: 'Damuc'
      },
      {
        nume: 'Farcaşa',
        simplu: 'Farcasa'
      },
      {
        nume: 'Făurei',
        simplu: 'Faurei'
      },
      {
        nume: 'Gherăeşti',
        simplu: 'Gheraesti'
      },
      {
        nume: 'Ghindăoani',
        simplu: 'Ghindaoani'
      },
      {
        nume: 'Girov'
      },
      {
        nume: 'Grinţieş',
        simplu: 'Grinties'
      },
      {
        nume: 'Grumăzeşti',
        simplu: 'Grumazesti'
      },
      {
        nume: 'Gârcina',
        simplu: 'Garcina'
      },
      {
        nume: 'Hangu'
      },
      {
        nume: 'Horia'
      },
      {
        nume: 'Icuşeşti',
        simplu: 'Icusesti'
      },
      {
        nume: 'Ion Creangă',
        simplu: 'Ion Creanga'
      },
      {
        nume: 'Moldoveni'
      },
      {
        nume: 'Mărgineni',
        simplu: 'Margineni'
      },
      {
        nume: 'Oniceni'
      },
      {
        nume: 'Petricani'
      },
      {
        nume: 'Piatra Soimului',
        simplu: 'Piatra Soimului'
      },
      {
        nume: 'Pipirig'
      },
      {
        nume: 'Podoleni'
      },
      {
        nume: 'Poiana Teiului'
      },
      {
        nume: 'Poienari'
      },
      {
        nume: 'Pângaraţi',
        simplu: 'Pangarati'
      },
      {
        nume: 'Păstrăveni',
        simplu: 'Pastraveni'
      },
      {
        nume: 'Rediu'
      },
      {
        nume: 'Roman'
      },
      {
        nume: 'Români',
        simplu: 'Romani'
      },
      {
        nume: 'Roznov'
      },
      {
        nume: 'Ruginoasa'
      },
      {
        nume: 'Răuceşti',
        simplu: 'Raucesti'
      },
      {
        nume: 'Războieni',
        simplu: 'Razboieni'
      },
      {
        nume: 'Sagna'
      },
      {
        nume: 'Secuieni'
      },
      {
        nume: 'Stăniţa',
        simplu: 'Stanita'
      },
      {
        nume: 'Săbăoani',
        simplu: 'Sabaoani'
      },
      {
        nume: 'Săvineşti',
        simplu: 'Savinesti'
      },
      {
        nume: 'Tarcău',
        simplu: 'Tarcau'
      },
      {
        nume: 'Tazlău',
        simplu: 'Tazlau'
      },
      {
        nume: 'Taşca',
        simplu: 'Tasca'
      },
      {
        nume: 'Timişeşti',
        simplu: 'Timisesti'
      },
      {
        nume: 'Trifeşti',
        simplu: 'Trifesti'
      },
      {
        nume: 'Tupilaţi',
        simplu: 'Tupilati'
      },
      {
        nume: 'Târgu Neamţ',
        simplu: 'Targu Neamt'
      },
      {
        nume: 'Tămăşeni',
        simplu: 'Tamaseni'
      },
      {
        nume: 'Urecheni'
      },
      {
        nume: 'Valea Ursului'
      },
      {
        nume: 'Vânători-Neamţ',
        simplu: 'Vanatori-Neamt'
      },
      {
        nume: 'Zăneşti',
        simplu: 'Zanesti'
      },
      {
        nume: 'Stefan cel Mare',
        simplu: 'Stefan cel Mare'
      },
      {
        nume: 'Tibucani',
        simplu: 'Tibucani'
      },
      {
        nume: 'Filioara',
        comuna: 'Agapia'
      },
      {
        nume: 'Săcăluşeşti',
        simplu: 'Sacalusesti',
        comuna: 'Agapia'
      },
      {
        nume: 'Varatec',
        comuna: 'Agapia'
      },
      {
        nume: 'Agârcia',
        simplu: 'Agarcia',
        comuna: 'Alexandru Cel Bun'
      },
      {
        nume: 'Bisericani',
        comuna: 'Alexandru Cel Bun'
      },
      {
        nume: 'Bistriţa',
        simplu: 'Bistrita',
        comuna: 'Alexandru Cel Bun'
      },
      {
        nume: 'Scăricica',
        simplu: 'Scaricica',
        comuna: 'Alexandru Cel Bun'
      },
      {
        nume: 'Vaduri',
        comuna: 'Alexandru Cel Bun'
      },
      {
        nume: 'Viişoara',
        simplu: 'Viisoara',
        comuna: 'Alexandru Cel Bun'
      },
      {
        nume: 'Vădurele',
        simplu: 'Vadurele',
        comuna: 'Alexandru Cel Bun'
      },
      {
        nume: 'Arămeşti',
        simplu: 'Aramesti',
        comuna: 'Bahna'
      },
      {
        nume: 'Broşteni',
        simplu: 'Brosteni',
        comuna: 'Bahna'
      },
      {
        nume: 'Băhnişoara',
        simplu: 'Bahnisoara',
        comuna: 'Bahna'
      },
      {
        nume: 'Izvoare',
        comuna: 'Bahna'
      },
      {
        nume: 'Liliac',
        comuna: 'Bahna'
      },
      {
        nume: 'Tuţcanii din Deal',
        simplu: 'Tutcanii din Deal',
        comuna: 'Bahna'
      },
      {
        nume: 'Tuţcanii din Vale',
        simplu: 'Tutcanii din Vale',
        comuna: 'Bahna'
      },
      {
        nume: 'Valea Arini',
        comuna: 'Baltatesti'
      },
      {
        nume: 'Valea Seacă',
        simplu: 'Valea Seaca',
        comuna: 'Baltatesti'
      },
      {
        nume: 'Bahna Mare',
        comuna: 'Bargauani'
      },
      {
        nume: 'Baratca',
        comuna: 'Bargauani'
      },
      {
        nume: 'Breaza',
        comuna: 'Bargauani'
      },
      {
        nume: 'Bălăneşti',
        simplu: 'Balanesti',
        comuna: 'Bargauani'
      },
      {
        nume: 'Certieni',
        comuna: 'Bargauani'
      },
      {
        nume: 'Chilia',
        comuna: 'Bargauani'
      },
      {
        nume: 'Dârloaia',
        simplu: 'Darloaia',
        comuna: 'Bargauani'
      },
      {
        nume: 'Ghelăieşti',
        simplu: 'Ghelaiesti',
        comuna: 'Bargauani'
      },
      {
        nume: 'Homiceni',
        comuna: 'Bargauani'
      },
      {
        nume: 'Hârtop',
        simplu: 'Hartop',
        comuna: 'Bargauani'
      },
      {
        nume: 'Talpa',
        comuna: 'Bargauani'
      },
      {
        nume: 'Vlădiceni',
        simplu: 'Vladiceni',
        comuna: 'Bargauani'
      },
      {
        nume: 'Capşa',
        simplu: 'Capsa',
        comuna: 'Bicaz'
      },
      {
        nume: 'Dodeni',
        comuna: 'Bicaz'
      },
      {
        nume: 'Izvoru Muntelui',
        comuna: 'Bicaz'
      },
      {
        nume: 'Izvorul Alb',
        comuna: 'Bicaz'
      },
      {
        nume: 'Potoci',
        comuna: 'Bicaz'
      },
      {
        nume: 'Secu',
        comuna: 'Bicaz'
      },
      {
        nume: 'Bârnadu',
        simplu: 'Barnadu',
        comuna: 'Bicaz-Chei'
      },
      {
        nume: 'Gherman',
        comuna: 'Bicaz-Chei'
      },
      {
        nume: 'Ivaneş',
        simplu: 'Ivanes',
        comuna: 'Bicaz-Chei'
      },
      {
        nume: 'Telec',
        comuna: 'Bicazu Ardelean'
      },
      {
        nume: 'Ticoş',
        simplu: 'Ticos',
        comuna: 'Bicazu Ardelean'
      },
      {
        nume: 'Boghicea',
        comuna: 'Bira'
      },
      {
        nume: 'Căuşeni',
        simplu: 'Causeni',
        comuna: 'Bira'
      },
      {
        nume: 'Negreşti',
        simplu: 'Negresti',
        comuna: 'Bira'
      },
      {
        nume: 'Nistria',
        comuna: 'Bira'
      },
      {
        nume: 'Rediu',
        comuna: 'Bira'
      },
      {
        nume: 'Slobozia',
        comuna: 'Bira'
      },
      {
        nume: 'Bodeştii de Jos',
        simplu: 'Bodestii de Jos',
        comuna: 'Bodesti'
      },
      {
        nume: 'Corni',
        comuna: 'Bodesti'
      },
      {
        nume: 'Oşlobeni',
        simplu: 'Oslobeni',
        comuna: 'Bodesti'
      },
      {
        nume: 'Lunca',
        comuna: 'Borca'
      },
      {
        nume: 'Mădei',
        simplu: 'Madei',
        comuna: 'Borca'
      },
      {
        nume: 'Pârâul Cârjei',
        simplu: 'Paraul Carjei',
        comuna: 'Borca'
      },
      {
        nume: 'Pârâul Pântei',
        simplu: 'Paraul Pantei',
        comuna: 'Borca'
      },
      {
        nume: 'Sabasa',
        comuna: 'Borca'
      },
      {
        nume: 'Soci',
        comuna: 'Borca'
      },
      {
        nume: 'Mastacăn',
        simplu: 'Mastacan',
        comuna: 'Borlesti'
      },
      {
        nume: 'Nechit',
        comuna: 'Borlesti'
      },
      {
        nume: 'Ruseni',
        comuna: 'Borlesti'
      },
      {
        nume: 'Sovoaia',
        simplu: 'Sovoaia',
        comuna: 'Borlesti'
      },
      {
        nume: 'Barticeşti',
        simplu: 'Barticesti',
        comuna: 'Botesti'
      },
      {
        nume: 'David',
        comuna: 'Botesti'
      },
      {
        nume: 'Moreni',
        comuna: 'Botesti'
      },
      {
        nume: 'Munteni',
        comuna: 'Botesti'
      },
      {
        nume: 'Nisiporeşti',
        simplu: 'Nisiporesti',
        comuna: 'Botesti'
      },
      {
        nume: 'Văleni',
        simplu: 'Valeni',
        comuna: 'Botesti'
      },
      {
        nume: 'Băneasa',
        simplu: 'Baneasa',
        comuna: 'Bozieni'
      },
      {
        nume: 'Crăieşti',
        simplu: 'Craiesti',
        comuna: 'Bozieni'
      },
      {
        nume: 'Cuci',
        comuna: 'Bozieni'
      },
      {
        nume: 'Iucşa',
        simplu: 'Iucsa',
        comuna: 'Bozieni'
      },
      {
        nume: 'Brusturi',
        comuna: 'Brusturi-Draganesti'
      },
      {
        nume: 'Drăgăneşti',
        simplu: 'Draganesti',
        comuna: 'Brusturi-Draganesti'
      },
      {
        nume: 'Groşi',
        simplu: 'Grosi',
        comuna: 'Brusturi-Draganesti'
      },
      {
        nume: 'Orţăşti',
        simplu: 'Ortasti',
        comuna: 'Brusturi-Draganesti'
      },
      {
        nume: 'Poiana',
        comuna: 'Brusturi-Draganesti'
      },
      {
        nume: 'Râşca',
        simplu: 'Rasca',
        comuna: 'Brusturi-Draganesti'
      },
      {
        nume: 'Târzia',
        simplu: 'Tarzia',
        comuna: 'Brusturi-Draganesti'
      },
      {
        nume: 'Soimăreşti',
        simplu: 'Soimaresti',
        comuna: 'Brusturi-Draganesti'
      },
      {
        nume: 'Bărcăneşti',
        simplu: 'Barcanesti',
        comuna: 'Candesti'
      },
      {
        nume: 'Dragova',
        comuna: 'Candesti'
      },
      {
        nume: 'Pădureni',
        simplu: 'Padureni',
        comuna: 'Candesti'
      },
      {
        nume: 'Vădurele',
        simplu: 'Vadurele',
        comuna: 'Candesti'
      },
      {
        nume: 'Târdenii Mici',
        simplu: 'Tardenii Mici',
        comuna: 'Candesti'
      },
      {
        nume: 'Bistricioara',
        comuna: 'Ceahlau'
      },
      {
        nume: 'Pârâul Mare',
        simplu: 'Paraul Mare',
        comuna: 'Ceahlau'
      },
      {
        nume: 'Pildeşti',
        simplu: 'Pildesti',
        comuna: 'Cordun'
      },
      {
        nume: 'Simioneşti',
        simplu: 'Simionesti',
        comuna: 'Cordun'
      },
      {
        nume: 'Dorneşti',
        simplu: 'Dornesti',
        comuna: 'Costisa'
      },
      {
        nume: 'Frunzeni',
        comuna: 'Costisa'
      },
      {
        nume: 'Mănoaia',
        simplu: 'Manoaia',
        comuna: 'Costisa'
      },
      {
        nume: 'Cracăul Negru',
        simplu: 'Cracaul Negru',
        comuna: 'Cracaoani'
      },
      {
        nume: 'Magazia',
        comuna: 'Cracaoani'
      },
      {
        nume: 'Mitocu Bălan',
        simplu: 'Mitocu Balan',
        comuna: 'Cracaoani'
      },
      {
        nume: 'Poiana Crăcăoani',
        simplu: 'Poiana Cracaoani',
        comuna: 'Cracaoani'
      },
      {
        nume: 'Huisurez',
        comuna: 'Damuc'
      },
      {
        nume: 'Trei Fântâni',
        simplu: 'Trei Fantani',
        comuna: 'Damuc'
      },
      {
        nume: 'Căşăria',
        simplu: 'Casaria',
        comuna: 'Dobreni'
      },
      {
        nume: 'Negreşti',
        simplu: 'Negresti',
        comuna: 'Dobreni'
      },
      {
        nume: 'Poiana',
        comuna: 'Dobreni'
      },
      {
        nume: 'Sărata',
        simplu: 'Sarata',
        comuna: 'Dobreni'
      },
      {
        nume: 'Băluşeşti',
        simplu: 'Balusesti',
        comuna: 'Dochia'
      },
      {
        nume: 'Buhonca',
        comuna: 'Doljesti'
      },
      {
        nume: 'Buruieneşti',
        simplu: 'Buruienesti',
        comuna: 'Doljesti'
      },
      {
        nume: 'Rotunda',
        comuna: 'Doljesti'
      },
      {
        nume: 'Borniş',
        simplu: 'Bornis',
        comuna: 'Dragomiresti'
      },
      {
        nume: 'Hlăpeşti',
        simplu: 'Hlapesti',
        comuna: 'Dragomiresti'
      },
      {
        nume: 'Mastacăn',
        simplu: 'Mastacan',
        comuna: 'Dragomiresti'
      },
      {
        nume: 'Unghi',
        comuna: 'Dragomiresti'
      },
      {
        nume: 'Vad',
        comuna: 'Dragomiresti'
      },
      {
        nume: 'Briţcani',
        simplu: 'Britcani',
        comuna: 'Dulcesti'
      },
      {
        nume: 'Corhana',
        comuna: 'Dulcesti'
      },
      {
        nume: 'Cârlig',
        simplu: 'Carlig',
        comuna: 'Dulcesti'
      },
      {
        nume: 'Poiana',
        comuna: 'Dulcesti'
      },
      {
        nume: 'Roşiori',
        simplu: 'Rosiori',
        comuna: 'Dulcesti'
      },
      {
        nume: 'Brăşăuţi',
        simplu: 'Brasauti',
        comuna: 'Dumbrava Rosie'
      },
      {
        nume: 'Cut',
        comuna: 'Dumbrava Rosie'
      },
      {
        nume: 'Izvoare',
        comuna: 'Dumbrava Rosie'
      },
      {
        nume: 'Buşmei',
        simplu: 'Busmei',
        comuna: 'Farcasa'
      },
      {
        nume: 'Frumosu',
        comuna: 'Farcasa'
      },
      {
        nume: 'Popeşti',
        simplu: 'Popesti',
        comuna: 'Farcasa'
      },
      {
        nume: 'Stejaru',
        comuna: 'Farcasa'
      },
      {
        nume: 'Budeşti',
        simplu: 'Budesti',
        comuna: 'Faurei'
      },
      {
        nume: 'Climeşti',
        simplu: 'Climesti',
        comuna: 'Faurei'
      },
      {
        nume: 'Micşuneşti',
        simplu: 'Micsunesti',
        comuna: 'Faurei'
      },
      {
        nume: 'Almaş',
        simplu: 'Almas',
        comuna: 'Garcina'
      },
      {
        nume: 'Cuejdiu',
        comuna: 'Garcina'
      },
      {
        nume: 'Gherăeştii Noi',
        simplu: 'Gheraestii Noi',
        comuna: 'Gheraesti'
      },
      {
        nume: 'Teţcani',
        simplu: 'Tetcani',
        comuna: 'Gheraesti'
      },
      {
        nume: 'Boţeşti',
        simplu: 'Botesti',
        comuna: 'Girov'
      },
      {
        nume: 'Căciuleşti',
        simplu: 'Caciulesti',
        comuna: 'Girov'
      },
      {
        nume: 'Doina',
        comuna: 'Girov'
      },
      {
        nume: 'Dăneşti',
        simplu: 'Danesti',
        comuna: 'Girov'
      },
      {
        nume: 'Gura Văii',
        simplu: 'Gura Vaii',
        comuna: 'Girov'
      },
      {
        nume: 'Popeşti',
        simplu: 'Popesti',
        comuna: 'Girov'
      },
      {
        nume: 'Turtureşti',
        simplu: 'Turturesti',
        comuna: 'Girov'
      },
      {
        nume: 'Verşeşti',
        simplu: 'Versesti',
        comuna: 'Girov'
      },
      {
        nume: 'Bradu',
        comuna: 'Grinties'
      },
      {
        nume: 'Poiana',
        comuna: 'Grinties'
      },
      {
        nume: 'Curechiştea',
        simplu: 'Curechistea',
        comuna: 'Grumazesti'
      },
      {
        nume: 'Netezi',
        comuna: 'Grumazesti'
      },
      {
        nume: 'Topoliţa',
        simplu: 'Topolita',
        comuna: 'Grumazesti'
      },
      {
        nume: 'Buhalniţa',
        simplu: 'Buhalnita',
        comuna: 'Hangu'
      },
      {
        nume: 'Chiriţeni',
        simplu: 'Chiriteni',
        comuna: 'Hangu'
      },
      {
        nume: 'Grozăveşti',
        simplu: 'Grozavesti',
        comuna: 'Hangu'
      },
      {
        nume: 'Rugineşti',
        simplu: 'Ruginesti',
        comuna: 'Hangu'
      },
      {
        nume: 'Cotu Vameş',
        simplu: 'Cotu Vames',
        comuna: 'Horia'
      },
      {
        nume: 'Băluşeşti',
        simplu: 'Balusesti',
        comuna: 'Icusesti'
      },
      {
        nume: 'Bătrâneşti',
        simplu: 'Batranesti',
        comuna: 'Icusesti'
      },
      {
        nume: 'Mesteacăn',
        simplu: 'Mesteacan',
        comuna: 'Icusesti'
      },
      {
        nume: 'Rocna',
        comuna: 'Icusesti'
      },
      {
        nume: 'Spiridoneşti',
        simplu: 'Spiridonesti',
        comuna: 'Icusesti'
      },
      {
        nume: 'Tabăra',
        simplu: 'Tabara',
        comuna: 'Icusesti'
      },
      {
        nume: 'Avereşti',
        simplu: 'Averesti',
        comuna: 'Ion Creanga'
      },
      {
        nume: 'Izvoru',
        comuna: 'Ion Creanga'
      },
      {
        nume: 'Muncelu',
        comuna: 'Ion Creanga'
      },
      {
        nume: 'Recea',
        comuna: 'Ion Creanga'
      },
      {
        nume: 'Stejaru',
        comuna: 'Ion Creanga'
      },
      {
        nume: 'Hoiseşti',
        simplu: 'Hoisesti',
        comuna: 'Margineni'
      },
      {
        nume: 'Hârţeşti',
        simplu: 'Hartesti',
        comuna: 'Margineni'
      },
      {
        nume: 'Itrineşti',
        simplu: 'Itrinesti',
        comuna: 'Margineni'
      },
      {
        nume: 'Hociungi',
        comuna: 'Moldoveni'
      },
      {
        nume: 'Ciornei',
        comuna: 'Oniceni'
      },
      {
        nume: 'Gorun',
        comuna: 'Oniceni'
      },
      {
        nume: 'Linseşti',
        simplu: 'Linsesti',
        comuna: 'Oniceni'
      },
      {
        nume: 'Lunca',
        comuna: 'Oniceni'
      },
      {
        nume: 'Mărmureni',
        simplu: 'Marmureni',
        comuna: 'Oniceni'
      },
      {
        nume: 'Pietrosu',
        comuna: 'Oniceni'
      },
      {
        nume: 'Poiana Humei',
        comuna: 'Oniceni'
      },
      {
        nume: 'Pustieta',
        comuna: 'Oniceni'
      },
      {
        nume: 'Solca',
        comuna: 'Oniceni'
      },
      {
        nume: 'Valea Enei',
        comuna: 'Oniceni'
      },
      {
        nume: 'Oanţu',
        simplu: 'Oantu',
        comuna: 'Pangarati'
      },
      {
        nume: 'Poiana',
        comuna: 'Pangarati'
      },
      {
        nume: 'Preluca',
        comuna: 'Pangarati'
      },
      {
        nume: 'Pângărăcior',
        simplu: 'Pangaracior',
        comuna: 'Pangarati'
      },
      {
        nume: 'Stejaru',
        comuna: 'Pangarati'
      },
      {
        nume: 'Lunca Moldovei',
        comuna: 'Pastraveni'
      },
      {
        nume: 'Rădeni',
        simplu: 'Radeni',
        comuna: 'Pastraveni'
      },
      {
        nume: 'Spieşti',
        simplu: 'Spiesti',
        comuna: 'Pastraveni'
      },
      {
        nume: 'Boiştea',
        simplu: 'Boistea',
        comuna: 'Petricani'
      },
      {
        nume: 'Târpeşti',
        simplu: 'Tarpesti',
        comuna: 'Petricani'
      },
      {
        nume: 'Tolici',
        simplu: 'Tolici',
        comuna: 'Petricani'
      },
      {
        nume: 'Ciritei',
        comuna: 'Piatra Neamt'
      },
      {
        nume: 'Doamna',
        comuna: 'Piatra Neamt'
      },
      {
        nume: 'Piatra-Neamţ',
        simplu: 'Piatra-Neamt',
        comuna: 'Piatra Neamt'
      },
      {
        nume: 'Văleni',
        simplu: 'Valeni',
        comuna: 'Piatra Neamt'
      },
      {
        nume: 'Luminiş',
        simplu: 'Luminis',
        comuna: 'Piatra Soimului'
      },
      {
        nume: 'Neguleşti',
        simplu: 'Negulesti',
        comuna: 'Piatra Soimului'
      },
      {
        nume: 'Poieni',
        comuna: 'Piatra Soimului'
      },
      {
        nume: 'Boboieşti',
        simplu: 'Boboiesti',
        comuna: 'Pipirig'
      },
      {
        nume: 'Dolheşti',
        simplu: 'Dolhesti',
        comuna: 'Pipirig'
      },
      {
        nume: 'Leghin',
        comuna: 'Pipirig'
      },
      {
        nume: 'Pluton',
        comuna: 'Pipirig'
      },
      {
        nume: 'Pâţâligeni',
        simplu: 'Pataligeni',
        comuna: 'Pipirig'
      },
      {
        nume: 'Stânca',
        simplu: 'Stanca',
        comuna: 'Pipirig'
      },
      {
        nume: 'Negriteşti',
        simplu: 'Negritesti',
        comuna: 'Podoleni'
      },
      {
        nume: 'Călugăreni',
        simplu: 'Calugareni',
        comuna: 'Poiana Teiului'
      },
      {
        nume: 'Dreptu',
        comuna: 'Poiana Teiului'
      },
      {
        nume: 'Galu',
        comuna: 'Poiana Teiului'
      },
      {
        nume: 'Petru Vodă',
        simplu: 'Petru Voda',
        comuna: 'Poiana Teiului'
      },
      {
        nume: 'Poiana Largului',
        comuna: 'Poiana Teiului'
      },
      {
        nume: 'Pârâul Fagului',
        simplu: 'Paraul Fagului',
        comuna: 'Poiana Teiului'
      },
      {
        nume: 'Roşeni',
        simplu: 'Roseni',
        comuna: 'Poiana Teiului'
      },
      {
        nume: 'Ruseni',
        comuna: 'Poiana Teiului'
      },
      {
        nume: 'Săvineşti',
        simplu: 'Savinesti',
        comuna: 'Poiana Teiului'
      },
      {
        nume: 'Topoliceni',
        comuna: 'Poiana Teiului'
      },
      {
        nume: 'Bunghi',
        comuna: 'Poienari'
      },
      {
        nume: 'Ciurea',
        comuna: 'Poienari'
      },
      {
        nume: 'Holm',
        comuna: 'Poienari'
      },
      {
        nume: 'Patricheni',
        comuna: 'Poienari'
      },
      {
        nume: 'Pânceşti',
        simplu: 'Pancesti',
        comuna: 'Poienari'
      },
      {
        nume: 'Săcăleni',
        simplu: 'Sacaleni',
        comuna: 'Poienari'
      },
      {
        nume: 'Tălpălăi',
        simplu: 'Talpalai',
        comuna: 'Poienari'
      },
      {
        nume: 'Oglinzi',
        comuna: 'Raucesti'
      },
      {
        nume: 'Săveşti',
        simplu: 'Savesti',
        comuna: 'Raucesti'
      },
      {
        nume: 'Ungheni',
        comuna: 'Raucesti'
      },
      {
        nume: 'Borşeni',
        simplu: 'Borseni',
        comuna: 'Razboieni'
      },
      {
        nume: 'Războienii de Jos',
        simplu: 'Razboienii de Jos',
        comuna: 'Razboieni'
      },
      {
        nume: 'Valea Albă',
        simplu: 'Valea Alba',
        comuna: 'Razboieni'
      },
      {
        nume: 'Valea Mare',
        comuna: 'Razboieni'
      },
      {
        nume: 'Beţeşti',
        simplu: 'Betesti',
        comuna: 'Rediu'
      },
      {
        nume: 'Poloboc',
        comuna: 'Rediu'
      },
      {
        nume: 'Socea',
        comuna: 'Rediu'
      },
      {
        nume: 'Goşmani',
        simplu: 'Gosmani',
        comuna: 'Romani'
      },
      {
        nume: 'Siliştea',
        simplu: 'Silistea',
        comuna: 'Romani'
      },
      {
        nume: 'Chintinici',
        comuna: 'Roznov'
      },
      {
        nume: 'Slobozia',
        comuna: 'Roznov'
      },
      {
        nume: 'Bozienii de Sus',
        comuna: 'Ruginoasa'
      },
      {
        nume: 'Traian',
        comuna: 'Sabaoani'
      },
      {
        nume: 'Gâdinţi',
        simplu: 'Gadinti',
        comuna: 'Sagna'
      },
      {
        nume: 'Luţca',
        simplu: 'Lutca',
        comuna: 'Sagna'
      },
      {
        nume: 'Vulpăşeşti',
        simplu: 'Vulpasesti',
        comuna: 'Sagna'
      },
      {
        nume: 'Dumbrava Deal',
        comuna: 'Savinesti'
      },
      {
        nume: 'Başta',
        simplu: 'Basta',
        comuna: 'Secuieni'
      },
      {
        nume: 'Bogzeşti',
        simplu: 'Bogzesti',
        comuna: 'Secuieni'
      },
      {
        nume: 'Butnăreşti',
        simplu: 'Butnaresti',
        comuna: 'Secuieni'
      },
      {
        nume: 'Bârjoveni',
        simplu: 'Barjoveni',
        comuna: 'Secuieni'
      },
      {
        nume: 'Giuleşti',
        simplu: 'Giulesti',
        comuna: 'Secuieni'
      },
      {
        nume: 'Prăjeşti',
        simplu: 'Prajesti',
        comuna: 'Secuieni'
      },
      {
        nume: 'Secuienii Noi',
        comuna: 'Secuieni'
      },
      {
        nume: 'Unceşti',
        simplu: 'Uncesti',
        comuna: 'Secuieni'
      },
      {
        nume: 'Chicerea',
        comuna: 'Stanita'
      },
      {
        nume: 'Ghidion',
        comuna: 'Stanita'
      },
      {
        nume: 'Poienile Oancei',
        comuna: 'Stanita'
      },
      {
        nume: 'Todireni',
        comuna: 'Stanita'
      },
      {
        nume: 'Veja',
        comuna: 'Stanita'
      },
      {
        nume: 'Vlădnicele',
        simplu: 'Vladnicele',
        comuna: 'Stanita'
      },
      {
        nume: 'Bordea',
        comuna: 'Stefan Cel Mare'
      },
      {
        nume: 'Cârligi',
        simplu: 'Carligi',
        comuna: 'Stefan Cel Mare'
      },
      {
        nume: 'Deleni',
        comuna: 'Stefan Cel Mare'
      },
      {
        nume: 'Duşeşti',
        simplu: 'Dusesti',
        comuna: 'Stefan Cel Mare'
      },
      {
        nume: 'Ghigoieşti',
        simplu: 'Ghigoiesti',
        comuna: 'Stefan Cel Mare'
      },
      {
        nume: 'Soci',
        comuna: 'Stefan Cel Mare'
      },
      {
        nume: 'Adjudeni',
        comuna: 'Tamaseni'
      },
      {
        nume: 'Ardeluţa',
        simplu: 'Ardeluta',
        comuna: 'Tarcau'
      },
      {
        nume: 'Brateş',
        simplu: 'Brates',
        comuna: 'Tarcau'
      },
      {
        nume: 'Cazaci',
        comuna: 'Tarcau'
      },
      {
        nume: 'Schitu Tarcău',
        simplu: 'Schitu Tarcau',
        comuna: 'Tarcau'
      },
      {
        nume: 'Straja',
        comuna: 'Tarcau'
      },
      {
        nume: 'Blebea',
        comuna: 'Targu Neamt'
      },
      {
        nume: 'Humuleşti',
        simplu: 'Humulesti',
        comuna: 'Targu Neamt'
      },
      {
        nume: 'Humuleştii Noi',
        simplu: 'Humulestii Noi',
        comuna: 'Targu Neamt'
      },
      {
        nume: 'Hamzoaia',
        comuna: 'Tasca'
      },
      {
        nume: 'Neagra',
        comuna: 'Tasca'
      },
      {
        nume: 'Ticoş-Floarea',
        simplu: 'Ticos-Floarea',
        comuna: 'Tasca'
      },
      {
        nume: 'Davideni',
        comuna: 'Tibucani'
      },
      {
        nume: 'Tibucanii de Jos',
        simplu: 'Tibucanii de Jos',
        comuna: 'Tibucani'
      },
      {
        nume: 'Dumbrava',
        comuna: 'Timisesti'
      },
      {
        nume: 'Plăieşu',
        simplu: 'Plaiesu',
        comuna: 'Timisesti'
      },
      {
        nume: 'Preuteşti',
        simplu: 'Preutesti',
        comuna: 'Timisesti'
      },
      {
        nume: 'Zvorăneşti',
        simplu: 'Zvoranesti',
        comuna: 'Timisesti'
      },
      {
        nume: 'Miron Costin',
        comuna: 'Trifesti'
      },
      {
        nume: 'Arămoaia',
        simplu: 'Aramoaia',
        comuna: 'Tupilati'
      },
      {
        nume: 'Hanul Ancuţei',
        simplu: 'Hanul Ancutei',
        comuna: 'Tupilati'
      },
      {
        nume: 'Totoieşti',
        simplu: 'Totoiesti',
        comuna: 'Tupilati'
      },
      {
        nume: 'Ingăreşti',
        simplu: 'Ingaresti',
        comuna: 'Urecheni'
      },
      {
        nume: 'Plugari',
        comuna: 'Urecheni'
      },
      {
        nume: 'Bucium',
        comuna: 'Valea Ursului'
      },
      {
        nume: 'Chilii',
        comuna: 'Valea Ursului'
      },
      {
        nume: 'Giurgeni',
        comuna: 'Valea Ursului'
      },
      {
        nume: 'Muncelu de Jos',
        comuna: 'Valea Ursului'
      },
      {
        nume: 'Lunca',
        comuna: 'Vanatori-Neamt'
      },
      {
        nume: 'Mânăstirea Neamţ',
        simplu: 'Manastirea Neamt',
        comuna: 'Vanatori-Neamt'
      },
      {
        nume: 'Nemţişor',
        simplu: 'Nemtisor',
        comuna: 'Vanatori-Neamt'
      },
      {
        nume: 'Traian',
        comuna: 'Zanesti'
      }
    ]
  },
  {
    auto: 'OT',
    nume: 'Olt',
    localitati: [
      {
        nume: 'Baldovineşti',
        simplu: 'Baldovinesti'
      },
      {
        nume: 'Balş',
        simplu: 'Bals'
      },
      {
        nume: 'Bobiceşti',
        simplu: 'Bobicesti'
      },
      {
        nume: 'Brastavăţu',
        simplu: 'Brastavatu'
      },
      {
        nume: 'Brebeni'
      },
      {
        nume: 'Brâncoveni',
        simplu: 'Brancoveni'
      },
      {
        nume: 'Bucinişu',
        simplu: 'Bucinisu'
      },
      {
        nume: 'Bârza',
        simplu: 'Barza'
      },
      {
        nume: 'Băbiciu',
        simplu: 'Babiciu'
      },
      {
        nume: 'Caracal'
      },
      {
        nume: 'Cezieni'
      },
      {
        nume: 'Cilieni'
      },
      {
        nume: 'Coloneşti',
        simplu: 'Colonesti'
      },
      {
        nume: 'Corabia'
      },
      {
        nume: 'Corbu'
      },
      {
        nume: 'Coteana'
      },
      {
        nume: 'Crâmpoia',
        simplu: 'Crampoia'
      },
      {
        nume: 'Cungrea'
      },
      {
        nume: 'Curtişoara',
        simplu: 'Curtisoara'
      },
      {
        nume: 'Cârlogani',
        simplu: 'Carlogani'
      },
      {
        nume: 'Deveselu'
      },
      {
        nume: 'Dobreţu',
        simplu: 'Dobretu'
      },
      {
        nume: 'Dobrosloveni'
      },
      {
        nume: 'Dobroteasa'
      },
      {
        nume: 'Dobrun'
      },
      {
        nume: 'Drăghiceni',
        simplu: 'Draghiceni'
      },
      {
        nume: 'Drăgăneşti-Olt',
        simplu: 'Draganesti-Olt'
      },
      {
        nume: 'Dăneasa',
        simplu: 'Daneasa'
      },
      {
        nume: 'Făgeţelu',
        simplu: 'Fagetelu'
      },
      {
        nume: 'Fălcoiu',
        simplu: 'Falcoiu'
      },
      {
        nume: 'Fărcaşele',
        simplu: 'Farcasele'
      },
      {
        nume: 'Giuvărăşti',
        simplu: 'Giuvarasti'
      },
      {
        nume: 'Gostavăţu',
        simplu: 'Gostavatu'
      },
      {
        nume: 'Grojdibodu'
      },
      {
        nume: 'Grădinari',
        simplu: 'Gradinari'
      },
      {
        nume: 'Gârcov',
        simplu: 'Garcov'
      },
      {
        nume: 'Găneasa',
        simplu: 'Ganeasa'
      },
      {
        nume: 'Ianca'
      },
      {
        nume: 'Iancu Jianu'
      },
      {
        nume: 'Icoana'
      },
      {
        nume: 'Izbiceni'
      },
      {
        nume: 'Izvoarele'
      },
      {
        nume: 'Leleasca'
      },
      {
        nume: 'Mihăeşti',
        simplu: 'Mihaesti'
      },
      {
        nume: 'Morunglav'
      },
      {
        nume: 'Movileni'
      },
      {
        nume: 'Mărunţei',
        simplu: 'Maruntei'
      },
      {
        nume: 'Nicolae Titulescu'
      },
      {
        nume: 'Oboga'
      },
      {
        nume: 'Obârşia',
        simplu: 'Obarsia'
      },
      {
        nume: 'Oporelu'
      },
      {
        nume: 'Orlea'
      },
      {
        nume: 'Osica de Sus'
      },
      {
        nume: 'Perieţi',
        simplu: 'Perieti'
      },
      {
        nume: 'Piatra-Olt'
      },
      {
        nume: 'Pleşoiu',
        simplu: 'Plesoiu'
      },
      {
        nume: 'Poboru'
      },
      {
        nume: 'Potcoava'
      },
      {
        nume: 'Priseaca'
      },
      {
        nume: 'Radomireşti',
        simplu: 'Radomiresti'
      },
      {
        nume: 'Redea'
      },
      {
        nume: 'Rotunda'
      },
      {
        nume: 'Rusăneşti',
        simplu: 'Rusanesti'
      },
      {
        nume: 'Schitu'
      },
      {
        nume: 'Scorniceşti',
        simplu: 'Scornicesti'
      },
      {
        nume: 'Scărişoara',
        simplu: 'Scarisoara'
      },
      {
        nume: 'Seaca'
      },
      {
        nume: 'Slatina'
      },
      {
        nume: 'Slătioara',
        simplu: 'Slatioara'
      },
      {
        nume: 'Spineni'
      },
      {
        nume: 'Sprâncenata',
        simplu: 'Sprancenata'
      },
      {
        nume: 'Stoeneşti',
        simplu: 'Stoenesti'
      },
      {
        nume: 'Stoicăneşti',
        simplu: 'Stoicanesti'
      },
      {
        nume: 'Strejeşti',
        simplu: 'Strejesti'
      },
      {
        nume: 'Studina'
      },
      {
        nume: 'Sâmbureşti',
        simplu: 'Samburesti'
      },
      {
        nume: 'Teslui'
      },
      {
        nume: 'Tia Mare'
      },
      {
        nume: 'Topana'
      },
      {
        nume: 'Traian'
      },
      {
        nume: 'Tufeni'
      },
      {
        nume: 'Tătuleşti',
        simplu: 'Tatulesti'
      },
      {
        nume: 'Urzica'
      },
      {
        nume: 'Valea Mare'
      },
      {
        nume: 'Verguleasa'
      },
      {
        nume: 'Vitomireşti',
        simplu: 'Vitomiresti'
      },
      {
        nume: 'Vişina',
        simplu: 'Visina'
      },
      {
        nume: 'Vlădila',
        simplu: 'Vladila'
      },
      {
        nume: 'Voineasa'
      },
      {
        nume: 'Vulpeni'
      },
      {
        nume: 'Vultureşti',
        simplu: 'Vulturesti'
      },
      {
        nume: 'Vâlcele',
        simplu: 'Valcele'
      },
      {
        nume: 'Vădastra',
        simplu: 'Vadastra'
      },
      {
        nume: 'Vădăstriţa',
        simplu: 'Vadastrita'
      },
      {
        nume: 'Văleni',
        simplu: 'Valeni'
      },
      {
        nume: 'Serbăneşti',
        simplu: 'Serbanesti'
      },
      {
        nume: 'Stefan cel Mare',
        simplu: 'Stefan cel Mare'
      },
      {
        nume: 'Broşteni',
        simplu: 'Brosteni',
        comuna: 'Baldovinesti'
      },
      {
        nume: 'Băleasa',
        simplu: 'Baleasa',
        comuna: 'Baldovinesti'
      },
      {
        nume: 'Dâmburile',
        simplu: 'Damburile',
        comuna: 'Baldovinesti'
      },
      {
        nume: 'Gubandru',
        comuna: 'Baldovinesti'
      },
      {
        nume: 'Găvăneşti',
        simplu: 'Gavanesti',
        comuna: 'Baldovinesti'
      },
      {
        nume: 'Pietriş',
        simplu: 'Pietris',
        comuna: 'Baldovinesti'
      },
      {
        nume: 'Corbeni',
        comuna: 'Bals'
      },
      {
        nume: 'Româna',
        simplu: 'Romana',
        comuna: 'Bals'
      },
      {
        nume: 'Teiş',
        simplu: 'Teis',
        comuna: 'Bals'
      },
      {
        nume: 'Boroeşti',
        simplu: 'Boroesti',
        comuna: 'Barasti'
      },
      {
        nume: 'Bărăştii de Cepturi',
        simplu: 'Barastii de Cepturi',
        comuna: 'Barasti'
      },
      {
        nume: 'Bărăştii de Vede',
        simplu: 'Barastii de Vede',
        comuna: 'Barasti'
      },
      {
        nume: 'Ciocăneşti',
        simplu: 'Ciocanesti',
        comuna: 'Barasti'
      },
      {
        nume: 'Lăzăreşti',
        simplu: 'Lazaresti',
        comuna: 'Barasti'
      },
      {
        nume: 'Mereni',
        comuna: 'Barasti'
      },
      {
        nume: 'Moţoeşti',
        simplu: 'Motoesti',
        comuna: 'Barasti'
      },
      {
        nume: 'Popeşti',
        simplu: 'Popesti',
        comuna: 'Barasti'
      },
      {
        nume: 'Braneţ',
        simplu: 'Branet',
        comuna: 'Barza'
      },
      {
        nume: 'Bechet',
        comuna: 'Bobicesti'
      },
      {
        nume: 'Belgun',
        comuna: 'Bobicesti'
      },
      {
        nume: 'Chinteşti',
        simplu: 'Chintesti',
        comuna: 'Bobicesti'
      },
      {
        nume: 'Comăneşti',
        simplu: 'Comanesti',
        comuna: 'Bobicesti'
      },
      {
        nume: 'Govora',
        comuna: 'Bobicesti'
      },
      {
        nume: 'Leoteşti',
        simplu: 'Leotesti',
        comuna: 'Bobicesti'
      },
      {
        nume: 'Mirila',
        comuna: 'Bobicesti'
      },
      {
        nume: 'Mărgheni',
        simplu: 'Margheni',
        comuna: 'Brancoveni'
      },
      {
        nume: 'Ociogi',
        comuna: 'Brancoveni'
      },
      {
        nume: 'Văleni',
        simplu: 'Valeni',
        comuna: 'Brancoveni'
      },
      {
        nume: 'Cruşovu',
        simplu: 'Crusovu',
        comuna: 'Brastavatu'
      },
      {
        nume: 'Teiuşu',
        simplu: 'Teiusu',
        comuna: 'Brebeni'
      },
      {
        nume: 'Bucinişu Mic',
        simplu: 'Bucinisu Mic',
        comuna: 'Bucinisu'
      },
      {
        nume: 'Beculeşti',
        simplu: 'Beculesti',
        comuna: 'Carlogani'
      },
      {
        nume: 'Cepari',
        comuna: 'Carlogani'
      },
      {
        nume: 'Scorbura',
        comuna: 'Carlogani'
      },
      {
        nume: 'Stupina',
        comuna: 'Carlogani'
      },
      {
        nume: 'Bondrea',
        comuna: 'Cezieni'
      },
      {
        nume: 'Corlăteşti',
        simplu: 'Corlatesti',
        comuna: 'Cezieni'
      },
      {
        nume: 'Bărăşti',
        simplu: 'Barasti',
        comuna: 'Colonesti'
      },
      {
        nume: 'Bătăreni',
        simplu: 'Batareni',
        comuna: 'Colonesti'
      },
      {
        nume: 'Chelbeşti',
        simplu: 'Chelbesti',
        comuna: 'Colonesti'
      },
      {
        nume: 'Cârstani',
        simplu: 'Carstani',
        comuna: 'Colonesti'
      },
      {
        nume: 'Gueşti',
        simplu: 'Guesti',
        comuna: 'Colonesti'
      },
      {
        nume: 'Mărunţei',
        simplu: 'Maruntei',
        comuna: 'Colonesti'
      },
      {
        nume: 'Năvârgeni',
        simplu: 'Navargeni',
        comuna: 'Colonesti'
      },
      {
        nume: 'Vlaici',
        comuna: 'Colonesti'
      },
      {
        nume: 'Tudor Vladimirescu',
        comuna: 'Corabia'
      },
      {
        nume: 'Vârtopu',
        simplu: 'Vartopu',
        comuna: 'Corabia'
      },
      {
        nume: 'Burduleşti',
        simplu: 'Burdulesti',
        comuna: 'Corbu'
      },
      {
        nume: 'Buzeşti',
        simplu: 'Buzesti',
        comuna: 'Corbu'
      },
      {
        nume: 'Ciureşti',
        simplu: 'Ciuresti',
        comuna: 'Corbu'
      },
      {
        nume: 'Milcoveni',
        comuna: 'Corbu'
      },
      {
        nume: 'Buta',
        comuna: 'Crampoia'
      },
      {
        nume: 'Cepeşti',
        simplu: 'Cepesti',
        comuna: 'Cungrea'
      },
      {
        nume: 'Ibăneşti',
        simplu: 'Ibanesti',
        comuna: 'Cungrea'
      },
      {
        nume: 'Mieşti',
        simplu: 'Miesti',
        comuna: 'Cungrea'
      },
      {
        nume: 'Oteştii de Jos',
        simplu: 'Otestii de Jos',
        comuna: 'Cungrea'
      },
      {
        nume: 'Oteştii de Sus',
        simplu: 'Otestii de Sus',
        comuna: 'Cungrea'
      },
      {
        nume: 'Spătaru',
        simplu: 'Spataru',
        comuna: 'Cungrea'
      },
      {
        nume: 'Dobrotinet',
        comuna: 'Curtisoara'
      },
      {
        nume: 'Linia din Vale',
        comuna: 'Curtisoara'
      },
      {
        nume: 'Pietrişu',
        simplu: 'Pietrisu',
        comuna: 'Curtisoara'
      },
      {
        nume: 'Proaspeţi',
        simplu: 'Proaspeti',
        comuna: 'Curtisoara'
      },
      {
        nume: 'Raiţiu',
        simplu: 'Raitiu',
        comuna: 'Curtisoara'
      },
      {
        nume: 'Berindei',
        comuna: 'Daneasa'
      },
      {
        nume: 'Cioflanu',
        comuna: 'Daneasa'
      },
      {
        nume: 'Pestra',
        comuna: 'Daneasa'
      },
      {
        nume: 'Zănoaga',
        simplu: 'Zanoaga',
        comuna: 'Daneasa'
      },
      {
        nume: 'Comanca',
        comuna: 'Deveselu'
      },
      {
        nume: 'Curtişoara',
        simplu: 'Curtisoara',
        comuna: 'Dobretu'
      },
      {
        nume: 'Horezu',
        comuna: 'Dobretu'
      },
      {
        nume: 'Frăsinetu',
        simplu: 'Frasinetu',
        comuna: 'Dobrosloveni'
      },
      {
        nume: 'Potopinu',
        comuna: 'Dobrosloveni'
      },
      {
        nume: 'Reşca',
        simplu: 'Resca',
        comuna: 'Dobrosloveni'
      },
      {
        nume: 'Reşcuţa',
        simplu: 'Rescuta',
        comuna: 'Dobrosloveni'
      },
      {
        nume: 'Batia',
        comuna: 'Dobroteasa'
      },
      {
        nume: 'Câmpu Mare',
        simplu: 'Campu Mare',
        comuna: 'Dobroteasa'
      },
      {
        nume: 'Vulpeşti',
        simplu: 'Vulpesti',
        comuna: 'Dobroteasa'
      },
      {
        nume: 'Bobu',
        comuna: 'Dobrun'
      },
      {
        nume: 'Chilii',
        comuna: 'Dobrun'
      },
      {
        nume: 'Osica de Jos',
        comuna: 'Dobrun'
      },
      {
        nume: 'Roşienii Mari',
        simplu: 'Rosienii Mari',
        comuna: 'Dobrun'
      },
      {
        nume: 'Roşienii Mici',
        simplu: 'Rosienii Mici',
        comuna: 'Dobrun'
      },
      {
        nume: 'Ulmet',
        comuna: 'Dobrun'
      },
      {
        nume: 'Comani',
        comuna: 'Draganesti-Olt'
      },
      {
        nume: 'Grozăveşti',
        simplu: 'Grozavesti',
        comuna: 'Draghiceni'
      },
      {
        nume: 'Liiceni',
        comuna: 'Draghiceni'
      },
      {
        nume: 'Bâgeşti',
        simplu: 'Bagesti',
        comuna: 'Fagetelu'
      },
      {
        nume: 'Chilia',
        comuna: 'Fagetelu'
      },
      {
        nume: 'Gruiu',
        comuna: 'Fagetelu'
      },
      {
        nume: 'Isaci',
        comuna: 'Fagetelu'
      },
      {
        nume: 'Pielcani',
        comuna: 'Fagetelu'
      },
      {
        nume: 'Cioroiaşu',
        simplu: 'Cioroiasu',
        comuna: 'Falcoiu'
      },
      {
        nume: 'Cioroiu',
        comuna: 'Falcoiu'
      },
      {
        nume: 'Fărcaşu de Jos',
        simplu: 'Farcasu de Jos',
        comuna: 'Farcasele'
      },
      {
        nume: 'Ghimpaţi',
        simplu: 'Ghimpati',
        comuna: 'Farcasele'
      },
      {
        nume: 'Hotărani',
        simplu: 'Hotarani',
        comuna: 'Farcasele'
      },
      {
        nume: 'Dranovăţu',
        simplu: 'Dranovatu',
        comuna: 'Ganeasa'
      },
      {
        nume: 'Grădiştea',
        simplu: 'Gradistea',
        comuna: 'Ganeasa'
      },
      {
        nume: 'Izvoru',
        comuna: 'Ganeasa'
      },
      {
        nume: 'Oltişoru',
        simplu: 'Oltisoru',
        comuna: 'Ganeasa'
      },
      {
        nume: 'Ursa',
        comuna: 'Garcov'
      },
      {
        nume: 'Slăveni',
        simplu: 'Slaveni',
        comuna: 'Gostavatu'
      },
      {
        nume: 'Petculeşti',
        simplu: 'Petculesti',
        comuna: 'Gradinari'
      },
      {
        nume: 'Runcu Mare',
        comuna: 'Gradinari'
      },
      {
        nume: 'Satu Nou',
        comuna: 'Gradinari'
      },
      {
        nume: 'Hotaru',
        comuna: 'Grojdibodu'
      },
      {
        nume: 'Potelu',
        comuna: 'Ianca'
      },
      {
        nume: 'Dobriceni',
        comuna: 'Iancu Jianu'
      },
      {
        nume: 'Preoteşti',
        simplu: 'Preotesti',
        comuna: 'Iancu Jianu'
      },
      {
        nume: 'Floru',
        comuna: 'Icoana'
      },
      {
        nume: 'Ursoaia',
        comuna: 'Icoana'
      },
      {
        nume: 'Alimăneşti',
        simplu: 'Alimanesti',
        comuna: 'Izvoarele'
      },
      {
        nume: 'Afumaţi',
        simplu: 'Afumati',
        comuna: 'Leleasca'
      },
      {
        nume: 'Greereşti',
        simplu: 'Greeresti',
        comuna: 'Leleasca'
      },
      {
        nume: 'Mierliceşti',
        simplu: 'Mierlicesti',
        comuna: 'Leleasca'
      },
      {
        nume: 'Toneşti',
        simplu: 'Tonesti',
        comuna: 'Leleasca'
      },
      {
        nume: 'Tufaru',
        comuna: 'Leleasca'
      },
      {
        nume: 'Urşi',
        simplu: 'Ursi',
        comuna: 'Leleasca'
      },
      {
        nume: 'Bălăneşti',
        simplu: 'Balanesti',
        comuna: 'Maruntei'
      },
      {
        nume: 'Malu Roşu',
        simplu: 'Malu Rosu',
        comuna: 'Maruntei'
      },
      {
        nume: 'Buşca',
        simplu: 'Busca',
        comuna: 'Mihaesti'
      },
      {
        nume: 'Ipoteşti',
        simplu: 'Ipotesti',
        comuna: 'Milcov'
      },
      {
        nume: 'Milcovu din Deal',
        comuna: 'Milcov'
      },
      {
        nume: 'Milcovu din Vale',
        comuna: 'Milcov'
      },
      {
        nume: 'Stejaru',
        comuna: 'Milcov'
      },
      {
        nume: 'Ulmi',
        comuna: 'Milcov'
      },
      {
        nume: 'Bărăşti',
        simplu: 'Barasti',
        comuna: 'Morunglav'
      },
      {
        nume: 'Ghioşani',
        simplu: 'Ghiosani',
        comuna: 'Morunglav'
      },
      {
        nume: 'Moruneşti',
        simplu: 'Morunesti',
        comuna: 'Morunglav'
      },
      {
        nume: 'Poiana Mare',
        comuna: 'Morunglav'
      },
      {
        nume: 'Bacea',
        comuna: 'Movileni'
      },
      {
        nume: 'Ghimpeţeni',
        simplu: 'Ghimpeteni',
        comuna: 'Nicolae Titulescu'
      },
      {
        nume: 'Ghimpeţenii Noi',
        simplu: 'Ghimpetenii Noi',
        comuna: 'Nicolae Titulescu'
      },
      {
        nume: 'Coteni',
        comuna: 'Obarsia'
      },
      {
        nume: 'Câmpu Părului',
        simplu: 'Campu Parului',
        comuna: 'Obarsia'
      },
      {
        nume: 'Obârşia Nouă',
        simplu: 'Obarsia Noua',
        comuna: 'Obarsia'
      },
      {
        nume: 'Tabonu',
        comuna: 'Obarsia'
      },
      {
        nume: 'Călui',
        simplu: 'Calui',
        comuna: 'Oboga'
      },
      {
        nume: 'Gura Căluiu',
        simplu: 'Gura Caluiu',
        comuna: 'Oboga'
      },
      {
        nume: 'Beria de Jos',
        comuna: 'Oporelu'
      },
      {
        nume: 'Beria de Sus',
        comuna: 'Oporelu'
      },
      {
        nume: 'Rădeşti',
        simplu: 'Radesti',
        comuna: 'Oporelu'
      },
      {
        nume: 'Optaşi',
        simplu: 'Optasi',
        comuna: 'Optasi-Magura'
      },
      {
        nume: 'Vităneşti',
        simplu: 'Vitanesti',
        comuna: 'Optasi-Magura'
      },
      {
        nume: 'Gura Padinii',
        comuna: 'Orlea'
      },
      {
        nume: 'Orlea Nouă',
        simplu: 'Orlea Noua',
        comuna: 'Orlea'
      },
      {
        nume: 'Satu Nou',
        comuna: 'Orlea'
      },
      {
        nume: 'Greci',
        comuna: 'Osica De Sus'
      },
      {
        nume: 'Ostrov',
        comuna: 'Osica De Sus'
      },
      {
        nume: 'Peretu',
        comuna: 'Osica De Sus'
      },
      {
        nume: 'Tomeni',
        comuna: 'Osica De Sus'
      },
      {
        nume: 'Vlăduleni',
        simplu: 'Vladuleni',
        comuna: 'Osica De Sus'
      },
      {
        nume: 'Butoi',
        comuna: 'Parscoveni'
      },
      {
        nume: 'Olari',
        comuna: 'Parscoveni'
      },
      {
        nume: 'Pîrşcoveni',
        simplu: 'Parscoveni',
        comuna: 'Parscoveni'
      },
      {
        nume: 'Sopârliţa',
        simplu: 'Soparlita',
        comuna: 'Parscoveni'
      },
      {
        nume: 'Bălteni',
        simplu: 'Balteni',
        comuna: 'Perieti'
      },
      {
        nume: 'Mierleştii de Sus',
        simplu: 'Mierlestii de Sus',
        comuna: 'Perieti'
      },
      {
        nume: 'Măgura',
        simplu: 'Magura',
        comuna: 'Perieti'
      },
      {
        nume: 'Bistriţa Nouă',
        simplu: 'Bistrita Noua',
        comuna: 'Piatra-Olt'
      },
      {
        nume: 'Criva de Jos',
        comuna: 'Piatra-Olt'
      },
      {
        nume: 'Criva de Sus',
        comuna: 'Piatra-Olt'
      },
      {
        nume: 'Enoşeşti',
        simplu: 'Enosesti',
        comuna: 'Piatra-Olt'
      },
      {
        nume: 'Piatra',
        comuna: 'Piatra-Olt'
      },
      {
        nume: 'Arceşti',
        simplu: 'Arcesti',
        comuna: 'Plesoiu'
      },
      {
        nume: 'Arceşti Cot',
        simplu: 'Arcesti Cot',
        comuna: 'Plesoiu'
      },
      {
        nume: 'Cocorăşti',
        simplu: 'Cocorasti',
        comuna: 'Plesoiu'
      },
      {
        nume: 'Doba',
        comuna: 'Plesoiu'
      },
      {
        nume: 'Schitu din Deal',
        comuna: 'Plesoiu'
      },
      {
        nume: 'Schitu din Vale',
        comuna: 'Plesoiu'
      },
      {
        nume: 'Albeşti',
        simplu: 'Albesti',
        comuna: 'Poboru'
      },
      {
        nume: 'Cornăţelu',
        simplu: 'Cornatelu',
        comuna: 'Poboru'
      },
      {
        nume: 'Creţi',
        simplu: 'Creti',
        comuna: 'Poboru'
      },
      {
        nume: 'Seaca',
        comuna: 'Poboru'
      },
      {
        nume: 'Surpeni',
        comuna: 'Poboru'
      },
      {
        nume: 'Potcoava Fălcoeni',
        simplu: 'Potcoava Falcoeni',
        comuna: 'Potcoava'
      },
      {
        nume: 'Sineşti',
        simplu: 'Sinesti',
        comuna: 'Potcoava'
      },
      {
        nume: 'Trufineşti',
        simplu: 'Trufinesti',
        comuna: 'Potcoava'
      },
      {
        nume: 'Valea Merilor',
        comuna: 'Potcoava'
      },
      {
        nume: 'Buiceşti',
        simplu: 'Buicesti',
        comuna: 'Priseaca'
      },
      {
        nume: 'Săltăneşti',
        simplu: 'Saltanesti',
        comuna: 'Priseaca'
      },
      {
        nume: 'Crăciunei',
        simplu: 'Craciunei',
        comuna: 'Radomiresti'
      },
      {
        nume: 'Călineşti',
        simplu: 'Calinesti',
        comuna: 'Radomiresti'
      },
      {
        nume: 'Poiana',
        comuna: 'Radomiresti'
      },
      {
        nume: 'Redişoara',
        simplu: 'Redisoara',
        comuna: 'Redea'
      },
      {
        nume: 'Valea Soarelui',
        comuna: 'Redea'
      },
      {
        nume: 'Jieni',
        comuna: 'Rusanesti'
      },
      {
        nume: 'Cerbeni',
        comuna: 'Samburesti'
      },
      {
        nume: 'Ioniceşti',
        simplu: 'Ionicesti',
        comuna: 'Samburesti'
      },
      {
        nume: 'Lăunele',
        simplu: 'Launele',
        comuna: 'Samburesti'
      },
      {
        nume: 'Mănuleşti',
        simplu: 'Manulesti',
        comuna: 'Samburesti'
      },
      {
        nume: 'Stănuleasa',
        simplu: 'Stanuleasa',
        comuna: 'Samburesti'
      },
      {
        nume: 'Toneşti',
        simplu: 'Tonesti',
        comuna: 'Samburesti'
      },
      {
        nume: 'Plăviceni',
        simplu: 'Plaviceni',
        comuna: 'Scarisoara'
      },
      {
        nume: 'Rudari',
        comuna: 'Scarisoara'
      },
      {
        nume: 'Catanele',
        comuna: 'Schitu'
      },
      {
        nume: 'Greci',
        comuna: 'Schitu'
      },
      {
        nume: 'Lisa',
        comuna: 'Schitu'
      },
      {
        nume: 'Moşteni',
        simplu: 'Mosteni',
        comuna: 'Schitu'
      },
      {
        nume: 'Bircii',
        comuna: 'Scornicesti'
      },
      {
        nume: 'Bălţaţi',
        simplu: 'Baltati',
        comuna: 'Scornicesti'
      },
      {
        nume: 'Chiţeasca',
        simplu: 'Chiteasca',
        comuna: 'Scornicesti'
      },
      {
        nume: 'Constantineşti',
        simplu: 'Constantinesti',
        comuna: 'Scornicesti'
      },
      {
        nume: 'Jitaru',
        comuna: 'Scornicesti'
      },
      {
        nume: 'Mihăileşti Popeşti',
        simplu: 'Mihailesti Popesti',
        comuna: 'Scornicesti'
      },
      {
        nume: 'Mogoşeşti',
        simplu: 'Mogosesti',
        comuna: 'Scornicesti'
      },
      {
        nume: 'Mărgineni Slobozia',
        simplu: 'Margineni Slobozia',
        comuna: 'Scornicesti'
      },
      {
        nume: 'Negreni',
        comuna: 'Scornicesti'
      },
      {
        nume: 'Piscani',
        comuna: 'Scornicesti'
      },
      {
        nume: 'Rusciori',
        comuna: 'Scornicesti'
      },
      {
        nume: 'Teiuş',
        simplu: 'Teius',
        comuna: 'Scornicesti'
      },
      {
        nume: 'Suica',
        simplu: 'Suica',
        comuna: 'Scornicesti'
      },
      {
        nume: 'Strugurelu',
        comuna: 'Serbanesti'
      },
      {
        nume: 'Serbăneştii de Sus',
        simplu: 'Serbanestii de Sus',
        comuna: 'Serbanesti'
      },
      {
        nume: 'Cireaşov',
        simplu: 'Cireasov',
        comuna: 'Slatina'
      },
      {
        nume: 'Salcia',
        comuna: 'Slatioara'
      },
      {
        nume: 'Alunişu',
        simplu: 'Alunisu',
        comuna: 'Spineni'
      },
      {
        nume: 'Cuza Vodă',
        simplu: 'Cuza Voda',
        comuna: 'Spineni'
      },
      {
        nume: 'Davideşti',
        simplu: 'Davidesti',
        comuna: 'Spineni'
      },
      {
        nume: 'Optăşani',
        simplu: 'Optasani',
        comuna: 'Spineni'
      },
      {
        nume: 'Profa',
        comuna: 'Spineni'
      },
      {
        nume: 'Vineţi',
        simplu: 'Vineti',
        comuna: 'Spineni'
      },
      {
        nume: 'Bârseştii de Sus',
        simplu: 'Barsestii de Sus',
        comuna: 'Sprancenata'
      },
      {
        nume: 'Frunzaru',
        comuna: 'Sprancenata'
      },
      {
        nume: 'Uria',
        comuna: 'Sprancenata'
      },
      {
        nume: 'Ianca Nouă',
        simplu: 'Ianca Noua',
        comuna: 'Stefan Cel Mare'
      },
      {
        nume: 'Colibaşi',
        simplu: 'Colibasi',
        comuna: 'Strejesti'
      },
      {
        nume: 'Mamura',
        comuna: 'Strejesti'
      },
      {
        nume: 'Strejeştii de Sus',
        simplu: 'Strejestii de Sus',
        comuna: 'Strejesti'
      },
      {
        nume: 'Arvăteasca',
        simplu: 'Arvateasca',
        comuna: 'Studina'
      },
      {
        nume: 'Grădinile',
        simplu: 'Gradinile',
        comuna: 'Studina'
      },
      {
        nume: 'Plăviceanca',
        simplu: 'Plaviceanca',
        comuna: 'Studina'
      },
      {
        nume: 'Studiniţa',
        simplu: 'Studinita',
        comuna: 'Studina'
      },
      {
        nume: 'Bărbălăi',
        simplu: 'Barbalai',
        comuna: 'Tatulesti'
      },
      {
        nume: 'Lunca',
        comuna: 'Tatulesti'
      },
      {
        nume: 'Mirceşti',
        simplu: 'Mircesti',
        comuna: 'Tatulesti'
      },
      {
        nume: 'Momaiu',
        comuna: 'Tatulesti'
      },
      {
        nume: 'Măgura',
        simplu: 'Magura',
        comuna: 'Tatulesti'
      },
      {
        nume: 'Cherleştii Moşteni',
        simplu: 'Cherlestii Mosteni',
        comuna: 'Teslui'
      },
      {
        nume: 'Cherleştii din Deal',
        simplu: 'Cherlestii din Deal',
        comuna: 'Teslui'
      },
      {
        nume: 'Comăniţa',
        simplu: 'Comanita',
        comuna: 'Teslui'
      },
      {
        nume: 'Corbu',
        comuna: 'Teslui'
      },
      {
        nume: 'Deleni',
        comuna: 'Teslui'
      },
      {
        nume: 'Schitu Deleni',
        comuna: 'Teslui'
      },
      {
        nume: 'Doanca',
        comuna: 'Tia Mare'
      },
      {
        nume: 'Potlogeni',
        comuna: 'Tia Mare'
      },
      {
        nume: 'Ciorâca',
        simplu: 'Cioraca',
        comuna: 'Topana'
      },
      {
        nume: 'Cojgărei',
        simplu: 'Cojgarei',
        comuna: 'Topana'
      },
      {
        nume: 'Cândeleşti',
        simplu: 'Candelesti',
        comuna: 'Topana'
      },
      {
        nume: 'Ungureni',
        comuna: 'Topana'
      },
      {
        nume: 'Barza',
        comuna: 'Tufeni'
      },
      {
        nume: 'Stoborăşti',
        simplu: 'Stoborasti',
        comuna: 'Tufeni'
      },
      {
        nume: 'Stăvaru',
        simplu: 'Stavaru',
        comuna: 'Urzica'
      },
      {
        nume: 'Vişina Nouă',
        simplu: 'Visina Noua',
        comuna: 'Vadastra'
      },
      {
        nume: 'Bărcăneşti',
        simplu: 'Barcanesti',
        comuna: 'Valcele'
      },
      {
        nume: 'Vâlcelele de Sus',
        simplu: 'Valcelele de Sus',
        comuna: 'Valcele'
      },
      {
        nume: 'Bârca',
        simplu: 'Barca',
        comuna: 'Valea Mare'
      },
      {
        nume: 'Recea',
        comuna: 'Valea Mare'
      },
      {
        nume: 'Turia',
        comuna: 'Valea Mare'
      },
      {
        nume: 'Zorleasca',
        comuna: 'Valea Mare'
      },
      {
        nume: 'Mandra',
        comuna: 'Valeni'
      },
      {
        nume: 'Popeşti',
        simplu: 'Popesti',
        comuna: 'Valeni'
      },
      {
        nume: 'Tirişneag',
        simplu: 'Tirisneag',
        comuna: 'Valeni'
      },
      {
        nume: 'Cucueţi',
        simplu: 'Cucueti',
        comuna: 'Verguleasa'
      },
      {
        nume: 'Căzăneşti',
        simplu: 'Cazanesti',
        comuna: 'Verguleasa'
      },
      {
        nume: 'Dumitreşti',
        simplu: 'Dumitresti',
        comuna: 'Verguleasa'
      },
      {
        nume: 'Poganu',
        comuna: 'Verguleasa'
      },
      {
        nume: 'Valea Fetei',
        comuna: 'Verguleasa'
      },
      {
        nume: 'Vâneşti',
        simplu: 'Vanesti',
        comuna: 'Verguleasa'
      },
      {
        nume: 'Bulimanu',
        comuna: 'Vitomiresti'
      },
      {
        nume: 'Dejeşti',
        simplu: 'Dejesti',
        comuna: 'Vitomiresti'
      },
      {
        nume: 'Doneşti',
        simplu: 'Donesti',
        comuna: 'Vitomiresti'
      },
      {
        nume: 'Stănuleasa',
        simplu: 'Stanuleasa',
        comuna: 'Vitomiresti'
      },
      {
        nume: 'Trepteni',
        comuna: 'Vitomiresti'
      },
      {
        nume: 'Frăsinet Gară',
        simplu: 'Frasinet Gara',
        comuna: 'Vladila'
      },
      {
        nume: 'Vlădila Nouă',
        simplu: 'Vladila Noua',
        comuna: 'Vladila'
      },
      {
        nume: 'Blaj',
        comuna: 'Voineasa'
      },
      {
        nume: 'Mărgăriteşti',
        simplu: 'Margaritesti',
        comuna: 'Voineasa'
      },
      {
        nume: 'Racoviţa',
        simplu: 'Racovita',
        comuna: 'Voineasa'
      },
      {
        nume: 'Rusăneştii de Sus',
        simplu: 'Rusanestii de Sus',
        comuna: 'Voineasa'
      },
      {
        nume: 'Cotorbeşti',
        simplu: 'Cotorbesti',
        comuna: 'Vulpeni'
      },
      {
        nume: 'Gropşani',
        simplu: 'Gropsani',
        comuna: 'Vulpeni'
      },
      {
        nume: 'Mardale',
        comuna: 'Vulpeni'
      },
      {
        nume: 'Pescăreşti',
        simplu: 'Pescaresti',
        comuna: 'Vulpeni'
      },
      {
        nume: 'Plopşorelu',
        simplu: 'Plopsorelu',
        comuna: 'Vulpeni'
      },
      {
        nume: 'Prisaca',
        comuna: 'Vulpeni'
      },
      {
        nume: 'Simniceni',
        comuna: 'Vulpeni'
      },
      {
        nume: 'Tabaci',
        comuna: 'Vulpeni'
      },
      {
        nume: 'Valea Satului',
        comuna: 'Vulpeni'
      },
      {
        nume: 'Dienci',
        comuna: 'Vulturesti'
      },
      {
        nume: 'Valea lui Alb',
        comuna: 'Vulturesti'
      },
      {
        nume: 'Vlăngăreşti',
        simplu: 'Vlangaresti',
        comuna: 'Vulturesti'
      }
    ]
  },
  {
    auto: 'PH',
    nume: 'Prahova',
    localitati: [
      {
        nume: 'Adunaţi',
        simplu: 'Adunati'
      },
      {
        nume: 'Albeşti-Paleologu',
        simplu: 'Albesti-Paleologu'
      },
      {
        nume: 'Aluniş',
        simplu: 'Alunis'
      },
      {
        nume: 'Apostolache'
      },
      {
        nume: 'Ariceştii Rahtivani',
        simplu: 'Aricestii Rahtivani'
      },
      {
        nume: 'Ariceştii Zeletin',
        simplu: 'Aricestii Zeletin'
      },
      {
        nume: 'Azuga'
      },
      {
        nume: 'Baba Ana'
      },
      {
        nume: 'Balta Doamnei'
      },
      {
        nume: 'Berceni'
      },
      {
        nume: 'Bertea'
      },
      {
        nume: 'Blejoi'
      },
      {
        nume: 'Boldeşti-Scăeni',
        simplu: 'Boldesti-Scaeni'
      },
      {
        nume: 'Bucov'
      },
      {
        nume: 'Buşteni',
        simplu: 'Busteni'
      },
      {
        nume: 'Băicoi',
        simplu: 'Baicoi'
      },
      {
        nume: 'Bălţeşti',
        simplu: 'Baltesti'
      },
      {
        nume: 'Băneşti',
        simplu: 'Banesti'
      },
      {
        nume: 'Bărcăneşti',
        simplu: 'Barcanesti'
      },
      {
        nume: 'Ceraşu',
        simplu: 'Cerasu'
      },
      {
        nume: 'Chiojdeanca'
      },
      {
        nume: 'Cocorăştii Mislii',
        simplu: 'Cocorastii Mislii'
      },
      {
        nume: 'Colceag'
      },
      {
        nume: 'Comarnic'
      },
      {
        nume: 'Câmpina',
        simplu: 'Campina'
      },
      {
        nume: 'Călugăreni',
        simplu: 'Calugareni'
      },
      {
        nume: 'Cărbuneşti',
        simplu: 'Carbunesti'
      },
      {
        nume: 'Drăgăneşti',
        simplu: 'Draganesti'
      },
      {
        nume: 'Dumbrava'
      },
      {
        nume: 'Dumbrăveşti',
        simplu: 'Dumbravesti'
      },
      {
        nume: 'Filipeştii de Pădure',
        simplu: 'Filipestii de Padure'
      },
      {
        nume: 'Filipeştii de Târg',
        simplu: 'Filipestii de Targ'
      },
      {
        nume: 'Floreşti',
        simplu: 'Floresti'
      },
      {
        nume: 'Fântânele',
        simplu: 'Fantanele'
      },
      {
        nume: 'Gherghiţa',
        simplu: 'Gherghita'
      },
      {
        nume: 'Gorgota'
      },
      {
        nume: 'Gornet'
      },
      {
        nume: 'Gornet-Cricov'
      },
      {
        nume: 'Gura Vadului'
      },
      {
        nume: 'Gura Vitioarei'
      },
      {
        nume: 'Iordăcheanu',
        simplu: 'Iordacheanu'
      },
      {
        nume: 'Izvoarele'
      },
      {
        nume: 'Jugureni'
      },
      {
        nume: 'Lapoş',
        simplu: 'Lapos'
      },
      {
        nume: 'Lipăneşti',
        simplu: 'Lipanesti'
      },
      {
        nume: 'Mizil'
      },
      {
        nume: 'Măgurele',
        simplu: 'Magurele'
      },
      {
        nume: 'Măgureni',
        simplu: 'Magureni'
      },
      {
        nume: 'Măneşti',
        simplu: 'Manesti'
      },
      {
        nume: 'Ploieşti',
        simplu: 'Ploiesti'
      },
      {
        nume: 'Plopeni'
      },
      {
        nume: 'Plopu'
      },
      {
        nume: 'Podenii Noi'
      },
      {
        nume: 'Poiana Câmpina',
        simplu: 'Poiana Campina'
      },
      {
        nume: 'Poienarii Burchii'
      },
      {
        nume: 'Proviţa de Jos',
        simplu: 'Provita de Jos'
      },
      {
        nume: 'Proviţa de Sus',
        simplu: 'Provita de Sus'
      },
      {
        nume: 'Puchenii Mari'
      },
      {
        nume: 'Păcureţi',
        simplu: 'Pacureti'
      },
      {
        nume: 'Păuleşti',
        simplu: 'Paulesti'
      },
      {
        nume: 'Râfov',
        simplu: 'Rafov'
      },
      {
        nume: 'Salcia'
      },
      {
        nume: 'Scorţeni',
        simplu: 'Scorteni'
      },
      {
        nume: 'Secăria',
        simplu: 'Secaria'
      },
      {
        nume: 'Sinaia'
      },
      {
        nume: 'Slănic',
        simplu: 'Slanic'
      },
      {
        nume: 'Starchiojd'
      },
      {
        nume: 'Surani'
      },
      {
        nume: 'Sângeru',
        simplu: 'Sangeru'
      },
      {
        nume: 'Sălciile',
        simplu: 'Salciile'
      },
      {
        nume: 'Talea'
      },
      {
        nume: 'Teişani',
        simplu: 'Teisani'
      },
      {
        nume: 'Telega'
      },
      {
        nume: 'Tinosu'
      },
      {
        nume: 'Tomşani',
        simplu: 'Tomsani'
      },
      {
        nume: 'Târgşoru Vechi',
        simplu: 'Targsoru Vechi'
      },
      {
        nume: 'Tătaru',
        simplu: 'Tataru'
      },
      {
        nume: 'Urlaţi',
        simplu: 'Urlati'
      },
      {
        nume: 'Valea Călugărească',
        simplu: 'Valea Calugareasca'
      },
      {
        nume: 'Vâlcăneşti',
        simplu: 'Valcanesti'
      },
      {
        nume: 'Vălenii de Munte',
        simplu: 'Valenii de Munte'
      },
      {
        nume: 'Vărbilău',
        simplu: 'Varbilau'
      },
      {
        nume: 'Sirna',
        simplu: 'Sirna'
      },
      {
        nume: 'Soimari',
        simplu: 'Soimari'
      },
      {
        nume: 'Sotrile',
        simplu: 'Sotrile'
      },
      {
        nume: 'Stefeşti',
        simplu: 'Stefesti'
      },
      {
        nume: 'Ocina de Jos',
        comuna: 'Adunati'
      },
      {
        nume: 'Ocina de Sus',
        comuna: 'Adunati'
      },
      {
        nume: 'Albeşti-Muru',
        simplu: 'Albesti-Muru',
        comuna: 'Albesti-Paleologu'
      },
      {
        nume: 'Cioceni',
        comuna: 'Albesti-Paleologu'
      },
      {
        nume: 'Vadu Părului',
        simplu: 'Vadu Parului',
        comuna: 'Albesti-Paleologu'
      },
      {
        nume: 'Ostrovu',
        comuna: 'Alunis'
      },
      {
        nume: 'Buzota',
        comuna: 'Apostolache'
      },
      {
        nume: 'Mârlogea',
        simplu: 'Marlogea',
        comuna: 'Apostolache'
      },
      {
        nume: 'Udreşti',
        simplu: 'Udresti',
        comuna: 'Apostolache'
      },
      {
        nume: 'Valea Cricovului',
        comuna: 'Apostolache'
      },
      {
        nume: 'Buda',
        comuna: 'Aricestii Rahtivani'
      },
      {
        nume: 'Nedelea',
        comuna: 'Aricestii Rahtivani'
      },
      {
        nume: 'Stoeneşti',
        simplu: 'Stoenesti',
        comuna: 'Aricestii Rahtivani'
      },
      {
        nume: 'Târgşoru Nou',
        simplu: 'Targsoru Nou',
        comuna: 'Aricestii Rahtivani'
      },
      {
        nume: 'Albinari',
        comuna: 'Aricestii Zeletin'
      },
      {
        nume: 'Cireşanu',
        simplu: 'Ciresanu',
        comuna: 'Baba Ana'
      },
      {
        nume: 'Conduratu',
        comuna: 'Baba Ana'
      },
      {
        nume: 'Crângurile',
        simplu: 'Crangurile',
        comuna: 'Baba Ana'
      },
      {
        nume: 'Satu Nou',
        comuna: 'Baba Ana'
      },
      {
        nume: 'Dâmbu',
        simplu: 'Dambu',
        comuna: 'Baicoi'
      },
      {
        nume: 'Lilieşti',
        simplu: 'Liliesti',
        comuna: 'Baicoi'
      },
      {
        nume: 'Schela',
        comuna: 'Baicoi'
      },
      {
        nume: 'Tufeni',
        comuna: 'Baicoi'
      },
      {
        nume: 'Tintea',
        simplu: 'Tintea',
        comuna: 'Baicoi'
      },
      {
        nume: 'Bâra',
        simplu: 'Bara',
        comuna: 'Balta Doamnei'
      },
      {
        nume: 'Curcubeu',
        comuna: 'Balta Doamnei'
      },
      {
        nume: 'Lacu Turcului',
        comuna: 'Balta Doamnei'
      },
      {
        nume: 'Izeşti',
        simplu: 'Izesti',
        comuna: 'Baltesti'
      },
      {
        nume: 'Podenii Vechi',
        comuna: 'Baltesti'
      },
      {
        nume: 'Urleta',
        comuna: 'Banesti'
      },
      {
        nume: 'Ghighiu',
        comuna: 'Barcanesti'
      },
      {
        nume: 'Puşcaşi',
        simplu: 'Puscasi',
        comuna: 'Barcanesti'
      },
      {
        nume: 'Româneşti',
        simplu: 'Romanesti',
        comuna: 'Barcanesti'
      },
      {
        nume: 'Tătărani',
        simplu: 'Tatarani',
        comuna: 'Barcanesti'
      },
      {
        nume: 'Cartierul Dâmbu',
        simplu: 'Cartierul Dambu',
        comuna: 'Berceni'
      },
      {
        nume: 'Corlăteşti',
        simplu: 'Corlatesti',
        comuna: 'Berceni'
      },
      {
        nume: 'Cătunu',
        simplu: 'Catunu',
        comuna: 'Berceni'
      },
      {
        nume: 'Moara Nouă',
        simplu: 'Moara Noua',
        comuna: 'Berceni'
      },
      {
        nume: 'Lutu Roşu',
        simplu: 'Lutu Rosu',
        comuna: 'Bertea'
      },
      {
        nume: 'Ploieştiori',
        simplu: 'Ploiestiori',
        comuna: 'Blejoi'
      },
      {
        nume: 'Tânţăreni',
        simplu: 'Tantareni',
        comuna: 'Blejoi'
      },
      {
        nume: 'Boldeşti',
        simplu: 'Boldesti',
        comuna: 'Boldesti-Gradistea'
      },
      {
        nume: 'Gradiştea',
        simplu: 'Gradistea',
        comuna: 'Boldesti-Gradistea'
      },
      {
        nume: 'Seciu',
        comuna: 'Boldesti-Scaeni'
      },
      {
        nume: 'Brazii de Jos',
        comuna: 'Brazi'
      },
      {
        nume: 'Brazii de Sus',
        comuna: 'Brazi'
      },
      {
        nume: 'Băteşti',
        simplu: 'Batesti',
        comuna: 'Brazi'
      },
      {
        nume: 'Negoieşti',
        simplu: 'Negoiesti',
        comuna: 'Brazi'
      },
      {
        nume: 'Popeşti',
        simplu: 'Popesti',
        comuna: 'Brazi'
      },
      {
        nume: 'Stejaru',
        comuna: 'Brazi'
      },
      {
        nume: 'Breaza de Jos',
        comuna: 'Breaza'
      },
      {
        nume: 'Breaza de Sus',
        comuna: 'Breaza'
      },
      {
        nume: 'Frăsinet',
        simplu: 'Frasinet',
        comuna: 'Breaza'
      },
      {
        nume: 'Gura Beliei',
        comuna: 'Breaza'
      },
      {
        nume: 'Irimeşti',
        simplu: 'Irimesti',
        comuna: 'Breaza'
      },
      {
        nume: 'Nistoreşti',
        simplu: 'Nistoresti',
        comuna: 'Breaza'
      },
      {
        nume: 'Podu Corbului',
        comuna: 'Breaza'
      },
      {
        nume: 'Podu Vadului',
        comuna: 'Breaza'
      },
      {
        nume: 'Surdeşti',
        simplu: 'Surdesti',
        comuna: 'Breaza'
      },
      {
        nume: 'Valea Târsei',
        simplu: 'Valea Tarsei',
        comuna: 'Breaza'
      },
      {
        nume: 'Brebu Megieşesc',
        simplu: 'Brebu Megiesesc',
        comuna: 'Brebu'
      },
      {
        nume: 'Brebu Mânăstirei',
        simplu: 'Brebu Manastirei',
        comuna: 'Brebu'
      },
      {
        nume: 'Pietriceaua',
        comuna: 'Brebu'
      },
      {
        nume: 'Podu Cheii',
        comuna: 'Brebu'
      },
      {
        nume: 'Bighilin',
        comuna: 'Bucov'
      },
      {
        nume: 'Chiţorani',
        simplu: 'Chitorani',
        comuna: 'Bucov'
      },
      {
        nume: 'Pleaşa',
        simplu: 'Pleasa',
        comuna: 'Bucov'
      },
      {
        nume: 'Valea Orlei',
        comuna: 'Bucov'
      },
      {
        nume: 'Poiana Tapului',
        simplu: 'Poiana Tapului',
        comuna: 'Busteni'
      },
      {
        nume: 'Valea Scheilor',
        comuna: 'Calugareni'
      },
      {
        nume: 'Gogeasca',
        comuna: 'Carbunesti'
      },
      {
        nume: 'Ceptura de Jos',
        comuna: 'Ceptura'
      },
      {
        nume: 'Ceptura de Sus',
        comuna: 'Ceptura'
      },
      {
        nume: 'Malu Roşu',
        simplu: 'Malu Rosu',
        comuna: 'Ceptura'
      },
      {
        nume: 'Rotari',
        comuna: 'Ceptura'
      },
      {
        nume: 'Soimeşti',
        simplu: 'Soimesti',
        comuna: 'Ceptura'
      },
      {
        nume: 'Slon',
        comuna: 'Cerasu'
      },
      {
        nume: 'Valea Borului',
        comuna: 'Cerasu'
      },
      {
        nume: 'Valea Brădetului',
        simplu: 'Valea Bradetului',
        comuna: 'Cerasu'
      },
      {
        nume: 'Valea Lespezii',
        comuna: 'Cerasu'
      },
      {
        nume: 'Valea Tocii',
        comuna: 'Cerasu'
      },
      {
        nume: 'Nucet',
        comuna: 'Chiojdeanca'
      },
      {
        nume: 'Trenu',
        comuna: 'Chiojdeanca'
      },
      {
        nume: 'Cioranii de Jos',
        comuna: 'Ciorani'
      },
      {
        nume: 'Cioranii de Sus',
        comuna: 'Ciorani'
      },
      {
        nume: 'Goruna',
        comuna: 'Cocorastii Mislii'
      },
      {
        nume: 'Tipăreşti',
        simplu: 'Tiparesti',
        comuna: 'Cocorastii Mislii'
      },
      {
        nume: 'Inoteşti',
        simplu: 'Inotesti',
        comuna: 'Colceag'
      },
      {
        nume: 'Parepa-Ruşani',
        simplu: 'Parepa-Rusani',
        comuna: 'Colceag'
      },
      {
        nume: 'Vâlcelele',
        simplu: 'Valcelele',
        comuna: 'Colceag'
      },
      {
        nume: 'Ghioşeşti',
        simplu: 'Ghiosesti',
        comuna: 'Comarnic'
      },
      {
        nume: 'Podu Lung',
        comuna: 'Comarnic'
      },
      {
        nume: 'Poiana',
        comuna: 'Comarnic'
      },
      {
        nume: 'Posada',
        comuna: 'Comarnic'
      },
      {
        nume: 'Cornu de Jos',
        comuna: 'Cornu'
      },
      {
        nume: 'Cornu de Sus',
        comuna: 'Cornu'
      },
      {
        nume: 'Valea Oprii',
        comuna: 'Cornu'
      },
      {
        nume: 'Cosmina de Jos',
        comuna: 'Cosminele'
      },
      {
        nume: 'Cosmina de Sus',
        comuna: 'Cosminele'
      },
      {
        nume: 'Drăghiceşti',
        simplu: 'Draghicesti',
        comuna: 'Cosminele'
      },
      {
        nume: 'Poiana Trestiei',
        comuna: 'Cosminele'
      },
      {
        nume: 'Belciug',
        comuna: 'Draganesti'
      },
      {
        nume: 'Bărăitaru',
        simplu: 'Baraitaru',
        comuna: 'Draganesti'
      },
      {
        nume: 'Cornu de Jos',
        comuna: 'Draganesti'
      },
      {
        nume: 'Hătcărău',
        simplu: 'Hatcarau',
        comuna: 'Draganesti'
      },
      {
        nume: 'Meri',
        comuna: 'Draganesti'
      },
      {
        nume: 'Tufani',
        comuna: 'Draganesti'
      },
      {
        nume: 'Ciocrac',
        comuna: 'Drajna'
      },
      {
        nume: 'Cătunu',
        simplu: 'Catunu',
        comuna: 'Drajna'
      },
      {
        nume: 'Drajna de Jos',
        comuna: 'Drajna'
      },
      {
        nume: 'Drajna de Sus',
        comuna: 'Drajna'
      },
      {
        nume: 'Făget',
        simplu: 'Faget',
        comuna: 'Drajna'
      },
      {
        nume: 'Ogretin',
        comuna: 'Drajna'
      },
      {
        nume: 'Piatra',
        comuna: 'Drajna'
      },
      {
        nume: 'Piţigoi',
        simplu: 'Pitigoi',
        comuna: 'Drajna'
      },
      {
        nume: 'Plai',
        comuna: 'Drajna'
      },
      {
        nume: 'Podurile',
        comuna: 'Drajna'
      },
      {
        nume: 'Poiana Mierlei',
        comuna: 'Drajna'
      },
      {
        nume: 'Ciupelniţa',
        simplu: 'Ciupelnita',
        comuna: 'Dumbrava'
      },
      {
        nume: 'Cornu de Sus',
        comuna: 'Dumbrava'
      },
      {
        nume: 'Trestienii de Jos',
        comuna: 'Dumbrava'
      },
      {
        nume: 'Trestienii de Sus',
        comuna: 'Dumbrava'
      },
      {
        nume: 'Zănoaga',
        simplu: 'Zanoaga',
        comuna: 'Dumbrava'
      },
      {
        nume: 'Găvănel',
        simplu: 'Gavanel',
        comuna: 'Dumbravesti'
      },
      {
        nume: 'Mălăeştii de Jos',
        simplu: 'Malaestii de Jos',
        comuna: 'Dumbravesti'
      },
      {
        nume: 'Mălăeştii de Sus',
        simplu: 'Malaestii de Sus',
        comuna: 'Dumbravesti'
      },
      {
        nume: 'Plopeni',
        comuna: 'Dumbravesti'
      },
      {
        nume: 'Sfârleanca',
        simplu: 'Sfarleanca',
        comuna: 'Dumbravesti'
      },
      {
        nume: 'Bozieni',
        comuna: 'Fantanele'
      },
      {
        nume: 'Ghinoaica',
        comuna: 'Fantanele'
      },
      {
        nume: 'Ungureni',
        comuna: 'Fantanele'
      },
      {
        nume: 'Vadu Săpat',
        simplu: 'Vadu Sapat',
        comuna: 'Fantanele'
      },
      {
        nume: 'Diţeşti',
        simplu: 'Ditesti',
        comuna: 'Filipestii De Padure'
      },
      {
        nume: 'Minieri',
        comuna: 'Filipestii De Padure'
      },
      {
        nume: 'Siliştea Dealului',
        simplu: 'Silistea Dealului',
        comuna: 'Filipestii De Padure'
      },
      {
        nume: 'Brătăşanca',
        simplu: 'Bratasanca',
        comuna: 'Filipestii De Targ'
      },
      {
        nume: 'Ezeni',
        comuna: 'Filipestii De Targ'
      },
      {
        nume: 'Mărginenii de Jos',
        simplu: 'Marginenii de Jos',
        comuna: 'Filipestii De Targ'
      },
      {
        nume: 'Ungureni',
        comuna: 'Filipestii De Targ'
      },
      {
        nume: 'Cap Roşu',
        simplu: 'Cap Rosu',
        comuna: 'Floresti'
      },
      {
        nume: 'Călineşti',
        simplu: 'Calinesti',
        comuna: 'Floresti'
      },
      {
        nume: 'Cătina',
        simplu: 'Catina',
        comuna: 'Floresti'
      },
      {
        nume: 'Novăceşti',
        simplu: 'Novacesti',
        comuna: 'Floresti'
      },
      {
        nume: 'Fulga de Jos',
        comuna: 'Fulga'
      },
      {
        nume: 'Fulga de Sus',
        comuna: 'Fulga'
      },
      {
        nume: 'Fânari',
        simplu: 'Fanari',
        comuna: 'Gherghita'
      },
      {
        nume: 'Independenţa',
        simplu: 'Independenta',
        comuna: 'Gherghita'
      },
      {
        nume: 'Malamuc',
        comuna: 'Gherghita'
      },
      {
        nume: 'Olari',
        comuna: 'Gherghita'
      },
      {
        nume: 'Olarii Vechi',
        comuna: 'Gherghita'
      },
      {
        nume: 'Ungureni',
        comuna: 'Gherghita'
      },
      {
        nume: 'Crivina',
        comuna: 'Gorgota'
      },
      {
        nume: 'Fânari',
        simplu: 'Fanari',
        comuna: 'Gorgota'
      },
      {
        nume: 'Poienarii Apostoli',
        comuna: 'Gorgota'
      },
      {
        nume: 'Potigrafu',
        comuna: 'Gorgota'
      },
      {
        nume: 'Bogdăneşti',
        simplu: 'Bogdanesti',
        comuna: 'Gornet'
      },
      {
        nume: 'Cuib',
        comuna: 'Gornet'
      },
      {
        nume: 'Nucet',
        comuna: 'Gornet'
      },
      {
        nume: 'Coşerele',
        simplu: 'Coserele',
        comuna: 'Gornet-Cricov'
      },
      {
        nume: 'Dobrota',
        comuna: 'Gornet-Cricov'
      },
      {
        nume: 'Priseaca',
        comuna: 'Gornet-Cricov'
      },
      {
        nume: 'Valea Seacă',
        simplu: 'Valea Seaca',
        comuna: 'Gornet-Cricov'
      },
      {
        nume: 'Tărculeşti',
        simplu: 'Tarculesti',
        comuna: 'Gornet-Cricov'
      },
      {
        nume: 'Perşunari',
        simplu: 'Persunari',
        comuna: 'Gura Vadului'
      },
      {
        nume: 'Tohani',
        comuna: 'Gura Vadului'
      },
      {
        nume: 'Bughea de Jos',
        comuna: 'Gura Vitioarei'
      },
      {
        nume: 'Fundeni',
        comuna: 'Gura Vitioarei'
      },
      {
        nume: 'Făgetu',
        simplu: 'Fagetu',
        comuna: 'Gura Vitioarei'
      },
      {
        nume: 'Poiana Copăceni',
        simplu: 'Poiana Copaceni',
        comuna: 'Gura Vitioarei'
      },
      {
        nume: 'Moceşti',
        simplu: 'Mocesti',
        comuna: 'Iordacheanu'
      },
      {
        nume: 'Plavia',
        comuna: 'Iordacheanu'
      },
      {
        nume: 'Străoşti',
        simplu: 'Straosti',
        comuna: 'Iordacheanu'
      },
      {
        nume: 'Valea Cucului',
        comuna: 'Iordacheanu'
      },
      {
        nume: 'Vărbila',
        simplu: 'Varbila',
        comuna: 'Iordacheanu'
      },
      {
        nume: 'Cerneşti',
        simplu: 'Cernesti',
        comuna: 'Izvoarele'
      },
      {
        nume: 'Chiriţeşti',
        simplu: 'Chiritesti',
        comuna: 'Izvoarele'
      },
      {
        nume: 'Homorâciu',
        simplu: 'Homoraciu',
        comuna: 'Izvoarele'
      },
      {
        nume: 'Malu Vânăt',
        simplu: 'Malu Vanat',
        comuna: 'Izvoarele'
      },
      {
        nume: 'Schiuleşti',
        simplu: 'Schiulesti',
        comuna: 'Izvoarele'
      },
      {
        nume: 'Boboci',
        comuna: 'Jugureni'
      },
      {
        nume: 'Marginea Pădurii',
        simplu: 'Marginea Padurii',
        comuna: 'Jugureni'
      },
      {
        nume: 'Valea Unghiului',
        comuna: 'Jugureni'
      },
      {
        nume: 'Glod',
        comuna: 'Lapos'
      },
      {
        nume: 'Lăpoşel',
        simplu: 'Laposel',
        comuna: 'Lapos'
      },
      {
        nume: 'Pietricica',
        comuna: 'Lapos'
      },
      {
        nume: 'Satu Nou',
        comuna: 'Lipanesti'
      },
      {
        nume: 'Zamfira',
        comuna: 'Lipanesti'
      },
      {
        nume: 'Sipotu',
        simplu: 'Sipotu',
        comuna: 'Lipanesti'
      },
      {
        nume: 'Coada Malului',
        comuna: 'Magurele'
      },
      {
        nume: 'Iazu',
        comuna: 'Magurele'
      },
      {
        nume: 'Cocorăştii Caplii',
        simplu: 'Cocorastii Caplii',
        comuna: 'Magureni'
      },
      {
        nume: 'Lunca Prahovei',
        comuna: 'Magureni'
      },
      {
        nume: 'Cheia',
        comuna: 'Maneciu'
      },
      {
        nume: 'Chiciureni',
        comuna: 'Maneciu'
      },
      {
        nume: 'Costeni',
        comuna: 'Maneciu'
      },
      {
        nume: 'Făcăieni',
        simplu: 'Facaieni',
        comuna: 'Maneciu'
      },
      {
        nume: 'Gheaba',
        comuna: 'Maneciu'
      },
      {
        nume: 'Mânăstirea Suzana',
        simplu: 'Manastirea Suzana',
        comuna: 'Maneciu'
      },
      {
        nume: 'Măneciu-Pământeni',
        simplu: 'Maneciu-Pamanteni',
        comuna: 'Maneciu'
      },
      {
        nume: 'Măneciu-Ungureni',
        simplu: 'Maneciu-Ungureni',
        comuna: 'Maneciu'
      },
      {
        nume: 'Plăieţu',
        simplu: 'Plaietu',
        comuna: 'Maneciu'
      },
      {
        nume: 'Băltiţa',
        simplu: 'Baltita',
        comuna: 'Manesti'
      },
      {
        nume: 'Cheşnoiu',
        simplu: 'Chesnoiu',
        comuna: 'Manesti'
      },
      {
        nume: 'Coada Izvorului',
        comuna: 'Manesti'
      },
      {
        nume: 'Cocorăştii Colţ',
        simplu: 'Cocorastii Colt',
        comuna: 'Manesti'
      },
      {
        nume: 'Cocorăştii Grind',
        simplu: 'Cocorastii Grind',
        comuna: 'Manesti'
      },
      {
        nume: 'Colţu de Jos',
        simplu: 'Coltu de Jos',
        comuna: 'Manesti'
      },
      {
        nume: 'Ghioldum',
        comuna: 'Manesti'
      },
      {
        nume: 'Gura Crivăţului',
        simplu: 'Gura Crivatului',
        comuna: 'Manesti'
      },
      {
        nume: 'Perşunari',
        simplu: 'Persunari',
        comuna: 'Manesti'
      },
      {
        nume: 'Piatra',
        comuna: 'Manesti'
      },
      {
        nume: 'Satu de Sus',
        comuna: 'Manesti'
      },
      {
        nume: 'Zalhanaua',
        comuna: 'Manesti'
      },
      {
        nume: 'Fefelei',
        comuna: 'Mizil'
      },
      {
        nume: 'Bărzila',
        simplu: 'Barzila',
        comuna: 'Pacureti'
      },
      {
        nume: 'Curmătura',
        simplu: 'Curmatura',
        comuna: 'Pacureti'
      },
      {
        nume: 'Matiţa',
        simplu: 'Matita',
        comuna: 'Pacureti'
      },
      {
        nume: 'Slavu',
        comuna: 'Pacureti'
      },
      {
        nume: 'Cocoşeşti',
        simplu: 'Cocosesti',
        comuna: 'Paulesti'
      },
      {
        nume: 'Găgeni',
        simplu: 'Gageni',
        comuna: 'Paulesti'
      },
      {
        nume: 'Păuleştii Noi',
        simplu: 'Paulestii Noi',
        comuna: 'Paulesti'
      },
      {
        nume: 'Gâlmeia',
        simplu: 'Galmeia',
        comuna: 'Plopu'
      },
      {
        nume: 'Hârşa',
        simplu: 'Harsa',
        comuna: 'Plopu'
      },
      {
        nume: 'Nisipoasa',
        comuna: 'Plopu'
      },
      {
        nume: 'Ghiocel',
        comuna: 'Podenii Noi'
      },
      {
        nume: 'Mehedinţa',
        simplu: 'Mehedinta',
        comuna: 'Podenii Noi'
      },
      {
        nume: 'Nevesteasca',
        comuna: 'Podenii Noi'
      },
      {
        nume: 'Podu lui Galben',
        comuna: 'Podenii Noi'
      },
      {
        nume: 'Popeşti',
        simplu: 'Popesti',
        comuna: 'Podenii Noi'
      },
      {
        nume: 'Rahova',
        comuna: 'Podenii Noi'
      },
      {
        nume: 'Sfăcăru',
        simplu: 'Sfacaru',
        comuna: 'Podenii Noi'
      },
      {
        nume: 'Sălcioara',
        simplu: 'Salcioara',
        comuna: 'Podenii Noi'
      },
      {
        nume: 'Valea Dulce',
        comuna: 'Podenii Noi'
      },
      {
        nume: 'Bobolia',
        comuna: 'Poiana Campina'
      },
      {
        nume: 'Pietrişu',
        simplu: 'Pietrisu',
        comuna: 'Poiana Campina'
      },
      {
        nume: 'Răgman',
        simplu: 'Ragman',
        comuna: 'Poiana Campina'
      },
      {
        nume: 'Cărbunari',
        simplu: 'Carbunari',
        comuna: 'Poienarii Burchii'
      },
      {
        nume: 'Ologeni',
        comuna: 'Poienarii Burchii'
      },
      {
        nume: 'Pioreşti',
        simplu: 'Pioresti',
        comuna: 'Poienarii Burchii'
      },
      {
        nume: 'Podu Văleni',
        simplu: 'Podu Valeni',
        comuna: 'Poienarii Burchii'
      },
      {
        nume: 'Poienarii Rali',
        comuna: 'Poienarii Burchii'
      },
      {
        nume: 'Poienarii Vechi',
        comuna: 'Poienarii Burchii'
      },
      {
        nume: 'Tătărăi',
        simplu: 'Tatarai',
        comuna: 'Poienarii Burchii'
      },
      {
        nume: 'Bodeşti',
        simplu: 'Bodesti',
        comuna: 'Posesti'
      },
      {
        nume: 'Merdeala',
        comuna: 'Posesti'
      },
      {
        nume: 'Nucşoara de Jos',
        simplu: 'Nucsoara de Jos',
        comuna: 'Posesti'
      },
      {
        nume: 'Nucşoara de Sus',
        simplu: 'Nucsoara de Sus',
        comuna: 'Posesti'
      },
      {
        nume: 'Poseştii Ungureni',
        simplu: 'Posestii Ungureni',
        comuna: 'Posesti'
      },
      {
        nume: 'Poseştii-Pământeni',
        simplu: 'Posestii-Pamanteni',
        comuna: 'Posesti'
      },
      {
        nume: 'Târleşti',
        simplu: 'Tarlesti',
        comuna: 'Posesti'
      },
      {
        nume: 'Valea Plopului',
        comuna: 'Posesti'
      },
      {
        nume: 'Valea Screzii',
        comuna: 'Posesti'
      },
      {
        nume: 'Valea Stupinii',
        comuna: 'Posesti'
      },
      {
        nume: 'Bobiceşti',
        simplu: 'Bobicesti',
        comuna: 'Predeal-Sarari'
      },
      {
        nume: 'Poienile',
        comuna: 'Predeal-Sarari'
      },
      {
        nume: 'Predeal',
        comuna: 'Predeal-Sarari'
      },
      {
        nume: 'Sărari',
        simplu: 'Sarari',
        comuna: 'Predeal-Sarari'
      },
      {
        nume: 'Sărăţel',
        simplu: 'Saratel',
        comuna: 'Predeal-Sarari'
      },
      {
        nume: 'Tulburea',
        comuna: 'Predeal-Sarari'
      },
      {
        nume: 'Tulburea Văleni',
        simplu: 'Tulburea Valeni',
        comuna: 'Predeal-Sarari'
      },
      {
        nume: 'Vitioara de Sus',
        comuna: 'Predeal-Sarari'
      },
      {
        nume: 'Zâmbroaia',
        simplu: 'Zambroaia',
        comuna: 'Predeal-Sarari'
      },
      {
        nume: 'Drăgăneasa',
        simplu: 'Draganeasa',
        comuna: 'Provita De Jos'
      },
      {
        nume: 'Piatra',
        comuna: 'Provita De Jos'
      },
      {
        nume: 'Izvoru',
        comuna: 'Provita De Sus'
      },
      {
        nume: 'Plaiu',
        comuna: 'Provita De Sus'
      },
      {
        nume: 'Valea Bradului',
        comuna: 'Provita De Sus'
      },
      {
        nume: 'Miroslăveşti',
        simplu: 'Miroslavesti',
        comuna: 'Puchenii Mari'
      },
      {
        nume: 'Moara',
        comuna: 'Puchenii Mari'
      },
      {
        nume: 'Odăile',
        simplu: 'Odaile',
        comuna: 'Puchenii Mari'
      },
      {
        nume: 'Pietroşani',
        simplu: 'Pietrosani',
        comuna: 'Puchenii Mari'
      },
      {
        nume: 'Puchenii Mici',
        comuna: 'Puchenii Mari'
      },
      {
        nume: 'Puchenii Moşneni',
        simplu: 'Puchenii Mosneni',
        comuna: 'Puchenii Mari'
      },
      {
        nume: 'Antofiloaia',
        comuna: 'Rafov'
      },
      {
        nume: 'Buchilaşi',
        simplu: 'Buchilasi',
        comuna: 'Rafov'
      },
      {
        nume: 'Buda',
        comuna: 'Rafov'
      },
      {
        nume: 'Goga',
        comuna: 'Rafov'
      },
      {
        nume: 'Moara Domnească',
        simplu: 'Moara Domneasca',
        comuna: 'Rafov'
      },
      {
        nume: 'Mălăieşti',
        simplu: 'Malaiesti',
        comuna: 'Rafov'
      },
      {
        nume: 'Palanca',
        comuna: 'Rafov'
      },
      {
        nume: 'Sicrita',
        comuna: 'Rafov'
      },
      {
        nume: 'Butuci',
        comuna: 'Sangeru'
      },
      {
        nume: 'Mireşu Mare',
        simplu: 'Miresu Mare',
        comuna: 'Sangeru'
      },
      {
        nume: 'Mireşu Mic',
        simplu: 'Miresu Mic',
        comuna: 'Sangeru'
      },
      {
        nume: 'Piatra Mică',
        simplu: 'Piatra Mica',
        comuna: 'Sangeru'
      },
      {
        nume: 'Tisa',
        comuna: 'Sangeru'
      },
      {
        nume: 'Bordenii Mari',
        comuna: 'Scorteni'
      },
      {
        nume: 'Bordenii Mici',
        comuna: 'Scorteni'
      },
      {
        nume: 'Mislea',
        comuna: 'Scorteni'
      },
      {
        nume: 'Sârca',
        simplu: 'Sarca',
        comuna: 'Scorteni'
      },
      {
        nume: 'Brăteşti',
        simplu: 'Bratesti',
        comuna: 'Sirna'
      },
      {
        nume: 'Coceana',
        comuna: 'Sirna'
      },
      {
        nume: 'Hăbud',
        simplu: 'Habud',
        comuna: 'Sirna'
      },
      {
        nume: 'Tăriceni',
        simplu: 'Tariceni',
        comuna: 'Sirna'
      },
      {
        nume: 'Varniţa',
        simplu: 'Varnita',
        comuna: 'Sirna'
      },
      {
        nume: 'Groşani',
        simplu: 'Grosani',
        comuna: 'Slanic'
      },
      {
        nume: 'Prăjani',
        simplu: 'Prajani',
        comuna: 'Slanic'
      },
      {
        nume: 'Lopatniţa',
        simplu: 'Lopatnita',
        comuna: 'Soimari'
      },
      {
        nume: 'Măgura',
        simplu: 'Magura',
        comuna: 'Soimari'
      },
      {
        nume: 'Lunca Mare',
        comuna: 'Sotrile'
      },
      {
        nume: 'Plaiu Cornului',
        comuna: 'Sotrile'
      },
      {
        nume: 'Plaiu Câmpinei',
        simplu: 'Plaiu Campinei',
        comuna: 'Sotrile'
      },
      {
        nume: 'Seciuri',
        comuna: 'Sotrile'
      },
      {
        nume: 'Vistieru',
        comuna: 'Sotrile'
      },
      {
        nume: 'Brădet',
        simplu: 'Bradet',
        comuna: 'Starchiojd'
      },
      {
        nume: 'Bătrâni',
        simplu: 'Batrani',
        comuna: 'Starchiojd'
      },
      {
        nume: 'Gresia',
        comuna: 'Starchiojd'
      },
      {
        nume: 'Poiana Mare',
        comuna: 'Starchiojd'
      },
      {
        nume: 'Rotarea',
        comuna: 'Starchiojd'
      },
      {
        nume: 'Valea Anei',
        comuna: 'Starchiojd'
      },
      {
        nume: 'Zmeuret',
        comuna: 'Starchiojd'
      },
      {
        nume: 'Scurteşti',
        simplu: 'Scurtesti',
        comuna: 'Stefesti'
      },
      {
        nume: 'Târşoreni',
        simplu: 'Tarsoreni',
        comuna: 'Stefesti'
      },
      {
        nume: 'Păcuri',
        simplu: 'Pacuri',
        comuna: 'Surani'
      },
      {
        nume: 'Plaiu',
        comuna: 'Talea'
      },
      {
        nume: 'Strejnicu',
        comuna: 'Targsoru Vechi'
      },
      {
        nume: 'Stănceşti',
        simplu: 'Stancesti',
        comuna: 'Targsoru Vechi'
      },
      {
        nume: 'Zahanaua',
        comuna: 'Targsoru Vechi'
      },
      {
        nume: 'Podgoria',
        comuna: 'Tataru'
      },
      {
        nume: 'Siliştea',
        simplu: 'Silistea',
        comuna: 'Tataru'
      },
      {
        nume: 'Bugnea de Sus',
        comuna: 'Teisani'
      },
      {
        nume: 'Olteni',
        comuna: 'Teisani'
      },
      {
        nume: 'Valea Stâlpului',
        simplu: 'Valea Stalpului',
        comuna: 'Teisani'
      },
      {
        nume: 'Stubeiu',
        simplu: 'Stubeiu',
        comuna: 'Teisani'
      },
      {
        nume: 'Boşilceşti',
        simplu: 'Bosilcesti',
        comuna: 'Telega'
      },
      {
        nume: 'Buştenari',
        simplu: 'Bustenari',
        comuna: 'Telega'
      },
      {
        nume: 'Doftana',
        comuna: 'Telega'
      },
      {
        nume: 'Meliceşti',
        simplu: 'Melicesti',
        comuna: 'Telega'
      },
      {
        nume: 'Tonţeşti',
        simplu: 'Tontesti',
        comuna: 'Telega'
      },
      {
        nume: 'Pisculeşti',
        simplu: 'Pisculesti',
        comuna: 'Tinosu'
      },
      {
        nume: 'Predeşti',
        simplu: 'Predesti',
        comuna: 'Tinosu'
      },
      {
        nume: 'Loloiasca',
        comuna: 'Tomsani'
      },
      {
        nume: 'Magula',
        comuna: 'Tomsani'
      },
      {
        nume: 'Sătucu',
        simplu: 'Satucu',
        comuna: 'Tomsani'
      },
      {
        nume: 'Arioneştii Noi',
        simplu: 'Arionestii Noi',
        comuna: 'Urlati'
      },
      {
        nume: 'Arioneştii Vechi',
        simplu: 'Arionestii Vechi',
        comuna: 'Urlati'
      },
      {
        nume: 'Cherba',
        comuna: 'Urlati'
      },
      {
        nume: 'Jercălăi',
        simplu: 'Jercalai',
        comuna: 'Urlati'
      },
      {
        nume: 'Mărunţiş',
        simplu: 'Maruntis',
        comuna: 'Urlati'
      },
      {
        nume: 'Orzoaia de Jos',
        comuna: 'Urlati'
      },
      {
        nume: 'Orzoaia de Sus',
        comuna: 'Urlati'
      },
      {
        nume: 'Schiau',
        comuna: 'Urlati'
      },
      {
        nume: 'Ulmi',
        comuna: 'Urlati'
      },
      {
        nume: 'Valea Bobului',
        comuna: 'Urlati'
      },
      {
        nume: 'Valea Crângului',
        simplu: 'Valea Crangului',
        comuna: 'Urlati'
      },
      {
        nume: 'Valea Mieilor',
        comuna: 'Urlati'
      },
      {
        nume: 'Valea Nucetului',
        comuna: 'Urlati'
      },
      {
        nume: 'Valea Pietrei',
        comuna: 'Urlati'
      },
      {
        nume: 'Valea Seman',
        comuna: 'Urlati'
      },
      {
        nume: 'Valea Urloii',
        comuna: 'Urlati'
      },
      {
        nume: 'Cârjari',
        simplu: 'Carjari',
        comuna: 'Valcanesti'
      },
      {
        nume: 'Trestioara',
        comuna: 'Valcanesti'
      },
      {
        nume: 'Arva',
        comuna: 'Valea Calugareasca'
      },
      {
        nume: 'Coslegi',
        comuna: 'Valea Calugareasca'
      },
      {
        nume: 'Dârvari',
        simplu: 'Darvari',
        comuna: 'Valea Calugareasca'
      },
      {
        nume: 'Pantazi',
        comuna: 'Valea Calugareasca'
      },
      {
        nume: 'Rachieri',
        comuna: 'Valea Calugareasca'
      },
      {
        nume: 'Radila',
        comuna: 'Valea Calugareasca'
      },
      {
        nume: 'Schiau',
        comuna: 'Valea Calugareasca'
      },
      {
        nume: 'Valea Largă',
        simplu: 'Valea Larga',
        comuna: 'Valea Calugareasca'
      },
      {
        nume: 'Valea Mantei',
        comuna: 'Valea Calugareasca'
      },
      {
        nume: 'Valea Nicovani',
        comuna: 'Valea Calugareasca'
      },
      {
        nume: 'Valea Poienii',
        comuna: 'Valea Calugareasca'
      },
      {
        nume: 'Valea Popii',
        comuna: 'Valea Calugareasca'
      },
      {
        nume: 'Valea Ursoii',
        comuna: 'Valea Calugareasca'
      },
      {
        nume: 'Vârfurile',
        simplu: 'Varfurile',
        comuna: 'Valea Calugareasca'
      },
      {
        nume: 'Teşila',
        simplu: 'Tesila',
        comuna: 'Valea Doftanei'
      },
      {
        nume: 'Trăisteni',
        simplu: 'Traisteni',
        comuna: 'Valea Doftanei'
      },
      {
        nume: 'Coţofeneşti',
        simplu: 'Cotofenesti',
        comuna: 'Varbilau'
      },
      {
        nume: 'Livadea',
        comuna: 'Varbilau'
      },
      {
        nume: 'Podu Ursului',
        comuna: 'Varbilau'
      },
      {
        nume: 'Poiana Vărbilău',
        simplu: 'Poiana Varbilau',
        comuna: 'Varbilau'
      }
    ]
  },
  {
    auto: 'SJ',
    nume: 'Sălaj',
    localitati: [
      {
        nume: 'Agrij'
      },
      {
        nume: 'Almaşu',
        simplu: 'Almasu'
      },
      {
        nume: 'Benesat'
      },
      {
        nume: 'Bobota'
      },
      {
        nume: 'Bocşa',
        simplu: 'Bocsa'
      },
      {
        nume: 'Buciumi'
      },
      {
        nume: 'Băbeni',
        simplu: 'Babeni'
      },
      {
        nume: 'Bălan',
        simplu: 'Balan'
      },
      {
        nume: 'Bănişor',
        simplu: 'Banisor'
      },
      {
        nume: 'Camăr',
        simplu: 'Camar'
      },
      {
        nume: 'Carastelec'
      },
      {
        nume: 'Cehu Silvaniei'
      },
      {
        nume: 'Chieşd',
        simplu: 'Chiesd'
      },
      {
        nume: 'Cizer'
      },
      {
        nume: 'Coşeiu',
        simplu: 'Coseiu'
      },
      {
        nume: 'Crasna'
      },
      {
        nume: 'Creaca'
      },
      {
        nume: 'Cristolţ',
        simplu: 'Cristolt'
      },
      {
        nume: 'Crişeni',
        simplu: 'Criseni'
      },
      {
        nume: 'Cuzăplac',
        simplu: 'Cuzaplac'
      },
      {
        nume: 'Dobrin'
      },
      {
        nume: 'Dragu'
      },
      {
        nume: 'Fildu de Jos'
      },
      {
        nume: 'Gâlgău',
        simplu: 'Galgau'
      },
      {
        nume: 'Gârbou',
        simplu: 'Garbou'
      },
      {
        nume: 'Halmăşd',
        simplu: 'Halmasd'
      },
      {
        nume: 'Hereclean'
      },
      {
        nume: 'Hida'
      },
      {
        nume: 'Horoatu Crasnei'
      },
      {
        nume: 'Ileanda'
      },
      {
        nume: 'Ip'
      },
      {
        nume: 'Jibou'
      },
      {
        nume: 'Letca'
      },
      {
        nume: 'Lozna'
      },
      {
        nume: 'Marca'
      },
      {
        nume: 'Meseşenii de Jos',
        simplu: 'Mesesenii de Jos'
      },
      {
        nume: 'Mirşid',
        simplu: 'Mirsid'
      },
      {
        nume: 'Măerişte',
        simplu: 'Maeriste'
      },
      {
        nume: 'Nuşfalău',
        simplu: 'Nusfalau'
      },
      {
        nume: 'Năpradea',
        simplu: 'Napradea'
      },
      {
        nume: 'Pericei'
      },
      {
        nume: 'Plopiş',
        simplu: 'Plopis'
      },
      {
        nume: 'Poiana Blenchii'
      },
      {
        nume: 'Românaşi',
        simplu: 'Romanasi'
      },
      {
        nume: 'Rus'
      },
      {
        nume: 'Someş-Odorhei',
        simplu: 'Somes-Odorhei'
      },
      {
        nume: 'Surduc'
      },
      {
        nume: 'Sâg',
        simplu: 'Sag'
      },
      {
        nume: 'Sânmihaiu Almaşului',
        simplu: 'Sanmihaiu Almasului'
      },
      {
        nume: 'Sălăţig',
        simplu: 'Salatig'
      },
      {
        nume: 'Treznea'
      },
      {
        nume: 'Valcău de Jos',
        simplu: 'Valcau de Jos'
      },
      {
        nume: 'Vârşolţ',
        simplu: 'Varsolt'
      },
      {
        nume: 'Zalha'
      },
      {
        nume: 'Zalău',
        simplu: 'Zalau'
      },
      {
        nume: 'Zimbor'
      },
      {
        nume: 'Samşud',
        simplu: 'Samsud'
      },
      {
        nume: 'Simişna',
        simplu: 'Simisna'
      },
      {
        nume: 'Simleu Silvaniei',
        simplu: 'Simleu Silvaniei'
      },
      {
        nume: 'Sărmăşag',
        simplu: 'Sarmasag'
      },
      {
        nume: 'Răstolţu Deşert',
        simplu: 'Rastoltu Desert',
        comuna: 'Agrij'
      },
      {
        nume: 'Băbiu',
        simplu: 'Babiu',
        comuna: 'Almasu'
      },
      {
        nume: 'Cutiş',
        simplu: 'Cutis',
        comuna: 'Almasu'
      },
      {
        nume: 'Jebucu',
        comuna: 'Almasu'
      },
      {
        nume: 'Mesteacănu',
        simplu: 'Mesteacanu',
        comuna: 'Almasu'
      },
      {
        nume: 'Petrinzel',
        comuna: 'Almasu'
      },
      {
        nume: 'Sfăraş',
        simplu: 'Sfaras',
        comuna: 'Almasu'
      },
      {
        nume: 'Stana',
        comuna: 'Almasu'
      },
      {
        nume: 'Tăudu',
        simplu: 'Taudu',
        comuna: 'Almasu'
      },
      {
        nume: 'Ciocmani',
        comuna: 'Babeni'
      },
      {
        nume: 'Cliţ',
        simplu: 'Clit',
        comuna: 'Babeni'
      },
      {
        nume: 'Piroşa',
        simplu: 'Pirosa',
        comuna: 'Babeni'
      },
      {
        nume: 'Poieniţa',
        simplu: 'Poienita',
        comuna: 'Babeni'
      },
      {
        nume: 'Chechiş',
        simplu: 'Chechis',
        comuna: 'Balan'
      },
      {
        nume: 'Chendrea',
        comuna: 'Balan'
      },
      {
        nume: 'Gâlgău Almaşului',
        simplu: 'Galgau Almasului',
        comuna: 'Balan'
      },
      {
        nume: 'Gălpâia',
        simplu: 'Galpaia',
        comuna: 'Balan'
      },
      {
        nume: 'Ban',
        comuna: 'Banisor'
      },
      {
        nume: 'Peceiu',
        comuna: 'Banisor'
      },
      {
        nume: 'Aluniş',
        simplu: 'Alunis',
        comuna: 'Benesat'
      },
      {
        nume: 'Biuşa',
        simplu: 'Biusa',
        comuna: 'Benesat'
      },
      {
        nume: 'Derşida',
        simplu: 'Dersida',
        comuna: 'Bobota'
      },
      {
        nume: 'Zalnoc',
        comuna: 'Bobota'
      },
      {
        nume: 'Borla',
        comuna: 'Bocsa'
      },
      {
        nume: 'Câmpia',
        simplu: 'Campia',
        comuna: 'Bocsa'
      },
      {
        nume: 'Sălăjeni',
        simplu: 'Salajeni',
        comuna: 'Bocsa'
      },
      {
        nume: 'Bodia',
        comuna: 'Buciumi'
      },
      {
        nume: 'Bogdana',
        comuna: 'Buciumi'
      },
      {
        nume: 'Huta',
        comuna: 'Buciumi'
      },
      {
        nume: 'Răstolţ',
        simplu: 'Rastolt',
        comuna: 'Buciumi'
      },
      {
        nume: 'Sângeorgiu de Meseş',
        simplu: 'Sangeorgiu de Meses',
        comuna: 'Buciumi'
      },
      {
        nume: 'Pădureni',
        simplu: 'Padureni',
        comuna: 'Camar'
      },
      {
        nume: 'Dumuslău',
        simplu: 'Dumuslau',
        comuna: 'Carastelec'
      },
      {
        nume: 'Horoatu Cehului',
        comuna: 'Cehu Silvaniei'
      },
      {
        nume: 'Motiş',
        simplu: 'Motis',
        comuna: 'Cehu Silvaniei'
      },
      {
        nume: 'Nadiş',
        simplu: 'Nadis',
        comuna: 'Cehu Silvaniei'
      },
      {
        nume: 'Ulciug',
        comuna: 'Cehu Silvaniei'
      },
      {
        nume: 'Colonia Sighetu Silvaniei',
        comuna: 'Chiesd'
      },
      {
        nume: 'Sighetu Silvaniei',
        comuna: 'Chiesd'
      },
      {
        nume: 'Plesca',
        comuna: 'Cizer'
      },
      {
        nume: 'Pria',
        comuna: 'Cizer'
      },
      {
        nume: 'Archid',
        comuna: 'Coseiu'
      },
      {
        nume: 'Chilioara',
        comuna: 'Coseiu'
      },
      {
        nume: 'Huseni',
        comuna: 'Crasna'
      },
      {
        nume: 'Marin',
        comuna: 'Crasna'
      },
      {
        nume: 'Ratin',
        comuna: 'Crasna'
      },
      {
        nume: 'Borza',
        comuna: 'Creaca'
      },
      {
        nume: 'Brebi',
        comuna: 'Creaca'
      },
      {
        nume: 'Brusturi',
        comuna: 'Creaca'
      },
      {
        nume: 'Ciglean',
        comuna: 'Creaca'
      },
      {
        nume: 'Jac',
        comuna: 'Creaca'
      },
      {
        nume: 'Lupoaia',
        comuna: 'Creaca'
      },
      {
        nume: 'Prodăneşti',
        simplu: 'Prodanesti',
        comuna: 'Creaca'
      },
      {
        nume: 'Viile Jacului',
        comuna: 'Creaca'
      },
      {
        nume: 'Cristur Crişeni',
        simplu: 'Cristur Criseni',
        comuna: 'Criseni'
      },
      {
        nume: 'Gârceiu',
        simplu: 'Garceiu',
        comuna: 'Criseni'
      },
      {
        nume: 'Muncel',
        comuna: 'Cristolt'
      },
      {
        nume: 'Poiana Onţii',
        simplu: 'Poiana Ontii',
        comuna: 'Cristolt'
      },
      {
        nume: 'Văleni',
        simplu: 'Valeni',
        comuna: 'Cristolt'
      },
      {
        nume: 'Cubleşu',
        simplu: 'Cublesu',
        comuna: 'Cuzaplac'
      },
      {
        nume: 'Gălăşeni',
        simplu: 'Galaseni',
        comuna: 'Cuzaplac'
      },
      {
        nume: 'Mierţa',
        simplu: 'Mierta',
        comuna: 'Cuzaplac'
      },
      {
        nume: 'Petrindu',
        comuna: 'Cuzaplac'
      },
      {
        nume: 'Ruginoasa',
        comuna: 'Cuzaplac'
      },
      {
        nume: 'Stoboru',
        comuna: 'Cuzaplac'
      },
      {
        nume: 'Tămasa',
        simplu: 'Tamasa',
        comuna: 'Cuzaplac'
      },
      {
        nume: 'Deleni',
        comuna: 'Dobrin'
      },
      {
        nume: 'Doba',
        comuna: 'Dobrin'
      },
      {
        nume: 'Naimon',
        comuna: 'Dobrin'
      },
      {
        nume: 'Sâncraiu Silvaniei',
        simplu: 'Sancraiu Silvaniei',
        comuna: 'Dobrin'
      },
      {
        nume: 'Verveghiu',
        comuna: 'Dobrin'
      },
      {
        nume: 'Adalin',
        comuna: 'Dragu'
      },
      {
        nume: 'Fântânele',
        simplu: 'Fantanele',
        comuna: 'Dragu'
      },
      {
        nume: 'Ugruţiu',
        simplu: 'Ugrutiu',
        comuna: 'Dragu'
      },
      {
        nume: 'Voivodeni',
        comuna: 'Dragu'
      },
      {
        nume: 'Fildu de Mijloc',
        comuna: 'Fildu De Jos'
      },
      {
        nume: 'Fildu de Sus',
        comuna: 'Fildu De Jos'
      },
      {
        nume: 'Tetişu',
        simplu: 'Tetisu',
        comuna: 'Fildu De Jos'
      },
      {
        nume: 'Bârsău Mare',
        simplu: 'Barsau Mare',
        comuna: 'Galgau'
      },
      {
        nume: 'Chizeni',
        comuna: 'Galgau'
      },
      {
        nume: 'Căpâlna',
        simplu: 'Capalna',
        comuna: 'Galgau'
      },
      {
        nume: 'Dobrocina',
        comuna: 'Galgau'
      },
      {
        nume: 'Fodora',
        comuna: 'Galgau'
      },
      {
        nume: 'Frâncenii de Piatră',
        simplu: 'Francenii de Piatra',
        comuna: 'Galgau'
      },
      {
        nume: 'Glod',
        comuna: 'Galgau'
      },
      {
        nume: 'Gura Vlădesei',
        simplu: 'Gura Vladesei',
        comuna: 'Galgau'
      },
      {
        nume: 'Bezded',
        comuna: 'Garbou'
      },
      {
        nume: 'Cernuc',
        comuna: 'Garbou'
      },
      {
        nume: 'Călacea',
        simplu: 'Calacea',
        comuna: 'Garbou'
      },
      {
        nume: 'Fabrica',
        comuna: 'Garbou'
      },
      {
        nume: 'Popteleac',
        comuna: 'Garbou'
      },
      {
        nume: 'Solomon',
        comuna: 'Garbou'
      },
      {
        nume: 'Aleuş',
        simplu: 'Aleus',
        comuna: 'Halmasd'
      },
      {
        nume: 'Cerişa',
        simplu: 'Cerisa',
        comuna: 'Halmasd'
      },
      {
        nume: 'Drighiu',
        comuna: 'Halmasd'
      },
      {
        nume: 'Fufez',
        comuna: 'Halmasd'
      },
      {
        nume: 'Badon',
        comuna: 'Hereclean'
      },
      {
        nume: 'Bocşiţa',
        simplu: 'Bocsita',
        comuna: 'Hereclean'
      },
      {
        nume: 'Dioşod',
        simplu: 'Diosod',
        comuna: 'Hereclean'
      },
      {
        nume: 'Guruslău',
        simplu: 'Guruslau',
        comuna: 'Hereclean'
      },
      {
        nume: 'Panic',
        comuna: 'Hereclean'
      },
      {
        nume: 'Baica',
        comuna: 'Hida'
      },
      {
        nume: 'Miluani',
        comuna: 'Hida'
      },
      {
        nume: 'Păduriş',
        simplu: 'Paduris',
        comuna: 'Hida'
      },
      {
        nume: 'Racâş',
        simplu: 'Racas',
        comuna: 'Hida'
      },
      {
        nume: 'Stupini',
        comuna: 'Hida'
      },
      {
        nume: 'Sânpetru Almaşului',
        simplu: 'Sanpetru Almasului',
        comuna: 'Hida'
      },
      {
        nume: 'Trestia',
        comuna: 'Hida'
      },
      {
        nume: 'Hurez',
        comuna: 'Horoatu Crasnei'
      },
      {
        nume: 'Stârciu',
        simplu: 'Starciu',
        comuna: 'Horoatu Crasnei'
      },
      {
        nume: 'Seredeiu',
        simplu: 'Seredeiu',
        comuna: 'Horoatu Crasnei'
      },
      {
        nume: 'Bizuşa Băi',
        simplu: 'Bizusa Bai',
        comuna: 'Ileanda'
      },
      {
        nume: 'Bârsăuţa',
        simplu: 'Barsauta',
        comuna: 'Ileanda'
      },
      {
        nume: 'Dolheni',
        comuna: 'Ileanda'
      },
      {
        nume: 'Dăbâceni',
        simplu: 'Dabaceni',
        comuna: 'Ileanda'
      },
      {
        nume: 'Luminişu',
        simplu: 'Luminisu',
        comuna: 'Ileanda'
      },
      {
        nume: 'Măleni',
        simplu: 'Maleni',
        comuna: 'Ileanda'
      },
      {
        nume: 'Negreni',
        comuna: 'Ileanda'
      },
      {
        nume: 'Perii Vadului',
        comuna: 'Ileanda'
      },
      {
        nume: 'Podişu',
        simplu: 'Podisu',
        comuna: 'Ileanda'
      },
      {
        nume: 'Rogna',
        comuna: 'Ileanda'
      },
      {
        nume: 'Răstoci',
        simplu: 'Rastoci',
        comuna: 'Ileanda'
      },
      {
        nume: 'Sasa',
        simplu: 'Sasa',
        comuna: 'Ileanda'
      },
      {
        nume: 'Cosniciu de Jos',
        comuna: 'Ip'
      },
      {
        nume: 'Cosniciu de Sus',
        comuna: 'Ip'
      },
      {
        nume: 'Zăuan',
        simplu: 'Zauan',
        comuna: 'Ip'
      },
      {
        nume: 'Zăuan Băi',
        simplu: 'Zauan Bai',
        comuna: 'Ip'
      },
      {
        nume: 'Cuceu',
        comuna: 'Jibou'
      },
      {
        nume: 'Husia',
        comuna: 'Jibou'
      },
      {
        nume: 'Rona',
        comuna: 'Jibou'
      },
      {
        nume: 'Var',
        comuna: 'Jibou'
      },
      {
        nume: 'Ciula',
        comuna: 'Letca'
      },
      {
        nume: 'Cozla',
        comuna: 'Letca'
      },
      {
        nume: 'Cuciulat',
        comuna: 'Letca'
      },
      {
        nume: 'Lemniu',
        comuna: 'Letca'
      },
      {
        nume: 'Purcăreţ',
        simplu: 'Purcaret',
        comuna: 'Letca'
      },
      {
        nume: 'Topliţa',
        simplu: 'Toplita',
        comuna: 'Letca'
      },
      {
        nume: 'Vălişoara',
        simplu: 'Valisoara',
        comuna: 'Letca'
      },
      {
        nume: 'Soimuşeni',
        simplu: 'Soimuseni',
        comuna: 'Letca'
      },
      {
        nume: 'Cormeniş',
        simplu: 'Cormenis',
        comuna: 'Lozna'
      },
      {
        nume: 'Preluci',
        comuna: 'Lozna'
      },
      {
        nume: 'Valea Leşului',
        simplu: 'Valea Lesului',
        comuna: 'Lozna'
      },
      {
        nume: 'Valea Loznei',
        comuna: 'Lozna'
      },
      {
        nume: 'Criştelec',
        simplu: 'Cristelec',
        comuna: 'Maeriste'
      },
      {
        nume: 'Doh',
        comuna: 'Maeriste'
      },
      {
        nume: 'Giurtelecu Simleului',
        simplu: 'Giurtelecu Simleului',
        comuna: 'Maeriste'
      },
      {
        nume: 'Mălădia',
        simplu: 'Maladia',
        comuna: 'Maeriste'
      },
      {
        nume: 'Uileacu Simleului',
        simplu: 'Uileacu Simleului',
        comuna: 'Maeriste'
      },
      {
        nume: 'Leşmir',
        simplu: 'Lesmir',
        comuna: 'Marca'
      },
      {
        nume: 'Marca Huta',
        comuna: 'Marca'
      },
      {
        nume: 'Porţ',
        simplu: 'Port',
        comuna: 'Marca'
      },
      {
        nume: 'Sumal',
        simplu: 'Sumal',
        comuna: 'Marca'
      },
      {
        nume: 'Aghireş',
        simplu: 'Aghires',
        comuna: 'Mesesenii De Jos'
      },
      {
        nume: 'Fetindia',
        comuna: 'Mesesenii De Jos'
      },
      {
        nume: 'Meseşenii de Sus',
        simplu: 'Mesesenii de Sus',
        comuna: 'Mesesenii De Jos'
      },
      {
        nume: 'Firminiş',
        simplu: 'Firminis',
        comuna: 'Mirsid'
      },
      {
        nume: 'Moigrad-Porolissum',
        comuna: 'Mirsid'
      },
      {
        nume: 'Popeni',
        comuna: 'Mirsid'
      },
      {
        nume: 'Cheud',
        comuna: 'Napradea'
      },
      {
        nume: 'Someş Guruslău',
        simplu: 'Somes Guruslau',
        comuna: 'Napradea'
      },
      {
        nume: 'Traniş',
        simplu: 'Tranis',
        comuna: 'Napradea'
      },
      {
        nume: 'Vădurele',
        simplu: 'Vadurele',
        comuna: 'Napradea'
      },
      {
        nume: 'Bilghez',
        comuna: 'Nusfalau'
      },
      {
        nume: 'Boghiş',
        simplu: 'Boghis',
        comuna: 'Nusfalau'
      },
      {
        nume: 'Bozieş',
        simplu: 'Bozies',
        comuna: 'Nusfalau'
      },
      {
        nume: 'Bădăcin',
        simplu: 'Badacin',
        comuna: 'Pericei'
      },
      {
        nume: 'Periceiu Mic',
        comuna: 'Pericei'
      },
      {
        nume: 'Sici',
        comuna: 'Pericei'
      },
      {
        nume: 'Făgetu',
        simplu: 'Fagetu',
        comuna: 'Plopis'
      },
      {
        nume: 'Iaz',
        comuna: 'Plopis'
      },
      {
        nume: 'Fălcuşa',
        simplu: 'Falcusa',
        comuna: 'Poiana Blenchii'
      },
      {
        nume: 'Gostila',
        comuna: 'Poiana Blenchii'
      },
      {
        nume: 'Măgura',
        simplu: 'Magura',
        comuna: 'Poiana Blenchii'
      },
      {
        nume: 'Chichişa',
        simplu: 'Chichisa',
        comuna: 'Romanasi'
      },
      {
        nume: 'Ciumărna',
        simplu: 'Ciumarna',
        comuna: 'Romanasi'
      },
      {
        nume: 'Poarta Sălajului',
        simplu: 'Poarta Salajului',
        comuna: 'Romanasi'
      },
      {
        nume: 'Păuşa',
        simplu: 'Pausa',
        comuna: 'Romanasi'
      },
      {
        nume: 'Romita',
        comuna: 'Romanasi'
      },
      {
        nume: 'Buzaş',
        simplu: 'Buzas',
        comuna: 'Rus'
      },
      {
        nume: 'Fântânele Rus',
        simplu: 'Fantanele Rus',
        comuna: 'Rus'
      },
      {
        nume: 'Fizeş',
        simplu: 'Fizes',
        comuna: 'Sag'
      },
      {
        nume: 'Mal',
        comuna: 'Sag'
      },
      {
        nume: 'Sârbi',
        simplu: 'Sarbi',
        comuna: 'Sag'
      },
      {
        nume: 'Tusa',
        comuna: 'Sag'
      },
      {
        nume: 'Bulgari',
        comuna: 'Salatig'
      },
      {
        nume: 'Deja',
        comuna: 'Salatig'
      },
      {
        nume: 'Mineu',
        comuna: 'Salatig'
      },
      {
        nume: 'Noţig',
        simplu: 'Notig',
        comuna: 'Salatig'
      },
      {
        nume: 'Valea Pomilor',
        comuna: 'Samsud'
      },
      {
        nume: 'Bercea',
        comuna: 'Sanmihaiu Almasului'
      },
      {
        nume: 'Sântă Măria',
        simplu: 'Santa Maria',
        comuna: 'Sanmihaiu Almasului'
      },
      {
        nume: 'Ilişua',
        simplu: 'Ilisua',
        comuna: 'Sarmasag'
      },
      {
        nume: 'Lompirt',
        comuna: 'Sarmasag'
      },
      {
        nume: 'Moiad',
        comuna: 'Sarmasag'
      },
      {
        nume: 'Poiana Măgura',
        simplu: 'Poiana Magura',
        comuna: 'Sarmasag'
      },
      {
        nume: 'Tărmure',
        simplu: 'Tarmure',
        comuna: 'Sarmasag'
      },
      {
        nume: 'Hăşmaş',
        simplu: 'Hasmas',
        comuna: 'Simisna'
      },
      {
        nume: 'Bic',
        comuna: 'Simleu Silvaniei'
      },
      {
        nume: 'Cehei',
        comuna: 'Simleu Silvaniei'
      },
      {
        nume: 'Pusta',
        comuna: 'Simleu Silvaniei'
      },
      {
        nume: 'Bârsa',
        simplu: 'Barsa',
        comuna: 'Somes-Odorhei'
      },
      {
        nume: 'Domnin',
        comuna: 'Somes-Odorhei'
      },
      {
        nume: 'Inău',
        simplu: 'Inau',
        comuna: 'Somes-Odorhei'
      },
      {
        nume: 'Soimuş',
        simplu: 'Soimus',
        comuna: 'Somes-Odorhei'
      },
      {
        nume: 'Brâglez',
        simplu: 'Braglez',
        comuna: 'Surduc'
      },
      {
        nume: 'Cristolţel',
        simplu: 'Cristoltel',
        comuna: 'Surduc'
      },
      {
        nume: 'Solona',
        comuna: 'Surduc'
      },
      {
        nume: 'Teştioara',
        simplu: 'Testioara',
        comuna: 'Surduc'
      },
      {
        nume: 'Tihău',
        simplu: 'Tihau',
        comuna: 'Surduc'
      },
      {
        nume: 'Turbuţa',
        simplu: 'Turbuta',
        comuna: 'Surduc'
      },
      {
        nume: 'Bozna',
        comuna: 'Treznea'
      },
      {
        nume: 'Lazuri',
        comuna: 'Valcau De Jos'
      },
      {
        nume: 'Preoteasa',
        comuna: 'Valcau De Jos'
      },
      {
        nume: 'Ratovei',
        comuna: 'Valcau De Jos'
      },
      {
        nume: 'Sub Cetate',
        comuna: 'Valcau De Jos'
      },
      {
        nume: 'Valcău de Sus',
        simplu: 'Valcau de Sus',
        comuna: 'Valcau De Jos'
      },
      {
        nume: 'Recea',
        comuna: 'Varsolt'
      },
      {
        nume: 'Recea Mică',
        simplu: 'Recea Mica',
        comuna: 'Varsolt'
      },
      {
        nume: 'Stâna',
        simplu: 'Stana',
        comuna: 'Zalau'
      },
      {
        nume: 'Ceaca',
        comuna: 'Zalha'
      },
      {
        nume: 'Ciureni',
        comuna: 'Zalha'
      },
      {
        nume: 'Valea Ciurenilor',
        comuna: 'Zalha'
      },
      {
        nume: 'Valea Hranei',
        comuna: 'Zalha'
      },
      {
        nume: 'Valea Lungă',
        simplu: 'Valea Lunga',
        comuna: 'Zalha'
      },
      {
        nume: 'Vârteşca',
        simplu: 'Vartesca',
        comuna: 'Zalha'
      },
      {
        nume: 'Chendremal',
        comuna: 'Zimbor'
      },
      {
        nume: 'Dolu',
        comuna: 'Zimbor'
      },
      {
        nume: 'Sutoru',
        comuna: 'Zimbor'
      },
      {
        nume: 'Sâncraiu Almaşului',
        simplu: 'Sancraiu Almasului',
        comuna: 'Zimbor'
      }
    ]
  },
  {
    auto: 'SM',
    nume: 'Satu Mare',
    localitati: [
      {
        nume: 'Andrid'
      },
      {
        nume: 'Apa'
      },
      {
        nume: 'Ardud'
      },
      {
        nume: 'Beltiug'
      },
      {
        nume: 'Berveni'
      },
      {
        nume: 'Bixad'
      },
      {
        nume: 'Bogdand'
      },
      {
        nume: 'Botiz'
      },
      {
        nume: 'Bătarci',
        simplu: 'Batarci'
      },
      {
        nume: 'Carei'
      },
      {
        nume: 'Cehal'
      },
      {
        nume: 'Certeze'
      },
      {
        nume: 'Craidorolţ',
        simplu: 'Craidorolt'
      },
      {
        nume: 'Crucişor',
        simplu: 'Crucisor'
      },
      {
        nume: 'Călineşti-Oaş',
        simplu: 'Calinesti-Oas'
      },
      {
        nume: 'Cămin',
        simplu: 'Camin'
      },
      {
        nume: 'Cămărzana',
        simplu: 'Camarzana'
      },
      {
        nume: 'Căpleni',
        simplu: 'Capleni'
      },
      {
        nume: 'Căuaş',
        simplu: 'Cauas'
      },
      {
        nume: 'Doba'
      },
      {
        nume: 'Dorolţ',
        simplu: 'Dorolt'
      },
      {
        nume: 'Foieni'
      },
      {
        nume: 'Gherţa Mică',
        simplu: 'Gherta Mica'
      },
      {
        nume: 'Halmeu'
      },
      {
        nume: 'Hodod'
      },
      {
        nume: 'Lazuri'
      },
      {
        nume: 'Livada'
      },
      {
        nume: 'Medieşu Aurit',
        simplu: 'Mediesu Aurit'
      },
      {
        nume: 'Micula'
      },
      {
        nume: 'Negreşti-Oaş',
        simplu: 'Negresti-Oas'
      },
      {
        nume: 'Odoreu'
      },
      {
        nume: 'Oraşu Nou',
        simplu: 'Orasu Nou'
      },
      {
        nume: 'Petreşti',
        simplu: 'Petresti'
      },
      {
        nume: 'Pir'
      },
      {
        nume: 'Pişcolt',
        simplu: 'Piscolt'
      },
      {
        nume: 'Pomi'
      },
      {
        nume: 'Păuleşti',
        simplu: 'Paulesti'
      },
      {
        nume: 'Sanislău',
        simplu: 'Sanislau'
      },
      {
        nume: 'Santău',
        simplu: 'Santau'
      },
      {
        nume: 'Satu Mare'
      },
      {
        nume: 'Socond'
      },
      {
        nume: 'Săcăşeni',
        simplu: 'Sacaseni'
      },
      {
        nume: 'Săuca',
        simplu: 'Sauca'
      },
      {
        nume: 'Tarna Mare'
      },
      {
        nume: 'Terebeşti',
        simplu: 'Terebesti'
      },
      {
        nume: 'Tiream'
      },
      {
        nume: 'Turulung'
      },
      {
        nume: 'Turţ',
        simplu: 'Turt'
      },
      {
        nume: 'Târşolţ',
        simplu: 'Tarsolt'
      },
      {
        nume: 'Tăşnad',
        simplu: 'Tasnad'
      },
      {
        nume: 'Urziceni'
      },
      {
        nume: 'Valea Vinului'
      },
      {
        nume: 'Vama'
      },
      {
        nume: 'Vetiş',
        simplu: 'Vetis'
      },
      {
        nume: 'Viile Satu Mare'
      },
      {
        nume: 'Acâş',
        simplu: 'Acas',
        comuna: 'Acis'
      },
      {
        nume: 'Ganaş',
        simplu: 'Ganas',
        comuna: 'Acis'
      },
      {
        nume: 'Mihăieni',
        simplu: 'Mihaieni',
        comuna: 'Acis'
      },
      {
        nume: 'Unimăt',
        simplu: 'Unimat',
        comuna: 'Acis'
      },
      {
        nume: 'Dindeşti',
        simplu: 'Dindesti',
        comuna: 'Andrid'
      },
      {
        nume: 'Irina',
        comuna: 'Andrid'
      },
      {
        nume: 'Lunca Apei',
        comuna: 'Apa'
      },
      {
        nume: 'Someşeni',
        simplu: 'Someseni',
        comuna: 'Apa'
      },
      {
        nume: 'Ardud-Vii',
        comuna: 'Ardud'
      },
      {
        nume: 'Baba Novac',
        comuna: 'Ardud'
      },
      {
        nume: 'Gerăuşa',
        simplu: 'Gerausa',
        comuna: 'Ardud'
      },
      {
        nume: 'Mădăras',
        simplu: 'Madaras',
        comuna: 'Ardud'
      },
      {
        nume: 'Sărătura',
        simplu: 'Saratura',
        comuna: 'Ardud'
      },
      {
        nume: 'Bârsău de Jos',
        simplu: 'Barsau de Jos',
        comuna: 'Barsau'
      },
      {
        nume: 'Bârsău de Sus',
        simplu: 'Barsau de Sus',
        comuna: 'Barsau'
      },
      {
        nume: 'Comlăuşa',
        simplu: 'Comlausa',
        comuna: 'Batarci'
      },
      {
        nume: 'Tămăşeni',
        simplu: 'Tamaseni',
        comuna: 'Batarci'
      },
      {
        nume: 'Sirlău',
        simplu: 'Sirlau',
        comuna: 'Batarci'
      },
      {
        nume: 'Bolda',
        comuna: 'Beltiug'
      },
      {
        nume: 'Ghirişa',
        simplu: 'Ghirisa',
        comuna: 'Beltiug'
      },
      {
        nume: 'Giungi',
        comuna: 'Beltiug'
      },
      {
        nume: 'Răteşti',
        simplu: 'Ratesti',
        comuna: 'Beltiug'
      },
      {
        nume: 'Sandra',
        simplu: 'Sandra',
        comuna: 'Beltiug'
      },
      {
        nume: 'Lucăceni',
        simplu: 'Lucaceni',
        comuna: 'Berveni'
      },
      {
        nume: 'Boineşti',
        simplu: 'Boinesti',
        comuna: 'Bixad'
      },
      {
        nume: 'Trip',
        comuna: 'Bixad'
      },
      {
        nume: 'Babţa',
        simplu: 'Babta',
        comuna: 'Bogdand'
      },
      {
        nume: 'Corund',
        comuna: 'Bogdand'
      },
      {
        nume: 'Ser',
        comuna: 'Bogdand'
      },
      {
        nume: 'Agriş',
        simplu: 'Agris',
        comuna: 'Botiz'
      },
      {
        nume: 'Ciuperceni',
        comuna: 'Botiz'
      },
      {
        nume: 'Coca',
        comuna: 'Calinesti-Oas'
      },
      {
        nume: 'Lechinţa',
        simplu: 'Lechinta',
        comuna: 'Calinesti-Oas'
      },
      {
        nume: 'Păşunea Mare',
        simplu: 'Pasunea Mare',
        comuna: 'Calinesti-Oas'
      },
      {
        nume: 'Ianculeşti',
        simplu: 'Ianculesti',
        comuna: 'Carei'
      },
      {
        nume: 'Ady Endre',
        comuna: 'Cauas'
      },
      {
        nume: 'Ghenci',
        comuna: 'Cauas'
      },
      {
        nume: 'Ghileşti',
        simplu: 'Ghilesti',
        comuna: 'Cauas'
      },
      {
        nume: 'Hotoan',
        comuna: 'Cauas'
      },
      {
        nume: 'Răduleşti',
        simplu: 'Radulesti',
        comuna: 'Cauas'
      },
      {
        nume: 'Cehăluţ',
        simplu: 'Cehalut',
        comuna: 'Cehal'
      },
      {
        nume: 'Orbău',
        simplu: 'Orbau',
        comuna: 'Cehal'
      },
      {
        nume: 'Huta Certeze',
        comuna: 'Certeze'
      },
      {
        nume: 'Moişeni',
        simplu: 'Moiseni',
        comuna: 'Certeze'
      },
      {
        nume: 'Crişeni',
        simplu: 'Criseni',
        comuna: 'Craidorolt'
      },
      {
        nume: 'Eriu Sâncrai',
        simplu: 'Eriu Sancrai',
        comuna: 'Craidorolt'
      },
      {
        nume: 'Satu Mic',
        comuna: 'Craidorolt'
      },
      {
        nume: 'Teghea',
        simplu: 'Teghea',
        comuna: 'Craidorolt'
      },
      {
        nume: 'Iegherişte',
        simplu: 'Iegheriste',
        comuna: 'Crucisor'
      },
      {
        nume: 'Poiana Codrului',
        comuna: 'Crucisor'
      },
      {
        nume: 'Apateu',
        comuna: 'Culciu'
      },
      {
        nume: 'Corod',
        comuna: 'Culciu'
      },
      {
        nume: 'Culciu Mare',
        comuna: 'Culciu'
      },
      {
        nume: 'Culciu Mic',
        comuna: 'Culciu'
      },
      {
        nume: 'Cărăşeu',
        simplu: 'Caraseu',
        comuna: 'Culciu'
      },
      {
        nume: 'Lipău',
        simplu: 'Lipau',
        comuna: 'Culciu'
      },
      {
        nume: 'Boghiş',
        simplu: 'Boghis',
        comuna: 'Doba'
      },
      {
        nume: 'Dacia',
        comuna: 'Doba'
      },
      {
        nume: 'Paulian',
        comuna: 'Doba'
      },
      {
        nume: 'Traian',
        comuna: 'Doba'
      },
      {
        nume: 'Atea',
        comuna: 'Dorolt'
      },
      {
        nume: 'Dara',
        comuna: 'Dorolt'
      },
      {
        nume: 'Petea',
        comuna: 'Dorolt'
      },
      {
        nume: 'Băbeşti',
        simplu: 'Babesti',
        comuna: 'Halmeu'
      },
      {
        nume: 'Cidreag',
        comuna: 'Halmeu'
      },
      {
        nume: 'Dobolţ',
        simplu: 'Dobolt',
        comuna: 'Halmeu'
      },
      {
        nume: 'Halmeu Vii',
        comuna: 'Halmeu'
      },
      {
        nume: 'Mesteacăn',
        simplu: 'Mesteacan',
        comuna: 'Halmeu'
      },
      {
        nume: 'Porumbeşti',
        simplu: 'Porumbesti',
        comuna: 'Halmeu'
      },
      {
        nume: 'Giurtelecu Hododului',
        comuna: 'Hodod'
      },
      {
        nume: 'Lelei',
        comuna: 'Hodod'
      },
      {
        nume: 'Nadişu Hododului',
        simplu: 'Nadisu Hododului',
        comuna: 'Hodod'
      },
      {
        nume: 'Chilia',
        comuna: 'Homoroade'
      },
      {
        nume: 'Homorodu de Jos',
        comuna: 'Homoroade'
      },
      {
        nume: 'Homorodu de Mijloc',
        comuna: 'Homoroade'
      },
      {
        nume: 'Homorodu de Sus',
        comuna: 'Homoroade'
      },
      {
        nume: 'Necopoi',
        comuna: 'Homoroade'
      },
      {
        nume: 'Solduba',
        comuna: 'Homoroade'
      },
      {
        nume: 'Bercu',
        comuna: 'Lazuri'
      },
      {
        nume: 'Nisipeni',
        comuna: 'Lazuri'
      },
      {
        nume: 'Noroieni',
        comuna: 'Lazuri'
      },
      {
        nume: 'Peleş',
        simplu: 'Peles',
        comuna: 'Lazuri'
      },
      {
        nume: 'Pelişor',
        simplu: 'Pelisor',
        comuna: 'Lazuri'
      },
      {
        nume: 'Adrian',
        comuna: 'Livada'
      },
      {
        nume: 'Dumbrava',
        comuna: 'Livada'
      },
      {
        nume: 'Livada Mică',
        simplu: 'Livada Mica',
        comuna: 'Livada'
      },
      {
        nume: 'Băbăşeşti',
        simplu: 'Babasesti',
        comuna: 'Mediesu Aurit'
      },
      {
        nume: 'Iojib',
        comuna: 'Mediesu Aurit'
      },
      {
        nume: 'Medieş Râturi',
        simplu: 'Medies Raturi',
        comuna: 'Mediesu Aurit'
      },
      {
        nume: 'Medieş Vii',
        simplu: 'Medies Vii',
        comuna: 'Mediesu Aurit'
      },
      {
        nume: 'Potău',
        simplu: 'Potau',
        comuna: 'Mediesu Aurit'
      },
      {
        nume: 'Româneşti',
        simplu: 'Romanesti',
        comuna: 'Mediesu Aurit'
      },
      {
        nume: 'Bercu Nou',
        comuna: 'Micula'
      },
      {
        nume: 'Micula Nouă',
        simplu: 'Micula Noua',
        comuna: 'Micula'
      },
      {
        nume: 'Domăneşti',
        simplu: 'Domanesti',
        comuna: 'Moftin'
      },
      {
        nume: 'Ghilvaci',
        comuna: 'Moftin'
      },
      {
        nume: 'Ghirolt',
        comuna: 'Moftin'
      },
      {
        nume: 'Istrău',
        simplu: 'Istrau',
        comuna: 'Moftin'
      },
      {
        nume: 'Moftinu Mare',
        comuna: 'Moftin'
      },
      {
        nume: 'Moftinu Mic',
        comuna: 'Moftin'
      },
      {
        nume: 'Sânmiclăuş',
        simplu: 'Sanmiclaus',
        comuna: 'Moftin'
      },
      {
        nume: 'Luna',
        comuna: 'Negresti-Oas'
      },
      {
        nume: 'Tur',
        comuna: 'Negresti-Oas'
      },
      {
        nume: 'Berindan',
        comuna: 'Odoreu'
      },
      {
        nume: 'Cucu',
        comuna: 'Odoreu'
      },
      {
        nume: 'Eteni',
        comuna: 'Odoreu'
      },
      {
        nume: 'Mărtineşti',
        simplu: 'Martinesti',
        comuna: 'Odoreu'
      },
      {
        nume: 'Vânătoreşti',
        simplu: 'Vanatoresti',
        comuna: 'Odoreu'
      },
      {
        nume: 'Oraşu Nou-Vii',
        simplu: 'Orasu Nou-Vii',
        comuna: 'Orasu Nou'
      },
      {
        nume: 'Prilog',
        comuna: 'Orasu Nou'
      },
      {
        nume: 'Prilog Vii',
        comuna: 'Orasu Nou'
      },
      {
        nume: 'Racşa',
        simplu: 'Racsa',
        comuna: 'Orasu Nou'
      },
      {
        nume: 'Racşa Vii',
        simplu: 'Racsa Vii',
        comuna: 'Orasu Nou'
      },
      {
        nume: 'Remetea Oaşului',
        simplu: 'Remetea Oasului',
        comuna: 'Orasu Nou'
      },
      {
        nume: 'Amaţi',
        simplu: 'Amati',
        comuna: 'Paulesti'
      },
      {
        nume: 'Ambud',
        comuna: 'Paulesti'
      },
      {
        nume: 'Hrip',
        comuna: 'Paulesti'
      },
      {
        nume: 'Petin',
        comuna: 'Paulesti'
      },
      {
        nume: 'Ruşeni',
        simplu: 'Ruseni',
        comuna: 'Paulesti'
      },
      {
        nume: 'Dindeştiu Mic',
        simplu: 'Dindestiu Mic',
        comuna: 'Petresti'
      },
      {
        nume: 'Piru Nou',
        comuna: 'Pir'
      },
      {
        nume: 'Sărvăzel',
        simplu: 'Sarvazel',
        comuna: 'Pir'
      },
      {
        nume: 'Resighea',
        comuna: 'Piscolt'
      },
      {
        nume: 'Scărişoara Nouă',
        simplu: 'Scarisoara Noua',
        comuna: 'Piscolt'
      },
      {
        nume: 'Aciua',
        comuna: 'Pomi'
      },
      {
        nume: 'Bicău',
        simplu: 'Bicau',
        comuna: 'Pomi'
      },
      {
        nume: 'Borleşti',
        simplu: 'Borlesti',
        comuna: 'Pomi'
      },
      {
        nume: 'Chegea',
        comuna: 'Sacaseni'
      },
      {
        nume: 'Berea',
        comuna: 'Sanislau'
      },
      {
        nume: 'Ciumeşti',
        simplu: 'Ciumesti',
        comuna: 'Sanislau'
      },
      {
        nume: 'Horea',
        comuna: 'Sanislau'
      },
      {
        nume: 'Marna Nouă',
        simplu: 'Marna Noua',
        comuna: 'Sanislau'
      },
      {
        nume: 'Viişoara',
        simplu: 'Viisoara',
        comuna: 'Sanislau'
      },
      {
        nume: 'Chereuşa',
        simplu: 'Chereusa',
        comuna: 'Santau'
      },
      {
        nume: 'Sudurău',
        simplu: 'Sudurau',
        comuna: 'Santau'
      },
      {
        nume: 'Sătmărel',
        simplu: 'Satmarel',
        comuna: 'Satu Mare'
      },
      {
        nume: 'Becheni',
        comuna: 'Sauca'
      },
      {
        nume: 'Cean',
        comuna: 'Sauca'
      },
      {
        nume: 'Chisău',
        simplu: 'Chisau',
        comuna: 'Sauca'
      },
      {
        nume: 'Silvaş',
        simplu: 'Silvas',
        comuna: 'Sauca'
      },
      {
        nume: 'Cuţa',
        simplu: 'Cuta',
        comuna: 'Socond'
      },
      {
        nume: 'Hodişa',
        simplu: 'Hodisa',
        comuna: 'Socond'
      },
      {
        nume: 'Soconzel',
        comuna: 'Socond'
      },
      {
        nume: 'Stâna',
        simplu: 'Stana',
        comuna: 'Socond'
      },
      {
        nume: 'Dobra',
        comuna: 'Supur'
      },
      {
        nume: 'Giorocuta',
        comuna: 'Supur'
      },
      {
        nume: 'Hurezu Mare',
        comuna: 'Supur'
      },
      {
        nume: 'Racova',
        comuna: 'Supur'
      },
      {
        nume: 'Sechereşa',
        simplu: 'Secheresa',
        comuna: 'Supur'
      },
      {
        nume: 'Supuru de Jos',
        comuna: 'Supur'
      },
      {
        nume: 'Supuru de Sus',
        comuna: 'Supur'
      },
      {
        nume: 'Bocicău',
        simplu: 'Bocicau',
        comuna: 'Tarna Mare'
      },
      {
        nume: 'Valea Seacă',
        simplu: 'Valea Seaca',
        comuna: 'Tarna Mare'
      },
      {
        nume: 'Văgaş',
        simplu: 'Vagas',
        comuna: 'Tarna Mare'
      },
      {
        nume: 'Aliceni',
        comuna: 'Tarsolt'
      },
      {
        nume: 'Blaja',
        comuna: 'Tasnad'
      },
      {
        nume: 'Cig',
        comuna: 'Tasnad'
      },
      {
        nume: 'Raţiu',
        simplu: 'Ratiu',
        comuna: 'Tasnad'
      },
      {
        nume: 'Sărăuad',
        simplu: 'Sarauad',
        comuna: 'Tasnad'
      },
      {
        nume: 'Valea Morii',
        comuna: 'Tasnad'
      },
      {
        nume: 'Aliza',
        comuna: 'Terebesti'
      },
      {
        nume: 'Gelu',
        comuna: 'Terebesti'
      },
      {
        nume: 'Pişcari',
        simplu: 'Piscari',
        comuna: 'Terebesti'
      },
      {
        nume: 'Portiţa',
        simplu: 'Portita',
        comuna: 'Tiream'
      },
      {
        nume: 'Vezendiu',
        comuna: 'Tiream'
      },
      {
        nume: 'Gherţa Mare',
        simplu: 'Gherta Mare',
        comuna: 'Turt'
      },
      {
        nume: 'Turţ Băi',
        simplu: 'Turt Bai',
        comuna: 'Turt'
      },
      {
        nume: 'Drăguşeni',
        simplu: 'Draguseni',
        comuna: 'Turulung'
      },
      {
        nume: 'Turulung Vii',
        comuna: 'Turulung'
      },
      {
        nume: 'Urziceni Pădure',
        simplu: 'Urziceni Padure',
        comuna: 'Urziceni'
      },
      {
        nume: 'Măriuş',
        simplu: 'Marius',
        comuna: 'Valea Vinului'
      },
      {
        nume: 'Roşiori',
        simplu: 'Rosiori',
        comuna: 'Valea Vinului'
      },
      {
        nume: 'Sâi',
        simplu: 'Sai',
        comuna: 'Valea Vinului'
      },
      {
        nume: 'Decebal',
        comuna: 'Vetis'
      },
      {
        nume: 'Oar',
        comuna: 'Vetis'
      },
      {
        nume: 'Cioncheşti',
        simplu: 'Cionchesti',
        comuna: 'Viile Satu Mare'
      },
      {
        nume: 'Medişa',
        simplu: 'Medisa',
        comuna: 'Viile Satu Mare'
      },
      {
        nume: 'Tireac',
        comuna: 'Viile Satu Mare'
      },
      {
        nume: 'Tătăreşti',
        simplu: 'Tataresti',
        comuna: 'Viile Satu Mare'
      }
    ]
  },
  {
    auto: 'SB',
    nume: 'Sibiu',
    localitati: [
      {
        nume: 'Agnita'
      },
      {
        nume: 'Alţina',
        simplu: 'Altina'
      },
      {
        nume: 'Apoldu de Jos'
      },
      {
        nume: 'Arpaşu de Jos',
        simplu: 'Arpasu de Jos'
      },
      {
        nume: 'Avrig'
      },
      {
        nume: 'Axente Sever'
      },
      {
        nume: 'Aţel',
        simplu: 'Atel'
      },
      {
        nume: 'Bazna'
      },
      {
        nume: 'Biertan'
      },
      {
        nume: 'Blăjel',
        simplu: 'Blajel'
      },
      {
        nume: 'Brateiu'
      },
      {
        nume: 'Bruiu'
      },
      {
        nume: 'Brădeni',
        simplu: 'Bradeni'
      },
      {
        nume: 'Chirpăr',
        simplu: 'Chirpar'
      },
      {
        nume: 'Cisnădie',
        simplu: 'Cisnadie'
      },
      {
        nume: 'Copşa Mică',
        simplu: 'Copsa Mica'
      },
      {
        nume: 'Cristian'
      },
      {
        nume: 'Cârţa',
        simplu: 'Carta'
      },
      {
        nume: 'Cârţişoara',
        simplu: 'Cartisoara'
      },
      {
        nume: 'Dumbrăveni',
        simplu: 'Dumbraveni'
      },
      {
        nume: 'Dârlos',
        simplu: 'Darlos'
      },
      {
        nume: 'Gura Râului',
        simplu: 'Gura Raului'
      },
      {
        nume: 'Hoghilag'
      },
      {
        nume: 'Iacobeni'
      },
      {
        nume: 'Jina'
      },
      {
        nume: 'Laslea'
      },
      {
        nume: 'Loamneş',
        simplu: 'Loamnes'
      },
      {
        nume: 'Ludoş',
        simplu: 'Ludos'
      },
      {
        nume: 'Marpod'
      },
      {
        nume: 'Mediaş',
        simplu: 'Medias'
      },
      {
        nume: 'Merghindeal'
      },
      {
        nume: 'Micăsasa',
        simplu: 'Micasasa'
      },
      {
        nume: 'Miercurea Sibiului'
      },
      {
        nume: 'Mihăileni',
        simplu: 'Mihaileni'
      },
      {
        nume: 'Moşna',
        simplu: 'Mosna'
      },
      {
        nume: 'Nocrich'
      },
      {
        nume: 'Ocna Sibiului'
      },
      {
        nume: 'Orlat'
      },
      {
        nume: 'Poiana Sibiului'
      },
      {
        nume: 'Poplaca'
      },
      {
        nume: 'Porumbacu de Jos'
      },
      {
        nume: 'Păuca',
        simplu: 'Pauca'
      },
      {
        nume: 'Racoviţa',
        simplu: 'Racovita'
      },
      {
        nume: 'Roşia',
        simplu: 'Rosia'
      },
      {
        nume: 'Râu Sadului',
        simplu: 'Rau Sadului'
      },
      {
        nume: 'Răşinari',
        simplu: 'Rasinari'
      },
      {
        nume: 'Sadu'
      },
      {
        nume: 'Sibiu'
      },
      {
        nume: 'Slimnic'
      },
      {
        nume: 'Sălişte',
        simplu: 'Saliste'
      },
      {
        nume: 'Tilişca',
        simplu: 'Tilisca'
      },
      {
        nume: 'Turnu Roşu',
        simplu: 'Turnu Rosu'
      },
      {
        nume: 'Târnava',
        simplu: 'Tarnava'
      },
      {
        nume: 'Tălmaciu',
        simplu: 'Talmaciu'
      },
      {
        nume: 'Valea Viilor'
      },
      {
        nume: 'Vurpăr',
        simplu: 'Vurpar'
      },
      {
        nume: 'Seica Mare',
        simplu: 'Seica Mare'
      },
      {
        nume: 'Seica Mică',
        simplu: 'Seica Mica'
      },
      {
        nume: 'Selimbăr',
        simplu: 'Selimbar'
      },
      {
        nume: 'Sura Mare',
        simplu: 'Sura Mare'
      },
      {
        nume: 'Sura Mică',
        simplu: 'Sura Mica'
      },
      {
        nume: 'Coveş',
        simplu: 'Coves',
        comuna: 'Agnita'
      },
      {
        nume: 'Ruja',
        comuna: 'Agnita'
      },
      {
        nume: 'Beneşti',
        simplu: 'Benesti',
        comuna: 'Altina'
      },
      {
        nume: 'Ghijasa de Sus',
        comuna: 'Altina'
      },
      {
        nume: 'Sângătin',
        simplu: 'Sangatin',
        comuna: 'Apoldu De Jos'
      },
      {
        nume: 'Arpaşu de Sus',
        simplu: 'Arpasu de Sus',
        comuna: 'Arpasu De Jos'
      },
      {
        nume: 'Noul Român',
        simplu: 'Noul Roman',
        comuna: 'Arpasu De Jos'
      },
      {
        nume: 'Alma',
        comuna: 'Atel'
      },
      {
        nume: 'Dupuş',
        simplu: 'Dupus',
        comuna: 'Atel'
      },
      {
        nume: 'Giacăş',
        simplu: 'Giacas',
        comuna: 'Atel'
      },
      {
        nume: 'Smig',
        simplu: 'Smig',
        comuna: 'Atel'
      },
      {
        nume: 'Bradu',
        comuna: 'Avrig'
      },
      {
        nume: 'Glâmboaca',
        simplu: 'Glamboaca',
        comuna: 'Avrig'
      },
      {
        nume: 'Mârşa',
        simplu: 'Marsa',
        comuna: 'Avrig'
      },
      {
        nume: 'Săcădate',
        simplu: 'Sacadate',
        comuna: 'Avrig'
      },
      {
        nume: 'Agârbiciu',
        simplu: 'Agarbiciu',
        comuna: 'Axente Sever'
      },
      {
        nume: 'Soala',
        simplu: 'Soala',
        comuna: 'Axente Sever'
      },
      {
        nume: 'Boian',
        comuna: 'Bazna'
      },
      {
        nume: 'Velţ',
        simplu: 'Velt',
        comuna: 'Bazna'
      },
      {
        nume: 'Copşa Mare',
        simplu: 'Copsa Mare',
        comuna: 'Biertan'
      },
      {
        nume: 'Richiş',
        simplu: 'Richis',
        comuna: 'Biertan'
      },
      {
        nume: 'Apoş',
        simplu: 'Apos',
        comuna: 'Birghis'
      },
      {
        nume: 'Bârghiş',
        simplu: 'Barghis',
        comuna: 'Birghis'
      },
      {
        nume: 'Ighişu Vechi',
        simplu: 'Ighisu Vechi',
        comuna: 'Birghis'
      },
      {
        nume: 'Pelişor',
        simplu: 'Pelisor',
        comuna: 'Birghis'
      },
      {
        nume: 'Vecerd',
        comuna: 'Birghis'
      },
      {
        nume: 'Zlagna',
        comuna: 'Birghis'
      },
      {
        nume: 'Păucea',
        simplu: 'Paucea',
        comuna: 'Blajel'
      },
      {
        nume: 'Romaneşti',
        simplu: 'Romanesti',
        comuna: 'Blajel'
      },
      {
        nume: 'Retiş',
        simplu: 'Retis',
        comuna: 'Bradeni'
      },
      {
        nume: 'Teline',
        simplu: 'Teline',
        comuna: 'Bradeni'
      },
      {
        nume: 'Buzd',
        comuna: 'Brateiu'
      },
      {
        nume: 'Gherdeal',
        comuna: 'Bruiu'
      },
      {
        nume: 'Somartin',
        simplu: 'Somartin',
        comuna: 'Bruiu'
      },
      {
        nume: 'Poieniţa',
        simplu: 'Poienita',
        comuna: 'Carta'
      },
      {
        nume: 'Săsăuş',
        simplu: 'Sasaus',
        comuna: 'Chirpar'
      },
      {
        nume: 'Veseud',
        comuna: 'Chirpar'
      },
      {
        nume: 'Vărd',
        simplu: 'Vard',
        comuna: 'Chirpar'
      },
      {
        nume: 'Cisnădioara',
        simplu: 'Cisnadioara',
        comuna: 'Cisnadie'
      },
      {
        nume: 'Curciu',
        comuna: 'Darlos'
      },
      {
        nume: 'Valea Lungă',
        simplu: 'Valea Lunga',
        comuna: 'Darlos'
      },
      {
        nume: 'Ernea',
        comuna: 'Dumbraveni'
      },
      {
        nume: 'Saroş pe Târnave',
        simplu: 'Saros pe Tarnave',
        comuna: 'Dumbraveni'
      },
      {
        nume: 'Prod',
        comuna: 'Hoghilag'
      },
      {
        nume: 'Valchid',
        comuna: 'Hoghilag'
      },
      {
        nume: 'Movile',
        comuna: 'Iacobeni'
      },
      {
        nume: 'Netuş',
        simplu: 'Netus',
        comuna: 'Iacobeni'
      },
      {
        nume: 'Noiştat',
        simplu: 'Noistat',
        comuna: 'Iacobeni'
      },
      {
        nume: 'Stejărişu',
        simplu: 'Stejarisu',
        comuna: 'Iacobeni'
      },
      {
        nume: 'Floreşti',
        simplu: 'Floresti',
        comuna: 'Laslea'
      },
      {
        nume: 'Mălâncrav',
        simplu: 'Malancrav',
        comuna: 'Laslea'
      },
      {
        nume: 'Nou Săsesc',
        simplu: 'Nou Sasesc',
        comuna: 'Laslea'
      },
      {
        nume: 'Roandola',
        comuna: 'Laslea'
      },
      {
        nume: 'Alămor',
        simplu: 'Alamor',
        comuna: 'Loamnes'
      },
      {
        nume: 'Armeni',
        comuna: 'Loamnes'
      },
      {
        nume: 'Haşag',
        simplu: 'Hasag',
        comuna: 'Loamnes'
      },
      {
        nume: 'Mândra',
        simplu: 'Mandra',
        comuna: 'Loamnes'
      },
      {
        nume: 'Sădinca',
        simplu: 'Sadinca',
        comuna: 'Loamnes'
      },
      {
        nume: 'Gusu',
        comuna: 'Ludos'
      },
      {
        nume: 'Ilimbav',
        comuna: 'Marpod'
      },
      {
        nume: 'Ighişu Nou',
        simplu: 'Ighisu Nou',
        comuna: 'Medias'
      },
      {
        nume: 'Dealu Frumos',
        comuna: 'Merghindeal'
      },
      {
        nume: 'Chesler',
        comuna: 'Micasasa'
      },
      {
        nume: 'Văleni',
        simplu: 'Valeni',
        comuna: 'Micasasa'
      },
      {
        nume: 'Tapu',
        simplu: 'Tapu',
        comuna: 'Micasasa'
      },
      {
        nume: 'Apoldu de Sus',
        comuna: 'Miercurea Sibiului'
      },
      {
        nume: 'Dobârca',
        simplu: 'Dobarca',
        comuna: 'Miercurea Sibiului'
      },
      {
        nume: 'Metiş',
        simplu: 'Metis',
        comuna: 'Mihaileni'
      },
      {
        nume: 'Moardăş',
        simplu: 'Moardas',
        comuna: 'Mihaileni'
      },
      {
        nume: 'Răvăşel',
        simplu: 'Ravasel',
        comuna: 'Mihaileni'
      },
      {
        nume: 'Salcău',
        simplu: 'Salcau',
        comuna: 'Mihaileni'
      },
      {
        nume: 'Alma Vii',
        comuna: 'Mosna'
      },
      {
        nume: 'Nemşa',
        simplu: 'Nemsa',
        comuna: 'Mosna'
      },
      {
        nume: 'Fofeldea',
        comuna: 'Nocrich'
      },
      {
        nume: 'Ghijasa de Jos',
        comuna: 'Nocrich'
      },
      {
        nume: 'Hosman',
        comuna: 'Nocrich'
      },
      {
        nume: 'Tichindeal',
        simplu: 'Tichindeal',
        comuna: 'Nocrich'
      },
      {
        nume: 'Topârcea',
        simplu: 'Toparcea',
        comuna: 'Ocna Sibiului'
      },
      {
        nume: 'Bogatu Român',
        simplu: 'Bogatu Roman',
        comuna: 'Pauca'
      },
      {
        nume: 'Broşteni',
        simplu: 'Brosteni',
        comuna: 'Pauca'
      },
      {
        nume: 'Presaca',
        comuna: 'Pauca'
      },
      {
        nume: 'Colun',
        comuna: 'Porumbacu De Jos'
      },
      {
        nume: 'Porumbacu de Sus',
        comuna: 'Porumbacu De Jos'
      },
      {
        nume: 'Scoreiu',
        comuna: 'Porumbacu De Jos'
      },
      {
        nume: 'Sărata',
        simplu: 'Sarata',
        comuna: 'Porumbacu De Jos'
      },
      {
        nume: 'Sebeşu de Sus',
        simplu: 'Sebesu de Sus',
        comuna: 'Racovita'
      },
      {
        nume: 'Prislop',
        comuna: 'Rasinari'
      },
      {
        nume: 'Caşolţ',
        simplu: 'Casolt',
        comuna: 'Rosia'
      },
      {
        nume: 'Cornăţel',
        simplu: 'Cornatel',
        comuna: 'Rosia'
      },
      {
        nume: 'Daia',
        comuna: 'Rosia'
      },
      {
        nume: 'Nou',
        comuna: 'Rosia'
      },
      {
        nume: 'Nucet',
        comuna: 'Rosia'
      },
      {
        nume: 'Aciliu',
        comuna: 'Saliste'
      },
      {
        nume: 'Amnaş',
        simplu: 'Amnas',
        comuna: 'Saliste'
      },
      {
        nume: 'Crinţ',
        simplu: 'Crint',
        comuna: 'Saliste'
      },
      {
        nume: 'Fântânele',
        simplu: 'Fantanele',
        comuna: 'Saliste'
      },
      {
        nume: 'Galeş',
        simplu: 'Gales',
        comuna: 'Saliste'
      },
      {
        nume: 'Mag',
        comuna: 'Saliste'
      },
      {
        nume: 'Sibiel',
        comuna: 'Saliste'
      },
      {
        nume: 'Săcel',
        simplu: 'Sacel',
        comuna: 'Saliste'
      },
      {
        nume: 'Vale',
        comuna: 'Saliste'
      },
      {
        nume: 'Boarta',
        comuna: 'Seica Mare'
      },
      {
        nume: 'Buia',
        comuna: 'Seica Mare'
      },
      {
        nume: 'Mighindoala',
        comuna: 'Seica Mare'
      },
      {
        nume: 'Petiş',
        simplu: 'Petis',
        comuna: 'Seica Mare'
      },
      {
        nume: 'Stenea',
        simplu: 'Stenea',
        comuna: 'Seica Mare'
      },
      {
        nume: 'Soroştin',
        simplu: 'Sorostin',
        comuna: 'Seica Mica'
      },
      {
        nume: 'Bungard',
        comuna: 'Selimbar'
      },
      {
        nume: 'Mohu',
        comuna: 'Selimbar'
      },
      {
        nume: 'Veştem',
        simplu: 'Vestem',
        comuna: 'Selimbar'
      },
      {
        nume: 'Păltiniş',
        simplu: 'Paltinis',
        comuna: 'Sibiu'
      },
      {
        nume: 'Albi',
        comuna: 'Slimnic'
      },
      {
        nume: 'Pădureni',
        simplu: 'Padureni',
        comuna: 'Slimnic'
      },
      {
        nume: 'Ruşi',
        simplu: 'Rusi',
        comuna: 'Slimnic'
      },
      {
        nume: 'Veseud',
        comuna: 'Slimnic'
      },
      {
        nume: 'Hamba',
        comuna: 'Sura Mare'
      },
      {
        nume: 'Rusciori',
        comuna: 'Sura Mica'
      },
      {
        nume: 'Boiţa',
        simplu: 'Boita',
        comuna: 'Talmaciu'
      },
      {
        nume: 'Colonia Tălmaciu',
        simplu: 'Colonia Talmaciu',
        comuna: 'Talmaciu'
      },
      {
        nume: 'Lazaret',
        comuna: 'Talmaciu'
      },
      {
        nume: 'Lotrioara',
        comuna: 'Talmaciu'
      },
      {
        nume: 'Paltin',
        comuna: 'Talmaciu'
      },
      {
        nume: 'Tălmăcel',
        simplu: 'Talmacel',
        comuna: 'Talmaciu'
      },
      {
        nume: 'Colonia Târnava',
        simplu: 'Colonia Tarnava',
        comuna: 'Tarnava'
      },
      {
        nume: 'Rod',
        comuna: 'Tilisca'
      },
      {
        nume: 'Sebeşu de Jos',
        simplu: 'Sebesu de Jos',
        comuna: 'Turnu Rosu'
      },
      {
        nume: 'Motiş',
        simplu: 'Motis',
        comuna: 'Valea Viilor'
      }
    ]
  },
  {
    auto: 'SV',
    nume: 'Suceava',
    localitati: [
      {
        nume: 'Adâncata',
        simplu: 'Adancata'
      },
      {
        nume: 'Arbore'
      },
      {
        nume: 'Baia'
      },
      {
        nume: 'Bilca'
      },
      {
        nume: 'Bogdăneşti',
        simplu: 'Bogdanesti'
      },
      {
        nume: 'Boroaia'
      },
      {
        nume: 'Bosanci'
      },
      {
        nume: 'Botoşana',
        simplu: 'Botosana'
      },
      {
        nume: 'Breaza'
      },
      {
        nume: 'Brodina'
      },
      {
        nume: 'Broşteni',
        simplu: 'Brosteni'
      },
      {
        nume: 'Buneşti',
        simplu: 'Bunesti'
      },
      {
        nume: 'Bălcăuţi',
        simplu: 'Balcauti'
      },
      {
        nume: 'Cacica'
      },
      {
        nume: 'Cajvana'
      },
      {
        nume: 'Calafindeşti',
        simplu: 'Calafindesti'
      },
      {
        nume: 'Capu Câmpului',
        simplu: 'Capu Campului'
      },
      {
        nume: 'Ciocăneşti',
        simplu: 'Ciocanesti'
      },
      {
        nume: 'Ciprian Porumbescu'
      },
      {
        nume: 'Comăneşti',
        simplu: 'Comanesti'
      },
      {
        nume: 'Cornu Luncii'
      },
      {
        nume: 'Coşna',
        simplu: 'Cosna'
      },
      {
        nume: 'Crucea'
      },
      {
        nume: 'Câmpulung Moldovenesc',
        simplu: 'Campulung Moldovenesc'
      },
      {
        nume: 'Cârlibaba',
        simplu: 'Carlibaba'
      },
      {
        nume: 'Dolhasca'
      },
      {
        nume: 'Dorna Candrenilor'
      },
      {
        nume: 'Dorna-Arini'
      },
      {
        nume: 'Dorneşti',
        simplu: 'Dornesti'
      },
      {
        nume: 'Drăgoieşti',
        simplu: 'Dragoiesti'
      },
      {
        nume: 'Drăguşeni',
        simplu: 'Draguseni'
      },
      {
        nume: 'Dumbrăveni',
        simplu: 'Dumbraveni'
      },
      {
        nume: 'Dărmăneşti',
        simplu: 'Darmanesti'
      },
      {
        nume: 'Forăşti',
        simplu: 'Forasti'
      },
      {
        nume: 'Frasin'
      },
      {
        nume: 'Frumosu'
      },
      {
        nume: 'Frătăuţii Noi',
        simplu: 'Fratautii Noi'
      },
      {
        nume: 'Frătăuţii Vechi',
        simplu: 'Fratautii Vechi'
      },
      {
        nume: 'Fundu Moldovei'
      },
      {
        nume: 'Fântâna Mare',
        simplu: 'Fantana Mare'
      },
      {
        nume: 'Fântânele',
        simplu: 'Fantanele'
      },
      {
        nume: 'Fălticeni',
        simplu: 'Falticeni'
      },
      {
        nume: 'Grămeşti',
        simplu: 'Gramesti'
      },
      {
        nume: 'Grăniceşti',
        simplu: 'Granicesti'
      },
      {
        nume: 'Gura Humorului'
      },
      {
        nume: 'Gălăneşti',
        simplu: 'Galanesti'
      },
      {
        nume: 'Horodnic de Sus'
      },
      {
        nume: 'Horodniceni'
      },
      {
        nume: 'Hănţeşti',
        simplu: 'Hantesti'
      },
      {
        nume: 'Iacobeni'
      },
      {
        nume: 'Iaslovăţ',
        simplu: 'Iaslovat'
      },
      {
        nume: 'Ipoteşti',
        simplu: 'Ipotesti'
      },
      {
        nume: 'Izvoarele Sucevei'
      },
      {
        nume: 'Liteni'
      },
      {
        nume: 'Marginea'
      },
      {
        nume: 'Milişăuţi',
        simplu: 'Milisauti'
      },
      {
        nume: 'Mitocu Dragomirnei'
      },
      {
        nume: 'Moldova-Suliţa',
        simplu: 'Moldova-Sulita'
      },
      {
        nume: 'Moldoviţa',
        simplu: 'Moldovita'
      },
      {
        nume: 'Muşeniţa',
        simplu: 'Musenita'
      },
      {
        nume: 'Mălini',
        simplu: 'Malini'
      },
      {
        nume: 'Mănăstirea Humorului',
        simplu: 'Manastirea Humorului'
      },
      {
        nume: 'Ostra'
      },
      {
        nume: 'Panaci'
      },
      {
        nume: 'Poiana Stampei'
      },
      {
        nume: 'Pojorâta',
        simplu: 'Pojorata'
      },
      {
        nume: 'Preuteşti',
        simplu: 'Preutesti'
      },
      {
        nume: 'Putna'
      },
      {
        nume: 'Pârteştii de Jos',
        simplu: 'Partestii de Jos'
      },
      {
        nume: 'Păltinoasa',
        simplu: 'Paltinoasa'
      },
      {
        nume: 'Pătrăuţi',
        simplu: 'Patrauti'
      },
      {
        nume: 'Râşca',
        simplu: 'Rasca'
      },
      {
        nume: 'Rădăuţi',
        simplu: 'Radauti'
      },
      {
        nume: 'Rădăşeni',
        simplu: 'Radaseni'
      },
      {
        nume: 'Sadova'
      },
      {
        nume: 'Salcea'
      },
      {
        nume: 'Satu Mare'
      },
      {
        nume: 'Siminicea'
      },
      {
        nume: 'Siret'
      },
      {
        nume: 'Slatina'
      },
      {
        nume: 'Solca'
      },
      {
        nume: 'Straja'
      },
      {
        nume: 'Stroieşti',
        simplu: 'Stroiesti'
      },
      {
        nume: 'Stulpicani'
      },
      {
        nume: 'Suceava'
      },
      {
        nume: 'Suceviţa',
        simplu: 'Sucevita'
      },
      {
        nume: 'Todireşti',
        simplu: 'Todiresti'
      },
      {
        nume: 'Udeşti',
        simplu: 'Udesti'
      },
      {
        nume: 'Ulma'
      },
      {
        nume: 'Vadu Moldovei'
      },
      {
        nume: 'Valea Moldovei'
      },
      {
        nume: 'Vama'
      },
      {
        nume: 'Vatra Dornei'
      },
      {
        nume: 'Vatra Moldoviţei',
        simplu: 'Vatra Moldovitei'
      },
      {
        nume: 'Vereşti',
        simplu: 'Veresti'
      },
      {
        nume: 'Vicovu de Jos'
      },
      {
        nume: 'Vicovu de Sus'
      },
      {
        nume: 'Volovăţ',
        simplu: 'Volovat'
      },
      {
        nume: 'Vultureşti',
        simplu: 'Vulturesti'
      },
      {
        nume: 'Zamostea'
      },
      {
        nume: 'Zvoriştea',
        simplu: 'Zvoristea'
      },
      {
        nume: 'Scheia',
        simplu: 'Scheia'
      },
      {
        nume: 'Serbăuţi',
        simplu: 'Serbauti'
      },
      {
        nume: 'Călugăreni',
        simplu: 'Calugareni',
        comuna: 'Adancata'
      },
      {
        nume: 'Feteşti',
        simplu: 'Fetesti',
        comuna: 'Adancata'
      },
      {
        nume: 'Bodnăreni',
        simplu: 'Bodnareni',
        comuna: 'Arbore'
      },
      {
        nume: 'Clit',
        comuna: 'Arbore'
      },
      {
        nume: 'Bogata',
        comuna: 'Baia'
      },
      {
        nume: 'Gropeni',
        comuna: 'Balcauti'
      },
      {
        nume: 'Negostina',
        comuna: 'Balcauti'
      },
      {
        nume: 'Bărăşti',
        simplu: 'Barasti',
        comuna: 'Boroaia'
      },
      {
        nume: 'Giuleşti',
        simplu: 'Giulesti',
        comuna: 'Boroaia'
      },
      {
        nume: 'Moişa',
        simplu: 'Moisa',
        comuna: 'Boroaia'
      },
      {
        nume: 'Săcuţa',
        simplu: 'Sacuta',
        comuna: 'Boroaia'
      },
      {
        nume: 'Cumpărătura',
        simplu: 'Cumparatura',
        comuna: 'Bosanci'
      },
      {
        nume: 'Breaza de Sus',
        comuna: 'Breaza'
      },
      {
        nume: 'Pârâu Negrei',
        simplu: 'Parau Negrei',
        comuna: 'Breaza'
      },
      {
        nume: 'Brodina de Jos',
        comuna: 'Brodina'
      },
      {
        nume: 'Cununschi',
        comuna: 'Brodina'
      },
      {
        nume: 'Dubiuşca',
        simplu: 'Dubiusca',
        comuna: 'Brodina'
      },
      {
        nume: 'Ehreşte',
        simplu: 'Ehreste',
        comuna: 'Brodina'
      },
      {
        nume: 'Falcău',
        simplu: 'Falcau',
        comuna: 'Brodina'
      },
      {
        nume: 'Norocu',
        comuna: 'Brodina'
      },
      {
        nume: 'Paltin',
        comuna: 'Brodina'
      },
      {
        nume: 'Sadău',
        simplu: 'Sadau',
        comuna: 'Brodina'
      },
      {
        nume: 'Zalomestra',
        comuna: 'Brodina'
      },
      {
        nume: 'Cotârgaşi',
        simplu: 'Cotargasi',
        comuna: 'Brosteni'
      },
      {
        nume: 'Dârmoxa',
        simplu: 'Darmoxa',
        comuna: 'Brosteni'
      },
      {
        nume: 'Frasin',
        comuna: 'Brosteni'
      },
      {
        nume: 'Holda',
        comuna: 'Brosteni'
      },
      {
        nume: 'Holdiţa',
        simplu: 'Holdita',
        comuna: 'Brosteni'
      },
      {
        nume: 'Hăleasa',
        simplu: 'Haleasa',
        comuna: 'Brosteni'
      },
      {
        nume: 'Lungeni',
        comuna: 'Brosteni'
      },
      {
        nume: 'Neagra',
        comuna: 'Brosteni'
      },
      {
        nume: 'Pietroasa',
        comuna: 'Brosteni'
      },
      {
        nume: 'Petia',
        comuna: 'Bunesti'
      },
      {
        nume: 'Podeni',
        comuna: 'Bunesti'
      },
      {
        nume: 'Unceşti',
        simplu: 'Uncesti',
        comuna: 'Bunesti'
      },
      {
        nume: 'Ses',
        simplu: 'Ses',
        comuna: 'Bunesti'
      },
      {
        nume: 'Maidan',
        comuna: 'Cacica'
      },
      {
        nume: 'Pârteştii de Sus',
        simplu: 'Partestii de Sus',
        comuna: 'Cacica'
      },
      {
        nume: 'Runcu',
        comuna: 'Cacica'
      },
      {
        nume: 'Soloneţu Nou',
        simplu: 'Solonetu Nou',
        comuna: 'Cacica'
      },
      {
        nume: 'Codru',
        comuna: 'Cajvana'
      },
      {
        nume: 'Botoşaniţa Mare',
        simplu: 'Botosanita Mare',
        comuna: 'Calafindesti'
      },
      {
        nume: 'Cârlibaba Nouă',
        simplu: 'Carlibaba Noua',
        comuna: 'Carlibaba'
      },
      {
        nume: 'Iedu',
        comuna: 'Carlibaba'
      },
      {
        nume: 'Valea Stânei',
        simplu: 'Valea Stanei',
        comuna: 'Carlibaba'
      },
      {
        nume: 'Sesuri',
        simplu: 'Sesuri',
        comuna: 'Carlibaba'
      },
      {
        nume: 'Tibău',
        simplu: 'Tibau',
        comuna: 'Carlibaba'
      },
      {
        nume: 'Botoş',
        simplu: 'Botos',
        comuna: 'Ciocanesti'
      },
      {
        nume: 'Braşca',
        simplu: 'Brasca',
        comuna: 'Ciprian Porumbescu'
      },
      {
        nume: 'Bălăceana',
        simplu: 'Balaceana',
        comuna: 'Ciprian Porumbescu'
      },
      {
        nume: 'Ilişeşti',
        simplu: 'Ilisesti',
        comuna: 'Ciprian Porumbescu'
      },
      {
        nume: 'Humoreni',
        comuna: 'Comanesti'
      },
      {
        nume: 'Brăieşti',
        simplu: 'Braiesti',
        comuna: 'Cornu Luncii'
      },
      {
        nume: 'Băişeşti',
        simplu: 'Baisesti',
        comuna: 'Cornu Luncii'
      },
      {
        nume: 'Dumbrava',
        comuna: 'Cornu Luncii'
      },
      {
        nume: 'Păiseni',
        simplu: 'Paiseni',
        comuna: 'Cornu Luncii'
      },
      {
        nume: 'Sasca Mare',
        comuna: 'Cornu Luncii'
      },
      {
        nume: 'Sasca Mică',
        simplu: 'Sasca Mica',
        comuna: 'Cornu Luncii'
      },
      {
        nume: 'Sasca Nouă',
        simplu: 'Sasca Noua',
        comuna: 'Cornu Luncii'
      },
      {
        nume: 'Sinca',
        simplu: 'Sinca',
        comuna: 'Cornu Luncii'
      },
      {
        nume: 'Podu Coşnei',
        simplu: 'Podu Cosnei',
        comuna: 'Cosna'
      },
      {
        nume: 'Româneşti',
        simplu: 'Romanesti',
        comuna: 'Cosna'
      },
      {
        nume: 'Teşna',
        simplu: 'Tesna',
        comuna: 'Cosna'
      },
      {
        nume: 'Valea Bancului',
        comuna: 'Cosna'
      },
      {
        nume: 'Chiril',
        comuna: 'Crucea'
      },
      {
        nume: 'Cojoci',
        comuna: 'Crucea'
      },
      {
        nume: 'Satu Mare',
        comuna: 'Crucea'
      },
      {
        nume: 'Călineşti',
        simplu: 'Calinesti',
        comuna: 'Darmanesti'
      },
      {
        nume: 'Călineşti-Vasilache',
        simplu: 'Calinesti-Vasilache',
        comuna: 'Darmanesti'
      },
      {
        nume: 'Dănila',
        simplu: 'Danila',
        comuna: 'Darmanesti'
      },
      {
        nume: 'Măriţei',
        simplu: 'Maritei',
        comuna: 'Darmanesti'
      },
      {
        nume: 'Măriţeia Mică',
        simplu: 'Mariteia Mica',
        comuna: 'Darmanesti'
      },
      {
        nume: 'Budeni',
        comuna: 'Dolhasca'
      },
      {
        nume: 'Gulia',
        comuna: 'Dolhasca'
      },
      {
        nume: 'Poiana',
        comuna: 'Dolhasca'
      },
      {
        nume: 'Poienari',
        comuna: 'Dolhasca'
      },
      {
        nume: 'Probota',
        comuna: 'Dolhasca'
      },
      {
        nume: 'Siliştea Nouă',
        simplu: 'Silistea Noua',
        comuna: 'Dolhasca'
      },
      {
        nume: 'Valea Poienei',
        comuna: 'Dolhasca'
      },
      {
        nume: 'Dolheştii Mici',
        simplu: 'Dolhestii Mici',
        comuna: 'Dolhesti'
      },
      {
        nume: 'Dolheştii-Mari',
        simplu: 'Dolhestii-Mari',
        comuna: 'Dolhesti'
      },
      {
        nume: 'Valea Bourei',
        comuna: 'Dolhesti'
      },
      {
        nume: 'Dealu Floreni',
        comuna: 'Dorna Candrenilor'
      },
      {
        nume: 'Poiana Negrii',
        comuna: 'Dorna Candrenilor'
      },
      {
        nume: 'Cozăneşti',
        simplu: 'Cozanesti',
        comuna: 'Dorna-Arini'
      },
      {
        nume: 'Gheorghiţeni',
        simplu: 'Gheorghiteni',
        comuna: 'Dorna-Arini'
      },
      {
        nume: 'Ortoaia',
        comuna: 'Dorna-Arini'
      },
      {
        nume: 'Rusca',
        comuna: 'Dorna-Arini'
      },
      {
        nume: 'Sunători',
        simplu: 'Sunatori',
        comuna: 'Dorna-Arini'
      },
      {
        nume: 'Iaz',
        comuna: 'Dornesti'
      },
      {
        nume: 'Berchişeşti',
        simplu: 'Berchisesti',
        comuna: 'Dragoiesti'
      },
      {
        nume: 'Corlata',
        comuna: 'Dragoiesti'
      },
      {
        nume: 'Lucăceşti',
        simplu: 'Lucacesti',
        comuna: 'Dragoiesti'
      },
      {
        nume: 'Măzănăeşti',
        simplu: 'Mazanaesti',
        comuna: 'Dragoiesti'
      },
      {
        nume: 'Broşteni',
        simplu: 'Brosteni',
        comuna: 'Draguseni'
      },
      {
        nume: 'Gara Leu',
        comuna: 'Draguseni'
      },
      {
        nume: 'Sălăgeni',
        simplu: 'Salageni',
        comuna: 'Dumbraveni'
      },
      {
        nume: 'Soldăneşti',
        simplu: 'Soldanesti',
        comuna: 'Falticeni'
      },
      {
        nume: 'Tarna Mare',
        simplu: 'Tarna Mare',
        comuna: 'Falticeni'
      },
      {
        nume: 'Cotu Băii',
        simplu: 'Cotu Baii',
        comuna: 'Fantana Mare'
      },
      {
        nume: 'Praxia',
        comuna: 'Fantana Mare'
      },
      {
        nume: 'Spătăreşti',
        simplu: 'Spataresti',
        comuna: 'Fantana Mare'
      },
      {
        nume: 'Băneşti',
        simplu: 'Banesti',
        comuna: 'Fantanele'
      },
      {
        nume: 'Cotu Dobei',
        comuna: 'Fantanele'
      },
      {
        nume: 'Slobozia',
        comuna: 'Fantanele'
      },
      {
        nume: 'Stamate',
        comuna: 'Fantanele'
      },
      {
        nume: 'Antoceni',
        comuna: 'Forasti'
      },
      {
        nume: 'Boura',
        comuna: 'Forasti'
      },
      {
        nume: 'Manolea',
        comuna: 'Forasti'
      },
      {
        nume: 'Oniceni',
        comuna: 'Forasti'
      },
      {
        nume: 'Roşiori',
        simplu: 'Rosiori',
        comuna: 'Forasti'
      },
      {
        nume: 'Ruşi',
        simplu: 'Rusi',
        comuna: 'Forasti'
      },
      {
        nume: 'Uideşti',
        simplu: 'Uidesti',
        comuna: 'Forasti'
      },
      {
        nume: 'Toleşti',
        simplu: 'Tolesti',
        comuna: 'Forasti'
      },
      {
        nume: 'Bucşoaia',
        simplu: 'Bucsoaia',
        comuna: 'Frasin'
      },
      {
        nume: 'Doroteia',
        comuna: 'Frasin'
      },
      {
        nume: 'Plutoniţa',
        simplu: 'Plutonita',
        comuna: 'Frasin'
      },
      {
        nume: 'Costişa',
        simplu: 'Costisa',
        comuna: 'Fratautii Noi'
      },
      {
        nume: 'Măneuţi',
        simplu: 'Maneuti',
        comuna: 'Fratautii Vechi'
      },
      {
        nume: 'Deia',
        comuna: 'Frumosu'
      },
      {
        nume: 'Dragoşa',
        simplu: 'Dragosa',
        comuna: 'Frumosu'
      },
      {
        nume: 'Botuş',
        simplu: 'Botus',
        comuna: 'Fundu Moldovei'
      },
      {
        nume: 'Botuşel',
        simplu: 'Botusel',
        comuna: 'Fundu Moldovei'
      },
      {
        nume: 'Braniştea',
        simplu: 'Branistea',
        comuna: 'Fundu Moldovei'
      },
      {
        nume: 'Colacu',
        comuna: 'Fundu Moldovei'
      },
      {
        nume: 'Delniţa',
        simplu: 'Delnita',
        comuna: 'Fundu Moldovei'
      },
      {
        nume: 'Deluţ',
        simplu: 'Delut',
        comuna: 'Fundu Moldovei'
      },
      {
        nume: 'Obcina',
        comuna: 'Fundu Moldovei'
      },
      {
        nume: 'Plai',
        comuna: 'Fundu Moldovei'
      },
      {
        nume: 'Smida Ungurenilor',
        comuna: 'Fundu Moldovei'
      },
      {
        nume: 'Hurjuieni',
        comuna: 'Galanesti'
      },
      {
        nume: 'Voitinel',
        comuna: 'Galanesti'
      },
      {
        nume: 'Botoşaniţa Mică',
        simplu: 'Botosanita Mica',
        comuna: 'Gramesti'
      },
      {
        nume: 'Bălineşti',
        simplu: 'Balinesti',
        comuna: 'Gramesti'
      },
      {
        nume: 'Rudeşti',
        simplu: 'Rudesti',
        comuna: 'Gramesti'
      },
      {
        nume: 'Verbia',
        comuna: 'Gramesti'
      },
      {
        nume: 'Dumbrava',
        comuna: 'Granicesti'
      },
      {
        nume: 'Gura Solcii',
        comuna: 'Granicesti'
      },
      {
        nume: 'Iacobeşti',
        simplu: 'Iacobesti',
        comuna: 'Granicesti'
      },
      {
        nume: 'Româneşti',
        simplu: 'Romanesti',
        comuna: 'Granicesti'
      },
      {
        nume: 'Slobozia Sucevei',
        comuna: 'Granicesti'
      },
      {
        nume: 'Voroneţ',
        simplu: 'Voronet',
        comuna: 'Gura Humorului'
      },
      {
        nume: 'Arţari',
        simplu: 'Artari',
        comuna: 'Hantesti'
      },
      {
        nume: 'Bereşti',
        simplu: 'Beresti',
        comuna: 'Hantesti'
      },
      {
        nume: 'Horodnic de Jos',
        comuna: 'Horodnic'
      },
      {
        nume: 'Boteşti',
        simplu: 'Botesti',
        comuna: 'Horodniceni'
      },
      {
        nume: 'Brădăţel',
        simplu: 'Bradatel',
        comuna: 'Horodniceni'
      },
      {
        nume: 'Mihăieşti',
        simplu: 'Mihaiesti',
        comuna: 'Horodniceni'
      },
      {
        nume: 'Rotopăneşti',
        simplu: 'Rotopanesti',
        comuna: 'Horodniceni'
      },
      {
        nume: 'Mestecăniş',
        simplu: 'Mestecanis',
        comuna: 'Iacobeni'
      },
      {
        nume: 'Lisaura',
        comuna: 'Ipotesti'
      },
      {
        nume: 'Tişăuţi',
        simplu: 'Tisauti',
        comuna: 'Ipotesti'
      },
      {
        nume: 'Bobeica',
        comuna: 'Izvoarele Sucevei'
      },
      {
        nume: 'Brodina',
        comuna: 'Izvoarele Sucevei'
      },
      {
        nume: 'Corni',
        comuna: 'Liteni'
      },
      {
        nume: 'Rotunda',
        comuna: 'Liteni'
      },
      {
        nume: 'Roşcani',
        simplu: 'Roscani',
        comuna: 'Liteni'
      },
      {
        nume: 'Siliştea',
        simplu: 'Silistea',
        comuna: 'Liteni'
      },
      {
        nume: 'Vercicani',
        comuna: 'Liteni'
      },
      {
        nume: 'Iesle',
        comuna: 'Malini'
      },
      {
        nume: 'Poiana Mărului',
        simplu: 'Poiana Marului',
        comuna: 'Malini'
      },
      {
        nume: 'Pâraie',
        simplu: 'Paraie',
        comuna: 'Malini'
      },
      {
        nume: 'Văleni-Stânişoara',
        simplu: 'Valeni-Stanisoara',
        comuna: 'Malini'
      },
      {
        nume: 'Pleşa',
        simplu: 'Plesa',
        comuna: 'Manastirea Humorului'
      },
      {
        nume: 'Poiana Micului',
        comuna: 'Manastirea Humorului'
      },
      {
        nume: 'Bădeuţi',
        simplu: 'Badeuti',
        comuna: 'Milisauti'
      },
      {
        nume: 'Gara',
        comuna: 'Milisauti'
      },
      {
        nume: 'Lunca',
        comuna: 'Milisauti'
      },
      {
        nume: 'Dragomirna',
        comuna: 'Mitocu Dragomirnei'
      },
      {
        nume: 'Lipoveni',
        comuna: 'Mitocu Dragomirnei'
      },
      {
        nume: 'Mitocaşi',
        simplu: 'Mitocasi',
        comuna: 'Mitocu Dragomirnei'
      },
      {
        nume: 'Bulai',
        comuna: 'Moara'
      },
      {
        nume: 'Frumoasa',
        comuna: 'Moara'
      },
      {
        nume: 'Groapa Vlădichii',
        simplu: 'Groapa Vladichii',
        comuna: 'Moara'
      },
      {
        nume: 'Liteni',
        comuna: 'Moara'
      },
      {
        nume: 'Moara Carp',
        comuna: 'Moara'
      },
      {
        nume: 'Moara Nica',
        comuna: 'Moara'
      },
      {
        nume: 'Vornicenii Mari',
        comuna: 'Moara'
      },
      {
        nume: 'Vornicenii Mici',
        comuna: 'Moara'
      },
      {
        nume: 'Benia',
        comuna: 'Moldova-Sulita'
      },
      {
        nume: 'Argel',
        comuna: 'Moldovita'
      },
      {
        nume: 'Demacuşa',
        simplu: 'Demacusa',
        comuna: 'Moldovita'
      },
      {
        nume: 'Raşca',
        simplu: 'Rasca',
        comuna: 'Moldovita'
      },
      {
        nume: 'Baineţ',
        simplu: 'Bainet',
        comuna: 'Musenita'
      },
      {
        nume: 'Bănceşti',
        simplu: 'Bancesti',
        comuna: 'Musenita'
      },
      {
        nume: 'Climăuţi',
        simplu: 'Climauti',
        comuna: 'Musenita'
      },
      {
        nume: 'Vicşani',
        simplu: 'Vicsani',
        comuna: 'Musenita'
      },
      {
        nume: 'Văşcăuţi',
        simplu: 'Vascauti',
        comuna: 'Musenita'
      },
      {
        nume: 'Tărnicioara',
        simplu: 'Tarnicioara',
        comuna: 'Ostra'
      },
      {
        nume: 'Capu Codrului',
        comuna: 'Paltinoasa'
      },
      {
        nume: 'Catrinari',
        comuna: 'Panaci'
      },
      {
        nume: 'Coverca',
        comuna: 'Panaci'
      },
      {
        nume: 'Drăgoiasa',
        simplu: 'Dragoiasa',
        comuna: 'Panaci'
      },
      {
        nume: 'Glodu',
        comuna: 'Panaci'
      },
      {
        nume: 'Păltiniş',
        simplu: 'Paltinis',
        comuna: 'Panaci'
      },
      {
        nume: 'Deleni',
        comuna: 'Partestii De Jos'
      },
      {
        nume: 'Varvata',
        comuna: 'Partestii De Jos'
      },
      {
        nume: 'Vârfu Dealului',
        simplu: 'Varfu Dealului',
        comuna: 'Partestii De Jos'
      },
      {
        nume: 'Căsoi',
        simplu: 'Casoi',
        comuna: 'Poiana Stampei'
      },
      {
        nume: 'Dornişoara',
        simplu: 'Dornisoara',
        comuna: 'Poiana Stampei'
      },
      {
        nume: 'Pilugani',
        comuna: 'Poiana Stampei'
      },
      {
        nume: 'Prăleni',
        simplu: 'Praleni',
        comuna: 'Poiana Stampei'
      },
      {
        nume: 'Teşna',
        simplu: 'Tesna',
        comuna: 'Poiana Stampei'
      },
      {
        nume: 'Tătaru',
        simplu: 'Tataru',
        comuna: 'Poiana Stampei'
      },
      {
        nume: 'Valea Putnei',
        comuna: 'Pojorata'
      },
      {
        nume: 'Arghira',
        comuna: 'Preutesti'
      },
      {
        nume: 'Bahna Arin',
        comuna: 'Preutesti'
      },
      {
        nume: 'Basarabi',
        comuna: 'Preutesti'
      },
      {
        nume: 'Huşi',
        simplu: 'Husi',
        comuna: 'Preutesti'
      },
      {
        nume: 'Hârtop',
        simplu: 'Hartop',
        comuna: 'Preutesti'
      },
      {
        nume: 'Leucuşeşti',
        simplu: 'Leucusesti',
        comuna: 'Preutesti'
      },
      {
        nume: 'Gura Putnei',
        comuna: 'Putna'
      },
      {
        nume: 'Lămăşeni',
        simplu: 'Lamaseni',
        comuna: 'Radaseni'
      },
      {
        nume: 'Pocoleni',
        comuna: 'Radaseni'
      },
      {
        nume: 'Buda',
        comuna: 'Rasca'
      },
      {
        nume: 'Dumbrăveni',
        simplu: 'Dumbraveni',
        comuna: 'Rasca'
      },
      {
        nume: 'Jahalia',
        comuna: 'Rasca'
      },
      {
        nume: 'Slătioara',
        simplu: 'Slatioara',
        comuna: 'Rasca'
      },
      {
        nume: 'Mereni',
        comuna: 'Salcea'
      },
      {
        nume: 'Plopeni',
        comuna: 'Salcea'
      },
      {
        nume: 'Prelipca',
        comuna: 'Salcea'
      },
      {
        nume: 'Văratec',
        simplu: 'Varatec',
        comuna: 'Salcea'
      },
      {
        nume: 'Gura Haitii',
        comuna: 'Saru Dornei'
      },
      {
        nume: 'Neagra Sarului',
        simplu: 'Neagra Sarului',
        comuna: 'Saru Dornei'
      },
      {
        nume: 'Plaiu Sarului',
        simplu: 'Plaiu Sarului',
        comuna: 'Saru Dornei'
      },
      {
        nume: 'Sărişor',
        simplu: 'Sarisor',
        comuna: 'Saru Dornei'
      },
      {
        nume: 'Sărişoru Mare',
        simplu: 'Sarisoru Mare',
        comuna: 'Saru Dornei'
      },
      {
        nume: 'Saru Bucovinei',
        simplu: 'Saru Bucovinei',
        comuna: 'Saru Dornei'
      },
      {
        nume: 'Sarul Dornei',
        simplu: 'Sarul Dornei',
        comuna: 'Saru Dornei'
      },
      {
        nume: 'Tibeni',
        simplu: 'Tibeni',
        comuna: 'Satu Mare'
      },
      {
        nume: 'Florinta',
        comuna: 'Scheia'
      },
      {
        nume: 'Mihoveni',
        comuna: 'Scheia'
      },
      {
        nume: 'Sfântu Ilie',
        simplu: 'Sfantu Ilie',
        comuna: 'Scheia'
      },
      {
        nume: 'Trei Movile',
        comuna: 'Scheia'
      },
      {
        nume: 'Călineşti',
        simplu: 'Calinesti',
        comuna: 'Serbauti'
      },
      {
        nume: 'Grigoreşti',
        simplu: 'Grigoresti',
        comuna: 'Siminicea'
      },
      {
        nume: 'Mănăstioara',
        simplu: 'Manastioara',
        comuna: 'Siret'
      },
      {
        nume: 'Pădureni',
        simplu: 'Padureni',
        comuna: 'Siret'
      },
      {
        nume: 'Găineşti',
        simplu: 'Gainesti',
        comuna: 'Slatina'
      },
      {
        nume: 'Herla',
        comuna: 'Slatina'
      },
      {
        nume: 'Poieni-Solca',
        comuna: 'Solca'
      },
      {
        nume: 'Vâlcelele',
        simplu: 'Valcelele',
        comuna: 'Stroiesti'
      },
      {
        nume: 'Zahareşti',
        simplu: 'Zaharesti',
        comuna: 'Stroiesti'
      },
      {
        nume: 'Gemenea',
        comuna: 'Stulpicani'
      },
      {
        nume: 'Negrileasa',
        comuna: 'Stulpicani'
      },
      {
        nume: 'Slătioara',
        simplu: 'Slatioara',
        comuna: 'Stulpicani'
      },
      {
        nume: 'Vadu Negrilesei',
        comuna: 'Stulpicani'
      },
      {
        nume: 'Voievodeasa',
        comuna: 'Sucevita'
      },
      {
        nume: 'Costâna',
        simplu: 'Costana',
        comuna: 'Todiresti'
      },
      {
        nume: 'Părhăuţi',
        simplu: 'Parhauti',
        comuna: 'Todiresti'
      },
      {
        nume: 'Soloneţ',
        simplu: 'Solonet',
        comuna: 'Todiresti'
      },
      {
        nume: 'Sârghieşti',
        simplu: 'Sarghiesti',
        comuna: 'Todiresti'
      },
      {
        nume: 'Chilişeni',
        simplu: 'Chiliseni',
        comuna: 'Udesti'
      },
      {
        nume: 'Luncuşoara',
        simplu: 'Luncusoara',
        comuna: 'Udesti'
      },
      {
        nume: 'Mănăstioara',
        simplu: 'Manastioara',
        comuna: 'Udesti'
      },
      {
        nume: 'Plăvălari',
        simplu: 'Plavalari',
        comuna: 'Udesti'
      },
      {
        nume: 'Poieni-Suceava',
        comuna: 'Udesti'
      },
      {
        nume: 'Racova',
        comuna: 'Udesti'
      },
      {
        nume: 'Reuseni',
        comuna: 'Udesti'
      },
      {
        nume: 'Ruşii-Mănăstioara',
        simplu: 'Rusii-Manastioara',
        comuna: 'Udesti'
      },
      {
        nume: 'Securiceni',
        comuna: 'Udesti'
      },
      {
        nume: 'Stirbăţ',
        simplu: 'Stirbat',
        comuna: 'Udesti'
      },
      {
        nume: 'Costileva',
        comuna: 'Ulma'
      },
      {
        nume: 'Lupcina',
        comuna: 'Ulma'
      },
      {
        nume: 'Măgura',
        simplu: 'Magura',
        comuna: 'Ulma'
      },
      {
        nume: 'Nisipitu',
        comuna: 'Ulma'
      },
      {
        nume: 'Ciumuleşti',
        simplu: 'Ciumulesti',
        comuna: 'Vadu Moldovei'
      },
      {
        nume: 'Cămârzani',
        simplu: 'Camarzani',
        comuna: 'Vadu Moldovei'
      },
      {
        nume: 'Dumbrăviţa',
        simplu: 'Dumbravita',
        comuna: 'Vadu Moldovei'
      },
      {
        nume: 'Ioneasa',
        comuna: 'Vadu Moldovei'
      },
      {
        nume: 'Mesteceni',
        comuna: 'Vadu Moldovei'
      },
      {
        nume: 'Movileni',
        comuna: 'Vadu Moldovei'
      },
      {
        nume: 'Nigoteşti',
        simplu: 'Nigotesti',
        comuna: 'Vadu Moldovei'
      },
      {
        nume: 'Mironu',
        comuna: 'Valea Moldovei'
      },
      {
        nume: 'Molid',
        comuna: 'Vama'
      },
      {
        nume: 'Prisaca Dornei',
        comuna: 'Vama'
      },
      {
        nume: 'Strâmtura',
        simplu: 'Stramtura',
        comuna: 'Vama'
      },
      {
        nume: 'Argestru',
        comuna: 'Vatra Dornei'
      },
      {
        nume: 'Roşu',
        simplu: 'Rosu',
        comuna: 'Vatra Dornei'
      },
      {
        nume: 'Todireni',
        comuna: 'Vatra Dornei'
      },
      {
        nume: 'Ciumârna',
        simplu: 'Ciumarna',
        comuna: 'Vatra Moldovitei'
      },
      {
        nume: 'Paltinu',
        comuna: 'Vatra Moldovitei'
      },
      {
        nume: 'Bursuceni',
        comuna: 'Veresti'
      },
      {
        nume: 'Corocăieşti',
        simplu: 'Corocaiesti',
        comuna: 'Veresti'
      },
      {
        nume: 'Hancea',
        comuna: 'Veresti'
      },
      {
        nume: 'Bivolăria',
        simplu: 'Bivolaria',
        comuna: 'Vicovu De Sus'
      },
      {
        nume: 'Burla',
        comuna: 'Volovat'
      },
      {
        nume: 'Giurgeşti',
        simplu: 'Giurgesti',
        comuna: 'Vulturesti'
      },
      {
        nume: 'Hreaţca',
        simplu: 'Hreatca',
        comuna: 'Vulturesti'
      },
      {
        nume: 'Jacota',
        comuna: 'Vulturesti'
      },
      {
        nume: 'Mereşti',
        simplu: 'Meresti',
        comuna: 'Vulturesti'
      },
      {
        nume: 'Osoi',
        comuna: 'Vulturesti'
      },
      {
        nume: 'Pleşeşti',
        simplu: 'Plesesti',
        comuna: 'Vulturesti'
      },
      {
        nume: 'Valea Glodului',
        comuna: 'Vulturesti'
      },
      {
        nume: 'Badragi',
        comuna: 'Zamostea'
      },
      {
        nume: 'Ciomârtan',
        simplu: 'Ciomartan',
        comuna: 'Zamostea'
      },
      {
        nume: 'Cojocăreni',
        simplu: 'Cojocareni',
        comuna: 'Zamostea'
      },
      {
        nume: 'Corpaci',
        comuna: 'Zamostea'
      },
      {
        nume: 'Lunca',
        comuna: 'Zamostea'
      },
      {
        nume: 'Nicani',
        comuna: 'Zamostea'
      },
      {
        nume: 'Răuţeni',
        simplu: 'Rauteni',
        comuna: 'Zamostea'
      },
      {
        nume: 'Tăuteşti',
        simplu: 'Tautesti',
        comuna: 'Zamostea'
      },
      {
        nume: 'Buda',
        comuna: 'Zvoristea'
      },
      {
        nume: 'Dealu',
        comuna: 'Zvoristea'
      },
      {
        nume: 'Poiana',
        comuna: 'Zvoristea'
      },
      {
        nume: 'Slobozia',
        comuna: 'Zvoristea'
      },
      {
        nume: 'Stânca',
        simplu: 'Stanca',
        comuna: 'Zvoristea'
      },
      {
        nume: 'Stâncuţa',
        simplu: 'Stancuta',
        comuna: 'Zvoristea'
      },
      {
        nume: 'Serbăneşti',
        simplu: 'Serbanesti',
        comuna: 'Zvoristea'
      }
    ]
  },
  {
    auto: 'TR',
    nume: 'Teleorman',
    localitati: [
      {
        nume: 'Alexandria'
      },
      {
        nume: 'Balaci'
      },
      {
        nume: 'Blejeşti',
        simplu: 'Blejesti'
      },
      {
        nume: 'Bogdana'
      },
      {
        nume: 'Botoroaga'
      },
      {
        nume: 'Bragadiru'
      },
      {
        nume: 'Brânceni',
        simplu: 'Branceni'
      },
      {
        nume: 'Bujoreni'
      },
      {
        nume: 'Bujoru'
      },
      {
        nume: 'Buzescu'
      },
      {
        nume: 'Băbăiţa',
        simplu: 'Babaita'
      },
      {
        nume: 'Cervenia'
      },
      {
        nume: 'Ciuperceni'
      },
      {
        nume: 'Conţeşti',
        simplu: 'Contesti'
      },
      {
        nume: 'Cosmeşti',
        simplu: 'Cosmesti'
      },
      {
        nume: 'Crevenicu'
      },
      {
        nume: 'Crângeni',
        simplu: 'Crangeni'
      },
      {
        nume: 'Crângu',
        simplu: 'Crangu'
      },
      {
        nume: 'Călineşti',
        simplu: 'Calinesti'
      },
      {
        nume: 'Călmăţuiu',
        simplu: 'Calmatuiu'
      },
      {
        nume: 'Călmăţuiu de Sus',
        simplu: 'Calmatuiu de Sus'
      },
      {
        nume: 'Dideşti',
        simplu: 'Didesti'
      },
      {
        nume: 'Dobroteşti',
        simplu: 'Dobrotesti'
      },
      {
        nume: 'Drăcşenei',
        simplu: 'Dracsenei'
      },
      {
        nume: 'Drăgăneşti de Vede',
        simplu: 'Draganesti de Vede'
      },
      {
        nume: 'Drăgăneşti-Vlaşca',
        simplu: 'Draganesti-Vlasca'
      },
      {
        nume: 'Frumoasa'
      },
      {
        nume: 'Furculeşti',
        simplu: 'Furculesti'
      },
      {
        nume: 'Gratia'
      },
      {
        nume: 'Gălăteni',
        simplu: 'Galateni'
      },
      {
        nume: 'Islaz'
      },
      {
        nume: 'Izvoarele'
      },
      {
        nume: 'Lisa'
      },
      {
        nume: 'Liţa',
        simplu: 'Lita'
      },
      {
        nume: 'Lunca'
      },
      {
        nume: 'Mavrodin'
      },
      {
        nume: 'Moşteni',
        simplu: 'Mosteni'
      },
      {
        nume: 'Mârzăneşti',
        simplu: 'Marzanesti'
      },
      {
        nume: 'Măgura',
        simplu: 'Magura'
      },
      {
        nume: 'Măldăeni',
        simplu: 'Maldaeni'
      },
      {
        nume: 'Nanov'
      },
      {
        nume: 'Necşeşti',
        simplu: 'Necsesti'
      },
      {
        nume: 'Nenciuleşti',
        simplu: 'Nenciulesti'
      },
      {
        nume: 'Năsturelu',
        simplu: 'Nasturelu'
      },
      {
        nume: 'Olteni'
      },
      {
        nume: 'Peretu'
      },
      {
        nume: 'Piatra'
      },
      {
        nume: 'Pietroşani',
        simplu: 'Pietrosani'
      },
      {
        nume: 'Plopii-Slăviteşti',
        simplu: 'Plopii-Slavitesti'
      },
      {
        nume: 'Plosca'
      },
      {
        nume: 'Poeni'
      },
      {
        nume: 'Poroschia'
      },
      {
        nume: 'Putineiu'
      },
      {
        nume: 'Rosiori de Vede'
      },
      {
        nume: 'Răsmireşti',
        simplu: 'Rasmiresti'
      },
      {
        nume: 'Salcia'
      },
      {
        nume: 'Scrioaştea',
        simplu: 'Scrioastea'
      },
      {
        nume: 'Scurtu Mare'
      },
      {
        nume: 'Seaca'
      },
      {
        nume: 'Segarcea-Vale'
      },
      {
        nume: 'Sfinţeşti',
        simplu: 'Sfintesti'
      },
      {
        nume: 'Siliştea',
        simplu: 'Silistea'
      },
      {
        nume: 'Siliştea-Gumeşti',
        simplu: 'Silistea-Gumesti'
      },
      {
        nume: 'Slobozia Mândra',
        simplu: 'Slobozia Mandra'
      },
      {
        nume: 'Smârdioasa',
        simplu: 'Smardioasa'
      },
      {
        nume: 'Stejaru'
      },
      {
        nume: 'Suhaia'
      },
      {
        nume: 'Sârbeni',
        simplu: 'Sarbeni'
      },
      {
        nume: 'Săceni',
        simplu: 'Saceni'
      },
      {
        nume: 'Traian'
      },
      {
        nume: 'Trivalea-Moşteni',
        simplu: 'Trivalea-Mosteni'
      },
      {
        nume: 'Troianul'
      },
      {
        nume: 'Turnu Măgurele',
        simplu: 'Turnu Magurele'
      },
      {
        nume: 'Tătărăştii de Jos',
        simplu: 'Tatarastii de Jos'
      },
      {
        nume: 'Tătărăştii de Sus',
        simplu: 'Tatarastii de Sus'
      },
      {
        nume: 'Vedea'
      },
      {
        nume: 'Videle'
      },
      {
        nume: 'Viişoara',
        simplu: 'Viisoara'
      },
      {
        nume: 'Vitanesti'
      },
      {
        nume: 'Zimnicea'
      },
      {
        nume: 'Zâmbreasca',
        simplu: 'Zambreasca'
      },
      {
        nume: 'Storobăneasa',
        simplu: 'Storobaneasa'
      },
      {
        nume: 'Tigăneşti',
        simplu: 'Tiganesti'
      },
      {
        nume: 'Clăniţa',
        simplu: 'Clanita',
        comuna: 'Babaita'
      },
      {
        nume: 'Frăsinet',
        simplu: 'Frasinet',
        comuna: 'Babaita'
      },
      {
        nume: 'Merişani',
        simplu: 'Merisani',
        comuna: 'Babaita'
      },
      {
        nume: 'Burdeni',
        comuna: 'Balaci'
      },
      {
        nume: 'Tecuci',
        comuna: 'Balaci'
      },
      {
        nume: 'Baciu',
        comuna: 'Blejesti'
      },
      {
        nume: 'Sericu',
        comuna: 'Blejesti'
      },
      {
        nume: 'Broşteanca',
        simplu: 'Brosteanca',
        comuna: 'Bogdana'
      },
      {
        nume: 'Ulmeni',
        comuna: 'Bogdana'
      },
      {
        nume: 'Urluiu',
        comuna: 'Bogdana'
      },
      {
        nume: 'Călugaru',
        simplu: 'Calugaru',
        comuna: 'Botoroaga'
      },
      {
        nume: 'Tunari',
        comuna: 'Botoroaga'
      },
      {
        nume: 'Târnava',
        simplu: 'Tarnava',
        comuna: 'Botoroaga'
      },
      {
        nume: 'Valea Cireşului',
        simplu: 'Valea Ciresului',
        comuna: 'Botoroaga'
      },
      {
        nume: 'Dărvaş',
        simplu: 'Darvas',
        comuna: 'Bujoreni'
      },
      {
        nume: 'Prunaru',
        comuna: 'Bujoreni'
      },
      {
        nume: 'Antoneşti',
        simplu: 'Antonesti',
        comuna: 'Calinesti'
      },
      {
        nume: 'Copăceanca',
        simplu: 'Copaceanca',
        comuna: 'Calinesti'
      },
      {
        nume: 'Licuriciu',
        comuna: 'Calinesti'
      },
      {
        nume: 'Mariţa',
        simplu: 'Marita',
        comuna: 'Calinesti'
      },
      {
        nume: 'Bujoru',
        comuna: 'Calmatuiu'
      },
      {
        nume: 'Caravaneţi',
        simplu: 'Caravaneti',
        comuna: 'Calmatuiu'
      },
      {
        nume: 'Nicolae Bălcescu',
        simplu: 'Nicolae Balcescu',
        comuna: 'Calmatuiu'
      },
      {
        nume: 'Băcăleşti',
        simplu: 'Bacalesti',
        comuna: 'Calmatuiu De Sus'
      },
      {
        nume: 'Ionaşcu',
        simplu: 'Ionascu',
        comuna: 'Calmatuiu De Sus'
      },
      {
        nume: 'Baldovineşti',
        simplu: 'Baldovinesti',
        comuna: 'Ciolanesti'
      },
      {
        nume: 'Ciolăneştii din Deal',
        simplu: 'Ciolanestii din Deal',
        comuna: 'Ciolanesti'
      },
      {
        nume: 'Ciolăneştii din Vale',
        simplu: 'Ciolanestii din Vale',
        comuna: 'Ciolanesti'
      },
      {
        nume: 'Poiana',
        comuna: 'Ciuperceni'
      },
      {
        nume: 'Ciuperceni',
        comuna: 'Cosmesti'
      },
      {
        nume: 'Balta Sărată',
        simplu: 'Balta Sarata',
        comuna: 'Crangeni'
      },
      {
        nume: 'Dorobanţu',
        simplu: 'Dorobantu',
        comuna: 'Crangeni'
      },
      {
        nume: 'Stejaru',
        comuna: 'Crangeni'
      },
      {
        nume: 'Dracea',
        comuna: 'Crangu'
      },
      {
        nume: 'Florica',
        comuna: 'Crangu'
      },
      {
        nume: 'Secara',
        comuna: 'Crangu'
      },
      {
        nume: 'Zlata',
        comuna: 'Crangu'
      },
      {
        nume: 'Răduleşti',
        simplu: 'Radulesti',
        comuna: 'Crevenicu'
      },
      {
        nume: 'Satu Nou',
        comuna: 'Didesti'
      },
      {
        nume: 'Însurăţei',
        simplu: 'Insuratei',
        comuna: 'Didesti'
      },
      {
        nume: 'Merişani',
        simplu: 'Merisani',
        comuna: 'Dobrotesti'
      },
      {
        nume: 'Beuca',
        comuna: 'Dracsenei'
      },
      {
        nume: 'Drăcşani',
        simplu: 'Dracsani',
        comuna: 'Dracsenei'
      },
      {
        nume: 'Odobeasca',
        comuna: 'Dracsenei'
      },
      {
        nume: 'Plopi',
        comuna: 'Dracsenei'
      },
      {
        nume: 'Satul Vechi',
        comuna: 'Dracsenei'
      },
      {
        nume: 'Măgura cu Liliac',
        simplu: 'Magura cu Liliac',
        comuna: 'Draganesti De Vede'
      },
      {
        nume: 'Văcăreşti',
        simplu: 'Vacaresti',
        comuna: 'Draganesti De Vede'
      },
      {
        nume: 'Comoara',
        comuna: 'Draganesti-Vlasca'
      },
      {
        nume: 'Văceni',
        simplu: 'Vaceni',
        comuna: 'Draganesti-Vlasca'
      },
      {
        nume: 'Păuleasca',
        simplu: 'Pauleasca',
        comuna: 'Frumoasa'
      },
      {
        nume: 'Moşteni',
        simplu: 'Mosteni',
        comuna: 'Furculesti'
      },
      {
        nume: 'Spătărei',
        simplu: 'Spatarei',
        comuna: 'Furculesti'
      },
      {
        nume: 'Voievoda',
        comuna: 'Furculesti'
      },
      {
        nume: 'Bâscoveni',
        simplu: 'Bascoveni',
        comuna: 'Galateni'
      },
      {
        nume: 'Grădişteanca',
        simplu: 'Gradisteanca',
        comuna: 'Galateni'
      },
      {
        nume: 'Ciurari-Deal',
        comuna: 'Gratia'
      },
      {
        nume: 'Drăghineşti',
        simplu: 'Draghinesti',
        comuna: 'Gratia'
      },
      {
        nume: 'Moldoveni',
        comuna: 'Islaz'
      },
      {
        nume: 'Vânători',
        simplu: 'Vanatori',
        comuna: 'Lisa'
      },
      {
        nume: 'Pleaşov',
        simplu: 'Pleasov',
        comuna: 'Lunca'
      },
      {
        nume: 'Prundu',
        comuna: 'Lunca'
      },
      {
        nume: 'Saelele',
        comuna: 'Lunca'
      },
      {
        nume: 'Guruieni',
        comuna: 'Magura'
      },
      {
        nume: 'Cernetu',
        comuna: 'Marzanesti'
      },
      {
        nume: 'Teleormanu',
        comuna: 'Marzanesti'
      },
      {
        nume: 'Valea Părului',
        simplu: 'Valea Parului',
        comuna: 'Marzanesti'
      },
      {
        nume: 'Merenii de Jos',
        comuna: 'Mereni'
      },
      {
        nume: 'Merenii de Sus',
        comuna: 'Mereni'
      },
      {
        nume: 'Stefeni',
        simplu: 'Stefeni',
        comuna: 'Mereni'
      },
      {
        nume: 'Zimnicele',
        comuna: 'Nasturelu'
      },
      {
        nume: 'Belciug',
        comuna: 'Necsesti'
      },
      {
        nume: 'Gârdeşti',
        simplu: 'Gardesti',
        comuna: 'Necsesti'
      },
      {
        nume: 'Păru Rotund',
        simplu: 'Paru Rotund',
        comuna: 'Nenciulesti'
      },
      {
        nume: 'Perii Broşteni',
        simplu: 'Perii Brosteni',
        comuna: 'Olteni'
      },
      {
        nume: 'Lăceni',
        simplu: 'Laceni',
        comuna: 'Orbeasca'
      },
      {
        nume: 'Orbeasca de Jos',
        comuna: 'Orbeasca'
      },
      {
        nume: 'Orbeasca de Sus',
        comuna: 'Orbeasca'
      },
      {
        nume: 'Beciu',
        comuna: 'Plopii-Slavitesti'
      },
      {
        nume: 'Brâncoveanca',
        simplu: 'Brancoveanca',
        comuna: 'Plopii-Slavitesti'
      },
      {
        nume: 'Bârseştii de Jos',
        simplu: 'Barsestii de Jos',
        comuna: 'Plopii-Slavitesti'
      },
      {
        nume: 'Dudu',
        comuna: 'Plopii-Slavitesti'
      },
      {
        nume: 'Smârdan',
        simplu: 'Smardan',
        comuna: 'Plopii-Slavitesti'
      },
      {
        nume: 'Banov',
        comuna: 'Poeni'
      },
      {
        nume: 'Brăteşti',
        simplu: 'Bratesti',
        comuna: 'Poeni'
      },
      {
        nume: 'Cătunu',
        simplu: 'Catunu',
        comuna: 'Poeni'
      },
      {
        nume: 'Preajba',
        comuna: 'Poeni'
      },
      {
        nume: 'Vătaşi',
        simplu: 'Vatasi',
        comuna: 'Poeni'
      },
      {
        nume: 'Tăvârlău',
        simplu: 'Tavarlau',
        comuna: 'Poeni'
      },
      {
        nume: 'Calomfireşti',
        simplu: 'Calomfiresti',
        comuna: 'Poroschia'
      },
      {
        nume: 'Băduleasa',
        simplu: 'Baduleasa',
        comuna: 'Putineiu'
      },
      {
        nume: 'Cârlomanu',
        simplu: 'Carlomanu',
        comuna: 'Putineiu'
      },
      {
        nume: 'Cetatea',
        comuna: 'Radoiesti'
      },
      {
        nume: 'Rădoieşti-Deal',
        simplu: 'Radoiesti-Deal',
        comuna: 'Radoiesti'
      },
      {
        nume: 'Rădoieşti-Vale',
        simplu: 'Radoiesti-Vale',
        comuna: 'Radoiesti'
      },
      {
        nume: 'Ludăneasca',
        simplu: 'Ludaneasca',
        comuna: 'Rasmiresti'
      },
      {
        nume: 'Butculeşti',
        simplu: 'Butculesti',
        comuna: 'Saceni'
      },
      {
        nume: 'Ciurari',
        comuna: 'Saceni'
      },
      {
        nume: 'Băneasa',
        simplu: 'Baneasa',
        comuna: 'Salcia'
      },
      {
        nume: 'Tudor Vladimirescu',
        comuna: 'Salcia'
      },
      {
        nume: 'Sârbenii de Jos',
        simplu: 'Sarbenii de Jos',
        comuna: 'Sarbeni'
      },
      {
        nume: 'Udeni',
        comuna: 'Sarbeni'
      },
      {
        nume: 'Brebina',
        comuna: 'Scrioastea'
      },
      {
        nume: 'Cucueţi',
        simplu: 'Cucueti',
        comuna: 'Scrioastea'
      },
      {
        nume: 'Viile',
        comuna: 'Scrioastea'
      },
      {
        nume: 'Albeni',
        comuna: 'Scurtu Mare'
      },
      {
        nume: 'Drăceşti',
        simplu: 'Dracesti',
        comuna: 'Scurtu Mare'
      },
      {
        nume: 'Negrileşti',
        simplu: 'Negrilesti',
        comuna: 'Scurtu Mare'
      },
      {
        nume: 'Scurtu-Slăveşti',
        simplu: 'Scurtu-Slavesti',
        comuna: 'Scurtu Mare'
      },
      {
        nume: 'Valea Poştei',
        simplu: 'Valea Postei',
        comuna: 'Scurtu Mare'
      },
      {
        nume: 'Năvodari',
        simplu: 'Navodari',
        comuna: 'Seaca'
      },
      {
        nume: 'Olteanca',
        comuna: 'Segarcea-Vale'
      },
      {
        nume: 'Segarcea-Deal',
        comuna: 'Segarcea-Vale'
      },
      {
        nume: 'Buteşti',
        simplu: 'Butesti',
        comuna: 'Silistea'
      },
      {
        nume: 'Purani',
        comuna: 'Silistea'
      },
      {
        nume: 'Puranii de Sus',
        comuna: 'Silistea'
      },
      {
        nume: 'Siliştea Mică',
        simplu: 'Silistea Mica',
        comuna: 'Silistea'
      },
      {
        nume: 'Uda-Clocociov',
        comuna: 'Slobozia Mandra'
      },
      {
        nume: 'Uda-Paciurea',
        comuna: 'Slobozia Mandra'
      },
      {
        nume: 'Soimu',
        simplu: 'Soimu',
        comuna: 'Smardioasa'
      },
      {
        nume: 'Bratcovu',
        comuna: 'Stejaru'
      },
      {
        nume: 'Gresia',
        comuna: 'Stejaru'
      },
      {
        nume: 'Socetu',
        comuna: 'Stejaru'
      },
      {
        nume: 'Beiu',
        comuna: 'Storobaneasa'
      },
      {
        nume: 'Fântânele',
        simplu: 'Fantanele',
        comuna: 'Suhaia'
      },
      {
        nume: 'Linia Costii',
        comuna: 'Talpa'
      },
      {
        nume: 'Rotăreşti',
        simplu: 'Rotaresti',
        comuna: 'Talpa'
      },
      {
        nume: 'Talpa Poştei',
        simplu: 'Talpa Postei',
        comuna: 'Talpa'
      },
      {
        nume: 'Talpa-Bâscoveni',
        simplu: 'Talpa-Bascoveni',
        comuna: 'Talpa'
      },
      {
        nume: 'Talpa-Ogrăzile',
        simplu: 'Talpa-Ograzile',
        comuna: 'Talpa'
      },
      {
        nume: 'Lada',
        comuna: 'Tatarastii De Jos'
      },
      {
        nume: 'Negreni',
        comuna: 'Tatarastii De Jos'
      },
      {
        nume: 'Negrenii de Sus',
        comuna: 'Tatarastii De Jos'
      },
      {
        nume: 'Negrenii-Osebiţi',
        simplu: 'Negrenii-Osebiti',
        comuna: 'Tatarastii De Jos'
      },
      {
        nume: 'Obârtu',
        simplu: 'Obartu',
        comuna: 'Tatarastii De Jos'
      },
      {
        nume: 'Slăveşti',
        simplu: 'Slavesti',
        comuna: 'Tatarastii De Jos'
      },
      {
        nume: 'Dobreni',
        comuna: 'Tatarastii De Sus'
      },
      {
        nume: 'Udupu',
        comuna: 'Tatarastii De Sus'
      },
      {
        nume: 'Brătăşani',
        simplu: 'Bratasani',
        comuna: 'Trivalea-Mosteni'
      },
      {
        nume: 'Deparaţi',
        simplu: 'Deparati',
        comuna: 'Trivalea-Mosteni'
      },
      {
        nume: 'Dulceni',
        comuna: 'Troianul'
      },
      {
        nume: 'Vatra',
        comuna: 'Troianul'
      },
      {
        nume: 'Gărăgău',
        simplu: 'Garagau',
        comuna: 'Vartoape'
      },
      {
        nume: 'Vârtoapele de Jos',
        simplu: 'Vartoapele de Jos',
        comuna: 'Vartoape'
      },
      {
        nume: 'Vârtoapele de Sus',
        simplu: 'Vartoapele de Sus',
        comuna: 'Vartoape'
      },
      {
        nume: 'Albeşti',
        simplu: 'Albesti',
        comuna: 'Vedea'
      },
      {
        nume: 'Coşoteni',
        simplu: 'Cosoteni',
        comuna: 'Vedea'
      },
      {
        nume: 'Dulceanca',
        comuna: 'Vedea'
      },
      {
        nume: 'Meri',
        comuna: 'Vedea'
      },
      {
        nume: 'Coşoaia',
        simplu: 'Cosoaia',
        comuna: 'Videle'
      },
      {
        nume: 'Purani',
        comuna: 'Vitanesti'
      },
      {
        nume: 'Schitu Poienari',
        comuna: 'Vitanesti'
      },
      {
        nume: 'Siliştea',
        simplu: 'Silistea',
        comuna: 'Vitanesti'
      }
    ]
  },
  {
    auto: 'TM',
    nume: 'Timiş',
    localitati: [
      {
        nume: 'Balinţ',
        simplu: 'Balint'
      },
      {
        nume: 'Banloc'
      },
      {
        nume: 'Bara'
      },
      {
        nume: 'Beba Veche'
      },
      {
        nume: 'Becicherecu Mic'
      },
      {
        nume: 'Belinţ',
        simplu: 'Belint'
      },
      {
        nume: 'Bethausen'
      },
      {
        nume: 'Biled'
      },
      {
        nume: 'Bogda'
      },
      {
        nume: 'Boldur'
      },
      {
        nume: 'Brestovăţ',
        simplu: 'Brestovat'
      },
      {
        nume: 'Buziaş',
        simplu: 'Buzias'
      },
      {
        nume: 'Bârna',
        simplu: 'Barna'
      },
      {
        nume: 'Cenad'
      },
      {
        nume: 'Cenei'
      },
      {
        nume: 'Chevereşu Mare',
        simplu: 'Cheveresu Mare'
      },
      {
        nume: 'Ciacova'
      },
      {
        nume: 'Comloşu Mare',
        simplu: 'Comlosu Mare'
      },
      {
        nume: 'Coşteiu',
        simplu: 'Costeiu'
      },
      {
        nume: 'Criciova'
      },
      {
        nume: 'Curtea'
      },
      {
        nume: 'Cărpiniş',
        simplu: 'Carpinis'
      },
      {
        nume: 'Darova'
      },
      {
        nume: 'Denta'
      },
      {
        nume: 'Deta'
      },
      {
        nume: 'Dudeştii Vechi',
        simplu: 'Dudestii Vechi'
      },
      {
        nume: 'Dumbrava'
      },
      {
        nume: 'Dumbrăviţa',
        simplu: 'Dumbravita'
      },
      {
        nume: 'Foeni'
      },
      {
        nume: 'Fârdea',
        simplu: 'Fardea'
      },
      {
        nume: 'Făget',
        simplu: 'Faget'
      },
      {
        nume: 'Gavojdia'
      },
      {
        nume: 'Ghiroda'
      },
      {
        nume: 'Ghizela'
      },
      {
        nume: 'Giarmata'
      },
      {
        nume: 'Giera'
      },
      {
        nume: 'Giroc'
      },
      {
        nume: 'Giulvăz',
        simplu: 'Giulvaz'
      },
      {
        nume: 'Gătaia',
        simplu: 'Gataia'
      },
      {
        nume: 'Jamu Mare'
      },
      {
        nume: 'Jebel'
      },
      {
        nume: 'Jimbolia'
      },
      {
        nume: 'Lenauheim'
      },
      {
        nume: 'Liebling'
      },
      {
        nume: 'Lovrin'
      },
      {
        nume: 'Lugoj'
      },
      {
        nume: 'Margina'
      },
      {
        nume: 'Maşloc',
        simplu: 'Masloc'
      },
      {
        nume: 'Moraviţa',
        simplu: 'Moravita'
      },
      {
        nume: 'Moşniţa Nouă',
        simplu: 'Mosnita Noua'
      },
      {
        nume: 'Mănăştiur',
        simplu: 'Manastiur'
      },
      {
        nume: 'Niţchidorf',
        simplu: 'Nitchidorf'
      },
      {
        nume: 'Nădrag',
        simplu: 'Nadrag'
      },
      {
        nume: 'Ohaba Lungă',
        simplu: 'Ohaba Lunga'
      },
      {
        nume: 'Orţişoara',
        simplu: 'Ortisoara'
      },
      {
        nume: 'Peciu Nou'
      },
      {
        nume: 'Periam'
      },
      {
        nume: 'Pietroasa'
      },
      {
        nume: 'Pişchia',
        simplu: 'Pischia'
      },
      {
        nume: 'Racoviţa',
        simplu: 'Racovita'
      },
      {
        nume: 'Recaş',
        simplu: 'Recas'
      },
      {
        nume: 'Remetea Mare'
      },
      {
        nume: 'Sacoşu Turcesc',
        simplu: 'Sacosu Turcesc'
      },
      {
        nume: 'Satchinez'
      },
      {
        nume: 'Secaş',
        simplu: 'Secas'
      },
      {
        nume: 'Sânandrei',
        simplu: 'Sanandrei'
      },
      {
        nume: 'Sânmihaiu Român',
        simplu: 'Sanmihaiu Roman'
      },
      {
        nume: 'Sânnicolau Mare',
        simplu: 'Sannicolau Mare'
      },
      {
        nume: 'Sânpetru Mare',
        simplu: 'Sanpetru Mare'
      },
      {
        nume: 'Săcălaz',
        simplu: 'Sacalaz'
      },
      {
        nume: 'Teremia Mare'
      },
      {
        nume: 'Timişoara',
        simplu: 'Timisoara'
      },
      {
        nume: 'Tomeşti',
        simplu: 'Tomesti'
      },
      {
        nume: 'Topolovăţu Mare',
        simplu: 'Topolovatu Mare'
      },
      {
        nume: 'Tormac'
      },
      {
        nume: 'Traian Vuia'
      },
      {
        nume: 'Uivar'
      },
      {
        nume: 'Variaş',
        simplu: 'Varias'
      },
      {
        nume: 'Victor Vlad Delamarina'
      },
      {
        nume: 'Voiteg'
      },
      {
        nume: 'Sag',
        simplu: 'Sag'
      },
      {
        nume: 'Stiuca',
        simplu: 'Stiuca'
      },
      {
        nume: 'Bodo',
        comuna: 'Balint'
      },
      {
        nume: 'Fădimac',
        simplu: 'Fadimac',
        comuna: 'Balint'
      },
      {
        nume: 'Târgovişte',
        simplu: 'Targoviste',
        comuna: 'Balint'
      },
      {
        nume: 'Dolaţ',
        simplu: 'Dolat',
        comuna: 'Banloc'
      },
      {
        nume: 'Livezile',
        comuna: 'Banloc'
      },
      {
        nume: 'Ofseniţa',
        simplu: 'Ofsenita',
        comuna: 'Banloc'
      },
      {
        nume: 'Partoş',
        simplu: 'Partos',
        comuna: 'Banloc'
      },
      {
        nume: 'Soca',
        comuna: 'Banloc'
      },
      {
        nume: 'Dobreşti',
        simplu: 'Dobresti',
        comuna: 'Bara'
      },
      {
        nume: 'Lăpuşnic',
        simplu: 'Lapusnic',
        comuna: 'Bara'
      },
      {
        nume: 'Rădmăneşti',
        simplu: 'Radmanesti',
        comuna: 'Bara'
      },
      {
        nume: 'Spata',
        comuna: 'Bara'
      },
      {
        nume: 'Boteşti',
        simplu: 'Botesti',
        comuna: 'Barna'
      },
      {
        nume: 'Botineşti',
        simplu: 'Botinesti',
        comuna: 'Barna'
      },
      {
        nume: 'Drinova',
        comuna: 'Barna'
      },
      {
        nume: 'Jureşti',
        simplu: 'Juresti',
        comuna: 'Barna'
      },
      {
        nume: 'Pogăneşti',
        simplu: 'Poganesti',
        comuna: 'Barna'
      },
      {
        nume: 'Sărăzani',
        simplu: 'Sarazani',
        comuna: 'Barna'
      },
      {
        nume: 'Cherestur',
        comuna: 'Beba Veche'
      },
      {
        nume: 'Pordeanu',
        comuna: 'Beba Veche'
      },
      {
        nume: 'Dudeştii Noi',
        simplu: 'Dudestii Noi',
        comuna: 'Becicherecu Mic'
      },
      {
        nume: 'Babşa',
        simplu: 'Babsa',
        comuna: 'Belint'
      },
      {
        nume: 'Chizătău',
        simplu: 'Chizatau',
        comuna: 'Belint'
      },
      {
        nume: 'Gruni',
        comuna: 'Belint'
      },
      {
        nume: 'Cladova',
        comuna: 'Bethausen'
      },
      {
        nume: 'Cliciova',
        comuna: 'Bethausen'
      },
      {
        nume: 'Cutina',
        comuna: 'Bethausen'
      },
      {
        nume: 'Leucuşeşti',
        simplu: 'Leucusesti',
        comuna: 'Bethausen'
      },
      {
        nume: 'Nevrincea',
        comuna: 'Bethausen'
      },
      {
        nume: 'Uihei',
        comuna: 'Biled'
      },
      {
        nume: 'Sandra',
        simplu: 'Sandra',
        comuna: 'Biled'
      },
      {
        nume: 'Altringen',
        comuna: 'Bogda'
      },
      {
        nume: 'Buzad',
        comuna: 'Bogda'
      },
      {
        nume: 'Charlotenburg',
        comuna: 'Bogda'
      },
      {
        nume: 'Comeat',
        comuna: 'Bogda'
      },
      {
        nume: 'Sintar',
        comuna: 'Bogda'
      },
      {
        nume: 'Jabăr',
        simplu: 'Jabar',
        comuna: 'Boldur'
      },
      {
        nume: 'Ohaba-Forgaci',
        comuna: 'Boldur'
      },
      {
        nume: 'Sinersig',
        comuna: 'Boldur'
      },
      {
        nume: 'Coşarii',
        simplu: 'Cosarii',
        comuna: 'Brestovat'
      },
      {
        nume: 'Hodoş',
        simplu: 'Hodos',
        comuna: 'Brestovat'
      },
      {
        nume: 'Lucareţ',
        simplu: 'Lucaret',
        comuna: 'Brestovat'
      },
      {
        nume: 'Teş',
        simplu: 'Tes',
        comuna: 'Brestovat'
      },
      {
        nume: 'Bacova',
        comuna: 'Buzias'
      },
      {
        nume: 'Silagiu',
        comuna: 'Buzias'
      },
      {
        nume: 'Iecea Mare',
        comuna: 'Carpinis'
      },
      {
        nume: 'Iecea Mică',
        simplu: 'Iecea Mica',
        comuna: 'Carpinis'
      },
      {
        nume: 'Bobda',
        comuna: 'Cenei'
      },
      {
        nume: 'Checea',
        comuna: 'Cenei'
      },
      {
        nume: 'Dragşina',
        simplu: 'Dragsina',
        comuna: 'Cheveresu Mare'
      },
      {
        nume: 'Vucova',
        comuna: 'Cheveresu Mare'
      },
      {
        nume: 'Cebza',
        comuna: 'Ciacova'
      },
      {
        nume: 'Gad',
        comuna: 'Ciacova'
      },
      {
        nume: 'Ghilad',
        comuna: 'Ciacova'
      },
      {
        nume: 'Macedonia',
        comuna: 'Ciacova'
      },
      {
        nume: 'Obad',
        comuna: 'Ciacova'
      },
      {
        nume: 'Petroman',
        comuna: 'Ciacova'
      },
      {
        nume: 'Comloşu Mic',
        simplu: 'Comlosu Mic',
        comuna: 'Comlosu Mare'
      },
      {
        nume: 'Lunga',
        comuna: 'Comlosu Mare'
      },
      {
        nume: 'Hezeriş',
        simplu: 'Hezeris',
        comuna: 'Costeiu'
      },
      {
        nume: 'Păru',
        simplu: 'Paru',
        comuna: 'Costeiu'
      },
      {
        nume: 'Valea Lungă Română',
        simplu: 'Valea Lunga Romana',
        comuna: 'Costeiu'
      },
      {
        nume: 'Tipari',
        simplu: 'Tipari',
        comuna: 'Costeiu'
      },
      {
        nume: 'Cireşu',
        simplu: 'Ciresu',
        comuna: 'Criciova'
      },
      {
        nume: 'Cireşu Mic',
        simplu: 'Ciresu Mic',
        comuna: 'Criciova'
      },
      {
        nume: 'Jdioara',
        comuna: 'Criciova'
      },
      {
        nume: 'Coşava',
        simplu: 'Cosava',
        comuna: 'Curtea'
      },
      {
        nume: 'Homojdia',
        comuna: 'Curtea'
      },
      {
        nume: 'Hodoş',
        simplu: 'Hodos',
        comuna: 'Darova'
      },
      {
        nume: 'Sacoşu Mare',
        simplu: 'Sacosu Mare',
        comuna: 'Darova'
      },
      {
        nume: 'Breştea',
        simplu: 'Brestea',
        comuna: 'Denta'
      },
      {
        nume: 'Roviniţa Mare',
        simplu: 'Rovinita Mare',
        comuna: 'Denta'
      },
      {
        nume: 'Roviniţa Mică',
        simplu: 'Rovinita Mica',
        comuna: 'Denta'
      },
      {
        nume: 'Opatiţa',
        simplu: 'Opatita',
        comuna: 'Deta'
      },
      {
        nume: 'Cheglevici',
        comuna: 'Dudestii Vechi'
      },
      {
        nume: 'Colonia Bulgară',
        simplu: 'Colonia Bulgara',
        comuna: 'Dudestii Vechi'
      },
      {
        nume: 'Vălcani',
        simplu: 'Valcani',
        comuna: 'Dudestii Vechi'
      },
      {
        nume: 'Bucovăţ',
        simplu: 'Bucovat',
        comuna: 'Dumbrava'
      },
      {
        nume: 'Răchita',
        simplu: 'Rachita',
        comuna: 'Dumbrava'
      },
      {
        nume: 'Begheiu Mic',
        comuna: 'Faget'
      },
      {
        nume: 'Bichigi',
        comuna: 'Faget'
      },
      {
        nume: 'Brăneşti',
        simplu: 'Branesti',
        comuna: 'Faget'
      },
      {
        nume: 'Bunea Mare',
        comuna: 'Faget'
      },
      {
        nume: 'Bunea Mică',
        simplu: 'Bunea Mica',
        comuna: 'Faget'
      },
      {
        nume: 'Băteşti',
        simplu: 'Batesti',
        comuna: 'Faget'
      },
      {
        nume: 'Colonia Mică',
        simplu: 'Colonia Mica',
        comuna: 'Faget'
      },
      {
        nume: 'Jupâneşti',
        simplu: 'Jupanesti',
        comuna: 'Faget'
      },
      {
        nume: 'Povârgina',
        simplu: 'Povargina',
        comuna: 'Faget'
      },
      {
        nume: 'Temereşti',
        simplu: 'Temeresti',
        comuna: 'Faget'
      },
      {
        nume: 'Drăgsineşti',
        simplu: 'Dragsinesti',
        comuna: 'Fardea'
      },
      {
        nume: 'Gladna Montană',
        simplu: 'Gladna Montana',
        comuna: 'Fardea'
      },
      {
        nume: 'Gladna Româna',
        simplu: 'Gladna Romana',
        comuna: 'Fardea'
      },
      {
        nume: 'Hăuzeşti',
        simplu: 'Hauzesti',
        comuna: 'Fardea'
      },
      {
        nume: 'Mâtnicu Mic',
        simplu: 'Matnicu Mic',
        comuna: 'Fardea'
      },
      {
        nume: 'Zolt',
        comuna: 'Fardea'
      },
      {
        nume: 'Cruceni',
        comuna: 'Foeni'
      },
      {
        nume: 'Berecuţa',
        simplu: 'Berecuta',
        comuna: 'Gataia'
      },
      {
        nume: 'Birda',
        comuna: 'Gataia'
      },
      {
        nume: 'Butin',
        comuna: 'Gataia'
      },
      {
        nume: 'Mânăstire',
        simplu: 'Manastire',
        comuna: 'Gataia'
      },
      {
        nume: 'Percosova',
        comuna: 'Gataia'
      },
      {
        nume: 'Sculia',
        comuna: 'Gataia'
      },
      {
        nume: 'Sângeorge',
        simplu: 'Sangeorge',
        comuna: 'Gataia'
      },
      {
        nume: 'Semlacu Mare',
        simplu: 'Semlacu Mare',
        comuna: 'Gataia'
      },
      {
        nume: 'Semlacu Mic',
        simplu: 'Semlacu Mic',
        comuna: 'Gataia'
      },
      {
        nume: 'Jena',
        comuna: 'Gavojdia'
      },
      {
        nume: 'Lugojel',
        comuna: 'Gavojdia'
      },
      {
        nume: 'Sălbăgel',
        simplu: 'Salbagel',
        comuna: 'Gavojdia'
      },
      {
        nume: 'Giarmata-Vii',
        comuna: 'Ghiroda'
      },
      {
        nume: 'Hisiaş',
        simplu: 'Hisias',
        comuna: 'Ghizela'
      },
      {
        nume: 'Paniova',
        comuna: 'Ghizela'
      },
      {
        nume: 'Sanoviţa',
        simplu: 'Sanovita',
        comuna: 'Ghizela'
      },
      {
        nume: 'Cerneteaz',
        comuna: 'Giarmata'
      },
      {
        nume: 'Grănicerii',
        simplu: 'Granicerii',
        comuna: 'Giera'
      },
      {
        nume: 'Toager',
        comuna: 'Giera'
      },
      {
        nume: 'Chişoda',
        simplu: 'Chisoda',
        comuna: 'Giroc'
      },
      {
        nume: 'Crai Nou',
        comuna: 'Giulvaz'
      },
      {
        nume: 'Ivanda',
        comuna: 'Giulvaz'
      },
      {
        nume: 'Rudna',
        comuna: 'Giulvaz'
      },
      {
        nume: 'Clopodia',
        comuna: 'Jamu Mare'
      },
      {
        nume: 'Ferendia',
        comuna: 'Jamu Mare'
      },
      {
        nume: 'Gherman',
        comuna: 'Jamu Mare'
      },
      {
        nume: 'Lăţunaş',
        simplu: 'Latunas',
        comuna: 'Jamu Mare'
      },
      {
        nume: 'Pădureni',
        simplu: 'Padureni',
        comuna: 'Jebel'
      },
      {
        nume: 'Bulgăruş',
        simplu: 'Bulgarus',
        comuna: 'Lenauheim'
      },
      {
        nume: 'Grabăţ',
        simplu: 'Grabat',
        comuna: 'Lenauheim'
      },
      {
        nume: 'Cerna',
        comuna: 'Liebling'
      },
      {
        nume: 'Iosif',
        comuna: 'Liebling'
      },
      {
        nume: 'Gottlob',
        comuna: 'Lovrin'
      },
      {
        nume: 'Tomnatic',
        comuna: 'Lovrin'
      },
      {
        nume: 'Vizejdia',
        comuna: 'Lovrin'
      },
      {
        nume: 'Măguri',
        simplu: 'Maguri',
        comuna: 'Lugoj'
      },
      {
        nume: 'Tapia',
        comuna: 'Lugoj'
      },
      {
        nume: 'Pădurani',
        simplu: 'Padurani',
        comuna: 'Manastiur'
      },
      {
        nume: 'Remetea-Luncă',
        simplu: 'Remetea-Lunca',
        comuna: 'Manastiur'
      },
      {
        nume: 'Topla',
        comuna: 'Manastiur'
      },
      {
        nume: 'Breazova',
        comuna: 'Margina'
      },
      {
        nume: 'Bulza',
        comuna: 'Margina'
      },
      {
        nume: 'Coşeviţa',
        simplu: 'Cosevita',
        comuna: 'Margina'
      },
      {
        nume: 'Coşteiu de Sus',
        simplu: 'Costeiu de Sus',
        comuna: 'Margina'
      },
      {
        nume: 'Groşi',
        simplu: 'Grosi',
        comuna: 'Margina'
      },
      {
        nume: 'Nemeşeşti',
        simplu: 'Nemesesti',
        comuna: 'Margina'
      },
      {
        nume: 'Sinteşti',
        simplu: 'Sintesti',
        comuna: 'Margina'
      },
      {
        nume: 'Zorani',
        comuna: 'Margina'
      },
      {
        nume: 'Alioş',
        simplu: 'Alios',
        comuna: 'Masloc'
      },
      {
        nume: 'Fibiş',
        simplu: 'Fibis',
        comuna: 'Masloc'
      },
      {
        nume: 'Remetea Mică',
        simplu: 'Remetea Mica',
        comuna: 'Masloc'
      },
      {
        nume: 'Dejan',
        comuna: 'Moravita'
      },
      {
        nume: 'Gaiu Mic',
        comuna: 'Moravita'
      },
      {
        nume: 'Stamora Germană',
        simplu: 'Stamora Germana',
        comuna: 'Moravita'
      },
      {
        nume: 'Albina',
        comuna: 'Mosnita Noua'
      },
      {
        nume: 'Moşniţa Veche',
        simplu: 'Mosnita Veche',
        comuna: 'Mosnita Noua'
      },
      {
        nume: 'Rudicica',
        comuna: 'Mosnita Noua'
      },
      {
        nume: 'Urseni',
        comuna: 'Mosnita Noua'
      },
      {
        nume: 'Crivina',
        comuna: 'Nadrag'
      },
      {
        nume: 'Blajova',
        comuna: 'Nitchidorf'
      },
      {
        nume: 'Duboz',
        comuna: 'Nitchidorf'
      },
      {
        nume: 'Dubeşti',
        simplu: 'Dubesti',
        comuna: 'Ohaba Lunga'
      },
      {
        nume: 'Ierşnic',
        simplu: 'Iersnic',
        comuna: 'Ohaba Lunga'
      },
      {
        nume: 'Ohaba Română',
        simplu: 'Ohaba Romana',
        comuna: 'Ohaba Lunga'
      },
      {
        nume: 'Corneşti',
        simplu: 'Cornesti',
        comuna: 'Ortisoara'
      },
      {
        nume: 'Călacea',
        simplu: 'Calacea',
        comuna: 'Ortisoara'
      },
      {
        nume: 'Seceani',
        comuna: 'Ortisoara'
      },
      {
        nume: 'Diniaş',
        simplu: 'Dinias',
        comuna: 'Peciu Nou'
      },
      {
        nume: 'Sânmartinu Sârbesc',
        simplu: 'Sanmartinu Sarbesc',
        comuna: 'Peciu Nou'
      },
      {
        nume: 'Pesac',
        comuna: 'Periam'
      },
      {
        nume: 'Crivina de Sus',
        comuna: 'Pietroasa'
      },
      {
        nume: 'Fărăşeşti',
        simplu: 'Farasesti',
        comuna: 'Pietroasa'
      },
      {
        nume: 'Poieni',
        comuna: 'Pietroasa'
      },
      {
        nume: 'Bencecu de Jos',
        comuna: 'Pischia'
      },
      {
        nume: 'Bencecu de Sus',
        comuna: 'Pischia'
      },
      {
        nume: 'Murani',
        comuna: 'Pischia'
      },
      {
        nume: 'Sălciua Nouă',
        simplu: 'Salciua Noua',
        comuna: 'Pischia'
      },
      {
        nume: 'Căpăt',
        simplu: 'Capat',
        comuna: 'Racovita'
      },
      {
        nume: 'Drăgoieşti',
        simplu: 'Dragoiesti',
        comuna: 'Racovita'
      },
      {
        nume: 'Ficătar',
        simplu: 'Ficatar',
        comuna: 'Racovita'
      },
      {
        nume: 'Hitiaş',
        simplu: 'Hitias',
        comuna: 'Racovita'
      },
      {
        nume: 'Sârbova',
        simplu: 'Sarbova',
        comuna: 'Racovita'
      },
      {
        nume: 'Bazoş',
        simplu: 'Bazos',
        comuna: 'Recas'
      },
      {
        nume: 'Herneacova',
        comuna: 'Recas'
      },
      {
        nume: 'Izvin',
        comuna: 'Recas'
      },
      {
        nume: 'Nadăş',
        simplu: 'Nadas',
        comuna: 'Recas'
      },
      {
        nume: 'Petrovaselo',
        comuna: 'Recas'
      },
      {
        nume: 'Stanciova',
        comuna: 'Recas'
      },
      {
        nume: 'Bazoşu Nou',
        simplu: 'Bazosu Nou',
        comuna: 'Remetea Mare'
      },
      {
        nume: 'Bucovăţ',
        simplu: 'Bucovat',
        comuna: 'Remetea Mare'
      },
      {
        nume: 'Ianova',
        comuna: 'Remetea Mare'
      },
      {
        nume: 'Beregsău Mare',
        simplu: 'Beregsau Mare',
        comuna: 'Sacalaz'
      },
      {
        nume: 'Beregsău Mic',
        simplu: 'Beregsau Mic',
        comuna: 'Sacalaz'
      },
      {
        nume: 'Berini',
        comuna: 'Sacosu Turcesc'
      },
      {
        nume: 'Icloda',
        comuna: 'Sacosu Turcesc'
      },
      {
        nume: 'Otveşti',
        simplu: 'Otvesti',
        comuna: 'Sacosu Turcesc'
      },
      {
        nume: 'Stamora Română',
        simplu: 'Stamora Romana',
        comuna: 'Sacosu Turcesc'
      },
      {
        nume: 'Uliuc',
        comuna: 'Sacosu Turcesc'
      },
      {
        nume: 'Unip',
        comuna: 'Sacosu Turcesc'
      },
      {
        nume: 'Parţa',
        simplu: 'Parta',
        comuna: 'Sag'
      },
      {
        nume: 'Carani',
        comuna: 'Sanandrei'
      },
      {
        nume: 'Covaci',
        comuna: 'Sanandrei'
      },
      {
        nume: 'Sânmihaiu German',
        simplu: 'Sanmihaiu German',
        comuna: 'Sanmihaiu Roman'
      },
      {
        nume: 'Utvin',
        comuna: 'Sanmihaiu Roman'
      },
      {
        nume: 'Igriş',
        simplu: 'Igris',
        comuna: 'Sanpetru Mare'
      },
      {
        nume: 'Saravale',
        comuna: 'Sanpetru Mare'
      },
      {
        nume: 'Bărăteaz',
        simplu: 'Barateaz',
        comuna: 'Satchinez'
      },
      {
        nume: 'Hodoni',
        comuna: 'Satchinez'
      },
      {
        nume: 'Checheş',
        simplu: 'Cheches',
        comuna: 'Secas'
      },
      {
        nume: 'Crivobara',
        comuna: 'Secas'
      },
      {
        nume: 'Vizma',
        comuna: 'Secas'
      },
      {
        nume: 'Dragomireşti',
        simplu: 'Dragomiresti',
        comuna: 'Stiuca'
      },
      {
        nume: 'Oloşag',
        simplu: 'Olosag',
        comuna: 'Stiuca'
      },
      {
        nume: 'Zgribeşti',
        simplu: 'Zgribesti',
        comuna: 'Stiuca'
      },
      {
        nume: 'Nerău',
        simplu: 'Nerau',
        comuna: 'Teremia Mare'
      },
      {
        nume: 'Teremia Mică',
        simplu: 'Teremia Mica',
        comuna: 'Teremia Mare'
      },
      {
        nume: 'Baloşeşti',
        simplu: 'Balosesti',
        comuna: 'Tomesti'
      },
      {
        nume: 'Colonia Fabricii',
        comuna: 'Tomesti'
      },
      {
        nume: 'Luncanii de Jos',
        comuna: 'Tomesti'
      },
      {
        nume: 'Luncanii de Sus',
        comuna: 'Tomesti'
      },
      {
        nume: 'Româneşti',
        simplu: 'Romanesti',
        comuna: 'Tomesti'
      },
      {
        nume: 'Cralovăţ',
        simplu: 'Cralovat',
        comuna: 'Topolovatu Mare'
      },
      {
        nume: 'Ictar-Budinţi',
        simplu: 'Ictar-Budinti',
        comuna: 'Topolovatu Mare'
      },
      {
        nume: 'Iosifalău',
        simplu: 'Iosifalau',
        comuna: 'Topolovatu Mare'
      },
      {
        nume: 'Suştra',
        simplu: 'Sustra',
        comuna: 'Topolovatu Mare'
      },
      {
        nume: 'Topolovăţu Mic',
        simplu: 'Topolovatu Mic',
        comuna: 'Topolovatu Mare'
      },
      {
        nume: 'Cadar',
        comuna: 'Tormac'
      },
      {
        nume: 'Sipet',
        simplu: 'Sipet',
        comuna: 'Tormac'
      },
      {
        nume: 'Jupani',
        comuna: 'Traian Vuia'
      },
      {
        nume: 'Sudriaş',
        simplu: 'Sudrias',
        comuna: 'Traian Vuia'
      },
      {
        nume: 'Surducu Mic',
        comuna: 'Traian Vuia'
      },
      {
        nume: 'Susani',
        comuna: 'Traian Vuia'
      },
      {
        nume: 'Săceni',
        simplu: 'Saceni',
        comuna: 'Traian Vuia'
      },
      {
        nume: 'Iohanisfeld',
        comuna: 'Uivar'
      },
      {
        nume: 'Otelec',
        comuna: 'Uivar'
      },
      {
        nume: 'Pustiniş',
        simplu: 'Pustinis',
        comuna: 'Uivar'
      },
      {
        nume: 'Răuţi',
        simplu: 'Rauti',
        comuna: 'Uivar'
      },
      {
        nume: 'Sânmartinu Maghiar',
        simplu: 'Sanmartinu Maghiar',
        comuna: 'Uivar'
      },
      {
        nume: 'Gelu',
        comuna: 'Varias'
      },
      {
        nume: 'Sânpetru Mic',
        simplu: 'Sanpetru Mic',
        comuna: 'Varias'
      },
      {
        nume: 'Herendeşti',
        simplu: 'Herendesti',
        comuna: 'Victor Vlad Delamarina'
      },
      {
        nume: 'Honorici',
        comuna: 'Victor Vlad Delamarina'
      },
      {
        nume: 'Petroasa Mare',
        comuna: 'Victor Vlad Delamarina'
      },
      {
        nume: 'Pini',
        comuna: 'Victor Vlad Delamarina'
      },
      {
        nume: 'Pădureni',
        simplu: 'Padureni',
        comuna: 'Victor Vlad Delamarina'
      },
      {
        nume: 'Vişag',
        simplu: 'Visag',
        comuna: 'Victor Vlad Delamarina'
      },
      {
        nume: 'Folea',
        comuna: 'Voiteg'
      }
    ]
  },
  {
    auto: 'TL',
    nume: 'Tulcea',
    localitati: [
      {
        nume: 'Babadag'
      },
      {
        nume: 'Baia'
      },
      {
        nume: 'Beidaud'
      },
      {
        nume: 'C.a. Rosetti'
      },
      {
        nume: 'Carcaliu'
      },
      {
        nume: 'Casimcea'
      },
      {
        nume: 'Ceamurlia de Jos'
      },
      {
        nume: 'Ceatalchioi'
      },
      {
        nume: 'Cerna'
      },
      {
        nume: 'Chilia Veche'
      },
      {
        nume: 'Ciucurova'
      },
      {
        nume: 'Crişan',
        simplu: 'Crisan'
      },
      {
        nume: 'Dorobanţu',
        simplu: 'Dorobantu'
      },
      {
        nume: 'Dăeni',
        simplu: 'Daeni'
      },
      {
        nume: 'Frecăţei',
        simplu: 'Frecatei'
      },
      {
        nume: 'Greci'
      },
      {
        nume: 'Grindu'
      },
      {
        nume: 'Hamcearca'
      },
      {
        nume: 'Horia'
      },
      {
        nume: 'I.c.brătianu',
        simplu: 'I.c.bratianu'
      },
      {
        nume: 'Isaccea'
      },
      {
        nume: 'Izvoarele'
      },
      {
        nume: 'Jijila'
      },
      {
        nume: 'Jurilovca'
      },
      {
        nume: 'Luncaviţa',
        simplu: 'Luncavita'
      },
      {
        nume: 'Mahmudia'
      },
      {
        nume: 'Maliuc'
      },
      {
        nume: 'Mihai Bravu'
      },
      {
        nume: 'Mihail Kogălniceanu',
        simplu: 'Mihail Kogalniceanu'
      },
      {
        nume: 'Murighiol'
      },
      {
        nume: 'Măcin',
        simplu: 'Macin'
      },
      {
        nume: 'Nalbant'
      },
      {
        nume: 'Niculiţel',
        simplu: 'Niculitel'
      },
      {
        nume: 'Nufăru',
        simplu: 'Nufaru'
      },
      {
        nume: 'Ostrov'
      },
      {
        nume: 'Pardina'
      },
      {
        nume: 'Peceneaga'
      },
      {
        nume: 'Sarichioi'
      },
      {
        nume: 'Sfântu Gheorghe',
        simplu: 'Sfantu Gheorghe'
      },
      {
        nume: 'Slava Cercheză',
        simplu: 'Slava Cercheza'
      },
      {
        nume: 'Smârdan',
        simplu: 'Smardan'
      },
      {
        nume: 'Somova'
      },
      {
        nume: 'Stejaru'
      },
      {
        nume: 'Sulina'
      },
      {
        nume: 'Topolog'
      },
      {
        nume: 'Tulcea'
      },
      {
        nume: 'Turcoaia'
      },
      {
        nume: 'Valea Nucarilor'
      },
      {
        nume: 'Văcăreni',
        simplu: 'Vacareni'
      },
      {
        nume: 'Camena',
        comuna: 'Baia'
      },
      {
        nume: 'Caugagia',
        comuna: 'Baia'
      },
      {
        nume: 'Ceamurlia de Sus',
        comuna: 'Baia'
      },
      {
        nume: 'Panduru',
        comuna: 'Baia'
      },
      {
        nume: 'Neatârnarea',
        simplu: 'Neatarnarea',
        comuna: 'Beidaud'
      },
      {
        nume: 'Sarighiol de Deal',
        comuna: 'Beidaud'
      },
      {
        nume: 'Cardon',
        comuna: 'C.A. Rosetti'
      },
      {
        nume: 'Letea',
        comuna: 'C.A. Rosetti'
      },
      {
        nume: 'Periprava',
        comuna: 'C.A. Rosetti'
      },
      {
        nume: 'Sfiştofca',
        simplu: 'Sfistofca',
        comuna: 'C.A. Rosetti'
      },
      {
        nume: 'Cişmeaua Nouă',
        simplu: 'Cismeaua Noua',
        comuna: 'Casimcea'
      },
      {
        nume: 'Corugea',
        comuna: 'Casimcea'
      },
      {
        nume: 'Haidar',
        comuna: 'Casimcea'
      },
      {
        nume: 'Rahman',
        comuna: 'Casimcea'
      },
      {
        nume: 'Războieni',
        simplu: 'Razboieni',
        comuna: 'Casimcea'
      },
      {
        nume: 'Stânca',
        simplu: 'Stanca',
        comuna: 'Casimcea'
      },
      {
        nume: 'Lunca',
        comuna: 'Ceamurlia De Jos'
      },
      {
        nume: 'Plauru',
        comuna: 'Ceatalchioi'
      },
      {
        nume: 'Pătlăgeanca',
        simplu: 'Patlageanca',
        comuna: 'Ceatalchioi'
      },
      {
        nume: 'Sălceni',
        simplu: 'Salceni',
        comuna: 'Ceatalchioi'
      },
      {
        nume: 'General Praporgescu',
        comuna: 'Cerna'
      },
      {
        nume: 'Mircea Vodă',
        simplu: 'Mircea Voda',
        comuna: 'Cerna'
      },
      {
        nume: 'Traian',
        comuna: 'Cerna'
      },
      {
        nume: 'Câşliţa',
        simplu: 'Caslita',
        comuna: 'Chilia Veche'
      },
      {
        nume: 'Ostrovu Tătaru',
        simplu: 'Ostrovu Tataru',
        comuna: 'Chilia Veche'
      },
      {
        nume: 'Tatanir',
        comuna: 'Chilia Veche'
      },
      {
        nume: 'Atmagea',
        comuna: 'Ciucurova'
      },
      {
        nume: 'Fântâna Mare',
        simplu: 'Fantana Mare',
        comuna: 'Ciucurova'
      },
      {
        nume: 'Caraorman',
        comuna: 'Crisan'
      },
      {
        nume: 'Mila 23',
        comuna: 'Crisan'
      },
      {
        nume: 'Ardealu',
        comuna: 'Dorobantu'
      },
      {
        nume: 'Cârjelari',
        simplu: 'Carjelari',
        comuna: 'Dorobantu'
      },
      {
        nume: 'Fântâna Oilor',
        simplu: 'Fantana Oilor',
        comuna: 'Dorobantu'
      },
      {
        nume: 'Meşteru',
        simplu: 'Mesteru',
        comuna: 'Dorobantu'
      },
      {
        nume: 'Cataloi',
        comuna: 'Frecatei'
      },
      {
        nume: 'Poşta',
        simplu: 'Posta',
        comuna: 'Frecatei'
      },
      {
        nume: 'Teliţa',
        simplu: 'Telita',
        comuna: 'Frecatei'
      },
      {
        nume: 'Balabancea',
        comuna: 'Hamcearca'
      },
      {
        nume: 'Căprioara',
        simplu: 'Caprioara',
        comuna: 'Hamcearca'
      },
      {
        nume: 'Nifon',
        comuna: 'Hamcearca'
      },
      {
        nume: 'Cloşca',
        simplu: 'Closca',
        comuna: 'Horia'
      },
      {
        nume: 'Floreşti',
        simplu: 'Floresti',
        comuna: 'Horia'
      },
      {
        nume: 'Revărsarea',
        simplu: 'Revarsarea',
        comuna: 'Isaccea'
      },
      {
        nume: 'Tichileşti',
        simplu: 'Tichilesti',
        comuna: 'Isaccea'
      },
      {
        nume: 'Alba',
        comuna: 'Izvoarele'
      },
      {
        nume: 'Iulia',
        comuna: 'Izvoarele'
      },
      {
        nume: 'Valea Teilor',
        comuna: 'Izvoarele'
      },
      {
        nume: 'Garvăn',
        simplu: 'Garvan',
        comuna: 'Jijila'
      },
      {
        nume: 'Sălcioara',
        simplu: 'Salcioara',
        comuna: 'Jurilovca'
      },
      {
        nume: 'Vişina',
        simplu: 'Visina',
        comuna: 'Jurilovca'
      },
      {
        nume: 'Rachelu',
        comuna: 'Luncavita'
      },
      {
        nume: 'Beştepe',
        simplu: 'Bestepe',
        comuna: 'Mahmudia'
      },
      {
        nume: 'Băltenii de Jos',
        simplu: 'Baltenii de Jos',
        comuna: 'Mahmudia'
      },
      {
        nume: 'Băltenii de Sus',
        simplu: 'Baltenii de Sus',
        comuna: 'Mahmudia'
      },
      {
        nume: 'Gorgova',
        comuna: 'Maliuc'
      },
      {
        nume: 'Ilganii de Sus',
        comuna: 'Maliuc'
      },
      {
        nume: 'Partizani',
        comuna: 'Maliuc'
      },
      {
        nume: 'Vulturu',
        comuna: 'Maliuc'
      },
      {
        nume: 'Satu Nou',
        comuna: 'Mihai Bravu'
      },
      {
        nume: 'Turda',
        comuna: 'Mihai Bravu'
      },
      {
        nume: 'Lăstuni',
        simplu: 'Lastuni',
        comuna: 'Mihail Kogalniceanu'
      },
      {
        nume: 'Rândunica',
        simplu: 'Randunica',
        comuna: 'Mihail Kogalniceanu'
      },
      {
        nume: 'Colina',
        comuna: 'Murighiol'
      },
      {
        nume: 'Dunavăţu de Jos',
        simplu: 'Dunavatu de Jos',
        comuna: 'Murighiol'
      },
      {
        nume: 'Dunavăţu de Sus',
        simplu: 'Dunavatu de Sus',
        comuna: 'Murighiol'
      },
      {
        nume: 'Plopul',
        comuna: 'Murighiol'
      },
      {
        nume: 'Sarinasuf',
        comuna: 'Murighiol'
      },
      {
        nume: 'Uzlina',
        comuna: 'Murighiol'
      },
      {
        nume: 'Nicolae Bălcescu',
        simplu: 'Nicolae Balcescu',
        comuna: 'Nalbant'
      },
      {
        nume: 'Trestenic',
        comuna: 'Nalbant'
      },
      {
        nume: 'Ilganii de Jos',
        comuna: 'Nufaru'
      },
      {
        nume: 'Malcoci',
        comuna: 'Nufaru'
      },
      {
        nume: 'Victoria',
        comuna: 'Nufaru'
      },
      {
        nume: 'Piatra',
        comuna: 'Ostrov'
      },
      {
        nume: 'Enisala',
        comuna: 'Sarichioi'
      },
      {
        nume: 'Sabangia',
        comuna: 'Sarichioi'
      },
      {
        nume: 'Visterna',
        comuna: 'Sarichioi'
      },
      {
        nume: 'Zebil',
        comuna: 'Sarichioi'
      },
      {
        nume: 'Slava Rusă',
        simplu: 'Slava Rusa',
        comuna: 'Slava Cercheza'
      },
      {
        nume: 'Mineri',
        comuna: 'Somova'
      },
      {
        nume: 'Parcheş',
        simplu: 'Parches',
        comuna: 'Somova'
      },
      {
        nume: 'Mina Altân Tepe',
        simplu: 'Mina Altan Tepe',
        comuna: 'Stejaru'
      },
      {
        nume: 'Vasile Alecsandri',
        comuna: 'Stejaru'
      },
      {
        nume: 'Calfa',
        comuna: 'Topolog'
      },
      {
        nume: 'Cerbu',
        comuna: 'Topolog'
      },
      {
        nume: 'Făgăraşu Nou',
        simplu: 'Fagarasu Nou',
        comuna: 'Topolog'
      },
      {
        nume: 'Luminiţa',
        simplu: 'Luminita',
        comuna: 'Topolog'
      },
      {
        nume: 'Măgurele',
        simplu: 'Magurele',
        comuna: 'Topolog'
      },
      {
        nume: 'Sâmbăta Nouă',
        simplu: 'Sambata Noua',
        comuna: 'Topolog'
      },
      {
        nume: 'Tudor Vladimirescu',
        comuna: 'Tulcea'
      },
      {
        nume: 'Agighiol',
        comuna: 'Valea Nucarilor'
      },
      {
        nume: 'Iazurile',
        comuna: 'Valea Nucarilor'
      }
    ]
  },
  {
    auto: 'VL',
    nume: 'Vâlcea',
    localitati: [
      {
        nume: 'Alunu'
      },
      {
        nume: 'Amărăşti',
        simplu: 'Amarasti'
      },
      {
        nume: 'Berbeşti',
        simplu: 'Berbesti'
      },
      {
        nume: 'Berislăveşti',
        simplu: 'Berislavesti'
      },
      {
        nume: 'Boişoara',
        simplu: 'Boisoara'
      },
      {
        nume: 'Brezoi'
      },
      {
        nume: 'Budeşti',
        simplu: 'Budesti'
      },
      {
        nume: 'Bujoreni'
      },
      {
        nume: 'Buneşti',
        simplu: 'Bunesti'
      },
      {
        nume: 'Băbeni',
        simplu: 'Babeni'
      },
      {
        nume: 'Bălceşti',
        simplu: 'Balcesti'
      },
      {
        nume: 'Bărbăteşti',
        simplu: 'Barbatesti'
      },
      {
        nume: 'Cernişoara',
        simplu: 'Cernisoara'
      },
      {
        nume: 'Copăceni',
        simplu: 'Copaceni'
      },
      {
        nume: 'Costeşti',
        simplu: 'Costesti'
      },
      {
        nume: 'Creţeni',
        simplu: 'Creteni'
      },
      {
        nume: 'Călimăneşti',
        simplu: 'Calimanesti'
      },
      {
        nume: 'Drăgoeşti',
        simplu: 'Dragoesti'
      },
      {
        nume: 'Drăgăşani',
        simplu: 'Dragasani'
      },
      {
        nume: 'Dăeşti',
        simplu: 'Daesti'
      },
      {
        nume: 'Frânceşti',
        simplu: 'Francesti'
      },
      {
        nume: 'Fârtăţeşti',
        simplu: 'Fartatesti'
      },
      {
        nume: 'Făureşti',
        simplu: 'Fauresti'
      },
      {
        nume: 'Galicea'
      },
      {
        nume: 'Ghioroiu'
      },
      {
        nume: 'Glăvile',
        simplu: 'Glavile'
      },
      {
        nume: 'Grădiştea',
        simplu: 'Gradistea'
      },
      {
        nume: 'Guşoeni',
        simplu: 'Gusoeni'
      },
      {
        nume: 'Horezu'
      },
      {
        nume: 'Ioneşti',
        simplu: 'Ionesti'
      },
      {
        nume: 'Laloşu',
        simplu: 'Lalosu'
      },
      {
        nume: 'Livezi'
      },
      {
        nume: 'Lungeşti',
        simplu: 'Lungesti'
      },
      {
        nume: 'Lădeşti',
        simplu: 'Ladesti'
      },
      {
        nume: 'Malaia'
      },
      {
        nume: 'Mateeşti',
        simplu: 'Mateesti'
      },
      {
        nume: 'Mihăeşti',
        simplu: 'Mihaesti'
      },
      {
        nume: 'Milcoiu'
      },
      {
        nume: 'Muereasca'
      },
      {
        nume: 'Mădulari',
        simplu: 'Madulari'
      },
      {
        nume: 'Măldăreşti',
        simplu: 'Maldaresti'
      },
      {
        nume: 'Olanu'
      },
      {
        nume: 'Orleşti',
        simplu: 'Orlesti'
      },
      {
        nume: 'Oteşani',
        simplu: 'Otesani'
      },
      {
        nume: 'Perişani',
        simplu: 'Perisani'
      },
      {
        nume: 'Pesceana'
      },
      {
        nume: 'Pietrari'
      },
      {
        nume: 'Popeşti',
        simplu: 'Popesti'
      },
      {
        nume: 'Prundeni'
      },
      {
        nume: 'Păuşeşti',
        simplu: 'Pausesti'
      },
      {
        nume: 'Păuşeşti-Măglaşi',
        simplu: 'Pausesti-Maglasi'
      },
      {
        nume: 'Racoviţa',
        simplu: 'Racovita'
      },
      {
        nume: 'Roeşti',
        simplu: 'Roesti'
      },
      {
        nume: 'Roşiile',
        simplu: 'Rosiile'
      },
      {
        nume: 'Runcu'
      },
      {
        nume: 'Râmnicu Vâlcea',
        simplu: 'Ramnicu Valcea'
      },
      {
        nume: 'Scundu'
      },
      {
        nume: 'Sineşti',
        simplu: 'Sinesti'
      },
      {
        nume: 'Slătioara',
        simplu: 'Slatioara'
      },
      {
        nume: 'Stoeneşti',
        simplu: 'Stoenesti'
      },
      {
        nume: 'Stoileşti',
        simplu: 'Stoilesti'
      },
      {
        nume: 'Stroeşti',
        simplu: 'Stroesti'
      },
      {
        nume: 'Stăneşti',
        simplu: 'Stanesti'
      },
      {
        nume: 'Suteşti',
        simplu: 'Sutesti'
      },
      {
        nume: 'Sălătrucel',
        simplu: 'Salatrucel'
      },
      {
        nume: 'Tetoiu'
      },
      {
        nume: 'Titeşti',
        simplu: 'Titesti'
      },
      {
        nume: 'Tomşani',
        simplu: 'Tomsani'
      },
      {
        nume: 'Vaideeni'
      },
      {
        nume: 'Valea Mare'
      },
      {
        nume: 'Vlădeşti',
        simplu: 'Vladesti'
      },
      {
        nume: 'Voiceşti',
        simplu: 'Voicesti'
      },
      {
        nume: 'Voineasa'
      },
      {
        nume: 'Zătreni',
        simplu: 'Zatreni'
      },
      {
        nume: 'Sirineasa',
        simplu: 'Sirineasa'
      },
      {
        nume: 'Stefăneşti',
        simplu: 'Stefanesti'
      },
      {
        nume: 'Suşani',
        simplu: 'Susani'
      },
      {
        nume: 'Bodeşti',
        simplu: 'Bodesti',
        comuna: 'Alunu'
      },
      {
        nume: 'Colteşti',
        simplu: 'Coltesti',
        comuna: 'Alunu'
      },
      {
        nume: 'Igoiu',
        comuna: 'Alunu'
      },
      {
        nume: 'Ilaciu',
        comuna: 'Alunu'
      },
      {
        nume: 'Ocracu',
        comuna: 'Alunu'
      },
      {
        nume: 'Roşia',
        simplu: 'Rosia',
        comuna: 'Alunu'
      },
      {
        nume: 'Mereşeşti',
        simplu: 'Meresesti',
        comuna: 'Amarasti'
      },
      {
        nume: 'Nemoiu',
        comuna: 'Amarasti'
      },
      {
        nume: 'Padina',
        comuna: 'Amarasti'
      },
      {
        nume: 'Palanga',
        comuna: 'Amarasti'
      },
      {
        nume: 'Teiul',
        comuna: 'Amarasti'
      },
      {
        nume: 'Bonciu',
        comuna: 'Babeni'
      },
      {
        nume: 'Capu Dealului',
        comuna: 'Babeni'
      },
      {
        nume: 'Pădureţu',
        simplu: 'Paduretu',
        comuna: 'Babeni'
      },
      {
        nume: 'Români',
        simplu: 'Romani',
        comuna: 'Babeni'
      },
      {
        nume: 'Tătărani',
        simplu: 'Tatarani',
        comuna: 'Babeni'
      },
      {
        nume: 'Valea Mare',
        comuna: 'Babeni'
      },
      {
        nume: 'Curăturile',
        simplu: 'Curaturile',
        comuna: 'Baile Govora'
      },
      {
        nume: 'Gătejeşti',
        simplu: 'Gatejesti',
        comuna: 'Baile Govora'
      },
      {
        nume: 'Prajila',
        comuna: 'Baile Govora'
      },
      {
        nume: 'Cheia',
        comuna: 'Baile Olanesti'
      },
      {
        nume: 'Comanca',
        comuna: 'Baile Olanesti'
      },
      {
        nume: 'Gurguiata',
        comuna: 'Baile Olanesti'
      },
      {
        nume: 'Livadia',
        comuna: 'Baile Olanesti'
      },
      {
        nume: 'Moşoroaşa',
        simplu: 'Mosoroasa',
        comuna: 'Baile Olanesti'
      },
      {
        nume: 'Olăneşti',
        simplu: 'Olanesti',
        comuna: 'Baile Olanesti'
      },
      {
        nume: 'Pietrişu',
        simplu: 'Pietrisu',
        comuna: 'Baile Olanesti'
      },
      {
        nume: 'Tisa',
        comuna: 'Baile Olanesti'
      },
      {
        nume: 'Beneşti',
        simplu: 'Benesti',
        comuna: 'Balcesti'
      },
      {
        nume: 'Chirculeşti',
        simplu: 'Chirculesti',
        comuna: 'Balcesti'
      },
      {
        nume: 'Cârlogani',
        simplu: 'Carlogani',
        comuna: 'Balcesti'
      },
      {
        nume: 'Goruneşti',
        simplu: 'Gorunesti',
        comuna: 'Balcesti'
      },
      {
        nume: 'Irimeşti',
        simplu: 'Irimesti',
        comuna: 'Balcesti'
      },
      {
        nume: 'Otetelişu',
        simplu: 'Otetelisu',
        comuna: 'Balcesti'
      },
      {
        nume: 'Preoteşti',
        simplu: 'Preotesti',
        comuna: 'Balcesti'
      },
      {
        nume: 'Satu Poieni',
        comuna: 'Balcesti'
      },
      {
        nume: 'Bodeşti',
        simplu: 'Bodesti',
        comuna: 'Barbatesti'
      },
      {
        nume: 'Bârzeşti',
        simplu: 'Barzesti',
        comuna: 'Barbatesti'
      },
      {
        nume: 'Negruleşti',
        simplu: 'Negrulesti',
        comuna: 'Barbatesti'
      },
      {
        nume: 'Dealu Aluniş',
        simplu: 'Dealu Alunis',
        comuna: 'Berbesti'
      },
      {
        nume: 'Dămţeni',
        simplu: 'Damteni',
        comuna: 'Berbesti'
      },
      {
        nume: 'Roşioara',
        simplu: 'Rosioara',
        comuna: 'Berbesti'
      },
      {
        nume: 'Târgu Gânguleşti',
        simplu: 'Targu Gangulesti',
        comuna: 'Berbesti'
      },
      {
        nume: 'Valea Mare',
        comuna: 'Berbesti'
      },
      {
        nume: 'Brădişor',
        simplu: 'Bradisor',
        comuna: 'Berislavesti'
      },
      {
        nume: 'Dângeşti',
        simplu: 'Dangesti',
        comuna: 'Berislavesti'
      },
      {
        nume: 'Robaia',
        comuna: 'Berislavesti'
      },
      {
        nume: 'Rădăcineşti',
        simplu: 'Radacinesti',
        comuna: 'Berislavesti'
      },
      {
        nume: 'Scăueni',
        simplu: 'Scaueni',
        comuna: 'Berislavesti'
      },
      {
        nume: 'Stoeneşti',
        simplu: 'Stoenesti',
        comuna: 'Berislavesti'
      },
      {
        nume: 'Bumbueşti',
        simplu: 'Bumbuesti',
        comuna: 'Boisoara'
      },
      {
        nume: 'Găujani',
        simplu: 'Gaujani',
        comuna: 'Boisoara'
      },
      {
        nume: 'Corbu',
        comuna: 'Brezoi'
      },
      {
        nume: 'Călineşti',
        simplu: 'Calinesti',
        comuna: 'Brezoi'
      },
      {
        nume: 'Drăgăneşti',
        simplu: 'Draganesti',
        comuna: 'Brezoi'
      },
      {
        nume: 'Golotreni',
        comuna: 'Brezoi'
      },
      {
        nume: 'Proieni',
        comuna: 'Brezoi'
      },
      {
        nume: 'Păscoaia',
        simplu: 'Pascoaia',
        comuna: 'Brezoi'
      },
      {
        nume: 'Valea lui Stan',
        comuna: 'Brezoi'
      },
      {
        nume: 'Văratica',
        simplu: 'Varatica',
        comuna: 'Brezoi'
      },
      {
        nume: 'Barza',
        comuna: 'Budesti'
      },
      {
        nume: 'Bercioiu',
        comuna: 'Budesti'
      },
      {
        nume: 'Bârseşti',
        simplu: 'Barsesti',
        comuna: 'Budesti'
      },
      {
        nume: 'Linia',
        comuna: 'Budesti'
      },
      {
        nume: 'Piscu Pietrei',
        comuna: 'Budesti'
      },
      {
        nume: 'Racoviţa',
        simplu: 'Racovita',
        comuna: 'Budesti'
      },
      {
        nume: 'Ruda',
        comuna: 'Budesti'
      },
      {
        nume: 'Bogdăneşti',
        simplu: 'Bogdanesti',
        comuna: 'Bujoreni'
      },
      {
        nume: 'Gura Văii',
        simplu: 'Gura Vaii',
        comuna: 'Bujoreni'
      },
      {
        nume: 'Lunca',
        comuna: 'Bujoreni'
      },
      {
        nume: 'Malu Alb',
        comuna: 'Bujoreni'
      },
      {
        nume: 'Malu Vârtop',
        simplu: 'Malu Vartop',
        comuna: 'Bujoreni'
      },
      {
        nume: 'Olteni',
        comuna: 'Bujoreni'
      },
      {
        nume: 'Coasta Mare',
        comuna: 'Bunesti'
      },
      {
        nume: 'Fireşti',
        simplu: 'Firesti',
        comuna: 'Bunesti'
      },
      {
        nume: 'Râpăneşti',
        simplu: 'Rapanesti',
        comuna: 'Bunesti'
      },
      {
        nume: 'Teiuşu',
        simplu: 'Teiusu',
        comuna: 'Bunesti'
      },
      {
        nume: 'Titireci',
        comuna: 'Bunesti'
      },
      {
        nume: 'Câinenii Mari',
        simplu: 'Cainenii Mari',
        comuna: 'Caineni'
      },
      {
        nume: 'Câinenii Mici',
        simplu: 'Cainenii Mici',
        comuna: 'Caineni'
      },
      {
        nume: 'Grebleşti',
        simplu: 'Greblesti',
        comuna: 'Caineni'
      },
      {
        nume: 'Priloage',
        comuna: 'Caineni'
      },
      {
        nume: 'Robeşti',
        simplu: 'Robesti',
        comuna: 'Caineni'
      },
      {
        nume: 'Râu Vadului',
        simplu: 'Rau Vadului',
        comuna: 'Caineni'
      },
      {
        nume: 'Căciulata',
        simplu: 'Caciulata',
        comuna: 'Calimanesti'
      },
      {
        nume: 'Jiblea Nouă',
        simplu: 'Jiblea Noua',
        comuna: 'Calimanesti'
      },
      {
        nume: 'Jiblea Veche',
        comuna: 'Calimanesti'
      },
      {
        nume: 'Păuşa',
        simplu: 'Pausa',
        comuna: 'Calimanesti'
      },
      {
        nume: 'Seaca',
        comuna: 'Calimanesti'
      },
      {
        nume: 'Armăşeşti',
        simplu: 'Armasesti',
        comuna: 'Cernisoara'
      },
      {
        nume: 'Groşi',
        simplu: 'Grosi',
        comuna: 'Cernisoara'
      },
      {
        nume: 'Modoia',
        comuna: 'Cernisoara'
      },
      {
        nume: 'Mădulari',
        simplu: 'Madulari',
        comuna: 'Cernisoara'
      },
      {
        nume: 'Obârşia',
        simplu: 'Obarsia',
        comuna: 'Cernisoara'
      },
      {
        nume: 'Sărsăneşti',
        simplu: 'Sarsanesti',
        comuna: 'Cernisoara'
      },
      {
        nume: 'Bondoci',
        comuna: 'Copaceni'
      },
      {
        nume: 'Bălteni',
        simplu: 'Balteni',
        comuna: 'Copaceni'
      },
      {
        nume: 'Hotărasa',
        simplu: 'Hotarasa',
        comuna: 'Copaceni'
      },
      {
        nume: 'Ulmetu',
        comuna: 'Copaceni'
      },
      {
        nume: 'Veţelu',
        simplu: 'Vetelu',
        comuna: 'Copaceni'
      },
      {
        nume: 'Bistriţa',
        simplu: 'Bistrita',
        comuna: 'Costesti'
      },
      {
        nume: 'Pietreni',
        comuna: 'Costesti'
      },
      {
        nume: 'Văratici',
        simplu: 'Varatici',
        comuna: 'Costesti'
      },
      {
        nume: 'Izvoru',
        comuna: 'Creteni'
      },
      {
        nume: 'Mreneşti',
        simplu: 'Mrenesti',
        comuna: 'Creteni'
      },
      {
        nume: 'Streminoasa',
        comuna: 'Creteni'
      },
      {
        nume: 'Băbueşti',
        simplu: 'Babuesti',
        comuna: 'Daesti'
      },
      {
        nume: 'Fedeleşoiu',
        simplu: 'Fedelesoiu',
        comuna: 'Daesti'
      },
      {
        nume: 'Sânbotin',
        simplu: 'Sanbotin',
        comuna: 'Daesti'
      },
      {
        nume: 'Bădeni',
        simplu: 'Badeni',
        comuna: 'Danicei'
      },
      {
        nume: 'Ceretu',
        comuna: 'Danicei'
      },
      {
        nume: 'Cireşul',
        simplu: 'Ciresul',
        comuna: 'Danicei'
      },
      {
        nume: 'Dealu Lăunele',
        simplu: 'Dealu Launele',
        comuna: 'Danicei'
      },
      {
        nume: 'Dealu Scheiului',
        comuna: 'Danicei'
      },
      {
        nume: 'Dobreşti',
        simplu: 'Dobresti',
        comuna: 'Danicei'
      },
      {
        nume: 'Drăguleşti',
        simplu: 'Dragulesti',
        comuna: 'Danicei'
      },
      {
        nume: 'Glodu',
        comuna: 'Danicei'
      },
      {
        nume: 'Gura Crucilor',
        comuna: 'Danicei'
      },
      {
        nume: 'Linia pe Vale',
        comuna: 'Danicei'
      },
      {
        nume: 'Lăunele de Jos',
        simplu: 'Launele de Jos',
        comuna: 'Danicei'
      },
      {
        nume: 'Udreşti',
        simplu: 'Udresti',
        comuna: 'Danicei'
      },
      {
        nume: 'Valea Scheiului',
        comuna: 'Danicei'
      },
      {
        nume: 'Valea Caselor',
        comuna: 'Dragasani'
      },
      {
        nume: 'Zlătărei',
        simplu: 'Zlatarei',
        comuna: 'Dragasani'
      },
      {
        nume: 'Zărneni',
        simplu: 'Zarneni',
        comuna: 'Dragasani'
      },
      {
        nume: 'Buciumeni',
        comuna: 'Dragoesti'
      },
      {
        nume: 'Geamăna',
        simplu: 'Geamana',
        comuna: 'Dragoesti'
      },
      {
        nume: 'Afânata',
        simplu: 'Afanata',
        comuna: 'Fartatesti'
      },
      {
        nume: 'Becşani',
        simplu: 'Becsani',
        comuna: 'Fartatesti'
      },
      {
        nume: 'Cuci',
        comuna: 'Fartatesti'
      },
      {
        nume: 'Căţetu',
        simplu: 'Catetu',
        comuna: 'Fartatesti'
      },
      {
        nume: 'Dejoi',
        comuna: 'Fartatesti'
      },
      {
        nume: 'Dozeşti',
        simplu: 'Dozesti',
        comuna: 'Fartatesti'
      },
      {
        nume: 'Dăncăi',
        simplu: 'Dancai',
        comuna: 'Fartatesti'
      },
      {
        nume: 'Giuleşti',
        simplu: 'Giulesti',
        comuna: 'Fartatesti'
      },
      {
        nume: 'Giuleştii de Sus',
        simplu: 'Giulestii de Sus',
        comuna: 'Fartatesti'
      },
      {
        nume: 'Gârnicet',
        simplu: 'Garnicet',
        comuna: 'Fartatesti'
      },
      {
        nume: 'Măriceşti',
        simplu: 'Maricesti',
        comuna: 'Fartatesti'
      },
      {
        nume: 'Nisipi',
        comuna: 'Fartatesti'
      },
      {
        nume: 'Popeşti',
        simplu: 'Popesti',
        comuna: 'Fartatesti'
      },
      {
        nume: 'Rusăneşti',
        simplu: 'Rusanesti',
        comuna: 'Fartatesti'
      },
      {
        nume: 'Seciu',
        comuna: 'Fartatesti'
      },
      {
        nume: 'Stănculeşti',
        simplu: 'Stanculesti',
        comuna: 'Fartatesti'
      },
      {
        nume: 'Tanislavi',
        comuna: 'Fartatesti'
      },
      {
        nume: 'Valea Ursului',
        comuna: 'Fartatesti'
      },
      {
        nume: 'Sotani',
        simplu: 'Sotani',
        comuna: 'Fartatesti'
      },
      {
        nume: 'Budeşti',
        simplu: 'Budesti',
        comuna: 'Fauresti'
      },
      {
        nume: 'Bungeţani',
        simplu: 'Bungetani',
        comuna: 'Fauresti'
      },
      {
        nume: 'Băbeni-Olteţu',
        simplu: 'Babeni-Oltetu',
        comuna: 'Fauresti'
      },
      {
        nume: 'Colelia',
        comuna: 'Fauresti'
      },
      {
        nume: 'Diculeşti',
        simplu: 'Diculesti',
        comuna: 'Fauresti'
      },
      {
        nume: 'Găineşti',
        simplu: 'Gainesti',
        comuna: 'Fauresti'
      },
      {
        nume: 'Mileşti',
        simplu: 'Milesti',
        comuna: 'Fauresti'
      },
      {
        nume: 'Mărcuşu',
        simplu: 'Marcusu',
        comuna: 'Fauresti'
      },
      {
        nume: 'Băluţoaia',
        simplu: 'Balutoaia',
        comuna: 'Francesti'
      },
      {
        nume: 'Coşani',
        simplu: 'Cosani',
        comuna: 'Francesti'
      },
      {
        nume: 'Dezrobiţi',
        simplu: 'Dezrobiti',
        comuna: 'Francesti'
      },
      {
        nume: 'Genuneni',
        comuna: 'Francesti'
      },
      {
        nume: 'Moşteni',
        simplu: 'Mosteni',
        comuna: 'Francesti'
      },
      {
        nume: 'Mănăileşti',
        simplu: 'Manailesti',
        comuna: 'Francesti'
      },
      {
        nume: 'Surpatele',
        comuna: 'Francesti'
      },
      {
        nume: 'Viişoara',
        simplu: 'Viisoara',
        comuna: 'Francesti'
      },
      {
        nume: 'Bratia din Deal',
        comuna: 'Galicea'
      },
      {
        nume: 'Bratia din Vale',
        comuna: 'Galicea'
      },
      {
        nume: 'Cocoru',
        comuna: 'Galicea'
      },
      {
        nume: 'Cremenari',
        comuna: 'Galicea'
      },
      {
        nume: 'Dealu Mare',
        comuna: 'Galicea'
      },
      {
        nume: 'Ostroveni',
        comuna: 'Galicea'
      },
      {
        nume: 'Teiu',
        comuna: 'Galicea'
      },
      {
        nume: 'Valea Râului',
        simplu: 'Valea Raului',
        comuna: 'Galicea'
      },
      {
        nume: 'Căzăneşti',
        simplu: 'Cazanesti',
        comuna: 'Ghioroiu'
      },
      {
        nume: 'Herăşti',
        simplu: 'Herasti',
        comuna: 'Ghioroiu'
      },
      {
        nume: 'Mierea',
        comuna: 'Ghioroiu'
      },
      {
        nume: 'Poienari',
        comuna: 'Ghioroiu'
      },
      {
        nume: 'Stirbeşti',
        simplu: 'Stirbesti',
        comuna: 'Ghioroiu'
      },
      {
        nume: 'Aninoasa',
        comuna: 'Glavile'
      },
      {
        nume: 'Jaroştea',
        simplu: 'Jarostea',
        comuna: 'Glavile'
      },
      {
        nume: 'Olteanca',
        comuna: 'Glavile'
      },
      {
        nume: 'Voiculeasa',
        comuna: 'Glavile'
      },
      {
        nume: 'Aldeşti',
        simplu: 'Aldesti',
        comuna: 'Golesti'
      },
      {
        nume: 'Blidari',
        comuna: 'Golesti'
      },
      {
        nume: 'Coasta',
        comuna: 'Golesti'
      },
      {
        nume: 'Drăgăneşti',
        simplu: 'Draganesti',
        comuna: 'Golesti'
      },
      {
        nume: 'Gibeşti',
        simplu: 'Gibesti',
        comuna: 'Golesti'
      },
      {
        nume: 'Giurgiuveni',
        comuna: 'Golesti'
      },
      {
        nume: 'Opăteşti',
        simplu: 'Opatesti',
        comuna: 'Golesti'
      },
      {
        nume: 'Poeniţa',
        simplu: 'Poenita',
        comuna: 'Golesti'
      },
      {
        nume: 'Popeşti',
        simplu: 'Popesti',
        comuna: 'Golesti'
      },
      {
        nume: 'Tulei-Câmpeni',
        simplu: 'Tulei-Campeni',
        comuna: 'Golesti'
      },
      {
        nume: 'Vătăşeşti',
        simplu: 'Vatasesti',
        comuna: 'Golesti'
      },
      {
        nume: 'Diaconeşti',
        simplu: 'Diaconesti',
        comuna: 'Gradistea'
      },
      {
        nume: 'Dobricea',
        comuna: 'Gradistea'
      },
      {
        nume: 'Linia',
        comuna: 'Gradistea'
      },
      {
        nume: 'Obislavu',
        comuna: 'Gradistea'
      },
      {
        nume: 'Străchineşti',
        simplu: 'Strachinesti',
        comuna: 'Gradistea'
      },
      {
        nume: 'Turburea',
        comuna: 'Gradistea'
      },
      {
        nume: 'Valea Grădiştei',
        simplu: 'Valea Gradistei',
        comuna: 'Gradistea'
      },
      {
        nume: 'Tuţuru',
        simplu: 'Tuturu',
        comuna: 'Gradistea'
      },
      {
        nume: 'Burdăleşti',
        simplu: 'Burdalesti',
        comuna: 'Gusoeni'
      },
      {
        nume: 'Dealu Mare',
        comuna: 'Gusoeni'
      },
      {
        nume: 'Guşoianca',
        simplu: 'Gusoianca',
        comuna: 'Gusoeni'
      },
      {
        nume: 'Măgureni',
        simplu: 'Magureni',
        comuna: 'Gusoeni'
      },
      {
        nume: 'Spârleni',
        simplu: 'Sparleni',
        comuna: 'Gusoeni'
      },
      {
        nume: 'Ifrimeşti',
        simplu: 'Ifrimesti',
        comuna: 'Horezu'
      },
      {
        nume: 'Romanii de Jos',
        comuna: 'Horezu'
      },
      {
        nume: 'Romanii de Sus',
        comuna: 'Horezu'
      },
      {
        nume: 'Râmeşti',
        simplu: 'Ramesti',
        comuna: 'Horezu'
      },
      {
        nume: 'Tănăseşti',
        simplu: 'Tanasesti',
        comuna: 'Horezu'
      },
      {
        nume: 'Urşani',
        simplu: 'Ursani',
        comuna: 'Horezu'
      },
      {
        nume: 'Bucşani',
        simplu: 'Bucsani',
        comuna: 'Ionesti'
      },
      {
        nume: 'Dealu Mare',
        comuna: 'Ionesti'
      },
      {
        nume: 'Delureni',
        comuna: 'Ionesti'
      },
      {
        nume: 'Fişcălia',
        simplu: 'Fiscalia',
        comuna: 'Ionesti'
      },
      {
        nume: 'Foteşti',
        simplu: 'Fotesti',
        comuna: 'Ionesti'
      },
      {
        nume: 'Guguianca',
        comuna: 'Ionesti'
      },
      {
        nume: 'Marcea',
        comuna: 'Ionesti'
      },
      {
        nume: 'Prodăneşti',
        simplu: 'Prodanesti',
        comuna: 'Ionesti'
      },
      {
        nume: 'Cermegeşti',
        simplu: 'Cermegesti',
        comuna: 'Ladesti'
      },
      {
        nume: 'Chiriceşti',
        simplu: 'Chiricesti',
        comuna: 'Ladesti'
      },
      {
        nume: 'Ciumagi',
        comuna: 'Ladesti'
      },
      {
        nume: 'Dealu Corni',
        comuna: 'Ladesti'
      },
      {
        nume: 'Găgeni',
        simplu: 'Gageni',
        comuna: 'Ladesti'
      },
      {
        nume: 'Măldăreşti',
        simplu: 'Maldaresti',
        comuna: 'Ladesti'
      },
      {
        nume: 'Olteanca',
        comuna: 'Ladesti'
      },
      {
        nume: 'Popeşti',
        simplu: 'Popesti',
        comuna: 'Ladesti'
      },
      {
        nume: 'Păsculeşti',
        simplu: 'Pasculesti',
        comuna: 'Ladesti'
      },
      {
        nume: 'Berbeşti',
        simplu: 'Berbesti',
        comuna: 'Lalosu'
      },
      {
        nume: 'Ghindari',
        comuna: 'Lalosu'
      },
      {
        nume: 'Mologeşti',
        simplu: 'Mologesti',
        comuna: 'Lalosu'
      },
      {
        nume: 'Olteţani',
        simplu: 'Oltetani',
        comuna: 'Lalosu'
      },
      {
        nume: 'Portăreşti',
        simplu: 'Portaresti',
        comuna: 'Lalosu'
      },
      {
        nume: 'Bereşti',
        simplu: 'Beresti',
        comuna: 'Lapusata'
      },
      {
        nume: 'Broşteni',
        simplu: 'Brosteni',
        comuna: 'Lapusata'
      },
      {
        nume: 'Mijaţi',
        simplu: 'Mijati',
        comuna: 'Lapusata'
      },
      {
        nume: 'Scoruşu',
        simplu: 'Scorusu',
        comuna: 'Lapusata'
      },
      {
        nume: 'Săruleşti',
        simplu: 'Sarulesti',
        comuna: 'Lapusata'
      },
      {
        nume: 'Zărneşti',
        simplu: 'Zarnesti',
        comuna: 'Lapusata'
      },
      {
        nume: 'Serbăneşti',
        simplu: 'Serbanesti',
        comuna: 'Lapusata'
      },
      {
        nume: 'Pleşoiu',
        simplu: 'Plesoiu',
        comuna: 'Livezi'
      },
      {
        nume: 'Pârâienii de Jos',
        simplu: 'Paraienii de Jos',
        comuna: 'Livezi'
      },
      {
        nume: 'Pârâienii de Mijloc',
        simplu: 'Paraienii de Mijloc',
        comuna: 'Livezi'
      },
      {
        nume: 'Pârâienii de Sus',
        simplu: 'Paraienii de Sus',
        comuna: 'Livezi'
      },
      {
        nume: 'Părăuşani',
        simplu: 'Parausani',
        comuna: 'Livezi'
      },
      {
        nume: 'Tina',
        comuna: 'Livezi'
      },
      {
        nume: 'Cărcădieşti',
        simplu: 'Carcadiesti',
        comuna: 'Lungesti'
      },
      {
        nume: 'Dumbrava',
        comuna: 'Lungesti'
      },
      {
        nume: 'Fumureni',
        comuna: 'Lungesti'
      },
      {
        nume: 'Gănţulei',
        simplu: 'Gantulei',
        comuna: 'Lungesti'
      },
      {
        nume: 'Stăneşti-Lunca',
        simplu: 'Stanesti-Lunca',
        comuna: 'Lungesti'
      },
      {
        nume: 'Bocşa',
        simplu: 'Bocsa',
        comuna: 'Maciuca'
      },
      {
        nume: 'Botorani',
        comuna: 'Maciuca'
      },
      {
        nume: 'Ciocănari',
        simplu: 'Ciocanari',
        comuna: 'Maciuca'
      },
      {
        nume: 'Măciuceni',
        simplu: 'Maciuceni',
        comuna: 'Maciuca'
      },
      {
        nume: 'Măldăreşti',
        simplu: 'Maldaresti',
        comuna: 'Maciuca'
      },
      {
        nume: 'Oveselu',
        comuna: 'Maciuca'
      },
      {
        nume: 'Popeşti',
        simplu: 'Popesti',
        comuna: 'Maciuca'
      },
      {
        nume: 'Zăvoieni',
        simplu: 'Zavoieni',
        comuna: 'Maciuca'
      },
      {
        nume: 'Stefăneşti',
        simplu: 'Stefanesti',
        comuna: 'Maciuca'
      },
      {
        nume: 'Bălşoara',
        simplu: 'Balsoara',
        comuna: 'Madulari'
      },
      {
        nume: 'Bănţeşti',
        simplu: 'Bantesti',
        comuna: 'Madulari'
      },
      {
        nume: 'Dimuleşti',
        simplu: 'Dimulesti',
        comuna: 'Madulari'
      },
      {
        nume: 'Iacovile',
        comuna: 'Madulari'
      },
      {
        nume: 'Mamu',
        comuna: 'Madulari'
      },
      {
        nume: 'Ciungetu',
        comuna: 'Malaia'
      },
      {
        nume: 'Săliştea',
        simplu: 'Salistea',
        comuna: 'Malaia'
      },
      {
        nume: 'Măldăreştii de Jos',
        simplu: 'Maldarestii de Jos',
        comuna: 'Maldaresti'
      },
      {
        nume: 'Roşoveni',
        simplu: 'Rosoveni',
        comuna: 'Maldaresti'
      },
      {
        nume: 'Telecheşti',
        simplu: 'Telechesti',
        comuna: 'Maldaresti'
      },
      {
        nume: 'Greci',
        comuna: 'Mateesti'
      },
      {
        nume: 'Turceşti',
        simplu: 'Turcesti',
        comuna: 'Mateesti'
      },
      {
        nume: 'Arsanca',
        comuna: 'Mihaesti'
      },
      {
        nume: 'Buleta',
        comuna: 'Mihaesti'
      },
      {
        nume: 'Bârseşti',
        simplu: 'Barsesti',
        comuna: 'Mihaesti'
      },
      {
        nume: 'Govora',
        comuna: 'Mihaesti'
      },
      {
        nume: 'Gurişoara',
        simplu: 'Gurisoara',
        comuna: 'Mihaesti'
      },
      {
        nume: 'Munteni',
        comuna: 'Mihaesti'
      },
      {
        nume: 'Măgura',
        simplu: 'Magura',
        comuna: 'Mihaesti'
      },
      {
        nume: 'Negreni',
        comuna: 'Mihaesti'
      },
      {
        nume: 'Rugetu',
        comuna: 'Mihaesti'
      },
      {
        nume: 'Scărişoara',
        simplu: 'Scarisoara',
        comuna: 'Mihaesti'
      },
      {
        nume: 'Stupărei',
        simplu: 'Stuparei',
        comuna: 'Mihaesti'
      },
      {
        nume: 'Vulpueşti',
        simplu: 'Vulpuesti',
        comuna: 'Mihaesti'
      },
      {
        nume: 'Ciuteşti',
        simplu: 'Ciutesti',
        comuna: 'Milcoiu'
      },
      {
        nume: 'Căzăneşti',
        simplu: 'Cazanesti',
        comuna: 'Milcoiu'
      },
      {
        nume: 'Izbăseşti',
        simplu: 'Izbasesti',
        comuna: 'Milcoiu'
      },
      {
        nume: 'Tepşenari',
        simplu: 'Tepsenari',
        comuna: 'Milcoiu'
      },
      {
        nume: 'Suricaru',
        simplu: 'Suricaru',
        comuna: 'Milcoiu'
      },
      {
        nume: 'Andreieşti',
        simplu: 'Andreiesti',
        comuna: 'Muereasca'
      },
      {
        nume: 'Frânceşti-Coasta',
        simplu: 'Francesti-Coasta',
        comuna: 'Muereasca'
      },
      {
        nume: 'Găvăneşti',
        simplu: 'Gavanesti',
        comuna: 'Muereasca'
      },
      {
        nume: 'Hotarele',
        comuna: 'Muereasca'
      },
      {
        nume: 'Muereasca de Sus',
        comuna: 'Muereasca'
      },
      {
        nume: 'Pripoara',
        comuna: 'Muereasca'
      },
      {
        nume: 'Suta',
        simplu: 'Suta',
        comuna: 'Muereasca'
      },
      {
        nume: 'Băneşti',
        simplu: 'Banesti',
        comuna: 'Nicolae Balcescu'
      },
      {
        nume: 'Corbii din Vale',
        comuna: 'Nicolae Balcescu'
      },
      {
        nume: 'Dosu Râului',
        simplu: 'Dosu Raului',
        comuna: 'Nicolae Balcescu'
      },
      {
        nume: 'Ginerica',
        comuna: 'Nicolae Balcescu'
      },
      {
        nume: 'Gâltofani',
        simplu: 'Galtofani',
        comuna: 'Nicolae Balcescu'
      },
      {
        nume: 'Linia Hanului',
        comuna: 'Nicolae Balcescu'
      },
      {
        nume: 'Mângureni',
        simplu: 'Mangureni',
        comuna: 'Nicolae Balcescu'
      },
      {
        nume: 'Măzăraru',
        simplu: 'Mazararu',
        comuna: 'Nicolae Balcescu'
      },
      {
        nume: 'Pleşoiu',
        simplu: 'Plesoiu',
        comuna: 'Nicolae Balcescu'
      },
      {
        nume: 'Popeşti',
        simplu: 'Popesti',
        comuna: 'Nicolae Balcescu'
      },
      {
        nume: 'Predeşti',
        simplu: 'Predesti',
        comuna: 'Nicolae Balcescu'
      },
      {
        nume: 'Rotărăşti',
        simplu: 'Rotarasti',
        comuna: 'Nicolae Balcescu'
      },
      {
        nume: 'Schitu',
        comuna: 'Nicolae Balcescu'
      },
      {
        nume: 'Tufanii',
        comuna: 'Nicolae Balcescu'
      },
      {
        nume: 'Valea Bălcească',
        simplu: 'Valea Balceasca',
        comuna: 'Nicolae Balcescu'
      },
      {
        nume: 'Valea Viei',
        comuna: 'Nicolae Balcescu'
      },
      {
        nume: 'Serbăneasa',
        simplu: 'Serbaneasa',
        comuna: 'Nicolae Balcescu'
      },
      {
        nume: 'Buda',
        comuna: 'Ocnele Mari'
      },
      {
        nume: 'Cosota',
        comuna: 'Ocnele Mari'
      },
      {
        nume: 'Făcăi',
        simplu: 'Facai',
        comuna: 'Ocnele Mari'
      },
      {
        nume: 'Gura Suhaşului',
        simplu: 'Gura Suhasului',
        comuna: 'Ocnele Mari'
      },
      {
        nume: 'Lunca',
        comuna: 'Ocnele Mari'
      },
      {
        nume: 'Ocniţa',
        simplu: 'Ocnita',
        comuna: 'Ocnele Mari'
      },
      {
        nume: 'Slătioarele',
        simplu: 'Slatioarele',
        comuna: 'Ocnele Mari'
      },
      {
        nume: 'Teica',
        simplu: 'Teica',
        comuna: 'Ocnele Mari'
      },
      {
        nume: 'Casa Veche',
        comuna: 'Olanu'
      },
      {
        nume: 'Cioboţi',
        simplu: 'Cioboti',
        comuna: 'Olanu'
      },
      {
        nume: 'Drăgioiu',
        simplu: 'Dragioiu',
        comuna: 'Olanu'
      },
      {
        nume: 'Nicoleşti',
        simplu: 'Nicolesti',
        comuna: 'Olanu'
      },
      {
        nume: 'Stoicăneşti',
        simplu: 'Stoicanesti',
        comuna: 'Olanu'
      },
      {
        nume: 'Aureşti',
        simplu: 'Auresti',
        comuna: 'Orlesti'
      },
      {
        nume: 'Procopoaia',
        comuna: 'Orlesti'
      },
      {
        nume: 'Scaioşi',
        simplu: 'Scaiosi',
        comuna: 'Orlesti'
      },
      {
        nume: 'Silea',
        comuna: 'Orlesti'
      },
      {
        nume: 'Bogdăneşti',
        simplu: 'Bogdanesti',
        comuna: 'Otesani'
      },
      {
        nume: 'Cuceşti',
        simplu: 'Cucesti',
        comuna: 'Otesani'
      },
      {
        nume: 'Cârstăneşti',
        simplu: 'Carstanesti',
        comuna: 'Otesani'
      },
      {
        nume: 'Sub Deal',
        comuna: 'Otesani'
      },
      {
        nume: 'Barcanele',
        comuna: 'Pausesti'
      },
      {
        nume: 'Buzdugan',
        comuna: 'Pausesti'
      },
      {
        nume: 'Cernelele',
        comuna: 'Pausesti'
      },
      {
        nume: 'Păuseşti-Otăsău',
        simplu: 'Pausesti-Otasau',
        comuna: 'Pausesti'
      },
      {
        nume: 'Văleni',
        simplu: 'Valeni',
        comuna: 'Pausesti'
      },
      {
        nume: 'Serbăneşti',
        simplu: 'Serbanesti',
        comuna: 'Pausesti'
      },
      {
        nume: 'Soliceşti',
        simplu: 'Solicesti',
        comuna: 'Pausesti'
      },
      {
        nume: 'Coasta',
        comuna: 'Pausesti-Maglasi'
      },
      {
        nume: 'Pietrari',
        comuna: 'Pausesti-Maglasi'
      },
      {
        nume: 'Ulmeţel',
        simplu: 'Ulmetel',
        comuna: 'Pausesti-Maglasi'
      },
      {
        nume: 'Valea Cheii',
        comuna: 'Pausesti-Maglasi'
      },
      {
        nume: 'Vlăduceni',
        simplu: 'Vladuceni',
        comuna: 'Pausesti-Maglasi'
      },
      {
        nume: 'Băiaşu',
        simplu: 'Baiasu',
        comuna: 'Perisani'
      },
      {
        nume: 'Mlăceni',
        simplu: 'Mlaceni',
        comuna: 'Perisani'
      },
      {
        nume: 'Podeni',
        comuna: 'Perisani'
      },
      {
        nume: 'Poiana',
        comuna: 'Perisani'
      },
      {
        nume: 'Pripoare',
        comuna: 'Perisani'
      },
      {
        nume: 'Spinu',
        comuna: 'Perisani'
      },
      {
        nume: 'Surdoiu',
        comuna: 'Perisani'
      },
      {
        nume: 'Cermegeşti',
        simplu: 'Cermegesti',
        comuna: 'Pesceana'
      },
      {
        nume: 'Lupoaia',
        comuna: 'Pesceana'
      },
      {
        nume: 'Negraia',
        comuna: 'Pesceana'
      },
      {
        nume: 'Roeşti',
        simplu: 'Roesti',
        comuna: 'Pesceana'
      },
      {
        nume: 'Ursoaia',
        comuna: 'Pesceana'
      },
      {
        nume: 'Pietrarii de Sus',
        comuna: 'Pietrari'
      },
      {
        nume: 'Curtea',
        comuna: 'Popesti'
      },
      {
        nume: 'Dăeşti',
        simplu: 'Daesti',
        comuna: 'Popesti'
      },
      {
        nume: 'Firijba',
        comuna: 'Popesti'
      },
      {
        nume: 'Meieni',
        comuna: 'Popesti'
      },
      {
        nume: 'Urşi',
        simplu: 'Ursi',
        comuna: 'Popesti'
      },
      {
        nume: 'Valea Caselor',
        comuna: 'Popesti'
      },
      {
        nume: 'Bărbuceni',
        simplu: 'Barbuceni',
        comuna: 'Prundeni'
      },
      {
        nume: 'Călina',
        simplu: 'Calina',
        comuna: 'Prundeni'
      },
      {
        nume: 'Zăvideni',
        simplu: 'Zavideni',
        comuna: 'Prundeni'
      },
      {
        nume: 'Balota',
        comuna: 'Racovita'
      },
      {
        nume: 'Blănoiu',
        simplu: 'Blanoiu',
        comuna: 'Racovita'
      },
      {
        nume: 'Bradu-Clocotici',
        comuna: 'Racovita'
      },
      {
        nume: 'Copăceni',
        simplu: 'Copaceni',
        comuna: 'Racovita'
      },
      {
        nume: 'Gruiu Lupului',
        comuna: 'Racovita'
      },
      {
        nume: 'Tuţuleşti',
        simplu: 'Tutulesti',
        comuna: 'Racovita'
      },
      {
        nume: 'Aranghel',
        comuna: 'Ramnicu Valcea'
      },
      {
        nume: 'Copăcelu',
        simplu: 'Copacelu',
        comuna: 'Ramnicu Valcea'
      },
      {
        nume: 'Căzăneşti',
        simplu: 'Cazanesti',
        comuna: 'Ramnicu Valcea'
      },
      {
        nume: 'Dealu Malului',
        comuna: 'Ramnicu Valcea'
      },
      {
        nume: 'Feţeni',
        simplu: 'Feteni',
        comuna: 'Ramnicu Valcea'
      },
      {
        nume: 'Goranu',
        comuna: 'Ramnicu Valcea'
      },
      {
        nume: 'Lespezi',
        comuna: 'Ramnicu Valcea'
      },
      {
        nume: 'Poenari',
        comuna: 'Ramnicu Valcea'
      },
      {
        nume: 'Priba',
        comuna: 'Ramnicu Valcea'
      },
      {
        nume: 'Râureni',
        simplu: 'Raureni',
        comuna: 'Ramnicu Valcea'
      },
      {
        nume: 'Stolniceni',
        comuna: 'Ramnicu Valcea'
      },
      {
        nume: 'Săliştea',
        simplu: 'Salistea',
        comuna: 'Ramnicu Valcea'
      },
      {
        nume: 'Troian',
        comuna: 'Ramnicu Valcea'
      },
      {
        nume: 'Băiaşa',
        simplu: 'Baiasa',
        comuna: 'Roesti'
      },
      {
        nume: 'Băjenari',
        simplu: 'Bajenari',
        comuna: 'Roesti'
      },
      {
        nume: 'Bărbărigeni',
        simplu: 'Barbarigeni',
        comuna: 'Roesti'
      },
      {
        nume: 'Ciocâltei',
        simplu: 'Ciocaltei',
        comuna: 'Roesti'
      },
      {
        nume: 'Cueni',
        comuna: 'Roesti'
      },
      {
        nume: 'Frăsina',
        simplu: 'Frasina',
        comuna: 'Roesti'
      },
      {
        nume: 'Piscu Scoarţei',
        simplu: 'Piscu Scoartei',
        comuna: 'Roesti'
      },
      {
        nume: 'Râpa Cărămizii',
        simplu: 'Rapa Caramizii',
        comuna: 'Roesti'
      },
      {
        nume: 'Saioci',
        comuna: 'Roesti'
      },
      {
        nume: 'Balaciu',
        comuna: 'Rosiile'
      },
      {
        nume: 'Cherăşti',
        simplu: 'Cherasti',
        comuna: 'Rosiile'
      },
      {
        nume: 'Hotăroaia',
        simplu: 'Hotaroaia',
        comuna: 'Rosiile'
      },
      {
        nume: 'Lupuieşti',
        simplu: 'Lupuiesti',
        comuna: 'Rosiile'
      },
      {
        nume: 'Perteşti',
        simplu: 'Pertesti',
        comuna: 'Rosiile'
      },
      {
        nume: 'Pleseşti',
        simplu: 'Plesesti',
        comuna: 'Rosiile'
      },
      {
        nume: 'Păsărei',
        simplu: 'Pasarei',
        comuna: 'Rosiile'
      },
      {
        nume: 'Româneşti',
        simplu: 'Romanesti',
        comuna: 'Rosiile'
      },
      {
        nume: 'Răţăleşti',
        simplu: 'Ratalesti',
        comuna: 'Rosiile'
      },
      {
        nume: 'Zgubea',
        comuna: 'Rosiile'
      },
      {
        nume: 'Căligi',
        simplu: 'Caligi',
        comuna: 'Runcu'
      },
      {
        nume: 'Gropeni',
        comuna: 'Runcu'
      },
      {
        nume: 'Snamăna',
        simplu: 'Snamana',
        comuna: 'Runcu'
      },
      {
        nume: 'Surpaţi',
        simplu: 'Surpati',
        comuna: 'Runcu'
      },
      {
        nume: 'Valea Babei',
        comuna: 'Runcu'
      },
      {
        nume: 'Vărateci',
        simplu: 'Varateci',
        comuna: 'Runcu'
      },
      {
        nume: 'Păteşti',
        simplu: 'Patesti',
        comuna: 'Salatrucel'
      },
      {
        nume: 'Seaca',
        comuna: 'Salatrucel'
      },
      {
        nume: 'Serbăneşti',
        simplu: 'Serbanesti',
        comuna: 'Salatrucel'
      },
      {
        nume: 'Avrămeşti',
        simplu: 'Avramesti',
        comuna: 'Scundu'
      },
      {
        nume: 'Blejani',
        comuna: 'Scundu'
      },
      {
        nume: 'Crângu',
        simplu: 'Crangu',
        comuna: 'Scundu'
      },
      {
        nume: 'Ciucheţi',
        simplu: 'Ciucheti',
        comuna: 'Sinesti'
      },
      {
        nume: 'Dealu Bisericii',
        comuna: 'Sinesti'
      },
      {
        nume: 'Mijlocu',
        comuna: 'Sinesti'
      },
      {
        nume: 'Popeşti',
        simplu: 'Popesti',
        comuna: 'Sinesti'
      },
      {
        nume: 'Urzica',
        comuna: 'Sinesti'
      },
      {
        nume: 'Aricioaia',
        comuna: 'Sirineasa'
      },
      {
        nume: 'Ciorăşti',
        simplu: 'Ciorasti',
        comuna: 'Sirineasa'
      },
      {
        nume: 'Slăviteşti',
        simplu: 'Slavitesti',
        comuna: 'Sirineasa'
      },
      {
        nume: 'Valea Alunişului',
        simplu: 'Valea Alunisului',
        comuna: 'Sirineasa'
      },
      {
        nume: 'Coasta Cerbului',
        comuna: 'Slatioara'
      },
      {
        nume: 'Goruneşti',
        simplu: 'Gorunesti',
        comuna: 'Slatioara'
      },
      {
        nume: 'Milostea',
        comuna: 'Slatioara'
      },
      {
        nume: 'Mogeşti',
        simplu: 'Mogesti',
        comuna: 'Slatioara'
      },
      {
        nume: 'Rugetu',
        comuna: 'Slatioara'
      },
      {
        nume: 'Bărcăneşti',
        simplu: 'Barcanesti',
        comuna: 'Stanesti'
      },
      {
        nume: 'Cioponeşti',
        simplu: 'Cioponesti',
        comuna: 'Stanesti'
      },
      {
        nume: 'Cuculeşti',
        simplu: 'Cuculesti',
        comuna: 'Stanesti'
      },
      {
        nume: 'Gârnicetu',
        simplu: 'Garnicetu',
        comuna: 'Stanesti'
      },
      {
        nume: 'Linia Dealului',
        comuna: 'Stanesti'
      },
      {
        nume: 'Suieşti',
        simplu: 'Suiesti',
        comuna: 'Stanesti'
      },
      {
        nume: 'Valea Lungă',
        simplu: 'Valea Lunga',
        comuna: 'Stanesti'
      },
      {
        nume: 'Vârleni',
        simplu: 'Varleni',
        comuna: 'Stanesti'
      },
      {
        nume: 'Condoieşti',
        simplu: 'Condoiesti',
        comuna: 'Stefanesti'
      },
      {
        nume: 'Dobruşa',
        simplu: 'Dobrusa',
        comuna: 'Stefanesti'
      },
      {
        nume: 'Serbăneşti',
        simplu: 'Serbanesti',
        comuna: 'Stefanesti'
      },
      {
        nume: 'Budurăşti',
        simplu: 'Budurasti',
        comuna: 'Stoenesti'
      },
      {
        nume: 'Bârlogu',
        simplu: 'Barlogu',
        comuna: 'Stoenesti'
      },
      {
        nume: 'Deleni',
        comuna: 'Stoenesti'
      },
      {
        nume: 'Dobriceni',
        comuna: 'Stoenesti'
      },
      {
        nume: 'Gruieri',
        comuna: 'Stoenesti'
      },
      {
        nume: 'Gruiu',
        comuna: 'Stoenesti'
      },
      {
        nume: 'Mogoşeşti',
        simplu: 'Mogosesti',
        comuna: 'Stoenesti'
      },
      {
        nume: 'Neghineşti',
        simplu: 'Neghinesti',
        comuna: 'Stoenesti'
      },
      {
        nume: 'Piscu Mare',
        comuna: 'Stoenesti'
      },
      {
        nume: 'Popeşti',
        simplu: 'Popesti',
        comuna: 'Stoenesti'
      },
      {
        nume: 'Suseni',
        comuna: 'Stoenesti'
      },
      {
        nume: 'Zmeurătu',
        simplu: 'Zmeuratu',
        comuna: 'Stoenesti'
      },
      {
        nume: 'Balomireasa',
        comuna: 'Stoilesti'
      },
      {
        nume: 'Bulagei',
        comuna: 'Stoilesti'
      },
      {
        nume: 'Bârsoiu',
        simplu: 'Barsoiu',
        comuna: 'Stoilesti'
      },
      {
        nume: 'Delureni',
        comuna: 'Stoilesti'
      },
      {
        nume: 'Geamăna',
        simplu: 'Geamana',
        comuna: 'Stoilesti'
      },
      {
        nume: 'Ghiobeşti',
        simplu: 'Ghiobesti',
        comuna: 'Stoilesti'
      },
      {
        nume: 'Giuroiu',
        comuna: 'Stoilesti'
      },
      {
        nume: 'Izvoru Rece',
        comuna: 'Stoilesti'
      },
      {
        nume: 'Malu',
        comuna: 'Stoilesti'
      },
      {
        nume: 'Neţeşti',
        simplu: 'Netesti',
        comuna: 'Stoilesti'
      },
      {
        nume: 'Obogeni',
        comuna: 'Stoilesti'
      },
      {
        nume: 'Stăneşti',
        simplu: 'Stanesti',
        comuna: 'Stoilesti'
      },
      {
        nume: 'Urşi',
        simplu: 'Ursi',
        comuna: 'Stoilesti'
      },
      {
        nume: 'Vlăduleşti',
        simplu: 'Vladulesti',
        comuna: 'Stoilesti'
      },
      {
        nume: 'Cireşu',
        simplu: 'Ciresu',
        comuna: 'Stroesti'
      },
      {
        nume: 'Dianu',
        comuna: 'Stroesti'
      },
      {
        nume: 'Obroceşti',
        simplu: 'Obrocesti',
        comuna: 'Stroesti'
      },
      {
        nume: 'Pojogi-Cerna',
        comuna: 'Stroesti'
      },
      {
        nume: 'Râmeşti',
        simplu: 'Ramesti',
        comuna: 'Susani'
      },
      {
        nume: 'Stoiculeşti',
        simplu: 'Stoiculesti',
        comuna: 'Susani'
      },
      {
        nume: 'Sârbi',
        simplu: 'Sarbi',
        comuna: 'Susani'
      },
      {
        nume: 'Uşurei',
        simplu: 'Usurei',
        comuna: 'Susani'
      },
      {
        nume: 'Boroşeşti',
        simplu: 'Borosesti',
        comuna: 'Sutesti'
      },
      {
        nume: 'Cetăţeaua',
        simplu: 'Cetateaua',
        comuna: 'Sutesti'
      },
      {
        nume: 'Izvoraşu',
        simplu: 'Izvorasu',
        comuna: 'Sutesti'
      },
      {
        nume: 'Mitrofani',
        comuna: 'Sutesti'
      },
      {
        nume: 'Măzili',
        simplu: 'Mazili',
        comuna: 'Sutesti'
      },
      {
        nume: 'Racu',
        comuna: 'Sutesti'
      },
      {
        nume: 'Verdea',
        comuna: 'Sutesti'
      },
      {
        nume: 'Budele',
        comuna: 'Tetoiu'
      },
      {
        nume: 'Băroiu',
        simplu: 'Baroiu',
        comuna: 'Tetoiu'
      },
      {
        nume: 'Măneasa',
        simplu: 'Maneasa',
        comuna: 'Tetoiu'
      },
      {
        nume: 'Nenciuleşti',
        simplu: 'Nenciulesti',
        comuna: 'Tetoiu'
      },
      {
        nume: 'Popeşti',
        simplu: 'Popesti',
        comuna: 'Tetoiu'
      },
      {
        nume: 'Tepeşti',
        simplu: 'Tepesti',
        comuna: 'Tetoiu'
      },
      {
        nume: 'Bratoveşti',
        simplu: 'Bratovesti',
        comuna: 'Titesti'
      },
      {
        nume: 'Cucoiu',
        comuna: 'Titesti'
      },
      {
        nume: 'Bogdăneşti',
        simplu: 'Bogdanesti',
        comuna: 'Tomsani'
      },
      {
        nume: 'Băltăţeni',
        simplu: 'Baltateni',
        comuna: 'Tomsani'
      },
      {
        nume: 'Chiceni',
        comuna: 'Tomsani'
      },
      {
        nume: 'Dumbrăveşti',
        simplu: 'Dumbravesti',
        comuna: 'Tomsani'
      },
      {
        nume: 'Foleştii de Jos',
        simplu: 'Folestii de Jos',
        comuna: 'Tomsani'
      },
      {
        nume: 'Foleştii de Sus',
        simplu: 'Folestii de Sus',
        comuna: 'Tomsani'
      },
      {
        nume: 'Mireşti',
        simplu: 'Miresti',
        comuna: 'Tomsani'
      },
      {
        nume: 'Cerna',
        comuna: 'Vaideeni'
      },
      {
        nume: 'Cornet',
        comuna: 'Vaideeni'
      },
      {
        nume: 'Izvoru Rece',
        comuna: 'Vaideeni'
      },
      {
        nume: 'Mariţa',
        simplu: 'Marita',
        comuna: 'Vaideeni'
      },
      {
        nume: 'Bătăşani',
        simplu: 'Batasani',
        comuna: 'Valea Mare'
      },
      {
        nume: 'Delureni',
        comuna: 'Valea Mare'
      },
      {
        nume: 'Drăganu',
        simplu: 'Draganu',
        comuna: 'Valea Mare'
      },
      {
        nume: 'Mărgineni',
        simplu: 'Margineni',
        comuna: 'Valea Mare'
      },
      {
        nume: 'Pietroasa',
        comuna: 'Valea Mare'
      },
      {
        nume: 'Fundătura',
        simplu: 'Fundatura',
        comuna: 'Vladesti'
      },
      {
        nume: 'Pleaşa',
        simplu: 'Pleasa',
        comuna: 'Vladesti'
      },
      {
        nume: 'Priporu',
        comuna: 'Vladesti'
      },
      {
        nume: 'Trundin',
        comuna: 'Vladesti'
      },
      {
        nume: 'Tighina',
        comuna: 'Voicesti'
      },
      {
        nume: 'Voiceştii din Vale',
        simplu: 'Voicestii din Vale',
        comuna: 'Voicesti'
      },
      {
        nume: 'Valea Măceşului',
        simplu: 'Valea Macesului',
        comuna: 'Voineasa'
      },
      {
        nume: 'Voineşiţa',
        simplu: 'Voinesita',
        comuna: 'Voineasa'
      },
      {
        nume: 'Butanu',
        comuna: 'Zatreni'
      },
      {
        nume: 'Ciorteşti',
        simplu: 'Ciortesti',
        comuna: 'Zatreni'
      },
      {
        nume: 'Contea',
        comuna: 'Zatreni'
      },
      {
        nume: 'Dealu Glămeia',
        simplu: 'Dealu Glameia',
        comuna: 'Zatreni'
      },
      {
        nume: 'Dealu Văleni',
        simplu: 'Dealu Valeni',
        comuna: 'Zatreni'
      },
      {
        nume: 'Făureşti',
        simplu: 'Fauresti',
        comuna: 'Zatreni'
      },
      {
        nume: 'Găneşti',
        simplu: 'Ganesti',
        comuna: 'Zatreni'
      },
      {
        nume: 'Lăcusteni',
        simplu: 'Lacusteni',
        comuna: 'Zatreni'
      },
      {
        nume: 'Lăcustenii de Jos',
        simplu: 'Lacustenii de Jos',
        comuna: 'Zatreni'
      },
      {
        nume: 'Lăcustenii de Sus',
        simplu: 'Lacustenii de Sus',
        comuna: 'Zatreni'
      },
      {
        nume: 'Mecea',
        comuna: 'Zatreni'
      },
      {
        nume: 'Mănicea',
        simplu: 'Manicea',
        comuna: 'Zatreni'
      },
      {
        nume: 'Olteţu',
        simplu: 'Oltetu',
        comuna: 'Zatreni'
      },
      {
        nume: 'Stanomiru',
        comuna: 'Zatreni'
      },
      {
        nume: 'Săşcioara',
        simplu: 'Sascioara',
        comuna: 'Zatreni'
      },
      {
        nume: 'Valea Văleni',
        simplu: 'Valea Valeni',
        comuna: 'Zatreni'
      },
      {
        nume: 'Văleni',
        simplu: 'Valeni',
        comuna: 'Zatreni'
      },
      {
        nume: 'Zătrenii de Sus',
        simplu: 'Zatrenii de Sus',
        comuna: 'Zatreni'
      }
    ]
  },
  {
    auto: 'VS',
    nume: 'Vaslui',
    localitati: [
      {
        nume: 'Albeşti',
        simplu: 'Albesti'
      },
      {
        nume: 'Alexandru Vlahuţă',
        simplu: 'Alexandru Vlahuta'
      },
      {
        nume: 'Arsura'
      },
      {
        nume: 'Banca'
      },
      {
        nume: 'Berezeni'
      },
      {
        nume: 'Blăgeşti',
        simplu: 'Blagesti'
      },
      {
        nume: 'Bogdana'
      },
      {
        nume: 'Bogdăneşti',
        simplu: 'Bogdanesti'
      },
      {
        nume: 'Bogdăniţa',
        simplu: 'Bogdanita'
      },
      {
        nume: 'Boţeşti',
        simplu: 'Botesti'
      },
      {
        nume: 'Băcani',
        simplu: 'Bacani'
      },
      {
        nume: 'Băceşti',
        simplu: 'Bacesti'
      },
      {
        nume: 'Bălteni',
        simplu: 'Balteni'
      },
      {
        nume: 'Codăeşti',
        simplu: 'Codaesti'
      },
      {
        nume: 'Coroieşti',
        simplu: 'Coroiesti'
      },
      {
        nume: 'Costeşti',
        simplu: 'Costesti'
      },
      {
        nume: 'Creţeşti',
        simplu: 'Cretesti'
      },
      {
        nume: 'Deleni'
      },
      {
        nume: 'Deleşti',
        simplu: 'Delesti'
      },
      {
        nume: 'Dragomireşti',
        simplu: 'Dragomiresti'
      },
      {
        nume: 'Drânceni',
        simplu: 'Dranceni'
      },
      {
        nume: 'Dumeşti',
        simplu: 'Dumesti'
      },
      {
        nume: 'Dăneşti',
        simplu: 'Danesti'
      },
      {
        nume: 'Epureni'
      },
      {
        nume: 'Fălciu',
        simplu: 'Falciu'
      },
      {
        nume: 'Ghergheşti',
        simplu: 'Gherghesti'
      },
      {
        nume: 'Griviţa',
        simplu: 'Grivita'
      },
      {
        nume: 'Gârceni',
        simplu: 'Garceni'
      },
      {
        nume: 'Găgeşti',
        simplu: 'Gagesti'
      },
      {
        nume: 'Hoceni'
      },
      {
        nume: 'Huşi',
        simplu: 'Husi'
      },
      {
        nume: 'Iana'
      },
      {
        nume: 'Ibăneşti',
        simplu: 'Ibanesti'
      },
      {
        nume: 'Iveşti',
        simplu: 'Ivesti'
      },
      {
        nume: 'Ivăneşti',
        simplu: 'Ivanesti'
      },
      {
        nume: 'Laza'
      },
      {
        nume: 'Lipovăţ',
        simplu: 'Lipovat'
      },
      {
        nume: 'Lunca Banului'
      },
      {
        nume: 'Micleşti',
        simplu: 'Miclesti'
      },
      {
        nume: 'Muntenii de Jos'
      },
      {
        nume: 'Murgeni'
      },
      {
        nume: 'Măluşteni',
        simplu: 'Malusteni'
      },
      {
        nume: 'Negreşti',
        simplu: 'Negresti'
      },
      {
        nume: 'Olteneşti',
        simplu: 'Oltenesti'
      },
      {
        nume: 'Oşeşti',
        simplu: 'Osesti'
      },
      {
        nume: 'Perieni'
      },
      {
        nume: 'Pogana'
      },
      {
        nume: 'Poieneşti',
        simplu: 'Poienesti'
      },
      {
        nume: 'Puieşti',
        simplu: 'Puiesti'
      },
      {
        nume: 'Pungeşti',
        simplu: 'Pungesti'
      },
      {
        nume: 'Pădureni',
        simplu: 'Padureni'
      },
      {
        nume: 'Rebricea'
      },
      {
        nume: 'Roşieşti',
        simplu: 'Rosiesti'
      },
      {
        nume: 'Soleşti',
        simplu: 'Solesti'
      },
      {
        nume: 'Stănileşti',
        simplu: 'Stanilesti'
      },
      {
        nume: 'Tanacu'
      },
      {
        nume: 'Todireşti',
        simplu: 'Todiresti'
      },
      {
        nume: 'Tutova'
      },
      {
        nume: 'Tăcuta',
        simplu: 'Tacuta'
      },
      {
        nume: 'Tătărăni',
        simplu: 'Tatarani'
      },
      {
        nume: 'Vaslui'
      },
      {
        nume: 'Vetrişoaia',
        simplu: 'Vetrisoaia'
      },
      {
        nume: 'Viişoara',
        simplu: 'Viisoara'
      },
      {
        nume: 'Vinderei'
      },
      {
        nume: 'Voineşti',
        simplu: 'Voinesti'
      },
      {
        nume: 'Vultureşti',
        simplu: 'Vulturesti'
      },
      {
        nume: 'Vutcani'
      },
      {
        nume: 'Văleni',
        simplu: 'Valeni'
      },
      {
        nume: 'Zorleni'
      },
      {
        nume: 'Zăpodeni',
        simplu: 'Zapodeni'
      },
      {
        nume: 'Stefan cel Mare',
        simplu: 'Stefan cel Mare'
      },
      {
        nume: 'Suletea',
        simplu: 'Suletea'
      },
      {
        nume: 'Corni-Albeşti',
        simplu: 'Corni-Albesti',
        comuna: 'Albesti'
      },
      {
        nume: 'Crasna',
        comuna: 'Albesti'
      },
      {
        nume: 'Gura Albeşti',
        simplu: 'Gura Albesti',
        comuna: 'Albesti'
      },
      {
        nume: 'Buda',
        comuna: 'Alexandru Vlahuta'
      },
      {
        nume: 'Ghicani',
        comuna: 'Alexandru Vlahuta'
      },
      {
        nume: 'Morăreni',
        simplu: 'Morareni',
        comuna: 'Alexandru Vlahuta'
      },
      {
        nume: 'Fundătura',
        simplu: 'Fundatura',
        comuna: 'Arsura'
      },
      {
        nume: 'Mihail Kogălniceanu',
        simplu: 'Mihail Kogalniceanu',
        comuna: 'Arsura'
      },
      {
        nume: 'Pâhneşti',
        simplu: 'Pahnesti',
        comuna: 'Arsura'
      },
      {
        nume: 'Băltăţeni',
        simplu: 'Baltateni',
        comuna: 'Bacani'
      },
      {
        nume: 'Drujeşti',
        simplu: 'Drujesti',
        comuna: 'Bacani'
      },
      {
        nume: 'Suseni',
        comuna: 'Bacani'
      },
      {
        nume: 'Vulpăşeni',
        simplu: 'Vulpaseni',
        comuna: 'Bacani'
      },
      {
        nume: 'Armăşeni',
        simplu: 'Armaseni',
        comuna: 'Bacesti'
      },
      {
        nume: 'Băbuşa',
        simplu: 'Babusa',
        comuna: 'Bacesti'
      },
      {
        nume: 'Păltiniş',
        simplu: 'Paltinis',
        comuna: 'Bacesti'
      },
      {
        nume: 'Vovrieşti',
        simplu: 'Vovriesti',
        comuna: 'Bacesti'
      },
      {
        nume: 'Tibăneştii Buhlii',
        simplu: 'Tibanestii Buhlii',
        comuna: 'Bacesti'
      },
      {
        nume: 'Bălteni-Deal',
        simplu: 'Balteni-Deal',
        comuna: 'Balteni'
      },
      {
        nume: 'Chetreşti',
        simplu: 'Chetresti',
        comuna: 'Balteni'
      },
      {
        nume: '1 Decembrie',
        comuna: 'Banca'
      },
      {
        nume: 'Gara Banca',
        comuna: 'Banca'
      },
      {
        nume: 'Ghermăneşti',
        simplu: 'Ghermanesti',
        comuna: 'Banca'
      },
      {
        nume: 'Micleşti',
        simplu: 'Miclesti',
        comuna: 'Banca'
      },
      {
        nume: 'Mitoc',
        comuna: 'Banca'
      },
      {
        nume: 'Satu Nou',
        comuna: 'Banca'
      },
      {
        nume: 'Stoişeşti',
        simplu: 'Stoisesti',
        comuna: 'Banca'
      },
      {
        nume: 'Strâmtura-Mitoc',
        simplu: 'Stramtura-Mitoc',
        comuna: 'Banca'
      },
      {
        nume: 'Sârbi',
        simplu: 'Sarbi',
        comuna: 'Banca'
      },
      {
        nume: 'Sălcioara',
        simplu: 'Salcioara',
        comuna: 'Banca'
      },
      {
        nume: 'Tifu',
        simplu: 'Tifu',
        comuna: 'Banca'
      },
      {
        nume: 'Muşata',
        simplu: 'Musata',
        comuna: 'Berezeni'
      },
      {
        nume: 'Rânceni',
        simplu: 'Ranceni',
        comuna: 'Berezeni'
      },
      {
        nume: 'Satu Nou',
        comuna: 'Berezeni'
      },
      {
        nume: 'Stuhuleţ',
        simplu: 'Stuhulet',
        comuna: 'Berezeni'
      },
      {
        nume: 'Bârlad',
        simplu: 'Barlad',
        comuna: 'Birlad'
      },
      {
        nume: 'Igeşti',
        simplu: 'Igesti',
        comuna: 'Blagesti'
      },
      {
        nume: 'Sipeni',
        comuna: 'Blagesti'
      },
      {
        nume: 'Arşiţa',
        simplu: 'Arsita',
        comuna: 'Bogdana'
      },
      {
        nume: 'Fântâna Blănarului',
        simplu: 'Fantana Blanarului',
        comuna: 'Bogdana'
      },
      {
        nume: 'Găvanu',
        simplu: 'Gavanu',
        comuna: 'Bogdana'
      },
      {
        nume: 'Lacu Babei',
        comuna: 'Bogdana'
      },
      {
        nume: 'Plopeni',
        comuna: 'Bogdana'
      },
      {
        nume: 'Similişoara',
        simplu: 'Similisoara',
        comuna: 'Bogdana'
      },
      {
        nume: 'Suceveni',
        comuna: 'Bogdana'
      },
      {
        nume: 'Verdeş',
        simplu: 'Verdes',
        comuna: 'Bogdana'
      },
      {
        nume: 'Buda',
        comuna: 'Bogdanesti'
      },
      {
        nume: 'Horoiata',
        comuna: 'Bogdanesti'
      },
      {
        nume: 'Hupca',
        comuna: 'Bogdanesti'
      },
      {
        nume: 'Orgoieşti',
        simplu: 'Orgoiesti',
        comuna: 'Bogdanesti'
      },
      {
        nume: 'Ulea',
        comuna: 'Bogdanesti'
      },
      {
        nume: 'Unţeşti',
        simplu: 'Untesti',
        comuna: 'Bogdanesti'
      },
      {
        nume: 'Vişinari',
        simplu: 'Visinari',
        comuna: 'Bogdanesti'
      },
      {
        nume: 'Vlădeşti',
        simplu: 'Vladesti',
        comuna: 'Bogdanesti'
      },
      {
        nume: 'Cepeşti',
        simplu: 'Cepesti',
        comuna: 'Bogdanita'
      },
      {
        nume: 'Coroieşti',
        simplu: 'Coroiesti',
        comuna: 'Bogdanita'
      },
      {
        nume: 'Cârţibaşi',
        simplu: 'Cartibasi',
        comuna: 'Bogdanita'
      },
      {
        nume: 'Rădăeşti',
        simplu: 'Radaesti',
        comuna: 'Bogdanita'
      },
      {
        nume: 'Schitu',
        comuna: 'Bogdanita'
      },
      {
        nume: 'Tunseşti',
        simplu: 'Tunsesti',
        comuna: 'Bogdanita'
      },
      {
        nume: 'Gugeşti',
        simplu: 'Gugesti',
        comuna: 'Botesti'
      },
      {
        nume: 'Găneşti',
        simplu: 'Ganesti',
        comuna: 'Botesti'
      },
      {
        nume: 'Tălpigeni',
        simplu: 'Talpigeni',
        comuna: 'Botesti'
      },
      {
        nume: 'Armăşeni',
        simplu: 'Armaseni',
        comuna: 'Bunesti-Averesti'
      },
      {
        nume: 'Avereşti',
        simplu: 'Averesti',
        comuna: 'Bunesti-Averesti'
      },
      {
        nume: 'Buneşti',
        simplu: 'Bunesti',
        comuna: 'Bunesti-Averesti'
      },
      {
        nume: 'Plopi',
        comuna: 'Bunesti-Averesti'
      },
      {
        nume: 'Podu Oprii',
        comuna: 'Bunesti-Averesti'
      },
      {
        nume: 'Roşiori',
        simplu: 'Rosiori',
        comuna: 'Bunesti-Averesti'
      },
      {
        nume: 'Tăbălăeşti',
        simplu: 'Tabalaesti',
        comuna: 'Bunesti-Averesti'
      },
      {
        nume: 'Ghergheleu',
        comuna: 'Codaesti'
      },
      {
        nume: 'Pribeşti',
        simplu: 'Pribesti',
        comuna: 'Codaesti'
      },
      {
        nume: 'Rediu Galian',
        comuna: 'Codaesti'
      },
      {
        nume: 'Chilieni',
        comuna: 'Coroiesti'
      },
      {
        nume: 'Coroieştii de Sus',
        simplu: 'Coroiestii de Sus',
        comuna: 'Coroiesti'
      },
      {
        nume: 'Hreasca',
        comuna: 'Coroiesti'
      },
      {
        nume: 'Mireni',
        comuna: 'Coroiesti'
      },
      {
        nume: 'Movileni',
        comuna: 'Coroiesti'
      },
      {
        nume: 'Păcurăreşti',
        simplu: 'Pacuraresti',
        comuna: 'Coroiesti'
      },
      {
        nume: 'Chiţcani',
        simplu: 'Chitcani',
        comuna: 'Costesti'
      },
      {
        nume: 'Dinga',
        comuna: 'Costesti'
      },
      {
        nume: 'Puntişeni',
        simplu: 'Puntiseni',
        comuna: 'Costesti'
      },
      {
        nume: 'Pârveşti',
        simplu: 'Parvesti',
        comuna: 'Costesti'
      },
      {
        nume: 'Rădeşti',
        simplu: 'Radesti',
        comuna: 'Costesti'
      },
      {
        nume: 'Budeşti',
        simplu: 'Budesti',
        comuna: 'Cretesti'
      },
      {
        nume: 'Creţeştii de Sus',
        simplu: 'Cretestii de Sus',
        comuna: 'Cretesti'
      },
      {
        nume: 'Satu Nou',
        comuna: 'Cretesti'
      },
      {
        nume: 'Bereasa',
        comuna: 'Danesti'
      },
      {
        nume: 'Boţoaia',
        simplu: 'Botoaia',
        comuna: 'Danesti'
      },
      {
        nume: 'Emil Racoviţă',
        simplu: 'Emil Racovita',
        comuna: 'Danesti'
      },
      {
        nume: 'Răşcani',
        simplu: 'Rascani',
        comuna: 'Danesti'
      },
      {
        nume: 'Tătărăni',
        simplu: 'Tatarani',
        comuna: 'Danesti'
      },
      {
        nume: 'Bulboaca',
        comuna: 'Deleni'
      },
      {
        nume: 'Moreni',
        comuna: 'Deleni'
      },
      {
        nume: 'Zizinca',
        comuna: 'Deleni'
      },
      {
        nume: 'Albeşti',
        simplu: 'Albesti',
        comuna: 'Delesti'
      },
      {
        nume: 'Băleşti',
        simplu: 'Balesti',
        comuna: 'Delesti'
      },
      {
        nume: 'Cozmeşti',
        simplu: 'Cozmesti',
        comuna: 'Delesti'
      },
      {
        nume: 'Fundătura',
        simplu: 'Fundatura',
        comuna: 'Delesti'
      },
      {
        nume: 'Fâstâci',
        simplu: 'Fastaci',
        comuna: 'Delesti'
      },
      {
        nume: 'Hordileşti',
        simplu: 'Hordilesti',
        comuna: 'Delesti'
      },
      {
        nume: 'Hârsova',
        simplu: 'Harsova',
        comuna: 'Delesti'
      },
      {
        nume: 'Mânăstirea',
        simplu: 'Manastirea',
        comuna: 'Delesti'
      },
      {
        nume: 'Răduieşti',
        simplu: 'Raduiesti',
        comuna: 'Delesti'
      },
      {
        nume: 'Grumezoaia',
        comuna: 'Dimitrie Cantemir'
      },
      {
        nume: 'Guşiţei',
        simplu: 'Gusitei',
        comuna: 'Dimitrie Cantemir'
      },
      {
        nume: 'Hurdugi',
        comuna: 'Dimitrie Cantemir'
      },
      {
        nume: 'Plotoneşti',
        simplu: 'Plotonesti',
        comuna: 'Dimitrie Cantemir'
      },
      {
        nume: 'Urlaţi',
        simplu: 'Urlati',
        comuna: 'Dimitrie Cantemir'
      },
      {
        nume: 'Belzeni',
        comuna: 'Dragomiresti'
      },
      {
        nume: 'Botoi',
        comuna: 'Dragomiresti'
      },
      {
        nume: 'Băbuţa',
        simplu: 'Babuta',
        comuna: 'Dragomiresti'
      },
      {
        nume: 'Ciuperca',
        comuna: 'Dragomiresti'
      },
      {
        nume: 'Doagele',
        comuna: 'Dragomiresti'
      },
      {
        nume: 'Poiana Pietrei',
        comuna: 'Dragomiresti'
      },
      {
        nume: 'Popeşti',
        simplu: 'Popesti',
        comuna: 'Dragomiresti'
      },
      {
        nume: 'Rădeni',
        simplu: 'Radeni',
        comuna: 'Dragomiresti'
      },
      {
        nume: 'Semenea',
        comuna: 'Dragomiresti'
      },
      {
        nume: 'Tuleşti',
        simplu: 'Tulesti',
        comuna: 'Dragomiresti'
      },
      {
        nume: 'Vladia',
        comuna: 'Dragomiresti'
      },
      {
        nume: 'Albiţa',
        simplu: 'Albita',
        comuna: 'Dranceni'
      },
      {
        nume: 'Băile Drânceni',
        simplu: 'Baile Dranceni',
        comuna: 'Dranceni'
      },
      {
        nume: 'Ghermăneşti',
        simplu: 'Ghermanesti',
        comuna: 'Dranceni'
      },
      {
        nume: 'Râşeşti',
        simplu: 'Rasesti',
        comuna: 'Dranceni'
      },
      {
        nume: 'Sopârleni',
        simplu: 'Soparleni',
        comuna: 'Dranceni'
      },
      {
        nume: 'Bobeşti',
        simplu: 'Bobesti',
        comuna: 'Duda-Epureni'
      },
      {
        nume: 'Duda',
        comuna: 'Duda-Epureni'
      },
      {
        nume: 'Epureni',
        comuna: 'Duda-Epureni'
      },
      {
        nume: 'Valea Grecului',
        comuna: 'Duda-Epureni'
      },
      {
        nume: 'Dumeştii Vechi',
        simplu: 'Dumestii Vechi',
        comuna: 'Dumesti'
      },
      {
        nume: 'Schinetea',
        comuna: 'Dumesti'
      },
      {
        nume: 'Valea Mare',
        comuna: 'Dumesti'
      },
      {
        nume: 'Bursuci',
        comuna: 'Epureni'
      },
      {
        nume: 'Bârlăleşti',
        simplu: 'Barlalesti',
        comuna: 'Epureni'
      },
      {
        nume: 'Horga',
        comuna: 'Epureni'
      },
      {
        nume: 'Bogdăneşti',
        simplu: 'Bogdanesti',
        comuna: 'Falciu'
      },
      {
        nume: 'Bozia',
        comuna: 'Falciu'
      },
      {
        nume: 'Copăceana',
        simplu: 'Copaceana',
        comuna: 'Falciu'
      },
      {
        nume: 'Odaia Bogdana',
        comuna: 'Falciu'
      },
      {
        nume: 'Rânzeşti',
        simplu: 'Ranzesti',
        comuna: 'Falciu'
      },
      {
        nume: 'Giurcani',
        comuna: 'Gagesti'
      },
      {
        nume: 'Peicani',
        comuna: 'Gagesti'
      },
      {
        nume: 'Popeni',
        comuna: 'Gagesti'
      },
      {
        nume: 'Tupilaţi',
        simplu: 'Tupilati',
        comuna: 'Gagesti'
      },
      {
        nume: 'Dumbrăveni',
        simplu: 'Dumbraveni',
        comuna: 'Garceni'
      },
      {
        nume: 'Racova',
        comuna: 'Garceni'
      },
      {
        nume: 'Racoviţa',
        simplu: 'Racovita',
        comuna: 'Garceni'
      },
      {
        nume: 'Slobozia',
        comuna: 'Garceni'
      },
      {
        nume: 'Trohan',
        comuna: 'Garceni'
      },
      {
        nume: 'Chetrosu',
        comuna: 'Gherghesti'
      },
      {
        nume: 'Corodeşti',
        simplu: 'Corodesti',
        comuna: 'Gherghesti'
      },
      {
        nume: 'Dragomăneşti',
        simplu: 'Dragomanesti',
        comuna: 'Gherghesti'
      },
      {
        nume: 'Drăxeni',
        simplu: 'Draxeni',
        comuna: 'Gherghesti'
      },
      {
        nume: 'Lazu',
        comuna: 'Gherghesti'
      },
      {
        nume: 'Lunca',
        comuna: 'Gherghesti'
      },
      {
        nume: 'Soci',
        comuna: 'Gherghesti'
      },
      {
        nume: 'Valea Lupului',
        comuna: 'Gherghesti'
      },
      {
        nume: 'Fruntişeni',
        simplu: 'Fruntiseni',
        comuna: 'Grivita'
      },
      {
        nume: 'Grăjdeni',
        simplu: 'Grajdeni',
        comuna: 'Grivita'
      },
      {
        nume: 'Odaia Bursucani',
        comuna: 'Grivita'
      },
      {
        nume: 'Trestiana',
        comuna: 'Grivita'
      },
      {
        nume: 'Barboşi',
        simplu: 'Barbosi',
        comuna: 'Hoceni'
      },
      {
        nume: 'Deleni',
        comuna: 'Hoceni'
      },
      {
        nume: 'Oţeleni',
        simplu: 'Oteleni',
        comuna: 'Hoceni'
      },
      {
        nume: 'Rediu',
        comuna: 'Hoceni'
      },
      {
        nume: 'Tomşa',
        simplu: 'Tomsa',
        comuna: 'Hoceni'
      },
      {
        nume: 'Sişcani',
        simplu: 'Siscani',
        comuna: 'Hoceni'
      },
      {
        nume: 'Hălăreşti',
        simplu: 'Halaresti',
        comuna: 'Iana'
      },
      {
        nume: 'Recea',
        comuna: 'Iana'
      },
      {
        nume: 'Siliştea',
        simplu: 'Silistea',
        comuna: 'Iana'
      },
      {
        nume: 'Vadurile',
        comuna: 'Iana'
      },
      {
        nume: 'Mânzaţi',
        simplu: 'Manzati',
        comuna: 'Ibanesti'
      },
      {
        nume: 'Puţu Olarului',
        simplu: 'Putu Olarului',
        comuna: 'Ibanesti'
      },
      {
        nume: 'Albina',
        comuna: 'Ivanesti'
      },
      {
        nume: 'Bleşca',
        simplu: 'Blesca',
        comuna: 'Ivanesti'
      },
      {
        nume: 'Broşteni',
        simplu: 'Brosteni',
        comuna: 'Ivanesti'
      },
      {
        nume: 'Buscata',
        comuna: 'Ivanesti'
      },
      {
        nume: 'Coşca',
        simplu: 'Cosca',
        comuna: 'Ivanesti'
      },
      {
        nume: 'Coşeşti',
        simplu: 'Cosesti',
        comuna: 'Ivanesti'
      },
      {
        nume: 'Fundătura Mare',
        simplu: 'Fundatura Mare',
        comuna: 'Ivanesti'
      },
      {
        nume: 'Fundătura Mică',
        simplu: 'Fundatura Mica',
        comuna: 'Ivanesti'
      },
      {
        nume: 'Hârşoveni',
        simplu: 'Harsoveni',
        comuna: 'Ivanesti'
      },
      {
        nume: 'Iezerel',
        comuna: 'Ivanesti'
      },
      {
        nume: 'Ursoaia',
        comuna: 'Ivanesti'
      },
      {
        nume: 'Valea Mare',
        comuna: 'Ivanesti'
      },
      {
        nume: 'Valea Oanei',
        comuna: 'Ivanesti'
      },
      {
        nume: 'Belceşti',
        simplu: 'Belcesti',
        comuna: 'Ivesti'
      },
      {
        nume: 'Pogoneşti',
        simplu: 'Pogonesti',
        comuna: 'Ivesti'
      },
      {
        nume: 'Polocin',
        comuna: 'Ivesti'
      },
      {
        nume: 'Bejeneşti',
        simplu: 'Bejenesti',
        comuna: 'Laza'
      },
      {
        nume: 'Poiana lui Alexa',
        comuna: 'Laza'
      },
      {
        nume: 'Puşcaşi',
        simplu: 'Puscasi',
        comuna: 'Laza'
      },
      {
        nume: 'Râşniţa',
        simplu: 'Rasnita',
        comuna: 'Laza'
      },
      {
        nume: 'Sauca',
        comuna: 'Laza'
      },
      {
        nume: 'Teişoru',
        simplu: 'Teisoru',
        comuna: 'Laza'
      },
      {
        nume: 'Valea Târgului',
        simplu: 'Valea Targului',
        comuna: 'Laza'
      },
      {
        nume: 'Chiţoc',
        simplu: 'Chitoc',
        comuna: 'Lipovat'
      },
      {
        nume: 'Corbu',
        comuna: 'Lipovat'
      },
      {
        nume: 'Căpuşneni',
        simplu: 'Capusneni',
        comuna: 'Lipovat'
      },
      {
        nume: 'Fundu Văii',
        simplu: 'Fundu Vaii',
        comuna: 'Lipovat'
      },
      {
        nume: 'Broscoşeşti',
        simplu: 'Broscosesti',
        comuna: 'Lunca Banului'
      },
      {
        nume: 'Condrea',
        comuna: 'Lunca Banului'
      },
      {
        nume: 'Focşa',
        simplu: 'Focsa',
        comuna: 'Lunca Banului'
      },
      {
        nume: 'Lunca Veche',
        comuna: 'Lunca Banului'
      },
      {
        nume: 'Oţetoaia',
        simplu: 'Otetoaia',
        comuna: 'Lunca Banului'
      },
      {
        nume: 'Răducani',
        simplu: 'Raducani',
        comuna: 'Lunca Banului'
      },
      {
        nume: 'Ghireasca',
        comuna: 'Malusteni'
      },
      {
        nume: 'Lupeşti',
        simplu: 'Lupesti',
        comuna: 'Malusteni'
      },
      {
        nume: 'Mânzăteşti',
        simplu: 'Manzatesti',
        comuna: 'Malusteni'
      },
      {
        nume: 'Mânăstirea',
        simplu: 'Manastirea',
        comuna: 'Malusteni'
      },
      {
        nume: 'Tuţcani',
        simplu: 'Tutcani',
        comuna: 'Malusteni'
      },
      {
        nume: 'Chirceşti',
        simplu: 'Chircesti',
        comuna: 'Miclesti'
      },
      {
        nume: 'Popeşti',
        simplu: 'Popesti',
        comuna: 'Miclesti'
      },
      {
        nume: 'Băcăoani',
        simplu: 'Bacaoani',
        comuna: 'Muntenii De Jos'
      },
      {
        nume: 'Mânjeşti',
        simplu: 'Manjesti',
        comuna: 'Muntenii De Jos'
      },
      {
        nume: 'Secuia',
        comuna: 'Muntenii De Jos'
      },
      {
        nume: 'Cârja',
        simplu: 'Carja',
        comuna: 'Murgeni'
      },
      {
        nume: 'Floreni',
        comuna: 'Murgeni'
      },
      {
        nume: 'Lăţeşti',
        simplu: 'Latesti',
        comuna: 'Murgeni'
      },
      {
        nume: 'Raiu',
        comuna: 'Murgeni'
      },
      {
        nume: 'Schineni',
        comuna: 'Murgeni'
      },
      {
        nume: 'Sărăţeni',
        simplu: 'Sarateni',
        comuna: 'Murgeni'
      },
      {
        nume: 'Cioatele',
        comuna: 'Negresti'
      },
      {
        nume: 'Căzăneşti',
        simplu: 'Cazanesti',
        comuna: 'Negresti'
      },
      {
        nume: 'Glodeni',
        comuna: 'Negresti'
      },
      {
        nume: 'Parpaniţa',
        simplu: 'Parpanita',
        comuna: 'Negresti'
      },
      {
        nume: 'Poiana',
        comuna: 'Negresti'
      },
      {
        nume: 'Valea Mare',
        comuna: 'Negresti'
      },
      {
        nume: 'Curteni',
        comuna: 'Oltenesti'
      },
      {
        nume: 'Pâhna',
        simplu: 'Pahna',
        comuna: 'Oltenesti'
      },
      {
        nume: 'Târzii',
        simplu: 'Tarzii',
        comuna: 'Oltenesti'
      },
      {
        nume: 'Vineţeşti',
        simplu: 'Vinetesti',
        comuna: 'Oltenesti'
      },
      {
        nume: 'Zgura',
        comuna: 'Oltenesti'
      },
      {
        nume: 'Buda',
        comuna: 'Osesti'
      },
      {
        nume: 'Pădureni',
        simplu: 'Padureni',
        comuna: 'Osesti'
      },
      {
        nume: 'Vâlcele',
        simplu: 'Valcele',
        comuna: 'Osesti'
      },
      {
        nume: 'Căpoteşti',
        simplu: 'Capotesti',
        comuna: 'Padureni'
      },
      {
        nume: 'Davideşti',
        simplu: 'Davidesti',
        comuna: 'Padureni'
      },
      {
        nume: 'Ivăneşti',
        simplu: 'Ivanesti',
        comuna: 'Padureni'
      },
      {
        nume: 'Leoşti',
        simplu: 'Leosti',
        comuna: 'Padureni'
      },
      {
        nume: 'Rusca',
        comuna: 'Padureni'
      },
      {
        nume: 'Todireni',
        comuna: 'Padureni'
      },
      {
        nume: 'Văleni',
        simplu: 'Valeni',
        comuna: 'Padureni'
      },
      {
        nume: 'Ciocani',
        comuna: 'Perieni'
      },
      {
        nume: 'Crâng',
        simplu: 'Crang',
        comuna: 'Perieni'
      },
      {
        nume: 'Crângu Nou',
        simplu: 'Crangu Nou',
        comuna: 'Perieni'
      },
      {
        nume: 'Podu Petriş',
        simplu: 'Podu Petris',
        comuna: 'Perieni'
      },
      {
        nume: 'Bogeşti',
        simplu: 'Bogesti',
        comuna: 'Pogana'
      },
      {
        nume: 'Cârjoani',
        simplu: 'Carjoani',
        comuna: 'Pogana'
      },
      {
        nume: 'Măscurei',
        simplu: 'Mascurei',
        comuna: 'Pogana'
      },
      {
        nume: 'Tomeşti',
        simplu: 'Tomesti',
        comuna: 'Pogana'
      },
      {
        nume: 'Dealu Secării',
        simplu: 'Dealu Secarii',
        comuna: 'Poienesti'
      },
      {
        nume: 'Floreşti',
        simplu: 'Floresti',
        comuna: 'Poienesti'
      },
      {
        nume: 'Frasinu',
        comuna: 'Poienesti'
      },
      {
        nume: 'Fundu Văii',
        simplu: 'Fundu Vaii',
        comuna: 'Poienesti'
      },
      {
        nume: 'Oprişiţa',
        simplu: 'Oprisita',
        comuna: 'Poienesti'
      },
      {
        nume: 'Poieneşti-Deal',
        simplu: 'Poienesti-Deal',
        comuna: 'Poienesti'
      },
      {
        nume: 'Bărtăluş-Mocani',
        simplu: 'Bartalus-Mocani',
        comuna: 'Puiesti'
      },
      {
        nume: 'Bărtăluş-Răzeşi',
        simplu: 'Bartalus-Razesi',
        comuna: 'Puiesti'
      },
      {
        nume: 'Cetăţuia',
        simplu: 'Cetatuia',
        comuna: 'Puiesti'
      },
      {
        nume: 'Cristeşti',
        simplu: 'Cristesti',
        comuna: 'Puiesti'
      },
      {
        nume: 'Călimăneşti',
        simplu: 'Calimanesti',
        comuna: 'Puiesti'
      },
      {
        nume: 'Fulgu',
        comuna: 'Puiesti'
      },
      {
        nume: 'Fântânele',
        simplu: 'Fantanele',
        comuna: 'Puiesti'
      },
      {
        nume: 'Gâlţeşti',
        simplu: 'Galtesti',
        comuna: 'Puiesti'
      },
      {
        nume: 'Iezer',
        comuna: 'Puiesti'
      },
      {
        nume: 'Lăleşti',
        simplu: 'Lalesti',
        comuna: 'Puiesti'
      },
      {
        nume: 'Rotari',
        comuna: 'Puiesti'
      },
      {
        nume: 'Ruşi',
        simplu: 'Rusi',
        comuna: 'Puiesti'
      },
      {
        nume: 'Armăşoaia',
        simplu: 'Armasoaia',
        comuna: 'Pungesti'
      },
      {
        nume: 'Curseşti-Deal',
        simplu: 'Cursesti-Deal',
        comuna: 'Pungesti'
      },
      {
        nume: 'Curseşti-Vale',
        simplu: 'Cursesti-Vale',
        comuna: 'Pungesti'
      },
      {
        nume: 'Hordila',
        comuna: 'Pungesti'
      },
      {
        nume: 'Rapşa',
        simplu: 'Rapsa',
        comuna: 'Pungesti'
      },
      {
        nume: 'Siliştea',
        simplu: 'Silistea',
        comuna: 'Pungesti'
      },
      {
        nume: 'Stejaru',
        comuna: 'Pungesti'
      },
      {
        nume: 'Toporăşti',
        simplu: 'Toporasti',
        comuna: 'Pungesti'
      },
      {
        nume: 'Bolaţi',
        simplu: 'Bolati',
        comuna: 'Rebricea'
      },
      {
        nume: 'Crăciuneşti',
        simplu: 'Craciunesti',
        comuna: 'Rebricea'
      },
      {
        nume: 'Draxeni',
        comuna: 'Rebricea'
      },
      {
        nume: 'Măcreşti',
        simplu: 'Macresti',
        comuna: 'Rebricea'
      },
      {
        nume: 'Rateşu Cuzei',
        simplu: 'Ratesu Cuzei',
        comuna: 'Rebricea'
      },
      {
        nume: 'Sasova',
        comuna: 'Rebricea'
      },
      {
        nume: 'Tatomireşti',
        simplu: 'Tatomiresti',
        comuna: 'Rebricea'
      },
      {
        nume: 'Tufeştii de Jos',
        simplu: 'Tufestii de Jos',
        comuna: 'Rebricea'
      },
      {
        nume: 'Codreni',
        comuna: 'Rosiesti'
      },
      {
        nume: 'Gara Roşieşti',
        simplu: 'Gara Rosiesti',
        comuna: 'Rosiesti'
      },
      {
        nume: 'Gura Idrici',
        comuna: 'Rosiesti'
      },
      {
        nume: 'Idrici',
        comuna: 'Rosiesti'
      },
      {
        nume: 'Rediu',
        comuna: 'Rosiesti'
      },
      {
        nume: 'Valea lui Darie',
        comuna: 'Rosiesti'
      },
      {
        nume: 'Bouşori',
        simplu: 'Bousori',
        comuna: 'Solesti'
      },
      {
        nume: 'Iaz',
        comuna: 'Solesti'
      },
      {
        nume: 'Satu Nou',
        comuna: 'Solesti'
      },
      {
        nume: 'Valea Siliştei',
        simplu: 'Valea Silistei',
        comuna: 'Solesti'
      },
      {
        nume: 'Serboteşti',
        simplu: 'Serbotesti',
        comuna: 'Solesti'
      },
      {
        nume: 'Stioborăni',
        simplu: 'Stioborani',
        comuna: 'Solesti'
      },
      {
        nume: 'Bogdana-Voloseni',
        comuna: 'Stanilesti'
      },
      {
        nume: 'Budu Cantemir',
        comuna: 'Stanilesti'
      },
      {
        nume: 'Chersăcosu',
        simplu: 'Chersacosu',
        comuna: 'Stanilesti'
      },
      {
        nume: 'Gura Văii',
        simplu: 'Gura Vaii',
        comuna: 'Stanilesti'
      },
      {
        nume: 'Pogăneşti',
        simplu: 'Poganesti',
        comuna: 'Stanilesti'
      },
      {
        nume: 'Săratu',
        simplu: 'Saratu',
        comuna: 'Stanilesti'
      },
      {
        nume: 'Brăhăşoaia',
        simplu: 'Brahasoaia',
        comuna: 'Stefan Cel Mare'
      },
      {
        nume: 'Bârzeşti',
        simplu: 'Barzesti',
        comuna: 'Stefan Cel Mare'
      },
      {
        nume: 'Călugăreni',
        simplu: 'Calugareni',
        comuna: 'Stefan Cel Mare'
      },
      {
        nume: 'Cănţălăreşti',
        simplu: 'Cantalaresti',
        comuna: 'Stefan Cel Mare'
      },
      {
        nume: 'Munteneşti',
        simplu: 'Muntenesti',
        comuna: 'Stefan Cel Mare'
      },
      {
        nume: 'Mărăşeni',
        simplu: 'Maraseni',
        comuna: 'Stefan Cel Mare'
      },
      {
        nume: 'Fedeşti',
        simplu: 'Fedesti',
        comuna: 'Suletea'
      },
      {
        nume: 'Jigălia',
        simplu: 'Jigalia',
        comuna: 'Suletea'
      },
      {
        nume: 'Răşcani',
        simplu: 'Rascani',
        comuna: 'Suletea'
      },
      {
        nume: 'Cujba',
        comuna: 'Tacuta'
      },
      {
        nume: 'Dumasca',
        comuna: 'Tacuta'
      },
      {
        nume: 'Focşeaşca',
        simplu: 'Focseasca',
        comuna: 'Tacuta'
      },
      {
        nume: 'Mirceşti',
        simplu: 'Mircesti',
        comuna: 'Tacuta'
      },
      {
        nume: 'Protopopeşti',
        simplu: 'Protopopesti',
        comuna: 'Tacuta'
      },
      {
        nume: 'Sofieni',
        comuna: 'Tacuta'
      },
      {
        nume: 'Beneşti',
        simplu: 'Benesti',
        comuna: 'Tanacu'
      },
      {
        nume: 'Muntenii de Sus',
        comuna: 'Tanacu'
      },
      {
        nume: 'Satu Nou',
        comuna: 'Tanacu'
      },
      {
        nume: 'Bălţaţi',
        simplu: 'Baltati',
        comuna: 'Tatarani'
      },
      {
        nume: 'Crăsnăşeni',
        simplu: 'Crasnaseni',
        comuna: 'Tatarani'
      },
      {
        nume: 'Giurgeşti',
        simplu: 'Giurgesti',
        comuna: 'Tatarani'
      },
      {
        nume: 'Leoşti',
        simplu: 'Leosti',
        comuna: 'Tatarani'
      },
      {
        nume: 'Mantu',
        comuna: 'Tatarani'
      },
      {
        nume: 'Stroieşti',
        simplu: 'Stroiesti',
        comuna: 'Tatarani'
      },
      {
        nume: 'Valea Seacă',
        simplu: 'Valea Seaca',
        comuna: 'Tatarani'
      },
      {
        nume: 'Valea lui Bosie',
        comuna: 'Tatarani'
      },
      {
        nume: 'Cotic',
        comuna: 'Todiresti'
      },
      {
        nume: 'Drăgeşti',
        simplu: 'Dragesti',
        comuna: 'Todiresti'
      },
      {
        nume: 'Huc',
        comuna: 'Todiresti'
      },
      {
        nume: 'Plopoasa',
        comuna: 'Todiresti'
      },
      {
        nume: 'Rafaila',
        comuna: 'Todiresti'
      },
      {
        nume: 'Siliştea',
        simplu: 'Silistea',
        comuna: 'Todiresti'
      },
      {
        nume: 'Sofroneşti',
        simplu: 'Sofronesti',
        comuna: 'Todiresti'
      },
      {
        nume: 'Valea Popii',
        comuna: 'Todiresti'
      },
      {
        nume: 'Viişoara',
        simplu: 'Viisoara',
        comuna: 'Todiresti'
      },
      {
        nume: 'Borodeşti',
        simplu: 'Borodesti',
        comuna: 'Tutova'
      },
      {
        nume: 'Bădeana',
        simplu: 'Badeana',
        comuna: 'Tutova'
      },
      {
        nume: 'Ciortolom',
        comuna: 'Tutova'
      },
      {
        nume: 'Coroiu',
        comuna: 'Tutova'
      },
      {
        nume: 'Criveşti',
        simplu: 'Crivesti',
        comuna: 'Tutova'
      },
      {
        nume: 'Pochidia',
        comuna: 'Tutova'
      },
      {
        nume: 'Satu Nou',
        comuna: 'Tutova'
      },
      {
        nume: 'Sălceni',
        simplu: 'Salceni',
        comuna: 'Tutova'
      },
      {
        nume: 'Vizureni',
        comuna: 'Tutova'
      },
      {
        nume: 'Fereşti',
        simplu: 'Feresti',
        comuna: 'Valeni'
      },
      {
        nume: 'Moara Domnească',
        simplu: 'Moara Domneasca',
        comuna: 'Valeni'
      },
      {
        nume: 'Bahnari',
        comuna: 'Vaslui'
      },
      {
        nume: 'Brodoc',
        comuna: 'Vaslui'
      },
      {
        nume: 'Moara Grecilor',
        comuna: 'Vaslui'
      },
      {
        nume: 'Rediu',
        comuna: 'Vaslui'
      },
      {
        nume: 'Viişoara',
        simplu: 'Viisoara',
        comuna: 'Vaslui'
      },
      {
        nume: 'Bumbăta',
        simplu: 'Bumbata',
        comuna: 'Vetrisoaia'
      },
      {
        nume: 'Dodeşti',
        simplu: 'Dodesti',
        comuna: 'Viisoara'
      },
      {
        nume: 'Halta Dodeşti',
        simplu: 'Halta Dodesti',
        comuna: 'Viisoara'
      },
      {
        nume: 'Urdeşti',
        simplu: 'Urdesti',
        comuna: 'Viisoara'
      },
      {
        nume: 'Viltoteşti',
        simplu: 'Viltotesti',
        comuna: 'Viisoara'
      },
      {
        nume: 'Văleni',
        simplu: 'Valeni',
        comuna: 'Viisoara'
      },
      {
        nume: 'Brădeşti',
        simplu: 'Bradesti',
        comuna: 'Vinderei'
      },
      {
        nume: 'Docani',
        comuna: 'Vinderei'
      },
      {
        nume: 'Docăneasa',
        simplu: 'Docaneasa',
        comuna: 'Vinderei'
      },
      {
        nume: 'Gara Docăneasa',
        simplu: 'Gara Docaneasa',
        comuna: 'Vinderei'
      },
      {
        nume: 'Gara Tălăşman',
        simplu: 'Gara Talasman',
        comuna: 'Vinderei'
      },
      {
        nume: 'Obârşeni',
        simplu: 'Obarseni',
        comuna: 'Vinderei'
      },
      {
        nume: 'Valea Lungă',
        simplu: 'Valea Lunga',
        comuna: 'Vinderei'
      },
      {
        nume: 'Avrămeşti',
        simplu: 'Avramesti',
        comuna: 'Voinesti'
      },
      {
        nume: 'Bănceşti',
        simplu: 'Bancesti',
        comuna: 'Voinesti'
      },
      {
        nume: 'Corobăneşti',
        simplu: 'Corobanesti',
        comuna: 'Voinesti'
      },
      {
        nume: 'Gârdeşti',
        simplu: 'Gardesti',
        comuna: 'Voinesti'
      },
      {
        nume: 'Mărăşeşti',
        simplu: 'Marasesti',
        comuna: 'Voinesti'
      },
      {
        nume: 'Obârşeni',
        simplu: 'Obarseni',
        comuna: 'Voinesti'
      },
      {
        nume: 'Obârşenii Lingurari',
        simplu: 'Obarsenii Lingurari',
        comuna: 'Voinesti'
      },
      {
        nume: 'Rugăria',
        simplu: 'Rugaria',
        comuna: 'Voinesti'
      },
      {
        nume: 'Stâncăşeni',
        simplu: 'Stancaseni',
        comuna: 'Voinesti'
      },
      {
        nume: 'Uricari',
        comuna: 'Voinesti'
      },
      {
        nume: 'Buhăieşti',
        simplu: 'Buhaiesti',
        comuna: 'Vulturesti'
      },
      {
        nume: 'Podeni',
        comuna: 'Vulturesti'
      },
      {
        nume: 'Voineşti',
        simplu: 'Voinesti',
        comuna: 'Vulturesti'
      },
      {
        nume: 'Mălăieşti',
        simplu: 'Malaiesti',
        comuna: 'Vutcani'
      },
      {
        nume: 'Poşta Elan',
        simplu: 'Posta Elan',
        comuna: 'Vutcani'
      },
      {
        nume: 'Butucăria',
        simplu: 'Butucaria',
        comuna: 'Zapodeni'
      },
      {
        nume: 'Ciofeni',
        comuna: 'Zapodeni'
      },
      {
        nume: 'Delea',
        comuna: 'Zapodeni'
      },
      {
        nume: 'Dobrosloveşti',
        simplu: 'Dobroslovesti',
        comuna: 'Zapodeni'
      },
      {
        nume: 'Măcreşti',
        simplu: 'Macresti',
        comuna: 'Zapodeni'
      },
      {
        nume: 'Portari',
        comuna: 'Zapodeni'
      },
      {
        nume: 'Telejna',
        comuna: 'Zapodeni'
      },
      {
        nume: 'Unceşti',
        simplu: 'Uncesti',
        comuna: 'Zapodeni'
      },
      {
        nume: 'Dealu Mare',
        comuna: 'Zorleni'
      },
      {
        nume: 'Popeni',
        comuna: 'Zorleni'
      },
      {
        nume: 'Simila',
        comuna: 'Zorleni'
      }
    ]
  },
  {
    auto: 'VN',
    nume: 'Vrancea',
    localitati: [
      {
        nume: 'Adjud'
      },
      {
        nume: 'Andreiaşu de Jos',
        simplu: 'Andreiasu de Jos'
      },
      {
        nume: 'Bogheşti',
        simplu: 'Boghesti'
      },
      {
        nume: 'Boloteşti',
        simplu: 'Bolotesti'
      },
      {
        nume: 'Bordeşti',
        simplu: 'Bordesti'
      },
      {
        nume: 'Broşteni',
        simplu: 'Brosteni'
      },
      {
        nume: 'Bârseşti',
        simplu: 'Barsesti'
      },
      {
        nume: 'Băleşti',
        simplu: 'Balesti'
      },
      {
        nume: 'Chiojdeni'
      },
      {
        nume: 'Ciorăşti',
        simplu: 'Ciorasti'
      },
      {
        nume: 'Corbiţa',
        simplu: 'Corbita'
      },
      {
        nume: 'Coteşti',
        simplu: 'Cotesti'
      },
      {
        nume: 'Câmpineanca',
        simplu: 'Campineanca'
      },
      {
        nume: 'Câmpuri',
        simplu: 'Campuri'
      },
      {
        nume: 'Cârligele',
        simplu: 'Carligele'
      },
      {
        nume: 'Dumbrăveni',
        simplu: 'Dumbraveni'
      },
      {
        nume: 'Dumitreşti',
        simplu: 'Dumitresti'
      },
      {
        nume: 'Fitioneşti',
        simplu: 'Fitionesti'
      },
      {
        nume: 'Focşani',
        simplu: 'Focsani'
      },
      {
        nume: 'Garoafa'
      },
      {
        nume: 'Goleşti',
        simplu: 'Golesti'
      },
      {
        nume: 'Gugeşti',
        simplu: 'Gugesti'
      },
      {
        nume: 'Gura Caliţei',
        simplu: 'Gura Calitei'
      },
      {
        nume: 'Homocea'
      },
      {
        nume: 'Jariştea',
        simplu: 'Jaristea'
      },
      {
        nume: 'Jitia'
      },
      {
        nume: 'Mera'
      },
      {
        nume: 'Milcovul'
      },
      {
        nume: 'Moviliţa',
        simplu: 'Movilita'
      },
      {
        nume: 'Măicăneşti',
        simplu: 'Maicanesti'
      },
      {
        nume: 'Mărăşeşti',
        simplu: 'Marasesti'
      },
      {
        nume: 'Negrileşti',
        simplu: 'Negrilesti'
      },
      {
        nume: 'Nereju'
      },
      {
        nume: 'Nistoreşti',
        simplu: 'Nistoresti'
      },
      {
        nume: 'Năneşti',
        simplu: 'Nanesti'
      },
      {
        nume: 'Năruja',
        simplu: 'Naruja'
      },
      {
        nume: 'Odobeşti',
        simplu: 'Odobesti'
      },
      {
        nume: 'Paltin'
      },
      {
        nume: 'Panciu'
      },
      {
        nume: 'Ploscuţeni',
        simplu: 'Ploscuteni'
      },
      {
        nume: 'Poiana Cristei'
      },
      {
        nume: 'Popeşti',
        simplu: 'Popesti'
      },
      {
        nume: 'Pufeşti',
        simplu: 'Pufesti'
      },
      {
        nume: 'Păuleşti',
        simplu: 'Paulesti'
      },
      {
        nume: 'Păuneşti',
        simplu: 'Paunesti'
      },
      {
        nume: 'Reghiu'
      },
      {
        nume: 'Rugineşti',
        simplu: 'Ruginesti'
      },
      {
        nume: 'Răcoasa',
        simplu: 'Racoasa'
      },
      {
        nume: 'Sihlea'
      },
      {
        nume: 'Slobozia Bradului'
      },
      {
        nume: 'Slobozia Ciorăşti',
        simplu: 'Slobozia Ciorasti'
      },
      {
        nume: 'Străoane',
        simplu: 'Straoane'
      },
      {
        nume: 'Suraia'
      },
      {
        nume: 'Tulnici'
      },
      {
        nume: 'Tâmboeşti',
        simplu: 'Tamboesti'
      },
      {
        nume: 'Tănăsoaia',
        simplu: 'Tanasoaia'
      },
      {
        nume: 'Tătăranu',
        simplu: 'Tataranu'
      },
      {
        nume: 'Urecheşti',
        simplu: 'Urechesti'
      },
      {
        nume: 'Valea Sării',
        simplu: 'Valea Sarii'
      },
      {
        nume: 'Vidra'
      },
      {
        nume: 'Vintileasca'
      },
      {
        nume: 'Vrâncioaia',
        simplu: 'Vrancioaia'
      },
      {
        nume: 'Vulturu'
      },
      {
        nume: 'Vânători',
        simplu: 'Vanatori'
      },
      {
        nume: 'Vârteşcoiu',
        simplu: 'Vartescoiu'
      },
      {
        nume: 'Tifeşti',
        simplu: 'Tifesti'
      },
      {
        nume: 'Adjudu Vechi',
        comuna: 'Adjud'
      },
      {
        nume: 'Burcioaia',
        comuna: 'Adjud'
      },
      {
        nume: 'Sişcani',
        simplu: 'Siscani',
        comuna: 'Adjud'
      },
      {
        nume: 'Andreiaşu de Sus',
        simplu: 'Andreiasu de Sus',
        comuna: 'Andreiasu De Jos'
      },
      {
        nume: 'Arşiţa',
        simplu: 'Arsita',
        comuna: 'Andreiasu De Jos'
      },
      {
        nume: 'Fetig',
        comuna: 'Andreiasu De Jos'
      },
      {
        nume: 'Hotaru',
        comuna: 'Andreiasu De Jos'
      },
      {
        nume: 'Răchitaşu',
        simplu: 'Rachitasu',
        comuna: 'Andreiasu De Jos'
      },
      {
        nume: 'Titila',
        comuna: 'Andreiasu De Jos'
      },
      {
        nume: 'Topeşti',
        simplu: 'Topesti',
        comuna: 'Barsesti'
      },
      {
        nume: 'Bicheşti',
        simplu: 'Bichesti',
        comuna: 'Boghesti'
      },
      {
        nume: 'Bogheştii de Sus',
        simplu: 'Boghestii de Sus',
        comuna: 'Boghesti'
      },
      {
        nume: 'Chiţcani',
        simplu: 'Chitcani',
        comuna: 'Boghesti'
      },
      {
        nume: 'Iugani',
        comuna: 'Boghesti'
      },
      {
        nume: 'Pleşeşti',
        simplu: 'Plesesti',
        comuna: 'Boghesti'
      },
      {
        nume: 'Plăcinţeni',
        simplu: 'Placinteni',
        comuna: 'Boghesti'
      },
      {
        nume: 'Prisecani',
        comuna: 'Boghesti'
      },
      {
        nume: 'Tăbuceşti',
        simplu: 'Tabucesti',
        comuna: 'Boghesti'
      },
      {
        nume: 'Găgeşti',
        simplu: 'Gagesti',
        comuna: 'Bolotesti'
      },
      {
        nume: 'Ivănceşti',
        simplu: 'Ivancesti',
        comuna: 'Bolotesti'
      },
      {
        nume: 'Pietroasa',
        comuna: 'Bolotesti'
      },
      {
        nume: 'Putna',
        comuna: 'Bolotesti'
      },
      {
        nume: 'Vităneştii de Sub Măgură',
        simplu: 'Vitanestii de Sub Magura',
        comuna: 'Bolotesti'
      },
      {
        nume: 'Bordeştii de Jos',
        simplu: 'Bordestii de Jos',
        comuna: 'Bordesti'
      },
      {
        nume: 'Arva',
        comuna: 'Brosteni'
      },
      {
        nume: 'Pituluşa',
        simplu: 'Pitulusa',
        comuna: 'Brosteni'
      },
      {
        nume: 'Pietroasa',
        comuna: 'Campineanca'
      },
      {
        nume: 'Vâlcele',
        simplu: 'Valcele',
        comuna: 'Campineanca'
      },
      {
        nume: 'Feteşti',
        simplu: 'Fetesti',
        comuna: 'Campuri'
      },
      {
        nume: 'Gura Văii',
        simplu: 'Gura Vaii',
        comuna: 'Campuri'
      },
      {
        nume: 'Rotileştii Mari',
        simplu: 'Rotilestii Mari',
        comuna: 'Campuri'
      },
      {
        nume: 'Rotileştii Mici',
        simplu: 'Rotilestii Mici',
        comuna: 'Campuri'
      },
      {
        nume: 'Blidari',
        comuna: 'Carligele'
      },
      {
        nume: 'Bonţeşti',
        simplu: 'Bontesti',
        comuna: 'Carligele'
      },
      {
        nume: 'Dălhăuţi',
        simplu: 'Dalhauti',
        comuna: 'Carligele'
      },
      {
        nume: 'Cătăuţi',
        simplu: 'Catauti',
        comuna: 'Chiojdeni'
      },
      {
        nume: 'Lojniţa',
        simplu: 'Lojnita',
        comuna: 'Chiojdeni'
      },
      {
        nume: 'Luncile',
        comuna: 'Chiojdeni'
      },
      {
        nume: 'Mărăcini',
        simplu: 'Maracini',
        comuna: 'Chiojdeni'
      },
      {
        nume: 'Podurile',
        comuna: 'Chiojdeni'
      },
      {
        nume: 'Seciu',
        comuna: 'Chiojdeni'
      },
      {
        nume: 'Tulburea',
        comuna: 'Chiojdeni'
      },
      {
        nume: 'Codreşti',
        simplu: 'Codresti',
        comuna: 'Ciorasti'
      },
      {
        nume: 'Mihălceni',
        simplu: 'Mihalceni',
        comuna: 'Ciorasti'
      },
      {
        nume: 'Salcia Nouă',
        simplu: 'Salcia Noua',
        comuna: 'Ciorasti'
      },
      {
        nume: 'Salcia Veche',
        comuna: 'Ciorasti'
      },
      {
        nume: 'Satu Nou',
        comuna: 'Ciorasti'
      },
      {
        nume: 'Spătăreasa',
        simplu: 'Spatareasa',
        comuna: 'Ciorasti'
      },
      {
        nume: 'Buda',
        comuna: 'Corbita'
      },
      {
        nume: 'Izvoarele',
        comuna: 'Corbita'
      },
      {
        nume: 'Lărgăşeni',
        simplu: 'Largaseni',
        comuna: 'Corbita'
      },
      {
        nume: 'Ocheşeşti',
        simplu: 'Ochesesti',
        comuna: 'Corbita'
      },
      {
        nume: 'Rădăcineşti',
        simplu: 'Radacinesti',
        comuna: 'Corbita'
      },
      {
        nume: 'Tuţu',
        simplu: 'Tutu',
        comuna: 'Corbita'
      },
      {
        nume: 'Vâlcelele',
        simplu: 'Valcelele',
        comuna: 'Corbita'
      },
      {
        nume: 'Serbăneşti',
        simplu: 'Serbanesti',
        comuna: 'Corbita'
      },
      {
        nume: 'Budeşti',
        simplu: 'Budesti',
        comuna: 'Cotesti'
      },
      {
        nume: 'Goleştii de Sus',
        simplu: 'Golestii de Sus',
        comuna: 'Cotesti'
      },
      {
        nume: 'Valea Coteşti',
        simplu: 'Valea Cotesti',
        comuna: 'Cotesti'
      },
      {
        nume: 'Alexandru Vlahuţă',
        simplu: 'Alexandru Vlahuta',
        comuna: 'Dumbraveni'
      },
      {
        nume: 'Cândeşti',
        simplu: 'Candesti',
        comuna: 'Dumbraveni'
      },
      {
        nume: 'Dragosloveni',
        comuna: 'Dumbraveni'
      },
      {
        nume: 'Biceştii de Jos',
        simplu: 'Bicestii de Jos',
        comuna: 'Dumitresti'
      },
      {
        nume: 'Biceştii de Sus',
        simplu: 'Bicestii de Sus',
        comuna: 'Dumitresti'
      },
      {
        nume: 'Blidari',
        comuna: 'Dumitresti'
      },
      {
        nume: 'Dumitreştii de Sus',
        simplu: 'Dumitrestii de Sus',
        comuna: 'Dumitresti'
      },
      {
        nume: 'Dumitreştii-Faţă',
        simplu: 'Dumitrestii-Fata',
        comuna: 'Dumitresti'
      },
      {
        nume: 'Găloieşti',
        simplu: 'Galoiesti',
        comuna: 'Dumitresti'
      },
      {
        nume: 'Lupoaia',
        comuna: 'Dumitresti'
      },
      {
        nume: 'Lăstuni',
        simplu: 'Lastuni',
        comuna: 'Dumitresti'
      },
      {
        nume: 'Motnău',
        simplu: 'Motnau',
        comuna: 'Dumitresti'
      },
      {
        nume: 'Poieniţa',
        simplu: 'Poienita',
        comuna: 'Dumitresti'
      },
      {
        nume: 'Roşcari',
        simplu: 'Roscari',
        comuna: 'Dumitresti'
      },
      {
        nume: 'Siminoc',
        comuna: 'Dumitresti'
      },
      {
        nume: 'Tinoasa',
        comuna: 'Dumitresti'
      },
      {
        nume: 'Trestia',
        comuna: 'Dumitresti'
      },
      {
        nume: 'Valea Mică',
        simplu: 'Valea Mica',
        comuna: 'Dumitresti'
      },
      {
        nume: 'Ciolăneşti',
        simplu: 'Ciolanesti',
        comuna: 'Fitionesti'
      },
      {
        nume: 'Ghimiceşti',
        simplu: 'Ghimicesti',
        comuna: 'Fitionesti'
      },
      {
        nume: 'Holbăneşti',
        simplu: 'Holbanesti',
        comuna: 'Fitionesti'
      },
      {
        nume: 'Mănăstioara',
        simplu: 'Manastioara',
        comuna: 'Fitionesti'
      },
      {
        nume: 'Mândreşti-Moldova',
        simplu: 'Mandresti-Moldova',
        comuna: 'Focsani'
      },
      {
        nume: 'Mândreşti-Munteni',
        simplu: 'Mandresti-Munteni',
        comuna: 'Focsani'
      },
      {
        nume: 'Bizigheşti',
        simplu: 'Bizighesti',
        comuna: 'Garoafa'
      },
      {
        nume: 'Ciuşlea',
        simplu: 'Ciuslea',
        comuna: 'Garoafa'
      },
      {
        nume: 'Doaga',
        comuna: 'Garoafa'
      },
      {
        nume: 'Făurei',
        simplu: 'Faurei',
        comuna: 'Garoafa'
      },
      {
        nume: 'Precistanu',
        comuna: 'Garoafa'
      },
      {
        nume: 'Răchitosu',
        simplu: 'Rachitosu',
        comuna: 'Garoafa'
      },
      {
        nume: 'Străjescu',
        simplu: 'Strajescu',
        comuna: 'Garoafa'
      },
      {
        nume: 'Ceardac',
        comuna: 'Golesti'
      },
      {
        nume: 'Oreavu',
        comuna: 'Gugesti'
      },
      {
        nume: 'Bălăneşti',
        simplu: 'Balanesti',
        comuna: 'Gura Calitei'
      },
      {
        nume: 'Cocoşari',
        simplu: 'Cocosari',
        comuna: 'Gura Calitei'
      },
      {
        nume: 'Dealul Lung',
        comuna: 'Gura Calitei'
      },
      {
        nume: 'Groapa Tufei',
        comuna: 'Gura Calitei'
      },
      {
        nume: 'Lacu lui Baban',
        comuna: 'Gura Calitei'
      },
      {
        nume: 'Plopu',
        comuna: 'Gura Calitei'
      },
      {
        nume: 'Poenile',
        comuna: 'Gura Calitei'
      },
      {
        nume: 'Raşca',
        simplu: 'Rasca',
        comuna: 'Gura Calitei'
      },
      {
        nume: 'Sotârcari',
        simplu: 'Sotarcari',
        comuna: 'Gura Calitei'
      },
      {
        nume: 'Costişa',
        simplu: 'Costisa',
        comuna: 'Homocea'
      },
      {
        nume: 'Lespezi',
        comuna: 'Homocea'
      },
      {
        nume: 'Pădureni',
        simplu: 'Padureni',
        comuna: 'Jaristea'
      },
      {
        nume: 'Scânteia',
        simplu: 'Scanteia',
        comuna: 'Jaristea'
      },
      {
        nume: 'Vărsătura',
        simplu: 'Varsatura',
        comuna: 'Jaristea'
      },
      {
        nume: 'Cerbu',
        comuna: 'Jitia'
      },
      {
        nume: 'Dealu Sării',
        simplu: 'Dealu Sarii',
        comuna: 'Jitia'
      },
      {
        nume: 'Jitia de Jos',
        comuna: 'Jitia'
      },
      {
        nume: 'Măgura',
        simplu: 'Magura',
        comuna: 'Jitia'
      },
      {
        nume: 'Belciugele',
        comuna: 'Maicanesti'
      },
      {
        nume: 'Râmniceni',
        simplu: 'Ramniceni',
        comuna: 'Maicanesti'
      },
      {
        nume: 'Slobozia Boteşti',
        simplu: 'Slobozia Botesti',
        comuna: 'Maicanesti'
      },
      {
        nume: 'Stupina',
        comuna: 'Maicanesti'
      },
      {
        nume: 'Tătaru',
        simplu: 'Tataru',
        comuna: 'Maicanesti'
      },
      {
        nume: 'Călimăneşti',
        simplu: 'Calimanesti',
        comuna: 'Marasesti'
      },
      {
        nume: 'Haret',
        comuna: 'Marasesti'
      },
      {
        nume: 'Modruzeni',
        comuna: 'Marasesti'
      },
      {
        nume: 'Pădureni',
        simplu: 'Padureni',
        comuna: 'Marasesti'
      },
      {
        nume: 'Siretu',
        comuna: 'Marasesti'
      },
      {
        nume: 'Tişiţa',
        simplu: 'Tisita',
        comuna: 'Marasesti'
      },
      {
        nume: 'Livada',
        comuna: 'Mera'
      },
      {
        nume: 'Milcovel',
        comuna: 'Mera'
      },
      {
        nume: 'Roşioara',
        simplu: 'Rosioara',
        comuna: 'Mera'
      },
      {
        nume: 'Vulcăneasa',
        simplu: 'Vulcaneasa',
        comuna: 'Mera'
      },
      {
        nume: 'Gologanu',
        comuna: 'Milcovul'
      },
      {
        nume: 'Lămoteşti',
        simplu: 'Lamotesti',
        comuna: 'Milcovul'
      },
      {
        nume: 'Răstoaca',
        simplu: 'Rastoaca',
        comuna: 'Milcovul'
      },
      {
        nume: 'Diocheţirediu',
        simplu: 'Diochetirediu',
        comuna: 'Movilita'
      },
      {
        nume: 'Frecăţei',
        simplu: 'Frecatei',
        comuna: 'Movilita'
      },
      {
        nume: 'Trotuşanu',
        simplu: 'Trotusanu',
        comuna: 'Movilita'
      },
      {
        nume: 'Văleni',
        simplu: 'Valeni',
        comuna: 'Movilita'
      },
      {
        nume: 'Călienii Noi',
        simplu: 'Calienii Noi',
        comuna: 'Nanesti'
      },
      {
        nume: 'Călienii Vechi',
        simplu: 'Calienii Vechi',
        comuna: 'Nanesti'
      },
      {
        nume: 'Podu Nărujei',
        simplu: 'Podu Narujei',
        comuna: 'Naruja'
      },
      {
        nume: 'Podu Stoica',
        comuna: 'Naruja'
      },
      {
        nume: 'Rebegari',
        comuna: 'Naruja'
      },
      {
        nume: 'Brădăceşti',
        simplu: 'Bradacesti',
        comuna: 'Nereju'
      },
      {
        nume: 'Chiricani',
        comuna: 'Nereju'
      },
      {
        nume: 'Nereju Mic',
        comuna: 'Nereju'
      },
      {
        nume: 'Sahastru',
        comuna: 'Nereju'
      },
      {
        nume: 'Brădetu',
        simplu: 'Bradetu',
        comuna: 'Nistoresti'
      },
      {
        nume: 'Bâtcari',
        simplu: 'Batcari',
        comuna: 'Nistoresti'
      },
      {
        nume: 'Făgetu',
        simplu: 'Fagetu',
        comuna: 'Nistoresti'
      },
      {
        nume: 'Podu Schiopului',
        simplu: 'Podu Schiopului',
        comuna: 'Nistoresti'
      },
      {
        nume: 'Romăneşti',
        simplu: 'Romanesti',
        comuna: 'Nistoresti'
      },
      {
        nume: 'Ungureni',
        comuna: 'Nistoresti'
      },
      {
        nume: 'Valea Neagră',
        simplu: 'Valea Neagra',
        comuna: 'Nistoresti'
      },
      {
        nume: 'Vetreşti-Herăstrău',
        simplu: 'Vetresti-Herastrau',
        comuna: 'Nistoresti'
      },
      {
        nume: 'Unirea',
        comuna: 'Odobesti'
      },
      {
        nume: 'Carşocheşti-Corăbiţa',
        simplu: 'Carsochesti-Corabita',
        comuna: 'Paltin'
      },
      {
        nume: 'Ghebari',
        comuna: 'Paltin'
      },
      {
        nume: 'Morăreşti',
        simplu: 'Moraresti',
        comuna: 'Paltin'
      },
      {
        nume: 'Prahuda',
        comuna: 'Paltin'
      },
      {
        nume: 'Păvălari',
        simplu: 'Pavalari',
        comuna: 'Paltin'
      },
      {
        nume: 'Spulber',
        comuna: 'Paltin'
      },
      {
        nume: 'Tojanii de Jos',
        comuna: 'Paltin'
      },
      {
        nume: 'Tojanii de Sus',
        comuna: 'Paltin'
      },
      {
        nume: 'Vâlcani',
        simplu: 'Valcani',
        comuna: 'Paltin'
      },
      {
        nume: 'Tepa',
        simplu: 'Tepa',
        comuna: 'Paltin'
      },
      {
        nume: 'Tipău',
        simplu: 'Tipau',
        comuna: 'Paltin'
      },
      {
        nume: 'Crucea de Jos',
        comuna: 'Panciu'
      },
      {
        nume: 'Crucea de Sus',
        comuna: 'Panciu'
      },
      {
        nume: 'Dumbrava',
        comuna: 'Panciu'
      },
      {
        nume: 'Neicu',
        comuna: 'Panciu'
      },
      {
        nume: 'Satu Nou',
        comuna: 'Panciu'
      },
      {
        nume: 'Hăulişca',
        simplu: 'Haulisca',
        comuna: 'Paulesti'
      },
      {
        nume: 'Viişoara',
        simplu: 'Viisoara',
        comuna: 'Paunesti'
      },
      {
        nume: 'Argea',
        comuna: 'Ploscuteni'
      },
      {
        nume: 'Dealu Cucului',
        comuna: 'Poiana Cristei'
      },
      {
        nume: 'Dumbrava',
        comuna: 'Poiana Cristei'
      },
      {
        nume: 'Mahriu',
        comuna: 'Poiana Cristei'
      },
      {
        nume: 'Odobasca',
        comuna: 'Poiana Cristei'
      },
      {
        nume: 'Petreanu',
        comuna: 'Poiana Cristei'
      },
      {
        nume: 'Podu Lacului',
        comuna: 'Poiana Cristei'
      },
      {
        nume: 'Târâtu',
        simplu: 'Taratu',
        comuna: 'Poiana Cristei'
      },
      {
        nume: 'Tercheşti',
        simplu: 'Terchesti',
        comuna: 'Popesti'
      },
      {
        nume: 'Ciorani',
        comuna: 'Pufesti'
      },
      {
        nume: 'Domneşti-Sat',
        simplu: 'Domnesti-Sat',
        comuna: 'Pufesti'
      },
      {
        nume: 'Domneşti-Târg',
        simplu: 'Domnesti-Targ',
        comuna: 'Pufesti'
      },
      {
        nume: 'Gogoiu',
        comuna: 'Racoasa'
      },
      {
        nume: 'Mărăşti',
        simplu: 'Marasti',
        comuna: 'Racoasa'
      },
      {
        nume: 'Varniţa',
        simplu: 'Varnita',
        comuna: 'Racoasa'
      },
      {
        nume: 'Verdea',
        comuna: 'Racoasa'
      },
      {
        nume: 'Farcaş',
        simplu: 'Farcas',
        comuna: 'Reghiu'
      },
      {
        nume: 'Jgheaburi',
        comuna: 'Reghiu'
      },
      {
        nume: 'Piscu Reghiului',
        comuna: 'Reghiu'
      },
      {
        nume: 'Răiuţi',
        simplu: 'Raiuti',
        comuna: 'Reghiu'
      },
      {
        nume: 'Ursoaia',
        comuna: 'Reghiu'
      },
      {
        nume: 'Valea Milcovului',
        comuna: 'Reghiu'
      },
      {
        nume: 'Sindrilari',
        simplu: 'Sindrilari',
        comuna: 'Reghiu'
      },
      {
        nume: 'Angheleşti',
        simplu: 'Anghelesti',
        comuna: 'Ruginesti'
      },
      {
        nume: 'Copăceşti',
        simplu: 'Copacesti',
        comuna: 'Ruginesti'
      },
      {
        nume: 'Văleni',
        simplu: 'Valeni',
        comuna: 'Ruginesti'
      },
      {
        nume: 'Bogza',
        comuna: 'Sihlea'
      },
      {
        nume: 'Căiata',
        simplu: 'Caiata',
        comuna: 'Sihlea'
      },
      {
        nume: 'Voetin',
        comuna: 'Sihlea'
      },
      {
        nume: 'Cornetu',
        comuna: 'Slobozia Bradului'
      },
      {
        nume: 'Coroteni',
        comuna: 'Slobozia Bradului'
      },
      {
        nume: 'Lieşti',
        simplu: 'Liesti',
        comuna: 'Slobozia Bradului'
      },
      {
        nume: 'Olăreni',
        simplu: 'Olareni',
        comuna: 'Slobozia Bradului'
      },
      {
        nume: 'Valea Beciului',
        comuna: 'Slobozia Bradului'
      },
      {
        nume: 'Armeni',
        comuna: 'Slobozia Ciorasti'
      },
      {
        nume: 'Jilişte',
        simplu: 'Jiliste',
        comuna: 'Slobozia Ciorasti'
      },
      {
        nume: 'Dragosloveni',
        comuna: 'Soveja'
      },
      {
        nume: 'Rucăreni',
        simplu: 'Rucareni',
        comuna: 'Soveja'
      },
      {
        nume: 'Muncelu',
        comuna: 'Straoane'
      },
      {
        nume: 'Repedea',
        comuna: 'Straoane'
      },
      {
        nume: 'Văleni',
        simplu: 'Valeni',
        comuna: 'Straoane'
      },
      {
        nume: 'Bilieşti',
        simplu: 'Biliesti',
        comuna: 'Suraia'
      },
      {
        nume: 'Obrejiţa',
        simplu: 'Obrejita',
        comuna: 'Tamboesti'
      },
      {
        nume: 'Pietroasa',
        comuna: 'Tamboesti'
      },
      {
        nume: 'Pădureni',
        simplu: 'Padureni',
        comuna: 'Tamboesti'
      },
      {
        nume: 'Slimnic',
        comuna: 'Tamboesti'
      },
      {
        nume: 'Trestieni',
        comuna: 'Tamboesti'
      },
      {
        nume: 'Costişa',
        simplu: 'Costisa',
        comuna: 'Tanasoaia'
      },
      {
        nume: 'Costişa de Sus',
        simplu: 'Costisa de Sus',
        comuna: 'Tanasoaia'
      },
      {
        nume: 'Covrag',
        comuna: 'Tanasoaia'
      },
      {
        nume: 'Călimăneasa',
        simplu: 'Calimaneasa',
        comuna: 'Tanasoaia'
      },
      {
        nume: 'Feldioara',
        comuna: 'Tanasoaia'
      },
      {
        nume: 'Galbeni',
        comuna: 'Tanasoaia'
      },
      {
        nume: 'Năneşti',
        simplu: 'Nanesti',
        comuna: 'Tanasoaia'
      },
      {
        nume: 'Vladnicu de Jos',
        comuna: 'Tanasoaia'
      },
      {
        nume: 'Vladnicu de Sus',
        comuna: 'Tanasoaia'
      },
      {
        nume: 'Bordeasca Nouă',
        simplu: 'Bordeasca Noua',
        comuna: 'Tataranu'
      },
      {
        nume: 'Bordeasca Veche',
        comuna: 'Tataranu'
      },
      {
        nume: 'Mărtineşti',
        simplu: 'Martinesti',
        comuna: 'Tataranu'
      },
      {
        nume: 'Vâjâitoarea',
        simplu: 'Vajaitoarea',
        comuna: 'Tataranu'
      },
      {
        nume: 'Bătineşti',
        simplu: 'Batinesti',
        comuna: 'Tifesti'
      },
      {
        nume: 'Clipiceşti',
        simplu: 'Clipicesti',
        comuna: 'Tifesti'
      },
      {
        nume: 'Igeşti',
        simplu: 'Igesti',
        comuna: 'Tifesti'
      },
      {
        nume: 'Oleşeşti',
        simplu: 'Olesesti',
        comuna: 'Tifesti'
      },
      {
        nume: 'Pătrăşcani',
        simplu: 'Patrascani',
        comuna: 'Tifesti'
      },
      {
        nume: 'Sârbi',
        simplu: 'Sarbi',
        comuna: 'Tifesti'
      },
      {
        nume: 'Vităneşti',
        simplu: 'Vitanesti',
        comuna: 'Tifesti'
      },
      {
        nume: 'Coza',
        comuna: 'Tulnici'
      },
      {
        nume: 'Gresu',
        comuna: 'Tulnici'
      },
      {
        nume: 'Lepşa',
        simplu: 'Lepsa',
        comuna: 'Tulnici'
      },
      {
        nume: 'Colacu',
        comuna: 'Valea Sarii'
      },
      {
        nume: 'Mătăcina',
        simplu: 'Matacina',
        comuna: 'Valea Sarii'
      },
      {
        nume: 'Poduri',
        comuna: 'Valea Sarii'
      },
      {
        nume: 'Prisaca',
        comuna: 'Valea Sarii'
      },
      {
        nume: 'Balta Raţei',
        simplu: 'Balta Ratei',
        comuna: 'Vanatori'
      },
      {
        nume: 'Jorăşti',
        simplu: 'Jorasti',
        comuna: 'Vanatori'
      },
      {
        nume: 'Mirceştii Noi',
        simplu: 'Mircestii Noi',
        comuna: 'Vanatori'
      },
      {
        nume: 'Mirceştii Vechi',
        simplu: 'Mircestii Vechi',
        comuna: 'Vanatori'
      },
      {
        nume: 'Petreşti',
        simplu: 'Petresti',
        comuna: 'Vanatori'
      },
      {
        nume: 'Răduleşti',
        simplu: 'Radulesti',
        comuna: 'Vanatori'
      },
      {
        nume: 'Beciu',
        comuna: 'Vartescoiu'
      },
      {
        nume: 'Faraoanele',
        comuna: 'Vartescoiu'
      },
      {
        nume: 'Olteni',
        comuna: 'Vartescoiu'
      },
      {
        nume: 'Pietroasa',
        comuna: 'Vartescoiu'
      },
      {
        nume: 'Râmniceanca',
        simplu: 'Ramniceanca',
        comuna: 'Vartescoiu'
      },
      {
        nume: 'Burca',
        comuna: 'Vidra'
      },
      {
        nume: 'Ireşti',
        simplu: 'Iresti',
        comuna: 'Vidra'
      },
      {
        nume: 'Ruget',
        comuna: 'Vidra'
      },
      {
        nume: 'Scafari',
        comuna: 'Vidra'
      },
      {
        nume: 'Tichiriş',
        simplu: 'Tichiris',
        comuna: 'Vidra'
      },
      {
        nume: 'Viişoara',
        simplu: 'Viisoara',
        comuna: 'Vidra'
      },
      {
        nume: 'Voloşcani',
        simplu: 'Voloscani',
        comuna: 'Vidra'
      },
      {
        nume: 'Serbeşti',
        simplu: 'Serbesti',
        comuna: 'Vidra'
      },
      {
        nume: 'Bahnele',
        comuna: 'Vintileasca'
      },
      {
        nume: 'După Măgura',
        simplu: 'Dupa Magura',
        comuna: 'Vintileasca'
      },
      {
        nume: 'Neculele',
        comuna: 'Vintileasca'
      },
      {
        nume: 'Poiana Stoichii',
        comuna: 'Vintileasca'
      },
      {
        nume: 'Tănăsari',
        simplu: 'Tanasari',
        comuna: 'Vintileasca'
      },
      {
        nume: 'Livezile',
        comuna: 'Vizantea-Livezi'
      },
      {
        nume: 'Mesteacănu',
        simplu: 'Mesteacanu',
        comuna: 'Vizantea-Livezi'
      },
      {
        nume: 'Piscu Radului',
        comuna: 'Vizantea-Livezi'
      },
      {
        nume: 'Vizantea Mănăstirească',
        simplu: 'Vizantea Manastireasca',
        comuna: 'Vizantea-Livezi'
      },
      {
        nume: 'Vizantea Răzăşească',
        simplu: 'Vizantea Razaseasca',
        comuna: 'Vizantea-Livezi'
      },
      {
        nume: 'Bodeşti',
        simplu: 'Bodesti',
        comuna: 'Vrancioaia'
      },
      {
        nume: 'Muncei',
        comuna: 'Vrancioaia'
      },
      {
        nume: 'Ploştina',
        simplu: 'Plostina',
        comuna: 'Vrancioaia'
      },
      {
        nume: 'Poiana',
        comuna: 'Vrancioaia'
      },
      {
        nume: 'Spineşti',
        simplu: 'Spinesti',
        comuna: 'Vrancioaia'
      },
      {
        nume: 'Boţârlău',
        simplu: 'Botarlau',
        comuna: 'Vulturu'
      },
      {
        nume: 'Hânguleşti',
        simplu: 'Hangulesti',
        comuna: 'Vulturu'
      },
      {
        nume: 'Maluri',
        comuna: 'Vulturu'
      },
      {
        nume: 'Vadu Roşca',
        simplu: 'Vadu Rosca',
        comuna: 'Vulturu'
      }
    ]
  }
];

export const tari = [
  'Afghanistan',
  'Albania',
  'Algeria',
  'Andorra',
  'Angola',
  'Antigua and Barbuda',
  'Argentina',
  'Armenia',
  'Australia',
  'Austria',
  'Azerbaijan',
  'The Bahamas',
  'Bahrain',
  'Bangladesh',
  'Barbados',
  'Belarus',
  'Belgium',
  'Belize',
  'Benin',
  'Bhutan',
  'Bolivia',
  'Bosnia and Herzegovina',
  'Botswana',
  'Brazil',
  'Brunei',
  'Bulgaria',
  'Burkina Faso',
  'Burundi',
  'Cabo Verde',
  'Cambodia',
  'Cameroon',
  'Canada',
  'Central African Republic',
  'Chad',
  'Chile',
  'China',
  'Colombia',
  'Comoros',
  'Congo, Democratic Republic of the',
  'Congo, Republic of the',
  'Costa Rica',
  'Côte d’Ivoire',
  'Croatia',
  'Cuba',
  'Cyprus',
  'Czech Republic',
  'Denmark',
  'Djibouti',
  'Dominica',
  'Dominican Republic',
  'East Timor (Timor-Leste)',
  'Ecuador',
  'Egypt',
  'El Salvador',
  'Equatorial Guinea',
  'Eritrea',
  'Estonia',
  'Eswatini',
  'Ethiopia',
  'Fiji',
  'Finland',
  'France',
  'Gabon',
  'The Gambia',
  'Georgia',
  'Germany',
  'Ghana',
  'Greece',
  'Grenada',
  'Guatemala',
  'Guinea',
  'Guinea-Bissau',
  'Guyana',
  'Haiti',
  'Honduras',
  'Hungary',
  'Iceland',
  'India',
  'Indonesia',
  'Iran',
  'Iraq',
  'Ireland',
  'Israel',
  'Italy',
  'Jamaica',
  'Japan',
  'Jordan',
  'Kazakhstan',
  'Kenya',
  'Kiribati',
  'Korea, North',
  'Korea, South',
  'Kosovo',
  'Kuwait',
  'Kyrgyzstan',
  'Laos',
  'Latvia',
  'Lebanon',
  'Lesotho',
  'Liberia',
  'Libya',
  'Liechtenstein',
  'Lithuania',
  'Luxembourg',
  'Madagascar',
  'Malawi',
  'Malaysia',
  'Maldives',
  'Mali',
  'Malta',
  'Marshall Islands',
  'Mauritania',
  'Mauritius',
  'Mexico',
  'Micronesia, Federated States of',
  'Moldova',
  'Monaco',
  'Mongolia',
  'Montenegro',
  'Morocco',
  'Mozambique',
  'Myanmar (Burma)',
  'Namibia',
  'Nauru',
  'Nepal',
  'Netherlands',
  'New Zealand',
  'Nicaragua',
  'Niger',
  'Nigeria',
  'North Macedonia',
  'Norway',
  'Oman',
  'Pakistan',
  'Palau',
  'Panama',
  'Papua New Guinea',
  'Paraguay',
  'Peru',
  'Philippines',
  'Poland',
  'Portugal',
  'Qatar',
  'Romania',
  'Russia',
  'Rwanda',
  'Saint Kitts and Nevis',
  'Saint Lucia',
  'Saint Vincent and the Grenadines',
  'Samoa',
  'San Marino',
  'Sao Tome and Principe',
  'Saudi Arabia',
  'Senegal',
  'Serbia',
  'Seychelles',
  'Sierra Leone',
  'Singapore',
  'Slovakia',
  'Slovenia',
  'Solomon Islands',
  'Somalia',
  'South Africa',
  'Spain',
  'Sri Lanka',
  'Sudan',
  'Sudan, South',
  'Suriname',
  'Sweden',
  'Switzerland',
  'Syria',
  'Taiwan',
  'Tajikistan',
  'Tanzania',
  'Thailand',
  'Togo',
  'Tonga',
  'Trinidad and Tobago',
  'Tunisia',
  'Turkey',
  'Turkmenistan',
  'Tuvalu',
  'Uganda',
  'Ukraine',
  'United Arab Emirates',
  'United Kingdom',
  'United States',
  'Uruguay',
  'Uzbekistan',
  'Vanuatu',
  'Vatican City',
  'Venezuela',
  'Vietnam',
  'Yemen',
  'Zambia',
  'Zimbabwe'
];
