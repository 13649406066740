import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';
import * as login from './modules/login';
import * as history from './modules/history';
import * as pacientModule from './modules/pacient';
import * as pacientiModule from './modules/pacienti';
import * as planTratamente from './modules/planTratamente';
import { state } from './state';
import { getters } from './getters';
import mutations from './mutations';
import actions from './actions';
axios.defaults.withCredentials = true;

Vue.use(Vuex);

export default new Vuex.Store({
  state,
  getters,
  mutations,
  actions,
  modules: {
    login,
    history,
    pacientModule,
    pacientiModule,
    planTratamente
  }
});
