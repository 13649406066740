<template>
  <ValidationObserver class="base-form" v-slot="{ handleSubmit }">
    <form @submit.prevent="handleSubmit(submit)">
      <slot />
    </form>
  </ValidationObserver>
</template>

<script>
  import { ValidationObserver } from 'vee-validate';
  export default {
    name: 'BaseForm',
    components: {
      ValidationObserver
    },
    methods: {
      submit () {
        this.$emit('submit');
      }
    }
  };
</script>

<style lang="less">
  .base-form {
    form {
      max-width: 400px;
      margin: auto;
      text-align: left;
      .base-input {
        margin-top: 8px;
      }
      button {
        margin-top: 24px;
      }
    }
  }
</style>
