<template>
  <div class="pdf-content-wrapper">
    <div class="pdf-header pdf-width flex flex-between row align-center">
      <div class="pdf-client-resume flex columns justify-center">
        <p>{{ nume }}</p>
        <div class="flex flex-between">
          <span>No. {{ planNo }}</span>
          <span>{{ getFormatDate }}</span>
        </div>
      </div>

      <figure>
        <img src="../assets/pdfAssets/logo-block.png" alt="Premium Dental">
      </figure>

      <div class="pdf-total-price">
        <p>SUMĂ TOTALĂ</p>
        <p><span>{{ (total * price1ron).toFixed(1) }} €</span></p>
      </div>
    </div>

    <div class="pdf-details-wrapper pdf-width mauto">
      <slot />
    </div>

    <PdfFooter />
  </div>
</template>

<script>
  import PdfFooter from './PdfFooter'
  export default {
    name: 'PdfContentWrapper',

    components: {
      PdfFooter
    },

    props: {
      total: Number,
      nume: String,
      planNo: Number,
      date: {
        type: String,
        required: true,
        default () {
          return '12.12.2024'
        }
      },
      price1ron: Number
    },

    computed: {
      getFormatDate () {
        return new Date(this.date).toLocaleDateString('ro', { year:'numeric', month:'numeric', day:'numeric'});
      }
    }
  }
</script>

<style lang="less">
  .pdf-content-wrapper {
    overflow: hidden;
    background: #9DDCF9;
    position: relative;
    margin: auto;
    background: linear-gradient(125deg, rgba(0, 182, 241, 1) 0%, rgba(94, 205, 246, 1) 100%);
    width: 595px;

    .pdf-header {
      margin: 20px auto;
      .pdf-client-resume {
        background: #fff;
        height: fit-content;
        padding: 10px 16px;
        border: solid 1.5px #fff;
        box-shadow: 2px 2px 6px rgba(100, 100, 100, 0.5);
        border-radius: 10px;

        p {
          font-family: 'Roboto', 'Arial', SansSerif, serif;
          color: #1E70AE;
          font-size: 16px;
          font-weight: 500;

        }
        div span {
          padding-top: 6px;
          color: #1E70AE;
          font-size: 11px;
          line-height: 18px;
        }
      }

      .pdf-total-price {
        background: linear-gradient(35deg, rgba(23, 136, 210, 1) 0%, rgba(37, 171, 214, 1) 100%);
        border-radius: 10px;
        padding: 10px 16px;
        border: solid 1.5px #fff;
        box-shadow: 2px 2px 6px rgba(100, 100, 100, 0.5);
        height: fit-content;
        p {
          font-size: 11px;
          line-height: 18px;
          padding-bottom: 6px;
          font-family: 'Roboto', 'Arial', SansSerif,serif;
          color: #fff;
          white-space: nowrap;

          span {
            font-size: 16px;
            font-weight: 500;
            display: block;
            float: right;
          }

        }
      }

      figure {
        img {
          width: 47.85px;
        }
      }
    }

    .pdf-details-wrapper {
      margin: 20px auto 0 auto;
      height: 660px;
      position: relative;
      z-index: 9999;
    }
  }

</style>
