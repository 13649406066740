<template>
	<div class="info-pt"
       cy="info-pt">
    <span>Plan Trat.</span>
    <ul class="flex flex-between">
      <li v-for="(item, key, index) in plans"
          :class="{'active-plan': data.includes(key), [`pt-${colors[index]}`]: true}"
          class="capitalize">
        {{ key.substring(0, 1) }}
      </li>
    </ul>
	</div>
</template>

<script>
	export default {
		name: 'InfoPT',
    props: {
      data: {
        type: Array,
        required: false,
        default: () => []
      }
    },
    data () {
      return {
        colors: ['blue', 'orange', 'green']
      }
    },
    computed: {
      plans () {
        return this.$store.state.planTratamente.planuriTemplate
      }
    }
	}
</script>

<style lang="less">
  @import "../assets/styles/var";
	.info-pt {
    margin-right: 6px;
    font-size: 16px;
    color: #545353;
    li {
      padding: 5px 7px;
      margin-top: 4px;
      border-radius: 4px;
      color: white;
      opacity: 0.4;
      &:not(:last-child) {
        margin-right: 6px;
      }
    }
    .active-plan {
      opacity: 1;
      position: relative;
      &:after {
        content: "";
        display: block;
        position: absolute;
        bottom: -4px;
        left: 1px;
        width: 22px;
        border-bottom: 2px solid black;
      }
    }
	}
</style>
