export const tratamente = [
  { name: 'Reevaluare si igienizare parodontala', pret: '600' },
  { name: 'Evaluare Parodontala ', pret: '300' },
  { name: 'Tratament parodontal chirurigical', pret: '300' },
  { name: 'Tratament parodontal non-chirugical', pret: '150' },
  { name: 'Tratament parodontal non-chirugical laser', pret: '200' },
  { name: 'Consultatie medic specalist + radiografie OPG', pret: '270' },
  { name: 'Consultatie medic specialist fara OPG', pret: '200' },
  { name: 'Pachet consultatie estetica', pret: '400' },
  { name: 'Pachet consultatie implant', pret: '500' },
  { name: 'Pachet consultatie reabilitate completa', pret: '700' },
  { name: 'Control periodic', pret: '150' },
  { name: 'Radiografie retroalvolara digitala', pret: '50' },
  { name: 'Radiografie panoramica digitala OPG', pret: '100' },
  { name: '3D CT Dentar partial', pret: '250' },
  { name: '3D CT Dentar total', pret: '400' },
  { name: 'Igienizare profesionala GBT', pret: '400' },
  { name: 'Igienizare profesionala subgingivala GBT', pret: '600' },
  { name: 'Igienizare profesionala GBT - 6 luni', pret: '320' },
  { name: 'Obturatie dinte lateral  Waterlase', pret: '500' },
  { name: 'Obturatie dinte frontal  Waterlase', pret: '600' },
  { name: 'Gingivectomie laser WaterLase', pret: '300' },
  { name: 'Alungire coronara laser WaterLase', pret: '600' },
  { name: 'Frenectomie laser WaterLase', pret: '700' },
  { name: 'Incizie & drenaj abces  WaterLase', pret: '350' },
  { name: 'Descoperire implant  WaterLase', pret: '300' },
  { name: 'Mini implant ortodontic', pret: '750' },
  { name: 'Obturatie colet cls.V', pret: '300' },
  { name: 'Obturatie dinte frontal cls.III', pret: '300' },
  { name: 'Obturatie dinte frontal cls.IV', pret: '400' },
  { name: 'Obturatie dinte frontal cls.IV + incizal', pret: '500' },
  { name: 'Obturatie estetica frontala vestibulara', pret: '500' },
  { name: 'Obturatie dinte posterior cls.I', pret: '300' },
  { name: 'Obturatie dinte posterior cls.II-a cu 1 suprafata', pret: '300' },
  { name: 'Obturatie dinte posterior cls.II-a cu 2 suprafate', pret: '400' },
  { name: 'Obturatie dinte posterior cls.II-a cu 3 suprafate', pret: '550' },
  { name: 'Obturatie dinte posterior cls.II-a cu 4 suprafate', pret: '700' },
  { name: 'Obturatie sub microscop', pret: '500' },
  { name: 'Pansament calmant  urgenta', pret: '250' },
  { name: 'Obturatie provizorie', pret: '50' },
  { name: 'Model studiu + plan tratament', pret: '300' },
  { name: 'Aparat dentar fix metalic', pret: '3500' },
  { name: 'Aparat dentar fix estetic', pret: '4970' },
  { name: 'Aparat dentar mobil', pret: '1600' },
  { name: 'Control + activare aparat', pret: '150' },
  { name: 'Control + activare aparat 2 arcade', pret: '200' },
  { name: 'Bracket metalic desprins', pret: '200' },
  { name: 'Bracket estetic desprins', pret: '250' },
  { name: 'Expansor maxilar', pret: '2000' },
  { name: 'Trainer silicon', pret: '1500' },
  { name: 'Mentinator spatiu', pret: '950' },
  { name: 'Tratament ortodontic gutiere Clear Aligner', pret: '3790' },
  { name: 'Indepartare aparat ortodontic fix', pret: '300' },
  { name: 'Albire dentara profesionala', pret: '1200' },
  { name: 'Albire dentara laser', pret: '1500' },
  { name: 'Amprenta digitala Trios', pret: '500' },
  { name: 'Digital Smile Design / dinte', pret: '200' },
  {
    name: 'Fateta ceramica premium feldspatica',
    color: '#4fcfb1',
    dinteDD: true,
    id: 1.2,
    pret: '2250'
  },
  {
    name: 'Fateta ceramica premium no-prep',
    color: '#4fcfb1',
    dinteDD: true,
    id: 1.2,
    pret: '2400'
  },
  {
    name: 'Fateta ceramica premium',
    color: '#4fcfb1',
    dinteDD: true,
    id: 1.2,
    pret: '1970'
  },
  { name: 'Coroana ceramica premium felspatica/ambermill', pret: '2250' },
  {
    name: 'Coroana ceramica premium',
    dinteDD: true,
    id: 1.1,
    pret: '1970'
  },
  { name: 'Coroana ceramica premium suport metal', pret: '1270' },
  {
    name: 'Inlay/onlay ceramica premium CEREC',
    color: '#4fcfb1',
    dinteDD: true,
    id: 1.3,
    pret: '1490'
  },
  {
    name: 'Coroana provizorie cabinet',
    color: '#a9e8e6',
    dinteDD: true,
    id: 1.4,
    pret: '150'
  },
  {
    name: 'Coroana provizorie Telio CEREC',
    color: '#a9e8e6',
    dinteDD: true,
    id: 1.4,
    pret: '350'
  },
  {
    name: 'Coroana provizorie Telio implant',
    color: '#a9e8e6',
    dinteDD: true,
    id: 1.4,
    pret: '800'
  },
  { name: 'Wax-up / dinte', pret: '200' },
  { name: 'Bont protetic zirconiu / implant', pret: '1270' },
  { name: 'Bont protetic metalic / implant', pret: '1000' },
  {
    name: 'Lucrare protetica finala ALL ON 4/6 metalo-ceramica',
    dinteDD: true,
    id: 1.5,
    pret: '12400'
  },
  {
    name: 'Lucrare protetica finala ALL ON 4/6 premium titan',
    dinteDD: true,
    id: 1.5,
    pret: '21900'
  },
  {
    name: 'Lucrare protetica finala ALL ON 4/6 premium zirconiu',
    dinteDD: true,
    id: 1.1,
    pret: '24900'
  },
  { name: 'Reconstructie bonturi cu pivot fibra sticla', pret: '350' },
  { name: 'Sistem fixare multi-unit ALL on 4/6', pret: '650' },
  { name: 'Ablatie coroana zirconiu/emax', pret: '250' },
  { name: 'Ablatie coroana metalo-ceramica', pret: '200' },
  { name: 'Proteza Kemeny', pret: '600' },
  { name: 'Gutiera contentie bruxism', pret: '350' },
  { name: 'Gutiera contentie premium', pret: '450' },
  { name: 'Proteza totala premium', pret: '2200' },
  { name: 'Proteza partiala premium', pret: '1950' },
  { name: 'Proteza scheletata', pret: '4800' },
  { name: 'Cimentare', pret: '150' },
  { name: 'Coroana cerec', dinteDD: true, id: 1.1, pret: '1930' },
  { name: 'Endocrown cerec', dinteDD: true, id: 1.1, pret: '2290' },
  { name: 'Inlay/onlay cerec', pret: '1490' },
  { name: 'Tratament endo microscop - vital  incisiv', pret: '600' },
  { name: 'Tratament endo microscop - vital  premolar', pret: '800' },
  { name: 'Tratament endo microscop - vital  molar', pret: '1000' },
  { name: 'Retratament endo microscop - devital  incisiv', pret: '700' },
  { name: 'Retratament endo microscop - devital  premolar', pret: '900' },
  { name: 'Retratament endo microscop - devital  molar', pret: '1100' },
  { name: 'Ablatie ace rupte pe canal', pret: '250' },
  { name: 'Ablatie Dentatus / DCR', pret: '200' },
  { name: 'Aplicare MTA', pret: '200' },
  {
    name: 'Implant dentar Megagen/MIS/SwedenMartina',
    dinteDD: true,
    id: 4,
    pret: '2700'
  },
  {
    name: 'Implant dentar premium Megagen Anyridge',
    dinteDD: true,
    id: 4,
    pret: '3730'
  },
  {
    name: 'Implant dentar superpremium Strauman BLX',
    dinteDD: true,
    id: 4,
    pret: '4690'
  },
  {
    name: 'One day implant Megagen Anyone/SwedenMartina',
    dinteDD: true,
    id: 4,
    pret: '3730'
  },
  {
    name: 'One day implant premium Megagen Anyridge',
    dinteDD: true,
    id: 4,
    pret: '4970'
  },
  { name: 'ALL on 4 - 4 implanturi dentare', pret: '14500' },
  { name: 'ALL on 4 - 4 implanturi dentare premium Megagen Anyridge', pret: '17500' },
  { name: 'ALL on 4 - 6 implanturi dentare superpremium Straumann BLX', pret: '20500' },
  { name: 'ALL on 6 - 6 implanturi dentare', pret: '18900' },
  { name: 'ALL on 6 - 6 implanturi dentare premium Megagen Anyridge', pret: '22500' },
  { name: 'ALL on 6 - 6 implanturi dentare superpremium Straumann BLX', pret: '25500' },
  { name: 'Ghid chirurgical implant 1/2 gauri', pret: '600' },
  { name: 'Ghid chirurgical implant 3/4 gauri', pret: '900' },
  { name: 'Ghid chirurgical implant arcada', pret: '1200' },
  { name: 'Aditie osoasa 1-2 dinti', pret: '1870' },
  { name: 'Aditie osoasa 3/- dinti', pret: '3800' },
  { name: 'Aditie osoasa extinsa', pret: '5700' },
  { name: 'Sinus lift extern', pret: '5400' },
  { name: 'Sinus lift intern', pret: '2450' },
  { name: 'Augumentare gingivala extinsa', pret: '3000' },
  { name: 'Augumentare gingivala 2/3 dinti', pret: '2370' },
  { name: 'Augumentare gingivala 1 dinte', pret: '1870' },
  { name: 'Anestezie digitala STA', pret: '100' },
  { name: 'Extractie dinte monoradicular', dinteDD: true, id: 3, pret: '300' },
  { name: 'Extractie dinte pluriradicular', dinteDD: true, id: 3, pret: '350' },
  { name: 'Extractie rest radicular', pret: '400' },
  { name: 'Extractie molar minte erupt complet', pret: '550' },
  { name: 'Extractie molar minte erupt partial', pret: '700' },
  { name: 'Extractie molar minte inclus', pret: '1000' },
  { name: 'Alungire coronara WaterLase', pret: '500' },
  { name: 'Frenectomie WaterLase', pret: '700' },
  { name: 'Incizie / drenaj abces dentar  WaterLase', pret: '350' },
  { name: '[K] Consultatie copii <3ani', pret: '100' },
  { name: '[K] Consultatie copii', pret: '130' },
  { name: '[K] Control periodic', pret: '100' },
  { name: '[K] Tratament de urgenta dentitie temporara', pret: '200' },
  { name: '[K] Tratament de urgenta dentitie permanenta', pret: '300' },
  { name: '[K] Acomodare pacient necooperant', pret: '100' },
  { name: '[K] DT - Obturatie ciment glassionomer 1 suprafata', pret: '160' },
  { name: '[K] DT - Obturatie ciment glassionomer 2 suprafete', pret: '180' },
  { name: '[K] DT - Obturatie ciment glassionomer 3 suprafete', pret: '200' },
  { name: '[K] DT - Obturatie compozit 1 suprafata', pret: '160' },
  { name: '[K] DT - Obturatie compozit 2 suprafete', pret: '180' },
  { name: '[K] DT - Obturatie compozit 3 suprafete', pret: '200' },
  { name: '[K] DT - Obturatie compozit colorat', pret: '160' },
  { name: '[K] DT - Obturatie provizorie', pret: '70' },
  { name: '[K] DT - Aplicare SDF', pret: '70' },
  { name: '[K] DP - Tratament ICON', pret: '150' },
  { name: '[K] DP - Coafaj', pret: '120' },
  { name: '[K] DP - Coafaj cu Biodentine', pret: '170' },
  { name: '[K] DP - Baza obturatie', pret: '120' },
  { name: '[K] DP - Obturatie ciment gassionomer 1 suprafata', pret: '200' },
  { name: '[K] DP - Obturatie ciment gassionomer 2 suprafete ', pret: '250' },
  { name: '[K] DP - Obturatie ciment gassionomer x3 suprafete ', pret: '280' },
  { name: '[K] DP - Obturatie compozit 1 suprafata', pret: '200' },
  { name: '[K] DP - Obturatie compozit 2 suprafete ', pret: '250' },
  { name: '[K] DP - bturatie compozit 3 suprafete', pret: '280' },
  { name: '[K] DP - Obturatie provizorie', pret: '70' },
  { name: '[K] DT - Pulpotomie vitala', pret: '150' },
  { name: '[K] DT - Pulpectomie vitala dinte monoradicular', pret: '120' },
  { name: '[K] DT - Pulpectomie vitala dinte pluriradicular', pret: '150' },
  { name: '[K] DT - Trarament devitalizant', pret: '120' },
  { name: '[K] DT - Pansament calmant + obturatie provizorie', pret: '100' },
  { name: '[K] DT - Tratament medicamentos/sedinta + obturatie provizorie', pret: '100' },
  { name: '[K] DT - Trarament necroza / gangrena pulpara', pret: '150' },
  { name: '[K] DT - Obturatie de canal dinte monoradicular', pret: '120' },
  { name: '[K] DT - Obturatie de canal dinte pluriradicular', pret: '150' },
  { name: '[K] DT - Drenaj ', pret: '100' },
  { name: '[K] DT - Tratament cu Biodentine ', pret: '150' },
  {
    name: '[K] DT - Extractie dinte mobil',
    dinteDD: true,
    id: 3,
    pret: '100'
  },
  {
    name: '[K] DT Extractie dinte temporar fara mobilitate',
      dinteDD: true,
    id: 3,
    pret: '150'
  },
  { name: '[K] DT - Extractie rest radicular ', pret: '150' },
  { name: '[K] DT - Gingivectomie laser ', pret: '150' },
  { name: '[K] DT - Decapusonare laser ', pret: '250' },
  { name: '[K] DT - Frenectomie laser ', pret: '400' },
  {
    name: '[K] DT - Extractie dinte in scop ortodontic',
      dinteDD: true,
    id: 3,
    pret: '150'
  },
  {
    name: '[K] DT - Imobilizare dinte post traumatism',
      dinteDD: true,
    id: 3,
    pret: '250'
  },

  {
    name: '[K] DT - Coroana metalica',
      color: '#a9e8e6',
    dinteDD: true,
    id: 1.4,
    pret: '300'
  },
  { name: '[K] DT - Coroana Zirconiu', dinteDD: true, id: 1.1, pret: '500' },
  { name: '[K] Mentinator de spatiu metalic', pret: '500' },
  { name: '[K] Imobilizare dinte post traumatic', pret: '200' },
  {
    name: '[K] Model de studiu',
      dinteDD: true,
    id: 1.1,
    pret: '100'
  },

  { name: '[K] DT Periaj+flourizare+instructaj', pret: '150' },
  { name: '[K] DT Pachet igenizare', pret: '220' },
  { name: '[K] DM Pachet igienizare ', pret: '270' },
  { name: '[K] DP Pachet igienizare', pret: '330' },
  { name: '[K] Flouorizare topica', pret: '100' },
  { name: '[K] Sigilare dinte temporar', pret: '120' },
  { name: '[K] Obturatie preventiva dinte temporar', pret: '140' },
  { name: '[K] Sigilare dinte permant', pret: '150' },
  { name: '[K] Obturatie preventiva dinte permanent', pret: '180' },
  { name: '[K] Refacere sigilare', pret: '80' },
  { name: '[K] Determinare indice de placa', pret: '30' },

  {
    name: 'Coroana metalo-ceramica pe implant',
      dinteDD: true,
    id: 1.5
  },
  {
    name: 'Punte ceramica / Zirconiu',
      fill: '#34a186',
    dinteDD: true,
    id: 6.1
  },
  {
    name: 'Punte acrilica provizorie',
      fill: '#a9e8e6',
    dinteDD: true,
    id: 6.4
  },
  {
    name: 'Punte metalo-ceramica',
      fill: '#FFB400',
    dinteDD: true,
    id: 6.5
  },
  { name: 'Indepartare expansor', pret: '150' },
  { name: 'Tratament de urgenta', pret: '500' },
  { name: 'Activare aparat estetic', pret: '180' },
  { name: 'Activare aparat mobil', pret: '100' },
  { name: 'Rezectie apicala', pret: '2000' },
  { name: 'Extractie cu split paro/dinte', pret: '300' }
];
