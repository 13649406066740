<template>
  <section class="add-tratament history-modal flex" @mousedown="_clickModal">
    <ValidationObserver v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(submit)">
        <!-- DATEPICKER -->
        <validation-provider rules="required" v-slot="{ errors }">
          <div class="relative">
            <i class="absolute left0 top0 flex-center" v-html="svg.calendar">
            </i>
            <datepicker
              v-model="form.createdAt"
              :class="{ 'invalid-input': errors[0] }"
              :monday-first="true"
              :language="$data.ro"
              :format="_customFormatter"
              placeholder="data"
              name="uniquename"
            />
          </div>
        </validation-provider>

        <!-- DINTI MULTISELECT -->
        <div class="mtop12px relative z99">
          <multiselect
            cy="select-dinti"
            v-model="dinti"
            :multiple="true"
            :options="options"
            placeholder="dinti"
          >
            <span slot="noResult"></span>
          </multiselect>
        </div>

        <!--  DENUMIRE TRATAMENTE DD -->
        <div class="mtop12px">
          <drop-down
            cy="select-tratament"
            :data="ddTratament"
            :value="data.denumire"
            @selected="setTratament"
          />
        </div>

        <!-- PRET -->
        <div class="pret mtop12px">
          <input
            type="text"
            data-test="pret-tratament"
            v-model="data.pret"
            placeholder="pret tratament (lei)"
          />
        </div>

        <!--  MEDIC CURANT -->
        <div class="mtop12px">
          <drop-down
            cy="select-medic"
            :data="ddMedic"
            :value="data.medic || (user.rol.includes('medic') ? user.nume : '')"
            @selected="setMedic"
          />
        </div>

        <!-- PLAN TRATAMENT -->
        <div class="plan-tratament mtop16px">
          <multiselect
            cy="planuri-tratamente"
            v-model="planTratament"
            :options="planuriTratamente"
            @input="selectPlanTratament"
            placeholder="plan tratament"
            track-by="nume"
            label="nume"
          />
        </div>

        <!-- DETALII -->
        <textarea
          class="mtop12px"
          data-test="detalii-tratament"
          v-model="data.detalii"
          cols="30"
          rows="5"
          placeholder="detalii"
        ></textarea>

        <div class="actions flex flex-between">
          <div class="flex">
            <upload-img-btn
              label="inainte"
              :img="setPic('inainte')"
              @select-img="selectImg"
            />
            <div class="mright20px"></div>
            <upload-img-btn
              label="dupa"
              :img="setPic('dupa')"
              @select-img="selectImg"
            />
          </div>

          <!-- SEMNATURA -->
          <div class="text-center">
            <button class="flex-center mauto" @mousedown="toggleSignaturePad">
              <i v-html="svg.semnatura"></i>
            </button>
            <span>{{ STRINGS.SEMNATURA }}</span>
          </div>
        </div>

        <div class="flex flex-between align-center">
          <!-- DELETE -->
          <button v-if="isDeleteBtn" class="delete" @click.stop="promptDelete">
            <i v-html="svg.delete"></i>
          </button>

          <div class="prompt-delete" v-if="isDeletePrompt">
            <p>{{ STRINGS.TRATAMENT.CONFIRMARE_STERGERE }}</p>
            <div class="flex flex-between">
              <span class="pointer" @click="promptDelete('no')">NU</span>
              <span class="pointer" @click.stop="deleteTratament">DA</span>
            </div>
          </div>

          <button
            class="submit flex-center m-left-auto submit-active mright7px"
            cy="save-tratament"
          >
            <i v-html="svg.save"></i>
          </button>
        </div>
      </form>
    </ValidationObserver>

    <div class="singature-pad" :class="{ 'show-signature': isPad }">
      <figure class="flex-center" v-if="data.signature">
        <img
          class="w100"
          :src="'data:image/png;base64, ' + data.signature"
          alt=""
        />
      </figure>
      <div v-else>
        <canvas id="cnv" name="cnv" width="396" height="100"></canvas>
      </div>

      <form action="#" name="FORM1">
        <p>
          <span class="flex flex-between">
            <button
              class="m-left-auto pointer relative z99"
              @click="sterge"
              onclick="javascript:onClear()"
            >
              {{ STRINGS.STERGE }}
            </button>
          </span>
        </p>
      </form>

      <br /><br />
    </div>
  </section>
</template>

<script>
  import { STRINGS } from '@/store/strings';
  import { mapState } from 'vuex';
  import { historyModal } from '@/mixins/history-modal';
  import Vue from 'vue';
  export default {
    name: 'AddTratament',
    mixins: [historyModal],
    data () {
      return {
        form: {
          type: 'tratament',
          denumire: undefined,
          createdAt: new Date(),
          dinte: [],
          pret: undefined,
          pics: []
        },
        planTratament: '',
        /* dummy array for multiselect component */
        dinti: [],
        options: this.$store.state.dinti,
        isPad: false,
        isSignature: false,
        isDeletePrompt: false,
        isDeleteBtn: undefined,
        ddDinte: {
          name: 'dinte',
          placeholder: 'dinte',
          ddData: 'dinti',
          disabled:
            this.$store.state.disableDintiDD ||
            (this.$store.state.isUpdateModal &&
              this.$store.state.modalData.denumire.includes('punte')),
          validation: false,
          clbk: (item) => {
            if (!item.name) {
              throw new Error('Dinte Item undefined');
            }
            this.$store.commit(
              'SET_DINTI_DESTINATIE',
              item.name.replace(' ', '-')
            );
            this.ddDinteDestinatie.changeable = Math.random();
          }
        },
        ddDinteDestinatie: {
          name: 'dinteDestinatie',
          placeholder: 'dinte destinatie',
          ddData: 'dintiDestinatie',
          changeable: 'some',
          disabled: this.$store.state.isUpdateModal,
          clbk: (item) => {
            this.$store.commit('SET_PUNTE_DIRECTION', item);
          }
        },
        ddTratament: {
          name: 'denumire',
          placeholder: 'denumire tratament',
          ddData: 'tratamente',
          validation: true,
          disabled:
            this.$store.state.isUpdateModal &&
            this.$store.state.modalData.denumire.includes('punte'),
          clbk: (item) => {
            /* aici stochez pretul initial pentru a il calcula in functie de numarul de dinti */
            this.initialPrice = +item.pret;
            /* aici preiau nr de dinti selectati - deci pretul e in functie de dinti si tratament */
            const length = this.data.dinte && this.data.dinte.length;
            const pret = length ? +item.pret * length : +item.pret;
            this.$store.commit('SET_TRATAMENT_PRET', pret);

            if (item.name.includes('punte')) {
              this.ddDinte.validation = item.dinteDD;
              /* aratam dd cu dinte destinatie */
              this.showDinteDestinatie = true;
              /* schimbam placeholder dd dinte in dinte origine */
              this.ddDinte.placeholder = 'dinte origine';
              /* filtreaza lista pentru dinti origine */
              this.$store.commit('FILTER_DINTI_ORIGINE');
              /* schimbam pointerul pentru lista de dinti pentru dinte origine catre doar cei cu coroana */
              this.ddDinte.ddData = 'dintiTratamentCoroana';
            } else {
              this.showDinteDestinatie = false;
              this.ddDinte.ddData = 'dinti';
              this.ddDinte.placeholder = 'dinte';
              this.$store.commit('REMOVE_KEY_VALUE', {
                sub: 'modalData',
                key: 'dinteDestinatie'
              });
            }
          }
        },
        ddMedic: {
          name: 'medic',
          placeholder: 'medic curant',
          ddData: 'medici',
          validation: true,
          default: '',
          capitalize: true
        },
        submitted: false,
        uploadName: undefined,
        before: false,
        after: false,
        idDelete: false,
        isPadRed: false,
        emptySignature: false,
        skipSignature: false,
        onOffSwitch: false,
        showDinteDestinatie: false,
        STRINGS,
        initialPrice: 0
      };
    },
    created () {
      !this.medici.length && this.$store.dispatch('get_medici');
      this.data.dinte && (this.dinti = this.data.dinte);
    },
    mounted () {
      this.isDeleteBtn = this.data.denumire;
      this.showDinteDestinatie = this.data.dinteDestinatie;
      this.isPad = !!this.data.signature;
      if (this.user && this.user.rol.includes('medic')) {
        this.setMedic({ value: this.user.nume });
      }
    },
    computed: {
      ...mapState({
        data: 'modalData',
        tratamente: 'tratamente',
        medici: 'medici',
        isUpdateModal: 'isUpdateModal',
        user: 'user',
        svg: 'svg'
      }),
      planuriTratamente () {
        return this.$store.getters.planuriFinalizate
      },
      listaTratamente () {
        return this.tratamente.map((o) => o.name);
      }
    },
    methods: {
      selectPlanTratament ({id}) {
        this.$store.commit('SET_PLAN_TRATAMENT_ID', id)
      },
      setMedic (data) {
        this.data.medic = data.value;
      },
      toggleSignaturePad (ev) {
        this.onOffSwitch = true;
        setTimeout(() => {
          this.onOffSwitch = false;
        }, 200);
        if (!this.isPad) {
          this.isPad = true;
          window.onSign();
        } else {
          this.isPad = false;
        }
      },
      sterge () {
        this.onOffSwitch = true;
        setTimeout(() => {
          this.onOffSwitch = false;
        }, 200);
        this.data.signature = undefined;
      },
      setPic (field) {
        return this.data.pics.filter((obj) => obj.name.indexOf(field) > -1)[0];
      },
      selectImg ({ file, picName }) {
        this.$store.commit('ADD_IMGS_TO_MODAL', { file, picName });
      },
      setTratament ({ value }) {
        this.$store.commit('SET_TRATAMENT', value);
      },
      promptDelete (action) {
        this.isDeletePrompt = action !== 'no';
        this.isDelete = action !== 'no';
      },
      deleteTratament () {
        this.isDelete = true;
        const obj = {
          id: this.$router.currentRoute.params.id
        };
        this.$store.dispatch('delete_tratament', obj);
      },
      submitWithSignature () {
        const clbk = (signature) => {
          const obj = {
            id: this.$router.currentRoute.params.id,
            signature
          };
          !this.isDelete &&
            this.$store.dispatch(
              this.isUpdateModal ? 'update_tratament' : 'save_tratament',
              obj
            );
        };
        const err = () => {
          this.emptySignature = true;
        };
        if (this.isUpdateModal) {
          const obj = {
            id: this.$router.currentRoute.params.id
          };
          !this.isDelete && this.$store.dispatch('update_tratament', obj);
        } else {
          this.isPad
            ? window.onDone(clbk, err)
            : (this.isPad = true) && (this.isPadRed = true) && window.onSign();
        }
      },
      submit () {
        // this.$store.dispatch('save_tratement_nou', this.form);
        let obj = {
          id: this.$router.currentRoute.params.id
        };
        if (!this.onOffSwitch) {
          if (this.isPad) {
            const clbk = (signature) => {
              this.$store.commit('SET_KEY_VALUE', {
                key: 'modalData',
                sub: 'signature',
                value: signature
              });
              if (this.isUpdateModal) {
                !this.isDelete && this.$store.dispatch('update_tratament', obj);
              } else {
                this.$store.dispatch('save_tratament', obj);
              }
            };
            const err = () => {
              this.emptySignature = true;
              window.alert('Eroare semnatura digitala.');
            };
            window.onDone(clbk, err);
          } else {
            if (this.isUpdateModal) {
              !this.isDelete && this.$store.dispatch('update_tratament', obj);
            } else {
              this.$store.dispatch('save_tratament', obj);
            }
          }
        }
      }
    },
    watch: {
      dinti: function (value, oldVal) {
        const obj = {
          key: 'modalData',
          sub: 'dinte',
          value
        };
        this.$store.commit('SET_KEY_VALUE', obj);
        if (this.initialPrice === 0) {
          this.initialPrice = this.data.pret
            ? this.data.pret / value.length
            : 0;
        }
        if (value.length > oldVal.length) {
          this.data.pret = this.initialPrice * value.length;
        } else if (value.length < oldVal.length && value.length) {
          this.data.pret -= this.initialPrice;
        }
      }
    },
    destroyed () {
      this.$store.commit('RESET_DD_CLONE', { template: this.data.ddData });
      this.$store.commit('SET_KEY_VALUE', {
        key: 'disableDintiDD',
        value: false
      });
      this.$store.commit('SET_KEY_VALUE', {
        key: 'filterTratamente',
        value: undefined
      });
    }
  };
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="less">
  @import "../assets/styles/mixin";
  @import "../assets/styles/var";
  .add-tratament {
    .submit,
    .delete,
    .actions {
      margin-top: 20px;
    }

    .delete {
      width: 65px;
      height: 65px;
      background-color: @red!important;
      transition: background-color 0.2s ease-in-out;
      padding: 0;
      svg path {
        transition: fill 0.1s ease-in-out;
      }
      &:hover {
        background-color: darken(red, 20%, relative);
      }
    }

    .prompt-delete {
      width: 200px;
      margin-top: 20px;
      text-align: center;
      font-size: 1.3rem;
      div {
        width: 50%;
        margin: 8px auto 0;
        span {
          width: 40px;
          height: 20px;
          padding-top: 4px;
          text-align: center;
          font-weight: bold;
          border-radius: 4px;
          color: white;
          transition: background-color 0.1s ease-in-out;
          &:first-child {
            background-color: @red;
            &:hover {
              background-color: darken(@red, 20%, relative);
            }
          }
          &:last-child {
            background-color: @main-green;
            &:hover {
              background-color: darken(@main-green, 20%, relative);
            }
          }
        }
      }
    }

    .singature-pad {
      width: 0;
      height: 100%;
      overflow: hidden;
      transition: width 0.3s ease-in-out;
      padding-top: 32px;
      margin-right: 20px;
      figure {
        width: 400px;
        height: 100px;
        overflow: hidden;
      }
      & > div {
        width: 400px;
        height: 100px;
        background-color: white;
      }
      form {
        button {
          width: 100px;
          height: 30px;
          margin-top: 24px;
          background-color: white;
          border: 1px solid @medium-grey;
          font-size: 1.2rem;
          border-radius: 4px;
        }
      }
    }

    textarea[name="sigImageData"] {
      width: 100%;
      height: 40px;
    }

    .show-signature {
      width: 400px !important;
    }
  }
  div.multiselect__tags {
    border-radius: 8px;
    border: 1px solid #dddddd;
  }
  li span.multiselect__option--highlight,
  li span.multiselect__option--highlight:after {
    background-color: @main-green!important;
  }
  span.multiselect__tag {
    background-color: @main-green!important;
  }
  i.multiselect__tag-icon {
    &:hover {
      background-color: red;
    }
    &:after {
      color: white;
    }
  }
  span.multiselect__placeholder {
    transform: translate(2px, 1px);
    font-size: 16px;
  }
  .multiselect__select {
    transform: scale(1.3);
    pointer-events: none;
  }
  .multiselect--active .multiselect__select {
    transform: scale(1.3) rotate(180deg);
  }
</style>
