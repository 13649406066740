<template>
  <section class="utilizatori w100">
    <main-modal />

    <header>
      <h1>Listă utilizatori</h1>
    </header>

    <div class="patients-table">
      <ul>
        <li
          class="flex flex-between"
          @click="openModal(item)"
          v-for="item in data"
          :key="item._id"
        >
          <span class="nume capitalize">{{ item.nume }}</span>
          <span class="rol">{{ item.rol }}</span>
          <span class="email">{{ item.email }}</span>
        </li>
      </ul>
      <div class="table-footer flex flex-between">
        <div class="pagination flex align-center">
          <button
            class="left"
            :class="{ pointer: page > 0 }"
            :disabled="page === 0"
            @click="pagination('prev')"
          >
            <svg
              width="10px"
              height="16px"
              viewBox="0 0 10 16"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
            >
              <g
                id="Page-1"
                stroke="none"
                stroke-width="1"
                fill="none"
                fill-rule="evenodd"
              >
                <path
                  d="M1.25,15.984375 C1.17708297,15.984375 1.10677117,15.9713543 1.0390625,15.9453125 C0.971353828,15.9192707 0.911458594,15.8802086 0.859375,15.828125 C0.755207812,15.7239578 0.703125,15.5989591 0.703125,15.453125 C0.703125,15.3072909 0.755207812,15.1822922 0.859375,15.078125 L8,7.9375 L0.96875,0.921875 C0.864582812,0.817707812 0.8125,0.690104922 0.8125,0.5390625 C0.8125,0.388020078 0.864582812,0.260417188 0.96875,0.15625 C1.07291719,0.0520828125 1.20052008,0 1.3515625,0 C1.50260492,0 1.63020781,0.0520828125 1.734375,0.15625 L9.125,7.5625 C9.22916719,7.66666719 9.28125,7.79166594 9.28125,7.9375 C9.28125,8.08333406 9.22916719,8.20833281 9.125,8.3125 L1.625,15.828125 C1.57291641,15.8802086 1.51302117,15.9192707 1.4453125,15.9453125 C1.37760383,15.9713543 1.31250031,15.984375 1.25,15.984375 Z"
                  id=""
                  fill="#00998B"
                  fill-rule="nonzero"
                  transform="translate(4.992188, 7.992188) rotate(-180.000000) translate(-4.992188, -7.992188) "
                ></path>
              </g>
            </svg>
          </button>

          <span class="page-number">{{ page + 1 }}</span>

          <button
            class="right"
            :class="{ pointer: !lastPage }"
            :disabled="lastPage"
            @click="pagination('next')"
          >
            <svg
              width="10px"
              height="16px"
              viewBox="0 0 10 16"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
            >
              <g
                id="Page-1"
                stroke="none"
                stroke-width="1"
                fill="none"
                fill-rule="evenodd"
              >
                <path
                  d="M1.25,15.984375 C1.17708297,15.984375 1.10677117,15.9713543 1.0390625,15.9453125 C0.971353828,15.9192707 0.911458594,15.8802086 0.859375,15.828125 C0.755207812,15.7239578 0.703125,15.5989591 0.703125,15.453125 C0.703125,15.3072909 0.755207812,15.1822922 0.859375,15.078125 L8,7.9375 L0.96875,0.921875 C0.864582812,0.817707812 0.8125,0.690104922 0.8125,0.5390625 C0.8125,0.388020078 0.864582812,0.260417188 0.96875,0.15625 C1.07291719,0.0520828125 1.20052008,0 1.3515625,0 C1.50260492,0 1.63020781,0.0520828125 1.734375,0.15625 L9.125,7.5625 C9.22916719,7.66666719 9.28125,7.79166594 9.28125,7.9375 C9.28125,8.08333406 9.22916719,8.20833281 9.125,8.3125 L1.625,15.828125 C1.57291641,15.8802086 1.51302117,15.9192707 1.4453125,15.9453125 C1.37760383,15.9713543 1.31250031,15.984375 1.25,15.984375 Z"
                  id=""
                  fill="#14988B"
                  fill-rule="nonzero"
                ></path>
              </g>
            </svg>
          </button>
        </div>

        <div class="flex" v-if="user.rol.includes('admin')">
          <button
            @click="addUtilizator"
            class="add-patient-btn m-left-auto pointer"
          >
            <svg width="52px" height="52px" viewBox="0 0 52 52">
              <g
                id="Page-1"
                stroke="none"
                stroke-width="1"
                fill="none"
                fill-rule="evenodd"
              >
                <g id="add-button">
                  <circle
                    id="Oval"
                    fill="#008ED9"
                    cx="26"
                    cy="26"
                    r="26"
                  ></circle>
                  <path
                    d="M15,25 L38,25 C39,25 39,26 39,27 L39,27 C39,27 39,28 38,28 L15,28 C14,28 14,27 14,27 L14,27 C14,26 14,25 15,25 Z"
                    id="Path"
                    fill="#F9F9F9"
                  ></path>
                  <path
                    d="M25,38 L25,15 C25,14 26,14 27,14 L27,14 C27,14 28,14 28,15 L28,38 C28,39 27,39 27,39 L27,39 C26,39 25,39 25,38 Z"
                    id="Path"
                    fill="#F9F9F9"
                  ></path>
                </g>
              </g>
            </svg>
          </button>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
  import { mapState } from 'vuex';
  export default {
    name: 'utilizatori',
    created () {
      this.$store.dispatch('get_utilizatori');
    },
    computed: {
      ...mapState({
        data: 'utilizatori',
        page: 'page',
        bookmarks: 'bookmarks',
        lastPage: 'lastPage',
        user: 'user'
      })
    },
    methods: {
      addUtilizator () {
        const obj = {
          val: true,
          name: 'addEditUtilizator'
        };
        this.$store.commit('SET_KEY_VALUE', {
          key: 'isUpdateModal',
          value: false
        });
        this.$store.commit('SET_MODAL', obj);
      },
      openModal (item) {
        item.id = item._id;
        const obj = {
          val: true,
          name: 'addEditUtilizator',
          setValues: item
        };
        this.$store.commit('SET_KEY_VALUE', {
          key: 'isUpdateModal',
          value: true
        });
        this.$store.commit('SET_MODAL', obj);
      },
      hideRightSideMenu () {
        this.$store.commit('SET_KEY_VALUE', {
          key: 'isEditPacient',
          value: false
        });
      },
      pagination (direction) {
        this.$store.dispatch('get_utilizatori', direction);
      }
    }
  };
</script>

<style lang="less">
  @import "../assets/styles/var";
  .utilizatori {
    padding: 60px 0;

    h1 {
      font-size: 2.4rem;
      font-weight: normal;
      color: rgba(0, 0, 0, 0.5);
    }

    .patients-table {
      li span {
        color: @main-green;
        cursor: pointer;
      }
      button {
        background-color: transparent;
        border: none;
      }
      .nume {
        width: 200px;
      }
      .rol {
        width: 100px;
      }
      .email {
        width: 240px;
      }
    }

    #add-button {
      path {
        pointer-events: none;
      }
      circle {
        transition: fill 0.1s ease-in-out;
        &:hover {
          fill: darken(@main-green, 20%, relative);
        }
      }
    }
  }
</style>
