import { judete } from './judete';
import * as _ from 'lodash';
import { STRINGS } from './strings';
import Vue from 'vue';
import { isEqualString } from './utils';

const mutations = {
  SET_PIN_CREATED ( state , { pin, key } ) {
  state.modalData[key] = pin
  },
  SET_PIN_DELETED ( state ) {
    Vue.delete(state.modalData, 'pin');
  },
  CALCULEAZA_TOTAL (state, filtersObj) {
    state.totalPacienti = state.pacientiClone.reduce((acc, pacient) => {
      Object.keys(filtersObj)
        .filter((key) => filtersObj[key])
        .every((key) => {
          let isMatch;
          switch (typeof filtersObj[key]) {
            case 'string':
              isMatch = pacient[key] === filtersObj[key];
              break;
            case 'number':
              if (filtersObj.deLa && filtersObj.panaLa) {
                console.log(filtersObj.deLa);
                isMatch =
                  +pacient.createdAt >= +filtersObj.deLa &&
                  +pacient.createdAt <= +filtersObj.panaLa;
              } else {
                isMatch = filtersObj.deLa
                  ? +pacient.createdAt >= +filtersObj.deLa
                  : +pacient.createdAt <= +filtersObj.panaLa;
              }
          }
          return isMatch;
        }) && (acc += 1);
      return acc;
    }, 0);

    // const filters = Object.keys(payload).reduce((acc, key) => {
    //   payload[key] && (acc[key] = payload[key]);
    //   return acc
    // }, {});
    // console.log(filters);
    // state.pacientiClone.forEach(obj => {
    //   console.log(Object.keys(filters).every(key => {
    //     console.log(filters[key]);
    //     console.log(typeof filters[key]);
    //     return true
    //   }));
    //   console.log(obj);
    //   if (Object.keys(filters).every(key => obj[key] === filters[key])) {
    //     total += 1;
    //   }
    // })
    // payload.deLa && (payload.deLa = Date.parse(payload.deLa));
    // payload.panaLa && (payload.panaLa = Date.parse(payload.panaLa));
    // const filters =
    //   Object.keys(payload)
    //     .filter(key => payload[key])
    // state.totalPacienti =
    //   state.pacientiClone
    //     .reduce((acc, curr) => {
    //       let increase = false;
    //       increase = filters.every(val => {
    //         let isInterval = false;
    //         if (val === 'deLa') {
    //           isInterval = +curr.createdAt >= +payload.deLa;
    //         }
    //         if (val === 'panaLa') {
    //           isInterval = +curr.createdAt <= +payload.panaLa;
    //         }
    //         console.log(curr[val], payload[val]);
    //         return curr[val] === payload[val] || isInterval;
    //       })
    //       increase && (acc += 1);
    //       return acc
    //     }, 0)
  },
  FILTER_TRATAMENTE_HISTORY (state, { medic = '', dinte = '' }) {
    !state.cloneHistory && (state.cloneHistory = state.pacient.history.slice());
    const results = state.cloneHistory.filter(
      (o) => isEqualString(o.medic, medic) || isEqualString(o.dinte, dinte)
    );
    Vue.set(state.pacient, 'history', results);
  },

  CLEAR_FILTER_TRATAMENTE (state) {
    if (state.cloneHistory) {
      Vue.set(state.pacient, 'history', state.cloneHistory);
      Vue.set(state, 'cloneHistory', undefined);
    }
  },

  FILTER_JUDETE (state) {
    state.judete = judete.map((obj) => obj.nume);
  },

  FILTER_ORASE_BY_JUDET (state, judet) {
    const name = judet.name || judet;
    state.orase = judete
      .filter((obj) => obj.nume.toLowerCase() === name.toLowerCase())
      .map((obj) => obj.localitati.map((o) => o.nume))[0];
  },

  FILTER_DINTI_ORIGINE (state) {
    /* trebuie sa obtin in final un array cu dinti in format text,
     * care sa contina toti dintii mai putin cei implicati in punti,
     * cei stersi sau extrasi
     * 1. dintii care nu sunt implicati in punti > creaza o lista cu numele
     * in text al dintilor > extrage numele lor din state.dantura obj
     * */
    const punti = state.pacient.history
      .filter((obj) => obj.denumire && obj.denumire.includes('punte'))
      .map((obj) => {
        return {
          direction: obj.direction,
          side: obj.side,
          start: obj.start,
          end: obj.end
        };
      });

    const puntiArr = punti.reduce((a, c) => {
      if (c.direction === 'right') {
        while (c.start <= c.end) {
          a.push(state.dantura[c.side][c.start].replace('-', ' '));
          c.start++;
        }
      } else {
        while (c.end <= c.start) {
          a.push(state.dantura[c.side][c.end].replace('-', ' '));
          c.end++;
        }
      }
      return a;
    }, []);

    const extractii = state.pacient.history
      .filter((obj) => obj.mapID === 3)
      .map((obj) => obj.dinte);

    const stersi = state.pacient.dintiStersi.map((val) =>
      val.replace('-', ' ')
    );

    const toRemove = stersi.concat(puntiArr, extractii);

    state.dintiTratamentCoroana = _.difference(state.dinti, toRemove);
  },

  SET_DINTI_DESTINATIE (state, dinteOrigine) {
    /* arata doar dintii:
     * 1. de pe partea selectata de dintele origine
     * 2. care nu sunt stersi
     * 3. care nu sunt extrasi */

    /* determina partea danturii */
    for (let key in state.dantura) {
      state.dantura[key].includes(dinteOrigine) && (state.punteSide = key);
    }

    const extractii = state.pacient.history
      .filter((obj) => obj.mapID === 3)
      .map((obj) => obj.dinte.replace(' ', '-'));

    const punti = state.pacient.history
      .filter((obj) => obj.denumire && obj.denumire.includes('punte'))
      .map((obj) => {
        return {
          direction: obj.direction,
          side: obj.side,
          start: obj.start,
          end: obj.end
        };
      });

    const puntiArr = punti.reduce((a, c) => {
      if (c.side === state.punteSide) {
        if (c.direction === 'right') {
          while (c.start <= c.end) {
            a.push(state.dantura[c.side][c.start]);
            c.start++;
          }
        } else {
          while (c.end <= c.start) {
            a.push(state.dantura[c.side][c.end]);
            c.end++;
          }
        }
      }
      return a;
    }, []);

    const toRemove = extractii.concat(state.pacient.dintiStersi, puntiArr, [
      dinteOrigine
    ]);

    state.dintiDestinatie = _.difference(
      state.dantura[state.punteSide],
      toRemove
    );
  },

  SET_PUNTE_DIRECTION (state) {
    const start = state.dantura[state.punteSide].indexOf(
      state.modalData.dinte.replace(' ', '-')
    );
    const end = state.dantura[state.punteSide].indexOf(
      state.modalData.dinteDestinatie.replace(' ', '-')
    );
    const direction = start < end ? 'right' : 'left';
    Vue.set(
      state.modalData,
      'dinteStart',
      state.modalData.dinte.replace(' ', '-')
    );
    Vue.set(state.modalData, 'start', start);
    Vue.set(state.modalData, 'end', end);
    Vue.set(state.modalData, 'side', state.punteSide);
    Vue.set(state.modalData, 'direction', direction);
    /* set punte fill color */
    const fill = state.tratamente
      .filter((obj) => obj.name === state.modalData.denumire)
      .map((obj) => obj.fill)[0];
    Vue.set(state.modalData, 'fill', fill);
  },

  REMOVE_KEY_VALUE (state, { sub, key }) {
    sub ? delete state[sub][key] : delete state[key];
  },

  MAKE_AVATAR_PIC (state, name) {
    state.avatarPic = `/history-imgs/${state.pacient._id}/${name}`;
  },

  FILTER_TRATAMENTE (state) {
    state.ddClone = state.tratamente.slice();
    switch (state.filterTratamente) {
      case STRINGS.ADD_COROANA:
        state.tratamente = state.ddClone.filter((obj) => obj.id < 2);
        break;
      case STRINGS.EXTRACTIE:
        state.tratamente = state.ddClone.filter((obj) => obj.id === 3);
        break;
      case 'implant':
        state.tratamente = state.ddClone.filter((obj) => obj.id === 4);
        break;
      case 'tratament endodontic':
        state.tratamente = state.ddClone.filter(
          (obj) => obj.id > 5 && obj.id < 6
        );
        break;
      default:
        break;
    }
  },

  SET_TRATAMENT (state, name) {
    Vue.set(state.modalData, 'denumire', name);
    state.tratamente.forEach((obj) => {
      obj.name === name && Vue.set(state.modalData, 'mapID', obj.id);
    });
  },

  SET_PLAN_TRATAMENT_ID (state, id) {
    Vue.set(state.modalData, 'planTratamentID', id);
  },

  ADD_IMGS_TO_MODAL (state, { file, picName }) {
    const formatImgName = `${state.modalData.id}-${getRandom()}-${picName}`;
    state.modalData.pics.push({
      name: formatImgName,
      w: undefined,
      h: undefined
    });
    state.formData.append(formatImgName, file);

    function getRandom () {
      return Math.floor(Math.random() * 100000);
    }
  },

  REMOVE_IMG (state, { picName }) {
    Vue.set(state.modalData, 'deleted', []);
    state.modalData.deleted.push(picName);
    let index = _.findIndex(state.modalData.pics, (o) => o.name === picName);
    state.modalData.pics.splice(index, 1);
    state.formData.delete(picName);
  },

  SET_KEY_VALUE (state, { key, sub, value }) {
    sub ? Vue.set(state[key], sub, value) : Vue.set(state, key, value);
  },

  SET_MODULE_KEY_VALUE (state, { key, module, value }) {
    Vue.set(state[module], key, value);
  },

  SEARCH_DD (state, { string, template }) {
    !state.ddClone && (state.ddClone = state[template].slice());
    state[template] = state.ddClone
      .filter((val) =>
        template === 'tratamente'
          ? val.name.toLowerCase().indexOf(string) > -1
          : val.toLowerCase().startsWith(string)
      )
      // .filter(val => template === 'tratamente' ? val.name.toLowerCase().indexOf(string) > -1 : val.toLowerCase().indexOf(string) > -1)
      .map((val) => val);
  },

  SET_VALUE (state, { key, value, template, clone }) {
    template && state.ddClone && (state[template] = state.ddClone.slice());
    state[key] = value;
  },

  RESET_DD_CLONE (state, { template }) {
    if (state.ddClone) {
      state[template] = state.ddClone.slice();
    }
    state.ddClone = undefined;
  },

  SEARCH_PATIENT (state, string) {
    const pacienti = state.pacientiClone
      .filter(
        (obj) =>
          obj.nume.toLowerCase().indexOf(string) > -1 ||
          obj.prenume.toLowerCase().indexOf(string) > -1 ||
          obj.telefon.toLowerCase().indexOf(string) > -1 ||
          obj.email.toLowerCase().indexOf(string) > -1
      )
      .map((obj) => obj);
    Vue.set(state, 'totalPacienti', pacienti.length);
    Vue.set(state, 'page', 0);
    Vue.set(state, 'pacientiSearchResults', pacienti.slice());
    Vue.set(
      state,
      'searchPagination',
      pacienti.length > state.searchResultsPerPage
    );
    Vue.set(state, 'lastPage', !state.searchPagination);
    Vue.set(state, 'pacienti', pacienti.splice(0, state.searchResultsPerPage));
    if (!string) {
      Vue.set(state, 'searchPagination', false);
      Vue.set(state, 'lastPage', false);
      Vue.set(state, 'pacienti', state.pacientiClone.slice().splice(0, 10));
      Vue.set(state, 'totalPacienti', state.pacientiClone.length);
    }
  },

  SET_MODAL (state, { val, name, setValues }) {
    setValues &&
      Object.keys(setValues).forEach((key) => {
        Vue.set(state.modalData, key, setValues[key]);
      });
    state.modalComponentName = name;
    state.isModal = val;
  },

  RESET_MODAL_DATA (state) {
    state.modalData = {
      type: undefined,
      createdAt: new Date(),
      pics: []
    };
    state.formData = new FormData();
    state.isUpdateModal = false;
  },

  ARRANGE_TRATAMENT_PICS_ARRAY (state) {
    const arr = [];
    state.modalData.type === 'tratament' &&
      ['inainte', 'dupa'].forEach((val) => {
        state.modalData.pics.forEach((obj) => {
          obj.name.includes(val) && arr.push(obj);
        });
      });
    Vue.set(state.modalData, 'pics', arr);
  },

  RESET_FILTER (state) {
    state.page = 0;
    Vue.set(state.filter, 'isFilterPatients', false);
    Vue.set(state.filter, 'payload', {});
    Vue.set(state.filter, 'bookmarks', []);
  },
  UPDATE_PACIENT (state, data) {
    Vue.set(state, 'pacient', data);
  },
  CLOSE_MODAL (state) {
    Vue.set(state, 'isModal', false);
    Vue.set(state, 'isLoading', false);
  },
  SHOW_LOADER (state, value) {
    Vue.set(state, 'isLoading', value);
  },
  SET_TRATAMENT_PRET (state, value) {
    Vue.set(state.modalData, 'pret', value);
    // value
    //   ? Vue.set(
    //       state.modalData,
    //       'pret',
    //       state.tratamente
    //         .filter(({ name }) => name === value)
    //         .map(({ pret }) => pret)[0]
    //     )
    //   : Vue.set(state.modalData, 'pret', undefined);
  },
  SET_LOGIN (state, {name, roles}) {
    state.user = {
      name,
      rol: roles[0]
    };
    state.loginErr = false;
  }

};

export default mutations;
