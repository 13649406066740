import axios from 'axios';
import Vue from 'vue';

export const state = {
  pacienti: [],
  pacientiClone: [],
  pacientiSearchClone: [],
  page: 1
};

export const actions = {
  async get_all_patients ({ state, commit }) {
    try {
      commit('SET_KEY_VALUE', { key: 'isLoading', value: true });
      const { data } = await axios('/api/pacienti');
      Vue.set(state, 'pacienti', data);
      commit('SET_KEY_VALUE', { key: 'isLoading', value: false });
    } catch (err) {
      commit('SET_KEY_VALUE', { key: 'isLoading', value: false });
      console.log(err);
    }
  }
};

export const mutations = {
  BROWSE_PACIENTI_PAGES (state, direction) {
    const page = direction === 'next' ? state.page + 1 : state.page - 1;
    Vue.set(state, 'page', page);
  },
  SEARCH_IN_PACIENTI (state, string) {
    Vue.set(state, 'page', 1);
    if (!state.pacientiSearchClone.length) {
      Vue.set(state, 'pacientiSearchClone', state.pacienti);
    }
    const pacienti = state.pacientiSearchClone
      .filter(
        ({ nume, prenume, email, telefon }) =>
          `${nume} ${prenume}`.toLowerCase().indexOf(string) > -1 ||
          email.toLowerCase().indexOf(string) > -1 ||
          telefon.toLowerCase().indexOf(string) > -1
      )
      .map((obj) => obj);
    Vue.set(state, 'pacienti', string ? pacienti : state.pacientiSearchClone);
    !string && Vue.set(state, 'pacientiSearchClone', []);
  },
  FILTER_PACIENTI (state, filters) {
    state.page = 1;
    if (!state.pacientiClone.length) {
      Vue.set(state, 'pacientiClone', state.pacienti.slice());
    }
    let clone = state.pacientiClone.slice();
    let pacienti = [];
    let stringFilters = ['judet', 'oras', 'tara', 'medic'];
    stringFilters.forEach((attr) => {
      if (filters[attr]) {
        pacienti = clone.filter(
          (pacient) =>
            pacient[attr] &&
            pacient[attr].toLowerCase() === filters[attr].toLowerCase()
        );
        Vue.set(state, 'pacienti', pacienti);
        clone = pacienti.slice();
      }
    });
    if (filters.igienizare) {
      pacienti = clone.filter(({ igienizare }) => {
        return igienizare;
      });
      Vue.set(state, 'pacienti', pacienti);
      clone = pacienti.slice();
    }
    if (filters.deLa) {
      const date = new Date(filters.deLa).setHours(0, 0);
      pacienti = clone.filter(({ createdAt }) => +createdAt >= +date);
      Vue.set(state, 'pacienti', pacienti);
      clone = pacienti.slice();
    }
    if (filters.panaLa) {
      const date = new Date(filters.panaLa).setHours(23, 59);
      pacienti = clone.filter(({ createdAt }) => +createdAt <= +date);
      Vue.set(state, 'pacienti', pacienti);
      clone = pacienti.slice();
    }
  },
  RESET_FILTERS (state) {
    state.page = 1;
    if (state.pacientiClone.length) {
      Vue.set(state, 'pacienti', state.pacientiClone.slice());
    } else if (state.pacientiSearchClone.length) {
      Vue.set(state, 'pacienti', state.pacientiSearchClone.slice());
    }
    Vue.set(state, 'pacientiClone', []);
    Vue.set(state, 'pacientiSearchClone', []);
  }
};
