import Vue from 'vue';
import axios from 'axios';
import router from '@/router';
import api from '../api';

export const actions = {
  async handle_pacient ({ rootState, dispatch }, pacient) {
    try {
      Vue.set(rootState, 'isLoading', true);
      pacient.birthdate = pacient.date;
      delete pacient.date;
      pacient.telefon = pacient.telefon.replace(/ /g, '');
      dispatch(pacient.id ? 'update_pacient' : 'save_new_pacient', pacient);
    } catch (err) {
      console.log('eroare handle pacient');
    }
  },
  async save_new_pacient ({ rootState }, pacient) {
    try {
      pacient.createdAt = Date.now();
      const { data } = await axios.post(api.pacient, pacient);
      const pacienti = [data, ...rootState.pacientiModule.pacienti];
      Vue.set(rootState.pacientiModule, 'pacienti', pacienti);
      Vue.set(rootState, 'isLoading', false);
      await router.push('/pacienti');
    } catch (err) {
      window.alert('Eroare salvare pacient.');
    }
  },
  async update_pacient ({ rootState }, pacient) {
    try {
      const { data } = await axios.put(
        `${api.pacient}/${router.currentRoute.params.id}`,
        pacient
      );
      const pacientiFiltered = rootState.pacientiModule.pacienti
        .filter(({ id }) => id.toString() !== pacient.id.toString())
        .map((o) => o);
      Vue.set(rootState.pacientiModule, 'pacienti', [
        data,
        ...pacientiFiltered
      ]);
      rootState.isLoading = false;
      await router.push('/pacienti');
    } catch (err) {
      window.alert('Eroare update date pacient.');
    }
  }
};

export const mutations = {};
