<template>
  <div class="pdf-doctors mright20px mtop10px">
    <div v-if="type === 'coordonator'" class="flex align-center">
      <div class="portrait relative mauto">
        <div class="absolute no-scroll z9999">
          <img v-if="setDoctor.img" :src="require(`../assets/doctors/${setDoctor.img}`)" alt="Premium Dental">
        </div>
      </div>
      <div class="coordonator-wrapper">
        <p class="dr-name">{{ setDoctor.name }}</p>
        <p v-show="setDoctor.occupation">{{ setDoctor.occupation }}</p>
        <p v-show="setDoctor.tel">{{ setDoctor.tel }}</p>
        <p v-show="setDoctor.email">{{ setDoctor.email }}</p>
      </div>
    </div>

    <div v-else-if="type === 'doctor'">
      <div class="portrait relative mauto">
        <div class="absolute no-scroll z9999">
          <img :src="require(`../assets/doctors/${getDoctor.img}`)" alt="Premium Dental">
        </div>
      </div>
      <p class="dr-name">dr. {{ getDoctor.name }}</p>
      <p v-show="getDoctor.occupation">{{ getDoctor.occupation }}</p>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'PdfDoctors',

    props: {
      name: String(),
      type: String
    },

    data () {
      return {
        coordinators: [
          {
            name: 'Sebastian Miclea',
            occupation: 'Estetică dentară, implantologie digitală',
            img: 'Sebastian-Miclea.png'
          },
          {
            name: 'Adriana Jula',
            occupation: 'Coordonator tratament',
            img: 'Adriana-Jula.png',
            tel: '+40 749 358997',
            email: 'adriana.jula@premiu-dental.ro'
          }
        ],
        doctors: [
          {
            name: 'Roxana Brisc',
            occupation: 'Odototerapie',
            img: 'Roxana-Brisc.png'
          },
          {
            name: 'Andreea Miron',
            occupation: 'Odototerapie',
            img: 'Andreea-Miron.png'
          },
          {
            name: 'Denisa Golban',
            occupation: 'Ortodontie',
            img: 'Denisa-Golban.png'
          },
          {
            name: 'Simina Muresan',
            occupation: 'Parodontologie',
            img: 'Simina-Muresan.png'
          },
          {
            name: 'Barbatei Georgiana',
            occupation: 'Igienizare GBT',
            img: 'Georgiana-Barbatei.png'
          },
          {
            name: 'Sebastian Miclea',
            occupation: 'Estetica dentara & implantologie',
            img: 'Sebastian-Miclea.png'
          },
          {
            name: 'Dan Veropaieff',
            occupation: 'Implantologie',
            img: 'Dan-Veropaieff.png'
          }
        ],
        setDoctor: []
      }
    },

    computed: {
      getDoctor () {
        console.log(this.doctors.find(({ name }) => name === this.name))
        if (this.doctors.find(({ name }) => name === this.name)) {
          this.setDoctor = this.doctors.find(({ name }) => name === this.name);
        } else {
          this.setDoctor.name = this.name
          this.setDoctor.img = 'no-avatar.png'
        }
        return this.setDoctor
      }
    },

    methods: {
      getCoordonator () {
        if (this.type === 'coordonator') this.setDoctor = this.coordinators.find(({ name }) => name === this.name);
      }
    },

    watch: {
      name: function (val) {
        if (val) this.getCoordonator()
      }
    }
  }
</script>

<style lang="less">
  .pdf-doctors {
    font-family: 'AppleBraille', 'Arial', SansSerif,serif;
    font-size: 8px;
    color: #fff;
    width: fit-content;
    .portrait {
      border-radius: 50%;
      width: 65px;
      height: 65px;
      background: #ECF0F3;
      border: solid 0.2px #D1D9E6;
      box-shadow: inset -2px -2px 3px #fff,
                  inset 2px 2px 3px #B8B9BE;
      &:before {
        position: absolute;
        z-index: 9998;
        content: "";
        border-radius: 50%;
        width: 55px;
        height: 55px;
        background: #ECF0F3;
        border: solid 0.2px #D1D9E6;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        box-shadow: -2px -2px 3px #fff,
                    2px 2px 3px #B8B9BE;
      }
      div {
        border-radius: 50%;
        width: 55px;
        height: 55px;
        background: #ECF0F3;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        img {
          position: absolute;
          z-index: 9999;
          object-fit: cover;
          width: 100%;
          bottom: 0;
        }
      }

    }

    .dr-name {
      font-size: 12px;
      line-height: 20px;
      text-align: center;
    }
    p {
      text-align: center;
      font-size: 10px;
    }

    .coordonator-wrapper {
      margin-left: 15px;
      .dr-name {
        font-size: 12px;
        line-height: 20px;
        text-align: left;
      }
      p {
        text-align: left;
        font-size: 10px;
        line-height: 13px;
      }
    }
  }
</style>
