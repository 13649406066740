export const getters = {
  tratament: (state) => {
    let tratament;
    state.pacient.history.forEach((obj) => {
      !tratament &&
        (obj.dinte && obj.dinte.replace(' ', '-')) ===
          state.modalData.dinteStart &&
        (tratament = obj.denumire);
    });
    return tratament;
  }
};
