<template>
  <div class="pdf-footer flex columns justify-center">
    <div class="horizontal-line"></div>
    <p class="cover-p">+40 (770) 707 601 &#8226; office@premium-dental.ro &#8226; www.premium-dental.ro</p>
    <p class="cover-p"><span>Arad, Str. Ion C. Bratianu Nr. 5A</span></p>
    <figure class="diamond">
      <img src="../assets/pdfAssets/diamond.png" alt="Premium Dental">
    </figure>
  </div>
</template>

<script>
  export default {
    name: 'PdfFooter'
  }
</script>

<style lang="less">
  .pdf-footer {
    position: relative;
    z-index: 9999;
    margin: 20px 0;
    .horizontal-line {
      margin-bottom: 20px;
      position: absolute;
      top: -10px;
      width: 50%;
      height: 2px;
      background: #fff;
    }
    .cover-p {
      //font-size: 9px;
      font-size: 12px;
      top: 21%;
      text-align: center;
      color: #fff;
      font-family: 'Roboto', 'Arial', SansSerif,serif;
      line-height: 20px;
      span {
        font-weight: 700;
      }
    }

    .diamond {
      img {
        width: 68.57%;
        position: absolute;
        bottom: -10px;
        right: -8%;
        opacity: .3;
      }
    }
  }

</style>
