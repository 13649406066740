export const regexValidation = {
  methods: {
    _input (ev, type) {
      switch (type) {
        case 'alpha':
          !/[a-zA-Z ]/.test(ev.key) && ev.preventDefault();
          break;
        case 'digits':
          !/[0-9+]/.test(ev.key) && ev.preventDefault();
          break;
        case 'card_expiration':
          !/[0-9\/]/.test(ev.key) && ev.preventDefault();
          break;
      }
    }
  }
};
