<template>
  <section
    v-if="isUpdateModal || $store.state.isEditScrisoare"
    class="delete-tratament-btn flex flex-center"
  >
    <button @click.stop="promptDelete">
      <i v-html="svg.delete"></i>
    </button>

    <div class="prompt-delete" v-if="isDeletePrompt">
      <p>Sunteți sigur că doriți să ștergeți acest tratament?</p>
      <div class="flex flex-between">
        <span class="pointer" @click="promptDelete('no')">NU</span>
        <span class="pointer" @click.stop="deleteTratament">DA</span>
      </div>
    </div>
  </section>
</template>

<script>
  import { mapState } from 'vuex';
  import { svg } from '../store/svg';

  export default {
    name: 'delete-tratament-btn',

    data () {
      return {
        svg,
        isDeletePrompt: false
      };
    },

    computed: {
      ...mapState({
        data: (state) => state.modalData,
        isUpdateModal: 'isUpdateModal'
      })
    },

    methods: {
      promptDelete (action) {
        this.isDeletePrompt = action !== 'no';
        this.$emit('set-delete-mode', action !== 'no');
        this.$emit('notify-deletion', action === 'no');
      },
      deleteTratament () {
        this.$emit('delete-tratament', this.isDelete);
      }
    }
  };
</script>

<style lang="less">
  @import "../assets/styles/mixin";
  @import "../assets/styles/var";

  .delete-tratament-btn {
    & > button {
      background-color: @red!important;
      transition: background-color 0.2s ease-in-out;
      border: none;
      cursor: pointer;
      svg circle {
        transition: fill 0.1s ease-in-out;
      }

      &:hover {
        background-color: darken(red, 20%, relative) !important;
      }
    }

    .prompt-delete {
      width: 200px;
      margin-top: 20px;
      text-align: center;
      font-size: 1.3rem;
      div {
        width: 50%;
        margin: 8px auto 0;
        span {
          width: 40px;
          height: 20px;
          padding-top: 4px;
          text-align: center;
          line-height: 1.4rem;
          font-weight: bold;
          border-radius: 4px;
          color: white;
          transition: background-color 0.1s ease-in-out;
          &:first-child {
            background-color: @red;
            &:hover {
              background-color: darken(@red, 20%, relative);
            }
          }
          &:last-child {
            background-color: @main-green;
            &:hover {
              background-color: darken(@main-green, 20%, relative);
            }
          }
        }
      }
    }
  }
</style>
