<template>
  <div class="base-dropdown relative">
    <validation-provider :rules="rules" v-slot="{ errors }">
      <input
        type="text"
        class="w100"
        :class="{ 'invalid-input': !searching && errors[0] }"
        v-model="model"
        @focus="focus"
        @blur="blur"
        ref="input"
        :placeholder="placeholder"
      />
      <font-awesome-icon
        v-show="!model"
        icon="caret-down"
        class="icon dd-icon pointer"
        @click="focus"
      />
      <font-awesome-icon
        v-show="model"
        class="icon close-icon absolute pointer"
        @click="clear"
        icon="times"
      />
      <ul
        class="absolute left0 z99 w100"
        :class="{ 'is-searching': searching }"
        ref="listUL"
      >
        <li
          v-for="(item, index) in list"
          :key="index"
          tabindex="-1"
          :class="{ 'is-navigating': index === listIndex }"
          @mousedown="select(item)"
        >
          <span>{{ item }}</span>
        </li>
      </ul>
    </validation-provider>
  </div>
</template>

<script>
  export default {
    name: 'BaseDropdown',
    props: {
      data: Array,
      name: String,
      rules: String,
      placeholder: String
    },
    data () {
      return {
        searching: false,
        model: undefined,
        listIndex: 0
      };
    },
    computed: {
      list () {
        return this.model
          ? this.data.filter((val) =>
              val.toLowerCase().includes(this.model.toLowerCase())
            )
          : this.data;
      }
    },
    methods: {
      focus () {
        this.searching = true;
        document.addEventListener('keydown', this.navigate);
        this.$nextTick(() => {
          this.$refs.input.focus();
        });
      },
      blur () {
        this.searching = false;
        this.removeEvListener();
      },
      clear () {
        this.model = undefined;
        this.removeEvListener();
        this.$emit('handle-clear', { name: this.name });
      },
      removeEvListener () {
        document.removeEventListener('keydown', this.navigate);
      },
      select (value) {
        this.model = value;
        this.searching = false;
        this.$emit('handle-select', { name: this.name, value });
        this.listIndex = 0;
        this.removeEvListener();
      },
      navigate (ev) {
        switch (ev.key) {
          case 'ArrowDown':
            this.listIndex > 2 &&
              this.$refs.listUL.scroll({
                top: 38 * this.listIndex,
                behavior: 'smooth'
              });
            this.listIndex < this.list.length - 1 && (this.listIndex += 1);
            break;
          case 'ArrowUp':
            this.$refs.listUL.scroll({
              top: 38 * (this.listIndex - 2),
              behavior: 'smooth'
            });
            this.listIndex > 0 && (this.listIndex -= 1);
            break;
          case 'Enter':
            ev.preventDefault();
            ev.stopPropagation();
            this.select(this.list[this.listIndex]);
            this.$refs.input.blur();
            break;
          case 'Escape':
            this.blur();
            this.$refs.input.blur();
            break;
        }
      }
    }
  };
</script>

<style lang="less">
  .base-dropdown {
    ul {
      height: 0;
      top: 34px;
      overflow-y: scroll;
      border-radius: 8px;
      border: 1px solid lightgrey;
      background-color: white;
      font-size: 1.6rem;
      opacity: 0;
      transition: height 0.15s ease-in-out;
    }
    li {
      padding: 10px 16px;
      cursor: default;
      &:hover {
        background-color: #f1f1f1;
      }
    }
    .is-searching {
      height: 168px;
      opacity: 1;
    }
    .is-navigating {
      background-color: #f1f1f1;
    }
    .icon {
      font-size: 2rem;
      opacity: 0.5;
      position: absolute;
    }
    .dd-icon {
      right: 14px;
      top: 7px;
    }
    .close-icon {
      right: 14px;
      top: 8px;
    }
  }
</style>
