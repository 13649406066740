<template>
  <div class="pdf-table">
    <p class="pdf-count">etapa {{ etapa.id }}</p>
    <table>
      <thead>
        <tr>
          <th>tratament</th>
          <th>index dinti (nr. total)</th>
          <th>pret unitar</th>
          <th>total</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="tratament in etapa.tratamente">
          <td>{{ tratament.tratament.name }}</td>
          <td>{{ teethToString(tratament.dinti) }}</td>
          <td>{{ teethPrice((tratament.tratament.pret * price1ron).toFixed(1), tratament.dinti.length )}}</td>
          <td>{{ (tratament.tratament.pret * price1ron).toFixed(1) }} €</td>
        </tr>
      </tbody>
      <tfoot>
      <tr>
        <td colspan="4">preț total etapă: {{ (etapa.total_etapa * price1ron).toFixed(1) }} €</td>
      </tr>
      </tfoot>
    </table>
  </div>
</template>

<script>
export default {
  name: 'PdfTable',
  props: {
    etapa: Object,
    price1ron: Number
  },

  methods: {
    teethToString (dinti) {
      return dinti.join(', ')
    },
    teethPrice (pretTotal, cantitate) {
      let pricePerUnit = '-'
      if (cantitate !== 0) pricePerUnit = `${(pretTotal / cantitate).toFixed(1)} €`
      return pricePerUnit
    }
  }
}
</script>

<style lang="less">
  .pdf-table {
    font-family: 'AppleBraille', 'Arial', SansSerif,serif;

    .pdf-count {
      font-size: 19px;
      color: #fff;
      margin-top: 15px;
    }

    table {
      width: 100%;
      background: #fff;
      border-radius: 5px;
      box-shadow: 2px 2px 6px rgba(100, 100, 100, 0.5);
      padding: 8px 16px;
      margin-top: 7px;
      thead {
        position: relative;
        height: 27px;
        tr {

          th {
            font-family: 'Roboto', 'Arial', SansSerif,serif;
            font-weight: 300;
            color: #373737;
            font-size: 12px;
            white-space: nowrap;
            &:nth-child(1) {
              text-align: left;
            }
            &:nth-last-child(2) {
              text-align: right;
            }
            &:nth-last-child(1) {
              position: absolute;
              border-bottom: 1.5px solid #25ABD6;
              width: 100%;
              left: 0;
              bottom: 3px;
            }
          }
        }
      }

      tbody {
        tr {
          vertical-align: top;
          td {
            padding: 5px 0;

            font-size: 12px;
            text-align: center;
            color: #212121;
          }
          td:nth-child(1) {
            text-align: left;
          }
          td:nth-child(2) {
            width: 48ch;
            font-size: 9px;
          }
          td:nth-last-child(1) {
            text-align: right;
            white-space: nowrap;
          }
          td:nth-last-child(2) {
            white-space: nowrap;
          }
        }
      }

      tfoot {
        text-align: right;
        font-size: 12px;
        font-weight: 500 ;
        tr {
          td {
            padding-top: 7px;
            border-top: solid 1.5px #25ABD6;
          }
        }
      }
    }
  }
</style>
