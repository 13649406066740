<template>
  <div class="pdf-container">
    <figure class="logo-in-line">
      <img src="../assets/pdfAssets/logo-in-line.png" alt="Premium Dental">
    </figure>
    <figure class="motto">
      <img src="../assets/pdfAssets/motto.png" alt="Premium Dental">
    </figure>
    <h2 class="cover-h2">Plan de tratament estimativ</h2>
    <figure class="bg-img">
      <img src="../assets/pdfAssets/bg.png" alt="Premium Dental">
    </figure>
    <p class="cover-p">+40 (770) 707 601 &#8226; office@premium-dental.ro &#8226; www.premium-dental.ro</p>
    <p class="cover-p"><span>Arad, Str. Ion C. Bratianu Nr. 5A</span></p>
  </div>
</template>

<script>
export default {
  name: 'PdfCover'
}
</script>

<style lang="less">
  .pdf-container {
    overflow: hidden;
    background: #9DDCF9;
    position: relative;
    margin: 0 auto;
    &::after {
      content: "";
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(125deg, rgba(0,182,241,1) 0%, rgba(0,182,241,0.4) 100%);
    }

    .logo-in-line {
      position: relative;
      z-index: 9999;
      top: 3.91%;
      left: 4.75%;
      img {
        width: 21.41%;
      }
    }

    .motto {
      position: relative;
      z-index: 9999;
      top: 12.82%;
      img {
        display: block;
        margin: 0 auto;
        width: 43.52%;
      }
    }

    .cover-h2 {
      position: relative;
      z-index: 9999;
      top: 15%;
      left: 3%;
      text-align: center;
      color: #fff;
      font-family: 'Roboto', 'Arial', SansSerif,serif;
      font-weight: 500;
      //font-size: 12px;
      font-size: 15px;
    }

    .bg-img {
      position: relative;
      top: 19%;
      img {
        display: block;
        margin: 0 auto;
        width: 73.10%;
      }
    }

    .cover-p {
      position: relative;
      z-index: 9999;
      //font-size: 9px;
      font-size: 12px;
      top: 21%;
      text-align: center;
      color: #fff;
      font-family: 'Roboto', 'Arial', SansSerif,serif;
      line-height: 20px;

      span {
        font-weight: 700;
      }
    }


    //@media (min-width: 576px){
    //  .cover-h2 {
    //    font-size: 15px;
    //  }
    //  .cover-p {
    //    font-size: 12px;
    //  }
    //}
    //
    //@media (min-width: 768px){
    //  .cover-h2 {
    //    font-size: 22px;
    //  }
    //  .cover-p {
    //    font-size: 16px;
    //  }
    //}
    //
    //@media (min-width: 992px){
    //  .cover-h2 {
    //    font-size: 32px;
    //  }
    //  .cover-p {
    //    font-size: 22px;
    //  }
    //}
  }
</style>
