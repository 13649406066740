<template>
  <div class="pdf-last-page">
    <div class="last-page-size">
      <div class="last-page-wrapper relative z9999">
        <!--  X-RAY image    -->
        <div class="x-ray mtop20px">
          <img :src="`/history-imgs/${plan.user}/${plan.radiografie}`" alt="Premium Dental">
          <p class="mleft15px mright12px mtop15px">Acest document si orice element atasat lui este confidential si se adreseaza doar persoanei care le primeste in mod direct si nemijlocit din partea personalului Premium Dental, in forma lui tiparita sau prin email.</p>
        </div>
        <!--  SVG Teeth schema    -->
        <PdfTeeth :dinti="plan.dinti_in_lucru" />

        <!--  Images with doctors    -->
        <div class="portrait-wrapper mtop15px">
          <p class="portrait-title">Medici desemnati in cazul dumneavoastra:</p>
          <div class="flex flex-wrap">
            <div v-for="doctor in plan.medici_desemnati">
              <PdfDoctors :name="doctor" type="doctor" />
            </div>
          </div>
          <!--  Images with coordinator    -->
          <p class="portrait-title mtop10px">Coordonator tratament:</p>
          <div class="flex">
            <PdfDoctors :name="plan.medicCoordonator" type="coordonator" />
          </div>
        </div>
        <p class="pdf-notes mtop15px">Acest document reprezinta un plan de tratament estimativ si poate suferi modifficari in functie de starea clinica si de evolutia tratamentului ortodontic.</p>
        <p class="pdf-notes mtop5px">Preturile sunt in EURO la cursul BNR.</p>
      </div>
    </div>
    <PdfFooter class="relative z9" />
  </div>
</template>

<script>
  import PdfDoctors from './PdfDoctors.vue';
  import PdfTeeth from './PdfTeeth.vue';
  import PdfFooter from './PdfFooter.vue';
  export default {
    name: 'PdfLastPage',
    components: {
      PdfDoctors,
      PdfTeeth,
      PdfFooter
    },

    props: {
      plan: Object
    }
  }
</script>

<style lang="less">
  .pdf-last-page {
    background: rgba(0,182,241,1);
    background: linear-gradient(125deg, rgba(0,182,241,1) 0%, rgba(91, 204, 246, 1) 100%);
    .last-page-size {
      height: 765px;
      .last-page-wrapper {
        width: 84%;
        margin: 0 auto;
        padding: 10px 0;

        .x-ray {
          height: 245px;
          border: solid 1px #fff;

          img {
            height: 170px;
            display: block;
            margin: 20px auto 0 auto;
          }

          p {
            margin-bottom: 10px;
            color: #fff;
            font-size: 10px;
            font-weight: 300;
            font-family: 'Roboto', 'Arial', SansSerif, serif;
          }
        }

        .portrait-wrapper {
          font-family: 'AppleBraille', 'Arial', SansSerif, serif;

          .portrait-title {
            color: #fff;
            font-size: 14px;
          }
        }

        .pdf-notes {
          color: #fff;
          font-size: 10px;
          font-weight: 500;
          font-family: 'Roboto', 'Arial', SansSerif, serif;

          &:nth-child(2) {
            padding-top: 14px;
            font-weight: 300;
          }
        }
      }
    }
  }
</style>
