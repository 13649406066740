export const checkObjProps = (obj = {}, props = '') => {
  const arr = props.split('.');
  let i = 0;
  while (i < arr.length) {
    obj = obj[arr[i]];
    if (!obj) break;
    i++;
  }
  return obj;
};

export const lowerCase = (string) => {
  return string ? string.toLowerCase() : undefined;
};

export const isEqualString = (value, other) => {
  return value && other ? value.toLowerCase() === other.toLowerCase() : false;
};

export const msToDays = (ms) => ms / 1000 / 60 / 60 / 24;

export const daysToMs = (days) => days * 1000 * 60 * 60 * 24;

export const nextDate = (number) =>
  new Date(new Date().setDate(new Date().getDate() + number));
