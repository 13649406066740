import * as axios from 'axios';
import router from '../router';
import Vue from 'vue';
import { checkObjProps, daysToMs } from './utils';

const actions = {
  async block_pacient ({ state }) {
    try {
      Vue.set(state, 'isLoading', true);
      const { data } = await axios.post('/api/block-pacient', {
        id: state.pacient._id
      });
      Vue.set(state, 'pacient', data);
      Vue.set(state, 'isLoading', false);
    } catch (e) {
      Vue.set(state, 'isLoading', false);
      console.log('cannot block pacient', e);
    }
  },

  save_punte (
    { state },
    { side, start, end, direction, fill, denumire, clbk = () => {} }
  ) {
    save();

    async function save () {
      try {
        state.isLoading = true;
        const payload = {
          type: 'tratament',
          denumire: state.modalData.denumire || denumire,
          createdAt: new Date(),
          id: new Date().getTime(),
          side,
          dinte: state.dantura[side][start],
          dinteStart: state.dantura[side][start],
          dinteDestinatie: state.dantura[side][end],
          start,
          end,
          direction,
          fill
        };
        const { data } = await axios.post(
          `/api/save-punte/${router.currentRoute.params.id}`,
          payload
        );
        clbk();
        state.isLoading = false;
        state.pacient.history.unshift(payload);
        state.modalData.dinte = undefined;
      } catch (e) {
        console.log(e.response.data);
      }
    }
  },

  delete_punte ({ state, commit }) {
    deletePunte();

    async function deletePunte () {
      try {
        const id = router.currentRoute.params.id;
        const idPunte = state.modalData.id;
        const { data } = await axios(`/api/delete-punte/${id}/${idPunte}`);
        state.pacient.history = data;
        state.isModal = false;
        commit('RESET_MODAL_DATA');
      } catch (e) {
        console.log(e.response.data);
      }
    }
    state.isModal = false;
    commit('RESET_MODAL_DATA');
  },

  save_scrisoare ({ state, commit }) {
    save();

    async function save () {
      try {
        const { data } = await axios.post('/api/save-scrisoare', {
          id: router.currentRoute.params.id,
          text: state.textScrisoare
        });
        commit('SET_MODAL', {
          name: undefined,
          val: false
        });
        state.pacient = data;
        window.print();
      } catch (e) {
        console.log(e);
      }
    }
  },

  delete_avatar ({ state, commit }) {
    state.isLoading = true;
    delImg();

    async function delImg () {
      try {
        const id = state.pacient._id;
        const index = state.avatarPic.indexOf('avatar');
        const name = state.avatarPic.substr(index, state.avatarPic.length);
        const { data } = await axios.post('/api/delete-avatar-img', {
          id,
          name
        });
        state.avatarPic = undefined;
        commit('SET_MODAL', {
          name: undefined,
          val: false
        });
        caches.open('v1').then(function (cache) {
          cache
            .delete(`/history-imgs/${id}/avatar.png`)
            .then(function (response) {
              // img removed from cache
            });
        });
        state.isLoading = false;
      } catch (e) {
        state.isLoading = false;
        console.log(e.response.data);
      }
    }
  },

  upload_avatar ({ state, commit }, file) {
    state.isLoading = true;
    saveImg();

    async function saveImg () {
      try {
        const { data } = await axios.post('/api/save-avatar-img', file);
        commit('MAKE_AVATAR_PIC', data);
        state.isLoading = false;
      } catch (e) {
        state.isLoading = false;
        window.alert('Eroare server.');
      }
    }
  },

  delete_tratament ({ state, commit }, { id }) {
    state.isLoading = true;
    sterge();

    async function sterge () {
      try {
        const { data } = await axios.post(
          `/api/sterge-tratament/${id}`,
          state.modalData
        );
        state.pacient.history.slice().forEach((obj, i) => {
          obj.id === data.id && state.pacient.history.splice(i, 1);
        });
        state.isModal = false;
        commit('RESET_MODAL_DATA');
        state.isLoading = false;
      } catch (e) {
        console.log(e);
        window.alert('Eroare server, va rugam contactati web master.');
      }
    }
  },

  sterge_dinte ({ state }, { dinte, clbk }) {
    sterge();

    async function sterge () {
      try {
        const {
          data
        } = await axios.post(
          `/api/sterge-dinte/${router.currentRoute.params.id}`,
          { dinte }
        );
        if (!state.pacient.dintiStersi) {
          Vue.set(state.pacient, 'dintiStersi', []);
        }
        let index = state.pacient.dintiStersi.indexOf(dinte);
        index > -1
          ? state.pacient.dintiStersi.splice(index, 1)
          : state.pacient.dintiStersi.push(dinte);
        clbk && clbk();
      } catch (e) {
        console.log(e);
        console.log('dintele nu a fost sters');
      }
    }
  },

  delete_user ({ state, dispatch }) {
    state.isLoading = true;
    axios(`/api/delete-user/${state.modalData.email}/${state.modalData._id}`)
      .then(({ data }) => {
        state.isLoading = false;
        state.isModal = false;
        state.utilizatori.forEach((obj, index) => {
          obj._id === data.id && state.utilizatori.splice(index, 1);
        });
        dispatch('get_utilizatori');
      })
      .catch((e) => {
        console.log(e.response ? e.response.data : e);
      });
  },

  update_doc_history ({ state, commit }, { id }) {
    state.formData.append('obj', JSON.stringify(state.modalData));
    state.isLoading = true;
    update();
    async function update () {
      try {
        const { data } = await axios.post(
          `/api/update-doc/${id}`,
          state.formData
        );
        state.pacient.history.forEach((obj, index) => {
          if (obj.id === data.id) {
            Vue.set(state.pacient.history, index, data);
          }
        });
        state.isModal = false;
        commit('RESET_MODAL_DATA');
        state.isLoading = false;
      } catch (e) {
        console.log(e);
      }
    }
  },

  save_utilizator ({ state, dispatch, commit }) {
    state.isLoading = true;
    delete state.modalData.createdAt;
    delete state.modalData.pics;
    save();
    async function save () {
      try {
        const { data } = await axios.post(
          '/api/save-utilizator',
          state.modalData
        );
        state.utilizatori.unshift(data);
        state.isModal = false;
        state.isLoading = false;
        state.isExistingUser = undefined;
        commit('RESET_MODAL_DATA');
        dispatch('get_utilizatori');
      } catch (e) {
        state.isLoading = false;
        const status = e.response.status;
        if (status === 409) state.isExistingUser = true;
      }
    }
  },

  update_utilizator ({ state, commit }) {
    state.isLoading = true;
    const id = state.modalData._id;
    delete state.modalData.id;
    update();
    async function update () {
      try {
        const { data } = await axios.post(
          `/api/update-utilizator/${id}`,
          state.modalData
        );
        state.utilizatori.forEach((obj, index) => {
          if (obj._id === id) {
            Vue.set(state.utilizatori, index, data);
          }
        });
        state.isModal = false;
        state.isLoading = false;
        commit('RESET_MODAL_DATA');
      } catch (e) {
        console.log(e);
      }
    }
  },

  async save_history ({ state, commit }, { id }) {
    state.modalData.id = new Date().getTime();
    state.modalData.type === 'tratament' &&
      commit('ARRANGE_TRATAMENT_PICS_ARRAY');
    state.formData.append('obj', JSON.stringify(state.modalData));
    state.isLoading = true;
    try {
      const { data } = await axios.post(
        `/api/save-history/${id}`,
        state.formData
      );
      !state.pacient.history && Vue.set(state.pacient, 'history', []);
      /* receive the history array from the server, and update it */
      state.pacient = data;
      state.isModal = false;
      commit('RESET_MODAL_DATA');
      state.isLoading = false;
    } catch (e) {
      window.alert('Eroare salvare tratament.');
    }
  },

  async update_history ({ state, commit }, { id }) {
    state.modalData.type === 'tratament' &&
      commit('ARRANGE_TRATAMENT_PICS_ARRAY');
    state.formData.append('obj', JSON.stringify(state.modalData));
    state.isLoading = true;
    try {
      const { data } = await axios.post(
        `/api/update-history/${id}`,
        state.formData
      );
      state.pacient.history.forEach((obj, index) => {
        if (obj.id === data.id) {
          Vue.set(state.pacient.history, index, data);
        }
      });
      state.isModal = false;
      commit('RESET_MODAL_DATA');
      state.isLoading = false;
    } catch (e) {
      window.alert('Eroare salvare tratament.');
    }
  },

  async get_patient ({ state, commit }, { id, clbk }) {
    try {
      Vue.set(state, 'isLoading', true);
      const { data } = await axios(`/api/get-pacient/${id}`);
      Vue.set(state, 'pacient', data);
      /* get avatar pic */
      data._attachments &&
        Object.keys(data._attachments).forEach((key) => {
          key.indexOf('avatar') > -1 && commit('MAKE_AVATAR_PIC', key);
        });
      state.antecedente = Object.keys(data.antecedente)
        .filter((key) => key !== 'alergii')
        .some((key) => data.antecedente[key].val);
      state.alergii = Object.keys(data.antecedente)
        .filter((key) => key === 'alergii')
        .some((key) => data.antecedente[key].val);
      Vue.set(
        state,
        'programari',
        data.programari
          ? data.programari.sort(
              (a, b) =>
                new Date(b.createdAt).getTime() -
                new Date(a.createdAt).getTime()
            )
          : []
      );
      clbk && clbk(data);
      Vue.set(state, 'isLoading', false);
    } catch (e) {
      Vue.set(state, 'isLoading', false);
      console.log('get pacient err', e);
    }
  },

  async refresh_patient ({ state, commit }, { id }) {
    try {
      const { data } = await axios(`/api/get-pacient/${id}`);
      Vue.set(state, 'pacient', data);
      /* get avatar pic */
      data._attachments &&
        Object.keys(data._attachments).forEach((key) => {
          key.indexOf('avatar') > -1 && commit('MAKE_AVATAR_PIC', key);
        });
      state.antecedente = Object.keys(data.antecedente)
        .filter((key) => key !== 'alergii')
        .some((key) => data.antecedente[key].val);
      state.alergii = Object.keys(data.antecedente)
        .filter((key) => key === 'alergii')
        .some((key) => data.antecedente[key].val);
      Vue.set(
        state,
        'programari',
        data.programari
          ? data.programari.sort(
              (a, b) =>
                new Date(b.createdAt).getTime() -
                new Date(a.createdAt).getTime()
            )
          : []
      );
    } catch (err) {
      Vue.set(state, 'isLoading', false);
      console.log('get pacient err', err);
    }
  },

  get_utilizatori ({ state }, direction) {
    getUtilizatori();
    async function getUtilizatori () {
      try {
        if (direction) {
          direction === 'next' ? state.page++ : state.page--;
        }
        const { data } = await axios.post('/api/get-utilizatori', {
          page: state.page
        });
        state.lastPage = !data.isNextPage;
        state.utilizatori = data.utilizatori;
      } catch (e) {
        console.log('get patients error');
      }
    }
  },

  save_pacient ({ rootState, state }, payload) {
    state.isLoading = true;
    /* in case the date has changed, we reupdate the birthdate */
    payload.birthdate = payload.date;
    delete payload.date;
    payload.telefon = payload.telefon.replace(/ /g, '');
    payload.id ? update() : save();
    async function save () {
      payload.createdAt = Date.now();
      try {
        const { data } = await axios.post('/api/save-pacient', payload);
        const pacienti = [data, ...rootState.pacientiModule.pacienti];
        Vue.set(rootState.pacientiModule, 'pacienti', pacienti);
        state.isLoading = false;
        await router.push('/pacienti');
      } catch (err) {
        state.isLoading = false;
        console.log('err', err);
      }
    }
    async function update () {
      try {
        const { data } = await axios.post(
          `/api/update-pacient/${router.currentRoute.params.id}`,
          payload
        );
        let pacientId;
        rootState.pacientiModule.pacienti.forEach((o, idx) => {
          +o.id === +payload.id && (pacientId = idx);
        });
        // sterge pacientul editat din matrice
        rootState.pacientiModule.pacienti.splice(pacientId, 1);
        rootState.pacientiModule.pacienti.unshift(data);
        state.isLoading = false;
        await router.push('/pacienti');
      } catch (e) {
        state.isLoading = true;
        console.log('err');
      }
    }
  },

  delete_pacient ({ state }) {
    state.isLoading = true;
    del();

    async function del () {
      try {
        const { data } = await axios(
          `/api/delete-pacient/${router.currentRoute.params.id}`
        );
        state.isLoading = false;
        state.isModal = false;
        data && router.push('/pacienti');
      } catch (e) {
        console.log(e.response.data);
      }
    }
  },

  async get_medici ({ state }) {
    try {
      const { data } = await axios.get('/api/get-medici');
      Vue.set(state, 'medici', data);
    } catch (e) {
      console.log(e);
    }
  },

  async save_programare ({ state }) {
    try {
      Vue.set(state, 'isLoading', true);
      const { data } = await axios.post(
        `/api/save-programare/${router.currentRoute.params.id}`,
        state.modalProgramare
      );
      Vue.set(
        state,
        'programari',
        data.sort(
          (a, b) =>
            new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
        )
      );
      Vue.set(state.pacient, 'programari', data);
      Vue.set(state, 'isLoading', false);
      Vue.set(state, 'isModal', false);
      Vue.set(state, 'modalComponentName', undefined);
    } catch (e) {
      Vue.set(state, 'isLoading', false);
      console.log('eroare save_programare', checkObjProps(e, 'response.data'));
    }
  },

  async update_programare ({ state }) {
    try {
      Vue.set(state, 'isLoading', true);
      const { data } = await axios.put(
        `/api/update-programare/${router.currentRoute.params.id}`,
        state.modalProgramare
      );
      Vue.set(state, 'programari', data);
      Vue.set(state, 'isLoading', false);
      Vue.set(state, 'isModal', false);
      Vue.set(state, 'modalComponentName', undefined);
    } catch (e) {
      Vue.set(state, 'isLoading', false);
      console.log('eroare save_programare', checkObjProps(e, 'response.data'));
    }
  },

  async delete_programare ({ state }) {
    try {
      Vue.set(state, 'isLoading', true);
      const { data } = await axios.post(
        `/api/sterge-programare/${router.currentRoute.params.id}`,
        state.modalProgramare
      );
      Vue.set(state, 'programari', data);
      Vue.set(state, 'isLoading', false);
      Vue.set(state, 'isModal', false);
      Vue.set(state, 'modalComponentName', undefined);
    } catch (e) {
      Vue.set(state, 'isLoading', false);
      console.log('eroare save_programare', checkObjProps(e, 'response.data'));
    }
  }
};

export default actions;
