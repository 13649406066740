export const STRINGS = {
  ADD_COROANA: 'adaugă coroană',
  EXTRACTIE: 'extracție',
  SEMNATURA: 'semnătura',
  PROGRAMARE: {
    CONFIRMARE_STERGERE:
      'Sunteți sigur că doriți să ștergeți această programare?'
  },
  TRATAMENT: {
    CONFIRMARE_STERGERE: 'Sunteți sigur că doriți să ștergeți acest tratament?'
  },
  STERGE: 'ȘTERGE'
};
