<template>
  <section class="drop-down relative" :class="{ z9: isDD }">
    <validation-provider
      :rules="data.validation ? 'required' : ''"
      v-slot="{ errors }"
    >
      <input
        type="text"
        v-model="model"
        :data-test="'dropdown-' + data.name"
        :disabled="data.disabled"
        @keydown.enter.prevent
        autocomplete="23k1j23123u1h2u31uh"
        class="w100"
        :class="{
          'invalid-input': data.validation && errors[0],
          capitalize: data.capitalize,
        }"
        @focus="focus"
        @blur="blur"
        @input="search"
        :placeholder="data.placeholder"
      />
    </validation-provider>

    <!--  CLEAR MODEL  -->

    <i
      v-show="
        isDD && !data.disabled && $store.state[data.ddData].length > 4 && model
      "
      :data-test="'dropdown-clear-value-' + data.name"
      class="absolute pointer"
      @mousedown="clearModel"
    >
      <svg width="12px" height="12px" viewBox="0 0 12 12" version="1.1">
        <g
          id="Page-1"
          stroke="none"
          stroke-width="1"
          fill="none"
          fill-rule="evenodd"
        >
          <polygon
            id=""
            fill="#7C7C7C"
            fill-rule="nonzero"
            points="11.82 1.36 7.18 6 11.82 10.64 10.64 11.82 6 7.18 1.36 11.82 0.18 10.64 4.82 6 0.18 1.36 1.36 0.18 6 4.82 10.64 0.18"
          ></polygon>
        </g>
      </svg>
    </i>

    <!--  LIST OPTIUNI -->

    <ul
      class="absolute w100"
      :data-test="'dropdown-ul-' + data.name"
      v-show="isDD && !data.disabled"
    >
      <li
        @mousedown="select(item)"
        :data-test="'dd-value-' + item"
        :class="{ selected: index === arrowIndex, capitalize: data.capitalize }"
        v-for="(item, index) in filteredData || $store.state[data.ddData]"
        :key="item.name"
      >
        {{ item.name || item }}
      </li>
    </ul>
  </section>
</template>

<script>
  import { mapState } from 'vuex';
  import { EventBus } from '../main.js';
  export default {
    name: 'drop-down',
    props: {
      data: {
        type: Object,
        required: true
      },
      value: {
        type: String,
        required: false
      }
    },
    data () {
      return {
        model: '',
        valid: true,
        isDD: false,
        elm: undefined,
        isClear: false,
        arrowIndex: 0,
        filteredData: undefined,
        filteredDataCopy: undefined
      };
    },
    computed: {
      ...mapState({
        filterTratamente: 'filterTratamente'
      })
    },
    created () {
      this.model = this.data.default || this.value;
    },
    mounted () {
      EventBus.$on('reset-dd-model', this.resetModel);
    },
    methods: {
      resetModel ({ name }) {
        if (name === this.data.name) {
          this.model = undefined;
        }
      },

      search () {
        const string = this.model.toLowerCase();
        this.arrowIndex = 0;
        if (this.filteredData) {
          !this.filteredDataCopy &&
            (this.filteredDataCopy = this.filteredData.slice());
          this.filteredData = this.filteredDataCopy.filter(
            (obj) => obj.name.toLowerCase().indexOf(string.toLowerCase()) > -1
          );
        } else {
          this.$store.commit('SEARCH_DD', {
            string,
            template: this.data.ddData
          });
        }
      },

      focus (ev) {
        if (this.filterTratamente && this.data.name === 'denumire') {
          switch (this.filterTratamente) {
            case 'adaugă coroană':
              this.filteredData = this.$store.state[this.data.ddData].filter(
                (obj) => obj.id < 2
              );
              break;
            case 'extracție':
              this.filteredData = this.$store.state[this.data.ddData].filter(
                (obj) => obj.id === 3
              );
              break;
            case 'implant':
              this.filteredData = this.$store.state[this.data.ddData].filter(
                (obj) => obj.id === 4
              );
              break;
            case 'tratament endodontic':
              this.filteredData = this.$store.state[this.data.ddData].filter(
                (obj) => obj.id > 5 && obj.id < 6
              );
              break;
            default:
              break;
          }
        }
        this.isDD = true;
        this.elm = ev.currentTarget;
        this.arrowIndex = 0;
        document.addEventListener('keyup', this.kbEvListener, {
          passive: true
        });
      },

      select (item) {
        this.isDD = false;
        this.model = item.name || item;
        this.valid = item;
        this.$emit('selected', { name: this.data.name, value: this.model });
        document.removeEventListener('keyup', this.kbEvListener);
        this.$store.commit('RESET_DD_CLONE', { template: this.data.ddData });
        this.data.clbk && this.data.clbk(item);
      },

      clearModel () {
        this.isClear = true;
        this.clear();
        this.$store.commit('SET_VALUE', {
          key: 'ddClone',
          template: this.data.ddData
        });
        this.$store.commit('SET_VALUE', { key: 'ddClone', value: undefined });
        setTimeout(() => {
          this.elm.focus();
          this.isClear = false;
        }, 0);
        this.$emit('selected', { name: this.data.name, value: this.model });
      },

      clear () {
        this.model = undefined;
      },

      blur () {
        if (this.$store.state[this.data.ddData].length === 0) {
          this.clear();
        }
        !this.isClear && (this.isDD = false);
        !this.isClear &&
          this.data.blur &&
          !this.model &&
          this.data.blur(this.model);
        this.valid = this.model;
        !this.model &&
          this.$emit('selected', { name: this.data.name, value: undefined });
        this.$store.commit('RESET_DD_CLONE', { template: this.data.ddData });
        document.removeEventListener('keyup', this.kbEvListener);
      },

      kbEvListener ({ key }) {
        switch (key) {
          case 'ArrowUp':
            if (this.arrowIndex > 0) {
              this.arrowIndex -= 1;
            }
            break;
          case 'ArrowDown':
            if (
              this.arrowIndex <
              this.$store.state[this.data.ddData].length - 1
            ) {
              this.arrowIndex += 1;
            }
            break;
          case 'Enter':
            this.isDD = false;
            let clbkObj = {};
            if (this.data.ddData === 'tratamente') {
              const tratamente = this.$store.state[this.data.ddData];
              this.model =
                tratamente.length && tratamente[this.arrowIndex].name;
              clbkObj.pret = tratamente[this.arrowIndex].pret;
              this.data.pret = tratamente[this.arrowIndex].pret;
            } else {
              this.model = this.$store.state[this.data.ddData][this.arrowIndex];
            }
            if (this.model) {
              this.$emit('selected', {
                name: this.data.name,
                value: this.model
              });
              clbkObj.name = this.model;
              this.data.clbk && this.data.clbk(clbkObj);
            }
            document.removeEventListener('keyup', this.kbEvListener);
            this.$store.commit('RESET_DD_CLONE', {
              template: this.data.ddData
            });
            break;
        }
      }
    },

    watch: {
      data: {
        deep: true,
        handler () {
          this.model = this.data.default;
        }
      }
    },

    destroyed () {
      EventBus.$off('reset-dd-model', this.resetModel);
      document.removeEventListener('keyup', this.kbEvListener);
    }
  };
</script>

<style lang="less">
  .drop-down {
    i {
      right: 10px;
      top: 12px;
    }
    ul {
      top: 35px;
      background-color: white;
      border: 1px solid #dddddd;
      border-radius: 8px;
      height: 178px;
      overflow-y: scroll;
      li {
        padding: 14px;
        font-size: 1.4rem;
        &:hover {
          background-color: #eeeeee;
        }
      }
      .selected {
        background-color: #eeeeee;
      }
    }
  }
</style>
