import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '../store';
import Login from '../views/Login';
import ViewPdf from '../views/ViewPdf';
import Utilizatori from '../views/Utilizatori';

Vue.use(VueRouter);

export const routes = [
  {
    path: '/',
    redirect: '/login'
  },
  {
    path: '/login',
    name: 'login',
    component: Login
  },
  {
    path: '/plan-tratament/pdf',
    name: 'ViewPdf',
    component: ViewPdf
  },
  {
    path: '/utilizatori',
    meta: {
      auth: true,
      role: 'admin'
    },
    name: 'utilizatori',
    component: Utilizatori
  },
  {
    path: '/pacienti',
    meta: {
      auth: true
    },
    component: () => import('../views/PacientiLayout.vue'),
    children: [
      {
        path: '',
        meta: {
          auth: true
        },
        component: () => import('../views/ListaPacienti.vue')
      },
      {
        path: 'history/:id',
        meta: {
          auth: true,
          header: true
        },
        name: 'history',
        component: () => import('../views/HistoryPacient.vue')
      },
      {
        path: 'list',
        meta: {
          auth: true
        },
        name: 'list-pacients',
        component: () => import('../views/ListaPacienti.vue')
      },
      {
        path: 'add',
        meta: {
          auth: true
        },
        name: 'add-patient',
        component: () => import('../views/AddPacient.vue')
      },
      {
        path: 'edit/:id',
        meta: {
          auth: true,
          header: true
        },
        name: 'edit',
        component: () => import('../views/AddPacient.vue')
      },
      {
        path: 'programari/:id',
        meta: {
          auth: true,
          header: true
        },
        name: 'programari',
        component: () => import('../views/Programari.vue')
      },
      {
        path: 'docs/:id',
        meta: {
          auth: true,
          header: true
        },
        name: 'docs',
        component: () => import('../views/DocsHistory.vue')
      },
      {
        path: 'schita/:id',
        meta: {
          auth: true,
          header: true
        },
        name: 'schita',
        component: () => import('../views/SchitaDentara.vue')
      }
    ]
  },
  {
    path: '/planuri-tratamente',
    meta: {
      auth: true
    },
    component: () => import('../layouts/PlanTratamentLayout.vue'),
    children: [
      {
        path: ':id',
        name: 'planuri-tratamente',
        meta: {
          auth: true
        },
        component: () => import('../views/PlanuriTratamente.vue')
      },
      {
        path: 'plan/:id',
        name: 'plan-nou',
        meta: {
          auth: true
        },
        component: () => import('../views/PlanTratamentNou.vue')
      },
      {
        path: 'plan/edit-plan/:id',
        name: 'edit-plan',
        meta: {
          auth: true
        },
        component: () => import('../views/EditPlanTratament.vue')
      }
    ]
  }
];

const router = new VueRouter({
  mode: 'history',
  routes
});

router.beforeEach((to, from, next) => {
  if (to.name === 'login') {
    store.state.isLogged ? next('/pacienti') : next();
  } else {
    to.meta.auth && !store.state.isLogged
      ? store.dispatch('checkLogin', { to, next })
      : next();
  }
});

export default router;
