import axios from 'axios';
import * as Sentry from '@sentry/browser';

export const actions = {
  async save_tratement_nou ({ rootState }, tratament) {
    try {
      console.log(tratament);
    } catch (err) {
      console.log(err);
    }
  },
  async save_tratament (
    { rootState: { modalData, formData, isLoading, pacient, isModal }, commit },
    { id }
  ) {
    try {
      commit('SHOW_LOADER', true);
      modalData.id = new Date().getTime();
      commit('ARRANGE_TRATAMENT_PICS_ARRAY');
      formData.append('obj', JSON.stringify(modalData));
      const url = `/api/history/add-tratament/${id}`;
      const { data } = await axios.post(url, formData);
      commit('UPDATE_PACIENT', data);
      commit('CLOSE_MODAL');
      commit('RESET_MODAL_DATA');
    } catch (err) {
      console.log(err)
      commit('CLOSE_MODAL');
      if (err.response && err.response.status === 400) {
        Sentry.captureException(err.response.data);
        alert('Eroare modificare tratament. Va rugam sa ne contactati.');
      }
    }
  },

  async update_tratament (
    { rootState: { modalData, formData, isLoading, pacient, isModal }, commit },
    { id }
  ) {
    try {
      commit('ARRANGE_TRATAMENT_PICS_ARRAY');
      formData.append('obj', JSON.stringify(modalData));
      isLoading = true;
      const url = `/api/history/update-tratament/${id}`;
      const { data } = await axios.put(url, formData);
      commit('UPDATE_PACIENT', data);
      commit('CLOSE_MODAL');
      commit('RESET_MODAL_DATA');
    } catch (err) {
      commit('CLOSE_MODAL');
      if (err.response.status === 400) {
        Sentry.captureException(err.response.data);
        alert('Eroare modificare tratament. Va rugam sa ne contactati.');
      }
    }
  }
};
