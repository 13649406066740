<template>
  <div class="pdf-wrapper">
    <!--  First page -> The cover    -->
    <PdfCover class="pdf-dimensions" />
    <PdfContentWrapper :total="plan.pret_total"
                       :price1ron="plan.pret_1_ron"
                       :date="plan.createdAt"
                       :nume="plan.numePacient"
                       :planNo="plan.planNo"
                       class="pdf-dimensions"
    >
<!--      <div v-for="etapa in plan.etape">-->
<!--        <PdfTable :etapa="etapa" />-->
<!--      </div>-->
    </PdfContentWrapper>

      <div class="temporary-tables" v-for="etapa in plan.etape">
        <PdfTable v-if="!etapa.isHidden" :etapa="etapa" :price1ron="plan.pret_1_ron" />
      </div>
    <PdfLastPage :plan="plan" class="pdf-dimensions" />
  </div>
</template>

<script>
  import PdfCover from '../components/PdfCover.vue';
  import PdfContentWrapper from '../components/PdfContentWrapper.vue';
  import PdfTable from '../components/PdfTable.vue';
  import PdfLastPage from '../components/PdfLastPage.vue';


  export default {
    name: 'viewPdf',
    components: {
      PdfCover,
      PdfContentWrapper,
      PdfTable,
      PdfLastPage
    },

    mounted () {
      this.removeUnusedHtml();

      window.addEventListener('load', () => {
        setTimeout(() => {
          this.handleUpdatePage()
        }, 500)
      });

    },

    created () {
      this.$store.dispatch('get_plan_pdf', this.$route.query.plan_id);
    },

    computed: {
      plan () {
        return this.$store.state.planTratamente.plan;
      }
    },

    methods: {
      removeUnusedHtml () {
        const el = document.querySelector('.tab-bar');
        el.style.display = 'none';
      },
      handleUpdatePage () {
        let wrapperNo = 0;
        let height = 0;
        const content_wrapper = document.getElementsByClassName('pdf-content-wrapper');
        const tables = document.getElementsByClassName('temporary-tables');
        const tables_wrapper = document.getElementsByClassName('pdf-details-wrapper')[0].offsetHeight;

        for (let i = 0; i < tables.length; i++) {
          height += tables[i].offsetHeight;
          const clone_table = tables[i].cloneNode(true);
          clone_table.classList.remove('temporary-tables');
          if (height >= tables_wrapper) {
            const clone_wrapper = content_wrapper[0].cloneNode(true);
            const last_page = document.getElementsByClassName('pdf-last-page');
            wrapperNo++;
            height = tables[i].offsetHeight;

            content_wrapper[0].parentNode.insertBefore(clone_wrapper, last_page[0]);
            let x = content_wrapper[wrapperNo].querySelectorAll('.pdf-table')
            x.forEach(e => e.remove())
          }
          content_wrapper[wrapperNo].getElementsByClassName('pdf-details-wrapper')[0].appendChild(clone_table);
        }
      }
    }
  }


</script>

<style lang="less">
  .pdf-wrapper {
    transform: scale(1.5);
    transform-origin: top;
    margin: 0 auto;
    top: 0;
    padding: 0;
    height: 100%;
    .temporary-tables {
      position: absolute;
      width: 550px;
      visibility: hidden;
    }
    @font-face {
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 300;
      src: url('../assets/pdfAssets/roboto-300.woff') format('woff');
    }
    @font-face {
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 400;
      src: url('../assets/pdfAssets/roboto-regular.woff') format('woff');
    }
    @font-face {
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 500;
      src: url('../assets/pdfAssets/roboto-500.woff') format('woff');
    }
    @font-face {
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 700;
      src: url('../assets/pdfAssets/roboto-700.woff') format('woff');
    }
    @font-face {
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 900;
      src: url('../assets/pdfAssets/roboto-900.woff') format('woff');
    }
    @font-face {
      font-family: 'AppleBraille';
      src: url('../assets/pdfAssets/AppleBraille.woff') format('woff');
    }

    .pdf-width {
      width: 84%;
    }

    .pdf-dimensions {
      width: 595px;
      aspect-ratio: 1 / 1.4142;
    }
  }
</style>
