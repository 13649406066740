<template>
  <div class="pin-point-component">
    <span
      @click="handlePin()"
      class="pointer"
      :class="isPinActive ? 'pin-active' : ''">
        <svg width="65" height="65" viewBox="0 0 56 55" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M55.5 27.5C55.5 42.4032 43.1964 54.5 28 54.5C12.8036 54.5 0.5 42.4032 0.5 27.5C0.5 12.5968 12.8036 0.5 28 0.5C43.1964 0.5 55.5 12.5968 55.5 27.5Z"
                stroke="#808080"/>
          <path d="M31.4165 16C31.52 15.9998 31.6225 16.02 31.7182 16.0595C31.8138 16.0989 31.9008 16.1569 31.974 16.2299L39.7701 24.026C39.9177 24.1736 40.0006 24.3739 40.0006 24.5827C40.0006 24.7915 39.9177 24.9918 39.7701 25.1394C39.0141 25.8954 38.0817 26.0655 37.4029 26.0655C37.1241 26.0655 36.8753 26.0372 36.6784 26.0041L31.7425 30.94C31.8725 31.4635 31.9568 31.9973 31.9945 32.5354C32.067 33.641 31.9441 35.1924 30.8606 36.2759C30.7129 36.4236 30.5126 36.5065 30.3038 36.5065C30.095 36.5065 29.8947 36.4236 29.7471 36.2759L25.2915 31.822L20.28 36.8335C19.9729 37.1406 18.3602 38.2541 18.053 37.947C17.7459 37.6398 18.8594 36.0255 19.1665 35.72L24.178 30.7085L19.7241 26.2529C19.5764 26.1053 19.4935 25.905 19.4935 25.6962C19.4935 25.4874 19.5764 25.2871 19.7241 25.1394C20.8076 24.0559 22.359 23.9315 23.4646 24.0055C24.0027 24.0432 24.5365 24.1275 25.06 24.2575L29.9959 19.3232C29.9547 19.0833 29.9336 18.8404 29.9329 18.5971C29.9329 17.9199 30.103 16.9875 30.8606 16.2299C31.0081 16.0827 31.2081 16 31.4165 16V16Z"
                fill="#808080"/>
        </svg>
    </span>
  </div>

</template>

<script>
  export default {
    name: 'PinPoint',
    data () {
      return {
        isPinActive: false
      }
    },
    props: {
      hasPinActive: Boolean
    },
    created () {
      this.isPinActive = this.hasPinActive
    },
    methods: {
      handlePin () {
        this.isPinActive = !this.isPinActive;
        this.$emit('handle-pin', this.isPinActive)
      }
    }
  };
</script>

<style lang="less">
.pin-point-component {
  .pin-active {
    path {
      stroke: #F0C700;
    }
    path:nth-child(2) {
      fill: #F0C700;
    }
  }
}
</style>
