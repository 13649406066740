import axios from 'axios'
import api from '../api'
import Vue from 'vue'
import router from '@/router'
import {tratamente} from '@/store/tratamente'

export const state = function () {
  return {
    planuriTemplate: {
      lucru: 'In lucru',
      asteptare: 'In asteptare',
      finalizate: 'Finalizate'
    },
    planuri: {
      lucru: [],
      asteptare: [],
      finalizate: []
    },
    plan: {},
    treatmentState: {
      isEdit: {
        etapaId: undefined,
        treatmentId: undefined
      },
      isNew: false
    },
    isLoading: false
  }
}

export const actions = {
  async get_planuri ({commit}, id) {
    try {
      const url = `${api.planuriTratament}/${id}`
      const {data} = await axios(url)
      commit('SET_PLANS_DATA', {key: 'planuri', value: data})
    } catch (err) {
      console.log('err get planuri tratament', err.response)
    }
  },
  async get_plan ({commit}, id) {
    try {
      const url = `${api.planuriTratament}/plan/${id}`
      const { data } = await axios(url)
      commit('SET_PLANS_DATA', {key: 'plan', value: data})
    } catch (err) {
      console.log('err create plan tratament', err.response)
    }
  },
  async create_plan ({ commit }, { plan, id }) {
    try {
      const url = `${api.planuriTratament}/${id}`
      const { data } = await axios.post(url, plan)
      await router.push(`edit-plan/${id}?plan_id=${data.id}`)
    } catch (err) {
      window.alert(err.response.data)
      console.log('err create plan tratament', err.response)
    }
  },
  async update_plan ({ state, commit }, plan_id) {
    try {
      if (!plan_id) plan_id = router.currentRoute.query.plan_id
      const url = `${api.planuriTratament}/plan/${plan_id}`
      await axios.put(url, state.plan)
      commit('SET_EDIT_MODE', {})
      commit('SET_NEW_TREATMENT', false)
    } catch (err) {
      console.log('err create plan tratament', err.response)
    }
  },
  async delete_plan ({ state, commit }) {
    try {
      const url = `${api.planuriTratament}/plan/${router.currentRoute.query.plan_id}`
      await axios.delete(url)
      commit('DELETE_PLAN', {})
      commit('SET_EDIT_MODE', {})
      commit('SET_NEW_TREATMENT', false)
      await router.push(`/planuri-tratamente/${router.currentRoute.params.id}`)
    } catch (err) {
      console.log('err delete plan tratament', err.response || err)
    }
  },

  async get_plan_pdf ({commit}, id) {
    try {
      const url = `${api.planuriTratament}/plan/${id}`
      const { data } = await axios(url)
      const toEuro = {
        amount: 1
      }
      const price = await axios.post(`${api.planuriTratament}/currency-rate/`, toEuro)
      commit('SET_PLANS_DATA_PDF', {
        data,
        priceRon: price.data.value
      })
    } catch (err) {
      console.log('err create plan tratament', err.response)
    }
  },

  async get_plan_with_x_ray ({commit, dispatch}, {id, radiografie}) {
    try {
      const url = `${api.planuriTratament}/plan/${id}`
      const { data, status } = await axios(url)
      commit('SET_PLANS_DATA', {key: 'plan', value: data})
      commit('SET_PLAN_RADIOGRAFIE', radiografie)
      if (status === 200 || status || 304) dispatch('update_plan', id)
    } catch (err) {
      console.log('err create plan tratament', err.response)
    }
  },

  async download_plan ({commit}, plan_id) {
    try {
      let filename = ''
      const url = `/api/treatment-plans/get-pdf/${plan_id}`
      commit('IS_LOADING', true)
      function toDataURL (url) {
        return fetch(url).then((response) => {
          const header = response.headers.get('content-disposition')
          const parts = header.split(';');
          filename = parts[1].split('=')[1].replaceAll('"', '');
          if(response.status === 200) commit('IS_LOADING', false)
          return response.blob();
        }).then(blob => {
          return URL.createObjectURL(blob);
        });
      }
      const a = document.createElement('a');
      a.href = await toDataURL(url);
      a.download = `${filename}.pdf`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    } catch (err) {
      console.log('err create plan tratament', err.response)
    }
  }
}

export const getters = {
  listaPlanuri: state => {
    const data = Object.keys(state.planuri).reduce(function (acc, key) {
      acc[key] = state.planuri[key]
      return acc
    }, {})
    return data
  },
  planuriFinalizate: state => state.planuri.lucru
    .map(o => ({id: o._id, nume: o.nume}))
}

export const mutations = {
  SET_PLANS_DATA (state, {key, value}) {
    Vue.set(state, key, value)
  },
  ADD_TRATAMENT_TO_ETAPA (state, etapaId) {
    Vue.set(state.treatmentState, 'isNew', true)
    const treatmentId = state.plan.etape[etapaId - 1].tratamente.length + 1
    state.plan.etape[etapaId - 1].tratamente.push({
      id: treatmentId,
      tratament: {},
      observatii: '',
      medic: '',
      dinti: [],
      dintiTratati: [],
      status: 'lucru'
    })
    Vue.set(state.treatmentState, 'isEdit', {etapaId, treatmentId})
    Vue.set(state.treatmentState, 'isNew', true)
  },
  SET_EDIT_MODE (state, data) {
    Vue.set(state.treatmentState, 'isEdit', data)
  },
  SET_NEW_TREATMENT (state, data) {
    Vue.set(state.treatmentState, 'isNew', data)
  },
  SET_TRATAMENT_PRET_ON_SELECT (state, name) {
    const {etapaId, treatmentId} = state.treatmentState.isEdit
    const indexEtapa = state.plan.etape.findIndex(o => +o.id === +etapaId)
    const indexTratament = state.plan.etape[indexEtapa].tratamente.findIndex(o => +o.id === +treatmentId)
    const dinti = state.plan.etape[indexEtapa].tratamente[indexTratament].dinti?.length || 1
    const pret = tratamente
      .filter((obj) => obj.name === name)
      .map(({pret}) => +pret * dinti)[0]
    Vue.set(state.plan.etape[indexEtapa].tratamente[indexTratament].tratament, 'pret', pret.toString())
  },
  CANCEL_NEW_TREATMENT (state, {etapaId}) {
    const indexEtapa = state.plan.etape.findIndex(o => +o.id === +etapaId)
    state.plan.etape[indexEtapa].tratamente.pop()
    Vue.set(state.treatmentState, 'isNew', false)
    Vue.set(state.treatmentState, 'isEdit', {})
  },
  UPDATE_TRATAMENT (state, {tratament, etapaId}) {
    const indexEtapa = state.plan.etape.findIndex(o => +o.id === +etapaId)
    const indexTratament = state.plan.etape[indexEtapa].tratamente.findIndex(o => +o.id === +tratament.id)
    Vue.set(state.plan.etape[indexEtapa].tratamente, indexTratament, tratament)
  },
  CLOSE_TRATAMENT (state, etapaId) {
    const index = state.plan.etape.findIndex(o => +o.id === +etapaId)
    state.plan.etape[index].tratamente.pop()
  },
  DELETE_TRATAMENT (state, {etapaId, tratamentId}) {
    const tIndex = state.plan.etape[etapaId - 1].tratamente.findIndex(o => +o.id === +tratamentId)
    state.plan.etape[etapaId - 1].tratamente.splice(tIndex, 1)
  },
  HANDLE_TOOTH_SELECTION (state, {id: toothId, manualPrice}) {
    const {etapaId, treatmentId} = state.treatmentState.isEdit
    const indexEtapa = state.plan.etape.findIndex(o => +o.id === +etapaId)
    const indexTratament = state.plan.etape[indexEtapa].tratamente.findIndex(o => +o.id === +treatmentId)
    const toothIndex = state.plan.etape[indexEtapa].tratamente[indexTratament].dinti.indexOf(toothId)
    const dinti = state.plan.etape[indexEtapa].tratamente[indexTratament].dinti || []
    const tratament = state.plan.etape[indexEtapa].tratamente[indexTratament].tratament.name
    const pretTratament = +manualPrice || tratamente.filter(({name}) => name === tratament).map(({pret}) => pret)[0]
    toothIndex === -1
      ? state.plan.etape[indexEtapa].tratamente[indexTratament].dinti.push(toothId)
      : state.plan.etape[indexEtapa].tratamente[indexTratament].dinti.splice(toothIndex, 1)
    const pretNou = (+pretTratament * dinti.length).toString()
    if (dinti.length > 0) {
      Vue.set(state.plan.etape[indexEtapa].tratamente[indexTratament].tratament, 'pret', pretNou)
    }
  },
  SET_PLAN_STATUS (state, status) {
    Vue.set(state.plan, 'status', status)
  },
  DELETE_PLAN (state) {
    const planIndex = state.planuri[state.plan.status].findIndex(obj => obj._id === state.plan._id)
    planIndex && state.planuri[state.plan.status].splice(planIndex, 1)
    Vue.set(state, 'plan', {})
  },
  RESET_DINTI_ON_MANUAL_PRICE (state) {
    const {etapaId, treatmentId} = state.treatmentState.isEdit
    const indexEtapa = state.plan.etape.findIndex(o => +o.id === +etapaId)
    const indexTratament = state.plan.etape[indexEtapa].tratamente.findIndex(o => +o.id === +treatmentId)
    Vue.set(state.plan.etape[indexEtapa].tratamente[indexTratament], 'dinti', [])
  },
  SET_PLANS_DATA_PDF (state, {data, priceRon}) {
    let dintiArr = [], mediciArr = []
    let total = 0
    data.etape.forEach(etapa => {
      let totalEtapa = 0
      if (!etapa.isHidden) {
        etapa.tratamente.forEach(trat => {
          mediciArr.indexOf(trat.medic) === -1 && mediciArr.push(trat.medic)
          totalEtapa += parseInt(trat.tratament.pret)
          total += parseInt(trat.tratament.pret)
          dintiArr = dintiArr.concat(trat.dinti.filter((item) => dintiArr.indexOf(item) < 0))
        })
      }
      etapa['total_etapa'] = totalEtapa
    })
    data['medici_desemnati'] = mediciArr
    data['dinti_in_lucru'] = dintiArr
    data['pret_total'] = total
    data['pret_1_ron'] = priceRon
    state.plan = data;
  },
  SET_PLAN_MEDIC_COORDONATOR (state, medic) {
    Vue.set(state.plan, 'medicCoordonator', medic);
  },
  SET_VISIBILITY_ETAPA (state, { idEtapa, value }) {
    Vue.set(state.plan.etape[idEtapa], 'isHidden', value);
  },
  SET_PLAN_RADIOGRAFIE (state, radiografie) {
    Vue.set(state.plan, 'radiografie', radiografie);
  },
  IS_LOADING (state, value) {
    state.isLoading = value
  }

}
