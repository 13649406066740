import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import RightSideMenu from './components/rightSideMenu.vue';
import PacientHeader from './components/pacientHeader.vue';
import DropDown from './components/dropdown.vue';
import UploadImgBtn from './components/uploadImgBtn.vue';
import MainModal from './components/mainModal.vue';
import BaseModal from './components/BaseModal.vue';
import LoadingComponent from './components/loading.vue';
import DeleteTratamentBtn from './components/deleteTratamentButton.vue';
import AddTratamentModal from './components/addTratamentModal.vue';
import BaseDropDown from './components/BaseDropDown.vue';
import Datepicker from 'vuejs-datepicker';
import BaseForm from './components/BaseForm';
import {
  setInteractionMode,
  ValidationProvider,
  ValidationObserver,
  extend
} from 'vee-validate';
import {
  alpha,
  email,
  min,
  required,
  confirmed
} from 'vee-validate/dist/rules';
// import VueLodash from 'vue-lodash';
import './assets/styles/base.less';
import VueMask from 'v-mask';
import './store/utils';
import { regexValidation } from './mixins/regex-validation';
import * as Sentry from '@sentry/browser';
import { Vue as VueIntegration } from '@sentry/integrations';
import { Integrations } from '@sentry/tracing';
import Multiselect from 'vue-multiselect';
import PinPoint from './components/PinPoint';
// setInteractionMode('passive');

import { library } from '@fortawesome/fontawesome-svg-core';
import { faTimes, faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

library.add(faTimes, faCaretDown);

Vue.component('font-awesome-icon', FontAwesomeIcon);

// Sentry.init({
//   dsn:
//     'https://7b41971ea2384ad8b5699f97819de374@o476429.ingest.sentry.io/5516536',
//   integrations: [
//     new VueIntegration({
//       Vue,
//       tracing: true
//     }),
//     new Integrations.BrowserTracing()
//   ],
//
//   // We recommend adjusting this value in production, or using tracesSampler
//   // for finer control
//   tracesSampleRate: 1.0
// });

export const EventBus = new Vue();
// Vue.use(VueLodash);
Vue.use(VueMask);
Vue.mixin(regexValidation);
Vue.component('RightSideMenu', RightSideMenu);
Vue.component('PacientHeader', PacientHeader);
Vue.component('MainModal', MainModal);
Vue.component('LoadingComponent', LoadingComponent);
Vue.component('DropDown', DropDown);
Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);
Vue.component('Datepicker', Datepicker);
Vue.component('UploadImgBtn', UploadImgBtn);
Vue.component('DeleteTratamentBtn', DeleteTratamentBtn);
Vue.component('AddTratamentModal', AddTratamentModal);
Vue.component('BaseModal', BaseModal);
Vue.component('Multiselect', Multiselect);
Vue.component('BaseDropdown', BaseDropDown);
Vue.component('BaseForm', BaseForm);
Vue.component('PinPoint',PinPoint)

extend('required', {
  ...required,
  message: 'This field is required!'
});
extend('email', email);
extend('alpha', alpha);
extend('min', min);
extend('password', {
  params: ['target'],
  validate (value, { target }) {
    return value === target;
  },
  message: 'Parolele nu coincid'
});
extend('confirmed', confirmed);
/* equal or greather then */
extend('eqOrGt', (value, values) => {
  if (value) {
    return +value >= +values[0];
  }
});
/* equal or greather then panaLa minutes */
extend('eqOrGtMinutes', (value, values) => {
  const deLaOra = +values[0];
  const deLaMinute = +values[1];
  const panaLaOra = +values[2];
  if (value) {
    return panaLaOra === deLaOra ? +value >= deLaMinute : true;
  }
});
/* greather then */
extend('gt', (value, values) => {
  if (value) {
    return +value > +values[0];
  }
});
/* less then */
extend('lt', {
  validate: (value, values) => +value < +values[0],
  message: (value, values) =>
    `The {_field_} field must be less then ${values[0]}`
});
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App)
}).$mount('#app');
